import React, { useCallback, useMemo } from 'react';
import _ from 'lodash';

import LayoutDirection from 'ecto-common/lib/types/LayoutDirection';
import Divider from 'ecto-common/lib/Divider/Divider';
import Flex, { FlexItem } from 'ecto-common/lib/Layout/Flex';
import SignalNamesTable from 'ecto-common/lib/SignalNamesTable/SignalNamesTable';
import T from 'ecto-common/lib/lang/Language';
import { KeyValueLabel } from 'ecto-common/lib/KeyValueInput/KeyValueLabel';
import { ModelDefinition } from 'ecto-common/lib/ModelForm/ModelPropType';
import {
  AdjustableChartSeries,
  AdjustableChartSignal
} from 'ecto-common/lib/AdjustableChart/AdjustableChart';
import { SignalInputType } from 'ecto-common/lib/Dashboard/datasources/LastSignalValuesDataSource';

interface SignalTimeSeriesTableProps {
  series: AdjustableChartSeries[];
  onSeriesChanged(series: AdjustableChartSeries[]): void;
  optionalSignalModels?: ModelDefinition<SignalInputType>[];
  useConstantValues?: boolean;
}

const SignalTimeSeriesTable = ({
  series,
  onSeriesChanged,
  optionalSignalModels,
  useConstantValues
}: SignalTimeSeriesTableProps) => {
  const xsignals = useMemo(() => _.compact(_.map(series, 'signalX')), [series]);
  const ysignals = useMemo(() => _.compact(_.map(series, 'signalY')), [series]);

  const onColumnSeriesChanged = useCallback(
    (columnName: 'signalX' | 'signalY', columnValues: SignalInputType[]) => {
      const object = {
        signalX: xsignals,
        signalY: ysignals,
        // overwrite any of the above
        [columnName]: columnValues
      };

      const newSeries = _.unzipWith(
        _.values(object),
        (signalX: AdjustableChartSignal, signalY: AdjustableChartSignal) => ({
          signalX,
          signalY
        })
      );
      onSeriesChanged(newSeries);
    },
    [onSeriesChanged, xsignals, ysignals]
  );

  const updateXSignalItem = useCallback(
    (newXSignals: SignalInputType[]) => {
      onColumnSeriesChanged('signalX', newXSignals);
    },
    [onColumnSeriesChanged]
  );

  const updateYSignalItem = useCallback(
    (newYSignals: SignalInputType[]) => {
      onColumnSeriesChanged('signalY', newYSignals);
    },
    [onColumnSeriesChanged]
  );

  return (
    <Flex direction={LayoutDirection.HORIZONTAL}>
      <FlexItem grow={1} shrink={1} basis={'50%'}>
        <KeyValueLabel>{T.signalsseriestable.xsignal}</KeyValueLabel>
        <SignalNamesTable
          onSignalsChanged={updateXSignalItem}
          signals={xsignals}
          optionalSignalModels={optionalSignalModels}
          useConstantValues={useConstantValues}
        />
      </FlexItem>
      <FlexItem grow={0} shrink={1} basis={'auto'}>
        <Divider direction={LayoutDirection.VERTICAL} />
      </FlexItem>
      <FlexItem grow={1} shrink={1} basis={'50%'}>
        <KeyValueLabel>{T.signalsseriestable.ysignal}</KeyValueLabel>
        <SignalNamesTable
          onSignalsChanged={updateYSignalItem}
          signals={ysignals}
          optionalSignalModels={optionalSignalModels}
          useConstantValues={useConstantValues}
        />
      </FlexItem>
    </Flex>
  );
};

export default React.memo(SignalTimeSeriesTable);
