import classNames from 'classnames';
import styles from 'ecto-common/lib/DataTable/DataTable.module.css';
import DataTableHeaderCell from './DataTableHeaderCell';
import React from 'react';
import {
  calculateMinWidthForRow,
  DataTableRowMinHeight
} from 'ecto-common/lib/DataTable/DataTableRow';
import HorizontalAlignments from 'ecto-common/lib/types/HorizontalAlign';
import { SortDirectionType } from 'ecto-common/lib/DataTable/SortDirection';
import { DataTableColumnProps } from 'ecto-common/lib/DataTable/DataTable';
import { typedMemo } from 'ecto-common/lib/utils/typescriptUtils';

interface DataTableHeaderRowProps<ObjectType> {
  columns: DataTableColumnProps<ObjectType>[];
  sortBy?: string;
  sortDirection?: SortDirectionType;
  onSortChange?({ sortBy }: { sortBy: string }): void;
}

const DataTableHeaderRow = <ObjectType,>({
  columns,
  sortBy,
  sortDirection,
  onSortChange
}: DataTableHeaderRowProps<ObjectType>) => {
  const className = classNames(styles.row, styles.headerRow);

  return (
    <div
      className={className}
      style={{
        minHeight: DataTableRowMinHeight.STANDARD,
        flexGrow: 0,
        minWidth: calculateMinWidthForRow(columns)
      }}
    >
      {columns.map((column, index) => {
        const {
          width: columnWidth = 1,
          dataKey,
          maxWidth,
          minWidth,
          flexGrow = 1,
          flexShrink = 1,
          truncateText = false
        } = column;

        let { align = HorizontalAlignments.LEFT } = column;

        if (truncateText && align !== HorizontalAlignments.LEFT) {
          console.error('Must use left align when truncating text');
          align = HorizontalAlignments.LEFT;
        }

        return (
          <div
            key={dataKey + index}
            style={{
              maxWidth,
              minWidth,
              flexGrow,
              flexShrink,
              width: columnWidth
            }}
            className={classNames(
              styles.rowColumn,
              sortBy === column.dataKey && styles.sortColumn
            )}
          >
            <DataTableHeaderCell
              columnData={column}
              onSortChange={onSortChange}
              sortBy={sortBy}
              sortDirection={sortDirection}
              align={align}
            />
          </div>
        );
      })}
    </div>
  );
};

export default typedMemo(DataTableHeaderRow);
