/* eslint-disable */
import Util from '../util';
import SquareButton from '../square_button';
import noneSelectedIcon from '../../assets/button_drawer/screens.svg';
import firstSelectedIcon from '../../assets/button_drawer/screens_1.svg';
import secondSelectedIcon from '../../assets/button_drawer/screens_2.svg';
import thirdSelectedIcon from '../../assets/button_drawer/screens_3.svg';
import { Image } from 'p5';
import { TableRect } from 'js/Table/types';
import TableP5 from 'js/Table/TableP5';

export default class ComboToggleButton extends SquareButton {
  isOpen: boolean;
  activeScreen: number;
  images: Image[];
  defaultRect: TableRect;

  constructor(activeComboScreen = 1) {
    super();
    this.isOpen = false;
    this.activeScreen = activeComboScreen;
    this.images = [];
  }

  setDefault(rect) {
    this.defaultRect = rect;
  }

  renderInit(p5Instance: TableP5) {
    this.images = [
      p5Instance.loadImage(noneSelectedIcon),
      p5Instance.loadImage(firstSelectedIcon),
      p5Instance.loadImage(secondSelectedIcon),
      p5Instance.loadImage(thirdSelectedIcon)
    ];
  }

  draw(p5Instance: TableP5, overrideRect: TableRect) {
    const defaultRect = this.defaultRect;
    const image = !this.isOpen
      ? this.images[this.activeScreen]
      : this.images[0];

    if (image !== undefined) {
      let w = 40;
      let h = 40;

      // The priority order is
      // 1. if the value exist in the override use that
      // 2. else use the value set on the object
      // 3. use the calculated size (and 0 position)

      this.bounding.x =
        (overrideRect && overrideRect.x) || (defaultRect && defaultRect.x) || 0;
      this.bounding.y =
        (overrideRect && overrideRect.y) || (defaultRect && defaultRect.y) || 0;
      this.bounding.w =
        (overrideRect && overrideRect.width) ||
        (defaultRect && defaultRect.width) ||
        w;
      this.bounding.h =
        (overrideRect && overrideRect.height) ||
        (defaultRect && defaultRect.height) ||
        h;

      p5Instance.image(
        image,
        this.bounding.x,
        this.bounding.y,
        this.bounding.w,
        this.bounding.h
      );
      return Util.SUCCESS;
    }

    return Util.NOOP;
  }
}
