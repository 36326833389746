import { PATCH_EQUIPMENT_TYPES, SET_EQUIPMENT_TYPES } from './actionTypes';
import { EquipmentTypeResponseModel } from 'ecto-common/lib/API/APIGen';

export function setEquipmentTypes(types: EquipmentTypeResponseModel[] = []) {
  return { type: SET_EQUIPMENT_TYPES, payload: types };
}

export function patchEquipmentTypes(payload: EquipmentTypeResponseModel[]) {
  return { type: PATCH_EQUIPMENT_TYPES, payload };
}
