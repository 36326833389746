import React, { useEffect, useState, Dispatch, SetStateAction } from 'react';

import SegmentControl from 'ecto-common/lib/SegmentControl/SegmentControl';
import { SegmentControlItem } from 'ecto-common/lib/SegmentControl/SegmentControlItem';
import classNames from 'classnames';
import styles from './CollapsingSegmentControlPicker.module.css';
import Select, { GenericSelectOption } from 'ecto-common/lib/Select/Select';
import { typedMemo } from 'ecto-common/lib/utils/typescriptUtils';
import { useResizeDetector } from 'react-resize-detector';

export type OptionWithIcon<ValueType = string> =
  GenericSelectOption<ValueType> & {
    icon?: React.ReactNode;
  };

type CollapsingSegmentControlPickerProps<ValueType> = {
  className?: string;
  options?: OptionWithIcon<ValueType>[];
  value?: OptionWithIcon<ValueType>;
  onChangeValue?: (value: OptionWithIcon<ValueType>) => void;
  maxWidth?: number;
  isLoading?: boolean;
  placeholder?: string;
};

type CollapsingSegmentControlPickerContentProps<ValueType> = Omit<
  CollapsingSegmentControlPickerProps<ValueType>,
  'maxWidth'
> & {
  setWidth: Dispatch<SetStateAction<number>>;
  visible: boolean;
};

/**
 * This complementary component is needed since we need to figure out the dimensions of the segment control.
 * The segment control is always part of the DOM, just not always visible. It is placed as an absolute element
 * so we can get the actual width without the containing environment interfering.
 */

function CollapsingSegmentControlPickerContent<ValueType>({
  options,
  value,
  setWidth,
  visible,
  onChangeValue
}: CollapsingSegmentControlPickerContentProps<ValueType>) {
  const { width, ref } = useResizeDetector({ handleWidth: true });

  useEffect(() => {
    setWidth(width);
  }, [setWidth, width]);

  return (
    <div ref={ref}>
      <SegmentControl
        className={classNames(styles.segmentControl, visible && styles.visible)}
      >
        {options.map((option, idx) => (
          <SegmentControlItem
            active={option === value}
            key={idx}
            onClick={() => onChangeValue(option)}
          >
            {option.icon}
            {option.label}
          </SegmentControlItem>
        ))}
      </SegmentControl>
    </div>
  );
}

/**
 * This is a segment control that when being too wide for the current context collapses to a Select component.
 * It has some limitations compared to the regular SegmentedControl as it uses the same options format as the
 * Select component.
 *
 * The current implementation just collapses when using more than 90% of the available parent width.
 */
function CollapsingSegmentControlPicker<ValueType = string>({
  options,
  value,
  onChangeValue,
  maxWidth = 0.9,
  className,
  isLoading,
  placeholder
}: CollapsingSegmentControlPickerProps<ValueType>) {
  const [showSegmentControl, setShowSegmentControl] = useState(true);
  const [childWidth, setChildWidth] = useState(0);
  const { width, ref } = useResizeDetector({ handleWidth: true });

  useEffect(() => {
    setShowSegmentControl(childWidth < maxWidth * width);

    if (
      ref.current != null &&
      ref.current.contains(document.activeElement) &&
      document.activeElement instanceof HTMLElement
    ) {
      document.activeElement.blur();
    }
  }, [childWidth, maxWidth, ref, width]);

  return (
    <div className={classNames(className, styles.container)} ref={ref}>
      {!isLoading && (
        <div className={classNames(styles.segmentContainer)}>
          <CollapsingSegmentControlPickerContent
            onChangeValue={onChangeValue}
            options={options}
            value={value}
            setWidth={setChildWidth}
            visible={showSegmentControl}
          />
        </div>
      )}
      <Select
        options={options}
        value={value}
        isLoading={isLoading}
        placeholder={placeholder}
        onChange={onChangeValue}
        className={classNames(
          styles.select,
          !showSegmentControl && styles.visible
        )}
      />
    </div>
  );
}

export default typedMemo(CollapsingSegmentControlPicker);
