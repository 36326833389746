import * as React from 'react';
const EctoplannerIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="Layer_1"
    width={17.29}
    height={15.587}
    x={0}
    y={0}
  >
    <style id="style3155" type="text/css">
      {'.st1{fill-rule:evenodd;clip-rule:evenodd;}'}
    </style>
    <g id="Pictogram" transform="matrix(.26197 0 0 .26197 -3.93 -4.847)">
      <path
        id="Date"
        d="M79 22h-4.5v-2c0-.8-.7-1.5-1.5-1.5s-1.5.7-1.5 1.5v2h-47v-2c0-.8-.7-1.5-1.5-1.5s-1.5.7-1.5 1.5v2H17c-1.1 0-2 .9-2 2v52c0 1.1.9 2 2 2h62c1.1 0 2-.9 2-2V24c0-1.1-.9-2-2-2zm0 54H17V28h62z"
      />
    </g>
    <g id="Layer_3" transform="matrix(.26197 0 0 .26197 -3.93 -4.847)">
      <g id="g3167">
        <g id="g3161">
          <path
            id="path3159"
            d="M36.7 37c-2 0-3.6 1.7-3.6 3.7v4.8H35l-3.2 5.7-3.2-5.7h1.9v-4.8c0-3.4 2.5-6.1 5.8-6.4-1.3-.8-2.8-1.3-4.4-1.3-4.6 0-8.4 3.7-8.4 8.4S31.8 60 31.8 60s8.4-14 8.4-18.7c0-.9-.1-1.7-.4-2.5-.6-1.1-1.8-1.8-3.1-1.8Z"
            className="st1"
          />
        </g>
        <g id="g3165">
          <path
            id="path3163"
            d="M43.6 44s-8.4 14-8.4 18.7c0 .4.1 1 .1 1.4.5 1.4 1.8 2.3 3.4 2.3 2 0 3.6-1.7 3.6-3.7v-4.4h-1.9l3.2-5.7 3.2 5.7H45v4.4c0 3.6-2.8 6.4-6.3 6.4h-.5c1.5 1.2 3.3 2 5.4 2 4.6 0 8.4-3.7 8.4-8.4 0-4.8-8.4-18.7-8.4-18.7Z"
            style={{
              fillRule: 'evenodd',
              clipRule: 'evenodd'
            }}
          />
        </g>
      </g>
    </g>
    <g id="g3176" transform="matrix(.26197 0 0 .26197 -3.93 -4.847)">
      <path id="rect3170" d="M48.7 32.7h7.1v7.1h-7.1z" className="st1" />
      <path id="rect3172" d="M58.2 32.7h7.1v7.1h-7.1z" className="st1" />
      <path id="rect3174" d="M67.9 32.7H75v7.1h-7.1z" className="st1" />
    </g>
    <g id="g3182" transform="matrix(.26197 0 0 .26197 -3.93 -4.847)">
      <path id="rect3178" d="M58.2 42.1h7.1v7.1h-7.1z" className="st1" />
      <path id="rect3180" d="M67.9 42.1H75v7.1h-7.1z" className="st1" />
    </g>
    <g id="g3188" transform="matrix(.26197 0 0 .26197 -3.93 -4.847)">
      <path id="rect3184" d="M58.2 51.6h7.1v7.1h-7.1z" className="st1" />
      <path id="rect3186" d="M67.9 51.6H75v7.1h-7.1z" className="st1" />
    </g>
    <g id="g3192" transform="matrix(.26197 0 0 .26197 -3.93 -4.847)">
      <path id="rect3190" d="M67.9 61.1H75v7.1h-7.1z" className="st1" />
    </g>
  </svg>
);
export default EctoplannerIcon;
