import _ from 'lodash';
import React, { useMemo } from 'react';
import animations from 'ecto-common/lib/styles/variables/animations';
import { formatNumberUnit } from 'ecto-common/lib/utils/stringUtils';
import CommonGraphOptions from 'ecto-common/lib/Graph/CommonGraphOptions';
import StockChart from 'ecto-common/lib/Charts/StockChart';
import { yAxisFormatter } from 'ecto-common/lib/SignalSelector/ChartUtils';
import { Highcharts } from 'ecto-common/lib/Highcharts/Highcharts';
import { GaugeGraphProps } from 'ecto-common/lib/Graph/GaugeGraph';
import { numDecimalsForUnit } from '../Charts/UnitUtil';

const gaugeOptions: Highcharts.Options = {
  ...CommonGraphOptions,

  tooltip: {
    enabled: true,
    split: false,
    formatter: function () {
      return '' + this.y;
    }
  },

  chart: {
    type: 'column'
  },
  legend: {
    enabled: false
  },
  xAxis: {
    crosshair: false,
    visible: false,
    labels: {
      enabled: false
    }
  },

  yAxis: {
    crosshair: false,
    offset: 30,
    labels: {
      formatter: yAxisFormatter
    }
  },
  plotOptions: {
    series: {
      animation: {
        duration: parseFloat(animations.defaultSpeed) * 1000
      }
    },
    column: {
      pointPadding: 0.01,
      groupPadding: 0
    }
  }
};

const BarGaugeGraph = ({
  value,
  min,
  max,
  hideUnit,
  unit,
  color,
  isLoading = false,
  hasError = false,
  numberOfDecimals
}: GaugeGraphProps) => {
  const actualUnit = hideUnit ? '' : unit;

  const config: Highcharts.Options = useMemo(() => {
    return _.merge({}, gaugeOptions, {
      tooltip: {
        formatter: function () {
          return formatNumberUnit(
            this.y,
            actualUnit,
            numberOfDecimals != null
              ? numberOfDecimals
              : numDecimalsForUnit(actualUnit)
          );
        }
      },
      yAxis: {
        min,
        max,
        showLastLabel: true,
        labels: {
          formatter: function () {
            return formatNumberUnit(
              this.value,
              actualUnit,
              numberOfDecimals != null
                ? numberOfDecimals
                : numDecimalsForUnit(actualUnit)
            );
          }
        }
      },
      series: [
        {
          name: actualUnit,
          data: _.compact([value]),
          color,
          dataLabels: {
            enabled: true,
            formatter: function () {
              return formatNumberUnit(
                this.y,
                actualUnit,
                numberOfDecimals != null
                  ? numberOfDecimals
                  : numDecimalsForUnit(actualUnit)
              );
            }
          }
        }
      ]
    });
  }, [min, max, actualUnit, value, color, numberOfDecimals]);

  return (
    <StockChart config={config} hasError={hasError} isLoading={isLoading} />
  );
};

export default React.memo(BarGaugeGraph);
