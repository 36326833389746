import React, { useMemo } from 'react';
import { debounce } from 'lodash';
import classNames from 'classnames';

import T from 'ecto-common/lib/lang/Language';
import TextInput from 'ecto-common/lib/TextInput/TextInput';
import Icons from 'ecto-common/lib/Icons/Icons';

import styles from './TableSearchInput.module.css';
import { DEFAULT_SEARCH_DEBOUNCE_TIME_MS } from 'ecto-common/lib/utils/constants';

interface TableSearchInputProps {
  className?: string;
  searchString?: string;
  searchPlaceholder?: string;
  onSearch(searchTerm: string): void;
  children?: React.ReactNode;
  disabled?: boolean;
}

const TableSearchInput = ({
  searchPlaceholder = T.common.search.placeholder,
  searchString,
  className,
  children,
  disabled,
  onSearch
}: TableSearchInputProps) => {
  const _onSearch = useMemo(
    () => debounce(onSearch, DEFAULT_SEARCH_DEBOUNCE_TIME_MS),
    [onSearch]
  );

  return (
    <div className={classNames(styles.searchContainer, className)}>
      <TextInput
        wrapperClassName={styles.searchField}
        autoFocus={!disabled}
        disabled={disabled}
        placeholder={searchPlaceholder}
        value={searchString}
        icon={<Icons.Search />}
        onChange={(event) => _onSearch(event.target.value)}
      />
      {children}
    </div>
  );
};

export default React.memo(TableSearchInput);
