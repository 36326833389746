import React, { useCallback } from 'react';
import styles from './UploadFile.module.css';
import _ from 'lodash';
import T from '../lang/Language';
import { getButtonCSSStyles } from '../Button/Button';

export type UploadFileType = File;

const UploadFile = ({
  file,
  onFileUpload,
  isIconButton,
  disabled,
  isLoading,
  children
}: {
  file: UploadFileType;
  onFileUpload: (file: UploadFileType) => void;
  isIconButton?: boolean;
  isLoading?: boolean;
  children?: React.ReactNode;
  disabled?: boolean;
}) => {
  const showFile = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();

      const _file = _.head((event.target as HTMLInputElement).files);
      onFileUpload(_file);
      // Reset the input value to allow the same file to be selected again
      event.target.value = null;
    },
    [onFileUpload]
  );

  return (
    <>
      <label
        className={getButtonCSSStyles(
          isLoading,
          disabled,
          isIconButton,
          false, // hasIconButtonBorder
          false, // compact
          false, // useFullWidth
          styles.uploadLabel // classname
        )}
      >
        {children ?? T.common.selectfile}
        <input className={styles.input} type="file" onChange={showFile} />
      </label>
      <span>{file?.name}</span>
    </>
  );
};
export default UploadFile;
