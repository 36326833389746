import { useContext, useMemo } from 'react';
import _ from 'lodash';
import { getNodeFromMap } from 'ecto-common/lib/utils/locationUtils';
import { ROOT_NODE_ID } from 'ecto-common/lib/constants';
import {
  PromiseCacheContext,
  batchedGetSignalsForNodesPromise
} from 'ecto-common/lib/Dashboard/datasources/signalUtils';
import { ApiContextSettings } from '../API/APIUtils';
import {
  EquipmentResponseModel,
  NodeEquipmentResponseModel,
  SignalProviderByNodeResponseModel,
  SignalResponseModel
} from 'ecto-common/lib/API/APIGen';
import { SingleGridNode } from 'ecto-common/lib/types/EctoCommonTypes';
import { useQuery } from '@tanstack/react-query';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';

/**
 * Creates a mapping from signalId to signal and signalProviderId to signalProvider and signalId to signalProviderId
 *
 * @param response
 * @returns {signalProviders, signals, signalIdToProviderId, nodeIdToSignal}
 * @param equipmentMap Key mapped list over given equipments
 * @param grid specifies which grid that is currently active
 */

export type CreateSignalAndSignalProviderMappingResult = {
  signalProviders: Record<string, SignalProviderByNodeResponseModel>;
  signalIdToProviderId: Record<string, string>;
  nodeIdToSignal: Record<string, Record<string, SignalResponseModel>>;
  signals: Record<string, SignalResponseModel>;
};

export const createSignalAndSignalProviderMapping = (
  signalProvidersWithSignals: SignalProviderByNodeResponseModel[],
  equipmentMap: Record<string, EquipmentResponseModel>
): CreateSignalAndSignalProviderMappingResult => {
  return _.reduce(
    signalProvidersWithSignals,
    (mapping, signalProvider) => {
      mapping.signalProviders[signalProvider.signalProviderId] = signalProvider;
      for (const signal of signalProvider.signals) {
        mapping.signals[signal.signalId] = signal;
      }

      mapping.nodeIdToSignal = _.reduce(
        signalProvider.nodeIds,
        (dict, nodeId) => {
          const key = getNodeFromMap(equipmentMap, nodeId)?.nodeId ?? nodeId;

          if (dict[key] == null) {
            dict[key] = {};
          }

          const signalObj = dict[key];

          for (const signal of signalProvider.signals) {
            signalObj[signal.signalId] = signal;
          }

          return dict;
        },
        mapping.nodeIdToSignal ??
          ({} as Record<string, Record<string, SignalResponseModel>>)
      );

      mapping.signalIdToProviderId = _.reduce(
        signalProvider.signals,
        (signalIdToProviderId, { signalId }) => {
          signalIdToProviderId[signalId] = signalProvider.signalProviderId;
          return signalIdToProviderId;
        },
        mapping.signalIdToProviderId
      );

      return mapping;
    },
    {
      signalProviders: {},
      signals: {},
      signalIdToProviderId: {},
      nodeIdToSignal: {}
    } as CreateSignalAndSignalProviderMappingResult
  );
};

/**
 * List all node ids including equipment ids from a list of nodes.
 * It will NOT traverse child nodes.
 * @param nodes list of nodes
 * @returns [<guid>] node ids and equipments id of all the nodes
 */
export const nodeIdsWithEquipmentIds = (
  nodes: Partial<SingleGridNode & NodeEquipmentResponseModel>[]
) => {
  return _.flatMap(nodes, (node) => {
    const equipmentId = node?.equipmentId;
    const nodeId = node?.nodeId;
    if (!_.startsWith(nodeId, ROOT_NODE_ID) && (nodeId || equipmentId)) {
      if (equipmentId) {
        return [equipmentId];
      }
      return [nodeId, ..._.map(node.equipments, 'equipmentId')];
    }
    return [];
  });
};

const getSignalInfoForNodes = (
  contextSettings: ApiContextSettings,
  ids: string[],
  cacheContext: PromiseCacheContext
) => {
  return batchedGetSignalsForNodesPromise(contextSettings, cacheContext)(ids);
};

/**
 * Returns all available signals and signal providers available to the node
 * @returns [isLoading: boolean, error: boolean, result: { signalProviders: {}, signals: {}, signalIdToProviderId: {} }, cancel: function]
 */
export const useAvailableSignalsForNodes = (
  nodes: SingleGridNode[] | EquipmentResponseModel[],
  cacheContext: PromiseCacheContext,
  equipmentMap: Record<string, EquipmentResponseModel>
): [
  isLoading: boolean,
  error: boolean,
  result: CreateSignalAndSignalProviderMappingResult
] => {
  const ids = useMemo(() => {
    return nodeIdsWithEquipmentIds(nodes);
  }, [nodes]);

  const { contextSettings } = useContext(TenantContext);

  const queryEnabled = !_.isEmpty(ids);
  const getSignalInfoQuery = useQuery({
    queryKey: ['useAvailableSignalsGetSignalInfoForNodes', ids],

    queryFn: () => {
      return getSignalInfoForNodes(contextSettings, ids, cacheContext);
    },

    enabled: queryEnabled
  });

  const result = useMemo(() => {
    if (getSignalInfoQuery.data == null) {
      return null;
    }

    return createSignalAndSignalProviderMapping(
      getSignalInfoQuery.data as unknown as SignalProviderByNodeResponseModel[],
      equipmentMap
    );
  }, [equipmentMap, getSignalInfoQuery.data]);

  const isLoading = queryEnabled && getSignalInfoQuery.isLoading;

  return [isLoading, getSignalInfoQuery.isError, result];
};
