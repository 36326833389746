import React from 'react';
import ReactSelect, { GroupBase, Props, StylesConfig } from 'react-select';

import './Select.module.css';
import zindex from '../styles/variables/zindex';
import { typedMemo } from 'ecto-common/lib/utils/typescriptUtils';
import { components } from 'react-select';
import styles from './Select.module.css';

export type GenericSelectOption<ValueType = string> = {
  label: React.ReactNode;
  value: ValueType;
};

export const useLabelSelectOptions = <ItemType,>(
  label: string,
  value: GenericSelectOption<ItemType>,
  placeholderLabel: string = null,
  placeHolderValue: string = null
) => {
  // eslint-disable-next-line react/prop-types
  const SingleValue = ({
    children: _children,
    ...props
  }: {
    children: React.ReactNode;
  }) => (
    // @ts-ignore-next-line
    <components.SingleValue {...props}>
      <label className={styles.label}>{label}</label> {value.label}
    </components.SingleValue>
  );

  // eslint-disable-next-line react/prop-types
  const Placeholder =
    placeholderLabel != null
      ? ({ children: _children, ...props }: { children: React.ReactNode }) => (
          // @ts-ignore-next-line
          <components.Placeholder {...props}>
            <label className={styles.label}>{placeholderLabel}</label>{' '}
            {placeHolderValue}
          </components.Placeholder>
        )
      : undefined;

  return {
    components: {
      SingleValue,
      Placeholder
    }
  };
};

export type SelectProps<
  ValueType = GenericSelectOption,
  IsMulti extends boolean = false
> = Props<ValueType, IsMulti> & {
  menuPortalTarget?: HTMLElement;
  /**
   * Default placement of the menu in relation to the control. 'auto' will flip when there isn't enough space below the control.
   */
  disabled?: boolean;
  isDisabled?: boolean;
  additionalStyles?: StylesConfig<ValueType, IsMulti, GroupBase<ValueType>>;
};

/**
 * This component should be used when you need to have a dropdown select button. It is a thin wrapper over react-select (https://react-select.com) so take a look at their site for more documentation. We basically only add styling and make sure that it is rendered with a portal target for correct overlays.
 */
function Select<
  ValueType = GenericSelectOption,
  IsMulti extends boolean = false
>({
  menuPortalTarget,
  menuPlacement = 'auto',
  disabled,
  isDisabled,
  additionalStyles = null,
  ...otherProps
}: SelectProps<ValueType, IsMulti>) {
  const _disabled = isDisabled == null ? disabled : isDisabled;
  const _menuPortalTarget =
    menuPortalTarget !== undefined ? menuPortalTarget : document.body;

  return (
    <ReactSelect<ValueType, IsMulti>
      menuPortalTarget={_menuPortalTarget}
      tabSelectsValue={false}
      classNamePrefix="Select"
      menuShouldScrollIntoView={false}
      menuPlacement={menuPlacement}
      isDisabled={_disabled}
      styles={{
        ...additionalStyles,
        menuPortal: (base) => ({ ...base, zIndex: zindex.modalOverlayZIndex })
      }}
      {...otherProps}
    />
  );
}

export default typedMemo(Select);
