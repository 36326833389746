import { getLatestDataPoint } from 'ecto-common/lib/SignalsTable/signalsTableUtils';
import { useCallback, useContext } from 'react';
import {
  editablePowerControlSignalTypes,
  SignalTypeIds
} from 'ecto-common/lib/utils/constants';
import _ from 'lodash';
import APIGen, {
  SetSignalWithAuditRequestModel,
  SignalProviderType
} from 'ecto-common/lib/API/APIGen';
import { SignalResponseModel } from 'ecto-common/lib/API/APIGen';
import {
  LastSignalValuesDataSet,
  LastSignalValuesResult
} from 'ecto-common/lib/hooks/useLatestSignalValues';
import { SignalProviderSignalWithProviderResponseModel } from 'ecto-common/lib/types/EctoCommonTypes';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';
import { useMutation } from '@tanstack/react-query';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';

/**
 * @typedef {Object} SignalProvider
 * @property {string} signalProviderId
 */

/**
 * @typedef {Object} Signal
 * @property {string} signalId - Unique signal id
 * @property {string} signalTypeId - Type id
 * @property {SignalProvider} signalProvider The provider that holds this signal
 * @property {number} value - Value to set
 */

/**
 * @typedef {Object.<string, Signal>} SignalValuesById
 */

/**
 * Get last value from signal
 * @param signal
 * @returns {*}
 */
const getLatestValue = (signal: LastSignalValuesDataSet) =>
  getLatestDataPoint(signal)?.value;

const EMPTY_ARRAY: SignalResponseModel[] = [];
const EMPTY_OBJECT: LastSignalValuesResult = {};

export const SET_SIGNAL_ERROR_DEVICE = 'DEVICE';
export const SET_SIGNAL_ERROR_DEVICE_NO_DEVICE = 'DEVICE_NO_DEVICE';

export type SetSignalDeviceError = {
  type:
    | typeof SET_SIGNAL_ERROR_DEVICE
    | typeof SET_SIGNAL_ERROR_DEVICE_NO_DEVICE;
  data: unknown;
};

/**
 * Normalize a set device signal value to either success or fail.
 * The device api returns 200 OK and then a success param inside the call.
 * @param {function} apiCall The Promise call
 * @param args
 * @returns {*}
 */
const normalizeResponseDeviceSetSignalApi = (
  contextSettings: ApiContextSettings,
  apiCall: (
    contextSettings: ApiContextSettings,
    ...args: unknown[]
  ) => Promise<unknown>,
  ...args: unknown[]
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return apiCall(contextSettings, ...args).then((result: any) => {
    if (_.some(result, { success: false })) {
      throw {
        type: SET_SIGNAL_ERROR_DEVICE,
        data: result
      };
    } else if (result.length === 0) {
      throw {
        type: SET_SIGNAL_ERROR_DEVICE_NO_DEVICE,
        data: null
      };
    }

    return Promise.resolve();
  });
};

/**
 * Set device signal value
 * @param {Signal} signal
 * @returns {Promise}
 */
const setDeviceSignal = (
  contextSettings: ApiContextSettings,
  signal: SignalResponseModelWithValue & { value: number; oldValue: number },
  message?: string
) => {
  return normalizeResponseDeviceSetSignalApi(
    contextSettings,
    APIGen.Devices.setSignalWithAudit.promise,
    {
      signalId: signal.signalId,
      value: signal.value,
      oldValue: signal.oldValue,
      message
    }
  );
};

/**
 * Set multiple device signals
 * @param {Signal[]} signals
 * @returns {*}
 */
const setDeviceSignals = (
  contextSettings: ApiContextSettings,
  signals: SignalResponseModelWithValue[],
  signalValuesById: LastSignalValuesResult,
  message?: string
) => {
  const signalValues: SetSignalWithAuditRequestModel[] = _.map(
    signals,
    (signal) => ({
      equipmentSignalId: signal.signalId,
      value: signal.value,
      oldValue: getLatestValue(signalValuesById[signal.signalId]),
      message
    })
  );
  return normalizeResponseDeviceSetSignalApi(
    contextSettings,
    APIGen.Devices.setSignalsWithAudit.promise,
    { signalValues }
  );
};

/**
 * Whether or not the signal is an equipment signal or not
 * @param {Signal} signal
 * @returns {boolean}
 */
const isEquipmentSignal = (signal: SignalResponseModelWithValue) =>
  signal.signalProvider?.signalProviderType === SignalProviderType.Equipment;

/**
 * Create a promise that sets the signal value
 * @param {Signal} signal
 * @param {Signal[]} otherSignals
 * @param {SignalValuesById} signalValuesById
 * @returns {Promise|*}
 */
const createSetSignalPromise = (
  contextSettings: ApiContextSettings,
  signal: SignalResponseModelWithValue,
  otherSignals: SignalResponseModel[],
  signalValuesById: LastSignalValuesResult,
  message?: string
) => {
  const value = signal.value;
  const oldValue = getLatestValue(signalValuesById[signal.signalId]) ?? null;

  if (editablePowerControlSignalTypes.includes(signal?.signalTypeId)) {
    const amplitudeSignal = _.find(otherSignals, [
      'signalTypeId',
      SignalTypeIds.POWER_CONTROL_AMPLITUDE_POWER_CONTROL_MUX
    ]);
    const limitSignal = _.find(otherSignals, [
      'signalTypeId',
      SignalTypeIds.POWER_CONTROL_LIMIT_POWER_CONTROL_MUX
    ]);
    let amplitudeValue =
      getLatestValue(signalValuesById[amplitudeSignal?.signalId]) ?? 0;
    let limitValue =
      getLatestValue(signalValuesById[limitSignal?.signalId]) ?? null;

    if (
      signal.signalTypeId ===
      SignalTypeIds.POWER_CONTROL_AMPLITUDE_POWER_CONTROL_MUX
    ) {
      amplitudeValue = value;
    } else if (
      signal.signalTypeId ===
      SignalTypeIds.POWER_CONTROL_LIMIT_POWER_CONTROL_MUX
    ) {
      limitValue = value;
    }

    // Not set via direct method, will not return result.success
    return APIGen.PowerControl.addLimitAndAmplitudeValuesWithAudit.promise(
      contextSettings,
      [
        {
          signalProviderId: signal.signalProvider.signalProviderId,
          limitValue,
          amplitudeValue,
          message
        }
      ],
      null
    );
  } else if (isEquipmentSignal(signal)) {
    return setDeviceSignal(
      contextSettings,
      { ...signal, value, oldValue },
      message
    );
  }

  return APIGen.Signals.addSignalValuesWithAudit.promise(
    contextSettings,
    {
      providerId: signal.signalProvider.signalProviderId,
      signalId: signal.signalId,
      value,
      oldValue,
      message
    },
    null
  );
};

/**
 *
 * @param {Signal[]} signals
 * @param {Signal[]} otherSignals
 * @param {SignalValuesById} signalValuesById
 * @returns {*}
 */
const setSignalValuesPromise = (
  contextSettings: ApiContextSettings,
  signals: SignalResponseModelWithValue[],
  otherSignals: SignalResponseModel[],
  signalValuesById: LastSignalValuesResult,
  message?: string
) => {
  // Separate signals into equipment signals and non equipment signals, because equipment signals can be updated
  // with one single api call
  const equipmentSignals = _.filter(signals, isEquipmentSignal);
  const nonEquipmentSignals = _.reject(signals, isEquipmentSignal);

  const otherSignalsPromises = _.map(nonEquipmentSignals, (signal) =>
    createSetSignalPromise(
      contextSettings,
      signal,
      otherSignals,
      signalValuesById,
      message
    )
  );
  if (_.isEmpty(equipmentSignals)) {
    return Promise.all(otherSignalsPromises);
  }

  return Promise.all([
    setDeviceSignals(
      contextSettings,
      equipmentSignals,
      signalValuesById,
      message
    ),
    ...otherSignalsPromises
  ]);
};

/**
 * @callback SetSignalValues
 * @param {Signal[]} signals
 */

/**
 * Creates a standardized setter for setting any signal value without knowing the type of the signal
 * @param {function} onSignalUpdated Called when signals where update successfully
 * @param {function} onSignalUpdateFailed Called when some or all signals did not update successfully
 * @param {Signal[]} otherSignals Used when setting power control signals
 * @param {SignalValuesById} signalValuesById Used when setting power control signals
 * @returns [{boolean} isSettingValue, {SetSignalValues} setSignalValues]
 */

export type SignalResponseModelWithValue =
  SignalProviderSignalWithProviderResponseModel & {
    value: number;
  };

type UseSignalSetterResult = [
  isSettingSignalValues: boolean,
  setSignalValues: (
    signals: SignalResponseModelWithValue[],
    message?: string
  ) => void
];
type UseSignalSetterProps = {
  onSignalUpdated?: (signals: SignalResponseModel[]) => void;
  onSignalUpdateFailed?: (error: Error, signals: SignalResponseModel[]) => void;
  otherSignals?: SignalResponseModel[];
  signalValuesById?: LastSignalValuesResult;
};

const useSignalSetter = ({
  onSignalUpdated = null,
  onSignalUpdateFailed = null,
  otherSignals = EMPTY_ARRAY,
  signalValuesById = EMPTY_OBJECT
}: UseSignalSetterProps): UseSignalSetterResult => {
  const { contextSettings } = useContext(TenantContext);

  const setValuesMutation = useMutation({
    mutationFn: ({
      signals,
      message
    }: {
      signals: SignalResponseModelWithValue[];
      message?: string;
    }) => {
      return setSignalValuesPromise(
        contextSettings,
        signals,
        otherSignals,
        signalValuesById,
        message
      );
    },

    onSuccess: (_res, signals) => {
      onSignalUpdated?.(signals.signals);
    },

    onError: (error, signals) => {
      onSignalUpdateFailed?.(error as unknown as Error, signals.signals);
    }
  });

  const setSignalValues = useCallback(
    (signals: SignalResponseModelWithValue[], message?: string) => {
      setValuesMutation.mutate({ signals, message });
    },
    [setValuesMutation]
  );

  return [setValuesMutation.isPending, setSignalValues];
};

export default useSignalSetter;
