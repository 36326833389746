import React from 'react';
import GreyButton from 'ecto-common/lib/Button/GreyButton';
import Tooltip from 'ecto-common/lib/Tooltip/Tooltip';
import classNames from 'classnames';
import styles from 'ecto-common/lib/Button/Button.module.css';
import { ButtonProps } from 'ecto-common/lib/Button/Button';

export type StandardButtonProps = ButtonProps & {
  icon?: React.ReactNode;
  tooltipText?: string;
  destructive?: boolean;
  classNames?: string;
};

const StandardButton = ({
  icon,
  children = null,
  destructive = false,
  tooltipText = null,
  isIconButton = false,
  ...props
}: StandardButtonProps) => {
  if (isIconButton) {
    let buttonContent = (
      <GreyButton
        isIconButton
        {...props}
        className={classNames(
          props.classNames,
          destructive && styles.destructive
        )}
      >
        {icon}
      </GreyButton>
    );

    if (tooltipText) {
      buttonContent = <Tooltip text={tooltipText}>{buttonContent}</Tooltip>;
    }

    return buttonContent;
  }

  return (
    <GreyButton {...props}>
      {icon} {children}
    </GreyButton>
  );
};

export default StandardButton;
