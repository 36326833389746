import { useEffect } from 'react';

export const keyboardEventIsUndo = (
  event: KeyboardEvent | React.KeyboardEvent<unknown>
) =>
  event.code === 'KeyZ' && (event.ctrlKey || event.metaKey) && !event.shiftKey;
export const keyboardEventIsRedo = (
  event: KeyboardEvent | React.KeyboardEvent<unknown>
) =>
  (event.code === 'KeyZ' &&
    (event.ctrlKey || event.metaKey) &&
    event.shiftKey) || // MacOS
  (event.code === 'KeyY' && event.ctrlKey);

const useUndoShortcuts = (
  undo: () => void,
  redo: () => void,
  enabled = true
) => {
  useEffect(() => {
    if (!enabled) {
      return;
    }

    const handleUndoRedoKeyboard = (event: KeyboardEvent) => {
      if (keyboardEventIsUndo(event)) {
        undo();
      }
      if (keyboardEventIsRedo(event)) {
        redo();
      }
    };

    window.addEventListener('keydown', handleUndoRedoKeyboard);

    return () => {
      window.removeEventListener('keydown', handleUndoRedoKeyboard);
    };
  }, [undo, redo, enabled]);
};

export default useUndoShortcuts;
