import React, { useCallback } from 'react';
import classNames from 'classnames';
import styles from './ProcessMapObjectViews.module.css';
import {
  ProcessMapRectObject,
  ProcessMapRectResizeArea,
  ProcessMapRectResizeAreas,
  connectionCircleRadiusPixels
} from 'ecto-common/lib/ProcessMap/ProcessMapViewConstants';
import colors from 'ecto-common/lib/styles/variables/colors';

type ProcessMapRectViewProps = {
  node: ProcessMapRectObject;
  isDragging: boolean;
  isHovering: boolean;
  fillColorFromRule?: string;
  onMouseOver?: (event: MouseEvent) => void;
  onMouseOut?: (event: MouseEvent) => void;
  onClick?: (event: MouseEvent) => void;
};

export const ProcessMapRectView = React.memo(
  ({
    node,
    isDragging,
    isHovering,
    fillColorFromRule,
    onMouseOver,
    onMouseOut,
    onClick
  }: ProcessMapRectViewProps) => {
    const rect = node.rects[0];
    return (
      <rect
        className={classNames(
          isDragging && styles.dragging,
          isHovering && styles.hovering
        )}
        x={rect.centerX - rect.width / 2.0}
        y={rect.centerY - rect.height / 2.0}
        width={rect.width}
        height={rect.height}
        stroke={node.strokeColor}
        strokeWidth={node.strokeWidth}
        fill={fillColorFromRule ?? node.fillColor ?? 'transparent'}
        rx={node.cornerRadius ?? 0}
        ry={node.cornerRadius ?? 0}
        shapeRendering="auto"
        onMouseOver={(event) => onMouseOver?.(event.nativeEvent)}
        onMouseOut={(event) => onMouseOut?.(event.nativeEvent)}
        onClick={(event) => onClick?.(event.nativeEvent)}
        style={{ cursor: onClick ? 'pointer' : 'default' }}
      />
    );
  }
);

type ProcessMapRectViewOverlayProps = {
  zoom: number;
  objectIndex: number;
  node: ProcessMapRectObject;
  setRectResizeElement: (
    objectIndex: number,
    rectIndex: number,
    corner: ProcessMapRectResizeArea
  ) => void;
};

export const ProcessMapRectViewOverlay = React.memo(
  ({
    objectIndex,
    node,
    setRectResizeElement,
    zoom
  }: ProcessMapRectViewOverlayProps) => {
    const rect = node.rects[0];
    const topLeftX = rect.centerX - rect.width / 2.0;
    const topLeftY = rect.centerY - rect.height / 2.0;

    const onMouseDown = useCallback(
      (e: React.MouseEvent<SVGCircleElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();
        setRectResizeElement(
          objectIndex,
          0,
          e.currentTarget.getAttribute('data-area') as ProcessMapRectResizeArea
        );
      },
      [objectIndex, setRectResizeElement]
    );

    const radius = connectionCircleRadiusPixels / zoom;
    return (
      <>
        <circle
          onMouseDown={onMouseDown}
          cx={topLeftX}
          cy={topLeftY}
          r={radius}
          fill={colors.accent1Color}
          data-area={ProcessMapRectResizeAreas.TopLeft}
          shapeRendering="auto"
          style={{ cursor: 'nw-resize' }}
        />
        <circle
          onMouseDown={onMouseDown}
          cx={topLeftX + rect.width}
          cy={topLeftY + rect.height}
          r={radius}
          fill={colors.accent1Color}
          shapeRendering="auto"
          data-area={ProcessMapRectResizeAreas.BottomRight}
          style={{ cursor: 'se-resize' }}
        />
      </>
    );
  }
);
