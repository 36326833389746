module.exports = {
  navBarZIndex: 500,
  innerContentZIndex: 600,
  sideBarZIndex: 600,
  modalZIndex: 1000,
  modalOverlayZIndex: 1001,
  loadingScreenZIndex: 9999,
  toastZIndex: 10000,
  tooltipZIndex: 10000
};
