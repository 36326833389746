import { decodeTUIOPacket } from 'js/views/TableView/TUIOParser';
import _ from 'lodash';
import ReconnectingWebSocket from 'reconnecting-websocket';
// @ts-ignore-next-line
import io from 'socket.io-client';

type TUIOObject = {
  sessionId: number;
  classId: number;
  x: number;
  y: number;
  angle: number;
};

type TUIOListener = {
  onObjectStateChanged: (objects: TUIOObjectState) => void;
  onCursorDown: (x: number, y: number) => void;
  onCursorUp: (x: number, y: number) => void;
  onCursorMove: (x: number, y: number) => void;
};

type TUIOObjectState = {
  activeObjects: TUIOObject[];
};

type SocketMode = 'websocket' | 'socketio';

export class TUIOIntegration {
  listener: TUIOListener;
  socketIO: any = null;
  webSocket: ReconnectingWebSocket = null;

  cursorState = {
    currentCursorId: -1,
    lastX: -1,
    lastY: -1
  };

  state: TUIOObjectState = {
    activeObjects: []
  };

  constructor(listener: TUIOListener, socketMode: SocketMode, host: string) {
    this.listener = listener;

    if (socketMode === 'websocket') {
      this.webSocket = new ReconnectingWebSocket(host);

      this.webSocket.onopen = () => {
        console.log('TUIO web socket connected to', host);
      };

      this.webSocket.onmessage = (message) => {
        if (message.data instanceof Blob) {
          const reader = new FileReader();

          reader.onload = () => {
            const u8Array = new Uint8Array(reader.result as ArrayBuffer);
            this.handleMessage(decodeTUIOPacket(u8Array));
          };

          reader.readAsArrayBuffer(message.data);
        }
      };
    } else if (socketMode == 'socketio') {
      this.socketIO = io(host);

      this.socketIO.on('osc', (message: any[]) => {
        this.handleMessage(message);
      });

      this.socketIO.on('connect', () => {
        console.log('TUIO Socket IO connected to', host);
      });

      this.socketIO.on('disconnect', () => {
        console.log('TUIO Socket IO disconnected');
      });

      this.socketIO.on('connect_error', (error: any) => {
        console.log('TUIO Socket IO failed to connect', error);
      });
    }
  }

  disconnectSocket() {
    this.socketIO?.disconnect();
    this.listener = null;
  }

  handleMessage(message: any[]) {
    // let messageType = message[0];
    // let timestamp = message[1];

    for (let i = 2; i < message.length; i++) {
      const event = message[i];
      const eventType = event[0];
      const eventAction = event[1];
      if (eventType === '/tuio/2Dcur') {
        switch (eventAction) {
          case 'source':
            break;
          case 'alive':
            {
              if (
                this.cursorState.currentCursorId !== -1 &&
                !event.includes(this.cursorState.currentCursorId)
              ) {
                this.listener.onCursorUp(
                  this.cursorState.lastX,
                  this.cursorState.lastY
                );
                this.cursorState.currentCursorId = -1;
                this.cursorState.lastX = -1;
                this.cursorState.lastY = -1;
              }

              if (this.cursorState.currentCursorId === -1 && event.length > 2) {
                this.cursorState.currentCursorId = _.min(event.slice(2));
              }
            }
            break;
          case 'fseq':
            break;
          case 'set': {
            const cursorId = event[2];
            if (this.cursorState.currentCursorId !== -1) {
              if (cursorId !== this.cursorState.currentCursorId) {
                return;
              }

              const x = event[3] * window.screen.width - window.screenX;
              const y = event[4] * window.screen.height - window.screenY;

              if (this.cursorState.lastX === -1) {
                this.listener.onCursorDown(x, y);
              } else {
                this.listener.onCursorMove(x, y);
              }

              this.cursorState.lastX = x;
              this.cursorState.lastY = y;
            }

            break;
          }
          default:
            console.log('Unknown cur type', eventAction);
            break;
        }
      } else if (eventType === '/tuio/2Dobj') {
        switch (eventAction) {
          case 'source':
            break;
          case 'alive':
            {
              let aliveSessionIds: number[] = event.slice(2);
              this.state = {
                activeObjects: _.filter(this.state.activeObjects, (point) =>
                  aliveSessionIds.includes(point.sessionId)
                )
              };
              this.listener.onObjectStateChanged(this.state);
            }
            break;
          case 'fseq':
            break;
          case 'set':
            {
              const [_unused1, _unused2, sessionId, classId, x, y, angle] =
                event;
              let newActivePoints = [...this.state.activeObjects];
              let point = newActivePoints.find(
                (activePoint) => activePoint.sessionId === sessionId
              );
              if (point == null) {
                point = {
                  sessionId,
                  classId,
                  x,
                  y,
                  angle
                };

                newActivePoints.push(point);
              }

              point.x = x * window.screen.width - window.screenX;
              point.y = y * window.screen.height - window.screenY;

              point.angle = angle;
              this.state = {
                activeObjects: newActivePoints
              };

              this.listener.onObjectStateChanged(this.state);
            }
            break;
          default:
            console.log('Unknown obj type', eventAction, eventType);
        }
      }
    }
  }
}
