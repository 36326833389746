import { useRef, useContext, useEffect, useMemo } from 'react';
import TimeRangeContext from 'ecto-common/lib/Dashboard/context/TimeRangeContext';
import UUID from 'uuidjs';
import { dateRangeFromTimeRange } from 'ecto-common/lib/types/TimeRangeOptions';
import { settingsFromTimeRange } from 'ecto-common/lib/types/TimeRangeOptions';
import _ from 'lodash';
import { hoursRange } from 'ecto-common/lib/Dashboard/datasources/SignalValuesDataSource';
import { SamplingInterval, AggregationType } from '../../API/APIGen';
import { TimeRangeOptions } from '../../types/TimeRangeOptions';
import { Moment } from 'moment';
import { SignalInputType } from 'ecto-common/lib/Dashboard/datasources/LastSignalValuesDataSource';

type UseTimeRangeResult = {
  dateFrom: Moment;
  dateTo: Moment;
  timeRangeOption: TimeRangeOptions | null;
  samplingInterval: SamplingInterval;
  aggregation: AggregationType;
  referenceDate: Moment;
};

/**
 * Calculate a time range from either time range context or a predefined time range
 * @param hoursBackward default hoursBackward if no setting set in signals
 * @param hoursForward default hoursForward
 * @param signals signal setting
 * @param samplingInterval default sampling interval, determines if the time range should be visible or not
 * @param aggregation default aggregation, determines if the time range should be visible or not
 * @param specifiedTimeRanges all of the time ranges the consumer has specific settings for
 * @param reloadTrigger triggers recalculation of dateFrom and dateTo
 * @returns {{dateTo: *|moment.Moment|moment.Moment, timeRangeOption: null, dateFrom: *|moment.Moment}}
 */
const useTimeRange = (
  hoursBackward: number,
  hoursForward: number,
  signals: SignalInputType[],
  samplingInterval: SamplingInterval,
  aggregation: AggregationType,
  specifiedTimeRanges: TimeRangeOptions[],
  reloadTrigger: number
): UseTimeRangeResult => {
  const timeRange = useContext(TimeRangeContext);
  const timeRangeOption = timeRange?.timeRangeOption;
  const referenceDate = timeRange?.referenceDate;

  const uniqDataSourceId = useRef(UUID.generate());
  const removeTimeRangeConsumer = timeRange?.removeTimeRangeConsumer;
  useEffect(() => {
    const id = uniqDataSourceId.current;
    return () => {
      removeTimeRangeConsumer?.(id);
    };
  }, [removeTimeRangeConsumer]);

  const { dateFrom, dateTo } = useMemo(() => {
    if (hoursBackward == null) {
      timeRange?.addTimeRangeConsumer(
        uniqDataSourceId.current,
        specifiedTimeRanges
      );
      return dateRangeFromTimeRange(timeRangeOption, referenceDate);
    }
    // Even if we have an hoursBackward, there might be signal settings that require
    // time period to be set in order to be visible; check signal settings
    // or if samplingInterval was not set or aggregation was not set
    if (
      _.some(signals, 'timeRange') ||
      samplingInterval == null ||
      aggregation == null
    ) {
      timeRange?.addTimeRangeConsumer(
        uniqDataSourceId.current,
        specifiedTimeRanges
      );
      // If there is a signal setting that matches the current selected time range
      // then use date range from the time range instead of the panels hoursBackward
      if (_.some(signals, { timeRange: timeRange?.timeRangeOption })) {
        return dateRangeFromTimeRange(timeRangeOption, referenceDate);
      }
    } else {
      timeRange?.removeTimeRangeConsumer(uniqDataSourceId.current);
    }

    // This is just to make sure eslint doesn't complain about reloadTrigger in dependencies
    _.noop(reloadTrigger);

    return hoursRange(hoursBackward, hoursForward);
  }, [
    timeRange,
    timeRangeOption,
    referenceDate,
    signals,
    hoursBackward,
    hoursForward,
    samplingInterval,
    aggregation,
    specifiedTimeRanges,
    reloadTrigger
  ]);

  return useMemo(() => {
    const {
      samplingInterval: timeRangeSamplingInterval,
      aggregation: timeRangeAggregation
    } = settingsFromTimeRange(timeRangeOption);

    // Whether or not to override values of sampling interval and aggregation
    const useTimeRangeValues = samplingInterval == null || aggregation == null;

    const _samplingInterval =
      (useTimeRangeValues ? timeRangeSamplingInterval : samplingInterval) ??
      timeRangeSamplingInterval;
    const _aggregation =
      (useTimeRangeValues ? timeRangeAggregation : aggregation) ??
      timeRangeAggregation;

    return {
      dateFrom,
      dateTo,
      timeRangeOption,
      samplingInterval: _samplingInterval,
      aggregation: _aggregation,
      referenceDate
    };
  }, [
    dateFrom,
    dateTo,
    timeRangeOption,
    samplingInterval,
    aggregation,
    referenceDate
  ]);
};

export default useTimeRange;
