export const SET_LOCATION_PICKER_OPEN = 'SET_LOCATION_PICKER_OPEN';
export const SET_CURRENT_NODE = 'SET_CURRENT_NODE';
export const CLEAR_REDUX_STATE = 'CLEAR_REDUX_STATE';
export const SET_SIGNAL_TYPES = 'SET_SIGNAL_TYPES';
export const PATCH_SIGNAL_TYPES = 'PATCH_SIGNAL_TYPES';
export const PATCH_SIGNAL_TYPE_FOLDERS = 'PATCH_SIGNAL_TYPE_FOLDERS';
export const SET_SIGNAL_TYPE_FOLDERS = 'SET_SIGNAL_TYPE_FOLDERS';
export const SET_ENUMS = 'SET_ENUMS';
export const LOCATION_CHANGE = 'LOCATION_CHANGE';
export const SET_NODES = 'SET_NODES';
export const PATCH_NODES = 'PATCH_NODES';
export const SET_EQUIPMENT_TYPES = 'SET_EQUIPMENT_TYPES';
export const PATCH_EQUIPMENT_TYPES = 'PATCH_EQUIPMENT_TYPES';
export const TOGGLE_SIDEBAR_EXPANDED = 'TOGGLE_SIDEBAR_EXPANDED';
export const SET_NODE_TAGS = 'SET_NODE_TAGS';
export const SET_UI_LANGUAGE = 'SET_UI_LANGUAGE';
export const DELETE_NODES_WITH_IDS = 'DELETE_NODES_WITH_IDS';
