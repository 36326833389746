import React, { MouseEventHandler, useCallback, useState } from 'react';
import Icons from 'ecto-common/lib/Icons/Icons';
import styles from './ModelFormSection.module.css';
import BorderlessButton from 'ecto-common/lib/Button/BorderlessButton';
import classNames from 'classnames';
import ModelFormSectionLines from 'ecto-common/lib/ModelForm/ModelFormSectionLines';
import _ from 'lodash';
import {
  ModelFormSectionStyle,
  ModelFormSectionType
} from 'ecto-common/lib/ModelForm/ModelPropType';
import ModelFormSelectControl from './ModelFormSelectControl';
import ModelFormSegmentControl from 'ecto-common/lib/ModelForm/ModelFormSegmentControl';
import Tooltip from 'ecto-common/lib/Tooltip/Tooltip';
import { NW } from 'ecto-common/lib/Tooltip/directions';
import ModelHelpText from 'ecto-common/lib/ModelForm/ModelHelpText';
import { ModelRenderCallbackFunction } from 'ecto-common/lib/ModelForm/ModelForm';

interface ModelFormSectionProps<ObjectType extends object> {
  section?: ModelFormSectionType<ObjectType>;
  renderChildModel?: ModelRenderCallbackFunction<ObjectType>;
  hideLabel?: boolean;
  className?: string;
  input: ObjectType;
}

type ModelFormSectionSubSectionsProps<ObjectType extends object> = {
  input: ObjectType;
  sections: ModelFormSectionType<ObjectType>[];
  subSectionsStyle?: ModelFormSectionStyle;
  renderChildModel?: ModelRenderCallbackFunction<ObjectType>;
};

const ModelFormSectionSubSections = <ObjectType extends object>({
  sections,
  renderChildModel,
  subSectionsStyle,
  input
}: ModelFormSectionSubSectionsProps<ObjectType>) => {
  switch (subSectionsStyle) {
    case ModelFormSectionStyle.SEGMENT_CONTROL:
      return (
        <ModelFormSegmentControl
          sections={sections}
          renderChildModel={renderChildModel}
          input={input}
        />
      );
    case ModelFormSectionStyle.SELECT_CONTROL:
      return (
        <ModelFormSelectControl
          sections={sections}
          renderChildModel={renderChildModel}
          sectionsTitle={null}
          input={input}
        />
      );
    default:
      return (
        <>
          {sections.map((subSection, index) => {
            return (
              (subSection.visible == null || subSection.visible(input)) && (
                <ModelFormSection
                  input={input}
                  key={index}
                  section={subSection}
                  renderChildModel={renderChildModel}
                />
              )
            );
          })}
        </>
      );
  }
};

const ModelFormSection = <ObjectType extends object>({
  section,
  renderChildModel,
  className,
  input,
  hideLabel = false
}: ModelFormSectionProps<ObjectType>) => {
  const [collapsed, setCollapsed] = useState(section.initiallyCollapsed);
  const toggleCollapsed: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      (e.target as HTMLElement)?.blur();
      setCollapsed((s) => !s);
    },
    []
  );

  let labelContent: React.ReactNode = section.label;

  if (section.helpText != null) {
    labelContent = (
      <Tooltip text={section.helpText} direction={NW} multiline withIcon>
        {labelContent}
      </Tooltip>
    );
  }

  return (
    <div
      className={classNames(
        styles.section,
        className,
        collapsed && styles.collapsed,
        section.wrapContent && styles.wrapped
      )}
    >
      {section.label && !hideLabel && !section.hideLabel && (
        <div className={styles.buttonContainer}>
          <BorderlessButton
            onClick={toggleCollapsed}
            className={styles.iconButton}
          >
            {collapsed ? (
              <Icons.NavigationArrowRight />
            ) : (
              <Icons.NavigationArrowDown />
            )}
            {labelContent}
          </BorderlessButton>
          {section.menuActions && (
            <>
              <div className={styles.flexibleSpace} />
              {section.menuActions}
            </>
          )}
        </div>
      )}
      {!collapsed && (
        <>
          {_.map(section.models, (modelInfo) =>
            renderChildModel(modelInfo.key)
          )}
          {section.lines && (
            <ModelFormSectionLines
              lines={section.lines}
              renderChildModel={renderChildModel}
            />
          )}

          {section.sections && (
            <ModelFormSectionSubSections
              input={input}
              sections={section.sections}
              subSectionsStyle={section.subSectionsStyle}
              renderChildModel={renderChildModel}
            />
          )}

          {section.footerText && (
            <ModelHelpText> {section.footerText} </ModelHelpText>
          )}
        </>
      )}
    </div>
  );
};

export default ModelFormSection;
