import React from 'react';
import classnames from 'classnames';
import './Flex.css';

type FlexProps = {
  direction?: 'row' | 'column';
  className?: string;
  id?: string;
  style?: React.CSSProperties;
  alignContent?: 'start' | 'end' | 'center' | 'between' | 'around' | 'stretch';
  alignItems?: 'start' | 'end' | 'center' | 'baseline' | 'stretch';
  justify?:
    | 'center'
    | 'start'
    | 'end'
    | 'flex-start'
    | 'flex-end'
    | 'left'
    | 'right'
    | 'normal;'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'stretch'
    | 'safe center'
    | 'unsafe center';
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
};

export default function Flex({
  direction,
  className,
  id,
  style,
  alignContent,
  alignItems,
  justify,
  children,
  onClick
}: FlexProps) {
  const useDirection = direction || 'row';
  const useJustify = justify ? `Flex--justify--${justify}` : null;
  const useAlignItems = alignItems ? `Flex--alignItems--${alignItems}` : null;
  const useAlignContent = alignContent
    ? `Flex--alignContent--${alignContent}`
    : null;
  const cssClasses = [
    'Flex',
    `Flex--${useDirection}`,
    useAlignContent,
    useAlignItems,
    useJustify,
    className
  ];

  return (
    <div
      id={id}
      className={classnames(cssClasses)}
      style={style}
      onClick={onClick}
    >
      {children}
    </div>
  );
}
