import React from 'react';
import classNames from 'classnames';
import Button, { ButtonProps } from './Button';
import styles from './Button.module.css';

const GreyButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, ...otherProps }, ref) => {
    return (
      <Button
        className={classNames(styles.greyButton, className)}
        ref={ref}
        {...otherProps}
      />
    );
  }
);

export default React.memo(GreyButton);
