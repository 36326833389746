import { formatNumberUnit } from 'ecto-common/lib/utils/stringUtils';
import { numDecimalsForUnit } from '../Charts/UnitUtil';

export const getGaugeValueText = (
  value: number,
  isLoading: boolean,
  hideUnit: boolean,
  unit: string,
  numberOfDecimals?: number
) => {
  const showLoading = value == null && isLoading;
  const showNoValue = value == null && !isLoading;

  if (showLoading) {
    return '';
  } else if (showNoValue) {
    return '-';
  }

  return formatNumberUnit(
    value,
    hideUnit ? null : unit,
    numberOfDecimals != null ? numberOfDecimals : numDecimalsForUnit(unit)
  );
};
