import MenuDrawer from './button_drawer/menu_drawer';
import { TableRect } from 'js/Table/types';
import TableP5 from 'js/Table/TableP5';
import p5 from 'p5';

interface ButtonBoundingRect {
  x: number;
  y: number;
  w: number;
  h: number;

  inside: (x: number, y: number) => boolean;
}
/**
 * An abstract definition of a button on the p5 surface that acts on clicks
 * @class SquareButton
 */
class SquareButton {
  clicks = 0;
  longPressTimeout: ReturnType<typeof setTimeout> = null;
  listeners: ((button: SquareButton) => void)[] = [];
  pressed: boolean;
  opacity: number;
  isActiveCallback: () => void;
  bounding: ButtonBoundingRect;
  _debugTouch: {
    x: number;
    y: number;
  } = null;
  name = '';
  menu: MenuDrawer = null;
  visible = true;

  constructor() {
    this.clicks = 0;
    this.longPressTimeout = null;
    this.listeners = [];
    this.pressed = false;
    this.opacity = 1;

    this.isActiveCallback = null;

    this.bounding = {
      x: 0,
      y: 0,
      w: 0,
      h: 0,
      inside: function (_x, _y) {
        return (
          _x > this.x &&
          _x < this.x + this.w &&
          _y > this.y &&
          _y < this.y + this.h
        );
      }
    };
  }

  touchStarted(x: number, y: number) {
    return this.mousePressed(x, y);
  }

  renderInit(_p5: TableP5, _imageCache: Record<string, p5.Image>) {}

  setIsActiveCallback(isActiveCallback: () => void) {
    this.isActiveCallback = isActiveCallback;
  }

  isActive() {
    if (this.isActiveCallback) {
      return this.isActiveCallback();
    }

    return true;
  }

  draw(
    _p5Instance: TableP5,
    _overrideRect?: TableRect,
    _currentComboScreen?: number
  ) {}

  addListener(listener: (button: SquareButton) => void) {
    this.listeners.push(listener);
  }

  /**
   * Determines if x, y hit the general area of this button or not.
   */
  hit(x: number, y: number) {
    return this.bounding.inside(x, y);
  }

  mousePressed(x: number, y: number) {
    let wasHit = false;

    if (this.hit(x, y)) {
      this.pressed = true;
      wasHit = true;
      this._debugTouch = { x, y };

      this.clicks++;
      this.longPressTimeout = setTimeout(() => {
        if (this.clicks > 0) {
          this.clicks = 0;
          this.longPress(this);
        }
      }, 1000);
    }

    return wasHit;
  }

  mouseDragged(_x: number, _y: number) {
    return false;
  }

  mouseReleased(x: number, y: number) {
    let consumed = false;

    if (this.hit(x, y)) {
      consumed = true;

      if (this.longPressTimeout) {
        clearTimeout(this.longPressTimeout);
        if (this.pressed && this.clicks > 0) {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const listenerArg = this;

          this.listeners.forEach((listener) => {
            listener(listenerArg);
          });
          this.clicks = 0;
        }
      }
    }

    this.pressed = false;
    this._debugTouch = undefined;

    return consumed;
  }

  longPress(_button: any) {
    console.warn(`Long press detected but not implemented for ${this.name}`);
  }
}

export default SquareButton;
