import RowCell from 'ecto-common/lib/DataTable/RowCell';
import React from 'react';
import HorizontalAlignments from 'ecto-common/lib/types/HorizontalAlign';
import { Property } from 'csstype';

const alignToFlexAlign = (align: string) => {
  switch (align) {
    case HorizontalAlignments.CENTER:
      return 'center';
    case HorizontalAlignments.RIGHT:
      return 'flex-end';
    case HorizontalAlignments.LEFT:
    default:
      return 'flex-start';
  }
};

interface DataTableCellProps {
  display?: React.ReactNode;
  rowIndex?: number;
  selectedIndex?: number;
  truncateText?: boolean;
  align?: Property.TextAlign;
}

const DataTableCell = ({
  display,
  rowIndex,
  selectedIndex,
  truncateText,
  align
}: DataTableCellProps) => {
  return (
    <RowCell
      selected={selectedIndex === rowIndex}
      tooltipEnabled={truncateText}
      truncateText={truncateText}
      style={{
        textAlign: align,
        display:
          truncateText || align === HorizontalAlignments.LEFT ? null : 'flex',
        justifyContent: alignToFlexAlign(align)
      }}
    >
      {display}
    </RowCell>
  );
};

export default React.memo(DataTableCell);
