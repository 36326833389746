import React from 'react';

import MarkdownContent from 'ecto-common/lib/Markdown/MarkdownContent';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import ModalFooter from 'ecto-common/lib/Modal/ModalFooter';
import Button from 'ecto-common/lib/Button/Button';
import T from 'ecto-common/lib/lang/Language';

import styles from 'ecto-common/lib/HelpTooltip/HelpTooltipModal.module.css';
import useHelpContent from 'ecto-common/lib/hooks/useHelpContent';

interface HelpTooltipModalFooterProps {
  onConfirmClick(): void;
  fullPageLink: React.ReactNode;
}

const HelpTooltipModalFooter = ({
  onConfirmClick,
  fullPageLink
}: HelpTooltipModalFooterProps) => (
  <ModalFooter>
    <div className={styles.helpTooltipModalFooter}>
      <span className={styles.linkToHelp}>{fullPageLink}</span>

      <Button onClick={onConfirmClick}>{T.common.done}</Button>
    </div>
  </ModalFooter>
);

interface HelpTooltipModalProps {
  dialogIsOpen: boolean;
  onConfirmClick(): void;
  helpPath: string;
}

const HelpTooltipModal = ({
  dialogIsOpen,
  onConfirmClick,
  helpPath
}: HelpTooltipModalProps) => {
  const helpContent = useHelpContent(helpPath);
  const { file, title, onLinkClick, fullPageLink } = helpContent;

  return (
    <ActionModal
      isOpen={dialogIsOpen}
      onConfirmClick={onConfirmClick}
      onModalClose={onConfirmClick}
      title={title}
      large
      className={styles.helpTooltipModal}
      footer={
        <HelpTooltipModalFooter
          onConfirmClick={onConfirmClick}
          fullPageLink={fullPageLink}
        />
      }
    >
      <MarkdownContent markdownFile={file} onClick={onLinkClick} />
    </ActionModal>
  );
};

export default HelpTooltipModal;
