import React from 'react';
import styles from './ModalFooter.module.css';

interface ModalFooterProps {
  children?: React.ReactNode;
}

/**
 * This component renders a bottom row inside a Modal. Use it to add Buttons that can control the Modal behavior.
 */
const ModalFooter = ({ children, ...otherProps }: ModalFooterProps) => (
  <div className={styles.modalFooter} {...otherProps}>
    {children}
  </div>
);

export default ModalFooter;
