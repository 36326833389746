import React, { MouseEventHandler } from 'react';
import classNames from 'classnames';
import styles from './SvgIcon.module.css';

export interface SvgIconProps {
  children?: React.ReactNode;
  viewBox?: string;
  height?: number;
  width?: number;
  className?: string;
  color?: string;
  style?: object;
  onClick?: MouseEventHandler<SVGSVGElement>;
}

const SvgIcon = ({
  children,
  className,
  color,
  style,
  width = 22,
  height = 22,
  viewBox = '0 0 24 24',
  ...otherProps
}: SvgIconProps) => {
  let colorStyle = {};

  if (color) {
    colorStyle = {
      color,
      fill: color,
      stroke: color
    };
  }

  const mergedStyles = Object.assign({}, colorStyle, style);

  return (
    <svg
      className={classNames(styles.icon, className)}
      style={mergedStyles}
      width={width}
      height={height}
      viewBox={viewBox}
      {...otherProps}
    >
      {children}
    </svg>
  );
};

export default React.memo(SvgIcon);
