import React, { Fragment, useMemo, useRef } from 'react';
import colors from 'ecto-common/lib/styles/variables/colors';
import _ from 'lodash';
import {
  ProcessMapLineObject,
  ProcessMapPendingConnectionLine,
  ProcessMapRectHandle,
  processMapDeleteConnectionButtonSize,
  lineRectHeight,
  ProcessMapLineConnection,
  ProcessMapLineCapStyles,
  ProcessMapRect,
  ProcessMapLineModes,
  processMapLineArrowLength,
  processMapLineRectLength
} from 'ecto-common/lib/ProcessMap/ProcessMapViewConstants';
import removeIcon from '../ProcessMapDeleteIcon.svg';
import {
  LineObjectPointsReturnVal,
  getLineObjectExtendedPoints
} from 'ecto-common/lib/ProcessMap/ProcessMapViewUtils';

type ProcessMapLineViewProps = {
  line: ProcessMapLineObject;
  scale: number;
};

type ProcessMapLinePoint = {
  x: number;
  y: number;
  isRounded: boolean;
};

const adjustPointForMarker = (
  p0: ProcessMapLinePoint,
  p1: ProcessMapLinePoint,
  markerLength: number,
  adjustEnd: boolean
) => {
  const normX = p1.x - p0.x;
  const normY = p1.y - p0.y;
  const normLength = Math.sqrt(normX * normX + normY * normY);
  const normDirX = normX / normLength;
  const normDirY = normY / normLength;

  if (adjustEnd) {
    p1.x -= normDirX * markerLength;
    p1.y -= normDirY * markerLength;
  } else {
    p0.x += normDirX * markerLength;
    p0.y += normDirY * markerLength;
  }
};

/**
 * Computes control points for a smooth curve between two points (`p1` and `p2`) using the Catmull-Rom spline method.
 *
 * This function returns two control points, `c1` and `c2`, which can be used to form a cubic Bezier curve to
 * create a smooth transition through the points.
 *
 * @param {Object} p0 - The point before the curve segment. Influences the curvature but isn't part of the segment.
 * @param {Object} p1 - The starting point of the curve segment.
 * @param {Object} p2 - The ending point of the curve segment.
 * @param {Object} p3 - The point after the curve segment. Influences the curvature but isn't part of the segment.
 *
 * @property {number} p0.x - X-coordinate of p0.
 * @property {number} p0.y - Y-coordinate of p0.
 *
 * ... (similarly for p1, p2, p3)
 *
 * @returns {Object} Control points for the cubic Bezier curve.
 * @property {Object} c1 - First control point.
 * @property {number} c1.x - X-coordinate of c1.
 * @property {number} c1.y - Y-coordinate of c1.
 * @property {Object} c2 - Second control point.
 * @property {number} c2.x - X-coordinate of c2.
 * @property {number} c2.y - Y-coordinate of c2.
 *
 * @example
 * const { c1, c2 } = computeControlPoints({x: 0, y: 0}, {x: 10, y: 10}, {x: 20, y: 20}, {x: 30, y: 30});
 * // Use c1 and c2 with SVG's cubic Bezier curve command to create the curve.
 */
function computeControlPoints(
  p0: ProcessMapLinePoint,
  p1: ProcessMapLinePoint,
  p2: ProcessMapLinePoint,
  p3: ProcessMapLinePoint
) {
  const c1 = {
    x: p1.x + (p2.x - p0.x) / 6,
    y: p1.y + (p2.y - p0.y) / 6
  };

  const c2 = {
    x: p2.x - (p3.x - p1.x) / 6,
    y: p2.y - (p3.y - p1.y) / 6
  };

  return { c1, c2 };
}

// Stored outside to avoid re-allocating each time
const lineObjectQuery: LineObjectPointsReturnVal = {
  p0x: 0,
  p0y: 0,
  mid0x: 0,
  mid0y: 0,
  mid1x: 0,
  mid1y: 0,
  p1x: 0,
  p1y: 0
};

export const ProcessMapLineView = React.memo(
  ({ line, scale }: ProcessMapLineViewProps) => {
    const extendedPointsRef = useRef<ProcessMapRect[]>(null);

    const pathData = useMemo(() => {
      if (line.mode === ProcessMapLineModes.Path) {
        const points: ProcessMapLinePoint[] = line.rects.map((rect) => {
          return {
            x: rect.centerX,
            y: rect.centerY,
            isRounded: !!rect.isRounded
          };
        });

        const arrowLength = processMapLineArrowLength / scale;

        if (
          line.endLineCapStyle === ProcessMapLineCapStyles.Arrow &&
          points.length >= 2
        ) {
          adjustPointForMarker(
            points[points.length - 2],
            points[points.length - 1],
            arrowLength,
            true
          );
        }

        if (
          line.startLineEndcapStyle === ProcessMapLineCapStyles.Arrow &&
          points.length >= 2
        ) {
          adjustPointForMarker(points[0], points[1], arrowLength, false);
        }

        let prevPoint: ProcessMapLinePoint = null;
        return points
          .map((point, index) => {
            const prevPointRounded = prevPoint?.isRounded;
            prevPoint = point;

            if (index === 0) {
              return `M ${point.x},${point.y}`;
            } else if (
              (point.isRounded || prevPointRounded) &&
              index >= 1 &&
              index < points.length
            ) {
              const p0 = points[index - 2] ?? points[index - 1];
              const p1 = points[index - 1];
              const p2 = points[index];
              const p3 = points[index + 1] ?? points[index];
              const { c1, c2 } = computeControlPoints(p0, p1, p2, p3);
              return `M${p1.x},${p1.y} C${c1.x},${c1.y} ${c2.x},${c2.y} ${p2.x},${p2.y}`;
            }

            return `L ${point.x},${point.y}`;
          })
          .join(' ');
      }
      if (extendedPointsRef.current == null) {
        extendedPointsRef.current = [];
      }

      getLineObjectExtendedPoints(line, true, lineObjectQuery);

      const polyLineString = `M${lineObjectQuery.p0x},${lineObjectQuery.p0y} L${lineObjectQuery.mid0x},${lineObjectQuery.mid0y} L${lineObjectQuery.mid1x},${lineObjectQuery.mid1y} ${lineObjectQuery.p1x},${lineObjectQuery.p1y}`;

      return polyLineString;
    }, [line, scale]);

    const color = line.color ?? colors.accent1Color;
    const arrowId = 'arrow-' + line.id;
    const circleId = 'rect-' + line.id;
    let markerEndUrl: string;
    let markerStartUrl: string;

    if (line.endLineCapStyle === ProcessMapLineCapStyles.Arrow) {
      markerEndUrl = 'url(#' + arrowId + ')';
    } else if (line.endLineCapStyle === ProcessMapLineCapStyles.Circle) {
      markerEndUrl = 'url(#' + circleId + ')';
    }

    if (line.startLineEndcapStyle === ProcessMapLineCapStyles.Arrow) {
      markerStartUrl = 'url(#' + arrowId + ')';
    } else if (line.startLineEndcapStyle === ProcessMapLineCapStyles.Circle) {
      markerStartUrl = 'url(#' + circleId + ')';
    }

    const markerRectSize = processMapLineRectLength;
    const markerArrowSize = processMapLineArrowLength;
    const midMarkerPoint = (markerArrowSize - 1) / 2.0;

    return (
      <>
        <defs>
          <marker
            id={arrowId}
            markerWidth={markerArrowSize}
            markerHeight={markerArrowSize}
            markerUnits="userSpaceOnUse"
            refX="0"
            refY={midMarkerPoint}
            orient="auto-start-reverse"
          >
            <polygon
              points={
                '0 0, ' +
                markerArrowSize +
                ' ' +
                midMarkerPoint +
                ', 0 ' +
                (markerArrowSize - 1)
              }
              fill={color}
              shapeRendering="auto"
            />
          </marker>
          <marker
            id={circleId}
            markerWidth={markerRectSize}
            markerHeight={markerRectSize}
            markerUnits="userSpaceOnUse"
            refX={markerRectSize / 2}
            refY={markerRectSize / 2}
            orient="auto-start-reverse"
          >
            <circle
              cx={markerRectSize / 2}
              cy={markerRectSize / 2}
              r={markerRectSize / 2}
              fill={color}
              shapeRendering="auto"
            />
          </marker>
        </defs>
        <path
          d={pathData}
          fill="none"
          stroke={color}
          strokeWidth={line.lineWidth ?? 1}
          strokeDasharray={line.dashed ? '4 4' : undefined}
          shapeRendering="auto"
          strokeLinecap="butt"
          markerEnd={markerEndUrl}
          markerStart={markerStartUrl}
        />
      </>
    );
  }
);

type ProcessMapLineViewOverlayProps = {
  line: ProcessMapLineObject;
  selectedRectHandles: ProcessMapRectHandle[];
  pendingConnectionLine: ProcessMapPendingConnectionLine;
  hoverRectHandles: ProcessMapRectHandle[];
  renderConnections: boolean;
  objectIndex: number;
  showDeleteConnections: boolean;
  lineConnections: ProcessMapLineConnection[];
  onAddNewLinePoint?: (
    objectIndex: number,
    index: number,
    centerX: number,
    centerY: number
  ) => void;
  zoom: number;
};

export const ProcessMapLineViewOverlay = React.memo(
  ({
    line,
    pendingConnectionLine,
    hoverRectHandles,
    renderConnections,
    selectedRectHandles,
    objectIndex,
    showDeleteConnections,
    lineConnections,
    onAddNewLinePoint,
    zoom
  }: ProcessMapLineViewOverlayProps) => {
    const anySelected = selectedRectHandles.some(
      (handle) => handle.objectIndex === objectIndex
    );
    let prevRect: ProcessMapRect = null;
    const rectSize = lineRectHeight / zoom;
    const addNewRectSize = rectSize * 0.5;

    return (
      <>
        {anySelected &&
          line.mode === ProcessMapLineModes.Path &&
          line.rects.map((p1, idx) => {
            const p0 = prevRect;
            prevRect = p1;
            if (p0 != null) {
              const normX = p1.centerX - p0.centerX;
              const normY = p1.centerY - p0.centerY;
              const normLength = Math.sqrt(normX * normX + normY * normY);
              const normDirX = normX / normLength;
              const normDirY = normY / normLength;
              const midX = p0.centerX + normDirX * normLength * 0.5;
              const midY = p0.centerY + normDirY * normLength * 0.5;
              return (
                <rect
                  key={idx}
                  x={midX - addNewRectSize / 2.0}
                  y={midY - addNewRectSize / 2.0}
                  width={addNewRectSize}
                  height={addNewRectSize}
                  fill="green"
                  opacity={0.35}
                  shapeRendering="auto"
                  rx={addNewRectSize / 2.0}
                  ry={addNewRectSize / 2.0}
                  onMouseDown={() => {
                    onAddNewLinePoint?.(objectIndex, idx, midX, midY);
                  }}
                />
              );
            }

            return null;
          })}
        {line.rects.map((rect, rectIndex) => {
          const amongSelected = selectedRectHandles.some(
            (handle) =>
              handle.objectIndex === objectIndex &&
              rectIndex === handle.rectIndex
          );
          const hasHover = hoverRectHandles.some(
            (handle) => handle.rectId === rect.id
          );
          const isActive = amongSelected || hasHover;
          const isConnectionTarget = renderConnections && !amongSelected;
          const renderActiveConnection =
            pendingConnectionLine?.lineRectHandle.objectIndex === objectIndex &&
            pendingConnectionLine?.lineRectHandle.rectIndex === rectIndex;

          let stroke: string = colors.accent1Color;
          if (renderActiveConnection) {
            stroke = colors.primary1Color;
          } else if (isConnectionTarget) {
            stroke = colors.accent1Color;
          }

          const hasConnection = lineConnections.some((connection) => {
            for (
              let index = 0;
              index < connection.rectHandles.length;
              index++
            ) {
              if (
                connection.rectHandles[index].objectIndex === objectIndex &&
                connection.rectHandles[index].rectIndex === rectIndex
              ) {
                return true;
              }
            }
            return false;
          });

          return (
            <Fragment key={rect.id}>
              {(isActive || isConnectionTarget || hasHover || anySelected) && (
                <rect
                  x={rect.centerX - rectSize / 2.0}
                  y={rect.centerY - rectSize / 2.0}
                  width={rectSize}
                  height={rectSize}
                  rx={rectSize / 2.0}
                  ry={rectSize / 2.0}
                  shapeRendering="auto"
                  opacity={
                    (hasHover || anySelected) &&
                    !amongSelected &&
                    !renderActiveConnection
                      ? 0.25
                      : 1.0
                  }
                  fill={stroke}
                  stroke="white"
                />
              )}

              {showDeleteConnections && amongSelected && hasConnection && (
                <image
                  href={removeIcon}
                  x={rect.centerX - processMapDeleteConnectionButtonSize * 0.5}
                  y={rect.centerY - processMapDeleteConnectionButtonSize * 0.5}
                  width={processMapDeleteConnectionButtonSize}
                  height={processMapDeleteConnectionButtonSize}
                  shapeRendering="auto"
                />
              )}
            </Fragment>
          );
        })}
      </>
    );
  }
);
