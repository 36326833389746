module.exports = {
  warm: '#c6342d',
  cold: '#25a0af',
  electricity: '#ccbb23',
  black: '#0c2e30',
  darkGrey: '#3f4d4d',
  lightGrey: '#666e6e',
  lighterGrey: '#999999',
  orange: '#f79335',
  blue: '#1c8ba8',
  lightOrange: '#f7c69f',
  turqoise: '#1dd6cc',
  lightTurqoise: '#b4ebe8',
  white: '#fff',
  cyan: '#25a0af',
  darkCyan: '#0d717c',
  red: '#c6342d',
  darkRed: '#aa1d1e',
  offBlack: '#313132',
  graphiteGrey: '#4b4b4b',
  steelGrey: '#828282',
  solar: '#c3b421',
  solarPredicted: '#dad49b',
  need: '#555555',
  needPredicted: '#707070'
};
