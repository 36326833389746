import React from 'react';
import {
  KeyValueInput,
  KeyValueInputProps
} from 'ecto-common/lib/KeyValueInput/KeyValueInput';
import { numberLocaleFormatter } from 'ecto-common/lib/utils/stringUtils';

const KeyValueNumberInput = ({ ...otherProps }: KeyValueInputProps) => {
  // moment.locale()
  return (
    <KeyValueInput textFormatter={numberLocaleFormatter} {...otherProps} />
  );
};

export default React.memo(KeyValueNumberInput);
