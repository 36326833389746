import { useCallback, useMemo, useState } from 'react';
import _ from 'lodash';
import { SortDirectionType } from 'ecto-common/lib/DataTable/SortDirection';
import { DataTableColumnProps } from 'ecto-common/lib/DataTable/DataTable';

type UseSortResult<DataType> = [
  by: string,
  direction: SortDirectionType,
  setSortParams: (by: string, direction: SortDirectionType) => void,
  sortedData: DataType[]
];

type DataTableColumnPropsWithSortBy<ObjectType extends object> =
  DataTableColumnProps<ObjectType> & {
    sortBy?: string[];
  };

const useSort = <DataType extends object>(
  defaultSortBy: string,
  defaultDirection: 'asc' | 'desc',
  columns: DataTableColumnPropsWithSortBy<DataType>[],
  originalData: DataType[]
): UseSortResult<DataType> => {
  const [sort, setSort] = useState({
    by: defaultSortBy,
    direction: defaultDirection
  });
  const setSortParams = useCallback(
    (by: string, direction: 'asc' | 'desc') => setSort({ by, direction }),
    []
  );

  const sortedData: DataType[] = useMemo(() => {
    // Order by column's 'sortBy' parameter, fallback to order by column's 'dataKey'
    const column = _.find(columns, { dataKey: sort.by });
    const sortBy = column?.sortBy ?? column?.dataKey;

    if (_.isArray(sortBy)) {
      // If sortBy is an array then the direction must be an array
      const direction = _.map(sortBy, _.constant(sort.direction));
      return _.orderBy(originalData, sortBy, direction);
    }

    return _.orderBy(originalData, sortBy, sort.direction);
  }, [columns, originalData, sort.by, sort.direction]);

  return [sort.by, sort.direction, setSortParams, sortedData];
};

export default useSort;
