/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ListResourcesResponse {
  /** Gets the resources. */
  resources?: ResourceModel[] | null;
  /**
   * Gets the total size of all the resources.
   * @format int32
   */
  totalSize?: number;
}

/** This class is a custom "view model" for the Resource entity. */
export interface ResourceModel {
  /** Gets or sets the identifier. */
  id?: string | null;
  /** Gets or sets the name. */
  name?: string | null;
  /** Gets the description. */
  description?: string | null;
}

export interface ProblemDetails {
  type?: string | null;
  title?: string | null;
  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
}

export interface ListRolesResponse {
  /** Gets the roles. */
  roles?: RoleModel[] | null;
  /**
   * Gets the total size of all the roles.
   * @format int32
   */
  totalSize?: number;
}

/** This class is a custom "view model" for the Role entity. */
export interface RoleModel {
  /** Gets or sets the identifier. */
  id?: string | null;
  /** Gets or sets the name. */
  name?: string | null;
  /** Gets the description. */
  description?: string | null;
}

export interface TenantUserSettingsModel {
  settings?: string | null;
}

export interface UpdateTenantUserSettingsRequest {
  /** The settings to update the tenant user with. */
  settings?: string | null;
}

/** This class is a custom "view model" for the B2CUser entity. */
export interface UserModel {
  /** Output only. The user identifier. This is a valid Guid. */
  id?: string | null;
  /** The email address and user name. This can't be modified! */
  email?: string | null;
  /** The display name of the user. */
  displayName?: string | null;
  /** If the login is federated to another AD. */
  isFederated?: boolean;
}

export interface UpdateUserRequest {
  user?: UserModel | null;
}

/** This class is a custom "view model" for the Tenant entity. */
export interface TenantModel {
  /** The tenant identifier. This is a valid Guid. */
  id?: string | null;
  /** The name of the tenant. */
  name?: string | null;
  /** An optional string describing the tenant. */
  description?: string | null;
  /**
   * The latitude of a tenant.
   * @format double
   */
  latitude?: number | null;
  /**
   * The longitude of a tenant.
   * @format double
   */
  longitude?: number | null;
  /** The IANA time zone of where the tenant is located. */
  timeZone?: string | null;
  /** Environment this tenant belongs to. Naming will be derived from Microsoft Azure region short name (i.e. WEU, which stands for West Europe). */
  environment?: string | null;
  /** Indicates if the current tenant is deactivated or not. */
  isDeactivated?: boolean;
  /** The identifiers and names of the resources that the tenant has been assigned. */
  resources?: IdNamePairModel[] | null;
  /** The name of the IoT hub host. */
  iotHubHostName?: string | null;
}

/** A generic class that holds an identifier and a name for some resource. */
export interface IdNamePairModel {
  /** The item identifier. This is a valid Guid. */
  id?: string | null;
  /** The name of the item. */
  name?: string | null;
}

/** This class is a custom "view model" for the TenantUser entity. */
export interface TenantUserModel {
  /** The tenant identifier. This is a valid Guid. */
  tenantId?: string | null;
  /** The user identifier. This is a valid Guid. It will not be set when FE adds a new user to a tenant. */
  userId?: string | null;
  /** The email address and user name. This can't be modified! */
  email?: string | null;
  /** Output only. The display name of a user. */
  displayName?: string | null;
  /** An optional string describing the tenant user. */
  description?: string | null;
  /** The roles that the user has been assigned in this tenant. */
  roles?: IdNamePairModel[] | null;
  /** If the login is federated to another AD. */
  isFederated?: boolean;
}

export interface GetUserSettingsResponse {
  /** The user's settings. */
  userSettings?: UserSettingsModel | null;
}

/** This class represents a user's settings. */
export interface UserSettingsModel {
  /**
   * Gets or sets the user identifier. This is the identifier in B2C.
   * @format guid
   */
  userId?: string;
  /** Gets or sets the user's preferred language. */
  language?: string | null;
  /** Gets or sets the user's activated experimental features. */
  experimentalFeatures?: Record<string, boolean>;
}

export interface CreateOrUpdateUserSettingsRequest {
  /** The user settings to be added or updated */
  userSettings?: UserSettingsModel | null;
}

export interface GetUserDashboardsResponse {
  /** List of found personal dashboards. */
  dashboards?: UserDashboardModel[] | null;
}

export interface UserDashboardModel {
  /**
   * Id of the dashboard.
   * @format guid
   */
  id?: string;
  /** Jsondata for the dashboards content. */
  jsonData?: Record<string, any>;
  /** Name of the dashboard. */
  name?: string | null;
  /** Description of the dashboard. */
  description?: string | null;
}

export interface CreateOrUpdateUserDashboardRequest {
  /** The user dashboard to be added or updated */
  dashboards?: UserDashboardModel[] | null;
}

export interface BatchGetUsersResponse {
  users?: UserModel[] | null;
}

/** The BatchGetUsers request message. */
export interface BatchGetUsersRequest {
  userIds?: string[] | null;
}

export interface GetUserByEmailResponse {
  /** The found user or null if no user was found. */
  user?: UserModel | null;
}

/** The GetUserByEmail request message. */
export interface GetUserByEmailRequest {
  /** The email of the user to find. */
  email?: string | null;
}

export interface ResourcesListResourcesParams {
  /** Gets or sets the filter to apply when getting the resources. Leave it empty if no filter is wanted. */
  Filter?: string | null;
  /**
   * The page number to retrieve. The value is 0-based so send 0 for the first page.
   * If no value is passed then the first page is returned.
   * @format int32
   */
  PageNumber?: number | null;
  /**
   * The maximum number of results to return in the response. If no value is passed then all items are returned.
   * @format int32
   */
  PageSize?: number | null;
  /**
   * The sort order of the result. If no value is supplied then the result will be sorted on "Name ascending".
   * The string value should follow SQL syntax: i.e. a field name and a direction.
   * For example: "foo". The default sorting order is ascending. To specify descending order for a
   * field, a suffix " desc" should be appended to the field name. For example: "foo desc".
   */
  OrderBy?: string | null;
}

export interface RolesListRolesParams {
  /** Gets or sets the filter to apply when getting the resources. Leave it empty if no filter is wanted. */
  Filter?: string | null;
  /**
   * The page number to retrieve. The value is 0-based so send 0 for the first page.
   * If no value is passed then the first page is returned.
   * @format int32
   */
  PageNumber?: number | null;
  /**
   * The maximum number of results to return in the response. If no value is passed then all items are returned.
   * @format int32
   */
  PageSize?: number | null;
  /**
   * The sort order of the result. If no value is supplied then the result will be sorted on "Name ascending".
   * The string value should follow SQL syntax: i.e. a field name and a direction.
   * For example: "foo". The default sorting order is ascending. To specify descending order for a
   * field, a suffix " desc" should be appended to the field name. For example: "foo desc".
   */
  OrderBy?: string | null;
}

/**
 * @title Identity API
 * @version v2
 * @baseUrl https://app-ec-identity-test-weu-001-hfkk.azurewebsites.net
 */

import { APIGenType } from './APIGenType';
import {
  ContentType,
  Method,
  apiEndpoint,
  apiEndpointEmpty,
  apiEndpointEmptyUsingQueryParams,
  apiEndpointEmptyWithPath
} from './APIUtils';

const apiTitle = APIGenType.IdentityServiceAPIGenV2;

export interface UserDeleteUserDashboardPath {
  /** @format guid */
  dashboardId: string;
}

export interface UsersGetUserPath {
  /** The user identifier. */
  userId: string | null;
}

export interface UsersBatchGetUsersPath {
  batchGet: string;
}

export interface UsersGetUserByEmailPath {
  getByEmail: string;
}

const IdentityServiceAPIGenV2 = {
  Resources: {
    /**
 * @summary Gets all the existing resources in a paged manner.
Leave all the parameters empty if you want a single page with all the
existing data.
*/
    listResources: apiEndpointEmptyUsingQueryParams<
      ResourcesListResourcesParams,
      ListResourcesResponse,
      ProblemDetails
    >(
      Method.GET,
      `/api/v2/resources`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    )
  },
  Roles: {
    /**
 * @summary Gets all the existing roles in a paged manner.
Leave all the parameters empty if you want a single page with all the
existing data.
*/
    listRoles: apiEndpointEmptyUsingQueryParams<
      RolesListRolesParams,
      ListRolesResponse,
      ProblemDetails
    >(
      Method.GET,
      `/api/v2/roles`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    )
  },
  TenantUser: {
    /**
     * @summary Gets the settings for the active user. A tenant user always has settings but it may be an empty string or null.
     */
    getUserSettings: apiEndpointEmpty<TenantUserSettingsModel, ProblemDetails>(
      Method.GET,
      `/api/v2/tenantUser/settings`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Updates the user settings. A tenant user always has settings but it may be an empty string or null.
     */
    updateUserSettings: apiEndpoint<
      UpdateTenantUserSettingsRequest,
      TenantUserSettingsModel,
      ProblemDetails
    >(
      Method.PUT,
      `/api/v2/tenantUser/settings`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Deletes the user settings. A tenant user always has settings so this will only update it to an empty string.
     */
    deleteUserSettings: apiEndpointEmpty<void, ProblemDetails>(
      Method.DELETE,
      `/api/v2/tenantUser/settings`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    )
  },
  User: {
    /**
     * @summary Gets the currently logged in user.
     */
    getUser: apiEndpointEmpty<UserModel, ProblemDetails>(
      Method.GET,
      `/api/v2/user`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Updates the display name of an existing user.
     */
    updateUser: apiEndpoint<UpdateUserRequest, UserModel, ProblemDetails>(
      Method.PUT,
      `/api/v2/user`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
 * @summary Gets the tenants that the currently logged in user has access to.
Tenants that are disabled are removed from the list.
*/
    getTenantsForUser: apiEndpointEmpty<TenantModel[], any>(
      Method.GET,
      `/api/v2/user/tenants`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
 * @summary Gets the tenant users that the currently logged in user is connected to.
Tenants that are disabled are removed from the list.
*/
    getTenantUsersForUser: apiEndpointEmpty<TenantUserModel[], ProblemDetails>(
      Method.GET,
      `/api/v2/user/tenantUsers`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Gets the user's personal settings.
     */
    getUserSettings: apiEndpointEmpty<GetUserSettingsResponse, any>(
      Method.GET,
      `/api/v2/user/settings`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Create the user's personal settings.
     */
    createOrUpdateUserSettings: apiEndpoint<
      CreateOrUpdateUserSettingsRequest,
      UserSettingsModel,
      ProblemDetails
    >(
      Method.PUT,
      `/api/v2/user/settings`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Gets the users dashboards
     */
    getUserDashboards: apiEndpointEmpty<GetUserDashboardsResponse, any>(
      Method.GET,
      `/api/v2/user/settings/dashboards`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Adds or updates existing dashboards. If an existing dashboard is not in request then it is not being removed, just ignored.
     */
    createOrUpdateUserDashboards: apiEndpoint<
      CreateOrUpdateUserDashboardRequest,
      void,
      ProblemDetails
    >(
      Method.PUT,
      `/api/v2/user/settings/dashboards`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    /**
     * @summary Deletes a user dashboard with matching id.
     */
    deleteUserDashboard: apiEndpointEmptyWithPath<
      void,
      ProblemDetails,
      UserDeleteUserDashboardPath
    >(
      Method.DELETE,
      ({ dashboardId }: UserDeleteUserDashboardPath) =>
        `/api/v2/user/settings/dashboards/${dashboardId}`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    )
  },
  Users: {
    /**
     * @summary Gets the user with the supplied identifier.
     */
    getUser: apiEndpointEmptyWithPath<
      UserModel,
      ProblemDetails,
      UsersGetUserPath
    >(
      Method.GET,
      ({ userId }: UsersGetUserPath) => `/api/v2/users/${userId}`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Get a list of users matching the supplied identifiers. No error will be returned if all users don't exist.
     */
    batchGetUsers: apiEndpoint<
      BatchGetUsersRequest,
      BatchGetUsersResponse,
      ProblemDetails
    >(
      Method.POST,
      `/api/v2/users:batchGet`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @description Notice that the method returns a response model containing the found user and not only the user like in GET api/v2/users/{userId} * @summary Gets the user matching the supplied email. No error will be returned if the user doesn't exist.
     */
    getUserByEmail: apiEndpoint<
      GetUserByEmailRequest,
      GetUserByEmailResponse,
      ProblemDetails
    >(
      Method.POST,
      `/api/v2/users:getByEmail`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    )
  }
};

export default IdentityServiceAPIGenV2;
