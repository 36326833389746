import { Highcharts } from 'ecto-common/lib/Highcharts/Highcharts';

const CommonGraphOptions: Highcharts.Options = {
  rangeSelector: {
    enabled: false
  },
  navigator: {
    enabled: false
  },
  credits: {
    enabled: false
  },
  scrollbar: {
    liveRedraw: false,
    enabled: false
  },
  exporting: {
    enabled: false
  },
  tooltip: {
    enabled: false
  },
  title: null
};

export default CommonGraphOptions;
