import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';

import Options from 'ecto-common/lib/Options/Options';
import { ModelEditorProps } from 'ecto-common/lib/ModelForm/ModelEditor';
import { GenericSelectOption } from 'ecto-common/lib/Select/Select';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import {
  ModelDynamicOptionsProperty,
  ModelDefinitionInternal
} from 'ecto-common/lib/ModelForm/ModelPropType';
import { KeyValueGeneric } from 'ecto-common/lib/KeyValueInput/KeyValueGeneric';

export type CheckboxOptionsModelDefinition<
  ObjectType extends object,
  EnvironmentType extends object,
  ValueType = object
> = {
  modelType: typeof ModelType.CHECKBOX_OPTIONS;
  isMulti?: boolean;
  selectAllLabel?: string;
  defaultValue?: GenericSelectOption[];
  options: ModelDynamicOptionsProperty<ObjectType, EnvironmentType, ValueType>;
} & ModelDefinitionInternal<ObjectType, EnvironmentType, ValueType>;

type ModelEditorCheckboxOptionsProps = ModelEditorProps & {
  options: GenericSelectOption[];
  model: CheckboxOptionsModelDefinition<object, object, unknown>;
};

const ModelEditorCheckboxOptions = ({
  model,
  updateItem,
  rawValue,
  disabled,
  helpText,
  options
}: ModelEditorCheckboxOptionsProps) => {
  const [selectedValues, setSelectedValues] = useState(
    model.defaultValue ?? []
  );

  const onChange = useCallback(
    (data: GenericSelectOption[] | GenericSelectOption) => {
      if (model.isMulti) {
        const dataArray = data as GenericSelectOption[];
        const value = _.map(dataArray, 'value');
        updateItem(value);
      } else {
        updateItem((data as GenericSelectOption).value);
      }
    },
    [model.isMulti, updateItem]
  );

  useEffect(() => {
    if (rawValue) {
      if (model.isMulti) {
        setSelectedValues(
          _.map(rawValue, (value) => _.find(options, { value }))
        );
      } else {
        const option = _.find(options, { value: rawValue });
        if (option != null) {
          setSelectedValues([option]);
        } else {
          setSelectedValues([]);
        }
      }
    }
  }, [rawValue, options, model.isMulti]);

  const content = (
    <Options
      isMulti={model.isMulti}
      options={options}
      value={selectedValues}
      selectAllLabel={model.selectAllLabel}
      onChange={onChange}
      disabled={disabled}
    />
  );

  if (model.label || helpText) {
    return (
      <KeyValueGeneric keyText={model.label} helpText={helpText}>
        {content}
      </KeyValueGeneric>
    );
  }
  return content;
};

export default React.memo(ModelEditorCheckboxOptions);
