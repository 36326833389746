import React, { useContext } from 'react';
import ErrorNotice from '../Notice/ErrorNotice';
import T from '../lang/Language';
import DashboardDataContext from 'ecto-common/lib/hooks/DashboardDataContext';
import { SignalInputType } from 'ecto-common/lib/Dashboard/datasources/LastSignalValuesDataSource';
import { isNullOrWhitespace } from 'ecto-common/lib/utils/stringUtils';

interface SignalSettingNameProps {
  signal?: SignalInputType;
}

const SignalSettingName = ({ signal }: SignalSettingNameProps) => {
  const { signalTypesMap, equipmentTypesMap } =
    useContext(DashboardDataContext);

  const type = signalTypesMap[signal.signalTypeId];

  if (type == null) {
    return <ErrorNotice>{T.signals.missingtype}</ErrorNotice>;
  }

  let name;

  const prefix = isNullOrWhitespace(type.description)
    ? type.name
    : type.name + ' - ' + type.description;

  if (signal.equipmentTypeId) {
    const eqType = equipmentTypesMap[signal.equipmentTypeId];
    name = prefix + ' (' + eqType?.name + ')';
  } else if (signal.signalProviderType) {
    name = prefix + ' (' + signal.signalProviderType + ')';
  } else {
    name = prefix;
  }

  return <span>{name}</span>;
};

export default React.memo(SignalSettingName);
