import Util from './util';

import PhysicalNode from './physical_node';

import PhysicalGrid from './physical_grid';
import { TableContextType } from 'js/Table/context';

/**
 * A network of Structures (+ the Ectogrid)
 * @class Structures
 */
class Structures extends PhysicalGrid {
  context: TableContextType;
  dirtySince: number;
  dead: boolean;

  constructor(context) {
    super(null);
    this.context = context;
    this.touch();
  }

  /**
   * Adding a PhysicalNode that is an independent drawn objects,
   * either groups (so far only Ectogrid) or individual ones (Structures)
   * @param {PhysicalNode} object
   * @throws {String} Message about dead structure
   * @throws {String} Message about bad in parameters
   */
  push(object) {
    if (this.dead) throw 'Trying to access dead Structure';
    if (!(object instanceof PhysicalNode)) throw 'Cannot add a non Node';

    if (this.addNode(object) === Util.SUCCESS) {
      this.touch();
    }
  }

  touch() {
    this.dirtySince = this.context.appNow();
  }

  dataIsDirtySince(marker: number) {
    // If the structures complex it self is dirty
    if (marker < this.dirtySince) {
      return true;
    }

    const all = this.getAllChildren();

    for (let structureIndex in all) {
      const structure = all[structureIndex];

      if (structure.dataIsDirtySince(marker)) {
        return true;
      }
    }
    return false;
  }

  /**
   * @param {PhysicalNode} object the node to remove
   * @throws {String} Message about dead structure
   * @throws {String} Message about bad in parameters
   */
  remove(object) {
    if (this.dead) throw 'Trying to access dead Structure';
    if (!(object instanceof PhysicalNode)) throw 'Cannot remove a non Node';

    const structure = object;

    // Ask the structure to go and destroy itself
    structure.resetConnections();
    structure.destructor();
    this.removeNode(structure);

    this.touch();
    return Util.SUCCESS;
  }

  clear() {
    const l = this.length();

    for (let i = 0; i < l; i++) {
      this.get(i).destructor();
    }

    this.clearNodes();
    this.touch();
  }

  layout(p5) {
    const l = this.length();

    for (let i = 0; i < l; i++) {
      this.get(i).layout(p5);
    }
  }

  draw(p5) {
    const l = this.length();

    for (let i = 0; i < l; i++) {
      this.get(i).layout(p5);
    }

    for (let i = 0; i < l; i++) {
      this.get(i).drawBg(p5);
    }

    for (let i = 0; i < l; i++) {
      this.get(i).draw(p5);
    }
  }

  getExternalGridHeatingNeedMW() {
    return Util.arraySummariseOverItems(
      this.getAllChildren(),
      'getHeatingNeedMW'
    );
  }

  getExternalGridCoolingNeedMW() {
    return Util.arraySummariseOverItems(
      this.getAllChildren(),
      'getCoolingNeedMW'
    );
  }

  getHeatingNeedMW() {
    return Util.arraySummariseOverItems(
      this.getAllChildren(),
      'getHeatingNeedMW'
    );
  }

  getCoolingNeedMW() {
    return Util.arraySummariseOverItems(
      this.getAllChildren(),
      'getCoolingNeedMW'
    );
  }

  getExternalGridTotalEnergyNeedMW() {
    return (
      this.getExternalGridCoolingNeedMW() + this.getExternalGridHeatingNeedMW()
    );
  }

  getTotalEnergyNeedMW() {
    return this.getCoolingNeedMW() + this.getHeatingNeedMW();
  }

  disconnectAllPipes() {
    const all = this.getAllChildren();

    for (let structureIndex in this.getAllChildren()) {
      const structure = all[structureIndex];

      structure.resetConnections();
    }
  }

  /**
   * Call this to pipe up all structures
   */
  pipeAllStructures() {
    this.disconnectAllPipes();
    this.buildGrid();
  }

  hitAMenu(x: number, y: number) {
    const all = this.getAllChildren();

    for (let structureIndex in this.getAllChildren()) {
      const structure = all[structureIndex];
      const hit = structure.hitMenu(x, y);

      if (hit) {
        return hit;
      }
    }

    return undefined;
  }

  hitAStructure(x, y, minDistance = 0) {
    const all = this.getAllChildren();

    for (let structureIndex in this.getAllChildren()) {
      const structure = all[structureIndex];
      const hit = structure.hit(x, y, minDistance);

      if (hit) {
        return hit;
      }
    }

    return undefined;
  }
}

export default Structures;
