import _ from 'lodash';

import T from 'ecto-common/lib/lang/Language';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import {
  BuildingStatusOptions,
  BuildingStatusDefault,
  BuildingStatusText
} from 'ecto-common/lib/utils/buildingStatusUtil';
import { BuildingStatus } from 'ecto-common/lib/API/APIGen';
import { ModelFormSectionType } from 'ecto-common/lib/ModelForm/ModelPropType';

type ObjectWithBuildingStatus = {
  buildingStatus?: BuildingStatus[];
};

export const buildingStatusSection: ModelFormSectionType<ObjectWithBuildingStatus> =
  {
    label: T.admin.dashboards.panels.buildingstatuses,
    lines: [
      {
        models: [
          {
            key: (input) => input.buildingStatus,
            modelType: ModelType.CHECKBOX_OPTIONS,
            options: BuildingStatusOptions,
            isMulti: true,
            defaultValue: _.map(BuildingStatusDefault, (value) => ({
              value,
              label: BuildingStatusText[value]
            }))
          }
        ]
      }
    ]
  };
