/**
 * DataSourceTypes available.
 */
enum DataSourceTypes {
  NODE = 'node',
  NODES = 'nodes',
  SIGNALS = 'signals',
  SIGNALS_LAST_VALUE = 'signalsLastValue',
  SIGNAL_PROVIDERS = 'signalProviders',
  SIGNAL_TIME_SERIES = 'signalTimeSeries',
  // Obsolete data source type, new name 'signalProviders'
  OBSOLETE_EQUIPMENTS = 'equipments'
}

export default DataSourceTypes;
