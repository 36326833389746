import _ from 'lodash';

import T from '../lang/Language';
import { BuildingStatus } from 'ecto-common/lib/API/APIGen';

export const BuildingStatusText: Record<string, string> = {
  [BuildingStatus.Production]: T.buildingstatus.production,
  [BuildingStatus.Created]: T.buildingstatus.created,
  [BuildingStatus.Testing]: T.buildingstatus.testing
};

export const BuildingStatusOptions = _.map(
  _.filter(
    BuildingStatus,
    (buildingStatus) => buildingStatus !== BuildingStatus.Unknown
  ),
  (value) => ({ label: BuildingStatusText[value], value })
);

export enum BuildingStatusTimestampComparisonType {
  BEFORE = 'Before',
  AFTER = 'After',
  EXACT = 'Exact'
}

export const BuildingStatusTimestampComparisonTypeText: Record<string, string> =
  {
    [BuildingStatusTimestampComparisonType.BEFORE]:
      T.buildingstatus.timetype.before,
    [BuildingStatusTimestampComparisonType.AFTER]:
      T.buildingstatus.timetype.after,
    [BuildingStatusTimestampComparisonType.EXACT]:
      T.buildingstatus.timetype.exact
  };

export const BuildingStatusDefault = _.map(BuildingStatus).filter(
  (x) => x !== BuildingStatus.Unknown
);

export const getBuildingStatusQueryParameter = (
  buildingStatus: BuildingStatus[]
) => {
  // Optimization for the API to exclude unnecessary filtering.
  if (_.difference(_.map(BuildingStatus), buildingStatus).length === 0) {
    return [];
  }

  if (!_.isEmpty(buildingStatus)) {
    return buildingStatus;
  }

  return BuildingStatusDefault;
};
