import { getNodeFromMap } from 'ecto-common/lib/utils/locationUtils';
import T from 'ecto-common/lib/lang/Language';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import { useContext } from 'react';
import DashboardDataContext from 'ecto-common/lib/hooks/DashboardDataContext';
import SectionListPriority from 'ecto-common/lib/Dashboard/SectionListPriority';
import { SingleGridNode } from 'ecto-common/lib/types/EctoCommonTypes';
import { ModelFormSectionType } from 'ecto-common/lib/ModelForm/ModelPropType';

interface NodeDataSourceProps {
  nodeId?: string;
}

const NodeDataSource = ({ nodeId }: NodeDataSourceProps): SingleGridNode => {
  const { nodeId: currentNodeId, nodeMap } = useContext(DashboardDataContext);

  if (nodeId == null) {
    return getNodeFromMap(nodeMap, currentNodeId);
  }

  return getNodeFromMap(nodeMap, nodeId);
};

export const nodeDataSourceSections: () => ModelFormSectionType<NodeDataSourceProps>[] =
  () => {
    return [
      {
        lines: [
          {
            models: [
              {
                key: (input) => input.nodeId,
                label: T.admin.dashboards.panels.types.nodename.node,
                modelType: ModelType.NODE,
                placeholder:
                  T.admin.dashboards.panels.types.nodename.nodeplaceholder
              }
            ]
          }
        ],
        listPriority: SectionListPriority.Location
      }
    ];
  };

export default NodeDataSource;
