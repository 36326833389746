import React, { Dispatch, SetStateAction, useMemo } from 'react';
import _ from 'lodash';
import TreeView, {
  isTreeNodeFolder,
  isTreeNodeRootLevel,
  treeViewIconFormatter,
  treeViewIconMinWidth,
  TreeViewNodeTypeWithParentId
} from 'ecto-common/lib/TreeView/TreeView';
import Icons from 'ecto-common/lib/Icons/Icons';
import styles from './SignalTypeTreeView.module.css';
import classNames from 'classnames';
import { getSignalTypeUnitSuffix } from 'ecto-common/lib/SignalSelector/SignalUtils';
import sortByLocaleCompare from 'ecto-common/lib/utils/sortByLocaleCompare';
import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';
import {
  TreeViewColumnType,
  TreeViewNodeType
} from 'ecto-common/lib/TreeView/TreeViewNode';
import { UnitResponseModel } from 'ecto-common/lib/API/APIGen';

// These come from the overlap between TreeViewNodeType and folders/types.
type SignalTypeTreeViewNodeType = TreeViewNodeType & {
  unitId?: string;
  isSystem?: boolean;
  description?: string;
};

const getColumns = (
  signalUnitTypesMap: Record<string, UnitResponseModel>,
  showIconForLockedTypes: boolean
): TreeViewColumnType<SignalTypeTreeViewNodeType>[] => {
  return _.compact([
    {
      dataFormatter: treeViewIconFormatter(<Icons.Signal />),
      minWidth: treeViewIconMinWidth
    },
    {
      dataFormatter: (
        node,
        _unused: unknown,
        highlightNode,
        path,
        searchFilterActive
      ) => {
        return (
          <div>
            <div className={styles.title}>
              {node.name}{' '}
              {getSignalTypeUnitSuffix(node.unitId, signalUnitTypesMap)}
            </div>
            <div
              className={classNames(
                styles.subtitle,
                highlightNode && styles.highlighted
              )}
            >
              {searchFilterActive && (
                <span className={styles.path}> {path} </span>
              )}{' '}
              {searchFilterActive && node.description && path && ' | '}{' '}
              {node.description}
            </div>
          </div>
        );
      }
    },
    {
      flexGrow: 1
    },
    showIconForLockedTypes && {
      dataFormatter: (node) => {
        if (node.isSystem) {
          return <Icons.Lock />;
        }

        return null;
      }
    }
  ]);
};

interface SignalTypeTreeViewProps {
  selectedNodes?: Record<string, boolean>;
  setSelectedNodes: Dispatch<SetStateAction<Record<string, boolean>>>;
  searchFilter?: string;
  selectFolder?: boolean;
  selectOnlyFolders?: boolean;
  onClickNode?(): void;
  showIconForLockedTypes?: boolean;
}

const SignalTypeTreeView = ({
  selectedNodes,
  setSelectedNodes,
  searchFilter,
  onClickNode,
  selectFolder,
  selectOnlyFolders,
  showIconForLockedTypes = false
}: SignalTypeTreeViewProps) => {
  const signalTypes = useCommonSelector((state) => state.general.signalTypes);
  const signalUnitTypesMap = useCommonSelector(
    (state) => state.general.signalUnitTypesMap
  );
  const signalTypeFolders = useCommonSelector(
    (state) => state.general.signalTypeFolders
  );
  const columns: TreeViewColumnType[] = useMemo(
    () => getColumns(signalUnitTypesMap, showIconForLockedTypes),
    [signalUnitTypesMap, showIconForLockedTypes]
  );

  const nodes = useMemo(() => {
    const foldersCopy: TreeViewNodeTypeWithParentId[] = signalTypeFolders.map(
      (signalTypeFolder) => ({
        ...signalTypeFolder,
        name: signalTypeFolder.name ?? '',
        children: [],
        path: null
      })
    );

    const foldersMap = _.keyBy(foldersCopy, 'id');
    const rootTypes: TreeViewNodeType[] = [];
    _.forEach(foldersCopy, (folder) => {
      folder.children = [];
    });

    _.forEach(foldersCopy, (folder) => {
      foldersMap[folder.parentId]?.children.push(folder);
    });

    if (!selectOnlyFolders) {
      _.forEach(signalTypes, (signalType) => {
        const treeNode: TreeViewNodeType = {
          ...signalType,
          name: signalType.name ?? '',
          path: null,
          children: null
        };

        if (signalType.signalTypeFolderId != null) {
          foldersMap[signalType.signalTypeFolderId].children.push(treeNode);
        } else {
          rootTypes.push(treeNode);
        }
      });
    }

    _.forEach(foldersCopy, (folder) => {
      folder.children = _.concat(
        sortByLocaleCompare(
          _.filter(folder.children, isTreeNodeFolder),
          'name'
        ),
        sortByLocaleCompare(_.reject(folder.children, isTreeNodeFolder), 'name')
      );
    });

    return _.concat(
      sortByLocaleCompare(_.filter(foldersCopy, isTreeNodeRootLevel), 'name'),
      sortByLocaleCompare(rootTypes, 'name')
    );
  }, [signalTypes, signalTypeFolders, selectOnlyFolders]);

  return (
    <TreeView
      nodes={nodes}
      onSelectedNodesChanged={setSelectedNodes}
      selectedNodes={selectedNodes}
      columns={columns}
      searchFilter={searchFilter}
      selectFolder={selectFolder}
      onClickNode={onClickNode}
    />
  );
};

export default React.memo(SignalTypeTreeView);
