import React, { useCallback, useMemo } from 'react';
import _ from 'lodash';

import T from 'ecto-common/lib/lang/Language';
import { formatNumber } from 'ecto-common/lib/utils/stringUtils';
import {
  getLatestDataPoint,
  showUpdateSignalError
} from 'ecto-common/lib/SignalsTable/signalsTableUtils';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import useSignalSetter, {
  SignalResponseModelWithValue
} from 'ecto-common/lib/hooks/useSignalSetter';
import { SignalProviderSignalResponseModel } from 'ecto-common/lib/API/APIGen';
import { LastSignalValuesResult } from 'ecto-common/lib/hooks/useLatestSignalValues';
import { SignalProviderSignalWithProviderResponseModel } from 'ecto-common/lib/types/EctoCommonTypes';
import SignalValueEditModal from './SignalValueEditModal';

interface SignalsTableModalProps {
  signal?: SignalProviderSignalWithProviderResponseModel;
  signalValuesById: LastSignalValuesResult;
  otherSignals?: SignalProviderSignalResponseModel[];
  onDoneEditingSignal(): void;
  setPendingSignal?(signal: SignalProviderSignalResponseModel): void;
  clearPendingSignal?(signal: SignalProviderSignalResponseModel): void;
}

const SignalsTableModal = ({
  signal,
  onDoneEditingSignal,
  signalValuesById,
  otherSignals,
  setPendingSignal,
  clearPendingSignal
}: SignalsTableModalProps) => {
  const onSignalUpdated = useCallback(() => {
    toastStore.addSuccessToast(T.equipment.setvaluesuccess);
    onDoneEditingSignal();
  }, [onDoneEditingSignal]);

  const onSignalUpdateFailed = useCallback(
    (err: unknown) => {
      clearPendingSignal?.(signal);
      showUpdateSignalError(err);
    },
    [signal, clearPendingSignal]
  );

  const [isSettingsSignalValues, setSignalValues] = useSignalSetter({
    onSignalUpdated,
    onSignalUpdateFailed,
    otherSignals,
    signalValuesById
  });

  const onConfirmClick = useCallback(
    (
      newSignal: SignalProviderSignalResponseModel,
      value: number,
      _unused: number,
      message: string
    ) => {
      setPendingSignal?.(newSignal);
      // Old value is not used here because it is set by useSignalSetter
      setSignalValues(
        [{ ...newSignal, value } as SignalResponseModelWithValue],
        message
      );
    },
    [setPendingSignal, setSignalValues]
  );

  const onCancelClick = useCallback(() => {
    onDoneEditingSignal();
  }, [onDoneEditingSignal]);

  const isOpen = !_.isNull(signal);
  const currentSignalData = signalValuesById[signal?.signalId];
  const [currentValue, currentRawValue] = useMemo(() => {
    const dataPoint = getLatestDataPoint(currentSignalData)?.value;
    return [dataPoint != null ? formatNumber(dataPoint) : '', dataPoint];
  }, [currentSignalData]);

  return (
    <SignalValueEditModal
      signal={signal}
      isOpen={isOpen}
      currentValue={currentValue}
      currentRawValue={currentRawValue}
      onCancel={onCancelClick}
      onConfirm={onConfirmClick}
      isLoading={isSettingsSignalValues}
    />
  );
};

export default SignalsTableModal;
