import { createContext } from 'react';
import _ from 'lodash';
import {
  ResourceModel,
  RoleModel,
  TenantModel
} from 'ecto-common/lib/API/IdentityServiceAPIGenV2';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';

type TenantContextProps = {
  tenantId: string;
  tenantResources: ResourceModel[];
  availableTenantRoles: RoleModel[];
  availableTenantResources: ResourceModel[];
  tenantUserRoles: RoleModel[];
  reloadTenants: () => void;
  tenantsFailedToLoad: boolean;
  tenants: TenantModel[];
  isLoadingTenants: boolean;
  reloadUser: () => void;
  contextSettings: ApiContextSettings;
};

const TenantContext = createContext<TenantContextProps>({
  contextSettings: { tenantId: null },
  tenantId: null,
  tenantResources: [],
  availableTenantRoles: [],
  availableTenantResources: [],
  tenantUserRoles: [],
  reloadTenants: null,
  tenantsFailedToLoad: false,
  tenants: [],
  isLoadingTenants: false,
  reloadUser: _.noop
});

export default TenantContext;
