// Constants that the TouchTriangle class uses to keep track of it's vertices
export const A = 1;
export const B = 2;
export const C = 3;

export function findSignificantVertex(a, b, c) {
  const sideC = a.dist(b);
  const sideA = b.dist(c);
  const sideB = c.dist(a);

  let resultVertex;

  if (Math.abs(sideA / sideB - 1) < 0.05) {
    resultVertex = C;
  } else if (Math.abs(sideB / sideC - 1) < 0.05) {
    resultVertex = A;
  } else if (Math.abs(sideC / sideA - 1) < 0.05) {
    resultVertex = B;
  }

  return resultVertex;
}

/**
 * Returns the circumference of the triangle defined by the coordinates
 */
export function triangleCircumference(x1, y1, x2, y2, x3, y3, p5Instance) {
  return (
    p5Instance.dist(x1, y1, x2, y2) +
    p5Instance.dist(x2, y2, x3, y3) +
    p5Instance.dist(x3, y3, x1, y1)
  );
}

/**
 * returns the closest triangle it can find in the array,
 * removes those points from the array
 */
export function getClosestTriangle(touchPoints, maxTriangleSize, p5Instance) {
  let closestTriangle;

  for (let i = 0; i < touchPoints.length; i++) {
    for (let j = i; j < touchPoints.length; j++) {
      if (touchPoints[j].identifier !== touchPoints[i].identifier) {
        for (let k = j; k < touchPoints.length; k++) {
          if (
            touchPoints[k].identifier !== touchPoints[j].identifier &&
            touchPoints[k].identifier !== touchPoints[i].identifier
          ) {
            let c = triangleCircumference(
              touchPoints[i].clientX,
              touchPoints[i].clientY,
              touchPoints[j].clientX,
              touchPoints[j].clientY,
              touchPoints[k].clientX,
              touchPoints[k].clientY,
              p5Instance
            );

            const createVector = p5Instance.createVector;
            const significantPoint = findSignificantVertex(
              createVector(touchPoints[i].clientX, touchPoints[i].clientY),
              createVector(touchPoints[j].clientX, touchPoints[j].clientY),
              createVector(touchPoints[k].clientX, touchPoints[k].clientY)
            );

            if (
              c < maxTriangleSize &&
              (!closestTriangle || c < closestTriangle.circumference) &&
              significantPoint !== undefined
            ) {
              closestTriangle = {
                a: i,
                b: j,
                c: k,
                circumference: c
              };
            }
          }
        }
      }
    }
  }

  if (!closestTriangle) return null;

  let s = {
    a: touchPoints[closestTriangle.a],
    b: touchPoints[closestTriangle.b],
    c: touchPoints[closestTriangle.c],
    circumference: closestTriangle.circumference
  };

  // remove those touchpoints from the array
  touchPoints.splice(closestTriangle.a, 1);
  touchPoints.splice(closestTriangle.b, 1);
  touchPoints.splice(closestTriangle.c, 1);

  return s;
}

export function vectorSub(vectorA, vectorB) {
  return {
    x: vectorA.x - vectorB.x,
    y: vectorA.y - vectorB.y
  };
}

function vectorCenter(vectorA, vectorB) {
  return vectorA.copy().lerp(vectorB, 0.5);
}

function vectorMagSq(vector) {
  return vector.x * vector.x + vector.y * vector.y;
}

export function vectorMag(vector) {
  return Math.sqrt(vectorMagSq(vector));
}

export function getTriangleCenter(posA, posB, posC) {
  let center = vectorCenter(posA, posB);

  center = center.copy().lerp(posC, 0.3333);
  return center;
}

export const TWO_PI = Math.PI * 2;
