import TABLESTATES from '../../config/states';
import RoomConfig from '../../config/rooms.json';

export const roomStorageKey = 'screen_room';
export const roleStorageKey = 'screen_role';
export const tokenStorageKey = 'screen_token';
export const tableStateKey = 'table_state';
export const tableSubStateKey = 'table_substate';

export const MultiViewTypes = {
  NONE: 'none',
  MULTI_3V_1: '3V:1',
  MULTI_3_1: '3:1',
  MULTI_2_1_1: '2:1:1',
  MULTI_1_1: '1:1'
};
export type Role = {
  roleName: string;
  urlMatch: string;
};

// Definition of the roles we have and the url string that is associated with them
// It might make sense to move this to a common config to sync with the server
export const ROLES: Record<string, Role> = {
  table: {
    urlMatch: 'tableview',
    roleName: ''
  },
  ectocloud: {
    urlMatch: 'ectocloudview',
    roleName: ''
  },
  consumption: {
    urlMatch: 'powerconsumptionview',
    roleName: ''
  },
  production: {
    urlMatch: 'powerproductionview',
    roleName: ''
  },
  combo: {
    urlMatch: 'comboview',
    roleName: ''
  },
  roomless: {
    urlMatch: 'roomlessview', // This is not meant for anyone to manually write, it's more of a 404
    roleName: ''
  },
  admin: {
    urlMatch: 'admin', // This is not meant for anyone to manually write, it's only used in support for existing flows
    roleName: ''
  }
};

Object.keys(ROLES).forEach((key) => (ROLES[key].roleName = key));

const roleOverrides = {};

/**
 * Allow for url assigning of roles in development and testing environments
 */
if (process.env.DEVELOPMENT) {
  Object.keys(ROLES).forEach(
    (key) => (roleOverrides[ROLES[key].urlMatch] = key)
  );
}

/**
 * Override first part of path to specific rooms by adding them here. Key is the
 * string to match, value is the room to map to.
 */
const roomPathOverrides = {
  admin: 'admin',
  screens: 'screens'
};

/**
 * Override domains to specific rooms by adding them here. Key is the origin,
 * value is the room to map to.
 */
const roomDomainOverrides = {};

/**
 * Figures out which room to use based on the URL and what is stored. Precedence
 * is as follows:
 *
 * 1. Manual overrides on domain or path
 * 2. URL (Tables get caught here)
 * 3. LocalStorage (Clients use this)
 *
 * @param {string} pathname The path in the URL (without the domain)
 * @param {string} [origin=''] Origin / domain
 * @param {object} [overrides={}] If we need to override the room selected, you
 can pass in key values here. This parameter is
 mainly for testing purposes, default overrides
 are added to corresponding variables in the
 module.
 * @return {string} Room to use.
 */
export function getRoom(pathname, origin = '', overrides = {}) {
  const params = pathname.split('/').filter((param) => param.trim());

  const combinedOverrides = {
    ...roomPathOverrides,
    ...roomDomainOverrides,
    ...overrides
  };

  const filterRooms = (roomCandidate) =>
    roomCandidate &&
    RoomConfig.rooms.indexOf(roomCandidate.toLowerCase()) !== -1;

  // First check if the domain is overriding the room selection
  if (origin) {
    const roomCandidate = combinedOverrides[origin];

    // Overriding domains doesn't have to adhere to rules of rooms
    if (roomCandidate) {
      return roomCandidate;
    }
  }

  if (params && params[0]) {
    // Figure out if we have any special rooms that should override the url
    let roomCandidate = combinedOverrides[params[0]];

    // Overriding paths doesn't have to adhere to rules of room names
    if (roomCandidate) {
      return roomCandidate;
    }

    // We didn't have any overrides so check that we have the room to match in
    // the config
    roomCandidate = params[0];
    if (filterRooms(roomCandidate)) {
      return roomCandidate;
    }
  }

  // We could not find any overrides or special rooms to use, so fallback to
  // to the stored one. This should be default behavior on client screens.
  return localStorage.getItem(roomStorageKey);
}

/**
 * Override domains to specific roles by adding them here. Key is the origin,
 * value is the room to map to.
 */
const roleDomainOverrides = {};

/**
 * Figures out which role to use based on what is stored. Precedence is as
 * follows:
 *
 * 1. Manual overrides on domain
 * 2. LocalStorage (Clients use this)
 *
 * @param {string} [pathname=''] The path in the URL (without the domain)
 * @param {string} [origin=''] Origin / domain
 * @param {object} [overrides={}] If we need to override the role selected, you
 can pass in key values here. This parameter is
 mainly for testing purposes, default overrides
 are added to corresponding variables in the
 module.
 * @return {string} Role to use.
 */
export function getRole(
  pathname = '',
  origin = '',
  overrides = {},
  prefix = ''
) {
  const params = pathname.split('/').filter((param) => param.trim());

  const combinedOverrides = {
    ...roleDomainOverrides,
    ...overrides
  };

  let roleCandidateName = undefined;

  if (origin && combinedOverrides[origin]) {
    roleCandidateName = combinedOverrides[origin];
  }
  if (!roleCandidateName && params.length > 0) {
    const urlMatch = params.find((param) => combinedOverrides[param]);

    if (urlMatch) roleCandidateName = combinedOverrides[urlMatch];
  }
  if (!roleCandidateName) {
    roleCandidateName = localStorage.getItem(prefix + roleStorageKey);
  }

  return roleCandidateName && ROLES[roleCandidateName];
}

export function setRoomToken(token) {
  localStorage.setItem(tokenStorageKey, token);
}

export function getRoomToken() {
  return localStorage.getItem(tokenStorageKey);
}

/**
 * Combined method to get the role, room and company based on parameters from the URL.
 * This method fetch the values and returns them in an object.
 *
 * @return {Object} Object with "room", "role" and eventual "company".
 */
export function getRoleRoomCompanyAndToken(clientPrefix = '') {
  const room = getRoom(window.location.pathname, window.location.origin);
  let role = getRole(
    window.location.pathname,
    window.location.origin,
    roleOverrides,
    clientPrefix
  );
  let company;

  if (!role) {
    role = room ? ROLES.table : (role = ROLES.roomless);
  }

  if (room === 'admin') {
    role = ROLES.admin;
  } else if (room) {
    company = room.split('-')[0];
  }

  return { room, role, company, roomToken: getRoomToken() };
}

const defaultTableState = 'disconnected';
const defaultTableSubState = undefined;

/**
 * Parse the path to ascern whether table state should be picked up from there
 *
 * @param {string} [pathname=''] The path in the URL (without the domain)
 * @return {string} Role to use.
 */
export function getTableStatesFromPath(pathname = '') {
  const params = pathname.split('/').filter((param) => param.trim());

  let tableStateCandidate = defaultTableState;
  let tableSubStateCandidate = defaultTableSubState;

  for (let i = 0; i < params.length; i++) {
    const param = params[i];

    if (TABLESTATES[param]) {
      tableStateCandidate = TABLESTATES[param];
      // The format here is that ..../mainState/subState -
      // so next param in the order is the one we are using for sub state
      // Do not evaluate this against the table since we have dynamic substates (id etc)
      tableSubStateCandidate = params[i + 1];
      break;
    }
  }

  return {
    tableState: tableStateCandidate,
    tableSubState: tableSubStateCandidate
  };
}

/**
 * Method to get the table state and substate for the dashboard screens based on parameters from the URL.
 * This method fetch the values and returns them in an object.
 *
 * @return {Object} Object with "tableState", and  "tableSubState".
 */
export function getTableStates() {
  const { tableState, tableSubState } = getTableStatesFromPath(
    window.location.pathname
  );

  return { tableState, tableSubState };
}
