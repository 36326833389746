import React, { useCallback } from 'react';
import { TwitterPicker, SwatchesPicker, ColorResult } from 'react-color';
import pickerStyles from './ColorPickerDialog.module.css';
import colors from 'ecto-common/lib/styles/variables/colors';
import material from 'material-colors';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import T from '../lang/Language';
import Icons from 'ecto-common/lib/Icons/Icons';
import ModalDivider from 'ecto-common/lib/Modal/ModalDivider';

// Same swatches as standard SwatchesPicker uses, but every other column is removed.
const swatches = [
  [
    material.red['900'],
    material.red['700'],
    material.red['500'],
    material.red['300'],
    material.red['100']
  ],
  [
    material.purple['900'],
    material.purple['700'],
    material.purple['500'],
    material.purple['300'],
    material.purple['100']
  ],
  [
    material.indigo['900'],
    material.indigo['700'],
    material.indigo['500'],
    material.indigo['300'],
    material.indigo['100']
  ],
  [
    material.lightBlue['900'],
    material.lightBlue['700'],
    material.lightBlue['500'],
    material.lightBlue['300'],
    material.lightBlue['100']
  ],
  [
    material.teal['900'],
    material.teal['700'],
    material.teal['500'],
    material.teal['300'],
    material.teal['100']
  ],
  [
    material.lightGreen['900'],
    material.lightGreen['700'],
    material.lightGreen['500'],
    material.lightGreen['300'],
    material.lightGreen['100']
  ],
  [
    material.yellow['900'],
    material.yellow['700'],
    material.yellow['500'],
    material.yellow['300'],
    material.yellow['100']
  ],
  [
    material.orange['900'],
    material.orange['700'],
    material.orange['500'],
    material.orange['300'],
    material.orange['100']
  ],
  [
    material.brown['900'],
    material.brown['700'],
    material.brown['500'],
    material.brown['300'],
    material.brown['100']
  ],
  ['#000000', '#525252', '#969696', '#D9D9D9', '#FFFFFF']
];

const colorSwatch = [
  colors.primary1Color,
  colors.primary2Color,
  colors.primary3Color,
  colors.active1Color,
  colors.accent1Color,
  colors.accent2Color,
  colors.accent3Color,
  colors.accent4Color,
  colors.accent5Color,
  '#FF9114'
];

interface ColorPickerDialogProps {
  color?: string;
  onChange(color: ColorResult, isCustomColor: boolean): void;
  onClose(): void;
  footer?: React.ReactNode;
  isOpen?: boolean;
  title?: string;
  isValid?: boolean;
}

const ColorPickerDialog = ({
  isOpen,
  color,
  onChange,
  onClose,
  footer,
  isValid = true,
  title = T.common.selectcolor
}: ColorPickerDialogProps) => {
  const onChangeComplete = useCallback(
    (newColor: ColorResult) => {
      onChange(newColor, !colorSwatch.includes(newColor.hex.toLowerCase()));
    },
    [onChange]
  );

  return (
    <ActionModal
      isOpen={isOpen}
      title={title}
      onModalClose={onClose}
      onConfirmClick={onClose}
      disableCancel
      actionText={T.common.done}
      headerIcon={Icons.Color}
      className={pickerStyles.modal}
      disableActionButton={!isValid}
    >
      <SwatchesPicker
        color={color}
        onChangeComplete={onChangeComplete}
        width={500}
        height={135}
        colors={swatches}
      />
      <ModalDivider />
      <TwitterPicker
        color={color}
        colors={colorSwatch}
        onChangeComplete={onChangeComplete}
        className={pickerStyles.zIndex}
        width="500"
        triangle="hide"
      />
      {footer && <ModalDivider />}
      {footer}
    </ActionModal>
  );
};

export default ColorPickerDialog;
