import React from 'react';
import classNames from 'classnames';
import styles from './Toolbar.module.css';

interface ToolbarProps {
  /**
   * The content of the toolbar.
   */
  children?: React.ReactNode;
  /**
   * Used to override the appearance of the toolbar. Should be a valid CSS class name.
   */
  className?: string;
  /**
   * If set to true adapt the styling to make it look good at the top of a page.
   */
  isPageHeadingToolbar?: boolean;

  /**
   * If true, add top margin from preceding element
   */
  withTopMargin?: boolean;
}

/**
 * This component is typically used at the top of a page. Shows a header and a configurable set of input controls. Note that we typically only ever use with isPageHeadingToolbar set to true now and we might remove the option to use it in any other way in the future.
 */
const Toolbar = React.forwardRef<HTMLDivElement, ToolbarProps>(
  (
    {
      children,
      className,
      isPageHeadingToolbar = false,
      withTopMargin = false
    },
    ref
  ) => {
    return (
      <div
        ref={ref}
        className={classNames(
          styles.toolbar,
          isPageHeadingToolbar && styles.pageToolbar,
          withTopMargin && styles.withTopMargin,
          className
        )}
      >
        {children}
      </div>
    );
  }
);

export default React.memo(Toolbar);
