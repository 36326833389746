import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import _ from 'lodash';

import SearchInput from 'ecto-common/lib/SearchInput/SearchInput';
import LocationTreeView from 'ecto-common/lib/LocationTreeView/LocationTreeView';
import dimensions from 'ecto-common/lib/styles/dimensions';
import { ROOT_NODE_ID } from 'ecto-common/lib/constants';

import styles from './SelectEquipment.module.css';
import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';
import { EquipmentResponseModel } from 'ecto-common/lib/API/APIGen';
import { getNodeFromMap } from 'ecto-common/lib/utils/locationUtils';
import { CustomNodeTreeSet } from 'ecto-common/lib/SelectNodeDialog/SelectNode';

interface SelectEquipmentProps {
  nodeId?: string;
  selectFromCurrentNodeOnly?: boolean;
  equipmentId?: string;
  onEquipmentSelected(equipmentId: string): void;
  customNodeTreeSet?: CustomNodeTreeSet;
}

const SelectEquipment = ({
  nodeId,
  selectFromCurrentNodeOnly,
  equipmentId,
  onEquipmentSelected,
  customNodeTreeSet = null
}: SelectEquipmentProps) => {
  let nodeMap = useCommonSelector((state) => state.general.nodeMap);
  let equipmentMap = useCommonSelector((state) => state.general.equipmentMap);
  let nodeTree = useCommonSelector((state) => state.general.nodeTree);

  if (customNodeTreeSet) {
    nodeMap = customNodeTreeSet.nodeMap;
    equipmentMap = customNodeTreeSet.equipmentMap;
    nodeTree = customNodeTreeSet.nodeTree;
  }

  const isRootNode = _.startsWith(
    _.defaultTo(nodeId, ROOT_NODE_ID),
    ROOT_NODE_ID
  );
  const [selectedIds, setSelectedIds] = useState(
    isRootNode ? [] : _.compact([nodeId])
  );
  const scrollerRef = useRef(null);

  useEffect(() => setSelectedIds([equipmentId]), [equipmentId]);

  const [filterText, setFilterText] = useState('');

  const updateSearch = useCallback((value: string) => {
    if (scrollerRef.current) {
      scrollerRef.current.scrollTop = 0;
    }

    setFilterText(_.toLower(value));
  }, []);

  const onSelectedChanged = useCallback(
    (itemId: string) => {
      setSelectedIds([itemId]);
      onEquipmentSelected(itemId);
    },
    [onEquipmentSelected]
  );

  const filteredNodeTree = useMemo(() => {
    if (selectFromCurrentNodeOnly) {
      const node = getNodeFromMap(nodeMap, nodeId);
      return node ? [node] : [];
    }

    return nodeTree;
  }, [selectFromCurrentNodeOnly, nodeTree, nodeId, nodeMap]);

  // Used when we have only one node, so we only search for equipment
  const equipmentFilter = useCallback(
    (equipment: EquipmentResponseModel) => {
      return equipment.name?.toLowerCase().indexOf(filterText) !== -1;
    },
    [filterText]
  );

  // Used when we have more than one node, which means we are going to search for nodes not equipments
  const nodeSearchFilter = useMemo(() => {
    if (!selectFromCurrentNodeOnly) {
      return { searchTerm: filterText };
    }
    return null;
  }, [selectFromCurrentNodeOnly, filterText]);

  return (
    <>
      <div className={styles.filterBox}>
        <SearchInput onChange={updateSearch} />
      </div>
      <div
        style={{ flexGrow: 1, overflowY: 'auto' }}
        data-parent-scroller="true"
        ref={scrollerRef}
      >
        <LocationTreeView
          searchFilter={nodeSearchFilter}
          selectEquipment
          equipmentFilter={selectFromCurrentNodeOnly ? equipmentFilter : null}
          expandAll={selectFromCurrentNodeOnly}
          selectedIds={selectedIds}
          sidePadding={
            selectFromCurrentNodeOnly ? dimensions.standardMargin : 0
          }
          className={styles.treeView}
          onChangeSelectedState={onSelectedChanged}
          nodeTree={filteredNodeTree}
          nodeMap={nodeMap}
          equipmentMap={equipmentMap}
          focusedId={_.head(selectedIds)}
        />
      </div>
    </>
  );
};

export default SelectEquipment;
