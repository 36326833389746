import React, { useEffect, useMemo, useRef, useCallback } from 'react';
import T from 'ecto-common/lib/lang/Language';
import { extractDataLength } from 'ecto-common/lib/Charts/ChartUtil';
import { Highcharts } from 'ecto-common/lib/Highcharts/Highcharts';
import HighchartsReact, {
  HighchartsReactRefObject
} from 'highcharts-react-official';
import _ from 'lodash';
import { DEFAULT_TIMEZONE } from 'ecto-common/lib/constants';
import {
  CHART_BOOST_THRESHOLD,
  arrayMergerHighcharts,
  boostHighchartsSettings
} from 'ecto-common/lib/SignalSelector/ChartUtils';

// Some series have no unit since they are in the "general"-measurement in influx.
// We call those 'n/a' to actually have something to show.

const STOCKCHART_CONTAINER_PROPS = {
  style: {
    height: '100%',
    width: '100%'
  }
};

type ScatterChartProps = {
  isLoading?: boolean;
  hasError?: boolean;
  hasPointsOverflow: boolean;
  containerWidth?: number;
  config: Highcharts.Options;
  /**
   * To keep a consistent visibility state of series when zooming, we need to have a
   * unique ID of every series. @see StockChartProps
   */
  seriesIds?: string[];
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  containerProps?: { [key: string]: any };
};

type SeriesVisibilityChangeFunction = (
  series: Highcharts.Series,
  visible: boolean
) => void;

/**
 * This class wraps a Highcharts stock chart component. It provides properties for controlling
 * some additional features that are commonly used in our app. The config object format is the
 * same as Highcharts uses. We do provide some config defaults that are more suitable for our
 * application. For more information, see: https://www.highcharts.com/blog/highstock/
 */
const ScatterChart = React.forwardRef<
  HighchartsReactRefObject,
  ScatterChartProps
>(
  (
    {
      isLoading = false,
      config,
      seriesIds,
      hasError = false,
      containerWidth = 0,
      hasPointsOverflow = false,
      containerProps = STOCKCHART_CONTAINER_PROPS
    }: ScatterChartProps,
    forwardRef
  ) => {
    const ref = useRef<HighchartsReactRefObject>();

    if (
      config != null &&
      config.series != null &&
      seriesIds != null &&
      config.series.length !== seriesIds.length
    ) {
      console.error('Series IDs length does not match series length');
    }

    // Highcharts does not retain the series visibility state when the chart is zoomed.
    // See StockChart for more information. Use this to keep track of the visibility state.
    const chartVisibilityState = useRef<Record<string, boolean>>({});

    const onVisibilityChange: SeriesVisibilityChangeFunction = useCallback(
      (series, show) => {
        if (seriesIds != null) {
          chartVisibilityState.current[seriesIds[series.index]] = show;
        } else {
          console.error(
            'Got visibility change but no series IDs were provided',
            series.index
          );
        }
      },
      [seriesIds]
    );

    const fullConfig = useMemo(() => {
      const _onShow: Highcharts.SeriesShowCallbackFunction = function () {
        onVisibilityChange(this, true);
      };

      const _onHide: Highcharts.SeriesHideCallbackFunction = function () {
        onVisibilityChange(this, false);
      };

      return _.mergeWith(
        {
          ...boostHighchartsSettings(),
          time: {
            useUTC: false,
            timezone: DEFAULT_TIMEZONE
          },
          title: {
            text: null
          },
          plotOptions: {
            scatter: {
              marker: {
                radius: 5,
                states: {
                  hover: {
                    enabled: true,
                    lineColor: 'rgb(100,100,100)'
                  }
                }
              },
              states: {
                hover: {
                  marker: {
                    enabled: false
                  }
                }
              },
              tooltip: {
                headerFormat: '<b>{series.name}</b><br>'
              }
            }
          },
          credits: {
            enabled: false
          },
          navigator: {
            adaptToUpdatedData: false,
            height: 0,
            enabled: false,
            series: null
          },
          chart: {
            type: 'scatter',
            resetZoomButton: {
              theme: {
                style: {
                  display: 'none'
                }
              }
            }
          }
        },
        config,
        {
          series: config.series.map((s, index) => {
            return {
              ...s,
              boostThreshold: CHART_BOOST_THRESHOLD,
              turboThreshold: CHART_BOOST_THRESHOLD,
              // TODO: Fix typing for telemetry series
              visible:
                seriesIds == null ||
                (chartVisibilityState.current[seriesIds[index]] ?? true),
              events: {
                show: _onShow,
                hide: _onHide,
                ...s.events
              }
            };
          })
        },
        arrayMergerHighcharts
      );
    }, [config, onVisibilityChange, seriesIds]);

    useEffect(() => {
      if (ref.current) {
        const chart = ref.current.chart;
        chart.reflow();
      }
    }, [containerWidth]);

    // We use the loading text as a generic label mechanism for displaying information.
    useEffect(() => {
      if (ref.current) {
        const chart = ref.current.chart;

        if (hasError) {
          chart.showLoading(T.powercontrol.dispatch.chart.loadingdata.failure);
        } else if (hasPointsOverflow) {
          chart.showLoading(T.graphs.pointsoverflow);
        } else if (isLoading) {
          chart.showLoading(T.common.loading);
        } else if (config.series.length === 0) {
          chart.showLoading(T.graphs.nosignalsfound);
        } else if (
          _.find(config.series, (s) => extractDataLength(s) > 0) == null
        ) {
          chart.showLoading(T.powercontrol.dispatch.chart.nodata);
        } else {
          chart.hideLoading();
        }
      }
    }, [ref, isLoading, hasError, config, hasPointsOverflow]);
    const assignRef = useCallback(
      (value: HighchartsReactRefObject) => {
        if (forwardRef) {
          if (typeof forwardRef === 'function') {
            forwardRef(value);
          } else if (forwardRef) {
            forwardRef.current = value;
          }
        }
        ref.current = value;
      },
      [forwardRef]
    );

    return (
      <HighchartsReact
        options={fullConfig}
        highcharts={Highcharts}
        ref={assignRef}
        containerProps={containerProps}
      />
    );
  }
);

export default React.memo(ScatterChart);
