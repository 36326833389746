import React from 'react';
import styles from '../ModelEditor.module.css';
import classNames from 'classnames';
import Spinner, { SpinnerSize } from 'ecto-common/lib/Spinner/Spinner';
import { KeyValueGeneric } from 'ecto-common/lib/KeyValueInput/KeyValueGeneric';
import { ModelEditorProps } from 'ecto-common/lib/ModelForm/ModelEditor';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import { ModelDefinitionInternal } from 'ecto-common/lib/ModelForm/ModelPropType';

export type LabelModelDefinition<
  ObjectType extends object,
  EnvironmentType extends object = object
> = {
  modelType: typeof ModelType.LABEL;
  dataFormatter?: (
    rawValue: string | number,
    input: ObjectType
  ) => React.ReactNode;
} & ModelDefinitionInternal<ObjectType, EnvironmentType, string | number>;

type ModelEditorLabelProps = ModelEditorProps & {
  formattedValue: string;
  model: LabelModelDefinition<object>;
};

const ModelEditorLabel = ({
  model,
  isHorizontal,
  modelIsLoading,
  formattedValue
}: ModelEditorLabelProps) => {
  return (
    <KeyValueGeneric isHorizontal={isHorizontal} keyText={model.label}>
      <div className={styles.container}>
        <div
          className={classNames(
            styles.labelValue,
            modelIsLoading && styles.hidden
          )}
        >
          {formattedValue}
        </div>
        <Spinner
          size={SpinnerSize.TINY}
          className={classNames(
            styles.spinner,
            !modelIsLoading && styles.hiddenWithLayout
          )}
        />
      </div>
    </KeyValueGeneric>
  );
};

export default React.memo(ModelEditorLabel);
