module.exports = {
  defaultSpeed: '0.2s',
  dataTableSpeed: '0.3s',
  spinnerSpeed: '1.1s',
  sidebarSpeed: '0.15s',
  modalSpeed: '0.2s',
  loadingScreenSpeed: '0.3s',
  toastSpeed: '0.25s',
  tooltipSpeed: '0.1s',

  // Easing
  jumpyEasing: 'cubic-bezier(0.6, 0.4, 0.2, 1.4)',
  motionEasing: 'cubic-bezier(0.23, 1, 0.32, 1)'
};
