const framesBetweenPrintouts = 100;
let currentFrameCount = 0;

const measurePoints: any = {
  callback: undefined,
  children: [],
  current: undefined
};

const pushMeasurePoint = (cb) => {
  if (!measurePoints.current) {
    measurePoints.callback = cb;
    measurePoints.current = measurePoints;
    measurePoints.children = [];
    currentFrameCount++;
    return;
  }

  const newCurrent: any = {
    callback: cb,
    children: [],
    parent: measurePoints.current
  };

  measurePoints.current.children.push(newCurrent);
  measurePoints.current = newCurrent;
};

let lastPrintedMeasure;

const _buildMeasureTree = (current, tab = '') => {
  const measureNode = current.callback();

  measureNode.tab = tab;
  measureNode.children = [];

  current.children.forEach((child) => {
    measureNode.children.push(_buildMeasureTree(child, tab + '  '));
  });

  return measureNode;
};

const _compareMeasureTrees = (node1, node2) => {
  if (!node1 || !node2) {
    return true;
  }

  if (node1.label !== node2.label) {
    return true;
  }

  if (
    Math.abs(1 - node1.time / node2.time) > 0.2 &&
    Math.abs(node1.time - node2.time) > 3
  ) {
    return true;
  }

  if (node1.children.length !== node2.children.length) {
    return true;
  }

  for (let i = node1.children.length - 1; i >= 0; i--) {
    if (_compareMeasureTrees(node1.children[i], node2.children[i])) {
      return true;
    }
  }

  return false;
};

const _printTree = (node) => {
  console.info(node.tab + '[' + node.label + ']' + node.time);
  node.children.forEach(_printTree);
};

const popMeasurePoint = () => {
  measurePoints.current = measurePoints.current.parent;

  // If reached the top
  if (!measurePoints.current) {
    const currentTree = _buildMeasureTree(measurePoints);

    if (
      currentFrameCount % framesBetweenPrintouts === 0 ||
      _compareMeasureTrees(lastPrintedMeasure, currentTree)
    ) {
      _printTree(currentTree);
      lastPrintedMeasure = currentTree;
    }
  }
};

let performanceMeasure = false;

const startMeasureFor = (object) => {
  if (!performanceMeasure) return;

  object.preDrawTimeStamp = performance.now();
  pushMeasurePoint(() => {
    const time = object.postDrawTimeStamp - object.preDrawTimeStamp;
    const label = object.name;

    return { label, time };
  });

  return object;
};

const stopMeasureFor = (object) => {
  if (!performanceMeasure || !object) return;

  object.postDrawTimeStamp = performance.now();
  popMeasurePoint();
};

export default {
  performanceMeasure,
  startMeasureFor,
  stopMeasureFor,
  pushMeasurePoint,
  popMeasurePoint
};
