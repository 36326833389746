import React, { memo, Fragment } from 'react';
import styles from './TagsGroup.module.css';
import classNames from 'classnames';
import Tag from 'ecto-common/lib/Tag/Tag';

type TagsGroupProps = {
  tags: string[];
  className?: string;
};

const TagsGroup = ({ tags, className = null }: TagsGroupProps) => (
  <Fragment>
    {tags.map((t, i) => (
      <div
        className={classNames(className, styles.tagContainer)}
        key={'div' + i}
      >
        <Tag key={'Tag:' + t}>{t}</Tag>
      </div>
    ))}
  </Fragment>
);

export default memo(TagsGroup);
