import React, { useCallback, useMemo } from 'react';
import _ from 'lodash';
import AddButton from 'ecto-common/lib/Button/AddButton';
import CurveSeriesTable from 'ecto-common/lib/CurveSeriesTable/CurveSeriesTable';
import T from 'ecto-common/lib/lang/Language';
import { getSignalColor } from 'ecto-common/lib/SignalSelector/StockChart.config';
import DataTable from 'ecto-common/lib/DataTable/DataTable';
import { buttonListColumn } from 'ecto-common/lib/utils/dataTableUtils';
import Icons from 'ecto-common/lib/Icons/Icons';
import Flex, {
  AlignItems,
  FlexItem,
  JustifyContent
} from 'ecto-common/lib/Layout/Flex';
import LayoutDirection from 'ecto-common/lib/types/LayoutDirection';
import { ModelEditorProps } from 'ecto-common/lib/ModelForm/ModelEditor';
import { ModelDefinition } from 'ecto-common/lib/ModelForm/ModelPropType';
import { AdjustableChartCurve } from 'ecto-common/lib/AdjustableChart/AdjustableChart';
import { SignalInputType } from 'ecto-common/lib/Dashboard/datasources/LastSignalValuesDataSource';
import { SignalTimeSeriesDataInputCurve } from 'ecto-common/lib/Dashboard/datasources/SignalTimeSeriesDataSource';

/**
 *
 * @param {ButtonColumnCallback} onCurveChanged
 * @param {array} optionalSignalModels
 * @param {ButtonColumnCallback} removeCurve
 * @param useConstantValues Whether to include constant signal values
 * @returns {array}
 */
const createColumns = (
  onCurveChanged: (curve: AdjustableChartCurve, index: number) => void,
  optionalSignalModels: ModelDefinition<SignalInputType>[],
  removeCurve: (item: AdjustableChartCurve, index: number) => void,
  useConstantValues: boolean
) => [
  {
    dataKey: '_unused',
    dataFormatter: (
      _unused: unknown,
      curve: AdjustableChartCurve,
      index: number
    ) => {
      return (
        <CurveSeriesTable
          curve={curve}
          onCurveChanged={(newCurve: AdjustableChartCurve) =>
            onCurveChanged(newCurve, index)
          }
          optionalSignalModels={optionalSignalModels}
          useConstantValues={useConstantValues}
        />
      );
    }
  },
  buttonListColumn([
    {
      icon: <Icons.Delete />,
      action: removeCurve
    }
  ])
];

const EMPTY_ARRAY: SignalTimeSeriesDataInputCurve[] = [];

type SignalTimeSeriesModelEditorProps = Omit<ModelEditorProps, 'rawValue'> & {
  rawValue: SignalTimeSeriesDataInputCurve[];
  optionalSignalModels?: ModelDefinition<SignalInputType>[];
  maxItems?: number;
  useConstantValues?: boolean;
};

const SignalTimeSeriesModelEditor = ({
  rawValue,
  updateItem,
  optionalSignalModels,
  maxItems,
  useConstantValues
}: SignalTimeSeriesModelEditorProps) => {
  const disabledAddButton = maxItems && rawValue?.length >= maxItems;

  const onAddCurve = useCallback(() => {
    updateItem([
      ...(rawValue ?? []),
      {
        series: [],
        color: getSignalColor(null, _.size(rawValue))
      }
    ]);
  }, [rawValue, updateItem]);

  const onCurveChanged = useCallback(
    (curve: AdjustableChartCurve, index: number) => {
      const newValues = [...rawValue];

      newValues[index] = curve as SignalTimeSeriesDataInputCurve;
      updateItem(newValues);
    },
    [rawValue, updateItem]
  );

  const removeCurve = useCallback(
    (unused: AdjustableChartCurve, index: number) => {
      const newValues = [...rawValue];
      _.pullAt(newValues, [index]);
      updateItem(newValues);
    },
    [updateItem, rawValue]
  );

  const columns = useMemo(
    () =>
      createColumns(
        onCurveChanged,
        optionalSignalModels,
        removeCurve,
        useConstantValues
      ),
    [useConstantValues, onCurveChanged, optionalSignalModels, removeCurve]
  );

  return (
    <Flex
      direction={LayoutDirection.VERTICAL}
      justifyContent={JustifyContent.FLEX_END}
    >
      <FlexItem>
        <DataTable
          data={rawValue ?? EMPTY_ARRAY}
          columns={columns}
          noDataText={T.admin.dashboards.forms.signalnames.empty}
          showNoticeHeaders={false}
          disableHeader
        />
      </FlexItem>
      <FlexItem align={AlignItems.END}>
        <AddButton disabled={disabledAddButton} onClick={onAddCurve}>
          {T.curves.add}
        </AddButton>
      </FlexItem>
    </Flex>
  );
};

export default React.memo(SignalTimeSeriesModelEditor);
