import Settings from './Settings';
import Util from './util';

import {
  vectorMag,
  vectorSub,
  getTriangleCenter,
  findSignificantVertex,
  A,
  B,
  C,
  TWO_PI
} from './triangleUtils';
import { TableContextType } from 'js/Table/context';

/**
 * One collection of three touchpoints that make up a definition of a touch object
 * @class TouchTriangle
 */
class TouchTriangle {
  context: TableContextType;
  moveThreshold: number;
  circumference: number;
  significantVertex: number;
  fullRotations: number;
  zeroRotationAngle: number;
  fingerPrint: number;
  listeners: any[];
  cache: any;
  createVector: any;
  a: any;
  b: any;
  c: any;
  touchA: any;
  touchB: any;
  touchC: any;
  lastRotationAngle: number;

  /*
   * @param {touchPoint} a
   * @param {touchPoint} b
   * @param {touchPoint} c
   * @param {number} circumference In 'pixels' adjusted for screen size
   */
  constructor(context, a, b, c, circumference, createVector) {
    this.context = context;

    // below this value in pixels the structure doesn't react to move.
    // This value changes over time, so that it gets less sensitive the longer it stands still
    this.moveThreshold = Settings.moveThreshold;
    this.createVector = createVector;

    this._moveTo(a, b, c);
    this.circumference = circumference;

    // a variable representing what vertex is the odd one.
    this.significantVertex = findSignificantVertex(this.a, this.b, this.c);

    this.fullRotations = 0;

    this.zeroRotationAngle = 0;
    this.zeroRotationAngle = this.getRotationAngle();

    this.fingerPrint = this.getAngle();

    this.listeners = [];

    this.cache = {};
  }

  addListener(listener) {
    this.listeners.push(listener);

    if (listener.touchTriangleCenterUpdated !== undefined) {
      listener.touchTriangleCenterUpdated(this.center());
    }
    if (listener.touchTriangleRotationUpdated !== undefined) {
      listener.touchTriangleRotationUpdated(this.getRotationAngle());
    }
    if (listener.touchTriangleListenerAdded !== undefined) {
      listener.touchTriangleListenerAdded(this);
    }
  }

  /**
   * Answers if this structure, made up of three touch points, contains a given
   * touch point or not
   * @param {Object} touchId  Touch point containing an id
   * @return {Boolean}  TRUE if it contains the touch point and false if not.
   */
  contains(touchId) {
    if (
      this.touchA.identifier === touchId ||
      this.touchB.identifier === touchId ||
      this.touchC.identifier === touchId
    ) {
      return true;
    }
    return false;
  }

  center() {
    return getTriangleCenter(this.a, this.b, this.c);
  }

  _getSignificantPoint() {
    switch (this.significantVertex) {
      case A:
        return this.a;
      case B:
        return this.b;
      case C:
        return this.c;
      default:
    }
  }

  getAbsoluteAngle() {
    // @ts-ignore-next-line: Incorrect definition @types/p5
    let v = this.context.Vector.sub(this._getSignificantPoint(), this.center());
    let angle = v.angleBetween(this.createVector(50, 0)); // a straight line to compare against

    return v.y > 0 ? angle : -angle;
  }

  /**
   * Gets the angle that side C (a -> b) has against [1,0]
   * @return {Number} The angle, in radians.
   */
  getRotationAngle() {
    if (!this.cache.rotationAngle) {
      // @ts-ignore-next-line: Incorrect definition @types/p5
      let v = this.context.Vector.sub(this.a, this.center());
      let angle = v.angleBetween(this.createVector(50, 0)); // a straight line to compare against

      angle = angle || 0;

      this.cache.rotationAngle = angle;

      // If we have made a jump from 359 to 1 for example
      if (this.lastRotationAngle - angle > Settings.unreasonablyBigJump) {
        this.fullRotations++;
      }
      // If we have jumped in the other direction - past 0
      else if (angle - this.lastRotationAngle > Settings.unreasonablyBigJump) {
        this.fullRotations--;
      }

      this.lastRotationAngle = angle;
      this.cache.rotationAngle = (angle || 0) + this.fullRotations * TWO_PI;
    }
    return this.cache.rotationAngle - this.zeroRotationAngle;
  }

  /**
   * Takes a touch point as input, and if this structure contains that touchpoint
   * (identified by identifier) it is updated, and the structure is moved
   *
   * @param {Touch} touch   A touch object from a touch event. Must contain
   * an identifier.
   *
   * @return {Boolean} True if this structure contained the touchpoint
   * and it was updated, otherwise false.
   */
  updateTouchPoint(touch) {
    let a = this.touchA;
    let b = this.touchB;
    let c = this.touchC;
    let moved = false;

    if (touch.identifier === a.identifier) {
      a = touch;
      moved = true;
    } else if (touch.identifier === b.identifier) {
      b = touch;
      moved = true;
    } else if (touch.identifier === c.identifier) {
      c = touch;
      moved = true;
    }

    if (moved) {
      this._moveTo(a, b, c);

      this.listeners.forEach((listener) => {
        if (listener.touchTriangleRotationUpdated !== undefined) {
          listener.touchTriangleRotationUpdated(this.getRotationAngle());
        }
      });
      return true;
    }
    return false;
  }

  /** Calculates the angle of the odd point of the triangle */
  getAngle() {
    {
      // calculate sides
      const sideC = vectorMag(vectorSub(this.a, this.b));
      const sideA = vectorMag(vectorSub(this.c, this.b));
      const sideB = vectorMag(vectorSub(this.a, this.c));

      const sq = Util.sq;

      if (this.significantVertex === A) {
        this.cache.angle = Util.degrees(
          Math.acos((sq(sideB) + sq(sideC) - sq(sideA)) / (2 * sideB * sideC))
        );
      } else if (this.significantVertex === B) {
        this.cache.angle = Util.degrees(
          Math.acos((sq(sideA) + sq(sideC) - sq(sideB)) / (2 * sideA * sideC))
        );
      } else {
        this.cache.angle = Util.degrees(
          Math.acos((sq(sideB) + sq(sideA) - sq(sideC)) / (2 * sideB * sideA))
        );
      }
    }
    return this.cache.angle;
  }

  _moveTo(a, b, c) {
    if (
      !this.touchA ||
      !this.touchB ||
      !this.touchC ||
      Math.abs(a.clientX - this.touchA.clientX) > this.moveThreshold ||
      Math.abs(a.clientY - this.touchA.clientY) > this.moveThreshold ||
      Math.abs(b.clientX - this.touchB.clientX) > this.moveThreshold ||
      Math.abs(b.clientY - this.touchB.clientY) > this.moveThreshold ||
      Math.abs(c.clientX - this.touchC.clientX) > this.moveThreshold ||
      Math.abs(c.clientY - this.touchC.clientY) > this.moveThreshold
    ) {
      this.cache = {};

      this.moveThreshold = 0;

      this.touchA = a;
      this.touchB = b;
      this.touchC = c;

      if (this.createVector !== undefined) {
        this.a = this.createVector(a.clientX, a.clientY);
        this.b = this.createVector(b.clientX, b.clientY);
        this.c = this.createVector(c.clientX, c.clientY);
      }
      this.listeners &&
        this.listeners.forEach((listener) => {
          if (listener.touchTriangleCenterUpdated !== undefined) {
            listener.touchTriangleCenterUpdated(this.center());
          }
        });
    }
  }
}

export default TouchTriangle;
