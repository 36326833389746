import { DataTableSectionHeaderType } from 'ecto-common/lib/DataTable/DataTable';
import React from 'react';
import styles from './DataTable.module.css';
import classNames from 'classnames';

type DataTableSectionHeaderViewProps = {
  section: DataTableSectionHeaderType;
};

const DataTableSectionHeaderView = ({
  section
}: DataTableSectionHeaderViewProps) => {
  return (
    <div
      className={classNames(
        styles.row,
        styles.dataRow,
        styles.sectionHeaderRow
      )}
    >
      {section.sectionTableHeader}
    </div>
  );
};

export default React.memo(DataTableSectionHeaderView);
