import styles from 'ecto-common/lib/TableColumn/TableColumn.module.css';
import React, { CSSProperties } from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

interface TableColumnProps {
  /**
   * The title (top label) of the column
   */
  title?: React.ReactNode;
  /**
   * Optional icon that is shown to the left of both labels
   */
  icon?: React.ReactNode;
  /**
   * The subtitle (bototm label) of the column
   */
  subtitle?: React.ReactNode;
  /**
   * Whether or not to horizontally center the labels
   */
  centered?: boolean;
  /**
   * Used to override the appearance of the component. Should be a valid CSS class name.
   */
  className?: string;
  /**
   * True by default. Determines if text should be truncated before creating a new line.
   */
  truncateText?: boolean;
  /**
   * Set to change the minimum height of the column
   */
  minHeight?: number;
  /**
   * Use title as a link
   */
  titleLink?: string;
  /**
   * Use subtitle as a link
   */
  subtitleLink?: string;
  /**
   * Whether or not the table column is currently selected
   */
  active?: boolean;
}
const stopPropagation = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
  event.stopPropagation();
};
/**
 * This component is used for DataTable layouts where multiple lines are needed.
 */
const TableColumn = ({
  title,
  icon,
  subtitle,
  centered,
  className,
  minHeight = 32,
  truncateText = true,
  titleLink,
  subtitleLink,
  active = false
}: TableColumnProps) => {
  const truncateStyle: CSSProperties = truncateText
    ? {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
      }
    : undefined;

  return (
    <div className={styles.columnLayout} style={{ minHeight }}>
      {icon && <div className={styles.icon}>{icon}</div>}
      <div
        style={{ minHeight }}
        className={classNames(
          styles.rowTitles,
          centered && styles.centered,
          className
        )}
      >
        {titleLink && (
          <NavLink
            className={classNames(styles.rowTitle, active && styles.active)}
            to={titleLink}
            style={truncateStyle}
            onClick={stopPropagation}
          >
            {title}
          </NavLink>
        )}
        {!titleLink && (
          <div
            className={classNames(styles.rowTitle, active && styles.active)}
            style={truncateStyle}
          >
            {title}
          </div>
        )}

        {subtitleLink && subtitle && (
          <NavLink
            className={classNames(
              styles.rowSubtitleLink,
              active && styles.active
            )}
            to={subtitleLink}
            onClick={stopPropagation}
          >
            {subtitle}
          </NavLink>
        )}
        {!subtitleLink && subtitle && (
          <div
            className={classNames(styles.rowSubtitle, active && styles.active)}
          >
            {subtitle}
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(TableColumn);
