import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import T from 'ecto-common/lib/lang/Language';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import { KeyValueInput } from 'ecto-common/lib/KeyValueInput/KeyValueInput';
import Icons from 'ecto-common/lib/Icons/Icons';

interface MessageDialogProps {
  title: string;
  messageTitle?: string;
  onModalClose: () => void;
  isLoading?: boolean;
  isOpen: boolean;
  onConfirmMessage?: (message: string) => void;
  message?: string;
  isRequired?: boolean;
}
const MessageDialog = ({
  title,
  messageTitle,
  message,
  isLoading,
  isOpen,
  onConfirmMessage,
  onModalClose,
  isRequired
}: MessageDialogProps) => {
  const [internalMessage, setMessage] = useState<string>(message);
  useEffect(() => {
    if (isOpen) {
      setMessage(message);
    }
  }, [isOpen, message]);

  return (
    <ActionModal
      compact
      headerIcon={Icons.Message}
      onModalClose={onModalClose}
      isOpen={isOpen}
      isLoading={isLoading}
      title={title}
      actionText={T.common.ok}
      onConfirmClick={() => onConfirmMessage(internalMessage)}
      disableActionButton={isRequired && _.isEmpty(internalMessage)}
    >
      <KeyValueInput
        keyText={messageTitle}
        value={internalMessage}
        onChange={(event) => setMessage(event.target.value)}
        hasError={isRequired && _.isEmpty(internalMessage)}
        autoFocus
      />
    </ActionModal>
  );
};

export default MessageDialog;
