import TableP5 from 'js/Table/TableP5';
import { TableContextType } from 'js/Table/context';

/**
 * The renderable class is a base class for things that follow the render paradigm
 * @class Renderable
 */
class Renderable {
  context: TableContextType;
  name: string;
  static implement: (object: any, context: TableContextType) => void;

  constructor(context: TableContextType) {
    this.context = context;
    this.name = 'Renderable';

    Renderable.implement(this, context);
  }

  /**
   * @throws {String} this is supposed to be overloaded
   */
  draw(_p5Instance: TableP5) {
    throw 'is never supposed to be called';
  }
}

/**
 * @throws {String} object doesn't properly implement interface
 */
Renderable.implement = (object, _) => {
  if (!object.draw || !object.name || !object.context) {
    throw 'Object doesnt implement interface';
  }

  object.drawActual = object.draw;

  object.draw = function (p5) {
    object.drawPre();
    object.drawActual(p5);
    object.drawPost();
  };

  object.drawPre = function () {
    const context = this.context;

    if (context.performanceMeasure) {
      context.startMeasureFor(this);
    }
  };
  object.drawPre.bind(object);

  object.drawPost = function () {
    const context = this.context;

    if (context.performanceMeasure) {
      context.stopMeasureFor(this);
    }
  };
  object.drawPost.bind(object);
};

export default Renderable;
