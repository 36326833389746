import React from 'react';

import Tooltip from 'ecto-common/lib/Tooltip/Tooltip';
import Icons from 'ecto-common/lib/Icons/Icons';
import T from 'ecto-common/lib/lang/Language';
import GreyButton from 'ecto-common/lib/Button/GreyButton';
import { ButtonProps } from 'ecto-common/lib/Button/Button';

type EditButtonProps = ButtonProps & {
  tooltipText?: string;
};

const EditButton = React.forwardRef<HTMLButtonElement, EditButtonProps>(
  (
    {
      children = null,
      tooltipText = null,
      isIconButton = false,
      ...otherProps
    },
    ref
  ) => {
    return (
      <>
        {isIconButton ? (
          <Tooltip text={tooltipText || T.common.edit}>
            <GreyButton isIconButton {...otherProps} ref={ref}>
              <Icons.Edit />
            </GreyButton>
          </Tooltip>
        ) : (
          <GreyButton {...otherProps} ref={ref}>
            <Icons.Edit />
            {children}
          </GreyButton>
        )}
      </>
    );
  }
);

export default EditButton;
