import React, { useCallback, useContext } from 'react';
import DashboardDataContext from 'ecto-common/lib/hooks/DashboardDataContext';
import Panel from 'ecto-common/lib/Dashboard/Panel';
import styles from './EctotableDashboardPanel.module.css';
import LoadingContainer from 'ecto-common/lib/LoadingContainer/LoadingContainer';
import ErrorNotice from 'ecto-common/lib/Notice/ErrorNotice';
import T from 'ecto-common/lib/lang/Language';
import useInterval from 'ecto-common/lib/hooks/useInterval';
import useReloadTrigger from 'ecto-common/lib/hooks/useReloadTrigger';

const EctotableDashboardPanel = ({ panelData, ...otherProps }) => {
  const { isLoadingResources, hasError, cacheContext } =
    useContext(DashboardDataContext);
  const [reloadTrigger, triggerReload] = useReloadTrigger();

  // Reload panel data every hour
  useInterval(
    useCallback(() => {
      if (!isLoadingResources && !hasError) {
        triggerReload();
      }
    }, [isLoadingResources, hasError]),
    60 * 1000 * 60
  );

  if (isLoadingResources) {
    return <LoadingContainer isLoading style={{ height: '100%' }} />;
  } else if (hasError) {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <ErrorNotice>{T.common.baserequesterror}</ErrorNotice>
      </div>
    );
  }

  return (
    <Panel
      titleClassName={styles.title}
      panel={panelData}
      cacheContext={cacheContext}
      reloadTrigger={reloadTrigger}
      {...otherProps}
    />
  );
};

export default React.memo(EctotableDashboardPanel);
