import Util from './util';
import _ from 'lodash';
import PhysicalCluster from './physical_cluster';
import AccumulatorTank from 'js/Table/AccumulatorTank';
import ElectricitySource from 'js/Table/ElectricitySource';
import PhysicalLeaf from 'js/Table/physical_leaf';
import PhysicalNode from 'js/Table/physical_node';

/**
 * The Network is an abstract representation of the physical network of Points and Vectors
 * @class PhysicalGrid
 */
class PhysicalGrid extends PhysicalCluster {
  connectExtraEquipment() {
    // Connect accumulator tank separately at the end.
    // It should not be connected to multiple nodes, just the closest one.
    let otherLeaves: PhysicalLeaf[] = null;
    const allLeaves = this.getAllLeaves();

    const findClosestLeaf = (sourceNode: PhysicalNode) => {
      otherLeaves = otherLeaves || allLeaves;
      return _.minBy(otherLeaves, (_node) => {
        return sourceNode.squaredDist(_node);
      });
    };

    const accTank = this.find((cb) => cb.buildingId === 'accumulatortank');
    if (accTank != null) {
      const minDistNode = findClosestLeaf(accTank);

      if (minDistNode != null) {
        (accTank as AccumulatorTank).connectToNode(minDistNode);
      }
    }

    // Reset electricity generation for all of the potential nodes,
    // connecting it to a source will enable it again.
    for (const node of allLeaves) {
      if (node.node != null) {
        node.node.electricityNode = null;
      }
    }

    for (const node of this._nodes) {
      if (node.buildingId === 'battery' || node.buildingId === 'pvcell') {
        const electricitySource = node as ElectricitySource;
        const minDistNode = findClosestLeaf(node);

        if (minDistNode != null) {
          electricitySource.connectToNode(minDistNode);
        }
      }
    }
  }

  buildGrid() {
    const allLeaves = this.getAllLeaves();
    this.connectExtraEquipment();

    if (allLeaves.length === 0) {
      console.warn('No Nodes to Build Grid from');
      return Util.NOOP;
    }

    if (allLeaves.length === 1) {
      // Only one Node, not wrong but nothing to do.
      return Util.NOOP;
    }

    // Start with one node, arbitrary which one
    const startNode = allLeaves[0];

    const nodeMap = {};

    allLeaves.forEach((_node) => {
      nodeMap[_node.id] = _node;
    });

    allLeaves.forEach((_node) => {
      _node.executePersistentConnection();
    });

    // THe array to work with where all nodes eventually will move to inside
    let inside = []; // The Nodes already connected to the starting point
    const outside = allLeaves; // The Nodes not yet connected to the starting point
    const distancesSquared = {};

    outside.forEach((_node) => {
      distancesSquared[_node.id] = {};
    });

    let currentNode = startNode;

    /* We work with the concept of inside nodes that are added to the grid, either one by one or in a
     *  Clump if some nodes already have connections
     */

    // This function will move a particular node to the inside and will update the calculated distance
    // between that node and the ones that are still outside
    const moveInsideAndUpdateDistances = (_current) => {
      const entrying = []; // Nodes about to become inside nodes

      const moveFromOutsideToInside = (_node) => {
        if (entrying.indexOf(_node) === -1) {
          entrying.push(_node);
          Util.arrayRemove(outside, _node);
          addConnectedToInside(_node); // Add all connected nodes as well
          delete distancesSquared[_node.id];
        }
      };

      const addConnectedToInside = (_node) => {
        _node.getConnected().forEach((_connected) => {
          moveFromOutsideToInside(_connected);
        });
      };

      // Recursively add all connected nodes
      moveFromOutsideToInside(_current);

      /* Calculate distance between the ones still outside and the ones now about to go inside */

      outside.forEach((outsideNode) => {
        entrying.forEach((entryingNode) => {
          distancesSquared[outsideNode.id][entryingNode.id] =
            outsideNode.squaredDist(entryingNode);
        });
      });

      inside = inside.concat(entrying);
    };

    // Start with one node
    moveInsideAndUpdateDistances(currentNode);

    /*eslint no-constant-condition: "off" */
    while (true) {
      if (outside.length === 0) {
        currentNode = undefined;
        break;
      }

      let shortest = Infinity;
      let newConnetionInsideNode;
      let newConnetionOutsideNode;
      const outsideNodesIds = Object.keys(distancesSquared);

      outsideNodesIds.forEach((outsideNodeId) => {
        const outsideNodeDistances = distancesSquared[outsideNodeId];
        const insideNodesIds = Object.keys(outsideNodeDistances);

        insideNodesIds.forEach((insideNodeId) => {
          const distance = distancesSquared[outsideNodeId][insideNodeId];

          if (distance < shortest) {
            shortest = distance;
            newConnetionOutsideNode = nodeMap[outsideNodeId];
            newConnetionInsideNode = nodeMap[insideNodeId];
          }
        });
      });

      if (newConnetionOutsideNode.next !== undefined) {
        newConnetionOutsideNode.reverserNextRelationship();
      }

      moveInsideAndUpdateDistances(newConnetionOutsideNode);
      newConnetionOutsideNode.connectTo(newConnetionInsideNode);
    }
  }

  // The algorithm used:

  // 1 Set up all predefined piping - including MV

  // 2 Start with arbitrary Structure - SX
  // Put SX in a "inside" array
  // Crate an array "outside" with all other Structures

  //----- Start
  // Create a temp array "entrying" ESa with SX and any potentially connected Structures
  // Remove all ESa Structures from "outside"
  // find the distance between the Each ESa and Each "outside" individually

  // Move all Structures in ESa "inside"

  // compare to find the shortest Vector - VY
  // connect the two Structures SI and SO "inside" and outside" respectively to each other
  //  If both SI and SO already have children, recursively reverse SO connections so that
  //  SO can be parent to SI, create that connection

  //------- Restart with SO as SX
}

export default PhysicalGrid;
