import Util from './util';
import PhysicalNode from './physical_node';

/**
 * A node in the physical network that doesn't have any children
 * @class PhysicalLeaf
 */
class PhysicalLeaf extends PhysicalNode {
  next: PhysicalLeaf | undefined;
  previous: PhysicalLeaf[];
  persistentNext: PhysicalLeaf | undefined;

  constructor(center) {
    super(center);

    this.next = undefined;
    this.previous = [];
  }

  center() {
    return this.centerPos;
  }

  /**
   * This is for setting up a connection that is prioritised between to Leaves
   * This is always constructed Before connections that are happening based on distance.
   * This can be called  anytime - but is not coming into affect until next frame,
   * as we don't know where in the process the piping is.
   *
   * @param {PhysicalLeaf | undefined} otherLeaf An Object that needs to be an other Physical Leaf (or undefined to remove a previous setting)
   * @throws {String} Message about bad in parameters
   */
  preparePersistentConnection(otherLeaf: PhysicalLeaf): number | void {
    if (otherLeaf === undefined) {
      delete this.persistentNext;
      return Util.SUCCESS;
    }

    if (otherLeaf instanceof PhysicalLeaf) {
      this.persistentNext = otherLeaf;
      return Util.SUCCESS;
    }

    throw 'PhysicalLeaf call to preparePersistentConnection with bad parameter';
  }

  executePersistentConnection() {
    if (this.persistentNext) {
      this.connectTo(this.persistentNext);
    }
  }

  /**
   * @param {PhysicalLeaf} otherLeaf A node that should be connected to this one
   * @throws {String} Message about bad in parameters
   * @returns {RESULTS} Success or Failure code
   */
  connectTo(otherLeaf: PhysicalLeaf) {
    if (!(otherLeaf instanceof PhysicalLeaf)) {
      throw 'PhysicalLeaf call to connectTo with bad parameter';
    }

    if (this.next === undefined) {
      this.next = otherLeaf;
      otherLeaf.previous.push(this);
      return Util.SUCCESS;
    }

    if (otherLeaf.next === undefined) {
      otherLeaf.next = this;
      this.previous.push(otherLeaf);
      return Util.SUCCESS;
    }

    throw 'PhysicalLeaf.connectTo unexpected case';
  }

  disconnectChild() {
    if (this.next === undefined) {
      return Util.NOOP;
    }

    Util.arrayRemove(this.next.previous, this);
    this.next = undefined;
    return Util.SUCCESS;
  }

  resetConnections() {
    this.previous.forEach((parent) => {
      parent.disconnectChild();
    });
    this.previous = [];
    this.next = undefined;
  }

  reverserNextRelationship() {
    if (this.next !== undefined) {
      const prevChild = this.next;

      this.disconnectChild();
      prevChild.reverserNextRelationship();

      prevChild.connectTo(this);
    }
  }

  getDirectConnections() {
    const connections = this.previous.slice();

    if (this.next !== undefined) connections.push(this.next);
    return connections;
  }

  getConnected(network) {
    if (network === undefined) {
      network = [];
    }

    if (network.indexOf(this) === -1) {
      network.push(this);
    }

    const connections = this.getDirectConnections();

    connections.forEach((connection) => {
      if (network.indexOf(connection) === -1) {
        network.push(connection);
        connection.getConnected(network);
      }
    });

    return network;
  }
}

export default PhysicalLeaf;
