import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import classNames from 'classnames';

import TextInput from 'ecto-common/lib/TextInput/TextInput';
import Icons from 'ecto-common/lib/Icons/Icons';
import styles from './SearchableTreeView.module.css';
import T from 'ecto-common/lib/lang/Language';
import Select, { GenericSelectOption } from 'ecto-common/lib/Select/Select';
import LocationTreeView from 'ecto-common/lib/LocationTreeView/LocationTreeView';
import { DEFAULT_SEARCH_DEBOUNCE_TIME_MS } from 'ecto-common/lib/utils/constants';
import { SingleGridNode } from 'ecto-common/lib/types/EctoCommonTypes';
import { EquipmentResponseModel } from 'ecto-common/lib/API/APIGen';

interface SearchableTreeViewProps {
  tabIndex?: number;
  triggerFocus?: boolean;
  nodeTree: SingleGridNode[];
  nodeMap: Record<string, SingleGridNode>;
  equipmentMap: Record<string, EquipmentResponseModel>;
  nodeTags: string[];
  nodeIds: string[];
  onSelectionChanged: (nodeId: string, isSelected: boolean) => void;
  treeClassName?: string;
  treeContainerClassName?: string;
  multiSelect?: boolean;
  selectEquipment?: boolean;
}

class SearchableTreeView extends Component<SearchableTreeViewProps> {
  state = {
    searchTerm: '',
    tags: [] as string[]
  };

  constructor(props: SearchableTreeViewProps) {
    super(props);
    this.search = _.debounce(this.search, DEFAULT_SEARCH_DEBOUNCE_TIME_MS);
  }

  search = (searchTerm: string) => this.setState({ searchTerm });

  tagsChanged = (tags: GenericSelectOption<string>[]) =>
    this.setState({ tags: tags != null ? tags.map((tag) => tag.value) : [] });

  _onSelectionChanged = (nodeId: string, isSelected: boolean) => {
    this.props.onSelectionChanged(nodeId, isSelected);
  };

  render() {
    const {
      tabIndex,
      triggerFocus,
      nodeTree,
      nodeTags,
      nodeIds,
      nodeMap,
      equipmentMap,
      treeClassName,
      treeContainerClassName,
      multiSelect = false,
      selectEquipment = false
    } = this.props;
    const { searchTerm, tags } = this.state;

    const createOption = (tag: string) => ({
      value: tag,
      label: tag
    });
    const tagOptions = nodeTags && nodeTags.map(createOption);
    const tagValue = tags.map(createOption);

    const focusedId = _.head(nodeIds);

    return (
      <Fragment>
        <TextInput
          tabIndex={tabIndex}
          icon={<Icons.Search />}
          autoFocus={triggerFocus}
          value={searchTerm}
          onChange={(e) => this.search(e.target ? e.target.value : '')}
          clearButton
          wrapperClassName={styles.searchFieldContainer}
          className={styles.searchField}
          placeholder={T.sidebar.location.search.placeholder}
        />
        <div className={styles.tagSearchContainer}>
          <label className={styles.tagSearchLabel}>
            {T.admin.editlocation.tags}
          </label>
          <Select<GenericSelectOption<string>, true>
            className={styles.tagSearchSelect}
            isMulti
            placeholder={T.creatableselect.placeholder}
            value={tagValue}
            options={tagOptions}
            onChange={this.tagsChanged}
          />
        </div>
        <div className={classNames(treeContainerClassName)}>
          <LocationTreeView
            className={classNames(treeClassName)}
            multiSelect={multiSelect}
            selectedIds={nodeIds}
            focusedId={focusedId}
            sidePadding={0}
            allowSelectingRootNodes
            searchFilter={{ searchTerm, tags }}
            onChangeSelectedState={this._onSelectionChanged}
            nodeTree={nodeTree}
            nodeMap={nodeMap}
            equipmentMap={equipmentMap}
            selectEquipment={selectEquipment}
          />
        </div>
      </Fragment>
    );
  }
}

export default SearchableTreeView;
