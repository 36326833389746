import React from 'react';
import styles from './Switch.module.css';
import classNames from 'classnames';

interface SwitchProps {
  className?: string;
  isOn?: boolean;
  isLoading?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  disabled?: boolean;
}

const Switch = ({
  className,
  isOn,
  isLoading,
  onClick,
  disabled = false
}: SwitchProps) => {
  return (
    <div
      className={classNames(
        className,
        styles.container,
        disabled && styles.disabled,
        isLoading && styles.noPointerEvents
      )}
    >
      <div
        className={classNames(
          styles.switch,
          isOn && styles.on,
          isLoading && styles.loading
        )}
        onClick={!isLoading ? onClick : null}
      >
        <div
          className={classNames(
            styles.loadingBackground,
            isLoading && styles.loading
          )}
        />
        <div
          className={classNames(
            styles.switchKernel,
            isOn && styles.on,
            isLoading && styles.loading
          )}
        />
      </div>
    </div>
  );
};

export default Switch;
