import React, { Component } from 'react';
import Creatable from 'react-select/creatable';
import styles from './Select.module.css';
import _ from 'lodash';
import T from 'ecto-common/lib/lang/Language';
import colors from 'ecto-common/lib/styles/variables/colors';
import Icons from 'ecto-common/lib/Icons/Icons';
import zindex from '../styles/variables/zindex';
import {
  CSSObjectWithLabel,
  MenuPlacement
} from 'react-select/dist/declarations/src/types';
import { CreatableProps } from 'react-select/dist/declarations/src/Creatable';
import { GenericSelectOption } from 'ecto-common/lib/Select/Select';

type CreatableSelectProps<
  ValueType = GenericSelectOption,
  IsMulti extends boolean = false
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
> = CreatableProps<ValueType, IsMulti, any> & {
  /**
   * Default placement of the menu in relation to the control. 'auto' will flip when there isn't enough space below the control.
   */
  menuPlacement: MenuPlacement;
  /**
   * The value of the create item.
   */
  createValue: string;
  /**
   * Sets the position of the createOption element in your options list. Defaults to 'last'
   */
  createOptionPosition: 'first' | 'last';
  /**
   * Whether or not to show the option when the list is empty
   */
  showOptionWhenEmpty: boolean;
  /**
   * We support both disabled / isDisabled for compatibility purposes
   */
  disabled?: boolean;
};

// TODO: Since we provide getNewOptionData, we have to lock down the type to GenericSelectOption<string>. Ideally this component should not make such an assumption
/**
 * Wrapper for a creatable react-select component. Adds an option to create a new item and not just select from existing ones.
 */
export default class CreatableSelect<
  IsMulti extends boolean = false
> extends Component<
  CreatableSelectProps<GenericSelectOption<string>, IsMulti>
> {
  static defaultProps = {
    menuPlacement: 'auto',
    createValue: '',
    createOptionPosition: 'first',
    showOptionWhenEmpty: true
  };

  state = {
    input: ''
  };

  _handleInputChange = (value: string) =>
    this.setState({ input: value ? value : '' });

  render() {
    const {
      menuPlacement,
      createValue,
      createOptionPosition,
      showOptionWhenEmpty
    } = this.props;
    const input = this.state.input;
    // We can use both isDisabled and disabled properties to determine disabled state. isDisabled is prioritized
    const disabled =
      this.props.isDisabled == null
        ? this.props.disabled
        : this.props.isDisabled;

    const createOption = {
      name: '',
      value: createValue,
      label: (
        <div className={styles.createHeader}>
          <Icons.Add className={styles.addIcon} /> {T.creatableselect.createnew}{' '}
        </div>
      ),
      action: 'create-option'
    };

    const colorStyles = {
      option: (
        stylesParam: CSSObjectWithLabel,
        {
          data
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }: any
      ) => {
        return {
          // TODO: Verify this condition, I don't think it matches the data type
          borderBottom: data.name === '' && '1px solid ' + colors.surface4Color,
          ...stylesParam
        };
      }
    };

    // replace 'options' with modified options before
    // sending it to ReactCreatableSelect
    let options = [...this.props.options];

    if (showOptionWhenEmpty && _.isEmpty(input)) {
      if (createOptionPosition === 'first') {
        options = [createOption, ...options];
      } else {
        options = [...options, createOption];
      }
    }

    const props = { ...this.props, options };
    return (
      <Creatable<GenericSelectOption<string>, IsMulti>
        classNamePrefix="Select"
        tabSelectsValue={false}
        placeholder={T.creatableselect.placeholder}
        menuShouldScrollIntoView={false}
        menuPlacement={menuPlacement}
        onInputChange={this._handleInputChange}
        noOptionsMessage={() => T.select.creatableempty}
        getNewOptionData={(value) => {
          return {
            name: '',
            value: value,
            label: T.format(T.creatableselect.create, value),
            action: 'create-option'
          };
        }}
        {...props}
        isDisabled={disabled}
        styles={{
          ...colorStyles,
          menuPortal: (base) => ({ ...base, zIndex: zindex.modalOverlayZIndex })
        }}
        menuPortalTarget={document.body}
      />
    );
  }
}
