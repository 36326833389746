import Select, { GenericSelectOption } from 'ecto-common/lib/Select/Select';
import ToolbarItem from 'ecto-common/lib/Toolbar/ToolbarItem';
import React, { useCallback } from 'react';
import T from 'ecto-common/lib/lang/Language';
import Tooltip from 'ecto-common/lib/Tooltip/Tooltip';
import ToolbarMenu from 'ecto-common/lib/Toolbar/ToolbarMenu';
import Icons from 'ecto-common/lib/Icons/Icons';
import ToolbarMenuButton from 'ecto-common/lib/Toolbar/ToolbarMenuButton';
import styles from './ProcessMapZoomSelector.module.css';

type ProcessMapZoomSelectorProps = {
  value: number;
  setZoom: (zoom: number) => void;
  resetTransform: () => void;
};

const zoomOptions: GenericSelectOption<number>[] = [
  {
    label: '25%',
    value: 0.25
  },
  {
    label: '50%',
    value: 0.5
  },
  {
    label: '75%',
    value: 0.75
  },
  {
    label: '100%',
    value: 1
  },
  {
    label: '125%',
    value: 1.25
  },
  {
    label: '150%',
    value: 1.5
  },
  {
    label: '175%',
    value: 1.75
  },
  {
    label: '200%',
    value: 2
  },
  {
    label: '300%',
    value: 3
  },
  {
    label: '400%',
    value: 4
  }
];

const ProcessMapZoomSelector = ({
  value,
  setZoom,
  resetTransform
}: ProcessMapZoomSelectorProps) => {
  const selectedValueIndex = zoomOptions.findIndex(
    (option) => Math.abs(option.value - value) < 0.0001
  );

  const selectedValue = zoomOptions[selectedValueIndex];

  const zoomIn = useCallback(() => {
    if (selectedValueIndex === -1) {
      const closestSmallerIndex = zoomOptions.findIndex(
        (option) => option.value > value
      );

      if (closestSmallerIndex === -1) {
        setZoom(zoomOptions[zoomOptions.length - 1].value);
      } else {
        setZoom(zoomOptions[closestSmallerIndex].value);
      }
    } else if (selectedValueIndex < zoomOptions.length - 1) {
      setZoom(zoomOptions[selectedValueIndex + 1].value);
    }
  }, [selectedValueIndex, setZoom, value]);

  const zoomOut = useCallback(() => {
    if (selectedValueIndex === -1) {
      const closestLargerIndex = zoomOptions.findLastIndex(
        (option) => option.value < value
      );

      if (closestLargerIndex === -1) {
        setZoom(zoomOptions[0].value);
      } else {
        setZoom(zoomOptions[closestLargerIndex].value);
      }
    } else if (selectedValueIndex > 0) {
      setZoom(zoomOptions[selectedValueIndex - 1].value);
    }
  }, [selectedValueIndex, setZoom, value]);

  const selectedOption = selectedValue ?? {
    label: Math.round(value * 100) + '%',
    value: value
  };

  return (
    <>
      <ToolbarItem>
        <ToolbarMenu>
          <ToolbarMenuButton
            icon={<Icons.Zoom />}
            onClick={zoomIn}
            tooltipText={T.admin.processmaps.zoomin}
          />
          <ToolbarMenuButton
            icon={<Icons.ZoomOut />}
            onClick={zoomOut}
            tooltipText={T.admin.processmaps.zoomout}
          />
          <ToolbarMenuButton
            icon={<Icons.Bullseye />}
            tooltipText={T.admin.processmaps.zoomreset}
            onClick={resetTransform}
          />
        </ToolbarMenu>
      </ToolbarItem>
      <ToolbarItem className={styles.zoomSelector}>
        <Tooltip text={T.admin.processmaps.zoom}>
          <Select
            placeholder={Math.round(value * 100) + '%'}
            options={zoomOptions}
            value={selectedOption}
            onChange={(option) => {
              setZoom(option.value);
            }}
          />
        </Tooltip>
      </ToolbarItem>
    </>
  );
};

export default React.memo(ProcessMapZoomSelector);
