import Settings from './Settings';

import fontsBlack from '../assets/fonts/eon_brixsans-black-webfont.ttf';
import fontsBlackItalic from '../assets/fonts/eon_brixsans-blackitalic-webfont.ttf';
import fontsMedium from '../assets/fonts/eon_brixsans-medium-webfont.ttf';
import fontsMediumItalic from '../assets/fonts/eon_brixsans-mediumitalic-webfont.ttf';
import fontsBold from '../assets/fonts/eon_brixsans-bold-webfont.ttf';
import fontsBoldItalic from '../assets/fonts/eon_brixsans-bolditalic-webfont.ttf';
import fontsRegular from '../assets/fonts/eon_brixsans-regular-webfont.ttf';
import fontsRegularItalic from '../assets/fonts/eon_brixsans-regularitalic-webfont.ttf';
import _ from 'lodash';
import p5 from 'p5';
import TableP5 from 'js/Table/TableP5';
import { TableContextType } from 'js/Table/context';

const startScale = 1;

export type IntegrationFontDefinitions = {
  black: p5.Font;
  medium: p5.Font;
  blackitalic: p5.Font;
  mediumItalic: p5.Font;
  bold: p5.Font;
  regular: p5.Font;
  boldItalic: p5.Font;
  regularItalic: p5.Font;
};

type IntegrationFuncType = {
  (p5Instance: TableP5, initFunction: any, size: any): void;
  context: TableContextType;
};

const sketchFunction: IntegrationFuncType = function (
  p5Instance: TableP5,
  initFunction,
  size
) {
  const setScale = (newScale: number, width: number, height: number) => {
    let scale = newScale;

    p5Instance.renderWidth = width / scale;
    p5Instance.renderHeight = height / scale;
    p5Instance.scaleRatio = scale;

    // console.info(
    //   'setScale',
    //   p5Instance.renderWidth,
    //   p5Instance.renderHeight,
    //   p5Instance.scaleRatio
    // );
  };

  const calculateSize = function (width, height) {
    const tableScaleFactor = p5Instance.App?.roomConfig?.tableScaleFactor ?? 1;

    const proclaimAs = (osName) =>
      navigator.platform.toLowerCase().indexOf(osName) !== -1 ||
      navigator.userAgent.toLowerCase().indexOf(osName) !== -1;

    /* Calculate a rough scaling */
    const d = window.getDPI();
    const w = size.width / d;
    const h = size.height / d;
    const rawScreenSize = Math.sqrt(w * w + h * h);
    const screenSize = Math.floor(rawScreenSize);

    const isWindows = proclaimAs('win');
    const isiPad = proclaimAs('ipad');
    const isMac = proclaimAs('mac');

    let model;

    if (isWindows && screenSize === 28) {
      model = 'surface';
      setScale(2, width, height);
    } else if (isWindows && screenSize > 40) {
      model = 'hub';
      setScale(1.5, width, height);
    } else if (isiPad) {
      model = 'ipad';
    } else {
      if (isMac) {
        model = 'mac';
      } else {
        model = 'desktop';
      }

      // Mobile 7-10 or so
      // Laptops and iPad -> 17-18
      // Surface 2 -> screen size 28
      // External Monitor -> screen size 30 ish
      // 55" monitor -> screen size 45
      if (screenSize > 35) {
        // Likely a reasonably large monitor
        setScale(2.5 * tableScaleFactor, width, height);
      } else if (screenSize > 30) {
        // Likely a reasonably large monitor
        setScale(2 * tableScaleFactor, width, height);
      } else {
        setScale(1.0 * tableScaleFactor, width, height);
      }
    }

    p5Instance.model = model;
    // console.info('scale: model =', model);

    p5Instance.screenSize = rawScreenSize;
  };

  setScale(startScale, size.width, size.height);

  let eonFonts = {};

  p5Instance.preload = function () {
    initFunction();

    eonFonts = {
      black: p5Instance.loadFont(fontsBlack),
      medium: p5Instance.loadFont(fontsMedium),
      blackitalic: p5Instance.loadFont(fontsBlackItalic),
      mediumItalic: p5Instance.loadFont(fontsMediumItalic),
      bold: p5Instance.loadFont(fontsBold),
      regular: p5Instance.loadFont(fontsRegular),
      boldItalic: p5Instance.loadFont(fontsBoldItalic),
      regularItalic: p5Instance.loadFont(fontsRegularItalic)
    };
  };

  p5Instance.setup = function () {
    this.frameRate(Settings.fps);
    const canvas = this.createCanvas(size.width, size.height);

    // Move the canvas so it’s inside our <div id="sketch-holder">.
    canvas.parent('sketch-holder');

    this.coldColorTransparent = this.color(Settings.coldColor);
    this.coldColorTransparent.setAlpha(Settings.colorAlpha);
    this.warmColorTransparent = this.color(Settings.warmColor);
    this.warmColorTransparent.setAlpha(Settings.colorAlpha);

    calculateSize(size.width, size.height);

    this.App.renderInit(this, eonFonts, this.model);
  };

  p5Instance.printDebugStuff = function () {
    p5Instance.push();
    p5Instance.fill(Settings.structureFontColor);
    p5Instance.text('Touches: ' + p5Instance.touches.length, 50, 30);
    p5Instance.text(
      'Structures: ' + p5Instance.App.structures.length(),
      50,
      50
    );
    p5Instance.text(
      'Touch triangles: ' +
        p5Instance.App.touchTriangleManager.touchTriangles.length,
      150,
      30
    );
    p5Instance.text(
      'Disable touch UI: ' + p5Instance.App.shouldDisableDynamicTouchUI(),
      150,
      50
    );
    p5Instance.text('Debug: ' + p5Instance.App.debugMessage, 300, 30);
    p5Instance.text(
      'Frame time: ' +
        p5Instance.App.frameTimeBuffer.avgValue.toFixed(1) +
        ' | ' +
        p5Instance.App.frameTimeBuffer.maxValue.toFixed(1) +
        'ms',
      300,
      50
    );
    p5Instance.pop();
  };

  p5Instance.draw = function () {
    p5Instance.push();
    p5Instance.scale(p5Instance.scaleRatio);
    p5Instance.App.draw(p5Instance);
    p5Instance.pop();
  };

  p5Instance.keyPressed = function () {
    let scale = p5Instance.scaleRatio;
    const elem = document.getElementById('TableView');

    if (p5Instance.key === '-') {
      scale /= 1.1;
    }
    if (p5Instance.key === '+') {
      scale *= 1.1;
    }

    setScale(scale, elem.clientWidth, elem.clientHeight);
    p5Instance.App.keyPressed(p5Instance);
  };

  p5Instance.mouseWheel = function (e: any) {
    let scale = p5Instance.scaleRatio;
    const containerRect =
      p5Instance.drawingContext.canvas.getBoundingClientRect();

    if (e.pageX < containerRect.x) {
      return;
    }

    const x = e.offsetX / scale;
    const y = e.offsetY / scale;

    p5Instance.App.mouseWheel(e.delta, x, y);
  };

  p5Instance.keyReleased = function () {
    p5Instance.App.keyReleased(p5Instance);
  };

  // for debugging on desktop
  p5Instance.mousePressed = function () {
    if (p5Instance.App?.showingSetup) {
      return true;
    }
    let scale = p5Instance.scaleRatio;

    const x = p5Instance.mouseX / scale;
    const y = p5Instance.mouseY / scale;

    p5Instance.App.mousePressed(x, y, 'mouse');
    return false;
  };

  p5Instance.mouseReleased = function () {
    if (p5Instance.App?.showingSetup) {
      return true;
    }

    let scale = p5Instance.scaleRatio;

    const x = p5Instance.mouseX / scale;
    const y = p5Instance.mouseY / scale;

    p5Instance.App.mouseReleased(x, y, 'mouse');
    return false;
  };
  p5Instance.mouseDragged = function () {
    let scale = p5Instance.scaleRatio;

    const x = p5Instance.mouseX / scale;
    const y = p5Instance.mouseY / scale;

    p5Instance.App.mouseDragged(x, y, 'mouse');
    return false;
  };

  const _cloneTouchArray = (evArray) => {
    let scale = p5Instance.scaleRatio;

    const newArray = [];
    const containerRect =
      p5Instance.drawingContext.canvas.getBoundingClientRect();

    for (let i = 0; i < evArray.length; i++) {
      const touch = evArray[i];

      newArray.push({
        pageX: (touch.pageX - containerRect.x) / scale,
        pageY: (touch.pageY - containerRect.y) / scale,
        clientX: (touch.clientX - containerRect.x) / scale,
        clientY: (touch.clientY - containerRect.y) / scale,
        screenX: touch.screenX / scale,
        screenY: touch.screenY / scale,
        identifier: touch.identifier
      });
    }
    return newArray;
  };

  const _scaledEvent = (ev: any) => {
    let scale = p5Instance.scaleRatio;
    const containerRect =
      p5Instance.drawingContext.canvas.getBoundingClientRect();
    const firstTouch: any = _.head(ev.changedTouches);

    return {
      pageX: ((firstTouch?.pageX ?? 0) - containerRect.x) / scale,
      pageY: ((firstTouch?.pageY ?? 0) - containerRect.y) / scale,
      touches: _cloneTouchArray(ev.touches),
      changedTouches: _cloneTouchArray(ev.changedTouches)
    };
  };

  p5Instance.touchEnded = function (ev) {
    if (
      p5Instance.App?.showingSetup ||
      p5Instance.App?.showingFullscreenInfoView()
    ) {
      return true;
    }

    p5Instance.App?.touchEnded(_scaledEvent(ev));
    return false;
  };

  p5Instance.touchMoved = function (ev) {
    p5Instance.App?.touchMoved(_scaledEvent(ev));
    return false;
  };

  p5Instance.touchStarted = function (ev) {
    if (
      p5Instance.App?.showingSetup ||
      p5Instance.App?.showingFullscreenInfoView()
    ) {
      return true;
    }

    p5Instance.App?.touchStarted(_scaledEvent(ev));
    return false;
  };

  p5Instance.windowResized = function () {
    const elem = document.getElementById('TableView');
    p5Instance.resizeCanvas(elem.clientWidth, elem.clientHeight);
    calculateSize(elem.clientWidth, elem.clientHeight);
  };
};

sketchFunction.context = null;

export default sketchFunction;
