import React from 'react';
import styles from './SegmentControl.module.css';
import classNames from 'classnames';

interface SegmentControlItemProps {
  /**
   * The content of the item. Can be text, images or other node types.
   */
  children?: React.ReactNode;
  /**
   * Whether or not this item is active (selected).
   */
  active: boolean;
  /**
   * Callback handler for when the item is clicked
   */
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  /**
   * If set to true the item is rendered in a slightly smaller frame.
   */
  compact?: boolean;
  /**
   * If set to true then expand to take available width. Will divide available space evenly among items.
   */
  expanding?: boolean;
  /**
   * Used to override the appearance of the item. Should be a valid CSS class name.
   */
  className?: string;
  /**
   * If set to true then the item will not be clickable
   */
  disabled?: boolean;
}

/**
 * Items that are used as content for SegmentControlView.
 */
export const SegmentControlItem = ({
  active,
  children,
  onClick = null,
  compact = false,
  className = null,
  expanding = false,
  disabled = false,
  ...otherProps
}: SegmentControlItemProps) => {
  return (
    <div
      onClick={onClick}
      className={classNames(
        styles.segmentItem,
        active && styles.activeSegmentItem,
        compact && styles.compactSegmentItem,
        expanding && styles.expandingSegmentItem,
        disabled && styles.disabledSegmentItem,
        className
      )}
      {...otherProps}
    >
      {children}
    </div>
  );
};
