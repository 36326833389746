import React from 'react';
import styles from './Divider.module.css';
import LayoutDirection from 'ecto-common/lib/types/LayoutDirection';

interface DividerProps {
  direction?: LayoutDirection;
}

const Divider = ({ direction }: DividerProps) => {
  return (
    <div
      className={
        direction === LayoutDirection.VERTICAL
          ? styles.dividerVertical
          : styles.dividerHorizontal
      }
    />
  );
};

export default React.memo(Divider);
