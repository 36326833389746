import React from 'react';
import { KeyValueGeneric } from 'ecto-common/lib/KeyValueInput/KeyValueGeneric';
import { ModelEditorProps } from 'ecto-common/lib/ModelForm/ModelEditor';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import { ModelDefinitionInternal } from 'ecto-common/lib/ModelForm/ModelPropType';
import UploadFile, {
  UploadFileType
} from 'ecto-common/lib/UploadFile/UploadFile';

export type FileModelDefinition<
  ObjectType extends object,
  EnvironmentType extends object = object
> = {
  modelType: typeof ModelType.FILE;
} & ModelDefinitionInternal<ObjectType, EnvironmentType, UploadFileType>;

type ModelEditorFileProps<
  ObjectType extends object,
  EnvironmentType extends object
> = ModelEditorProps<EnvironmentType, UploadFileType> & {
  model: FileModelDefinition<ObjectType>;
};

const ModelEditorFile = <
  ObjectType extends object,
  EnvironmentType extends object
>({
  model,
  rawValue,
  updateItem,
  helpText = null,
  useTooltipHelpTexts = false,
  isHorizontal
}: ModelEditorFileProps<ObjectType, EnvironmentType>) => {
  return (
    <KeyValueGeneric
      keyText={model.label}
      isHorizontal={isHorizontal}
      horizontalWeights={model.horizontalWeights}
      helpText={helpText}
      useTooltipHelpTexts={useTooltipHelpTexts}
    >
      <UploadFile file={rawValue} onFileUpload={updateItem} />
    </KeyValueGeneric>
  );
};

export default React.memo(ModelEditorFile);
