import React, { useCallback, useEffect, useState } from 'react';
import { ModelEditorProps } from 'ecto-common/lib/ModelForm/ModelEditor';
import { KeyValueFixedSelectableInput } from 'ecto-common/lib/KeyValueInput/KeyValueFixedSelectableInput';
import _ from 'lodash';
import { useSimpleDialogState } from 'ecto-common/lib/hooks/useDialogState';
import Icons from 'ecto-common/lib/Icons/Icons';
import styles from './ModelEditorSignal.module.css';
import T from 'ecto-common/lib/lang/Language';
import SelectSignalsDialog from 'ecto-common/lib/SelectSignalsDialog/SelectSignalsDialog';
import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';
import APIGen from 'ecto-common/lib/API/APIGen';
import { ChartSignal } from 'ecto-common/lib/SignalSelector/ChartUtils';
import UUID from 'uuidjs';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import {
  ModelDefinitionInternal,
  ModelDynamicStringProperty
} from 'ecto-common/lib/ModelForm/ModelPropType';
import { CustomNodeTreeSet } from 'ecto-common/lib/SelectNodeDialog/SelectNode';

export const formattedSignalNameWithProvider = (signal: ChartSignal) => {
  if (signal != null) {
    return signal.group.signalProviderName + ' - ' + signal.item.name;
  }
  return '';
};

export type SignalModelDefinition<
  ObjectType extends object,
  EnvironmentType extends object = object
> = {
  modelType: typeof ModelType.SIGNAL;
  customNodeTreeSet?: CustomNodeTreeSet;
  nodeId?: ModelDynamicStringProperty<ObjectType, EnvironmentType, string>;
  isClearable?: boolean;
  validSignalTypeIds?: string[];
  selectFromCurrentNodeOnly?: boolean;
} & ModelDefinitionInternal<ObjectType, EnvironmentType, string>;

type ModelEditorSignalProps = ModelEditorProps & {
  model: SignalModelDefinition<object, object>;
  nodeId?: string | null;
};

const ModelEditorSignal = ({
  model,
  nodeId: externalNodeId,
  helpText,
  disabled,
  rawValue,
  hasError,
  updateItem
}: ModelEditorSignalProps) => {
  const [dialogIsOpen, showDialog, hideDialog] = useSimpleDialogState();

  const [signalCache, setSignalCache] = useState<Record<string, ChartSignal>>(
    {}
  );
  const commonNodeId = useCommonSelector((state) => state.general.nodeId);
  const nodeId = externalNodeId || commonNodeId;

  const signalDataQueryEnabled = rawValue != null && !signalCache[rawValue];

  const getSignalDataQuery = APIGen.Signals.getProvidersBySignalIds.useQuery(
    {
      signalIds: [rawValue]
    },
    {
      enabled: signalDataQueryEnabled
    }
  );

  const isLoading = getSignalDataQuery.isLoading && signalDataQueryEnabled;

  useEffect(() => {
    if (getSignalDataQuery.data != null && signalCache[rawValue] == null) {
      const provider = _.head(getSignalDataQuery.data);
      const signal = provider.signals.find(
        (otherSignal) => otherSignal.signalId === rawValue
      );

      const chartSignal: ChartSignal = {
        item: signal,
        chartSignalId: UUID.generate(),
        group: _.omit(provider, 'signals'),
        parent: null
      };

      setSignalCache((oldCache) => ({
        ...oldCache,
        [signal.signalId]: chartSignal
      }));
    }
  }, [getSignalDataQuery.data, rawValue, signalCache]);

  const selectedSignal = signalCache[rawValue];

  let valueLabel;
  if (getSignalDataQuery.isError) {
    valueLabel = T.modeleditor.failedtoloadsignaldata;
  } else {
    valueLabel = formattedSignalNameWithProvider(selectedSignal);
  }

  const content = rawValue && !isLoading && (
    <div className={styles.valueContainer}>
      <Icons.Signal /> <div>{valueLabel}</div>
    </div>
  );

  const placeholderContent = (
    <div className={styles.valueContainer}>
      <Icons.Signal />{' '}
      {isLoading
        ? T.common.loading
        : model.placeholder ?? T.signals.selectsignal}
    </div>
  );
  const updateValue = useCallback(
    (selectedSignals: ChartSignal[]) => {
      const signal = _.head(selectedSignals);
      const signalId = signal?.item?.signalId;

      if (signalId != null) {
        setSignalCache((oldCache) => ({
          ...oldCache,
          [signalId]: signal
        }));
      }

      updateItem(signalId);
      hideDialog();
    },
    [hideDialog, updateItem]
  );

  return (
    <>
      <KeyValueFixedSelectableInput
        disabled={disabled}
        keyText={model.label}
        value={content}
        isLoading={isLoading}
        placeholder={placeholderContent}
        onClick={showDialog}
        hasError={hasError}
        isDisabled={isLoading}
        helpText={helpText}
        isClearable={model.isClearable}
        onClear={() => updateItem(null)}
      />
      <SelectSignalsDialog
        customNodeTreeSet={model.customNodeTreeSet}
        isOpen={dialogIsOpen}
        nodeId={nodeId}
        onModalClose={hideDialog}
        onSignalsSelected={updateValue}
        isSingleSelect
        validSignalTypeIds={model.validSignalTypeIds}
        selectedSignals={_.compact([selectedSignal])}
        selectFromCurrentNodeOnly={model.selectFromCurrentNodeOnly}
      />
    </>
  );
};

export default React.memo(ModelEditorSignal);
