import React, { useContext, useEffect, useMemo, useState } from 'react';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import T from 'ecto-common/lib/lang/Language';
import Icons from 'ecto-common/lib/Icons/Icons';
import { DEFAULT_TIMEZONE, ROOT_NODE_ID } from 'ecto-common/lib/constants';
import TableColumn from 'ecto-common/lib/TableColumn/TableColumn';
import classNames from 'classnames';
import styles from './AlarmView.module.css';
import moment from 'moment';
import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { requestWasCancelled } from 'ecto-common/lib/utils/reqState';
import _ from 'lodash';
import PagingFooter from 'ecto-common/lib/PagingFooter/PagingFooter';
import APIGen, {
  AlarmInfoResponseModel,
  AlarmsListResponseModel
} from 'ecto-common/lib/API/APIGen';
import { getNodeFromMap } from 'ecto-common/lib/utils/locationUtils';
import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import {
  TimeFormats,
  getDefaultDateTimeFormat,
  getDefaultTimeFormat
} from 'ecto-common/lib/utils/dateUtils';

const pageSize = 9;

const PAGE_LINK_QUERY_PARAM = 'historyPage';

const columns: DataTableColumnProps<AlarmInfoResponseModel>[] = [
  {
    label: T.alarms.columns.status,
    dataKey: 'isActive',
    width: 120,
    flexGrow: 1,
    truncateText: true,
    dataFormatter: (isActive: boolean) => (
      <TableColumn
        title={
          <span className={classNames(isActive && styles.active)}>
            {isActive ? T.alarms.active : T.alarms.inactive}
          </span>
        }
      />
    )
  },
  {
    label: T.common.date,
    dataKey: 'alarmDate',
    width: 120,
    flexGrow: 1,
    dataFormatter: (alarmDate: string) => {
      const date = moment.tz(alarmDate, DEFAULT_TIMEZONE);
      return (
        <TableColumn
          title={date.format(getDefaultDateTimeFormat(TimeFormats.NONE))}
          subtitle={T.format(
            T.alarms.datesubtitleformat,
            date.format(getDefaultTimeFormat(TimeFormats.LONG_TIME))
          )}
        />
      );
    }
  },
  {
    label: T.alarms.columns.ackstatus,
    dataKey: 'isAcknowledged',
    width: 120,
    flexGrow: 1,
    dataFormatter: (isAcknowledged: boolean, item) => (
      <TableColumn
        title={
          isAcknowledged
            ? T.alarms.acknowledged
            : T.alarms.notacknowledgedstatus
        }
        subtitle={
          isAcknowledged ? item.acknowledgedBy : T.alarms.notacknowledgedstatus
        }
      />
    )
  },
  {
    label: T.alarms.columns.comment,
    dataKey: 'alarmCommentMessage',
    width: 120,
    flexGrow: 1,
    dataFormatter: (alarmCommentMessage: string) => (
      <TableColumn title={alarmCommentMessage} />
    )
  }
];

interface AlarmHistoryModalProps {
  nodeId: string;
  alarm?: AlarmInfoResponseModel;
  onModalClose: () => void;
  showingHistoryItem?: boolean;
}

const AlarmHistoryModal = ({
  nodeId,
  alarm,
  onModalClose,
  showingHistoryItem
}: AlarmHistoryModalProps) => {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [alarmHistoryResults, setAlarmHistoryResults] =
    useState<AlarmsListResponseModel>({ alarms: [], totalPages: 0 });

  const location = useLocation();
  const { contextSettings } = useContext(TenantContext);
  const queryParams = queryString.parse(location.search);
  const page = _.parseInt((queryParams.historyPage as string) ?? '0');
  const nodeMap = useCommonSelector((state) => state.general.nodeMap);
  const node = getNodeFromMap(nodeMap, nodeId);

  useEffect(() => {
    let promise: Promise<unknown> = null;
    setHasError(false);
    setLoading(true);
    const abortController = new AbortController();

    if (alarm) {
      promise = APIGen.Alarms.getAlarmHistory.promise(
        contextSettings,
        {
          NodeId: nodeId.startsWith(ROOT_NODE_ID) ? null : nodeId,
          Grid: node.grid,
          SignalId: alarm.signalId,
          Page: page + 1,
          PageSize: pageSize
        },
        abortController.signal
      );

      promise
        .then((results: AlarmsListResponseModel) => {
          setLoading(false);
          setAlarmHistoryResults(results);
        })
        .catch((e: unknown) => {
          if (!requestWasCancelled(e)) {
            setLoading(false);
            setHasError(true);
          }
        });
    } else {
      setAlarmHistoryResults({ alarms: [], totalPages: 0 });
    }

    return () => {
      setLoading(false);

      if (promise != null) {
        abortController.abort();
      }
    };
  }, [contextSettings, alarm, setLoading, nodeId, page, node.grid]);

  const title = useMemo(
    () => T.format(T.alarms.historymodal.titleformat, alarm?.name),
    [alarm]
  );

  let selectedIndex;
  if (showingHistoryItem) {
    selectedIndex = alarm
      ? alarmHistoryResults.alarms.findIndex(
          (x) => x.isActiveDate === alarm.isActiveDate
        )
      : -1;
  } else {
    selectedIndex = page === 0 ? 0 : -1;
  }

  return (
    <ActionModal
      isOpen={alarm != null}
      onModalClose={onModalClose}
      disableCancel
      className={styles.historyModal}
      headerIcon={Icons.History}
      title={title}
      onConfirmClick={onModalClose}
    >
      <DataTable
        columns={columns}
        isLoading={loading}
        className={styles.historyModalTable}
        data={alarmHistoryResults.alarms}
        hasError={hasError}
        selectedIndexStyle={styles.selectedIndex}
        selectedIndex={selectedIndex}
      />

      {alarmHistoryResults.totalPages > 1 && (
        <PagingFooter
          totalPages={alarmHistoryResults.totalPages}
          page={page}
          pageLinkQueryParameter={PAGE_LINK_QUERY_PARAM}
        />
      )}
    </ActionModal>
  );
};

export default React.memo(AlarmHistoryModal);
