import T, { UnlocalizedString } from '../lang/Language';
import _ from 'lodash';
import { AlarmType } from 'ecto-common/lib/API/APIGen';
import { enumValues } from 'ecto-common/lib/utils/typescriptUtils';
import { GenericSelectOption } from 'ecto-common/lib/Select/Select';

export enum ResourceType {
  CORE = 'Core',
  ECTOGRID = 'EctoGrid',
  SMART_COMFORT = 'SmartComfort',
  LOBA = 'LoBa',
  POWER_CONTROL = 'PowerControl',
  TENANT_MANAGEMENT = 'TenantManagement',
  USER_MANAGEMENT = 'UserManagement',
  TEMPLATE_MANAGEMENT = 'TemplateManagement',
  ECTOPLANNER = 'EctoPlanner',
  ECTOTABLE_MANAGEMENT = 'EctoTableAdmin'
}

export const TEMPLATE_ADMIN_ID = 'template-administration';

export const ADMIN_SPECIFIC_TENANT_IDS = [
  TEMPLATE_ADMIN_ID,
  'tenant-administration',
  'device-administration'
];

export const RoleType = Object.freeze({
  ADMIN: 'Admin',
  READ: 'Read',
  WRITE: 'Write'
});

export const RoleTypeTranslation = Object.freeze({
  [RoleType.ADMIN]: T.admin.tenants.roles.admin,
  [RoleType.READ]: T.admin.tenants.roles.read,
  [RoleType.WRITE]: T.admin.tenants.roles.write
});

export const RoleOptions: GenericSelectOption<string>[] = _.map(
  RoleType,
  (role) => ({
    label: RoleTypeTranslation[role],
    value: role
  })
);

export enum ScheduleStatus {
  RUNNING = 'RUNNING',
  ABORTED = 'ABORTED',
  PENDING = 'PENDING',
  FINISHED = 'FINISHED'
}

export const ScheduleStatusText: Record<string, string> = {
  [ScheduleStatus.RUNNING]: T.powercontrol.status.running,
  [ScheduleStatus.ABORTED]: T.powercontrol.status.aborted,
  [ScheduleStatus.PENDING]: T.powercontrol.status.pending,
  [ScheduleStatus.FINISHED]: T.powercontrol.status.finished
};

const SignalProviderTypeTextHandler = {
  get: function (_target: unknown, name: keyof typeof T.signalproviders.type) {
    return T.signalproviders.type[name] === UnlocalizedString
      ? name
      : T.signalproviders.type[name];
  }
};
export const SignalProviderTypeText: Record<string, string> = new Proxy(
  {},
  SignalProviderTypeTextHandler
);

export enum AlarmSeverity {
  HIGH = 0,
  MEDIUM = 1,
  LOW = 2
}

export const GraphicalRepresentation = Object.freeze({
  EVENT: 'Event',
  STEP: 'Step',
  LINEAR: 'Linear',
  SPLINE: 'Spline'
});

// TODO translation?
export const AlarmTypeText: Record<string, string> = {
  [AlarmType.Stateful]: 'Stateful',
  [AlarmType.Stateless]: 'Stateless'
};

export const PowerControlAlgorithmType = Object.freeze({
  BASIC: 'Basic',
  TRACKING: 'Tracking',
  TRANSMIT: 'Transmit',
  TRACKING_VENTILATION: 'TrackingVentilation'
});

export const SortOrderType = Object.freeze({
  ASC: 'asc',
  DESC: 'desc'
});

export const AllAlarmSeverities = enumValues(AlarmSeverity);

// console.log('Alarm severities without none', AlarmSeveritiesWithoutNone);
export const AlarmSeverityText: Record<string, string> = {
  [AlarmSeverity.HIGH]: 'A',
  [AlarmSeverity.MEDIUM]: 'B',
  [AlarmSeverity.LOW]: 'C'
};

export const DEFAULT_LAT = 55.5989165;
export const DEFAULT_LNG = 12.989437;
export const ROOT_NODE_ID = 'root';
export const DEFAULT_TIMEZONE = 'Europe/Stockholm';
export const KEY_CODE_ENTER = 13;
export const KEY_CODE_TAB = 9;
export const KEY_CODE_ESCAPE = 27;
export const KEY_CODE_ARROW_UP = 38;
export const KEY_CODE_ARROW_DOWN = 40;
export const NODE_TYPE_BUILDING = 'Building';
export const CancelledErrorName = 'AbortError';
export const HTTP_STATUS_NOT_FOUND = 404;

export const GridTypes = Object.freeze({
  ECTOGRID: 'Ectogrid',
  HEATING: 'Heating',
  COOLING: 'Cooling',
  ELECTRICITY: 'Electricity'
});

export const EnergyManagerModels = Object.freeze({
  NPEX500: 'NPEX500',
  NPE_9500_CM3: 'NPE-9500-CM3',
  NPE_9500_M38_LTE: 'NPE-9500-M38-LTE',
  NPE_9500_M3_4G: 'NPE-9500-M3-4G',
  NPE_9500_M3_LTE: 'NPE-9500-M3-LTE'
});
