import React, { KeyboardEvent } from 'react';
import dimensions from 'ecto-common/lib/styles/dimensions';
import classNames from 'classnames';
import styles from './LocationTreeView.module.css';
import {
  getEnergyManagerEquipmentTypeId,
  getEquipmentName
} from 'ecto-common/lib/utils/equipmentTypeUtils';
import { NodeTypes } from 'ecto-common/lib/utils/constants';
import { ArrowIcon } from 'ecto-common/lib/Icon/index';
import { DeviceIcon } from 'ecto-common/lib/Icon';
import Icons from 'ecto-common/lib/Icons/Icons';
import _ from 'lodash';
import {
  EquipmentResponseModel,
  EquipmentTypeResponseModel,
  NodeEquipmentResponseModel
} from 'ecto-common/lib/API/APIGen';
import { SingleGridNode } from 'ecto-common/lib/types/EctoCommonTypes';

// Use graphical ASCII characters as constants to make it easy to debug print tree
const COLUMN_BLANK = ' ';
const COLUMN_VERTICAL = '|';
const COLUMN_VERTICAL_HALF_TOP = '.';
const COLUMN_VERTICAL_HALF_BOTTOM = ':';
const COLUMN_HORIZONTAL = '-';
const COLUMN_DOT = '*';
const COLUMN_DOT_LINE = '+';

const BASE_COLUMN_STYLES: Record<string, string> = {
  [COLUMN_BLANK]: styles.column,
  [COLUMN_VERTICAL]: classNames(styles.column, styles.verticalColumn),
  [COLUMN_VERTICAL_HALF_TOP]: classNames(
    styles.column,
    styles.midHalfVerticalColumn
  ),
  [COLUMN_VERTICAL_HALF_BOTTOM]: classNames(
    styles.column,
    styles.halfVerticalColumn
  ),
  [COLUMN_HORIZONTAL]: classNames(styles.column, styles.horizontalColumn)
};

// TODO: Keeping these semi-related types in the same array is not good
export type LocationTreeEquipmentOrNode = Partial<SingleGridNode> &
  Partial<NodeEquipmentResponseModel>;

export type LocationTreeViewRowType = {
  prefix: string;
  node: LocationTreeEquipmentOrNode;
  path: string;
  parentPath: string;
  parentNode: LocationTreeEquipmentOrNode;
  searchPath: string;
};

const compareEquipments = (
  lhs: EquipmentResponseModel,
  rhs: EquipmentResponseModel,
  energyManagerTypeId: string
) => {
  const lhsEM = lhs.equipmentTypeId === energyManagerTypeId;
  const rhsEM = rhs.equipmentTypeId === energyManagerTypeId;

  // Sort so that Energy Manager equipments appear before other equipments
  // and then sort based on names

  if ((lhsEM && rhsEM) || (!lhsEM && !rhsEM)) {
    return lhs.name.localeCompare(rhs.name);
  } else if (lhsEM) {
    return -1;
  }

  return 1;
};

const getChildrenArray = (
  node: LocationTreeEquipmentOrNode,
  selectEquipment: boolean,
  equipmentFilter: (equipment: EquipmentResponseModel) => boolean,
  equipmentTypes: EquipmentTypeResponseModel[]
): LocationTreeEquipmentOrNode[] => {
  let childrenArray: LocationTreeEquipmentOrNode[] = node.children;

  const energyManagerTypeId =
    equipmentTypes && getEnergyManagerEquipmentTypeId(equipmentTypes);

  if (
    (!childrenArray || childrenArray.length === 0) &&
    selectEquipment &&
    node.equipments
  ) {
    // Equipments have a node ID which refers to the parent location that it belongs to. However,
    // since it's a node, it also has a node ID property (but this is called equipmentId for historical
    // reasons). We should really change this API but in the meantime we create a new structure where
    // we set the correct node ID for each equipment (and not the ID of it's parent location)
    let equipmentArray: EquipmentResponseModel[] = node.equipments.map((eq) =>
      _.merge({}, _.cloneDeep(eq), {
        nodeId: eq.equipmentId,
        parentLocation: node as SingleGridNode
      })
    );

    if (energyManagerTypeId) {
      equipmentArray = equipmentArray.sort((x, y) =>
        compareEquipments(x, y, energyManagerTypeId)
      );
    }

    if (equipmentFilter) {
      equipmentArray = equipmentArray.filter(equipmentFilter);
    }

    childrenArray = equipmentArray;
  }

  return childrenArray || [];
};

/**
 *  Returns a list of rows for the location tree view. Each row has a "prefix list" - this is basically
 *  a list of columns that is used to draw the hierarchical lines of the tree. These are then used to
 *  create divs which makes up the visual appearance of the lines.
 */
export const getTreeRowsForNodes = (
  nodes: LocationTreeEquipmentOrNode[],
  prefix: string,
  parentNode: LocationTreeEquipmentOrNode,
  isLastChild: boolean,
  path: string,
  expandedState: Record<string, boolean>,
  _selectEquipment: boolean,
  _equipmentFilter: (equipment: EquipmentResponseModel) => boolean,
  _equipmentTypes: EquipmentTypeResponseModel[]
): LocationTreeViewRowType[] => {
  if (parentNode) {
    if (isLastChild) {
      prefix = prefix.concat(COLUMN_BLANK);
    } else {
      prefix = prefix.concat(COLUMN_VERTICAL);
    }
  }

  return _.flatMap(nodes, (node, idx: number) => {
    let subPrefix;
    const childrenArray = getChildrenArray(
      node,
      _selectEquipment,
      _equipmentFilter,
      _equipmentTypes
    );
    const nodePath = path + '.' + node.nodeId;
    const nodeIsExpanded = !expandedState || expandedState[nodePath];
    const dot =
      nodeIsExpanded && childrenArray.length > 0 ? COLUMN_DOT_LINE : COLUMN_DOT;

    if (idx === 0 && parentNode == null) {
      if (nodes.length > 1) {
        subPrefix = COLUMN_VERTICAL_HALF_TOP + COLUMN_HORIZONTAL + dot;
      } else {
        subPrefix = COLUMN_BLANK + COLUMN_BLANK + dot;
      }
    } else if (idx === nodes.length - 1) {
      subPrefix = COLUMN_VERTICAL_HALF_BOTTOM + COLUMN_HORIZONTAL + dot;
    } else {
      subPrefix = COLUMN_VERTICAL + COLUMN_HORIZONTAL + dot;
    }

    const initialNode = {
      prefix: prefix.concat(subPrefix),
      node: node,
      path: nodePath,
      parentPath: path,
      parentNode,
      searchPath: ''
    };

    if (nodeIsExpanded) {
      return [
        initialNode,
        ...getTreeRowsForNodes(
          childrenArray,
          prefix,
          node,
          idx === nodes.length - 1,
          nodePath,
          expandedState,
          _selectEquipment,
          _equipmentFilter,
          _equipmentTypes
        )
      ];
    }

    return [initialNode];
  });
};

const renderPrefix = (prefix: string, dotElement: React.ReactNode) => {
  return _.map(prefix, (part, idx) => {
    switch (part) {
      case COLUMN_DOT:
        return (
          <div key={idx} className={styles.dotContainer}>
            {dotElement}
          </div>
        );
      case COLUMN_DOT_LINE:
        return (
          <div key={idx} className={styles.dotContainer}>
            {[
              dotElement,
              <div key={idx} className={styles.midHalfVerticalColumn} />
            ]}
          </div>
        );
      default:
        return (
          <div key={idx} className={styles.columnContainer}>
            <div key={idx} className={BASE_COLUMN_STYLES[part]} />
          </div>
        );
    }
  });
};

interface LocationTreeViewRowProps {
  searchPath?: string;
  hasSearchFilter?: boolean;
  node?: LocationTreeEquipmentOrNode;
  parentNode?: object;
  path?: string;
  prefix?: string;
  expandAll?: boolean;
  energyManagerTypeId?: string;
  allowSelectingRootNodes?: boolean;
  expandPath?(path: string, toggle: boolean): void;
  expanded?: boolean;
  multiSelect?: boolean;
  multiSelectFilter?(node: LocationTreeEquipmentOrNode): boolean;
  onChangeSelectedState?(parentId: string, isSelected: boolean): void;
  onKeyUp?: (
    event: KeyboardEvent<HTMLDivElement>,
    node: LocationTreeEquipmentOrNode,
    path: string
  ) => void;
  selectEquipment?: boolean;
  selectLocation?(
    location: LocationTreeEquipmentOrNode,
    path: string,
    isSelected: boolean
  ): void;
  sidePadding?: number;
  equipmentTypes?: EquipmentTypeResponseModel[];
  selected?: boolean;
  isEvenRow?: boolean;
  renderRowIcons?: (node: LocationTreeEquipmentOrNode) => React.ReactNode;
  searchResultIcon?: React.ReactNode;
}

const LocationTreeViewRow = ({
  node,
  parentNode,
  searchPath,
  path,
  prefix,
  expandAll,
  energyManagerTypeId,
  allowSelectingRootNodes,
  expandPath,
  expanded,
  multiSelect,
  multiSelectFilter,

  onChangeSelectedState,
  onKeyUp,
  selectEquipment,
  selectLocation,
  sidePadding,
  equipmentTypes,
  selected,
  isEvenRow,
  renderRowIcons,
  hasSearchFilter,
  searchResultIcon
}: LocationTreeViewRowProps) => {
  const isEquipment = node.equipmentId != null;
  const hasChildren =
    node.children?.length > 0 ||
    (selectEquipment && node.equipments?.length > 0);

  const searchOffset = hasSearchFilter ? dimensions.largeMargin : 0;
  const rowStyle = { paddingLeft: sidePadding + searchOffset };

  let showCheckbox = multiSelect;

  if (multiSelectFilter) {
    showCheckbox = multiSelectFilter(node);
  }

  const showTreeDot =
    !isEquipment &&
    (!showCheckbox || (parentNode == null && !allowSelectingRootNodes));

  let dotElement = null;
  let checkboxElement = null;

  if (showTreeDot) {
    dotElement = (
      <div
        key={'dot' + node.nodeId}
        className={classNames(
          styles.treeDot,
          !showCheckbox && selected && styles.selectedBackground
        )}
      />
    );
  } else if (showCheckbox) {
    checkboxElement = (
      <input
        type="checkbox"
        onChange={(e) => onChangeSelectedState(node.nodeId, e.target.checked)}
        checked={selected}
      />
    );
    dotElement = (
      <div
        key={'dot' + node.nodeId}
        className={classNames(
          styles.treeDot,
          styles.checkboxDot,
          !showCheckbox && selected && styles.selectedBackground
        )}
      >
        {checkboxElement}
      </div>
    );
  }

  const selectedClass = !multiSelect && selected && styles.selected;

  let suffix: React.ReactNode = '';

  if (isEquipment && equipmentTypes) {
    const eqName = getEquipmentName(node.equipmentTypeId, equipmentTypes);
    suffix = (
      <>
        <br />
        <div className={styles.equipmentName}>{eqName}</div>
      </>
    );
  }

  let icon = hasSearchFilter ? searchResultIcon : null;

  if (icon == null) {
    if (!isEquipment && searchPath && node.nodeType === NodeTypes.SITE) {
      icon = <Icons.Site />;
    } else if (
      !isEquipment &&
      searchPath &&
      node.nodeType === NodeTypes.BUILDING
    ) {
      icon = <Icons.Building />;
    } else if (isEquipment && node.equipmentTypeId === energyManagerTypeId) {
      icon = <Icons.EnergyManager />;
    } else if (isEquipment && node.equipmentTypeId !== energyManagerTypeId) {
      icon = <DeviceIcon />;
    }
  }

  return (
    <div
      id={node.nodeId}
      key={node.nodeId}
      tabIndex={0}
      onKeyUp={(e) => onKeyUp(e, node, path)}
      onClick={(e) =>
        e.target === e.currentTarget && selectLocation(node, path, selected)
      }
      style={rowStyle}
      className={classNames(
        styles.row,
        !showCheckbox && selected && styles.selected,
        searchPath && styles.searchResult,
        isEvenRow && styles.evenRow,
        selectedClass
      )}
    >
      {prefix && renderPrefix(prefix, dotElement)}

      <div
        className={classNames(
          styles.text,
          parentNode == null && styles.strong,
          searchPath && styles.searchText
        )}
        onClick={() => selectLocation(node, path, selected)}
        data-test={node.name}
      >
        {!prefix && showCheckbox && checkboxElement}

        {icon}
        <div>
          {' '}
          {node.name} {suffix}
        </div>
        {renderRowIcons && renderRowIcons(node)}
      </div>
      {hasChildren && !expandAll && (
        <div
          onClick={() => expandPath(path, true)}
          className={styles.arrowContainer}
        >
          <ArrowIcon
            className={classNames(
              styles.arrow,
              expanded && styles.arrowExpanded
            )}
          />
        </div>
      )}
    </div>
  );
};

export default React.memo(LocationTreeViewRow);
