import React, { useMemo } from 'react';

import { SymbolModel } from 'ecto-common/lib/API/PresentationAPIGen';
import DropdownButton, {
  DropdownButtonMenuPosition
} from 'ecto-common/lib/DropdownButton/DropdownButton';
import { KeyValueGeneric } from 'ecto-common/lib/KeyValueInput/KeyValueGeneric';
import { ModelEditorProps } from 'ecto-common/lib/ModelForm/ModelEditor';
import ProcessMapLibraryMenu from 'ecto-common/lib/ProcessMaps/ProcessMapLibraryMenu';
import T from 'ecto-common/lib/lang/Language';
import { Base64 } from 'js-base64';
import _ from 'lodash';
import md5 from 'md5';

type ProcessMapSymbolEditorProps = Omit<ModelEditorProps, 'rawValue'> & {
  rawValue: string;
  svgImages: Record<string, string>;
  library: SymbolModel[];
};
export const ProcessMapSymbolEditor = ({
  rawValue,
  svgImages,
  library,
  updateItem
}: ProcessMapSymbolEditorProps) => {
  const imageUrl = useMemo(() => {
    return URL.createObjectURL(
      new Blob([Base64.decode(svgImages[rawValue])], { type: 'image/svg+xml' })
    );
  }, [rawValue, svgImages]);

  const header = (
    <div>
      <ProcessMapLibraryMenu
        library={library}
        onDragItemEnd={_.noop}
        onClickLibraryMenuItem={(item) => {
          updateItem(md5(item.item.data));
        }}
      />
    </div>
  );

  return (
    <KeyValueGeneric keyText={T.admin.processmaps.objecteditor.symbol}>
      <DropdownButton
        menuPosition={DropdownButtonMenuPosition.BOTTOM_RIGHT}
        header={header}
      >
        <img src={imageUrl} style={{ width: '40px', height: 'auto' }} />
      </DropdownButton>
    </KeyValueGeneric>
  );
};
