import React from 'react';
import { getNodeFromMap } from 'ecto-common/lib/utils/locationUtils';
import styles from 'ecto-common/lib/ToolbarContentPage/ToolbarLocationPicker.module.css';
import Icons from 'ecto-common/lib/Icons/Icons';
import { NodeTypes } from 'ecto-common/lib/utils/constants';
import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';
import CopyToClipboardTooltip from '../CopyToClipboardTooltip/CopyToClipboardTooltip';
import { EquipmentResponseModel, NodeType } from 'ecto-common/lib/API/APIGen';
import { DeviceIcon } from 'ecto-common/lib/Icon';
import { getEnergyManagerEquipmentTypeId } from 'ecto-common/lib/utils/equipmentTypeUtils';
import TagsGroup from 'ecto-common/lib/TagsGroup/TagsGroup';
import {
  NodeResponseModelAnnotated,
  SingleGridNode
} from 'ecto-common/lib/types/EctoCommonTypes';
import T from 'ecto-common/lib/lang/Language';
import { BuildingStatusText } from 'ecto-common/lib/utils/buildingStatusUtil';
import ToolbarNavControl from 'ecto-common/lib/ToolbarContentPage/ToolbarNavControl';

export const getSubtitle = (
  node: NodeResponseModelAnnotated,
  nodeMap: Record<string, SingleGridNode>,
  hasEquipment = false
) => {
  const rootParent = getNodeFromMap(nodeMap, node?.parentId);
  let parent = rootParent;
  let parentText = '';

  const MAX_PARENT_TEXT_LENGTH = 70;

  const indices = [];
  while (parent != null) {
    parentText = parent.name + parentText;
    parent = getNodeFromMap(nodeMap, parent.parentId);
    if (parent) {
      parentText = ' > ' + parentText;
      indices.push(parentText.length);
    }
  }

  if (hasEquipment) {
    parentText += ' > ' + node.name;
  }

  if (parentText.length > MAX_PARENT_TEXT_LENGTH && rootParent != null) {
    if (hasEquipment) {
      let abbreviated = '... > ' + rootParent.name + ' > ' + node.name;
      if (abbreviated.length > MAX_PARENT_TEXT_LENGTH) {
        abbreviated = '... > ' + node.name;
      }

      return [abbreviated, parentText];
    }

    return ['... > ' + rootParent.name, parentText];
  }

  return [parentText, parentText];
};

interface ToolbarLocationPickerProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const ToolbarLocationPicker = ({
  isOpen,
  setIsOpen
}: ToolbarLocationPickerProps) => {
  const nodeId = useCommonSelector((state) => state.general.nodeId);
  const nodeMap = useCommonSelector((state) => state.general.nodeMap);
  const equipmentId = useCommonSelector((state) => state.general.equipmentId);
  const equipmentMap = useCommonSelector((state) => state.general.equipmentMap);
  const equipmentTypes = useCommonSelector(
    (state) => state.general.equipmentTypes
  );
  const energyManagerTypeId =
    equipmentTypes && getEnergyManagerEquipmentTypeId(equipmentTypes);
  const node: NodeResponseModelAnnotated = getNodeFromMap(nodeMap, nodeId);

  let icon = null;

  if (node) {
    switch (node.nodeType) {
      case NodeTypes.BUILDING:
        icon = <Icons.Building />;
        break;
      case NodeTypes.SITE:
        icon = <Icons.Site />;
        break;
      default:
        break;
    }
  }

  let largeIcon = false;
  let locationText = node?.name ?? '';
  let subtitle = null;
  let fullSubtitle = null;

  if (equipmentId) {
    const equipment: EquipmentResponseModel = getNodeFromMap(
      equipmentMap,
      equipmentId
    );

    if (equipment) {
      locationText = equipment.name;
      if (node && node.parentId) {
        [subtitle, fullSubtitle] = getSubtitle(node, nodeMap, true);
      }

      if (equipment.equipmentTypeId === energyManagerTypeId) {
        icon = <Icons.EnergyManager />;
      } else {
        largeIcon = true;
        icon = <DeviceIcon />;
      }
    }
  } else if (node && node.parentId) {
    [subtitle, fullSubtitle] = getSubtitle(node, nodeMap, false);
  }

  const navTitle = (
    <>
      <CopyToClipboardTooltip valueToCopy={node?.name ?? ''}>
        <label>{locationText}</label>
      </CopyToClipboardTooltip>
    </>
  );

  const navSubtitle = (
    <>
      <CopyToClipboardTooltip valueToCopy={fullSubtitle}>
        {subtitle}
      </CopyToClipboardTooltip>
    </>
  );

  return (
    <div className={styles.container}>
      <ToolbarNavControl
        icon={icon}
        title={navTitle}
        subtitle={navSubtitle}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        largeIcon={largeIcon}
      />
      {node?.nodeType === NodeType.Building && (
        <div className={styles.buildingInfo}>
          <div className={styles.title}>
            <CopyToClipboardTooltip
              valueToCopy={node.buildingInfo.buildingStatus}
            >
              <label>{T.locationheader.status}</label>{' '}
              {BuildingStatusText[node.buildingInfo.buildingStatus]}
            </CopyToClipboardTooltip>
          </div>
          <div className={styles.subtitle}>
            <CopyToClipboardTooltip valueToCopy={node.street}>
              <label>{T.locationheader.street}</label> {node.street}
            </CopyToClipboardTooltip>
          </div>
        </div>
      )}

      {((node?.nodeType === NodeType.Building &&
        node.buildingInfo.districtHeatingFacilityId) ||
        node?.tags?.length > 0) && (
        <div className={styles.buildingInfo}>
          <div className={styles.title}>
            <label>{T.locationheader.tags}</label>
            <TagsGroup className={styles.tags} tags={node.tags} />
          </div>
          {node.nodeType === NodeType.Building &&
            node.buildingInfo.districtHeatingFacilityId && (
              <div className={styles.subtitle}>
                <CopyToClipboardTooltip
                  valueToCopy={node.buildingInfo.districtHeatingFacilityId}
                >
                  <label>{T.locationheader.facilityid}</label>{' '}
                  {node.buildingInfo.districtHeatingFacilityId}
                </CopyToClipboardTooltip>
              </div>
            )}
        </div>
      )}
    </div>
  );
};

export default ToolbarLocationPicker;
