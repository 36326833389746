import TableP5 from 'js/Table/TableP5';
import Settings from './Settings';
import { formatNumberUnit } from 'ecto-common/lib/utils/stringUtils';

export const circleNodeTextInset = 20.0;

function measureEnergyText(
  p: TableP5,
  energyMW: number,
  prefix: string,
  scale: number,
  appendSpace: boolean
) {
  let maxEnergyText = formatNumberUnit(
    Math.round((energyMW / scale) * 1000),
    'kW',
    0
  );

  if (appendSpace) {
    maxEnergyText += ' ';
  }

  return p.textWidth(prefix) + p.textWidth(maxEnergyText);
}

function drawEnergy(
  p: TableP5,
  color: string,
  energyMW: number,
  prefix: string,
  textPos: { x: number; y: number },
  lineOffset: number,
  appendSpace: boolean
) {
  p.fill(color);
  let energyText = formatNumberUnit(Math.round(energyMW * 1000), 'kW', 0);

  if (appendSpace) {
    energyText += ' ';
  }

  p.text(prefix, textPos.x, textPos.y + lineOffset);
  textPos.x += p.textWidth(prefix);
  p.fill('white');
  p.text(energyText, textPos.x, textPos.y + lineOffset);
  textPos.x += p.textWidth(energyText);
}

export function drawNodeText(
  p: TableP5,
  center: { x: number; y: number },
  name: string,
  heatingNeedMW: number,
  coolingNeedMW: number,
  electricityNeedMW: number,
  scaling: number,
  // infoMeasureText: string,
  crustRadius: number,
  textRectInset: number,
  debugText: string
) {
  const textOffset = 10;
  const lineHeight = 16;
  const headerHeight = 20;
  let lineOffset = 0;

  let lineWidth = 175;

  const linePadding = 20;

  const boxHeight = lineHeight + headerHeight + linePadding;
  const yPos = center.y - boxHeight;
  let returnWidth = 0;

  // const kwText = '●⚈●⏺ kW: ';

  // const estMaxElectricityMW = electricityMW / this.node._scale;
  // const estMaxHeatingMW = this.node.getHeatingNeedMW() / this.node._scale;
  // const estMaxCoolingMW = this.node.getCoolingNeedMW() / this.node._scale;

  // let mainText = kwText + Math.round(parseFloat(electricityMW.toFixed(2)) * 1000);
  // mainText += ' ' + kwText + Math.round(parseFloat(this.node.getHeatingNeedMW().toFixed(2)) * 1000);
  // mainText += ' ' + kwText + Math.round(parseFloat(this.node.getCoolingNeedMW().toFixed(2)) * 1000);

  // let measureText = kwText + Math.round(parseFloat(estMaxElectricityMW.toFixed(2)) * 1000);
  // measureText += ' ' + kwText + Math.round(parseFloat(estMaxHeatingMW.toFixed(2)) * 1000);
  // measureText += ' ' + kwText + Math.round(parseFloat(estMaxCoolingMW.toFixed(2)) * 1000);

  const prefix = '● ';
  let measuredTextWidth = 0;

  if (heatingNeedMW != 0.0) {
    measuredTextWidth += measureEnergyText(
      p,
      heatingNeedMW,
      prefix,
      scaling,
      true
    );
  }

  if (coolingNeedMW != 0.0) {
    measuredTextWidth += measureEnergyText(
      p,
      coolingNeedMW,
      prefix,
      scaling,
      true
    );
  }

  if (electricityNeedMW != 0.0) {
    measuredTextWidth += measureEnergyText(
      p,
      electricityNeedMW,
      prefix,
      scaling,
      false
    );
  }

  p.push();
  {
    p.textFont(Settings.structureFont);
    p.textSize(Settings.structureHeaderFontSize);
    p.textStyle(p.NORMAL);
    p.textAlign(p.LEFT, p.TOP);
    lineWidth = Math.max(
      p.textWidth(name),
      Math.ceil(measuredTextWidth / 15.0) * 15.0
    );

    returnWidth = lineWidth + textOffset + linePadding;

    p.noStroke();
    p.fill(0, 0, 0, 150);
    p.rect(
      center.x + crustRadius / 2 - textRectInset,
      yPos,
      lineWidth + textOffset + linePadding + textRectInset,
      boxHeight,
      0,
      0,
      0,
      0
    );
    p.push();
    // White line to the right hand side of the circle
    p.noFill();
    p.stroke(p.color('white'));
    p.strokeWeight(1);
    p.line(
      center.x + crustRadius / 2,
      yPos + boxHeight,
      center.x + crustRadius / 2 + lineWidth + textOffset + linePadding,
      yPos + boxHeight
    );

    p.pop();
  }

  p.pop();

  p.push();
  {
    p.fill(Settings.structureFontColor);
    p.textFont(Settings.structureFont);
    p.textStyle(p.BOLD);
    p.textSize(Settings.structureHeaderFontSize);
    p.textAlign(p.LEFT, p.TOP);

    const textPos = {
      x: center.x + crustRadius / 2 + textOffset,
      y: yPos + linePadding * 0.5
    };

    p.text(name, textPos.x, textPos.y);
    lineOffset += headerHeight;
    p.textStyle(p.NORMAL);

    if (heatingNeedMW != 0.0) {
      drawEnergy(
        p,
        Settings.warmPipeColor,
        heatingNeedMW,
        prefix,
        textPos,
        lineOffset,
        true
      );
    }

    if (coolingNeedMW != 0.0) {
      drawEnergy(
        p,
        Settings.coldPipeColor,
        coolingNeedMW,
        prefix,
        textPos,
        lineOffset,
        true
      );
    }

    if (electricityNeedMW != 0.0) {
      drawEnergy(
        p,
        Settings.electricityPipeColor,
        electricityNeedMW,
        prefix,
        textPos,
        lineOffset,
        false
      );
    }

    lineOffset += lineHeight;
    if (debugText != null) {
      p.text(debugText, textPos.x, textPos.y + lineOffset);
    }
  }

  p.pop();

  return returnWidth;
}
