import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';

import TextInput, { TextInputProps } from 'ecto-common/lib/TextInput/TextInput';
import Icons from 'ecto-common/lib/Icons/Icons';
import T from 'ecto-common/lib/lang/Language';
import { DEFAULT_SEARCH_DEBOUNCE_TIME_MS } from 'ecto-common/lib/utils/constants';

export type SearchInputProps = Omit<TextInputProps, 'onChange' | 'ref'> & {
  /**
   * Called when input changes, first argument is a string containing search input
   */
  onChange?(newValue: string): void;
  /**
   * Text to shown when there is no input
   */
  placeholder?: string;
  /**
   * Initial value of the search input
   */
  initialValue?: string;
};

/**
 * Renders a search input field and debounces the input.
 */
const SearchInput = ({
  initialValue = '',
  onChange,
  placeholder = T.common.search.placeholder,
  ...textProps
}: SearchInputProps) => {
  const updateSearch = useMemo(() => {
    return _.debounce((e) => {
      const newValue = e.target?.value;
      setValue(newValue);
      onChange?.(newValue);
    }, DEFAULT_SEARCH_DEBOUNCE_TIME_MS);
  }, [onChange]);

  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <TextInput
      {...textProps}
      icon={<Icons.Search />}
      placeholder={placeholder}
      value={value}
      clearButton
      onChange={updateSearch}
    />
  );
};

export default React.memo(SearchInput);
