import React from 'react';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import T from 'ecto-common/lib/lang/Language';
import _ from 'lodash';
import HelpPaths from 'ecto-common/help/tocKeys';
import { ModelFormSectionType } from 'ecto-common/lib/ModelForm/ModelPropType';
import { CustomPanelProps } from 'ecto-common/lib/Dashboard/Panel';

type TextPanelConfig = {
  content: string;
};

type TextPanelProps = CustomPanelProps & {
  data: TextPanelConfig;
};

const TextPanel = ({ data }: TextPanelProps) => {
  return <>{data.content ?? ''}</>;
};

const sections: ModelFormSectionType<TextPanelConfig>[] = [
  {
    lines: [
      {
        models: [
          {
            key: (input) => input.content,
            modelType: ModelType.TEXT,
            label: T.admin.dashboards.panels.text,
            placeholder: T.admin.dashboards.panels.placeholders.text,
            hasError: _.isEmpty
          }
        ]
      }
    ]
  }
];

export const TextPanelData = {
  sections,
  helpPath: HelpPaths.docs.admin.manage.dashboards
};

export default TextPanel;
