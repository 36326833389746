import React, { useContext } from 'react';
import classnames from 'classnames';
import View from '../../components/View/View';
import Flex from '../../components/Flex/Flex';
import Buildings from './Buildings';
import HourlyChart from './HourlyChart';
import './PowerConsumptionView.css';
import AppContext from '../../components/App/app_context';
import EctotableDashboardPanel from '../DashboardView/EctotableDashboardPanel';
import dashboardData from './PowerConsumptionViewDashboardData.json';

import ceoImage from '../../assets/medicon_ceo.png';
import eonImage from '../../assets/tagline/eon.svg';
/** Client screen showing consumption and heating/cooling data. */
export default function PowerConsumptionView(props) {
  const appState = useContext(AppContext);
  const { data, className, ectocloudMode, size } = props;
  const { colors } = appState;

  if (ectocloudMode) {
    return (
      <div className="PowerConsumptionView__dashboard_container">
        <div className={'PowerConsumptionView__dashboard_title_container'}>
          <div className="View__title PowerConsumptionView__dashboard_title">
            <h1>Swedish innovation expands in Europe</h1>
            <h2>Medicon Village</h2>
          </div>
          <div className={'PowerConsumptionView__dashboard_icons'}>
            <div>
              <img src={eonImage} className="PowerConsumptionView__eon_icon" />
            </div>
            <div>
              <div className="PowerConsumptionView__ecto_icon" />
            </div>
          </div>
        </div>
        <div className={'PowerConsumptionView__content_center'}>
          <div className={'PowerConsumptionView__center_text'}>
            <div className={'PowerConsumptionView__center_text_columns'}>
              <div>
                <div>
                  Science Park Medicon Village in Lund hosts around 2 600
                  employees working in 170 life-science companies and
                  organizations. One condition of the foundation behind the park
                  is that all profits be reinvested in the research. Therefore,
                  the energy savings conducted by E.ON ectogrid™ have a direct
                  influence on the innovative work conducted there.
                  <br />
                  <br />
                </div>
              </div>
              <div className="PowerConsumptionView__content_spacer" />
              <div className="PowerConsumptionView__content_quote">
                <div>
                  <img src={ceoImage} />
                </div>
                <div className="PowerConsumptionView__quote_spacer" />
                <div className="PowerConsumptionView__ceo_quote">
                  <div>
                    ”What we liked about E.ON ectogrid™ is the possibility to
                    circulate, reuse and share energy between our buildings.
                    It’s equally important to us, as it is to our tenants in
                    life science research, that the parks climate footprint is
                    as small as possible. E.ON ectogrid™ supports our vision –
                    to be a sustainable and inventive research park for science
                    and innovation.”
                  </div>
                  <br />
                  <strong>Erik Jagesten</strong>
                  <div>CEO, Medicon Village Fastighets AB</div>
                </div>
              </div>
              <div className="PowerConsumptionView__content_spacer" />
            </div>
          </div>
          <div className={'PowerConsumptionView__center_dashboard'}>
            <EctotableDashboardPanel panelData={dashboardData} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <View className={classnames(className)} title="Heating and Cooling Balance">
      <div className="PowerConsumptionView__content__side">
        <Buildings data={data} size={size} />
      </div>
      <Flex direction="column" className="PowerConsumptionView__content__main">
        <div className="PowerConsumptionView__content__main__section">
          <HourlyChart
            mainColor={colors.warm}
            data={data && data.heatingNeedPerDayPerHour}
            animate={true}
            title="Heating Needs"
            size={size}
          />
        </div>
        <div className="PowerConsumptionView__content__main__section">
          <HourlyChart
            mainColor={colors.cold}
            data={data && data.coolingNeedPerDayPerHour}
            animate={true}
            title="Cooling Needs"
            size={size}
          />
        </div>
      </Flex>
    </View>
  );
}
