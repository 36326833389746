import APIFetch from 'ecto-common/lib/utils/APIFetch';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';

function createEctotableAPIFetch(roomToken, room) {
  return (
    _contextSettings: ApiContextSettings,
    path: string,
    options: any = {},
    fetchOptions = {}
  ) => {
    const newOptions: any = Object.assign({}, options);
    newOptions.headers = Object.assign({}, options.headers, {
      RoomToken: roomToken,
      Room: room
    });

    return APIFetch({
      path: '/api' + path,
      ...fetchOptions,
      options: newOptions
    });
  };
}

export default createEctotableAPIFetch;
