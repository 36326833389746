import toc from 'ecto-common/help/toc.json';
import { isTestingWithJest } from 'ecto-common/lib/utils/testEnvironmentUtil';

const defaultFile = 'docs/operator/operator.md';

// includes all .md-files to the bundle.
const markdownFiles = ((context) => {
  let keys = context.keys();

  let values;

  // Jest is having problems with the context type.
  if (isTestingWithJest()) {
    values = Object.values(context);
  } else {
    values = keys.map(context);
  }

  return keys.reduce((obj, key, index) => {
    obj[key] = values[index]?.default;
    return obj;
  }, {});
})(require.context('./lang', true, /\.md$/));

const getHelpFile = (language, fileKey) => {
  const file = markdownFiles?.['./' + language + '/' + fileKey];
  if (file == null) {
    return markdownFiles?.['./en/' + fileKey];
  }

  return file;
};

const getToc = (language) => {
  const languageToc = toc?.[language];
  // Fallback to english
  return languageToc || toc?.['en'];
};

export { defaultFile, getHelpFile, getToc };
