import React, { ChangeEventHandler, useCallback } from 'react';
import SignalTimeSeriesTable from 'ecto-common/lib/SignalTimeSeriesTable/SignalTimeSeriesTable';
import { KeyValueLabel } from 'ecto-common/lib/KeyValueInput/KeyValueLabel';
import { KeyValueInput } from 'ecto-common/lib/KeyValueInput/KeyValueInput';
import T from 'ecto-common/lib/lang/Language';
import ColorSelector from 'ecto-common/lib/ColorSelector/ColorSelector';
import Flex, { FlexItem } from '../Layout/Flex';
import LayoutDirection from '../types/LayoutDirection';
import styles from './CurveSeriesTable.module.css';
import { ModelDefinition } from 'ecto-common/lib/ModelForm/ModelPropType';
import {
  AdjustableChartCurve,
  AdjustableChartSeries
} from 'ecto-common/lib/AdjustableChart/AdjustableChart';
import { ColorResult } from 'react-color';
import { SignalInputType } from 'ecto-common/lib/Dashboard/datasources/LastSignalValuesDataSource';

interface CurveSeriesTableProps {
  curve?: AdjustableChartCurve;
  onCurveChanged(curve: AdjustableChartCurve): void;
  optionalSignalModels?: ModelDefinition<SignalInputType>[];
  useConstantValues?: boolean;
}

const CurveSeriesTable = ({
  curve,
  onCurveChanged,
  optionalSignalModels,
  useConstantValues
}: CurveSeriesTableProps) => {
  const onColorChange = useCallback(
    (color: ColorResult, _isCustomColor: boolean) => {
      onCurveChanged({
        ...curve,
        color: color.hex
      });
    },
    [curve, onCurveChanged]
  );

  const setName: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      onCurveChanged({
        ...curve,
        name: e.target.value
      });
    },
    [curve, onCurveChanged]
  );

  const onSeriesChanged = useCallback(
    (series: AdjustableChartSeries[]) => {
      onCurveChanged({
        ...curve,
        series
      });
    },
    [curve, onCurveChanged]
  );

  return (
    <Flex direction={LayoutDirection.VERTICAL} className={styles.container}>
      <FlexItem>
        <KeyValueLabel>{T.common.color}</KeyValueLabel>
        <ColorSelector
          color={curve.color}
          onColorChange={onColorChange}
          className={styles.colorSelector}
        />
      </FlexItem>
      <FlexItem>
        <KeyValueInput
          keyText={T.signals.displayname}
          onChange={setName}
          value={curve.name}
        />
      </FlexItem>
      <FlexItem>
        <Flex direction={LayoutDirection.VERTICAL}>
          <FlexItem>
            <KeyValueLabel>
              {T.admin.dashboards.forms.signalnames.curvedata}
            </KeyValueLabel>
          </FlexItem>
          <FlexItem>
            <SignalTimeSeriesTable
              series={curve.series}
              onSeriesChanged={onSeriesChanged}
              optionalSignalModels={optionalSignalModels}
              useConstantValues={useConstantValues}
            />
          </FlexItem>
        </Flex>
      </FlexItem>
    </Flex>
  );
};

export default React.memo(CurveSeriesTable);
