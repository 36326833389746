import React, { useCallback } from 'react';
import { KeyValueFixedSelectableInput } from 'ecto-common/lib/KeyValueInput/KeyValueFixedSelectableInput';
import SelectNodeDialog from 'ecto-common/lib/SelectNodeDialog/SelectNodeDialog';
import { useSimpleDialogState } from 'ecto-common/lib/hooks/useDialogState';
import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';
import { ModelEditorProps } from 'ecto-common/lib/ModelForm/ModelEditor';
import { modelFormattedNodeValues } from 'ecto-common/lib/ModelForm/modelValueUtil';
import { ModelDefinitionInternal } from 'ecto-common/lib/ModelForm/ModelPropType';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';

export type NodeListModelDefinition<
  ObjectType extends object,
  EnvironmentType extends object = object
> = {
  modelType: typeof ModelType.NODE_LIST;
  multiSelect?: boolean;
} & ModelDefinitionInternal<ObjectType, EnvironmentType, string[]>;

type ModelEditorNodeListProps = ModelEditorProps & {
  model: NodeListModelDefinition<object>;
};

const ModelEditorNodeList = ({
  model,
  updateItem,
  disabled,
  rawValue,
  hasError
}: ModelEditorNodeListProps) => {
  const nodeMap = useCommonSelector((state) => state.general.nodeMap);
  const equipmentMap = useCommonSelector((state) => state.general.equipmentMap);
  const [dialogIsOpen, showDialog, hideDialog] = useSimpleDialogState();

  const updateDialogItem = useCallback(
    (value: string[]) => {
      updateItem(value);
      hideDialog();
    },
    [updateItem, hideDialog]
  );

  const formattedValue = modelFormattedNodeValues(
    rawValue,
    nodeMap,
    equipmentMap
  );

  // If you have a model that requires a list of nodeIds but you only want to
  // set one at a time, you can force this control to only allow one to be selected
  // at a time using the args.multiSelect option (but still return the result as
  // an array)
  return (
    <>
      <KeyValueFixedSelectableInput
        disabled={disabled}
        keyText={model.label}
        value={formattedValue}
        placeholder={model.placeholder}
        onClick={showDialog}
        hasError={hasError}
      />
      <SelectNodeDialog
        isOpen={dialogIsOpen}
        onModalClose={hideDialog}
        nodeIds={rawValue}
        onNodesSelected={updateDialogItem}
        multiSelect={model.multiSelect === undefined ? true : model.multiSelect}
      />
    </>
  );
};

export default React.memo(ModelEditorNodeList);
