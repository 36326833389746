import { useContext, useMemo } from 'react';
import { getNodeFromMap } from 'ecto-common/lib/utils/locationUtils';
import { isRootNodeId } from 'ecto-common/lib/utils/locationUtils';
import T from 'ecto-common/lib/lang/Language';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import _ from 'lodash';
import DashboardDataContext from 'ecto-common/lib/hooks/DashboardDataContext';
import SectionListPriority from 'ecto-common/lib/Dashboard/SectionListPriority';
import { NodeTypes } from 'ecto-common/lib/utils/constants';
import { ModelFormSectionType } from 'ecto-common/lib/ModelForm/ModelPropType';

type NodesDataSourceProps = {
  nodeIds?: string[];
  useSiblings?: boolean;
};

const NodesDataSource = ({
  nodeIds,
  useSiblings = false
}: NodesDataSourceProps) => {
  const { nodeId: currentNodeId, nodeMap } = useContext(DashboardDataContext);

  return useMemo(() => {
    const _nodeIds = nodeIds ?? [currentNodeId];
    const getNode = (nodeId: string) => getNodeFromMap(nodeMap, nodeId);
    const nodes = _.compact(
      _.isEmpty(_nodeIds)
        ? [getNode(currentNodeId)]
        : _.map(_nodeIds, (nodeId) => getNodeFromMap(nodeMap, nodeId))
    );

    // Map all siblings and children to the nodes
    if (useSiblings) {
      const siblingsToNode = _(nodes)
        .filter((node) => node.nodeType === NodeTypes.BUILDING) // Only show siblings for buildings
        .flatMap('parentIds')
        .reject(isRootNodeId)
        .uniq()
        .map(getNode)
        .compact() // Remove any parents that are from other grids (getNode would return null in this case)
        .flatMap('children')
        .value();

      const siteChildren = _(nodes)
        .filter((node) => node.nodeType === NodeTypes.SITE)
        .flatMap('children')
        .value();

      return _.uniq(_.concat(nodes, siblingsToNode, siteChildren));
    }

    return nodes;
  }, [nodeIds, currentNodeId, nodeMap, useSiblings]);
};

export const nodesDataSourceSections: () => ModelFormSectionType<NodesDataSourceProps>[] =
  () => [
    {
      lines: [
        {
          models: [
            {
              key: (input) => input.nodeIds,
              label: T.admin.dashboards.panels.types.nodename.node,
              modelType: ModelType.NODE_LIST,
              placeholder:
                T.admin.dashboards.panels.types.nodename.nodeplaceholder
            }
          ]
        }
      ],
      listPriority: SectionListPriority.Location
    }
  ];

export default NodesDataSource;
