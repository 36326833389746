import React from 'react';
import classNames from 'classnames';

import styles from 'ecto-common/lib/KeyValueInput/KeyValueInput.module.css';
import { KeyValueLabel } from 'ecto-common/lib/KeyValueInput/KeyValueLabel';
import KeyValueInternalTextInput from 'ecto-common/lib/KeyValueInput/Internal/KeyValueInternalTextInput';
import {
  DefaultHorizontalWeights,
  HorizontalLabelValueWeights
} from 'ecto-common/lib/KeyValueInput/KeyValueGeneric';

export interface KeyValueInputProps {
  /**
   * Used to override the appearance of the input. Should be a valid CSS class name.
   */
  className?: string;
  /**
   * Called whenever the value of the input field is changed. The parameter is the event which triggered the change.
   */
  onChange?(
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ): void;

  /**
   * Called whenever a file is dropped on the element. The parameter is the event which triggered the change.
   */
  onDrop?(
    event:
      | React.DragEvent<HTMLInputElement>
      | React.DragEvent<HTMLTextAreaElement>
  ): void;

  /**
   * The value of the input field.
   */
  value?: string;
  /**
   * Text describing the input field. Shown above the input field with a small font.
   */
  keyText?: React.ReactNode;
  /**
   * Placeholder text that will be shown in the input field before any text has been entered.
   */
  placeholder?: string;
  /**
   * If set to true, attempt to focus the input field after the component is first rendered.
   */
  autoFocus?: boolean;
  /**
   * Which input type the text field should use. Accepts standard form <input> types.
   */
  type?: string;
  /**
   * Callback function that is triggered when the enter button is pressed while the text field is focused.
   */
  onEnterKeyPressed?(): void;
  /**
   * If set to true the input field will be greyed out and non-editable.
   */
  disabled?: boolean;
  /**
   * Set this value to display an informative help text.
   */
  help?: React.ReactNode;
  /**
   * Set this to show a tooltip instead of the help text.
   */
  useTooltipHelpTexts?: boolean;
  /**
   * Set this value to display a unit to the right of the input control.
   */
  unit?: React.ReactNode;
  /**
   * If set to true the input field will indicate that an error has occured.
   */
  hasError?: boolean;
  /**
   * Whether or not to render the key and value on one line.
   */
  isHorizontal?: boolean;
  /**
   * If using horizontal layout, specify the layout weights of the value and label. For instance, to have the label take 40% and the value take 60% of the available width,
   * you could pass a value of [4, 6].
   */
  horizontalWeights?: HorizontalLabelValueWeights;
  /**
   * If set to true, show a small delimiter border at the bottom of the control.
   */
  withDivider?: boolean;

  textFormatter?: (value: string, source: HTMLInputElement) => string;

  step?: number;

  rows?: number;

  multiline?: boolean;

  maxLength?: number;

  autoComplete?: string;

  min?: number;

  max?: number;
}

/**
 * This component renders a text field with a descriptive label on top of it. It can also display a help text.
 */
export const KeyValueInput = ({
  onChange = null,
  value = null,
  disabled = false,
  keyText = null,
  className = null,
  placeholder = null,
  autoFocus = false,
  type = null,
  withDivider = false,
  isHorizontal = false,
  horizontalWeights = DefaultHorizontalWeights,
  onEnterKeyPressed = null,
  help = null,
  useTooltipHelpTexts = false,
  hasError = false,
  unit = null,
  ...otherProps
}: KeyValueInputProps) => {
  // In horizontal mode, place help text underneath the horizontal flex layout. If not,
  // place it in the same vertical layout as the label and the text.
  const helpComponent = help && !useTooltipHelpTexts && (
    <div
      className={classNames(
        styles.helpText,
        isHorizontal && styles.horizontalHelpText
      )}
    >
      {help}
    </div>
  );

  return (
    <>
      <div
        className={classNames(
          styles.keyValueContainer,
          isHorizontal && styles.keyValueContainerHorizontal,
          withDivider && styles.divider
        )}
      >
        <KeyValueLabel
          style={{ flexGrow: isHorizontal ? horizontalWeights[0] : 1 }}
          tooltipText={useTooltipHelpTexts && help}
        >
          {keyText}
        </KeyValueLabel>
        <div
          className={styles.inputContainer}
          style={{ flexGrow: isHorizontal ? horizontalWeights[1] : 1 }}
        >
          <KeyValueInternalTextInput
            className={classNames(
              styles.valueItem,
              styles.textInput,
              className
            )}
            onChange={onChange}
            value={value}
            disabled={disabled}
            onEnterKeyPressed={onEnterKeyPressed}
            autoFocus={autoFocus}
            placeholder={placeholder}
            type={type}
            hasError={hasError}
            {...otherProps}
          />
          {unit && <div className={styles.unit}> {unit} </div>}
        </div>
        {!isHorizontal && helpComponent}
      </div>
      {isHorizontal && helpComponent}
    </>
  );
};
