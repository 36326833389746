import React from 'react';
import DropdownButton, {
  DropdownButtonProps,
  DropdownButtonOptionType
} from 'ecto-common/lib/DropdownButton/DropdownButton';
import Icons from 'ecto-common/lib/Icons/Icons';

export type MenuDropdownButtonProps = DropdownButtonProps & {
  /**
   * The options to show in the menu.
   */
  options: DropdownButtonOptionType[];
};

/**
 *  Convenience implementation of DropdownButton that uses a menu icon (three vertical dots)
 */
const MenuDropdownButton = ({
  options,
  ...otherProps
}: MenuDropdownButtonProps) => {
  return (
    <DropdownButton options={options} isIconButton {...otherProps}>
      <Icons.CircleMenu />
    </DropdownButton>
  );
};

export default MenuDropdownButton;
