import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo
} from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import {
  getNodeFromMap,
  searchResultsNodeTree
} from 'ecto-common/lib/utils/locationUtils';
import { KEY_CODE_ENTER, ROOT_NODE_ID } from 'ecto-common/lib/constants';
import { getEnergyManagerEquipmentTypeId } from 'ecto-common/lib/utils/equipmentTypeUtils';
import Icons from 'ecto-common/lib/Icons/Icons';
import styles from 'ecto-common/lib/LocationTreeView/LocationTreeView.module.css';
import { centerListEntry } from 'ecto-common/lib/utils/scrollUtils';
import T from 'ecto-common/lib/lang/Language';
import LocationTreeViewRow, {
  getTreeRowsForNodes,
  LocationTreeEquipmentOrNode
} from 'ecto-common/lib/LocationTreeView/LocationTreeViewRow';
import Button from 'ecto-common/lib/Button/Button';
import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';
import { EquipmentResponseModel } from 'ecto-common/lib/API/APIGen';
import { SingleGridNode } from 'ecto-common/lib/types/EctoCommonTypes';

export type LocationTreeViewSearchFilter = {
  searchTerm?: string;
  tags?: string[];
};

const SEARCH_RESULT_STEP = 50;

const validSearchFilter = (searchFilter: LocationTreeViewSearchFilter) => {
  return (
    searchFilter &&
    (searchFilter.searchTerm !== '' ||
      (searchFilter.tags && searchFilter.tags.length > 0))
  );
};

const locationMatchesSearchTerm = (
  location: LocationTreeEquipmentOrNode,
  searchFilter: LocationTreeViewSearchFilter
) => {
  return location.name.toLowerCase().indexOf(searchFilter.searchTerm) !== -1;
};

const hasSearchTerm = (
  location: LocationTreeEquipmentOrNode,
  parentLocation: LocationTreeEquipmentOrNode,
  searchFilter: LocationTreeViewSearchFilter
) => {
  if (searchFilter.searchTerm == null || searchFilter.searchTerm === '') {
    return true;
  }

  // Determine whether to show equipment in search results based on the tags
  // of the parent building.
  if (
    location.equipmentId &&
    locationMatchesSearchTerm(parentLocation, searchFilter)
  ) {
    return true;
  }

  return locationMatchesSearchTerm(location, searchFilter);
};

const hasTags = (
  location: LocationTreeEquipmentOrNode,
  parentLocation: LocationTreeEquipmentOrNode,
  searchFilter: LocationTreeViewSearchFilter
) => {
  if (
    searchFilter == null ||
    searchFilter.tags == null ||
    searchFilter.tags.length === 0
  ) {
    return true;
  }

  // Determine whether to show equipment in search results based on the tags
  // of the parent building.
  if (location.equipmentId) {
    location = parentLocation;
  }

  return (
    _.intersection(location.tags, searchFilter.tags).length ===
    searchFilter.tags.length
  );
};

const shouldIncludeLocation = (
  location: LocationTreeEquipmentOrNode,
  parentLocation: LocationTreeEquipmentOrNode,
  searchFilter: LocationTreeViewSearchFilter
) => {
  return (
    hasSearchTerm(location, parentLocation, searchFilter) &&
    hasTags(location, parentLocation, searchFilter)
  );
};

function getExpandedState<ItemType extends SingleGridNode>(
  nodeIdsToSelect: string[],
  expanded: Record<string, boolean>,
  nodeMap: Record<string, ItemType>,
  equipmentMap: Record<string, EquipmentResponseModel>
): Record<string, boolean> {
  if (!nodeIdsToSelect || !nodeMap || !equipmentMap) {
    return expanded;
  }

  const getParentId = (
    item: Partial<SingleGridNode> & Partial<EquipmentResponseModel>
  ) => {
    if (item.equipmentId) {
      return item.nodeId;
    }

    return item.parentId;
  };

  const findNodeOrEquipment = (nodeId: string) => {
    return (
      getNodeFromMap(nodeMap, nodeId) || getNodeFromMap(equipmentMap, nodeId)
    );
  };

  expanded = Object.assign({}, expanded);
  for (const nodeId of nodeIdsToSelect) {
    let curNode = findNodeOrEquipment(nodeId);

    if (curNode) {
      const selectPath = [curNode];

      while (curNode && getParentId(curNode)) {
        curNode = findNodeOrEquipment(getParentId(curNode));

        if (curNode) {
          selectPath.unshift(curNode);
        }
      }

      let curPathStr = '';
      selectPath.forEach((location) => {
        curPathStr += '.';
        curPathStr += location.nodeId;
        expanded[curPathStr] = true;
      });
    }
  }

  return expanded;
}

type LocationTreeViewProps<ItemType extends SingleGridNode> = {
  /**
   * A hierarchical tree of nodes. Should always be the result from a call to createFlatNodeTree (or a subset thereof).
   */
  nodeTree: ItemType[];
  /**
   * A lookup table for nodes, needed in order to make rendering more efficient.
   */
  nodeMap: Record<string, ItemType>;
  /**
   * A lookup table for equipments, needed in order to make rendering more efficient.
   */
  equipmentMap: Record<string, EquipmentResponseModel>;
  /**
   * Filter the contents of the tree view based on a free text search string and a list of tags.
   */
  searchFilter?: LocationTreeViewSearchFilter;
  /**
   * If set to true then you can select top level nodes (i.e. Grids)
   */
  allowSelectingRootNodes?: boolean;
  /**
   * Used to override the appearance of the tree view container. Should be a valid CSS class name.
   */
  className?: string;
  /**
   * Used to change the padding to the right of the LocationTreeView
   *
   * TODO: Should this really be here?
   */
  sidePadding?: number;
  /**
   * A list of node ID:s for all of the nodes that are selected. If not using multiSelect this should only
   * contain one element.
   */
  selectedIds?: string[];

  /**
   * Called whenever the user has clicked on a node. Arguments are (nodeId, isSelected).
   */
  onChangeSelectedState?(parentId: string, isSelected: boolean): void;
  /**
   * If set to true then it is possible to select multiple nodes. A checkbox will appear to the left of the node item.
   */
  multiSelect?: boolean;
  /**
   * If set to true then the user can select equipments that are part of buildings. By default these are hidden.
   */
  selectEquipment?: boolean;
  /**
   * Filter function if the view should only show certain types of equipment for instance. Called repeatedly for
   * each equipment, should return true or false.
   */
  equipmentFilter?(equipment: EquipmentResponseModel): boolean;

  /**
   * This property allows you to specify that the tree should make sure that the node with this ID is visible (i.e. the scrolling will be adjusted if necessary). Should always be set
   * together with focusedGrid. This ID should be included in selectedIds.
   */
  focusedId?: string;
  /**
   * If using multi select, filter which items are actually possible to select. If you only want to select buildings for instance you can filter on node type. Called repeatedly for each node with the node as the only argument.
   */
  multiSelectFilter?(node: LocationTreeEquipmentOrNode): boolean;

  /**
   * Shows the entire tree completely expanded. No possibility to contract nodes. Only relevant for small subtrees.
   */
  expandAll?: boolean;

  /**
   * Allows you to render extra icons to the right of the row. This is useful for instance if you want to show a warning icon if the equipment is in an error state.
   */
  renderRowIcons?: (node: LocationTreeEquipmentOrNode) => React.ReactNode;

  /**
   * Use this to show a custom icon for when search results are displayed. Will override the default icons.
   */
  searchResultIcon?: React.ReactNode;
};

/**
 * A location tree view is a tree navigation component for node hierarchies (sites, buildings equipments).
 *
 * The design is similar to many filesystem browsers. You can expand and contract nodes which contain child nodes.
 *
 * In the future we might make this component more generic and support more use cases. We will also move some of the navigation logic out of this class.
 */
function LocationTreeView({
  nodeTree,
  nodeMap,
  equipmentMap,
  searchFilter,
  allowSelectingRootNodes = false,
  className,
  sidePadding = 10,
  selectedIds = [],
  onChangeSelectedState,
  multiSelect = false,
  selectEquipment = false,
  equipmentFilter,
  focusedId = null,
  multiSelectFilter,
  renderRowIcons,
  expandAll = false,
  searchResultIcon = null
}: LocationTreeViewProps<SingleGridNode>) {
  const equipmentTypes = useCommonSelector(
    (state) => state.general.equipmentTypes
  );

  const [expanded, setExpanded] = useState<Record<string, boolean>>(
    getExpandedState(
      selectedIds,
      {
        '.root-location': true
      },
      nodeMap,
      equipmentMap
    )
  );

  const centerEntryRef = useRef(null);

  // Make sure the search result is visible on screen after clearing search
  useEffect(() => {
    if (!validSearchFilter(searchFilter) && focusedId) {
      centerListEntry(focusedId, centerEntryRef.current);
    }
  }, [searchFilter, focusedId]);

  // Reset scroll when entering new search text
  useEffect(() => {
    if (centerEntryRef.current && validSearchFilter(searchFilter)) {
      centerEntryRef.current.scrollTop = 0;
    }
  }, [searchFilter]);

  const expandPath = useCallback((path: string, toggle: boolean) => {
    setExpanded((oldExpanded: Record<string, boolean>) => {
      const pathElems = _.filter(path.split('.'));
      let curPath = '';
      const newExpandedState = _.cloneDeep(oldExpanded);

      pathElems.forEach((pathElem) => {
        curPath += '.' + pathElem;
        if (curPath === path) {
          if (toggle) {
            newExpandedState[curPath] = !oldExpanded[curPath];
          } else {
            newExpandedState[curPath] = true;
          }
        } else {
          newExpandedState[curPath] = true;
        }
      });

      return newExpandedState;
    });
  }, []);

  const selectLocation = useCallback(
    (
      location: LocationTreeEquipmentOrNode,
      path: string,
      isSelected: boolean
    ) => {
      expandPath(path, isSelected);

      if (
        location.nodeId.startsWith(ROOT_NODE_ID) &&
        !allowSelectingRootNodes
      ) {
        return;
      } else if (
        multiSelect &&
        multiSelectFilter &&
        !multiSelectFilter(location)
      ) {
        return;
      }

      onChangeSelectedState(location.nodeId, !isSelected);
    },
    [
      allowSelectingRootNodes,
      expandPath,
      multiSelect,
      multiSelectFilter,
      onChangeSelectedState
    ]
  );

  const onKeyUp = useCallback(
    (
      event: React.KeyboardEvent<HTMLDivElement>,
      node: LocationTreeEquipmentOrNode,
      path: string
    ) => {
      if (event.keyCode === KEY_CODE_ENTER) {
        selectLocation(node, path, false);
      }
    },
    [selectLocation]
  );

  const hasSearchFilter = validSearchFilter(searchFilter);

  const [searchResultsLimit, setSearchResultsLimit] =
    useState(SEARCH_RESULT_STEP);

  useEffect(() => {
    setSearchResultsLimit(SEARCH_RESULT_STEP);
  }, [searchFilter]);

  const lowerCaseSearchFilter = useMemo(() => {
    return (
      hasSearchFilter && {
        ...searchFilter,
        searchTerm: searchFilter.searchTerm.toLowerCase()
      }
    );
  }, [hasSearchFilter, searchFilter]);

  const searchNodeTree = useMemo(() => {
    return _.orderBy(searchResultsNodeTree(null, nodeTree, selectEquipment), [
      'searchPath',
      'node.name'
    ]);
  }, [nodeTree, selectEquipment]);

  const searchRows = useMemo(() => {
    if (!hasSearchFilter) {
      return null;
    }

    return _.filter(searchNodeTree, (row) =>
      shouldIncludeLocation(row.node, row.parentNode, lowerCaseSearchFilter)
    );
  }, [hasSearchFilter, lowerCaseSearchFilter, searchNodeTree]);

  const limitedSearchRows = useMemo(() => {
    return searchRows?.slice(0, searchResultsLimit);
  }, [searchRows, searchResultsLimit]);

  const showMore = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    (e.target as HTMLElement).blur();
    setSearchResultsLimit((oldLimit) => oldLimit + SEARCH_RESULT_STEP);
  }, []);

  const treeRows = useMemo(() => {
    const _treeRows = getTreeRowsForNodes(
      nodeTree,
      '',
      null,
      true,
      '',
      expandAll ? null : expanded,
      selectEquipment,
      equipmentFilter,
      equipmentTypes
    );

    // If we only have one root node, we don't want the left-most column of padding.
    // Somewhat ugly workaround, just remove the first blank prefix cell
    // after tree has been created (keeps tree generation algorithm simpler)
    if (nodeTree.length === 1) {
      _treeRows.forEach((locationRow) => {
        if (_.startsWith(locationRow.prefix, ' ')) {
          locationRow.prefix = locationRow.prefix.substring(1);
        }
      });
    }

    return _treeRows;
  }, [
    nodeTree,
    expandAll,
    expanded,
    selectEquipment,
    equipmentFilter,
    equipmentTypes
  ]);

  const locationRows = hasSearchFilter ? limitedSearchRows : treeRows;
  const energyManagerTypeId =
    equipmentTypes && getEnergyManagerEquipmentTypeId(equipmentTypes);

  let lastPath: string = null;
  let isEvenRow = true;

  const treeNodes = locationRows.map((row) => {
    const isNewGrouping = row.searchPath !== lastPath && hasSearchFilter;

    if (isNewGrouping) {
      isEvenRow = true;
    }

    const ret = (
      <LocationTreeViewRow
        node={row.node}
        path={row.path}
        searchPath={row.searchPath}
        hasSearchFilter={hasSearchFilter}
        parentNode={row.parentNode}
        prefix={row.prefix}
        key={row.path}
        expandAll={hasSearchFilter || expandAll}
        energyManagerTypeId={energyManagerTypeId}
        allowSelectingRootNodes={allowSelectingRootNodes}
        expandPath={expandPath}
        expanded={expandAll || expanded?.[row.path]}
        multiSelect={multiSelect}
        multiSelectFilter={multiSelectFilter}
        onChangeSelectedState={onChangeSelectedState}
        onKeyUp={onKeyUp}
        selectEquipment={selectEquipment}
        selectLocation={selectLocation}
        sidePadding={sidePadding}
        equipmentTypes={equipmentTypes}
        selected={selectedIds.includes(row.node.nodeId)}
        isEvenRow={isEvenRow}
        renderRowIcons={renderRowIcons}
        searchResultIcon={searchResultIcon}
      />
    );

    isEvenRow = !isEvenRow;

    if (isNewGrouping) {
      lastPath = row.searchPath;

      if (row.searchPath !== '') {
        return (
          <div key={row.searchPath + row.node.nodeId}>
            <div className={styles.groupHeader}>{row.searchPath}</div>
            {ret}
          </div>
        );
      }
    }

    return ret;
  });

  return (
    <div
      className={classNames(styles.treeView, className)}
      ref={centerEntryRef}
    >
      <div key="root-locations" className={classNames(styles.locationPicker)}>
        {treeNodes}
      </div>

      {limitedSearchRows?.length < searchRows?.length && (
        <div className={styles.showMore}>
          <Button onClick={showMore}>
            <Icons.Refresh /> {T.treeview.moreresults}
          </Button>
        </div>
      )}
    </div>
  );
}

export default React.memo(LocationTreeView);
