//
// Configuration utilities for stock charts
//
import Color from 'color';

const HOUR_IN_MILLIS = 60 * 60 * 1000;
const YEAR_IN_MILLIS = 365 * 24 * HOUR_IN_MILLIS;
const CURRENT_TIME_MARKER_ID = 'CURRENT_TIME_MARKER_ID';
const CURRENT_TIME_MARKER_WIDTH = 10;
const FUTURE_PLOTBAND_ID = 'FUTURE_PLOTBAND_ID';

function _generateFuturePlotband() {
  return {
    from: new Date().getTime(),
    to: new Date().getTime() + YEAR_IN_MILLIS,
    color: '#f5f5f5',
    id: FUTURE_PLOTBAND_ID
  };
}

function _generatePlotLines() {
  return {
    color: 'red',
    width: CURRENT_TIME_MARKER_WIDTH,
    value: new Date().getTime(),
    id: CURRENT_TIME_MARKER_ID
  };
}

export const generateStockChartConfigForPlotLines = () => {
  return {
    xAxis: {
      plotLines: [_generatePlotLines()],
      // Adding plot band for showing the future in an other color
      // Zones with dashed lines could also be used,
      //  see: http://jsfiddle.net/gh/get/jquery/1.7.2/highslide-software/highcharts.com/tree/master/samples/highcharts/series/color-zones-dashstyle-dot
      plotBands: [_generateFuturePlotband()]
    }
  };
};

export const shadeColor = (color: string, steps: number) => {
  if (color == null || steps === 1 || steps == null) {
    return color;
  }

  return new Color(color)
    .desaturate(0.4 * Math.log10(steps))
    .lighten(1.9 * Math.log10(steps))
    .hex();
};

export const colorTable = [
  '#396AB1',
  '#DA7C30',
  '#3E9651',
  '#CC2529',
  '#535154',
  '#6B4C9A',
  '#922428',
  '#948B3D',
  '#ff0000',
  '#0000ff',
  '#00ff00',
  '#FFC0CB',
  '#00FFFF',
  '#A52A2A',
  '#FF00FF',
  '#FFA500',
  '#228B22',
  '#008000',
  '#FFD700',
  '#9932CC',
  '#8B0000',
  '#E9967A',
  '#8FBC8F',
  '#483D8B',
  '#2F4F4F',
  '#2F4F4F',
  '#00CED1',
  '#9400D3',
  '#FF1493',
  '#00BFFF',
  '#696969',
  '#696969',
  '#1E90FF',
  '#B22222',
  '#FFFAF0',
  '#228B22',
  '#FF00FF',
  '#DCDCDC',
  '#F8F8FF',
  '#FFD700',
  '#DAA520',
  '#808080',
  '#808080',
  '#008000',
  '#ADFF2F',
  '#F0FFF0',
  '#FF69B4',
  '#CD5C5C',
  '#4B0082',
  '#FFFFF0',
  '#F0E68C',
  '#E6E6FA',
  '#FFF0F5',
  '#7CFC00',
  '#FFFACD',
  '#ADD8E6',
  '#F08080',
  '#E0FFFF',
  '#D3D3D3',
  '#D3D3D3',
  '#90EE90',
  '#FFB6C1',
  '#FFA07A',
  '#20B2AA',
  '#87CEFA'
];

export const largeAlternativePalette = [
  '#017de6',
  '#a437ff',
  '#55c1ff',
  '#5dd68e',
  '#ff515f',
  '#f8aebc',
  '#ff9200',
  '#fad000',
  '#7ac284',
  '#82113e',
  '#3a5c4c',
  '#db6553',
  '#e8db1e',
  '#1dcfdb',
  '#586be8',
  '#db1d4f',
  '#4a9ac2',
  '#541ee8',
  '#c27a8d',
  '#db691d',
  '#e8e292',
  '#0f4675',
  '#836aa8',
  '#809c14',
  '#4a6175',
  '#aa4ac2',
  '#3a5c23',
  '#222c36',
  '#1ddb1d',
  '#5892e8',
  '#e81ea5'
];

const constants = {
  colorTable,
  CURRENT_TIME_MARKER_ID,
  CURRENT_TIME_MARKER_WIDTH,
  FUTURE_PLOTBAND_ID
};

const getAlternativeColor = (index: number) =>
  largeAlternativePalette[index % largeAlternativePalette.length];

interface SignalWithColor {
  color?: string;
}

function getSignalColor(signal: SignalWithColor, signalIdx: number) {
  if (signal?.color) {
    return signal?.color;
  }

  return colorTable[signalIdx % colorTable.length];
}

export {
  getSignalColor,
  getAlternativeColor,
  constants as StockChartConstants
};
