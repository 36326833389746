import Settings from './Settings';
import TouchTriangle from './touch_triangle';

import { getClosestTriangle } from './triangleUtils';
import { TableContextType } from 'js/Table/context';
import TableP5 from 'js/Table/TableP5';

/**
 * The manager for creating, destroying and keeping track of TouchTriangles
 * @class TouchTriangleManager
 */
class TouchTriangleManager {
  p5Instance: TableP5;
  context: TableContextType;
  touchRemovalCache: any;
  listeners: any[];
  touchTriangles: TouchTriangle[];

  constructor(context: TableContextType, p5Instance: TableP5) {
    this.p5Instance = p5Instance;
    this.context = context;
    this.touchRemovalCache = {};
    this.listeners = [];
    this.touchTriangles = [];
  }

  addListener(listener) {
    this.listeners.push(listener);
  }

  newTouchWasFlicker(touch) {
    return this.touchRemovalCache[touch.identifier] !== undefined;
  }

  // first check if the changed touches were just removed. In that case we cancel the removals first
  clearFlickerRemovalForTouch(touch) {
    clearTimeout(this.touchRemovalCache[touch.identifier]);
  }

  touchStarted(touches) {
    const copyOfTouches = [];

    // copy the touch array immediately, except the elements already part of any structure
    // this way copyOfTouches will contain all touch points that have not yet been converted into a structure.
    for (let i = 0; i < touches.length; i++) {
      if (!this.isTouchPointPartOfAnyTriangle(touches[i].identifier)) {
        copyOfTouches.push(touches[i]);
      }
    }

    // if there are more than 2 touch points left, find the closest triangle and create a structure
    let keepLooking = true;

    while (keepLooking && copyOfTouches.length > 2) {
      let triangle = getClosestTriangle(
        copyOfTouches,
        Settings.maxTriangleSize / this.p5Instance.scaleRatio,
        this.p5Instance
      );

      if (triangle) {
        let newTouchTriangle = new TouchTriangle(
          this.context,
          triangle.a,
          triangle.b,
          triangle.c,
          triangle.circumference,
          this.p5Instance.createVector
        );

        this.touchTriangles.push(newTouchTriangle);

        this.listeners.forEach((listener) => {
          if (listener.touchTriangleAdded !== undefined) {
            listener.touchTriangleAdded(newTouchTriangle);
          }
        });
      } else {
        // if it returns null there are points that don't qualify as a triangle
        keepLooking = false;
      }
    }
  }

  touchEnded(changedTouches, resetCB) {
    for (let i = 0; i < changedTouches.length; i++) {
      let t = setTimeout(() => {
        this.removeByTouchId(changedTouches[i].identifier);
        resetCB();
        delete this.touchRemovalCache[changedTouches[i].identifier];
      }, Settings.touchPointErrorTolerance);

      this.touchRemovalCache[changedTouches[i].identifier] = t;
    }
  }

  updateTouchPoints(touchPoints) {
    for (let i = 0; i < touchPoints.length; i++) {
      for (let j = 0; j < this.touchTriangles.length; j++) {
        if (this.touchTriangles[j].updateTouchPoint(touchPoints[i])) break;
      }
    }
  }

  removeByTouchId(touchId) {
    for (let i = 0; i < this.touchTriangles.length; i++) {
      if (this.touchTriangles[i].contains(touchId)) {
        this.remove(i);
      }
    }
  }

  remove(index) {
    const touchTriangleToBeRemoved = this.touchTriangles[index];

    this.touchTriangles.splice(index, 1);
    this.listeners.forEach((listener) => {
      if (listener.touchTriangleRemoved !== undefined) {
        listener.touchTriangleRemoved(touchTriangleToBeRemoved);
      }
    });
  }

  isTouchPointPartOfAnyTriangle(touchId) {
    for (let i = 0; i < this.touchTriangles.length; i++) {
      if (this.touchTriangles[i].contains(touchId)) {
        return this.touchTriangles[i];
      }
    }
    return undefined;
  }

  hasTouchTriangles() {
    return this.touchTriangles.length > 0;
  }
}

export default TouchTriangleManager;
