import _ from 'lodash';

/**
 * Iterates over elements of collection, returning the first element predicate returns truthy for.
 * The `predicate` is invoked with three arguments: (value, index|key, collection).
 *
 * @param collection - The collection to inspect.
 *
 * @param childrenPath - The name of the child object property that should be traversed
 *
 * @param predicate - The function invoked per iteration.
 *
 */
const treeFind = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  collection: any,
  childrenPath: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  predicate: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
  const predType = typeof predicate;
  let _predicate = predicate;

  // convert array predicate to object
  if (_.isArray(predicate)) {
    _predicate = _.set({}, predicate[0], predicate[1]);
  }

  for (const subObj of collection) {
    if (
      (predType === 'function' && _predicate(subObj)) ||
      (predType === 'object' && _.matches(_predicate)(subObj))
    ) {
      return subObj;
    }

    const children = _.get(subObj, childrenPath);

    if (!_.isEmpty(children)) {
      const foundSubObj = treeFind(children, childrenPath, predicate);

      if (foundSubObj) {
        return foundSubObj;
      }
    }
  }

  return null;
};

export default treeFind;
