import React from 'react';
import { CSSTransition } from 'ecto-common/lib/external/react-transition-group';

import styles from 'ecto-common/lib/Animations/FadeInFadeOutAnimation.module.css';

interface FadeInFadeOutAnimationProps {
  children: React.ReactNode;
  isVisible: boolean;
  // Disabled as the nodeRef in react-transition-group is not typed
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  nodeRef: any;
}

const FadeInFadeOutAnimation = ({
  children,
  nodeRef,
  isVisible = false
}: FadeInFadeOutAnimationProps) => {
  return (
    <CSSTransition
      in={isVisible}
      timeout={200}
      nodeRef={nodeRef}
      classNames={{
        enter: styles.enter,
        enterActive: styles.enterActive,
        enterDone: styles.enterDone,
        exit: styles.exit,
        exitActive: styles.exitActive
      }}
    >
      {children}
    </CSSTransition>
  );
};

export default FadeInFadeOutAnimation;
