import React, { Component } from 'react';
import { comboViewOrder, comboViewMessageTypes } from '../../assets/combo_view';
import PowerConsumptionView from '../PowerConsumptionView/PowerConsumptionView';
import PowerProductionView from '../PowerProductionView/PowerProductionView';
import EctocloudView from '../EctocloudView/EctocloudView';
import './ComboView.css';

const ViewComponentMap = {
  PowerProductionView,
  PowerConsumptionView,
  EctocloudView
};

type ComboViewProps = {
  socket: any;
  data: any;
  ectocloudMode: boolean;
  size: { width: number; height: number };
};

type ComboViewState = {
  active: string;
};

/** The consolidated combo-view that can toggle between roles. */
export default class ComboView extends Component<
  ComboViewProps,
  ComboViewState
> {
  constructor(props) {
    super(props);
    this.transitionScreens = this.transitionScreens.bind(this);

    this.state = {
      active: comboViewOrder[0]
    };
  }

  componentDidMount() {
    this.props.socket.on(comboViewMessageTypes.CHANGE, this.transitionScreens);
  }

  componentWillUnmount() {
    this.props.socket.off(comboViewMessageTypes.CHANGE, this.transitionScreens);
  }

  /**
   * 1. Hide current view with animation
   * 2. Switch view while hidden
   * 3. Show new view
   */
  transitionScreens({ comboScreen }) {
    const active = comboViewOrder[comboScreen - 1];
    this.setState({ active });
  }

  render() {
    const { data, ectocloudMode, size } = this.props;
    const ActiveView =
      ViewComponentMap[this.state.active] ??
      ViewComponentMap['PowerProductionView'];

    return (
      <ActiveView
        className="ComboView"
        data={data}
        ectocloudMode={ectocloudMode}
        size={size}
      />
    );
  }
}
