import React, { Component } from 'react';
import ToastStore from './ToastStore';
import cssDurationToMilliseconds from '../utils/cssDurationToMilliseconds';
import animations from '../styles/variables/animations';
import styles from './ToastContainer.module.css';
import Toast from 'ecto-common/lib/Toast/Toast';
import {
  CSSTransition,
  TransitionGroup
} from 'ecto-common/lib/external/react-transition-group';
const toastSpeed = cssDurationToMilliseconds(animations.toastSpeed);

const transitionStyles = {
  appear: styles.enter,
  appearActive: styles.enterActive,
  enter: styles.enter,
  enterActive: styles.enterActive,
  exit: styles.exit,
  exitActive: styles.exitActive
};

export type ToastType = {
  type: string;
  message: React.ReactNode;
  id: number;
};

function ToastWrapper({ type, message, id }: ToastType) {
  const toastRef = React.useRef<HTMLDivElement>(null);

  return (
    <TransitionGroup className={styles.toastContainer}>
      <CSSTransition
        classNames={transitionStyles}
        timeout={{
          appear: toastSpeed,
          enter: toastSpeed,
          exit: toastSpeed
        }}
        enter
        appear
        exit
        nodeRef={toastRef}
      >
        <Toast
          type={type}
          ref={toastRef}
          onRemove={() => toastStore.removeToast(id)}
          key={`toast-${id}`}
        >
          {message}
        </Toast>
      </CSSTransition>
    </TransitionGroup>
  );
}

class ToastContainer extends Component {
  //
  // Local variables

  state = {
    toasts: [] as ToastType[]
  };

  //
  // Lifecycle

  componentDidMount() {
    toastStore.addListener(this.onToastChange);
  }

  componentWillUnmount() {
    toastStore.removeListener(this.onToastChange);
  }

  shouldComponentUpdate(_: unknown, nextState: typeof this.state) {
    return this.state.toasts.length !== nextState.toasts.length;
  }

  //
  // Listeners

  onToastChange = (toasts: ToastType[]) => {
    this.setState({ toasts });
  };

  //
  // Render function

  render() {
    return (
      <div className={styles.toastOuterContainer}>
        {this.state.toasts.map((toastProps) => (
          <ToastWrapper key={toastProps.id} {...toastProps} />
        ))}

        <div className={styles.toastPadElement} />
      </div>
    );
  }
}

export default ToastContainer;

export const toastStore = new ToastStore();
