import { APIFetchType } from 'ecto-common/lib/utils/APIFetchType';

let apiFetch: APIFetchType = null;
let externalApiFetch: APIFetchType = null;

export const setAPIFetch = (newApiFetch: APIFetchType) => {
  apiFetch = newApiFetch;
};

export const getAPIFetch = (): APIFetchType => {
  return apiFetch;
};

export const setExternalAPIFetch = (newApiFetch: APIFetchType) => {
  externalApiFetch = newApiFetch;
};

export const getExternalAPIFetch = () => {
  return externalApiFetch;
};
