const storageLanguageKey = 'language';
const storageLastTenantIdKey = 'tenantId';

export function getSelectedLanguage() {
  return (
    localStorage.getItem(storageLanguageKey) ||
    navigator.language ||
    'en'
  ).slice(0, 2);
}

export function setLocationPickerOpen(open: boolean) {
  localStorage.setItem('locationPickerOpen', open ? '1' : '0');
}

export function getLocationPickerOpen() {
  return localStorage.getItem('locationPickerOpen') === '1';
}

export function setSelectedLanguage(language: string) {
  localStorage.setItem(storageLanguageKey, language);
}

export const getLastTenantId = () => {
  return localStorage.getItem(storageLastTenantIdKey);
};

export function setLastTenantId(tenantId: string) {
  localStorage.setItem(storageLastTenantIdKey, tenantId);
}
