import React from 'react';

import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import Icons from 'ecto-common/lib/Icons/Icons';
import T from 'ecto-common/lib/lang/Language';

interface ConfirmDeleteDialogProps {
  onModalClose(): void;
  isLoading?: boolean;
  isOpen: boolean;
  onDelete?(): void;
  children?: React.ReactNode;
  itemName?: string;
}

const ConfirmDeleteDialog = ({
  children,
  itemName,
  isLoading,
  onDelete,
  isOpen,
  onModalClose
}: ConfirmDeleteDialogProps) => {
  return (
    <ActionModal
      compact
      onModalClose={onModalClose}
      isOpen={isOpen}
      isLoading={isLoading}
      headerIcon={Icons.Delete}
      title={T.common.confirmdelete.title}
      actionText={T.common.delete}
      onConfirmClick={onDelete}
    >
      {children ||
        (itemName && T.format(T.common.confirmdelete.message, itemName)) ||
        ''}
    </ActionModal>
  );
};

export default ConfirmDeleteDialog;
