import * as React from 'react';

function SvgClusterSolid(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 512 512" width="1em" height="1em" {...props}>
      <path d="M240 368h-64c-8.8 0-16 7.2-16 16v64c0 8.8 7.2 16 16 16h64c8.8 0 16-7.2 16-16v-64c0-8.8-7.2-16-16-16zM80 48H16C7.2 48 0 55.2 0 64v64c0 8.8 7.2 16 16 16h64c8.8 0 16-7.2 16-16V64c0-8.8-7.2-16-16-16zm160 160h-64c-8.8 0-16 7.2-16 16v64c0 8.8 7.2 16 16 16h64c8.8 0 16-7.2 16-16v-64c0-8.8-7.2-16-16-16zM496 64H176c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h320c8.8 0 16-7.2 16-16V80c0-8.8-7.2-16-16-16zM335.4 224h161.1c8.5 0 15.4 6.9 15.4 15.4v33.1c0 8.5-6.9 15.4-15.4 15.4H335.4c-8.5 0-15.4-6.9-15.4-15.4v-33.1c0-8.5 6.9-15.4 15.4-15.4zm0 160h161.1c8.5 0 15.4 6.9 15.4 15.4v33.1c0 8.5-6.9 15.4-15.4 15.4H335.4c-8.5 0-15.4-6.9-15.4-15.4v-33.1c0-8.5 6.9-15.4 15.4-15.4z" />
    </svg>
  );
}

export default SvgClusterSolid;
