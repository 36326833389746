import SocketMessages from '../../../server/socket-messages';
import { comboViewMessageTypes } from '../assets/combo_view';
import _ from 'lodash';

const fakeClientNumber = 12;

/**
 * This is a local only implementation of the socket.io socket that is used
 * to establish a local session for an ectotable room. For certain rooms where
 * you have both the table and the info views in one screen, you do not want
 * another users session to interfere with your local session.
 *
 * But we still want to maintain the possibility to use the socket.io interface
 * when the room is in a multi device setup. So we have this local implementation
 * that can be used in those cases. It just emits and listens to events locally
 * and does not actually connect to a server.
 */
class ClientSocket {
  constructor() {}

  id = 'client-socket';
  isTable = false;
  connected = true;
  disconnected = false;
  sendBuffer = [];
  allListeners: Record<string, Array<(arg: unknown) => void>> = {};
  nsp = '';
  currentComboScreen = 1;
  ectocloudMode = false;
  room = '';
  roleName = '';

  emit(message: string, data: unknown) {
    if (message === SocketMessages.ROLE_CHANGE) {
      this.roleName = (data as any).role as string;
    } else if (message === comboViewMessageTypes.CHANGE) {
      let nextScreen = (data as any).comboScreen as number;
      if (nextScreen != null) {
        this.currentComboScreen = nextScreen;
      } else {
        this.currentComboScreen = this.currentComboScreen + 1;
        if (this.currentComboScreen > 3) {
          this.currentComboScreen = 1;
        }
      }

      let listeners = this.allListeners[comboViewMessageTypes.CHANGE] ?? [];
      listeners.forEach((listener) =>
        listener({ comboScreen: this.currentComboScreen })
      );
    } else if (message === comboViewMessageTypes.REQUEST) {
      let listeners = this.allListeners[comboViewMessageTypes.CHANGE] ?? [];
      listeners.forEach((listener) =>
        listener({ comboScreen: this.currentComboScreen })
      );
    } else if (message === SocketMessages.REFRESH_REQUEST) {
      let listeners = this.allListeners[SocketMessages.REFRESH_FORCE] ?? [];
      listeners.forEach((listener) => listener(null));
    } else if (message == SocketMessages.REQUEST_ECTOCLOUD_MODE) {
      let listeners =
        this.allListeners[SocketMessages.SET_ECTOCLOUD_MODE] ?? [];
      listeners.forEach((listener) => listener(this.ectocloudMode));
    } else if (message === SocketMessages.DATA_BROADCAST) {
      let listeners = this.allListeners[SocketMessages.DATA] ?? [];
      listeners.forEach((listener) => listener((data as any).data));
    } else {
      let listeners = this.allListeners[message] ?? [];
      listeners.forEach((listener) => listener(data));
    }
  }

  on(message: string, func: (arg: unknown) => void) {
    if (message === SocketMessages.CONNECT) {
      func(null);

      _.defer(() => {
        let listeners = this.allListeners[comboViewMessageTypes.CHANGE] ?? [];
        listeners.forEach((listener) =>
          listener({ comboScreen: this.currentComboScreen })
        );
      });
    } else if (message === SocketMessages.CLIENT_NUMBER) {
      func(fakeClientNumber);
    } else if (message === comboViewMessageTypes.CHANGE) {
      func({ comboScreen: this.currentComboScreen });
    }

    if (!this.allListeners[message]) {
      this.allListeners[message] = [];
    }

    this.allListeners[message].push(func);
  }

  off(message: string, func: (arg: unknown) => void) {
    this.allListeners[message] = (this.allListeners[message] ?? []).filter(
      (x) => x !== func
    );
  }

  disconnect() {
    this.allListeners = {};
  }

  // These are all methods on the socket.io socket, add stub implementations
  // to avoid crashes (I am pretty certain we don't use any of them.)
  subEvents() {}
  open() {}
  connect() {}
  send() {}
  packet() {}

  close() {}
  compress() {}
  binary() {}
}

export const globalClientSocket = new ClientSocket();

export default ClientSocket;
