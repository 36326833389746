import React from 'react';
import classNames from 'classnames';
import Notice, { NoticeProps } from './Notice';
import styles from './Notice.module.css';

type ErrorNoticeProps = NoticeProps & {
  /**
   * Used to override the appearance of the notice. Should be a valid CSS class name.
   */
  className?: string;
};

/**
 * An error notice is a special type of Notice that signifies that an error has occurred.
 */
const ErrorNotice = ({ className = null, ...otherProps }: ErrorNoticeProps) => {
  return (
    <Notice
      className={classNames(styles.errorNotice, className)}
      {...otherProps}
    />
  );
};

export default ErrorNotice;
