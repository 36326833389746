/* eslint no-lone-blocks: 0 */ // --> OFF
import PhysicalLeaf from './physical_leaf';
import Renderable from './renderable';

import Settings from './Settings';

import p5 from 'p5';
import * as d3 from 'd3-ease';
import PhysicalNode from './physical_node';
import Pipe, { calculateWarmAndColdPipePoints } from './Pipe';
import TableP5 from 'js/Table/TableP5';
import { EnergyGridSummary } from 'js/Table/energy_grid';
import AccTankArrow from '../assets/acc_tank_arrow.svg';

/**
 * Objects that are created when a normal physical base is put down on the screen
 * @class Structure
 */
class AccumulatorTank extends PhysicalNode {
  size: number;
  imageSize: number;
  name: string;
  context: any;
  icon: string;
  p5Instance: TableP5;
  createdTimeStamp: number;
  templateFingerPrint: number;
  dirtySince: number;
  dead: boolean;
  baseScale: number;
  shapeLayout: {
    pieChartSize: number;
    rawSize: number;
  };
  warmPipe: Pipe;
  coldPipe: Pipe;
  extractHeat: boolean;
  extractIntensity: number;
  accTankImage: p5.Image;
  connectedNode: PhysicalNode;

  constructor(
    context: any,
    p5Instance: TableP5,
    buildingTemplate: any,
    scale: number,
    imageCache: Record<string, p5.Image>
  ) {
    super(null);
    this.baseScale = scale;
    this.active = false;
    this.size = 1.0;
    this.imageSize = Settings.accTankArrowImageSize;
    this.dead = false;
    this.extractHeat = true;
    this.extractIntensity = 0.1;
    this.connectedNode = null;
    this.accTankImage = imageCache[AccTankArrow];

    this.shapeLayout = {
      pieChartSize: 0,
      rawSize: 0
    };

    this.dirtySince = context.appNow();
    this.name = 'Accumulator Tank';
    this.context = context;
    Renderable.implement(this, context);

    this.buildingId = buildingTemplate.buildingId;
    this.icon = buildingTemplate.icon;
    this.name = buildingTemplate.name;

    this.p5Instance = p5Instance;
    this.createdTimeStamp = p5Instance.frameCount;
    this.templateFingerPrint = buildingTemplate.fingerprint;
  }

  connectToNode(node: PhysicalLeaf) {
    this.connectedNode = node;
  }

  destructor() {
    this.dead = true;
  }

  touch() {
    this.dirtySince = this.context.appNow();
  }

  /**
   * @throws {String} Message about dead node
   */
  dataIsDirtySince(marker) {
    if (this.dead) throw 'Trying to access dead Structure';

    return marker < this.dirtySince;
  }

  /**
   * @throws {String} Message about dead node
   */
  layoutPipes(p5Instance: TableP5) {
    // if (this.dead) throw 'Trying to access dead Structure';

    if (this.connectedNode === undefined) {
      return;
    }

    const [warmStartPoint, warmEndPoint, coldStartPoint, coldEndPoint] =
      calculateWarmAndColdPipePoints(
        this.center(),
        this.connectedNode.center(),
        this._pipeInterSectRadius(),
        this.connectedNode._pipeInterSectRadius(),
        this.context.Vector
      );

    if (this.warmPipe == null) {
      this.warmPipe = new Pipe(
        p5Instance,
        warmStartPoint,
        warmEndPoint,
        Settings.warmPipeColor
      );
    }

    if (this.coldPipe == null) {
      this.coldPipe = new Pipe(
        p5Instance,
        coldStartPoint,
        coldEndPoint,
        Settings.coldPipeColor
      );
    }

    this.coldPipe.init(
      p5Instance,
      coldStartPoint,
      coldEndPoint,
      Settings.coldPipeColor
    );
    this.warmPipe.init(
      p5Instance,
      warmStartPoint,
      warmEndPoint,
      Settings.warmPipeColor
    );
  }

  _pipeInterSectRadius() {
    return this.shapeLayout.pieChartSize / 2;
  }

  resetConnections() {
    super.resetConnections();
  }

  /* Get the "size" of the structure, which is the value scaling value coming from the user rotation */
  getSize() {
    return this.size;
  }

  setSummary(summary: EnergyGridSummary) {
    // console.log('Set summary', summary.coolingSatisfaction, summary.heatingSatisfaction, summary.heating, summary.cooling);

    this.extractHeat =
      summary.heatingSatisfaction < summary.coolingSatisfaction;
    if (this.extractHeat) {
      this.extractIntensity = summary.heating / 50;
    } else {
      this.extractIntensity = summary.cooling / 50;
    }
  }

  shapeRadius() {
    return this.shapeLayout.rawSize / 2;
  }

  center() {
    return this.centerPos.copy();
  }

  layout(p5Instance: TableP5) {
    let settingsSize = Settings.structureDialSize * this.baseScale;
    let baseSize = Settings.baseStructureSize * this.baseScale;
    const thickness = this.getSize() * baseSize;
    let pieChartSize = baseSize + thickness + settingsSize;

    const t = p5Instance.frameCount - this.createdTimeStamp;

    this.shapeLayout.rawSize = pieChartSize;
    this.imageSize = Settings.accTankArrowImageSize;
    if (t < Settings.structureAnimationTime) {
      const animation = d3.easeElasticOut(
        p5Instance.map(t, 0, Settings.structureAnimationTime, 0, 1)
      );
      pieChartSize *= animation;
      this.imageSize *= animation;
    }

    this.shapeLayout.pieChartSize = pieChartSize;
  }

  _drawPipes(p5Instance: TableP5) {
    p5Instance.push();

    if (
      this.connectedNode &&
      this.connectedNode.node &&
      this.warmPipe &&
      this.coldPipe
    ) {
      this.warmPipe.draw(!this.extractHeat, this.extractIntensity);
      this.coldPipe.draw(this.extractHeat, this.extractIntensity);
    }

    p5Instance.pop();
  }

  drawBg(p5Instance: TableP5) {
    this.layoutPipes(p5Instance);
    this._drawPipes(p5Instance);
  }

  /**
   * @throws {String} Message about dead structure
   */
  draw(p: TableP5) {
    if (this.dead) throw 'Trying to access dead Structure';

    const center = this.center();
    const mainRadius = this.shapeLayout.pieChartSize;
    const mainThickness = 2;

    p.push();
    {
      p.push();
      p.noStroke();

      const circles = this.extractHeat
        ? ([
            ['#2D5962', 1.0],
            ['#3C7C89', 0.85],
            ['#4C9FAF', 0.5]
          ] as const)
        : ([
            ['#722A26', 1.0],
            ['#9C2F2A', 0.85],
            ['#C6342D', 0.5]
          ] as const);

      if (this.active) {
        p.push();
        p.noStroke();
        p.fill(255, 255, 255, 255);
        const circleSize = mainRadius - mainThickness + 3;
        p.arc(center.x, center.y, circleSize, circleSize, 0, Math.PI * 2);
        p.pop();
      }

      for (let i = 0; i < circles.length; i++) {
        const [color, relativeSize] = circles[i];
        const circleSize = (mainRadius - mainThickness) * relativeSize;
        p.fill(color);
        p.arc(center.x, center.y, circleSize, circleSize, 0, Math.PI * 2);
      }

      p.push();

      if (this.accTankImage != null) {
        p.translate(center.x, center.y + Math.sin(p.frameCount * 0.08) * 5);

        if (this.extractHeat) {
          p.rotate(Math.PI);
        }

        p.image(
          this.accTankImage,
          -this.imageSize * 0.5,
          -this.imageSize * 0.5,
          this.imageSize,
          this.imageSize
        );
      }

      p.pop();

      p.fill(Settings.structureFontColor);
      p.textFont(Settings.structureFont);
      p.textSize(Settings.structureHeaderFontSize);
      p.textAlign(p.CENTER, p.CENTER);
      p.text(
        'Accumulator Tank',
        center.x,
        center.y +
          (mainRadius - mainThickness) / 2.0 +
          Settings.structureHeaderFontSize
      );

      p.pop();
    }
    p.pop();
  }
}

export default AccumulatorTank;
