import io from 'socket.io-client';
import Performance from '../performance';
import * as DataManager from './data/data_manager';
import SocketMessages from '../../../server/socket-messages';
import { TableContextType } from 'js/Table/context';
import Structure from 'js/Table/Structure';
import Grid, {
  HistoricEnergyNeed,
  PredictedEnergyNeed
} from 'js/Table/energy_grid';

const statusUpdateInterval = 500; // Never send updates of status more often than every half second

export type EnergyData = {
  location: {
    city: string;
    longitude: number;
    latitude: number;
    zone: string;
  };
  totalEnergyNeed: number;
  totalHeatingNeedMW: number;
  totalCoolingNeedMW: number;
  structures: any[];
  heatingNeedPerDayPerHour: number[];
  coolingNeedPerDayPerHour: number[];
  heatingNeedPerMonth: number[];
  coolingNeedPerMonth: number[];
  totalEnergyHistoric: HistoricEnergyNeed[];
  totalEnergyPredictive: PredictedEnergyNeed[];

  totalAddedEffectSystemMW: number;
  balancedEnergyMW: number;
  unbalancedEnergyMW: number;
};

/**
 * The object responsible for sending information from the table to the server
 * @class DataTransmitter
 */
export default class DataTransmitter {
  context: TableContextType;
  timeStampLastData: number;
  lastDataMarker: number;
  socket: any;

  constructor(context, socket) {
    this.timeStampLastData = -100000000000;

    this.context = context;
    this.lastDataMarker = this.context.appNow();

    this.socket =
      socket ||
      io(window.location.origin, {
        query: {
          role: 'table'
        },
        credentials: 'include'
      });
  }

  _convertDataStructureToSendStructure(
    dataStructures: Structure[],
    sendStructures
  ) {
    for (let i = 0; i < dataStructures.length; i++) {
      let s = dataStructures[i];

      const sendStructure = {
        name: s.name,
        id: s.id,
        fingerPrint: s.templateFingerPrint,
        heatingNeedMW: s.getHeatingNeedMW() - s.getExternalGridHeatingNeedMW(),
        coolingNeedMW: s.getCoolingNeedMW() - s.getExternalGridCoolingNeedMW(),
        electricityEffectMW: s.getCurrentElectricityEffectMW(),
        icon: s.icon,
        buildingId: s.buildingId
      };

      sendStructures.push(sendStructure);
    }
  }

  setEctocloudMode(newMode) {
    this.socket.emit(SocketMessages.SET_ECTOCLOUD_MODE, newMode);
  }

  refreshAll() {
    this.socket.emit(SocketMessages.REFRESH_REQUEST);
  }

  setTableState(mainState, subState = undefined, fromTableApp = true) {
    const tableStateObject = {
      mainState: mainState.name,
      fromTableApp: fromTableApp,
      subState: subState
    };

    this.socket.emit(SocketMessages.TABLE_STATE, tableStateObject);
  }

  transmit(energyGrid: Grid, structures, location) {
    const performanceObject = { name: 'send status' };

    Performance.startMeasureFor(performanceObject);

    const currentTimestamp = this.context.appNow();

    if (structures.dataIsDirtySince(this.lastDataMarker)) {
      if (currentTimestamp > this.timeStampLastData + statusUpdateInterval) {
        this.lastDataMarker = this.context.appNow();
        /* The large array summarising needs over every hour the whole year
         */

        let energyStructures: any[] = [];
        const { heating, cooling, monthsHeating, monthsCooling } =
          DataManager.getAnnualNeed(structures.getAllChildren());

        this._convertDataStructureToSendStructure(
          structures.getAllChildren(),
          energyStructures
        );

        const data: EnergyData = {
          location: {
            city: 'Lund',
            longitude: 13.21769,
            latitude: 55.71148,
            zone: 'SE'
          },
          totalEnergyNeed:
            structures.getTotalEnergyNeedMW() -
            structures.getExternalGridTotalEnergyNeedMW(),
          totalHeatingNeedMW:
            structures.getHeatingNeedMW() -
            structures.getExternalGridHeatingNeedMW(),
          totalCoolingNeedMW:
            structures.getCoolingNeedMW() -
            structures.getExternalGridCoolingNeedMW(),
          structures: energyStructures,
          heatingNeedPerDayPerHour: heating,
          coolingNeedPerDayPerHour: cooling,
          heatingNeedPerMonth: monthsHeating,
          coolingNeedPerMonth: monthsCooling,
          totalEnergyHistoric: [],
          totalEnergyPredictive: [],
          totalAddedEffectSystemMW: 0,
          balancedEnergyMW: 0,
          unbalancedEnergyMW: 0
        };

        data.location = location;

        if (energyGrid) {
          /*Electricity*/
          data.totalEnergyHistoric = energyGrid.totalEnergyHistoric;
          data.totalEnergyPredictive = energyGrid.totalEnergyPredictive;
          data.totalAddedEffectSystemMW =
            energyGrid.summary.totalAddedEffectSystemMW;
          data.balancedEnergyMW = energyGrid.summary.balancedEnergyMW;
          data.unbalancedEnergyMW = energyGrid.summary.unbalancedEnergyMW;
        }

        const dataAsString = JSON.stringify(data);

        const performanceObject2 = { name: 'socket emit' };

        Performance.startMeasureFor(performanceObject2);

        this.socket.emit(SocketMessages.DATA_BROADCAST, {
          data: dataAsString
        });

        Performance.stopMeasureFor(performanceObject2);

        this.socket.data = dataAsString;

        this.timeStampLastData = currentTimestamp;
      }
    }
    Performance.stopMeasureFor(performanceObject);
  }
}
