import React, { useMemo } from 'react';
import _ from 'lodash';
import MenuDropdownButton from 'ecto-common/lib/DropdownButton/MenuDropdownButton';
import { DropdownButtonMenuPosition } from 'ecto-common/lib/DropdownButton/DropdownButton';
import { DashboardPanel } from 'ecto-common/lib/Dashboard/Panel';

export type PanelDropDownOption = {
  icon: React.ReactNode;
  label: string;
  isVisible?: (
    panel: DashboardPanel,
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    panelData: Record<string, any>
  ) => boolean;
  isEnabled?: (
    panel: DashboardPanel,
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    panelData: Record<string, any>
  ) => boolean;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  action: (panel: DashboardPanel, panelData: Record<string, any>) => void;
};

interface PanelDropDownMenuProps {
  panel?: DashboardPanel;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  panelData?: Record<string, any>;
  menuOptions?: PanelDropDownOption[];
}

const PanelDropDownMenu = ({
  panel,
  panelData,
  menuOptions = []
}: PanelDropDownMenuProps) => {
  const boundOptions = useMemo(() => {
    return _.reduce(
      menuOptions,
      (result, menuOption) => {
        if (
          menuOption.isVisible == null ||
          menuOption.isVisible(panel, panelData)
        ) {
          result.push({
            ...menuOption,
            action: () => menuOption.action(panel, panelData),
            isEnabled: menuOption.isEnabled?.(panel, panelData)
          });
        }
        return result;
      },
      []
    );
  }, [panel, panelData, menuOptions]);

  return (
    <MenuDropdownButton
      options={boundOptions}
      menuPosition={DropdownButtonMenuPosition.BOTTOM_RIGHT}
    />
  );
};

export default PanelDropDownMenu;
