import React from 'react';
import SvgIcon, { SvgIconProps } from './SvgIcon';
import styles from './Help.module.css';

const Help = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 42 42" {...props}>
    <path
      className={styles.cls1}
      d="M19.64,23.74a4,4,0,0,1-.13-1c0-1.09.17-1.46,1.46-3.28A4,4,0,0,0,22,17a1.28,1.28,0,0,0-1.37-1.33,3.76,3.76,0,0,0-2.27.88L17.82,15a5.25,5.25,0,0,1,3.38-1.26,2.69,2.69,0,0,1,3,2.91c0,1.28-.24,1.76-1.84,3.77A4.27,4.27,0,0,0,21,23.31v.43Zm2,3.36a1.21,1.21,0,0,1-1.35,1.16,1.18,1.18,0,1,1,0-2.33A1.22,1.22,0,0,1,21.65,27.1Z"
    />
    <circle className={styles.cls2} cx="21" cy="21" r="15" />
  </SvgIcon>
);

export default Help;
