import { ObjectValues } from 'ecto-common/lib/utils/typescriptUtils';

const HorizontalAlignments = {
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right'
} as const;

export type HorizontalAlignment = ObjectValues<typeof HorizontalAlignments>;

export default HorizontalAlignments;
