import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';

import TextInput from 'ecto-common/lib/TextInput/TextInput';
import styles from 'ecto-common/lib/KeyValueInput/KeyValueInput.module.css';
import MultilineTextInput from 'ecto-common/lib/TextInput/MultilineTextInput';

export interface KeyValueInternalTextInputProps {
  className?: string;
  onChange?(
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ): void;
  value?: string;
  placeholder?: string;
  autoFocus?: boolean;
  type?: string;
  onEnterKeyPressed?(
    event:
      | React.KeyboardEvent<HTMLInputElement>
      | React.KeyboardEvent<HTMLTextAreaElement>
  ): void;
  disabled?: boolean;
  hasError?: ((value: string) => boolean) | boolean;
  multiline?: boolean;
  onBlur?(
    event:
      | React.FocusEvent<HTMLInputElement>
      | React.FocusEvent<HTMLTextAreaElement>
  ): void;
}

const KeyValueInternalTextInput = ({
  onChange = null,
  value = null,
  disabled = false,
  className = null,
  type = null,
  onEnterKeyPressed = null,
  placeholder = null,
  autoFocus = false,
  hasError = false,
  multiline = false,
  ...otherProps
}: KeyValueInternalTextInputProps) => {
  const _hasError = _.isFunction(hasError) ? hasError(value) : hasError;
  const wrapperClassname = classNames(
    styles.valueItem,
    className,
    _hasError && styles.error
  );

  if (multiline) {
    return (
      <MultilineTextInput
        wrapperClassName={wrapperClassname}
        onChange={onChange}
        value={value}
        disabled={disabled}
        onEnterKeyPressed={onEnterKeyPressed}
        autoFocus={autoFocus}
        placeholder={placeholder}
        type={type}
        {...otherProps}
      />
    );
  }

  return (
    <TextInput
      wrapperClassName={wrapperClassname}
      onChange={onChange}
      value={value}
      disabled={disabled}
      onEnterKeyPressed={onEnterKeyPressed}
      autoFocus={autoFocus}
      placeholder={placeholder}
      type={type}
      {...otherProps}
    />
  );
};

export default KeyValueInternalTextInput;
