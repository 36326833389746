import React, { useCallback } from 'react';
import styles from './TreeView.module.css';
import Icons from 'ecto-common/lib/Icons/Icons';
import Checkbox from 'ecto-common/lib/Checkbox/Checkbox';
import classNames from 'classnames';
import { KEY_CODE_ENTER } from 'ecto-common/lib/constants';
import dimensions from 'ecto-common/lib/styles/dimensions';
import { isTreeNodeFolder } from 'ecto-common/lib/TreeView/TreeView';

const LEVEL_MARGIN = 30;
const COMPACT_LEVEL_MARGIN = 8;

export type TreeViewColumnType<
  ItemType extends TreeViewNodeType = TreeViewNodeType
> = {
  dataFormatter?: (
    node: ItemType,
    isNodeOpen: boolean,
    highlightNode: boolean,
    path: string,
    searchFilterActive: boolean
  ) => React.ReactNode;
  width?: number;
  maxWidth?: number;
  minWidth?: number;
  flexGrow?: number;
  flexShrink?: number;
};

export type TreeViewNodeType = {
  name: string;
  path: string;
  id: string;
  children: TreeViewNodeType[];
};

export type InternalTreeViewNodeType = {
  node: TreeViewNodeType;
  level: number;
  isVisible: boolean;
  parent: TreeViewNodeType;
  path: string;
};

interface TreeViewNodeProps {
  node?: TreeViewNodeType;
  onClickNode(node: TreeViewNodeType): void;
  onDoubleClickNode(node: TreeViewNodeType): void;
  onClickExpand(node: TreeViewNodeType): void;
  isNodeOpen?: boolean;
  isNodeSelected?: boolean;
  isVisible?: boolean;
  level?: number;
  columns?: TreeViewColumnType[];
  multiSelect?: boolean;
  searchFilterActive?: boolean;
  path?: string;
  embedded: boolean;
  compact: boolean;
}

const TreeViewNode = ({
  node,
  onClickNode,
  onDoubleClickNode,
  onClickExpand,
  isNodeOpen,
  isNodeSelected,
  isVisible,
  level,
  columns,
  multiSelect,
  searchFilterActive,
  path,
  embedded,
  compact
}: TreeViewNodeProps) => {
  const isFolder = isTreeNodeFolder(node);
  const onClick = useCallback(() => onClickNode?.(node), [node, onClickNode]);
  const onDoubleClick = useCallback(
    () => onDoubleClickNode?.(node),
    [node, onDoubleClickNode]
  );

  const onKeyDown: React.KeyboardEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      if (e.keyCode === KEY_CODE_ENTER) {
        onClick();
      }
    },
    [onClick]
  );

  const _onClickExpand: React.MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      e.stopPropagation();
      onClickExpand(node);
    },
    [node, onClickExpand]
  );

  if (!isVisible) {
    return null;
  }

  const levelMargin = compact ? COMPACT_LEVEL_MARGIN : LEVEL_MARGIN;

  const highlightRow = !multiSelect && isNodeSelected;

  // Intentionally not memoized since I don't want to construct elements for non-visible nodes.
  const columnEntities = columns.map((column, idx) => {
    const {
      width: columnWidth = undefined,
      maxWidth,
      minWidth,
      flexGrow = 0,
      flexShrink = 1,
      dataFormatter
    } = column;

    return (
      <div
        className={styles.rowItem}
        style={{
          maxWidth,
          minWidth,
          flexGrow,
          flexShrink,
          width: columnWidth
        }}
        key={idx}
      >
        {dataFormatter?.(
          node,
          isNodeOpen,
          highlightRow,
          path,
          searchFilterActive
        )}
      </div>
    );
  });

  return (
    <div
      id={node.id}
      className={classNames(
        styles.row,
        compact && styles.compact,
        highlightRow && styles.highlighted,
        embedded && styles.embedded
      )}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      onKeyDown={onKeyDown}
      style={{
        minHeight: dimensions.dataTableRowHeightStandard
      }}
      tabIndex={0}
    >
      <div
        className={styles.expansionArea}
        style={{
          paddingLeft: level * levelMargin + dimensions.standardMargin
        }}
        onClick={_onClickExpand}
      >
        {isFolder && (
          <div
            className={classNames(
              styles.expansionItem,
              isNodeOpen && styles.isOpen
            )}
          >
            {node.children.length > 0 && <Icons.MenuRightArrow />}
          </div>
        )}
      </div>
      {!isFolder && multiSelect && (
        <div className={styles.rowItem}>
          <Checkbox compact checked={isNodeSelected} />
        </div>
      )}

      {columnEntities}
    </div>
  );
};

export default React.memo(TreeViewNode);
