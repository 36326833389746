import { Image } from 'p5';
import SquareButton from '../square_button';
import TableP5 from '../TableP5';
import Settings from '../Settings';

const MENU_DRAWER_INNER_VERTICAL_PADDING = 7;
const MENU_DRAWER_PADDING_HORIZONTAL = 12;
const MENU_DRAWER_LINE_HEIGHT = 58;
const MENU_DRAWER_IMAGE_MARGIN = 14;
const MENU_DRAWER_IMAGE_SIZE = 45;
const MENU_DRAWER_TEXT_SIZE = 18;

export class MenuDrawerButton extends SquareButton {
  title: string;
  image: Image;
  imagePath: string | null;
  id: string;
  onClick: () => void;

  // Don't use regular draw as it does not match signature, each button
  // seems to have their own signature
  drawButton(
    p5Instance: TableP5,
    x: number,
    y: number,
    lineWidth: number,
    isSelected: boolean
  ) {
    if (isSelected) {
      p5Instance.fill(Settings.selectedColor);
    } else {
      p5Instance.fill(255, 255, 255);
    }

    let margin = 0;
    if (this.image != null) {
      p5Instance.fill(80, 80, 80, 80);

      let imgWidth = MENU_DRAWER_IMAGE_SIZE;
      let imgAspect = this.image.height / this.image.width;
      if (imgAspect > 1) {
        imgWidth /= imgAspect;
      }

      let imgOffsetX = (MENU_DRAWER_IMAGE_SIZE - imgWidth) * 0.5;

      p5Instance.rect(
        x + MENU_DRAWER_PADDING_HORIZONTAL,
        y + MENU_DRAWER_INNER_VERTICAL_PADDING,
        MENU_DRAWER_IMAGE_SIZE,
        MENU_DRAWER_IMAGE_SIZE,
        5
      );

      p5Instance.image(
        this.image,
        x + MENU_DRAWER_PADDING_HORIZONTAL + imgOffsetX,
        y + MENU_DRAWER_INNER_VERTICAL_PADDING,
        imgWidth,
        imgWidth * imgAspect
      );

      p5Instance.fill(255, 255, 255, 255);
      margin = MENU_DRAWER_IMAGE_MARGIN + MENU_DRAWER_IMAGE_SIZE;
    }

    p5Instance.textAlign(p5Instance.LEFT, p5Instance.CENTER);
    p5Instance.text(
      this.title,
      x + margin + MENU_DRAWER_PADDING_HORIZONTAL,
      y + MENU_DRAWER_INNER_VERTICAL_PADDING,
      lineWidth,
      MENU_DRAWER_LINE_HEIGHT - MENU_DRAWER_INNER_VERTICAL_PADDING * 2
    );
    p5Instance.fill(255, 255, 255);

    this.bounding.x = x;
    this.bounding.y = y;
    (this.bounding.w = lineWidth), (this.bounding.h = MENU_DRAWER_LINE_HEIGHT);
  }

  constructor(
    id: string,
    title: string,
    onClick: () => void,
    imagePath: string | null = null
  ) {
    super();

    this.title = imagePath == null ? '• ' + title : title;
    this.id = id;
    this.onClick = onClick;
    this.imagePath = imagePath;
    this.image = null;
    this.addListener(onClick);
  }

  renderInit(p5Instance: TableP5, imageCache: Record<string, Image>) {
    if (this.imagePath != null) {
      this.image =
        imageCache[this.imagePath] ?? p5Instance.loadImage(this.imagePath);
    }
  }
}

export default class MenuDrawer {
  options: MenuDrawerButton[] = [];
  visible = false;
  menuLayout = {
    menuWidth: 0
  };

  toggleVisibility() {
    this.visible = !this.visible;
  }

  constructor() {
    this.options = [];
  }

  height() {
    return this.options.length * MENU_DRAWER_LINE_HEIGHT;
  }

  mouseDragged(_x: number, _y: number) {
    return false;
  }

  mouseReleased(x: number, y: number) {
    if (!this.visible) {
      return false;
    }

    for (let i = 0; i < this.options.length; i++) {
      if (this.options[i].mouseReleased(x, y)) return true;
    }
    return false;
  }

  mousePressed(x: number, y: number) {
    if (!this.visible) {
      return false;
    }

    for (let i = 0; i < this.options.length; i++) {
      if (this.options[i].mousePressed(x, y)) return true;
    }
    return false;
  }

  touchStarted(
    touch: any,
    touchedCallback = (_button: MenuDrawerButton) => {}
  ) {
    if (!this.visible) {
      return false;
    }

    for (let i = 0; i < this.options.length; i++) {
      const button = this.options[i];

      if (button.touchStarted(touch.clientX, touch.clientY)) {
        touchedCallback(button);
        return true;
      }
    }
    return false;
  }

  renderInit(p5Instance: TableP5, imageCache: Record<string, Image>) {
    for (const button of this.options) {
      button.renderInit(p5Instance, imageCache);
    }
  }

  layout(p5Instance: TableP5) {
    let menuWidth = 50;
    p5Instance.textSize(MENU_DRAWER_TEXT_SIZE);

    for (let option of this.options) {
      let margin = MENU_DRAWER_PADDING_HORIZONTAL * 2;
      if (option.image != null) {
        margin += MENU_DRAWER_IMAGE_MARGIN + MENU_DRAWER_IMAGE_SIZE;
      }

      const curWidth = p5Instance.textWidth(option.title) + margin;

      menuWidth = Math.max(menuWidth, curWidth);
    }

    this.menuLayout.menuWidth = menuWidth;
  }

  draw(p5Instance: TableP5, x: number, y: number, selectedId: string = null) {
    // Needed to be done inside draw since we cannot
    // rely on layout being called outside at the moment, it
    // is not part of the button interface
    this.layout(p5Instance);

    let curY = y;
    p5Instance.push();
    p5Instance.textSize(MENU_DRAWER_TEXT_SIZE);

    p5Instance.fill(0, 0, 0, 210.0);
    p5Instance.rect(x, y, this.menuLayout.menuWidth, this.height());
    p5Instance.fill(255, 255, 255, 255.0);

    for (let i = 0; i < this.options.length; i++) {
      const option = this.options[i];
      option.drawButton(
        p5Instance,
        x,
        curY,
        this.menuLayout.menuWidth,
        selectedId === option.id
      );
      curY += MENU_DRAWER_LINE_HEIGHT;
      if (i < this.options.length - 1) {
        p5Instance.fill(255, 255, 255, 100.0);
        p5Instance.rect(x, curY, this.menuLayout.menuWidth, 2);
        p5Instance.fill(255, 255, 255, 255.0);
      }
    }

    p5Instance.pop();
  }
}
