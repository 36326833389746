import React from 'react';
import {
  SignalTypeResponseModel,
  UnitResponseModel
} from 'ecto-common/lib/API/APIGen';
import { SignalInputType } from 'ecto-common/lib/Dashboard/datasources/LastSignalValuesDataSource';
import { ModelEditorProps } from 'ecto-common/lib/ModelForm/ModelEditor';
import { ModelFormSectionType } from 'ecto-common/lib/ModelForm/ModelPropType';
import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';
import { EmptySignalType } from 'ecto-common/lib/utils/constants';
import _ from 'lodash';
import { GraphMinMaxSettings } from 'ecto-common/lib/types/EctoCommonTypes';
import { GraphMinMaxSettingsTable } from 'ecto-common/lib/GraphMinMaxSettingsTable/GraphMinMaxSettingsTable';
import T from 'ecto-common/lib/lang/Language';
import { EditDashboardPanelEnvironment } from 'ecto-common/lib/Dashboard/panels';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';

type GraphMinMaxModelEditorProps = Omit<
  ModelEditorProps<EditDashboardPanelEnvironment>,
  'rawValue'
> & {
  rawValue: Record<string, GraphMinMaxSettings>;
};

const GraphMinMaxModelEditor = ({
  rawValue,
  environment,
  updateItem
}: GraphMinMaxModelEditorProps) => {
  const signalTypesMap: Record<string, SignalTypeResponseModel> =
    useCommonSelector((state) => state.general.signalTypesMap);
  const signalUnitTypesMap: Record<string, UnitResponseModel> =
    useCommonSelector((state) => state.general.signalUnitTypesMap);

  const units = _.uniq(
    (environment.allSignalInputs as SignalInputType[]).map((signal) => {
      const signalType = signalTypesMap[signal.signalTypeId] ?? EmptySignalType;
      return signalUnitTypesMap[signalType.unitId];
    })
  );

  return (
    <div>
      <GraphMinMaxSettingsTable
        units={units}
        minMaxSettings={rawValue}
        onMinMaxSettingsChanged={updateItem}
        onConfirmMinMaxSettings={updateItem}
        setHasChanges={_.noop}
      />
    </div>
  );
};

type ObjectWithMinMaxSettings = {
  minMaxSettings?: Record<string, GraphMinMaxSettings>;
};

export const graphMinMaxSection: ModelFormSectionType<
  ObjectWithMinMaxSettings,
  EditDashboardPanelEnvironment
> = {
  label: T.admin.dashboards.panels.minmaxsettings,
  initiallyCollapsed: true,
  models: [
    {
      modelType: ModelType.CUSTOM,
      render: (props) => <GraphMinMaxModelEditor {...props} />,
      key: (input) => input.minMaxSettings
    }
  ]
};
