import styles from './ToolbarMenu.module.css';
import React from 'react';

interface ToolbarMenuProps {
  /**
   * The content of the toolbar menu.
   */
  children?: React.ReactNode;
}

/**
 * A toolbar menu is an icon based menu. It is useful for scenarios where you have a lot of actions that needs to be easily accessible. The buttons have no title but their action can be identified using tool tips.
 */
const ToolbarMenu = ({ children }: ToolbarMenuProps) => {
  return <div className={styles.toolbarMenu}>{children}</div>;
};

export default ToolbarMenu;
