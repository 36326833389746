import ButtonManager from './button_drawer/button_manager';
import p5 from 'p5';
import EnergyNode from './energy_node';
import TableP5 from 'js/Table/TableP5';

let idCounter = 0;

/**
 * An abstract node in the network
 * @class PhysicalNode
 */
class PhysicalNode {
  centerPos: p5.Vector;
  id: number;
  buttons: ButtonManager;
  tuioId?: number;
  active: boolean;
  node?: EnergyNode;
  reportedRotation?: number;
  validTUIOObject: boolean;
  buildingId?: string = undefined;

  constructor(center) {
    this.centerPos = center;
    this.id = idCounter++;
    this.buttons = new ButtonManager();
    this.active = false;
    this.node = null;
    this.reportedRotation = null;
    this.validTUIOObject = false;
  }

  /**
   * Determines if x, y hit the general area of this structure or not.
   */

  hit(
    x: number,
    y: number,
    minDistance = 0
  ): [node: PhysicalNode, x: number, y: number] {
    const v = new p5.Vector(x, y);
    const center = this.center();
    const distanceFromCenter = v.dist(center);

    if (distanceFromCenter < this.shapeRadius() + minDistance) {
      return [this, center.x - x, center.y - y];
    }
    return null;
  }

  shapeRadius() {
    return 0;
  }

  lengthOfLeaves() {
    return 0;
  }

  getCurrentElectricityEffectMW() {
    return this.node?.electricityNode?.getCurrentEffectMW() ?? 0;
  }

  getExternalGridHeatingNeedMW() {
    return this.node?.outsideGrid ? this.node.getHeatingNeedMW() : 0;
  }

  getExternalGridCoolingNeedMW() {
    return this.node?.outsideGrid ? this.node.getCoolingNeedMW() : 0;
  }

  getHeatingNeedMW() {
    return this.node?.getHeatingNeedMW() ?? 0;
  }

  getCoolingNeedMW() {
    return this.node?.getCoolingNeedMW() ?? 0;
  }

  destructor() {}

  touchTriangleRotationUpdated(_rotation: number) {}

  touchTriangleCenterUpdated(posCenter: p5.Vector) {
    this.centerPos = posCenter;
  }

  hitMenu(_x: number, _y: number) {
    return false;
  }

  resetConnections() {}

  layout(_p5Instance: TableP5) {}

  drawBg(_p5Instance: TableP5) {}

  draw(_p5Instance: TableP5) {}

  dataIsDirtySince(_marker: number) {
    return false;
  }

  _pipeInterSectRadius() {
    return 0;
  }

  center() {
    return this.centerPos;
  }

  executePersistentConnection() {
    // It's not wrong to call but if the derived class is not implementing this it has no effect
  }

  /**
   * @param {PhysicalNode} otherNode the node to connect to
   * @throws {String} Message about bad in parameters
   * @return {number}
   */
  squaredDist(otherNode) {
    if (!(otherNode instanceof PhysicalNode)) {
      throw 'PhysicalLeaf call to squaredDist with bad parameter';
    }

    const delta = {
      x: this.center().x - otherNode.center().x,
      y: this.center().y - otherNode.center().y
    };

    return delta.x * delta.x + delta.y * delta.y;
  }
}

export default PhysicalNode;
