import React, { useContext } from 'react';
import DotLoader from 'react-spinners/DotLoader';
import classnames from 'classnames';
import Flex from '../Flex/Flex';
import ClientNr from '../App/ClientNr';
import './View.css';
import AppContext from '../App/app_context';

const css = 'margin-left: 2em';

type ViewProps = {
  title?: string;
  children?: any;
  className?: string;
  id?: string;
  direction?: 'column' | 'row';
  onClick?: any;
  style?: any;
  [key: string]: any;
};

export default function View({
  title,
  children,
  className,
  id,
  direction,
  onClick,
  ...otherProps
}: ViewProps) {
  const { colors, connectionState } = useContext(AppContext);
  // Randomize color. Completely unnecessary :-)
  const selectedColors = [
    colors.turqoise,
    colors.orange,
    colors.white,
    colors.cyan
  ];
  const color =
    selectedColors[Math.floor(Math.random() * selectedColors.length)];

  const flexAttrs = {
    ...otherProps,
    onClick,
    id,
    className: classnames(['View', className]),
    direction: 'column'
  } as const;

  return (
    <Flex {...flexAttrs}>
      {title && (
        <div className="View__title">
          <h1>{title}</h1>
          <DotLoader
            css={css}
            size={'2rem'}
            color={color}
            loading={!connectionState}
          />
          {!connectionState}
        </div>
      )}
      <Flex className="View__content" direction={direction || 'row'}>
        {children}
      </Flex>
      {!title && !connectionState && (
        <div className="View__centerSpinnerContainer">
          <DotLoader size={2} color={color} loading={!connectionState} />
        </div>
      )}
      <ClientNr />
    </Flex>
  );
}
