import React from 'react';
import classNames from 'classnames';
import Tooltip from 'ecto-common/lib/Tooltip/Tooltip';
import { CheckMarkIcon, AlertIcon } from 'ecto-common/lib/Icon';
import {
  AlarmSeverity as Severity,
  AlarmSeverityText as SeverityText
} from 'ecto-common/lib/constants';
import styles from './AlarmSeverity.module.css';

interface AlarmSeverityProps {
  severity?: Severity;
  icon?: boolean;
  className?: string;
}

const AlarmSeverity = ({
  severity,
  icon = null,
  className = null,
  ...otherProps
}: AlarmSeverityProps) => {
  const alarmClassName = getAlarmStyle(severity);

  if (icon) {
    let text = null;
    let alarmIcon = null;

    switch (severity) {
      case null:
        alarmIcon = (
          <CheckMarkIcon className={alarmClassName} height={16} width={16} />
        );
        text = 'Status OK';
        break;
      case Severity.LOW:
        alarmIcon = <AlertIcon className={alarmClassName} />;
        text = 'Status Caution';
        break;
      case Severity.MEDIUM:
        alarmIcon = <AlertIcon className={alarmClassName} />;
        text = 'Status Warning';
        break;
      case Severity.HIGH:
        alarmIcon = <AlertIcon className={alarmClassName} />;
        text = 'Status Critical';
        break;
      default:
        break;
    }

    return (
      <Tooltip
        text={text}
        className={classNames(styles.tooltip, className)}
        {...otherProps}
      >
        {alarmIcon}
      </Tooltip>
    );
  }

  return (
    <div
      className={classNames(styles.alarmIndicator, alarmClassName, className)}
      {...otherProps}
    >
      {getAlarmSeverityText(severity)}
    </div>
  );
};

export default React.memo(AlarmSeverity);

export function getAlarmStyle(severity: Severity) {
  switch (severity) {
    case null:
    case undefined:
      return styles.larmNONE;
    case Severity.HIGH:
      return styles.larmA;
    case Severity.MEDIUM:
      return styles.larmB;
    case Severity.LOW:
      return styles.larmC;
    default:
      throw new Error(`Invalid severity ${severity} specified`);
  }
}

export function getAlarmSeverityText(severity: Severity) {
  if (severity in SeverityText) {
    return SeverityText[severity];
  }
  throw new Error(`Invalid severity ${severity} specified`);
}
