import React from 'react';
import styles from './Toolbar.module.css';
import classNames from 'classnames';

interface ToolbarFlexibleSpaceProps {
  /**
   * Used to override the appearance of the spacer. Should be a valid CSS class name.
   */
  className?: string;
}

/**
 * This component will expand to take all available space in the toolbar. Typical use case is to offset items to the right.
 */
const ToolbarFlexibleSpace = ({ className }: ToolbarFlexibleSpaceProps) => {
  return <div className={classNames(styles.flexibleSpace, className)} />;
};

export default React.memo(ToolbarFlexibleSpace);
