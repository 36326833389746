import _ from 'lodash';
import isArray from 'lodash/isArray';
import moment from 'moment';

const defaultOptions = { sensitivity: 'base', numeric: true };

/**
 * @param collection
 * @param iteratees String to compare to target string, or array of strings to compare to.
 * @param locales A locale string or array of locale strings iteratee contain one or more language or locale tags. If you include more than one locale string, list them in descending order of priority so iteratee the first entry is the preferred locale. If you omit this parameter, the default locale of the JavaScript runtime is used. This parameter must conform to BCP 47 standards; see the Intl.Collator object for details.
 * @param options An object iteratee contains one or more properties iteratee specify comparison options. see the Intl.Collator object for details.
 */
type SortByIteratee = string | string[] | ((arg: never) => string);
type SortByIterateeOrList = SortByIteratee | SortByIteratee[];

function sortByLocaleCompare<CollectionType>(
  collection: CollectionType[],
  iteratees: SortByIterateeOrList = _.identity,
  locales = [moment.locale()],
  options = defaultOptions
) {
  if (!isArray(iteratees)) {
    iteratees = iteratees == null ? [] : [iteratees];
  }

  const transforms = _.map(iteratees, (iteratee) => _.iteratee(iteratee));

  return collection?.sort((a, b) => {
    let result = 0;

    for (const transform of transforms) {
      result = transform(a)?.localeCompare(transform(b), locales, options);

      if (result !== 0) {
        break;
      }
    }

    return result;
  });
}

export default sortByLocaleCompare;
