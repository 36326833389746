import React, { Component } from 'react';
import { Circle } from '../../components/Shapes';
import Flex from '../../components/Flex/Flex';
import StructurePieChart from './StructurePieChart';
import AppContext, { NOT_CONNECTED } from '../../components/App/app_context';
import ScaleHelper from '../../config/scale';
import './Buildings.css';
import buildingIcons from '../../config/buildingIcons';
import { formatNumberUnit } from 'ecto-common/lib/utils/stringUtils';

type BuildingsProps = {
  data: any;
  size: { width: number; height: number };
};

class Buildings extends Component<BuildingsProps> {
  declare context: React.ContextType<typeof AppContext>;

  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(nextProps, _) {
    return !!nextProps.data && this.context.connectionState != NOT_CONNECTED;
  }

  render() {
    const { data, size } = this.props;
    const { colors } = this.context;
    const structuresToShow = [];

    if (!data || !data.structures || data.structures.length === 0) {
      return <h2>No buildings in grid</h2>;
    }

    const drawStructure = (structure) => {
      const icon =
        buildingIcons.buildingIcons[
          structure.icon && structure.icon.toLowerCase()
        ];

      if (!icon) {
        console.error('Weird or missing icon in structure', structure);
      }

      if (
        structure.buildingId === 'accumulatortank' ||
        structure.buildingId === 'pvcell'
      ) {
        return;
      }

      structuresToShow.push(
        <Flex
          key={structure.id}
          alignItems="center"
          justify="space-between"
          className="Buildings__row"
        >
          <div className="Building__type__container">
            <img
              alt={structure.icon}
              className="Building__type"
              src={buildingIcons.buildingIcons[structure.icon]}
            />
          </div>
          <h3 className="Building__name">{structure.name}</h3>
          <div className="Building__chart">
            <StructurePieChart structure={structure} size={size} />
          </div>
          <Flex direction="column" justify="center" className="Building__needs">
            {structure.electricityEffectMW !== 0.0 && (
              <Flex
                className="Building__need"
                justify="flex-end"
                alignItems="center"
              >
                <Circle
                  r={ScaleHelper.scaleFromPx(4, size)}
                  fill={{ color: colors.electricity }}
                />
                {formatNumberUnit(
                  Math.round(structure.electricityEffectMW * 1000),
                  'kW',
                  0
                )}
              </Flex>
            )}
            {structure.heatingNeedMW !== 0.0 && (
              <Flex
                className="Building__need"
                justify="flex-end"
                alignItems="center"
              >
                <Circle
                  r={ScaleHelper.scaleFromPx(4, size)}
                  fill={{ color: colors.warm }}
                />
                {formatNumberUnit(
                  Math.round(structure.heatingNeedMW * 1000),
                  'kW',
                  0
                )}
              </Flex>
            )}
            {structure.coolingNeedMW !== 0.0 && (
              <Flex
                className="Building__need"
                justify="flex-end"
                alignItems="center"
              >
                <Circle
                  r={ScaleHelper.scaleFromPx(4, size)}
                  fill={{ color: colors.cold }}
                />
                {formatNumberUnit(
                  Math.round(structure.coolingNeedMW * 1000),
                  'kW',
                  0
                )}
              </Flex>
            )}
          </Flex>
        </Flex>
      );
    };

    data.structures.forEach(drawStructure);

    return <div>{structuresToShow}</div>;
  }
}

Buildings.contextType = AppContext;

export default Buildings;
