import React, { useEffect } from 'react';
import {
  LogLevel,
  HubConnectionBuilder,
  HubConnection,
  IRetryPolicy
} from '@microsoft/signalr';
import { createContext, useContext, useMemo } from 'react';
import { noop } from 'lodash';
import _ from 'lodash';
import UUID from 'uuidjs';
import { getApiEnvironment } from '../utils/apiEnvironment';
import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';
import {
  AuthenticationContext,
  handleMSALNeedsInteraction,
  isAuthRedirecting
} from 'ecto-common/lib/hooks/useAuthentication';
import { ApiContextSettings } from '../API/APIUtils';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';

type EventHubEvent = {
  subscriptionId: string;
  data: unknown;
};

export const eventHubRetryPolicy: IRetryPolicy = {
  nextRetryDelayInMilliseconds: (retryContext) => {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    if ((retryContext.retryReason as any)?.statusCode === 401) {
      return null;
    }

    // Keep retrying forever, but with increasing wait times up to limit
    const defaultRetryTimes = [0, 2000, 10000, 15000];
    const retryIndex = Math.min(
      defaultRetryTimes.length - 1,
      retryContext.previousRetryCount
    );
    return defaultRetryTimes[retryIndex];
  }
};

const EventHubUnsubscribeMethod = 'Unsubscribe';

export type EventHubTopic = {
  eventName: string;
  method: string;
};

export const EventHubTopics: Record<string, EventHubTopic> = {
  ALARMS_CHANGED: {
    eventName: 'AlarmsChanged',
    method: 'SubscribeToAlarmEvents'
  },
  ALARM_COUNTS_CHANGED: {
    eventName: 'AlarmCountsChanged',
    method: 'SubscribeToAlarmCountEvents'
  },
  SIGNALS_CHANGED: {
    eventName: 'SignalsChanged',
    method: 'SubscribeToSignalEvents'
  }
};

export const enum EventHubSubscriptionState {
  DISCONNECTED = 0,
  CONNECTING = 1,
  CONNECTED = 2
}

export const EVENT_HUB_SERVICE_DEFAULT_TIMEOUT_VALUE = 5000;

type Subscription = {
  token: object;
  topic: EventHubTopic;
  onChanged: (data: unknown) => void;
  onError: (error: unknown) => void;
  params: unknown[];
  subscriptionId: string;
  state: EventHubSubscriptionState;
};

class EventHubService {
  _connection: HubConnection = null;
  _connected = false;
  _subscriptions: Subscription[] = [];
  _timerId: number = null;
  _stopping = false;
  _lastToken: string = null;
  _retryTimeout = 0;
  msalConfiguration: IPublicClientApplication = null;
  scopes: string[] = [];
  currentAccount: AccountInfo = null;
  contextSettings: ApiContextSettings;

  constructor(
    contextSettings: ApiContextSettings,
    retryTimeout = EVENT_HUB_SERVICE_DEFAULT_TIMEOUT_VALUE,
    scopes: string[] = [],
    msalConfiguration: IPublicClientApplication = null,
    currentAccount: AccountInfo = null
  ) {
    this.contextSettings = contextSettings;
    this._retryTimeout = retryTimeout;
    this.msalConfiguration = msalConfiguration;
    this.scopes = scopes;
    this.currentAccount = currentAccount;
  }

  accessTokenFactory = (): Promise<string> => {
    const accessTokenRequest = {
      scopes: this.scopes,
      account: this.currentAccount
    };
    if (this._stopping || isAuthRedirecting()) {
      // In this case we are disconnecting due to user logging out, if we trigger
      // acquireTokenSilent then the logout will be cancelled. Instead, try to use
      // the previously acquired token for the cleanup phase (the SDK will do another
      // call to the event hub to delete the connection properly)
      return Promise.resolve(this._lastToken);
    }

    // TODO: If we should just return empty string from catch/acquire
    return this.msalConfiguration
      .acquireTokenSilent(accessTokenRequest)
      .then((response) => {
        this._lastToken = response?.accessToken;
        return response?.accessToken ?? '';
      })
      .catch((error) => {
        handleMSALNeedsInteraction(
          error,
          this.msalConfiguration,
          accessTokenRequest
        );
        console.error(error);
        return '';
      });
  };

  subscribe(
    topic: EventHubTopic,
    onChanged: (data: unknown) => void,
    onError: (err: unknown) => void,
    params: unknown[]
  ) {
    const subscriptionInfo: Subscription = {
      token: {},
      topic,
      onChanged,
      onError,
      params,
      subscriptionId: UUID.generate(),
      state: EventHubSubscriptionState.DISCONNECTED
    };

    this._subscriptions.push(subscriptionInfo);

    if (this._connected) {
      this._performSubscribe(subscriptionInfo);
    }

    return subscriptionInfo.token;
  }

  unsubscribe(token: object) {
    const subscriptionInfo = _.find(
      this._subscriptions,
      (subscription) => subscription.token === token
    );

    if (this._connected && subscriptionInfo == null) {
      console.error('Attempted to unsubscribe with invalid token');
    }

    if (subscriptionInfo != null) {
      this._performUnsubscribe(subscriptionInfo);
    }
  }

  connect() {
    this._stopRetryTimer();
    const domain = getApiEnvironment().urls.signalRUrl;
    const tenantId = this.contextSettings.tenantId;

    this._connection = new HubConnectionBuilder()
      .withUrl(domain + '/hubs/eventsHub?tenant_id=' + tenantId, {
        accessTokenFactory: this.accessTokenFactory
      })
      .configureLogging(LogLevel.Critical)
      .withAutomaticReconnect(eventHubRetryPolicy)
      .build();

    _.forEach(_.values(EventHubTopics), (topic) => {
      this._connection.on(topic.eventName, (event: EventHubEvent) => {
        if (_.isObject(event)) {
          // Data is nested object with subscription ID and the actual data.
          this._handleEvent(event.subscriptionId, event.data);
        } else {
          // Some events have no data. In this case the data param is actually the subscription ID.
          this._handleEvent(event, null);
        }
      });
    });

    this._connection.onclose(() => {
      this._connected = false;
      this.disconnectSubscriptions();
    });

    this._connection.onreconnecting(() => {
      this._connected = false;
      this.disconnectSubscriptions();
    });

    this._connection.onreconnected(() => {
      this._onReconnected();
    });

    this._startConnection();
  }

  disconnectSubscriptions() {
    _.forEach(this._subscriptions, (subscription) => {
      subscription.state = EventHubSubscriptionState.DISCONNECTED;
      subscription.subscriptionId = UUID.generate();
    });
  }

  disconnect() {
    this._stopRetryTimer();
    this._connected = false;
    this.disconnectSubscriptions();

    this._subscriptions = [];
    this._stopping = true;
    this._connection
      .stop()
      .then(noop)
      .catch((err: unknown) => {
        console.error(err);
      })
      .finally(() => {
        this._stopping = false;
      });

    this._connection = null;
  }

  _startConnection = () => {
    this._connection
      .start()
      .then(() => {
        if (this._connection != null) {
          this._onConnectionEstablished();
        }
      })
      .catch((err) => {
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        if ((err as any)?.statusCode !== 401 && this._connection != null) {
          this._timerId = window.setTimeout(() => {
            this._timerId = null;
            this._startConnection();
          }, this._retryTimeout);
        }
      });
  };

  _onConnectionEstablished = () => {
    this._connected = true;
    _.map(this._subscriptions, this._performSubscribe);
  };

  _onReconnected = () => {
    this._onConnectionEstablished();
  };

  _handleEvent = (subscriptionId: string, data: unknown) => {
    const owner = _.find(this._subscriptions, [
      'subscriptionId',
      subscriptionId
    ]);
    owner?.onChanged(data);
  };

  _performSubscribe = (subscriptionInfo: Subscription) => {
    subscriptionInfo.state = EventHubSubscriptionState.CONNECTING;
    this._connection
      .invoke(
        subscriptionInfo.topic.method,
        subscriptionInfo.subscriptionId,
        ...subscriptionInfo.params
      )
      .then(() => {
        if (this._connected) {
          subscriptionInfo.state = EventHubSubscriptionState.CONNECTED;

          if (!this._subscriptions.includes(subscriptionInfo)) {
            // Subscription got unsubscribed while we were connecting.
            this._performUnsubscribe(subscriptionInfo);
          }
        }
      })
      .catch((e) => {
        if (this._subscriptions.includes(subscriptionInfo)) {
          subscriptionInfo.onError?.(e);
        }

        this._subscriptions = _.without(this._subscriptions, subscriptionInfo);

        if (!e.name.indexOf('connection being closed')) {
          console.error(e);
        }
      });
  };

  _performUnsubscribe = (subscriptionInfo: Subscription) => {
    this._subscriptions = _.without(this._subscriptions, subscriptionInfo);
    subscriptionInfo.state = EventHubSubscriptionState.DISCONNECTED;

    // Do fire and forget unsubscribe - best effort, don't care about results
    if (this._connected && subscriptionInfo.subscriptionId) {
      this._connection
        .invoke(EventHubUnsubscribeMethod, subscriptionInfo.subscriptionId)
        .catch((e: unknown) => {
          console.error(e);
        });
    }
  };

  _stopRetryTimer() {
    clearTimeout(this._timerId);
    this._timerId = null;
  }
}

export const EventHubServiceContext = createContext<EventHubService>(null);
export default EventHubService;

export const EventHubServiceContainer = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const apiEnvironment = getApiEnvironment();
  const { contextSettings } = useContext(TenantContext);
  const { msalConfiguration, currentAccount } = useContext(
    AuthenticationContext
  );

  const connection = useMemo(
    () =>
      new EventHubService(
        contextSettings,
        EVENT_HUB_SERVICE_DEFAULT_TIMEOUT_VALUE,
        [apiEnvironment.scopes.gateway],
        msalConfiguration,
        currentAccount
      ),
    [currentAccount, msalConfiguration, apiEnvironment.scopes, contextSettings]
  );

  useEffect(() => {
    if (connection) {
      connection.connect();

      return () => {
        connection.disconnect();
      };
    }
  }, [connection]);

  return (
    <EventHubServiceContext.Provider value={connection}>
      {children}
    </EventHubServiceContext.Provider>
  );
};
