import React, { ComponentType, ComponentProps } from 'react';

type PropsComparator<C extends ComponentType> = (
  prevProps: Readonly<ComponentProps<C>>,
  nextProps: Readonly<ComponentProps<C>>
) => boolean;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function typedMemo<C extends ComponentType<any>>(
  Component: C,
  propsComparator?: PropsComparator<C>
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return React.memo(Component, propsComparator) as any as C;
}

export type ObjectValues<T> = T[keyof T];
export const enumKeys = <EnumType>(enumType: EnumType): Array<keyof EnumType> =>
  Object.keys(enumType).filter((enumKey) => isNaN(Number(enumKey))) as Array<
    keyof EnumType
  >;
export const enumValues = <EnumType>(
  enumType: EnumType
): Array<EnumType[keyof EnumType]> =>
  enumKeys(enumType).map((enumKey) => enumType[enumKey]);

export function makeKey<Prefix extends string, Suffix extends string>(
  prefix: Prefix,
  suffix: Suffix
) {
  return (prefix + suffix) as `${Prefix}${Suffix}`;
}

export function makeKey3<
  Prefix extends string,
  Middle extends string,
  Suffix extends string
>(prefix: Prefix, middle: Middle, suffix: Suffix) {
  return (prefix + middle + suffix) as `${Prefix}${Middle}${Suffix}`;
}

/**
 * Return type of the values in a record
 */
export type ValueTypeOfRecord<T> =
  T extends Record<infer _, infer V> ? V : never;

/**
 * Return type of the keys in a record
 */
export type KeyTypeOfRecord<T> = T extends Record<infer K, infer _> ? K : never;

/**
 * Return type of the values in a collection
 */
export type ValueOfCollection<T> =
  T extends Array<infer U> ? U : ValueTypeOfRecord<T>;

/**
 * Return type of the index value in either an array or a record
 */
export type IndexOfCollection<T> =
  T extends Array<infer _> ? number : KeyTypeOfRecord<T>;

/**
 * Return function type for a reducer
 * @param Values The type of the collection
 * @param Result The type of the result
 */
export type ReducerFunction<Values, Result> = (
  result: Result,
  value: ValueOfCollection<Values>,
  index: IndexOfCollection<Values>,
  collection: Values
) => Result;
