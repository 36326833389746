import { SET_ENUMS } from './actionTypes';

export function setEnums(enums = {}) {
  return {
    type: SET_ENUMS,
    payload: {
      enums
    }
  };
}
