import { extractVariables, replaceVariables } from '../utils/stringUtils';
import _ from 'lodash';
import T from './Language';

/**
 * Replace variables in "string" with matching localization strings.
 * @param templateString
 * @return new string with ${T.*} replaced with the corresponding translation for the active language
 */
export const replaceLanguageVariables = (templateString: string) => {
  const variables: string[] = extractVariables(templateString);
  const variableValues = _.reduce(
    variables,
    (dict, variable) => {
      if (_.startsWith(variable, 'T.')) {
        dict[variable] = _.get(T, variable.slice(2));
      }
      return dict;
    },
    {} as Record<string, string>
  );
  return replaceVariables(templateString, variableValues);
};
