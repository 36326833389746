import classNames from 'classnames';
import styles from './DataTable.module.css';
import React from 'react';

interface DataTableFooterProps {
  children: React.ReactNode;
  className?: string;
  alignRight?: boolean;
}

export const DataTableFooter = ({
  children,
  className,
  alignRight = false
}: DataTableFooterProps) => {
  return (
    <div
      className={classNames(
        styles.footer,
        className,
        alignRight && styles.alignRight
      )}
    >
      {children}
    </div>
  );
};

export default DataTableFooter;
