import React from 'react';

const PassiveBalancingUnit = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23 3V29H9V3H23ZM23 1H9C8.46957 1 7.96086 1.21071 7.58579 1.58579C7.21071 1.96086 7 2.46957 7 3V29C7 29.5304 7.21071 30.0391 7.58579 30.4142C7.96086 30.7893 8.46957 31 9 31H23C23.5304 31 24.0391 30.7893 24.4142 30.4142C24.7893 30.0391 25 29.5304 25 29V3C25 2.46957 24.7893 1.96086 24.4142 1.58579C24.0391 1.21071 23.5304 1 23 1Z"
      fill="white"
    />
    <path d="M21 5H11V15H21V5Z" fill="white" />
    <path d="M21 17H11V27H21V17Z" fill="white" />
  </svg>
);

export default PassiveBalancingUnit;
