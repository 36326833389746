import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

interface PortalProps {
  children: React.ReactNode;
  isOpen?: boolean;
  closeTimeout?: number;
}

// The only reason for using this instead of ReactDOM.createPortal directly is that it
// allows you to specify a timeout during which the portal content will still be visible
// after isOpen is set to false. Useful for modal transitions.
const Portal = ({ isOpen = true, closeTimeout = 0, children }: PortalProps) => {
  const [isVisible, setIsVisible] = useState(isOpen);
  const [content, setContent] = useState<React.ReactPortal>(null);
  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> = null;
    if (isOpen) {
      setIsVisible(true);
    } else {
      timeout = setTimeout(() => {
        setIsVisible(isOpen);
      }, closeTimeout);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isOpen, closeTimeout]);

  // Unfortunately we have to use an effect in order to synchronize portal creation when
  // there are multiple portals stacked in the same component hierarchy (i.e. a child modal in a parent modal).
  // Otherwise, createPortal might create them in the wrong order.
  // An example: Edit a building in Ectoplanner, and then select show summary. Notice that the URL now
  // contains state indicating that both modals should be open. If you reload the page, the modals will
  // call createPortal in the right order but during the same render phase, which will cause React to
  // create them in the wrong order even though React.createPortal was called in the correct order.
  // It worked when you clicked your way into the state because the modals were created in different render
  // phases. With the state in the URL, they are created in the same render phase.
  //
  // Using this effect seems to ensure that the actual portal content is created in the correct order.
  useEffect(() => {
    setContent(
      isVisible ? ReactDOM.createPortal(children, document.body) : null
    );
  }, [children, isVisible]);

  return content;
};

export default Portal;
