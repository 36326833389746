import React from 'react';
import T from 'ecto-common/lib/lang/Language';
import Icons from 'ecto-common/lib/Icons/Icons';
import StandardButton, {
  StandardButtonProps
} from 'ecto-common/lib/Button/StandardButton';

type ShareButtonProps = StandardButtonProps & {
  tooltipText?: string;
};

const ShareButton = ({ tooltipText = null, ...props }: ShareButtonProps) => (
  <StandardButton
    {...props}
    icon={<Icons.Share />}
    tooltipText={tooltipText || T.common.share}
  />
);

export default ShareButton;
