export default {
  docs: {
    general: {
      ectocloud_general: 'docs/general/ectocloud_general.md',
      timeseries: 'docs/general/timeseries.md'
    },
    dashboard: {
      dashboards: {
        alarm_counter: 'docs/dashboard/dashboards/alarm_counter.md',
        dashboards: 'docs/dashboard/dashboards.md',
        alarm_list: 'docs/dashboard/dashboards/alarm_list.md',
        alarm_status_list: 'docs/dashboard/dashboards/alarm_status_list.md',
        barchart: 'docs/dashboard/dashboards/barchart.md',
        compare_value_chart: 'docs/dashboard/dashboards/compare_value_chart.md',
        gauge: 'docs/dashboard/dashboards/gauge.md',
        line_chart: 'docs/dashboard/dashboards/line_chart.md',
        location_panel: 'docs/dashboard/dashboards/location_panel.md',
        map: 'docs/dashboard/dashboards/map.md',
        process_map: 'docs/dashboard/dashboards/process_map.md',
        scatter_chart: 'docs/dashboard/dashboards/scatter_chart.md',
        signal_curve_editor: 'docs/dashboard/dashboards/signal_curve_editor.md',
        signal_list: 'docs/dashboard/dashboards/signal_list.md',
        text: 'docs/dashboard/dashboards/text.md'
      }
    },
    operator: {
      operator: 'docs/operator/operator.md',
      dashboard: 'docs/operator/dashboard.md',
      personal_dashboards: 'docs/operator/personal_dashboards.md',
      powercontrol: 'docs/operator/powercontrol.md',
      powercontrol_create: 'docs/operator/powercontrol_create.md',
      alarms: 'docs/operator/alarms.md',
      signals: 'docs/operator/signals.md',
      logs: 'docs/operator/logs.md',
      graphs: 'docs/operator/graphs.md',
      export_data: 'docs/operator/export_data.md'
    },
    admin: {
      admin: 'docs/admin/admin.md',
      manage: {
        locations: {
          add_edit_notification_dialog:
            'docs/admin/manage/locations/add_edit_notification_dialog.md',
          locations: 'docs/admin/manage/locations.md',
          building_add_from_template:
            'docs/admin/manage/locations/building_add_from_template.md'
        },
        manage: 'docs/admin/manage.md',
        equipment: {
          signals: {
            modbus: 'docs/admin/manage/equipment/signals/modbus.md',
            signals: 'docs/admin/manage/equipment/signals.md'
          },
          equipment: 'docs/admin/manage/equipment.md',
          tools: {
            battery_charging_threshold:
              'docs/admin/manage/equipment/tools/battery_charging_threshold.md',
            tools: 'docs/admin/manage/equipment/tools.md',
            comfort: 'docs/admin/manage/equipment/tools/comfort.md',
            netmore: 'docs/admin/manage/equipment/tools/netmore.md',
            linear_optimization:
              'docs/admin/manage/equipment/tools/linear_optimization.md',
            power_control: 'docs/admin/manage/equipment/tools/power_control.md'
          },
          energy_manager: 'docs/admin/manage/equipment/energy_manager.md'
        },
        process_maps: {
          create_process_map:
            'docs/admin/manage/process_maps/create_process_map.md',
          process_maps: 'docs/admin/manage/process_maps.md',
          configure_diagrams_net:
            'docs/admin/manage/process_maps/configure_diagrams_net.md',
          create_diagrams_asset:
            'docs/admin/manage/process_maps/create_diagrams_asset.md'
        },
        integration_accounts: {
          integration_accounts: 'docs/admin/manage/integration_accounts.md',
          netmore: 'docs/admin/manage/integration_accounts/netmore.md'
        },
        dashboards: 'docs/admin/manage/dashboards.md',
        signal_mappings: 'docs/admin/manage/signal_mappings.md',
        weather: 'docs/admin/manage/weather.md',
        users: 'docs/admin/manage/users.md',
        notifications: 'docs/admin/manage/notifications.md'
      },
      installation: {
        buildings: 'docs/admin/installation/buildings.md',
        installation: 'docs/admin/installation.md',
        energy_managers: 'docs/admin/installation/energy_managers.md',
        energy_manager_details:
          'docs/admin/installation/energy_manager_details.md'
      },
      templates: {
        alarms_types: 'docs/admin/templates/alarms_types.md',
        templates: 'docs/admin/templates.md',
        building_templates: 'docs/admin/templates/building_templates.md',
        equipment_types: 'docs/admin/templates/equipment_types.md',
        signal_types: 'docs/admin/templates/signal_types.md'
      },
      terminology: 'docs/admin/terminology.md'
    }
  },
  releases: {
    release_notes: 'releases/release_notes.md'
  }
};
