import React, { useSyncExternalStore } from 'react';
import classnames from 'classnames';
import View from '../../components/View/View';
import Flex from '../../components/Flex/Flex';
import './PowerProductionView.css';
import EctotableDashboardPanel from '../DashboardView/EctotableDashboardPanel';
import dashboardData from './PowerProductionDashboardData.json';
import EnergyDemandBarChart from 'js/views/PowerConsumptionView/EnergyDemandBarChart';
import { darkModeStore } from 'ecto-common/lib/DarkMode/DarkMode';

type PowerProductionViewProps = {
  data: any;
  className?: string;
  ectocloudMode: boolean;
  size: { width: number; height: number };
};

/** Client screen showing power production. */
const PowerProductionView = ({
  data,
  className,
  ectocloudMode,
  size
}: PowerProductionViewProps) => {
  const darkModeEnabled = useSyncExternalStore(
    darkModeStore.subscribe,
    darkModeStore.getSnapshot
  );

  if (ectocloudMode) {
    return (
      <div className="PowerProductionView__dashboard_content__main">
        <div className="View__title">
          <h1>Live data</h1>
        </div>
        <div className="PowerProductionView__dashboard_panel_container">
          <EctotableDashboardPanel
            panelData={dashboardData}
            contentClassName="PowerProductionView__dashboard_panel"
            showOnlyContent
          />
        </div>
      </div>
    );
  }

  return (
    <View className={classnames(className)} title="Energy Source Balance">
      <Flex direction="column" className="PowerProductionView__content__main">
        {size.width != null && size.height != null && (
          <EnergyDemandBarChart
            darkModeEnabled={darkModeEnabled}
            data={data}
            size={size}
          />
        )}
      </Flex>
    </View>
  );
};

export default React.memo(PowerProductionView);
