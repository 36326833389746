const SUCCESS = 0;
const FAILURE = 1;
const INT_FAILURE = -1;

export function arrayRemove(array, object) {
  if (array === undefined) {
    console.error('Cannot remove from nothing');
    return FAILURE;
  }
  if (object === undefined) {
    console.error('Cannot remove nothing form array');
    return FAILURE;
  }

  const index = array.indexOf(object);

  if (index === -1) {
    console.warn('Not supposed to happen');
    return FAILURE;
  }
  array.splice(index, 1);
  return SUCCESS;
}

export function arrayCopy(array) {
  return array.slice(0, array.length);
}

export function arrayLast(array) {
  return array.slice(-1)[0];
}

export function arraySummariseOverItems(array, functionName) {
  let sum = 0;

  for (let index in array) {
    const item = array[index];
    const call = item[functionName].bind(item);

    if (typeof call === 'function') {
      sum += call();
    }
  }

  return sum;
}

export default {
  SUCCESS,
  FAILURE,
  NOOP: 2,
  INT_FAILURE,
  HEX_ONE: 256,
  isVector: (obj) => {
    if (obj !== undefined && ((obj.x !== undefined) !== obj.y) !== undefined) {
      return true;
    }
    return false;
  },
  interpolateStates(p5, s1, s2, base, ratio) {
    const combinedState = {};

    Object.keys(base).forEach((key) => {
      if (s1[key] === undefined && s2[key] === undefined) {
        combinedState[key] = base[key];
      } else if (s1[key] === undefined) {
        combinedState[key] = s2[key];
      } else if (s2[key] === undefined) {
        combinedState[key] = s1[key];
      } else {
        if (key.indexOf('Color') !== -1) {
          const c1 = p5.color(s1[key]);
          const c2 = p5.color(s2[key]);

          combinedState[key] = p5.lerpColor(c1, c2, ratio).toString('#rrggbb');
        } else {
          combinedState[key] = s1[key] * (1 - ratio) + s2[key] * ratio;
        }
      }
    });
    return combinedState;
  },
  objectCopy(object) {
    const newObject = {};

    Object.keys(object).forEach((key) => {
      const val = object[key];

      if (val instanceof Object) {
        newObject[key] = this.objectCopy(val);
      } else if (Array.isArray(val)) {
        newObject[key] = this.arrayCopy(val);
      } else {
        newObject[key] = val;
      }
    });

    return newObject;
  },
  arrayRemove,
  arrayCopy,
  arrayLast,
  arraySummariseOverItems,
  degrees(radians) {
    return (360 * radians) / (2 * Math.PI);
  },
  sq(n) {
    return n * n;
  }
};
