import React, { Component } from 'react';
import p5 from 'p5';
import Integration from '../../Table/Integration';
import TableApp from '../../Table/TableApp';
import Context from '../../Table/context';
import './TableView.css';
import { ROLES } from '../../components/App/app_util';
import AppContext, {
  CONNECTED_BUT_NO_TABLE
} from '../../components/App/app_context';
import SocketMessages from '../../../../server/socket-messages';
import SetupView from '../SetupView/SetupView';
import { TUIOIntegration } from './TUIOIntegration';
import RoomConfig from '../../config/roomConfig.json';
import Gesto from 'gesto';
import { RoomConfigType } from 'js/Table/types';
import TableP5 from 'js/Table/TableP5';

type TableViewProps = {
  socket: any;
  company: string;
  room: string;
  size: { width: number; height: number };
  onToggleMultiScreen: () => void;
  multiScreenActive: () => boolean;
};

type TableViewState = {
  showSetup: boolean;
  pauseVideoIntervalCheck: boolean;
};

/** The main Table view (ReactJS) wrapping the Table surface (P5.js) */
class TableView extends Component<TableViewProps, TableViewState> {
  declare context: React.ContextType<typeof AppContext>;

  roomConfig: RoomConfigType;
  pauseTimeout: any;
  videoRef: React.RefObject<HTMLVideoElement>;
  containerRef: React.RefObject<HTMLDivElement>;
  timeoutTickInterval: number;
  p5Instance: TableP5;
  gesto: Gesto;
  tuioIntegration: TUIOIntegration;
  app: TableApp;

  constructor(props) {
    super(props);
    this.roomConfig = RoomConfig[props.room];

    this.containerRef = React.createRef();
    this.pauseTimeout = null;
    this.videoRef = React.createRef();
    this.connectSocket = this.connectSocket.bind(this);
    this.onToggleSetup = this.onToggleSetup.bind(this);
    this.toggleSetup = this.toggleSetup.bind(this);
    this.onSocketData = this.onSocketData.bind(this);
    this.timeoutTickInterval = null;
    this.p5Instance = null;

    this.state = {
      showSetup: false,
      pauseVideoIntervalCheck: false
    };
  }

  componentDidMount() {
    this.props.socket && this.connectSocket();
    let scale = 1.0;
    this.gesto = new Gesto(this.containerRef.current, {
      container: window,
      pinchOutside: true
    })
      .on('pinchStart', (e) => {
        if (!this.p5Instance.App.shouldDisableDynamicTouchUI()) {
          this.p5Instance.App.pinchZooming = true;
        }
        e.datas.scale = this.p5Instance.App.zoomScale();
      })
      .on('pinch', (e) => {
        const containerBounds =
          this.containerRef.current.getBoundingClientRect();
        const pinchX =
          (e.clientX - containerBounds.x) / this.p5Instance.scaleRatio;
        const pinchY =
          (e.clientY - containerBounds.y) / this.p5Instance.scaleRatio;

        if (!this.p5Instance.App.shouldDisableDynamicTouchUI()) {
          this.p5Instance.App.zoom(scale, pinchX, pinchY);
        }

        scale = e.datas.scale * e.scale;
      })
      .on('pinchEnd', () => {
        if (!this.p5Instance.App.shouldDisableDynamicTouchUI()) {
          this.p5Instance.App.pinchZooming = false;
        }
      });
  }

  componentWillUnmount() {
    this.props.socket &&
      this.props.socket.off(SocketMessages.DATA, this.onSocketData);
    this.timeoutTickInterval && clearInterval(this.timeoutTickInterval);
    this.pauseTimeout = clearTimeout(this.pauseTimeout);
    this.tuioIntegration?.disconnectSocket();
    this.tuioIntegration = null;
    this.gesto.unset();

    if (this.p5Instance != null) {
      this.p5Instance.remove();
      this.p5Instance.App = null;
      delete this.p5Instance;
      this.p5Instance = null;
    }
  }

  componentDidUpdate(prevProps) {
    // If socket was created after we mounted, connect the table socket now
    // instead.
    if (this.props.socket && !prevProps.socket) this.connectSocket();
  }

  connectSocket() {
    Integration.context = Context;
    Integration.context.Vector = p5.Vector;
    Integration.context.company = this.props.company;

    const wrappedIntegration = (_p5Instance) => {
      return Integration(
        _p5Instance,
        () => {
          this.app = new TableApp(
            Context,
            _p5Instance,
            this.props.socket,
            this.props.room,
            this.roomConfig,
            this.toggleSetup,
            this.props.onToggleMultiScreen,
            this.props.multiScreenActive
          );
          this.props.socket.on(SocketMessages.DATA, this.onSocketData);
          this.app.socketConnected();
        },
        this.props.size
      );
    };

    // The p5 typescript exports are a bit weird, they contain both a class named p5 and an interface
    // We want to add additional properties to the p5 class, so we need to cast it to our specific
    // interface type to avoid typescript errors for now.
    // TODO: Look into just extending the p5 interface instead of using TableP5
    this.p5Instance = new p5(wrappedIntegration) as any as TableP5;

    if (this.roomConfig != null) {
      this.tuioIntegration = new TUIOIntegration(
        {
          onObjectStateChanged: (state) => {
            this.p5Instance?.App?.updateTUIOPoints(
              state.activeObjects,
              this.roomConfig.markerDiameter
            );
          },
          onCursorDown: (x, y) => {
            const containerRect =
              this.p5Instance.drawingContext.canvas.getBoundingClientRect();

            this.p5Instance?.App?.mousePressed(
              (x - containerRect.x) / this.p5Instance.scaleRatio,
              (y - containerRect.y) / this.p5Instance.scaleRatio,
              'tuio'
            );
          },
          onCursorUp: (x, y) => {
            const containerRect =
              this.p5Instance.drawingContext.canvas.getBoundingClientRect();

            this.p5Instance?.App?.mouseReleased(
              (x - containerRect.x) / this.p5Instance.scaleRatio,
              (y - containerRect.y) / this.p5Instance.scaleRatio,
              'tuio'
            );
          },
          onCursorMove: (x, y) => {
            const containerRect =
              this.p5Instance.drawingContext.canvas.getBoundingClientRect();

            this.p5Instance?.App?.mouseDragged(
              (x - containerRect.x) / this.p5Instance.scaleRatio,
              (y - containerRect.y) / this.p5Instance.scaleRatio,
              'tuio'
            );
          }
        },
        this.roomConfig.tuioType,
        this.roomConfig.tuioHost
      );
    }
  }

  onSocketData(_json) {
    // If we are the table, but server says there is no table, let it know
    // there is a table now.
    if (this.context.connectionState === CONNECTED_BUT_NO_TABLE) {
      console.warn(
        '[TableView@onSocketData] Server said there was no table, telling it there is'
      );
      this.props.socket.emit(SocketMessages.ROLE_CHANGE, { role: ROLES.table });
    }
  }

  toggleSetup() {
    this.setState(
      {
        showSetup: !this.state.showSetup
      },
      () => {
        this.onToggleSetup(this.state.showSetup);
      }
    );
  }

  onToggleSetup(value) {
    this.app.showingSetup = value;
    if (this.state.showSetup !== value) {
      this.setState({ showSetup: !this.state.showSetup });
    }
  }

  render() {
    return (
      <div id="TableView" ref={this.containerRef}>
        <div id="sketch-holder" />

        {this.state.showSetup && (
          <SetupView
            socket={this.props.socket}
            room={this.props.room}
            onSetupToggle={this.onToggleSetup}
          />
        )}
      </div>
    );
  }
}

TableView.contextType = AppContext;

export default TableView;
