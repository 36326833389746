export const SE = 'se';
export const SW = 'sw';
export const S = 's';
export const NE = 'ne';
export const NW = 'nw';
export const N = 'n';
export const E = 'e';
export const W = 'w';

export const all = [SE, SW, S, NE, NW, N, E, W];

export type DirectionType = (typeof all)[number];
