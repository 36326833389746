import React from 'react';
import Tooltip from 'ecto-common/lib/Tooltip/Tooltip';
import classNames from 'classnames';
import styles from './ToolbarMenu.module.css';
import ToolbarMenuItem from 'ecto-common/lib/Toolbar/ToolbarMenuItem';
import Spinner, { SpinnerSize } from 'ecto-common/lib/Spinner/Spinner';

interface ToolbarMenuButtonProps {
  /**
   * The text describing the button. Will be shown when hovering the button.
   */
  tooltipText: string;
  /**
   * The icon that identifies the action of the button.
   */
  icon: React.ReactElement;
  /**
   * Function that will be called when the button is clicked.
   */
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  /**
   * If disabled the icon will be dimmed out and will not be clickable.
   */
  disabled?: boolean;

  /**
   * If true, the button will show a loading indicator.
   */
  loading?: boolean;

  /**
   * The title of the button, optional. If not provided, the button will only show the icon.
   */
  title?: string;

  /**
   * If true, the button will be highlighted, indicating an state.
   */
  highlighted?: boolean;
}

/**
 * A small icon based button which is used with ToolbarMenu.
 */
const ToolbarMenuButton = ({
  icon,
  disabled = false,
  onClick = null,
  loading = false,
  title = null,
  highlighted = false,
  tooltipText
}: ToolbarMenuButtonProps) => {
  return (
    <ToolbarMenuItem>
      <Tooltip text={tooltipText}>
        <button
          type="button"
          className={classNames(
            styles.toolbarMenuButton,
            highlighted && styles.highlighted,
            title && styles.withTitle
          )}
          disabled={disabled}
          onClick={onClick}
        >
          {icon}
          {title}
        </button>
        {loading && (
          <Spinner size={SpinnerSize.INLINE} className={styles.loading} />
        )}
      </Tooltip>
    </ToolbarMenuItem>
  );
};

export default React.memo(ToolbarMenuButton);
