import { createContext } from 'react';
import { Route } from 'ecto-common/lib/types/Route';
import { ResourceType } from 'ecto-common/lib/constants';
export type GetAlarmUrlFunction = (
  tenantId: string,
  nodeId: string,
  subPage?: string
) => string;
export type GetSignalsUrlFunction = (
  tenantId: string,
  nodeId: string,
  providerId?: string
) => string;

export type UrlContextInformation = {
  locationRoute: Route;
  getAlarmUrl: GetAlarmUrlFunction;
  getAlarmUrlV2: GetAlarmUrlFunction;
  getSignalsUrl: GetSignalsUrlFunction;
};

const UrlContext = createContext<UrlContextInformation>({
  locationRoute: {
    path: '/:tenantId/home/:nodeId/:page/:itemId?/:subPage?',
    exact: false,
    resourceTypes: [ResourceType.CORE]
  },
  getAlarmUrl: () => '',
  getAlarmUrlV2: () => '',
  getSignalsUrl: () => ''
});

export default UrlContext;
