import React, { Component } from 'react';
import _ from 'lodash';

import Modal from 'ecto-common/lib/Modal/Modal';
import ModalFooter from 'ecto-common/lib/Modal/ModalFooter';
import ModalHeader from 'ecto-common/lib/Modal/ModalHeader';
import ModalBody from 'ecto-common/lib/Modal/ModalBody';
import styles from './SelectedNodeDialog.module.css';
import { EditIcon } from 'ecto-common/lib/Icon';
import T from 'ecto-common/lib/lang/Language';
import LocalizedButtons from 'ecto-common/lib/Button/LocalizedButtons';
import SelectNode, { CustomNodeTreeSet } from './SelectNode';

interface SelectNodeDialogProps {
  isOpen: boolean;
  onModalClose: () => void;
  onNodesSelected: (nodeIds: string[]) => void;
  nodeIds: string[];
  multiSelect: boolean;
  customNodeTreeSet?: CustomNodeTreeSet;
  selectEquipment?: boolean;
}

class SelectNodeDialog extends Component<SelectNodeDialogProps> {
  static defaultProps = {
    multiSelect: true
  };

  constructor(props: SelectNodeDialogProps) {
    super(props);
    this.nodeIds = props.nodeIds;
  }

  componentDidUpdate(prevProps: SelectNodeDialogProps) {
    if (!_.isEqual(prevProps.nodeIds, this.props.nodeIds)) {
      this.nodeIds = this.props.nodeIds;
    }
  }

  nodeIds: string[] = [];

  render() {
    const {
      isOpen,
      onModalClose,
      onNodesSelected,
      nodeIds,
      multiSelect,
      customNodeTreeSet,
      selectEquipment
    } = this.props;

    const selectDone = () => {
      onNodesSelected(this.nodeIds);
      onModalClose();
    };

    return (
      <Modal
        className={styles.editParentsModal}
        onModalClose={onModalClose}
        isOpen={isOpen}
      >
        <ModalHeader titleIcon={EditIcon}>
          {T.admin.selectlocationdialog.title}
        </ModalHeader>
        <ModalBody
          className={styles.editParentBody}
          withoutPadding
          withWhiteBackground
        >
          <SelectNode
            customNodeTreeSet={customNodeTreeSet}
            nodeIds={nodeIds}
            multiSelect={multiSelect}
            selectEquipment={selectEquipment}
            onNodesSelected={(ids) => {
              this.nodeIds = ids;
            }}
          />
        </ModalBody>
        <ModalFooter>
          <LocalizedButtons.Ok onClick={selectDone} />
          <LocalizedButtons.Cancel onClick={onModalClose} />
        </ModalFooter>
      </Modal>
    );
  }
}

export default SelectNodeDialog;
