import { useContext, useMemo } from 'react';
import _ from 'lodash';

import { useAvailableSignalsForNodes } from 'ecto-common/lib/hooks/useAvailableSignals';
import T from 'ecto-common/lib/lang/Language';
import NodeDataSource, { nodeDataSourceSections } from './NodeDataSource';
import { text } from 'ecto-common/lib/ModelForm/formUtils';
import DashboardDataContext from 'ecto-common/lib/hooks/DashboardDataContext';
import { SingleGridNode } from 'ecto-common/lib/types/EctoCommonTypes';
import { FullSignalProviderResponseModel } from 'ecto-common/lib/API/APIGen';
import { ModelFormSectionType } from 'ecto-common/lib/ModelForm/ModelPropType';
import { PromiseCacheContext } from 'ecto-common/lib/Dashboard/datasources/signalUtils';

interface SignalProvidersDataSourceProps {
  nodeId?: string;
  equipmentName?: string;
  cacheContext: PromiseCacheContext;
}

export type SignalProvidersDataSourceResult = {
  node: SingleGridNode;
  useNode: boolean;
  equipmentId?: string;
  signalProviders: FullSignalProviderResponseModel[];
  isLoading: boolean;
  hasError: boolean;
};

const SignalProvidersDataSource = ({
  nodeId,
  equipmentName,
  cacheContext
}: SignalProvidersDataSourceProps) => {
  const node = NodeDataSource({ nodeId });
  const useNode = _.isEmpty(_.trim(equipmentName));
  const nodeList = useMemo(() => _.compact([node]), [node]);
  const equipment = _.find(node?.equipments, ['name', equipmentName]);
  const equipmentList = useMemo(() => _.compact([equipment]), [equipment]);
  const { equipmentMap } = useContext(DashboardDataContext);

  const [isLoading, hasError, signalsForNode] = useAvailableSignalsForNodes(
    useNode ? nodeList : equipmentList,
    cacheContext,
    equipmentMap
  );
  const signalProviders = useMemo(
    () => _.map(signalsForNode?.signalProviders),
    [signalsForNode]
  );
  return {
    node,
    useNode,
    equipmentId: equipment?.equipmentId,
    signalProviders,
    isLoading,
    hasError
  };
};

export const signalProvidersDataSourceSections: () => ModelFormSectionType<SignalProvidersDataSourceProps>[] =
  () => [
    ...nodeDataSourceSections(),
    {
      lines: [
        {
          models: [text((input) => input.equipmentName, T.admin.equipment.name)]
        }
      ]
    }
  ];

export default SignalProvidersDataSource;
