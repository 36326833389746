import classNames from 'classnames';
import styles from 'ecto-common/lib/KeyValueInput/KeyValueInput.module.css';
import Tooltip from 'ecto-common/lib/Tooltip/Tooltip';
import { NW } from 'ecto-common/lib/Tooltip/directions';
import React from 'react';

interface KeyValueLabelProps {
  children?: React.ReactNode;
  className?: string;
  style?: object;
  tooltipText?: React.ReactNode;
}

export const KeyValueLabel = ({
  style,
  children,
  className,
  tooltipText = null
}: KeyValueLabelProps) => {
  let content: React.ReactNode = children;

  if (tooltipText) {
    content = (
      <Tooltip text={tooltipText} direction={NW} multiline withIcon>
        {content}
      </Tooltip>
    );
  }

  return (
    <label
      className={classNames(styles.keyValueLabel, className)}
      style={style}
    >
      {content}
    </label>
  );
};
