import React from 'react';
import classNames from 'classnames';

import { AlarmSeverityText } from 'ecto-common/lib/constants';
import T from 'ecto-common/lib/lang/Language';
import Tooltip from 'ecto-common/lib/Tooltip/Tooltip';

import styles from './AlarmSeverityCount.module.css';

interface AlarmSeverityCountProps {
  severity: number;
  count: number;
}

const AlarmSeverityCount = ({ severity, count }: AlarmSeverityCountProps) => (
  <Tooltip
    text={T.format(
      T.admin.alarmstatuspanel.tooltip.alarmseverites,
      AlarmSeverityText[severity]
    )}
  >
    <span
      className={classNames(styles.severityBox, styles['severity-' + severity])}
      key={severity}
    >
      {AlarmSeverityText[severity]} - {count}
    </span>
  </Tooltip>
);

export default React.memo(AlarmSeverityCount);
