import React, { useCallback } from 'react';
import { KeyValueInput } from 'ecto-common/lib/KeyValueInput/KeyValueInput';
import { parseStringToNumber } from 'ecto-common/lib/utils/stringUtils';
import { ModelEditorProps } from 'ecto-common/lib/ModelForm/ModelEditor';
import { ModelDefinitionInternal } from 'ecto-common/lib/ModelForm/ModelPropType';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';

export type NumberModelDefinition<
  ObjectType extends object,
  EnvironmentType extends object = object
> = {
  modelType: typeof ModelType.NUMBER;
  withDivider?: boolean;
  unit?: React.ReactNode;
  step?: number;
  min?: number;
  max?: number;
} & ModelDefinitionInternal<ObjectType, EnvironmentType, number>;

type ModelEditorNumberProps = ModelEditorProps & {
  model: NumberModelDefinition<object, object>;
};

const ModelEditorNumber = ({
  model,
  updateItem,
  disabled,
  hasError,
  helpText = null,
  useTooltipHelpTexts = false,
  isHorizontal = false,
  rawValue
}: ModelEditorNumberProps) => {
  const onChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      updateItem(parseStringToNumber(event.target.value));
    },
    [updateItem]
  );

  return (
    <KeyValueInput
      keyText={model.label}
      disabled={disabled}
      value={rawValue ?? ''}
      onChange={onChange}
      placeholder={model.placeholder}
      hasError={hasError}
      type={'number'}
      step={model.step}
      unit={model.unit}
      help={helpText}
      useTooltipHelpTexts={useTooltipHelpTexts}
      isHorizontal={isHorizontal}
      horizontalWeights={model.horizontalWeights}
      withDivider={model.withDivider}
      min={model.min}
      max={model.max}
    />
  );
};

export default React.memo(ModelEditorNumber);
