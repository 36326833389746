import React from 'react';
import T from 'ecto-common/lib/lang/Language';
import _ from 'lodash';
import PresentationAPIGen from '../API/PresentationAPIGen';
import SelectPresentationItemDialog from '../SelectPresentationItemDialog/SelectPresentationItemDialog';

export type Item = {
  id?: string;
  name?: string;
  priority?: number;
  nodeId?: string;
};

const SelectProcessMapDialog = ({
  isOpen,
  onModalClose,
  nodeId,
  equipmentTypeId,
  onConfirm = null,
  actionText = T.common.save
}: {
  isOpen?: boolean;
  onModalClose: () => void;
  nodeId?: string;
  equipmentTypeId?: string;
  onConfirm?: (item: Item[]) => void;
  actionText?: React.ReactNode;
}) => {
  return (
    <SelectPresentationItemDialog
      title={T.admin.editlocation.editprocessmap}
      isOpen={isOpen}
      onModalClose={onModalClose}
      nodeId={nodeId}
      equipmentTypeId={equipmentTypeId}
      onConfirm={onConfirm}
      actionText={actionText}
      listItemsHook={PresentationAPIGen.Pictures.listPictures.useQuery}
      getItemHook={PresentationAPIGen.Nodes.getPictures.useQuery}
      saveItemPromise={PresentationAPIGen.Nodes.assignPictures.promise}
      deleteItemPromise={PresentationAPIGen.Nodes.unassignPicture.promise}
    />
  );
};

export default SelectProcessMapDialog;
