import React from 'react';
import styles from './Checkbox.module.css';
import Icons from '../Icons/Icons';
import classNames from 'classnames';

interface CheckboxProps {
  /**
   * Called whenever the state of the checkbox changes. First argument is checked state.
   */
  onChange?(checked: boolean): void;
  /**
   * Checkbox label.
   */
  children?: React.ReactNode;
  /**
   * Whether or not the Checkbox is checked.
   */
  checked?: boolean;
  /**
   * When state is checked, render using slightly lighter colors. Useful for table header checkboxes that indicate that some of the items are selected but not all.
   */
  partiallyChecked?: boolean;
  /**
   *  Gives the checkbox a disabled appearance. onChange will not be triggered if this is set.
   */
  disabled?: boolean;
  /**
   * Used to override the appearance of the checkbox. Should be a valid CSS class name.
   */
  className?: string;
  /**
   * Used to make the checkbox slightly smaller, useful for inline content
   */
  compact?: boolean;
  /**
   * Used to specify a DOM ID for the checkbox.
   */
  id?: string;
}

const Checkbox = ({
  id,
  disabled,
  children,
  onChange,
  checked,
  partiallyChecked,
  className,
  compact
}: CheckboxProps) => {
  const constrainSize =
    children == null || React.Children.count(children) === 0;

  return (
    <div
      id={id}
      className={classNames(
        styles.checkbox,
        disabled && styles.disabled,
        constrainSize && styles.constrainSize,
        compact && styles.compact,
        className
      )}
      onClick={() => {
        if (disabled) {
          return;
        }

        if (onChange) {
          onChange(!checked);
        }
      }}
    >
      <div
        className={classNames(
          styles.checkboxCheckmark,
          children && styles.marginRight,
          compact && styles.compact
        )}
      >
        <Icons.Checkmark
          className={classNames(
            styles.icon,
            checked && styles.visibleIcon,
            partiallyChecked && styles.partiallyVisibleIcon
          )}
        />
      </div>
      {children}
    </div>
  );
};

export default React.memo(Checkbox);
