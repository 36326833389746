import React, { useCallback, useMemo } from 'react';
import _ from 'lodash';

import { useSimpleDialogState } from 'ecto-common/lib/hooks/useDialogState';
import Icons from 'ecto-common/lib/Icons/Icons';
import SelectEquipmentDialog from 'ecto-common/lib/SelectEquipmentDialog/SelectEquipmentDialog';
import { KeyValueFixedSelectableInput } from 'ecto-common/lib/KeyValueInput/KeyValueFixedSelectableInput';

import styles from 'ecto-common/lib/ModelForm/Plugins/ModelEditorEquipment.module.css';
import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';
import { ModelEditorProps } from 'ecto-common/lib/ModelForm/ModelEditor';
import { getEquipmentNode } from 'ecto-common/lib/utils/locationUtils';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import { ModelDefinitionInternal } from 'ecto-common/lib/ModelForm/ModelPropType';

export type EquipmentModelDefinition<
  ObjectType extends object,
  EnvironmentType extends object = object
> = {
  modelType: typeof ModelType.EQUIPMENT;
  selectFromCurrentNodeOnly?: boolean;
} & ModelDefinitionInternal<ObjectType, EnvironmentType, string>;

type ModelEditorEquipmentProps = ModelEditorProps & {
  model: EquipmentModelDefinition<object>;
};

const ModelEditorEquipment = ({
  model,
  disabled,
  rawValue,
  hasError,
  updateItem
}: ModelEditorEquipmentProps) => {
  const [dialogIsOpen, showDialog, hideDialog] = useSimpleDialogState();

  const nodeId = useCommonSelector((state) => state.general.nodeId);
  const nodeMap = useCommonSelector((state) => state.general.nodeMap);
  const equipmentMap = useCommonSelector((state) => state.general.equipmentMap);

  const equipment = useMemo(
    () =>
      _.find(getEquipmentNode(rawValue, nodeMap, equipmentMap)?.equipments, [
        'equipmentId',
        rawValue
      ]),
    [equipmentMap, nodeMap, rawValue]
  );

  const content = useMemo(
    () =>
      equipment && (
        <div className={styles.valueContainer}>
          <Icons.Equipment /> <div>{equipment?.name}</div>
        </div>
      ),
    [equipment]
  );

  const placeholderContent = useMemo(
    () => (
      <div className={styles.valueContainer}>
        <Icons.Equipment /> {model.placeholder}
      </div>
    ),
    [model?.placeholder]
  );

  const updateValue = useCallback(
    (selectedEquipments: string[]) => {
      const equipmentId = _.head(selectedEquipments);

      updateItem(equipmentId);
      hideDialog();
    },
    [hideDialog, updateItem]
  );

  const onClearClick = useCallback(() => {
    updateItem(null);
  }, [updateItem]);

  return (
    <>
      <div className={styles.inputWrapper}>
        <KeyValueFixedSelectableInput
          disabled={disabled}
          keyText={model.label}
          onClick={showDialog}
          value={content}
          placeholder={placeholderContent}
          hasError={hasError}
          isClearable
          onClear={onClearClick}
        />
      </div>

      <SelectEquipmentDialog
        isOpen={dialogIsOpen}
        nodeId={nodeId}
        onModalClose={hideDialog}
        onEquipmentSelected={updateValue}
        selectedEquipment={equipment}
        selectFromCurrentNodeOnly={model.selectFromCurrentNodeOnly}
      />
    </>
  );
};

export default React.memo(ModelEditorEquipment);
