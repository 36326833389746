/**
 * Main app context. Keeps track of connection statuses, colors and other
 * things relevant for most screens.
 */

import React from 'react';
import colors from '../../styles/variables/colors';

// Connection statuses
export const NOT_CONNECTED = 0;
export const CONNECTED = 1;
export const CONNECTED_BUT_NO_TABLE = 2;
export const CONNECTED_BUT_NO_BUILDINGS = 3;

/**
 * Helper to check if context is in a "connect"-state.
 * @param {Object} context The context you want to check
 * @returns {boolean}
 */
export const isConnecting = (context) => context.connecting;
/**
 * Helper to check if context is in a "connected"-state.
 * @param {Object} context The context you want to check
 * @returns {boolean}
 */
export const isConnected = (context) =>
  !context.connecting && context.connectionState > NOT_CONNECTED;

/** The initial, default app context */
export const initialAppContext = {
  debug: false,
  clientNumber: -1,
  error: null,
  connecting: true,
  isMultiScreen: false,
  room: null,
  connectionState: NOT_CONNECTED,
  reloadApp: () => {},
  colors
};

const AppContext = React.createContext(initialAppContext);

export default AppContext;

export type AppContextType = typeof initialAppContext;
