export function getPlatform() {
  // 2022 way of detecting. Note : this userAgentData feature is available only in secure contexts (HTTPS)

  if (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    typeof (navigator as any).userAgentData !== 'undefined' &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (navigator as any).userAgentData != null
  ) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (navigator as any).userAgentData.platform;
  }
  // Deprecated but still works for most of the browser
  if (typeof navigator.platform !== 'undefined') {
    if (
      typeof navigator.userAgent !== 'undefined' &&
      /android/.test(navigator.userAgent.toLowerCase())
    ) {
      // android device’s navigator.platform is often set as 'linux', so let’s use userAgent for them
      return 'android';
    }
    return navigator.platform;
  }
  return 'unknown';
}

export function isMac() {
  const platform = getPlatform();
  const isOSX = /mac/.test(platform); // Mac desktop
  const isIOS = ['iphone', 'ipad', 'ipod'].indexOf(platform) >= 0; // Mac iOs
  return isOSX || isIOS; // Apple device (desktop or iOS)
}
