import React from 'react';
import styles from './Tag.module.css';
import classNames from 'classnames';

interface TagProps {
  className?: string;
  children?: React.ReactNode;
}

const Tag = ({ children, className = null, ...otherProps }: TagProps) => {
  return (
    <div className={classNames(styles.tag, className)} {...otherProps}>
      {children}
    </div>
  );
};

export default React.memo(Tag);
