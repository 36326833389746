import HttpStatus from 'ecto-common/lib/utils/HttpStatus';

function checkStatus(response: Response) {
  const { status, statusText } = response;

  if (status >= HttpStatus.OK && status < 300) {
    return response;
  }

  const error = new Error(statusText);

  // TODO: Add better handling than this
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore-next-line
  error.response = response;

  throw {
    error: error,
    response: response
  };
}

export default checkStatus;
