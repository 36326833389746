import React from 'react';
import T from 'ecto-common/lib/lang/Language';
import _ from 'lodash';
import { CustomPanelProps } from 'ecto-common/lib/Dashboard/Panel';
import DataSourceTypes from '../datasources/DataSourceTypes';
import { SingleGridNode } from 'ecto-common/lib/types/EctoCommonTypes';
import FilesList from 'ecto-common/lib/SelectFilesDialog/FilesList';
import PresentationAPIGen from 'ecto-common/lib/API/PresentationAPIGen';

const FilesPanel = ({
  data
}: CustomPanelProps & {
  data: {
    node: SingleGridNode;
  };
}) => {
  const node = data?.node;

  const listQuery = PresentationAPIGen.Nodes.getFiles.useQuery(
    { nodeId: node?.nodeId },
    null,
    {
      enabled: !_.isEmpty(node?.nodeId)
    }
  );
  if (node == null) {
    return <div>{T.location.error.find}</div>;
  }

  return (
    <FilesList
      nodeId={node?.nodeId}
      items={listQuery.data?.items ?? []}
      isLoading={listQuery.isLoading}
    />
  );
};

export const FilesPanelData = {
  version: 0,
  emptyTargets: {
    node: {
      sourceType: DataSourceTypes.NODE
    }
  },
  helpPath: ''
};

export default FilesPanel;
