import React from 'react';
import classnames from 'classnames';
import Flex from '../Flex/Flex';
import './Box.css';

type BoxProps = {
  title: string;
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
};

export default function Box({ title, children, className, style }: BoxProps) {
  const cssClasses = classnames(['Box', className]);

  return (
    <Flex direction="column" className={cssClasses} style={style}>
      <Flex direction="column" className="Box__inner">
        <h2 className="Box__title">{title}</h2>
        <div className="Box__content">{children}</div>
      </Flex>
    </Flex>
  );
}
