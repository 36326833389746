import React, {
  MouseEventHandler,
  useCallback,
  useContext,
  useMemo
} from 'react';
import _ from 'lodash';

import Icons from 'ecto-common/lib/Icons/Icons';

import styles from './StyledMapMarker.module.css';
import StyledMarkerPopup from './StyledMarkerPopup';

import { EquipmentTypes } from 'ecto-common/lib/utils/equipmentTypeUtils';
import Spinner, { SpinnerSize } from 'ecto-common/lib/Spinner/Spinner';
import classNames from 'classnames';
import DashboardDataContext from 'ecto-common/lib/hooks/DashboardDataContext';
import { NodeTypes } from 'ecto-common/lib/utils/constants';
import { formatNumberUnit } from 'ecto-common/lib/utils/stringUtils';
import { numDecimalsForUnit } from 'ecto-common/lib/Charts/UnitUtil';
import { getSignalTypeUnit } from 'ecto-common/lib/SignalSelector/SignalUtils';
import { GridTypes } from 'ecto-common/lib/constants';
import {
  DashboardOption,
  PointPropertiesType
} from 'ecto-common/lib/Dashboard/panels/LocationMapPanel/DashboardMap';
import {
  MatchedSignal,
  MatchSignalsForNodesResult
} from 'ecto-common/lib/Dashboard/datasources/SignalValuesDataSource';
import { SignalValueType } from 'ecto-common/lib/hooks/useLatestSignalValues';
import { NodeEquipmentResponseModel } from 'ecto-common/lib/API/APIGen';
import { EquipmentType } from '../../../utils/equipmentTypeUtils';

const ActiveTypes = [
  EquipmentTypes.ECTOGRID_ACTIVE_REVERSIBLE_HEAT_PUMP,
  EquipmentTypes.ECTOGRID_ACTIVE_DISTRICT_HEATING,
  EquipmentTypes.ECTOGRID_ACTIVE_COOLING_TOWER
];

const PassiveTypes = [EquipmentTypes.ECTOGRID_PASSIVE_BALANCING_UNIT];

const someIncludes = (
  objects: NodeEquipmentResponseModel[],
  valuesToCheck: EquipmentType[],
  match: string
) =>
  _.some(objects, (object) => _.includes(valuesToCheck, _.get(object, match)));

interface MarkerIconProps {
  grid?: string;
  isSite?: boolean;
  hasActiveBalancingUnit?: boolean;
  hasPassiveBalancingUnit?: boolean;
}

const MarkerIcon = ({
  isSite,
  grid,
  hasActiveBalancingUnit,
  hasPassiveBalancingUnit
}: MarkerIconProps) => {
  if (isSite) {
    switch (grid) {
      case GridTypes.ECTOGRID:
        return <Icons.EctoGridLogo />;
      case GridTypes.HEATING:
        return <Icons.HeatingGrid />;
      case GridTypes.COOLING:
        return <Icons.CoolingGrid />;
      case GridTypes.ELECTRICITY:
        return <Icons.ElectricityGrid className={styles.gridIcon} />;
      default:
        return null;
    }
  }

  if (hasActiveBalancingUnit) {
    return <Icons.ActiveBalancingUnit />;
  }

  if (hasPassiveBalancingUnit) {
    return <Icons.PassiveBalancingUnit />;
  }

  return <Icons.HouseUnit />;
};

interface StyledMapMarkerProps {
  properties: PointPropertiesType;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  showPopup?: boolean;
  focusActive?: boolean;
  nodeIsFocused?: boolean;
  signalValues?: SignalValueType[];
  signalInfo?: MatchSignalsForNodesResult;
  isLoading?: boolean;
  selectedSignal?: DashboardOption;
  isCurrentNode?: boolean;
}

const StyledMapMarker = ({
  properties,
  onClick,
  showPopup,
  focusActive,
  nodeIsFocused,
  signalValues,
  signalInfo,
  isLoading,
  selectedSignal,
  isCurrentNode
}: StyledMapMarkerProps) => {
  const { nodeId, grid } = properties;
  const isSite = NodeTypes.SITE === properties?.nodeType;
  const hasActiveBalancingUnit =
    !isSite &&
    someIncludes(properties.equipments, ActiveTypes, 'equipmentTypeId');
  const hasPassiveBalancingUnit =
    !isSite &&
    someIncludes(properties.equipments, PassiveTypes, 'equipmentTypeId');

  const { signalTypesMap, signalUnitTypesMap } =
    useContext(DashboardDataContext);

  const _selectedSignal = useMemo<MatchedSignal>(() => {
    const selectedSignals = _.filter(signalInfo?.matchingSignals, [
      'signal.signalTypeId',
      selectedSignal?.value
    ]);
    return _.find(
      selectedSignals,
      ({ signal }) =>
        signalInfo?.nodeIdToSignal[properties.nodeId]?.[signal.signalId] != null
    );
  }, [
    signalInfo?.matchingSignals,
    signalInfo?.nodeIdToSignal,
    selectedSignal?.value,
    properties.nodeId
  ]);

  const nodeSignal = useMemo(() => {
    return {
      value: _.find(signalValues, {
        signalId: _selectedSignal?.signal?.signalId
      })?.value,
      unit: getSignalTypeUnit(
        _selectedSignal?.signal?.signalTypeId,
        signalTypesMap,
        signalUnitTypesMap
      ),
      numberOfDecimals: _selectedSignal?.signalInfo?.rounding
    };
  }, [_selectedSignal, signalValues, signalTypesMap, signalUnitTypesMap]);

  const _onClick: MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      if (isLoading) {
        return;
      }

      onClick?.(event);
    },
    [isLoading, onClick]
  );

  const { setNode } = useContext(DashboardDataContext);

  const onClickPopup = useCallback(() => {
    setNode?.(nodeId);
  }, [setNode, nodeId]);

  return (
    <div
      className={classNames(
        styles.marker,
        isCurrentNode && styles.isCurrentNode,
        showPopup && styles.popup,
        isLoading && styles.isLoading,
        focusActive && nodeIsFocused && styles.hasFocus,
        focusActive && !nodeIsFocused && styles.fade
      )}
      onClick={_onClick}
    >
      <div>
        <div
          className={classNames(
            styles.icon,
            !nodeSignal?.value && styles.hasNoSignalValue
          )}
        >
          {isLoading ? (
            <Spinner size={SpinnerSize.SMALL} color={'white'} />
          ) : (
            <MarkerIcon
              grid={grid}
              isSite={isSite}
              hasActiveBalancingUnit={hasActiveBalancingUnit}
              hasPassiveBalancingUnit={hasPassiveBalancingUnit}
            />
          )}
        </div>

        {nodeSignal?.value != null && (
          <div className={styles.signalValue}>
            <span>
              {formatNumberUnit(
                nodeSignal.value,
                nodeSignal.unit,
                nodeSignal.numberOfDecimals != null
                  ? nodeSignal.numberOfDecimals
                  : numDecimalsForUnit(nodeSignal.unit)
              )}
            </span>
          </div>
        )}
      </div>

      <div className={styles.arrowContainer}>
        <div className={styles.arrow} />
      </div>

      <StyledMarkerPopup
        isVisible={nodeIsFocused}
        properties={properties}
        onClick={onClickPopup}
        isCurrentNode={isCurrentNode}
      />
    </div>
  );
};

export default StyledMapMarker;
