import React from 'react';
import _ from 'lodash';
import T from 'ecto-common/lib/lang/Language';
import { modelFormIsValid } from 'ecto-common/lib/ModelForm/validateForm';

import SignalNamesTable from 'ecto-common/lib/SignalNamesTable/SignalNamesTable';
import { ModelEditorProps } from 'ecto-common/lib/ModelForm/ModelEditor';
import { ModelDefinition } from 'ecto-common/lib/ModelForm/ModelPropType';
import { SignalInputType } from 'ecto-common/lib/Dashboard/datasources/LastSignalValuesDataSource';
import { typedMemo } from 'ecto-common/lib/utils/typescriptUtils';
import { EditDashboardPanelEnvironment } from 'ecto-common/lib/Dashboard/panels';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';

type SignalNamesModelEditorProps = ModelEditorProps & {
  minItems: number;
  maxItems?: number;
  optionalSignalModels?: ModelDefinition<SignalInputType>[];
};

const SignalNamesModelEditor = ({
  updateItem,
  rawValue,
  optionalSignalModels,
  minItems,
  maxItems
}: SignalNamesModelEditorProps) => {
  return (
    <SignalNamesTable
      onSignalsChanged={updateItem}
      signals={rawValue}
      minItems={minItems}
      maxItems={maxItems}
      optionalSignalModels={optionalSignalModels}
    />
  );
};

type ObjectWithSignals = {
  signals: SignalInputType[];
};

export const createSignalNamesModel = (
  minItems: number,
  optionalSignalModels: ModelDefinition<SignalInputType>[]
): ModelDefinition<ObjectWithSignals, EditDashboardPanelEnvironment> => {
  return {
    key: (input) => input.signals,
    modelType: ModelType.CUSTOM,
    render: (props) => (
      <SignalNamesModelEditor
        minItems={minItems}
        optionalSignalModels={optionalSignalModels}
        {...props}
      />
    ),
    label: T.admin.dashboards.datasources.proptexts.signals,
    hasError: (
      signals: SignalInputType[],
      _input: ObjectWithSignals,
      environment
    ) => {
      // signalNamesModels is pulled in from the environment defined in EditDashboardPanelModal in order
      // to avoid recalculating it every time.
      const { signalNamesModels } = environment;

      return (
        (minItems > 0 && _.isEmpty(signals)) ||
        !_.every(signals, (signal) =>
          modelFormIsValid(signalNamesModels, signal, {})
        )
      );
    }
  };
};

export default typedMemo(SignalNamesModelEditor);
