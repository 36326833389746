const SocketMessages = {
  CONNECT: 'connect',

  DISCONNECT: 'disconnect',

  /**
   * Socket.IO build-in, client-side only, if the connection timed out.
   *
   * Upgrade note: This does not exist in socket.io 3.x
   */
  CONNECT_TIMEOUT: 'connect_timeout',
  /**
   * Socket.IO build-in, client-side only, if the failed due to connection error.
   *
   * Upgrade note: This does not exist in socket.io 3.x
   */
  CONNECT_ERROR: 'connect_error',

  /** Sends a request to all connected sockets in the room to reload. */
  REFRESH_REQUEST: 'request_clients_to_refresh',
  /** Triggers a refresh with all clients. */
  REFRESH_FORCE: 'force_refresh',

  /** Event for when the data is updated on the server */
  DATA: 'data',
  /**
   * Sets the current room data and broadcasts the change to all clients
   * on the server. This is a table-to-server-to-clients event.
   */
  DATA_BROADCAST: 'api',

  /** Sets the current socket role on the server. */
  ROLE_CHANGE: 'role',

  /**
   * Instructs the screens to change state, used for ad-views and screensavers.
   */
  TABLE_STATE: 'table_state',

  /** Server-to-screen only, sets the client number on connection. */
  CLIENT_NUMBER: 'client_number',

  /** Request pairing to a screen from the table. */
  PAIR_TABLE_TO_CLIENT: 'connect_table_to_client',
  /**
   * Connect a screen to the table as a result from PAIR_TABLE_TO_CLIENT
   * (if successful).
   */
  PAIR_CLIENT_TO_TABLE: 'connect_client_to_table',
  /** Let the table know the pairing was successful. */
  PAIR_SUCCESS: 'pair:success',
  /** Let the table know the pairing failed. */
  PAIR_ERROR: 'pair:error',

  /** Debug/test response from the server. */
  ECHO: 'echo',

  /** Set whether or not the panels should show ectocloud data */
  SET_ECTOCLOUD_MODE: 'set_ectoclud_mode',

  /** Invoked in order to get current ectocloud state */
  REQUEST_ECTOCLOUD_MODE: 'request_ectoclud_mode'
};

export default SocketMessages;
