import React from 'react';
import T from '../lang/Language';

import GreyButton from './GreyButton';
import SaveButton from './SaveButton';
import Button, { ButtonProps } from './Button';

const LocalizedButtons = {
  Ok: (props: ButtonProps) => <Button {...props}>{T.common.ok}</Button>,
  Done: (props: ButtonProps) => <Button {...props}>{T.common.done}</Button>,
  Add: (props: ButtonProps) => <Button {...props}>{T.common.add}</Button>,
  Save: (props: ButtonProps) => (
    <SaveButton {...props}>{T.common.save}</SaveButton>
  ),
  Cancel: (props: ButtonProps) => (
    <GreyButton {...props}>{T.common.cancel}</GreyButton>
  )
};

export default LocalizedButtons;
