import EnergyNode from './energy_node';

let idCounter = 0;

/**
 * A Connection is an abstract object connecting two Nodes
 * @class Connection
 */
class Connection {
  from: EnergyNode;
  to: EnergyNode;
  energy: number;
  id: number;

  constructor(from: EnergyNode, to: EnergyNode) {
    if (from === undefined) {
      console.warn('Trying to create a Connection without a start point');
      return;
    }
    if (to === undefined) {
      console.warn('Trying to create a Connection without an end point');
      return;
    }

    if (!(from instanceof EnergyNode)) {
      console.warn(
        'Trying to create a Connection where start point is not a Node'
      );
      return;
    }
    if (!(to instanceof EnergyNode)) {
      console.warn(
        'Trying to create a Connection where end point is not a Node'
      );
      return;
    }

    this.from = from;
    this.to = to;
    this.id = idCounter++;
  }

  getNode(otherNode: EnergyNode) {
    if (!(otherNode instanceof EnergyNode)) {
      console.warn('Instance of other node was not EnergyNode');
    }

    if (otherNode === this.from) {
      return this.to;
    }

    if (otherNode === this.to) {
      return this.from;
    }

    return undefined;
  }

  setValue(energy: number) {
    this.energy = energy;
  }

  reset() {
    this.from = undefined;
    this.to = undefined;
  }
}

export default Connection;
