const stateObject: any = {
  UNKNOWN: {},
  ACTIVE: {},
  INFO: {}
};

// Add a 'name' variable to each state and substate, matching the key it has in the parent
const nameObject = (obj) => {
  const keys = Object.keys(obj);

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const subObject = obj[key];

    nameObject(subObject);
    subObject.name = key;
  }
};

nameObject(stateObject);

export default stateObject;
