import Settings from './Settings';
import gridImage from '../assets/grid.png';
import gridImageRetina from '../assets/grid@2x.png';
import TableP5 from 'js/Table/TableP5';
import { Matrix } from 'transformation-matrix';
import p5 from 'p5';

const GRID_IMAGE_SIZE = 1024;

// How many times we should repeat the grid image in each direction
// in order to cover a reasonable area of the map
const NUM_GRID_IMAGES = 6;

/**
 * The rendered background for the table
 * @class Background
 */
class Background {
  gridImage: p5.Image;
  gridImageRetina: p5.Image;

  /* Initiate the rendering */
  renderInit(p5Instance: TableP5) {
    this.gridImageRetina = p5Instance.loadImage(gridImageRetina);
    this.gridImage = p5Instance.loadImage(gridImage);
  }

  draw(p5Instance: TableP5, transform: Matrix) {
    p5Instance.clear(0, 0, 0, 255);
    p5Instance.push();
    const { a, b, c, d, e, f } = transform;
    p5Instance.applyMatrix(a, b, c, d, e, f);

    let bgColor = p5Instance.color(Settings.backgroundColor);
    p5Instance.background(bgColor);

    let image =
      window.devicePixelRatio > 1 || p5Instance.scaleRatio >= 2
        ? this.gridImageRetina
        : this.gridImage;

    for (
      let x = -p5Instance.renderWidth * NUM_GRID_IMAGES;
      x < p5Instance.renderWidth * NUM_GRID_IMAGES;
      x += GRID_IMAGE_SIZE
    ) {
      for (
        let y = -p5Instance.renderHeight * NUM_GRID_IMAGES;
        y < p5Instance.renderHeight * NUM_GRID_IMAGES;
        y += GRID_IMAGE_SIZE
      ) {
        p5Instance.image(image, x, y, GRID_IMAGE_SIZE, GRID_IMAGE_SIZE);
      }
    }

    p5Instance.pop();
  }
}

export default Background;
