import { useCallback, useState } from 'react';
import _ from 'lodash';
import { useSearchParams } from 'react-router-dom';
import { useBlockerFunction } from 'ecto-common/lib/hooks/useBlockerListener';

/**
 * This is the main mechanism for handling dialog visibility in the application.
 * It uses the URLSearchParams API to store the dialog state in the URL. Should
 * be preferred over useSimpleDialogState if possible.
 *
 * @param dialogKey Unique key for the dialog, will be used as a search param
 */
const useDialogState = (
  dialogKey: string
): [boolean, () => void, () => void] => {
  const [params, setParams] = useSearchParams();

  const showDialog = useCallback(() => {
    params.set(dialogKey, 'true');
    setParams(params);
  }, [dialogKey, params, setParams]);

  const hideDialog = useCallback(() => {
    if (params.has(dialogKey)) {
      params.delete(dialogKey);
      setParams(params);
    }
  }, [dialogKey, params, setParams]);

  const dialogIsVisible = params.get(dialogKey) === 'true';
  return [dialogIsVisible, showDialog, hideDialog];
};

/**
 * For some dialogs, maintaing the dialog state in the URL complicates matters too much.
 * For instance confirmation dialogs, deeply nested dialogs, etc. This hook uses useState
 * to maintain the dialog state. Since users can close regular useDialogState dialogs using
 * the back button, this hook implements a similar mechanism by using useBlockerFunction.
 * If visible, the back button will close the dialog. If multiple dialogs are open, the
 * back button will close the topmost dialog.
 *
 * Finally, when no dialog is open, the back button will navigate back.
 */
export function useSimpleDialogState(initialIsOpen = false) {
  const [isOpen, setIsOpen] = useState(initialIsOpen);

  useBlockerFunction(
    useCallback(() => {
      setIsOpen(false);
      return true;
    }, []),
    isOpen
  );

  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);

  return [isOpen, open, close] as const;
}

/**
 * For some menus we can use this simple hook to maintain the state, that does not implement
 * the back button behavior. Examples: Dropdown nested menus etc.
 */
export function useMenuState(initialIsOpen = false) {
  const [isOpen, setIsOpen] = useState(initialIsOpen);

  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);

  return [isOpen, open, close] as const;
}

/**
 * Like useState, but the state is stored in the URLSearchParams. Useful for when you
 * handle dialog visibility or other UI changes by storing an ID in the URL search bar.
 */
export function useSearchParamState(key: string, defaultValue: string) {
  const [params, setParams] = useSearchParams();
  const value = params.get(key) || defaultValue;

  const setValue = useCallback(
    (newValue: string, replace = false) => {
      if (
        (newValue == null && params.get(key) == null) ||
        params.get(key) === newValue
      ) {
        return;
      }

      setParams(
        (prev) => {
          if (newValue === defaultValue || newValue == null) {
            prev.delete(key);
          } else {
            prev.set(key, newValue);
          }

          return prev;
        },
        { replace }
      );
    },
    [params, key, setParams, defaultValue]
  );

  return [value, setValue] as const;
}

export default useDialogState;
