import React, { useState, useEffect, useCallback } from 'react';
import ModelForm from 'ecto-common/lib/ModelForm/ModelForm';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import Icons from 'ecto-common/lib/Icons/Icons';
import useFormInputWithValidation from 'ecto-common/lib/ModelForm/useFormInputWithValidation';
import _ from 'lodash';
import T from 'ecto-common/lib/lang/Language';
import { ModelDefinition } from 'ecto-common/lib/ModelForm/ModelPropType';
import { SignalInputType } from 'ecto-common/lib/Dashboard/datasources/LastSignalValuesDataSource';

interface EditSignalSettingsDialogProps {
  models?: ModelDefinition<SignalInputType, object>[];
  input?: SignalInputType;
  isOpen?: boolean;
  onModalClose: () => void;
  onSuccess?(data: SignalInputType): void;
  isEditing?: boolean;
}

const EditSignalSettingsDialog = ({
  models,
  input,
  isOpen,
  onModalClose,
  onSuccess,
  isEditing
}: EditSignalSettingsDialogProps) => {
  const [data, setData] = useState<SignalInputType>(null);
  const [isValid, setIsValidItem] = useState(false);

  const onUpdateInput = useFormInputWithValidation(
    data,
    setData,
    setIsValidItem,
    models
  );

  const onConfirm = useCallback(() => {
    onSuccess(data);
    onModalClose();
  }, [onSuccess, data, onModalClose]);

  // Update form data when input changed
  useEffect(() => {
    setData(_.clone(input));
  }, [input]);

  return (
    <ActionModal
      headerIcon={isEditing ? Icons.Edit : Icons.Add}
      onModalClose={onModalClose}
      onConfirmClick={onConfirm}
      isOpen={isOpen}
      title={
        isEditing
          ? T.admin.dashboards.forms.signaldetails.editdialog.title
          : T.admin.dashboards.forms.signaldetails.editdialog.addtitle
      }
      disableActionButton={!isValid}
    >
      <ModelForm input={data} onUpdateInput={onUpdateInput} models={models} />
    </ActionModal>
  );
};

export default React.memo(EditSignalSettingsDialog);
