import CommonAPIFetch from 'ecto-common/lib/utils/APIFetch';
import {
  InteractionRequiredAuthError,
  IPublicClientApplication,
  AuthenticationResult
} from '@azure/msal-browser';
import {
  handleMSALNeedsInteraction,
  isAuthRedirecting
} from 'ecto-common/lib/hooks/useAuthentication';
import { APIFetchType, FetchOptions } from 'ecto-common/lib/utils/APIFetchType';
import _ from 'lodash';
import { ApiContextSettings } from '../API/APIUtils';

export const createExternalAPIFetch = () => {
  return (
    _contextSettings: ApiContextSettings,
    path: string,
    options = {},
    fetchOptions = {}
  ) => {
    // Don't handle 401 errors since request is not tied to our auth system
    return CommonAPIFetch({ path, ...fetchOptions, options });
  };
};

export const createAPIFetch = (
  msalConfiguration: IPublicClientApplication,
  defaultScopes: string[],
  signIn: (result: AuthenticationResult) => void
): APIFetchType => {
  return (
    contextSettings: ApiContextSettings,
    path: string,
    options: RequestInit = { headers: {} },
    fetchOptions: FetchOptions = {}
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<any> => {
    const accounts = msalConfiguration?.getAllAccounts();
    const account = accounts?.[0];

    const storeTokenCallback = (response: AuthenticationResult) =>
      signIn?.(response);

    const scopesFromOptions = _.isFunction(fetchOptions.scopes)
      ? fetchOptions.scopes()
      : fetchOptions.scopes;

    const scopes = scopesFromOptions || defaultScopes;
    const accessTokenRequest = { scopes, account };

    if (account == null) {
      signIn(null);

      if (!isAuthRedirecting()) {
        return msalConfiguration.logoutRedirect({
          postLogoutRedirectUri: '/'
        });
      }
    }

    if (isAuthRedirecting()) {
      return Promise.reject({});
    }

    return (
      msalConfiguration
        .acquireTokenSilent(accessTokenRequest)
        .then((response) => {
          const token = response?.accessToken;
          storeTokenCallback(response);

          const tenantId = contextSettings?.tenantId;
          if (tenantId === undefined) {
            throw new Error('Tenant ID is not defined');
          }

          const newHeaders = {
            Authorization: `Bearer ${token}`,
            'tenant-id': tenantId
          };

          const newOptions = {
            ...options,
            headers: {
              ...options.headers,
              ...newHeaders
            }
          };

          return CommonAPIFetch({ path, ...fetchOptions, options: newOptions });
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: InteractionRequiredAuthError | any) => {
          if (error instanceof InteractionRequiredAuthError) {
            // Check for error twice in order to avoid throwing error if user is already being redirected
            handleMSALNeedsInteraction(
              error,
              msalConfiguration,
              accessTokenRequest
            );
          } else {
            throw error;
          }
        })
    );
  };
};

export default createAPIFetch;
