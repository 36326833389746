import React from 'react';
import Icons from 'ecto-common/lib/Icons/Icons';
import StandardButton from 'ecto-common/lib/Button/StandardButton';
import T from 'ecto-common/lib/lang/Language';
import { ButtonProps } from 'ecto-common/lib/Button/Button';

type UsersButtonProps = ButtonProps & {
  tooltipText?: string;
};

const UsersButton = (props: UsersButtonProps) => (
  <StandardButton
    {...props}
    icon={<Icons.User />}
    tooltipText={T.common.users}
  />
);

export default UsersButton;
