import React, { useMemo } from 'react';
import colors from 'ecto-common/lib/styles/variables/colors';
import _ from 'lodash';

export type ProcessMapGridProps = {
  width: number;
  height: number;
  matrixTransform: string;
};

export const ProcessMapGridMaxSize = 3000;

const ProcessMapGrid = ({
  width,
  height,
  matrixTransform
}: ProcessMapGridProps) => {
  const safeWidth = Math.min(ProcessMapGridMaxSize, width);
  const safeHeight = Math.min(ProcessMapGridMaxSize, height);

  const [documentRows, documentColumns] = useMemo(() => {
    return [_.range(0, safeHeight, 10), _.range(0, safeWidth, 10)];
  }, [safeHeight, safeWidth]);

  return (
    <g
      style={{ transform: matrixTransform, fill: colors.whiteColor }}
      shapeRendering="crispEdges"
    >
      {<rect width={width} height={height} />}
      {documentRows.map((yPos, idx) => (
        <line
          style={{
            stroke: idx % 4 === 0 ? colors.surface4Color : colors.surface5Color
          }}
          key={yPos}
          x1={0}
          x2={safeWidth}
          y1={yPos}
          y2={yPos}
        />
      ))}
      {documentColumns.map((xPos, idx) => (
        <line
          style={{
            stroke: idx % 4 === 0 ? colors.surface4Color : colors.surface5Color
          }}
          key={xPos}
          x1={xPos}
          x2={xPos}
          y1={0}
          y2={safeHeight}
        />
      ))}
    </g>
  );
};

export default React.memo(ProcessMapGrid);
