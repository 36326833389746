import Structure from './Structure';
import EctogridCluster from './EctogridCluster';
import buildingIcons from '../config/buildingIcons';
import AccumulatorTank from './AccumulatorTank';
import { PVCell } from './ElectricitySource';

// FINGERPRINTS
const INDUSTRY = 73;
const SUPERMARKET = 114;
const SUPERMARKET_STABLE = 85;
const COMMERCIAL = 10;
const COMMERCIAL_STABLE = 65;
const RESIDENTIAL = 40;
const DATA_CENTER = 55;
const ECTOGRID = 25;

const touchFingerprints = [
  {
    fingerprint: INDUSTRY,
    centerOffset: 4,
    baseCircumference: 250,
    id: 'industry',
    tuioId: 5
  },
  {
    fingerprint: SUPERMARKET,
    centerOffset: -10,
    baseCircumference: 214,
    id: 'supermarket',
    tuioId: 6
  },
  {
    fingerprint: SUPERMARKET_STABLE,
    centerOffset: -12,
    baseCircumference: 255,
    id: 'supermarket'
  },
  {
    fingerprint: DATA_CENTER,
    centerOffset: 5,
    baseCircumference: 272,
    id: 'datacenter',
    tuioId: 4
  },
  {
    fingerprint: COMMERCIAL,
    centerOffset: 18,
    baseCircumference: 193,
    id: 'commercial',
    tuioId: 2
  },
  {
    fingerprint: COMMERCIAL_STABLE,
    centerOffset: 5,
    baseCircumference: 240,
    id: 'commercial'
  },
  {
    fingerprint: RESIDENTIAL,
    centerOffset: 10.2,
    baseCircumference: 246,
    id: 'residential',
    tuioId: 3
  },
  {
    fingerprint: ECTOGRID,
    centerOffset: 0,
    baseCircumference: 226,
    id: 'ectogrid',
    tuioId: 1
  }
];

/* When using mouse we go through all buildings one after another
 * and put them on the table starting with this index
 */
let dummmyIndexForNewBuildings = 2;

export const preLoadedBuildings = {
  industry: {
    buildingId: 'industry',
    name: 'Industry',
    icon: buildingIcons.buildingIconConstants.industry,
    scale: 0.4,
    class: Structure
  },
  supermarket: {
    buildingId: 'supermarket',
    name: 'Supermarket',
    icon: buildingIcons.buildingIconConstants.supermarket,
    scale: 0.4,
    class: Structure
  },
  datacenter: {
    buildingId: 'datacenter',
    name: 'Data Center',
    icon: buildingIcons.buildingIconConstants.datacenter,
    scale: 0.4,
    class: Structure
  },
  commercial: {
    buildingId: 'commercial',
    name: 'Commercial',
    icon: buildingIcons.buildingIconConstants.commercial,
    scale: 0.1,
    class: Structure
  },
  residential: {
    buildingId: 'residential',
    name: 'Residential Building',
    icon: buildingIcons.buildingIconConstants.residential,
    scale: 0.1,
    class: Structure
  },
  ectogrid: {
    buildingId: 'ectogrid',
    name: 'ectogrid™',
    icon: buildingIcons.buildingIconConstants.ectogrid,
    class: EctogridCluster
  },
  accumulatortank: {
    buildingId: 'accumulatortank',
    name: 'Accumulator Tank',
    icon: buildingIcons.buildingIconConstants.commercial,
    class: AccumulatorTank
  },
  pvcell: {
    buildingId: 'pvcell',
    name: 'Solar cell',
    icon: buildingIcons.buildingIconConstants.pvcell,
    class: PVCell
  }
};

const combineBuildingTemplateAndTouchFingerprint = (
  triangleTemplate,
  buildingTemplate
) => {
  buildingTemplate.centerOffset = triangleTemplate.centerOffset;
  buildingTemplate.baseCircumference = triangleTemplate.baseCircumference;
  buildingTemplate.tuioId = triangleTemplate.tuioId;
  return buildingTemplate;
};

export default {
  buildingIcons: buildingIcons.buildingIconConstants,
  preLoadedBuildings,
  numTouchFingerPrints: touchFingerprints.length,
  findPreLoadedBuildingByTuioId: (tuioId) => {
    let triangleTemplate = touchFingerprints.find((fp) => fp.tuioId === tuioId);

    if (triangleTemplate == null) {
      return null;
    }

    const buildingTemplate = preLoadedBuildings[triangleTemplate.id];
    return combineBuildingTemplateAndTouchFingerprint(
      triangleTemplate,
      buildingTemplate
    );
  },
  findPreLoadedBuilding: (fingerprint) => {
    let triangleTemplate = touchFingerprints[0];

    touchFingerprints.forEach((building) => {
      if (
        Math.abs(fingerprint - building.fingerprint) <
        Math.abs(fingerprint - triangleTemplate.fingerprint)
      ) {
        triangleTemplate = building;
      }
    });
    const buildingTemplate = preLoadedBuildings[triangleTemplate.id];

    return combineBuildingTemplateAndTouchFingerprint(
      triangleTemplate,
      buildingTemplate
    );
  },
  getNextBuildingInDummyOrder: () => {
    const buildings = Object.values(preLoadedBuildings);
    const building = buildings[dummmyIndexForNewBuildings];

    dummmyIndexForNewBuildings++;
    dummmyIndexForNewBuildings = dummmyIndexForNewBuildings % buildings.length;
    return building;
  }
};
