import React from 'react';

const HouseUnit = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M13 20V24H11V20H13ZM15 18H9V26H15V18Z" fill="white" />
    <path d="M13 12V14H11V12H13ZM15 10H9V16H15V10Z" fill="white" />
    <path d="M21 12V14H19V12H21ZM23 10H17V16H23V10Z" fill="white" />
    <path d="M21 20V28H19V20H21ZM23 18H17V30H23V18Z" fill="white" />
    <path
      d="M25 6V28H7V6H25ZM27 4H5V28C5 28.5304 5.21071 29.0391 5.58579 29.4142C5.96086 29.7893 6.46957 30 7 30H25C25.5304 30 26.0391 29.7893 26.4142 29.4142C26.7893 29.0391 27 28.5304 27 28V4Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.8319 6.4453C29.9323 6.5959 29.99 6.77092 29.9987 6.9517C30.0075 7.13249 29.967 7.31225 29.8816 7.47184C29.7962 7.63142 29.6691 7.76483 29.5138 7.85784C29.3585 7.95086 29.1809 7.99999 28.9999 8H2.99995C2.81895 7.99999 2.64135 7.95086 2.48609 7.85784C2.33082 7.76483 2.20371 7.63142 2.11831 7.47184C2.03291 7.31225 1.99243 7.13249 2.00117 6.9517C2.00991 6.77092 2.06755 6.5959 2.16795 6.4453L4.83455 2.4453C4.92587 2.30831 5.04961 2.196 5.19476 2.11831C5.33992 2.04063 5.50201 1.99999 5.66665 2H26.3333C26.4979 1.99999 26.66 2.04063 26.8051 2.11831C26.9503 2.196 27.074 2.30831 27.1653 2.4453L29.8319 6.4453Z"
      fill="white"
    />
  </svg>
);

export default HouseUnit;
