import React from 'react';
import SvgIcon, { SvgIconProps } from './SvgIcon';
import styles from './Edit.module.css';

const Edit = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 42 42" {...props}>
    <path
      className={styles.cls1}
      d="M17,6h1.21A1.79,1.79,0,0,1,20,7.79v4.65a.56.56,0,0,0,.56.56h4.65A1.79,1.79,0,0,1,27,14.79V15"
    />
    <path
      className={styles.cls2}
      d="M27,15V13.77a.56.56,0,0,0-.16-.4L19.67,6.17a.56.56,0,0,0-.4-.17H6.56A.56.56,0,0,0,6,6.56V35.44a.56.56,0,0,0,.56.56H26.44a.56.56,0,0,0,.56-.56V32"
    />
    <path
      className={styles.cls3}
      d="M30.57,19.29,29.24,18,20.7,26.5l-2.23,5.58L24,29.85,35.42,18.48a2.37,2.37,0,0,0,0-3.35h0a2.37,2.37,0,0,0-3.35,0l-.37.37"
    />
  </SvgIcon>
);

export default Edit;
