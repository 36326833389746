import React from 'react';
import classNames from 'classnames';
import styles from './Spinner.module.css';
import dimensions from 'ecto-common/lib/styles/dimensions';

export enum SpinnerSize {
  INLINE = 'inline',
  TREE_VIEW = 'treeview',
  TINY = 'tiny',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large'
}

export const getSpinnerSizePixels = (spinnerSize: SpinnerSize) => {
  switch (spinnerSize) {
    case SpinnerSize.LARGE:
      return dimensions.spinnerLargeSize;
    case SpinnerSize.MEDIUM:
      return dimensions.spinnerMediumSize;
    case SpinnerSize.TINY:
      return dimensions.spinnerTinySize;
    case SpinnerSize.TREE_VIEW:
      return dimensions.spinnerTreeViewSize;
    case SpinnerSize.SMALL:
      return dimensions.spinnerSmallSize;
    case SpinnerSize.INLINE:
      return dimensions.spinnerInlineSize;
    default:
      return 0;
  }
};

interface SpinnerProps {
  /**
   * The size of the spinner
   */
  size?: SpinnerSize;
  /**
   * Used to override the appearance of the button. Should be a valid CSS class name.
   */
  className?: string;
  /**
   * The color of the spinner
   */
  color?: 'red' | 'white' | 'green';
}

/**
 * This is a component that can be used to indicate that some asynchronous operation is taking place.
 */
const Spinner = ({
  className = null,
  size = SpinnerSize.MEDIUM,
  color = 'green',
  ...otherProps
}: SpinnerProps) => {
  if (size === SpinnerSize.INLINE) {
    return (
      <div
        className={classNames(styles.inline, className)}
        aria-label="Loading..."
        {...otherProps}
      >
        <div />
        <div />
        <div />
        <div />
      </div>
    );
  }

  return (
    <div
      className={classNames(
        styles.loading,
        className,
        styles[color],
        styles[size]
      )}
      aria-label="Loading..."
      {...otherProps}
    />
  );
};

export default React.memo(Spinner);
