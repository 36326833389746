// These util methods are lifted from https://github.com/google-map-react/google-map-react/
// We had to stop using the library due to the lack of support for the latest version of React.

const log2 = Math.log2 ? Math.log2 : (x: number) => Math.log(x) / Math.LN2;
const GOOGLE_TILE_SIZE = 256;
type LatLng = {
  lat: number;
  lng: number;
};

function latLng2World({ lat, lng }: LatLng) {
  const sin = Math.sin((lat * Math.PI) / 180);
  const x = lng / 360 + 0.5;
  let y = 0.5 - (0.25 * Math.log((1 + sin) / (1 - sin))) / Math.PI;

  y =
    y < 0 // eslint-disable-line
      ? 0
      : y > 1
        ? 1
        : y;
  return { x, y };
}

function world2LatLng({ x, y }: { x: number; y: number }) {
  const n = Math.PI - 2 * Math.PI * y;

  // TODO test that this is faster
  // 360 * Math.atan(Math.exp((180 - y * 360) * Math.PI / 180)) / Math.PI - 90;
  return {
    lat: (180 / Math.PI) * Math.atan(0.5 * (Math.exp(n) - Math.exp(-n))),
    lng: x * 360 - 180
  };
}

function fitNwSe(nw: LatLng, se: LatLng, width: number, height: number) {
  const EPS = 0.000000001;
  const nwWorld = latLng2World(nw);
  const seWorld = latLng2World(se);
  const dx =
    nwWorld.x < seWorld.x ? seWorld.x - nwWorld.x : 1 - nwWorld.x + seWorld.x;
  const dy = seWorld.y - nwWorld.y;

  if (dx <= 0 && dy <= 0) {
    return null;
  }

  const zoomX = log2(width / GOOGLE_TILE_SIZE / Math.abs(dx));
  const zoomY = log2(height / GOOGLE_TILE_SIZE / Math.abs(dy));
  const zoom = Math.floor(EPS + Math.min(zoomX, zoomY));

  // TODO find center just unproject middle world point
  const middle = {
    x:
      nwWorld.x < seWorld.x // eslint-disable-line
        ? 0.5 * (nwWorld.x + seWorld.x)
        : nwWorld.x + seWorld.x - 1 > 0
          ? 0.5 * (nwWorld.x + seWorld.x - 1)
          : 0.5 * (1 + nwWorld.x + seWorld.x),
    y: 0.5 * (nwWorld.y + seWorld.y)
  };

  const scale = Math.pow(2, zoom);
  const halfW = width / scale / GOOGLE_TILE_SIZE / 2;
  const halfH = height / scale / GOOGLE_TILE_SIZE / 2;

  const newNW = world2LatLng({
    x: middle.x - halfW,
    y: middle.y - halfH
  });

  const newSE = world2LatLng({
    x: middle.x + halfW,
    y: middle.y + halfH
  });

  return {
    center: world2LatLng(middle),
    zoom,
    newBounds: {
      nw: newNW,
      se: newSE
    }
  };
}

export function convertNeSwToNwSe({ ne, sw }: { ne: LatLng; sw: LatLng }) {
  return {
    nw: {
      lat: ne.lat,
      lng: sw.lng
    },
    se: {
      lat: sw.lat,
      lng: ne.lng
    }
  };
}

export function convertNwSeToNeSw({ nw, se }: { nw: LatLng; se: LatLng }) {
  return {
    ne: {
      lat: nw.lat,
      lng: se.lng
    },
    sw: {
      lat: se.lat,
      lng: nw.lng
    }
  };
}

export function fitBounds(
  { nw, se, ne, sw }: { nw?: LatLng; se?: LatLng; ne: LatLng; sw: LatLng },
  { width, height }: { width: number; height: number }
) {
  let fittedData;

  if (nw && se) {
    fittedData = fitNwSe(nw, se, width, height);
  } else {
    const calculatedNwSe = convertNeSwToNwSe({ ne, sw });
    fittedData = fitNwSe(calculatedNwSe.nw, calculatedNwSe.se, width, height);
  }

  return {
    ...fittedData,
    newBounds: {
      ...fittedData.newBounds,
      ...convertNwSeToNeSw(fittedData.newBounds)
    }
  };
}
