import React, { useCallback, useContext, useMemo, useState } from 'react';
import { KeyValueSelectableInput } from 'ecto-common/lib/KeyValueInput/KeyValueSelectableInput';
import _ from 'lodash';
import { ModelEditorProps } from 'ecto-common/lib/ModelForm/ModelEditor';
import { GenericSelectOption } from 'ecto-common/lib/Select/Select';
import { ApiContextSettings } from '../../API/APIUtils';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { ModelDefinitionInternal } from 'ecto-common/lib/ModelForm/ModelPropType';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';

export type OdataListOptionsModelDefinition<
  ObjectType extends object,
  EnvironmentType extends object = object
> = {
  modelType: typeof ModelType.ODATA_LIST_OPTIONS;
  isClearable?: boolean;
  oDataPromise?: (
    contextSettings: ApiContextSettings,
    params: ODataQueryParam,
    abortSignal: AbortSignal
  ) => Promise<ODataResponseType>;
  showOptionWhenEmpty?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onAction?: (value?: any) => void;
} & ModelDefinitionInternal<ObjectType, EnvironmentType, string[]>;

export type ODataQueryParam = {
  $filter?: string;
  $top?: number;
  continuationToken?: string;
};

export type ODataItemWithIdAndName = {
  id?: string;
  name?: string;
};

export type ODataResponseType = {
  items?: ODataItemWithIdAndName[];
  continuationToken?: string;
};

type AdditionalReqData = {
  continuationToken?: string;
};

type ModelEditorOptionsProps = ModelEditorProps & {
  model: OdataListOptionsModelDefinition<object, object>;
};

/**
 * This can be used when dealing with an OData API which has a $filter syntax. It solves
 * automatic paging and makes sure the selected options are always loaded regardless of the
 * search string.
 *
 * It presents a Select control and incrementally loads new options as needed.
 *
 * Currently it assumes that all objects in items both have a 'name' and 'id' property. This
 * component could be extended to be more flexible if needed.
 */
const ModelEditorODataListOptions = ({
  model,
  updateItem,
  disabled,
  rawValue,
  hasError,
  helpText = null,
  useTooltipHelpTexts = false
}: ModelEditorOptionsProps) => {
  const [allOptions, setAllOptions] = useState<
    Record<string, GenericSelectOption>
  >({});
  const [isLoading, setIsLoading] = useState(false);

  const value = useMemo(() => {
    if (rawValue == null) {
      return null;
    } else if (model.isMultiOption) {
      return _.compact(_.map(rawValue, (item) => allOptions[item]));
    }

    return allOptions[rawValue];
  }, [rawValue, model.isMultiOption, allOptions]);
  const { contextSettings } = useContext(TenantContext);

  const loadOptions = useCallback(
    (
      search: string,
      loadedOptions: GenericSelectOption<string>[],
      additional: AdditionalReqData
    ) => {
      const ids: string[] = model.isMultiOption
        ? rawValue ?? []
        : _.compact([rawValue]);
      const missingIds = ids.filter((id) => allOptions[id] == null);
      const itemToOption = (
        item: ODataItemWithIdAndName
      ): GenericSelectOption => ({ label: item.name, value: item.id });

      let missingOptionsRequest: Promise<ODataResponseType> = Promise.resolve({
        items: [],
        continuationToken: null
      });

      if (missingIds.length > 0) {
        missingOptionsRequest = model.oDataPromise(
          contextSettings,
          {
            $filter: `id in (${missingIds.map((option) => "'" + option + "'")})`
          },
          null
        );
      }

      setIsLoading(true);

      const listRequest = model.oDataPromise(
        contextSettings,
        {
          $filter: search
            ? `contains(tolower(name), tolower('${search}'))`
            : undefined,
          continuationToken: additional.continuationToken
        },
        null
      );

      return Promise.all([missingOptionsRequest, listRequest])
        .then(([missingOptionsResponse, listResponse]) => {
          setIsLoading(false);
          const missingOptions = _.map(
            missingOptionsResponse.items,
            itemToOption
          );
          let listOptions = _.map(listResponse.items, itemToOption);

          const newAllOptions = { ...allOptions };

          for (const option of _.concat(missingOptions, listOptions)) {
            newAllOptions[option.value] = option;
          }

          const combinedOptions = loadedOptions.concat(listOptions);

          for (const id of ids) {
            if (combinedOptions.find((item) => item.value === id) == null) {
              const entry = newAllOptions[id];

              listOptions = [entry, ...listOptions];
            }
          }

          setAllOptions(newAllOptions);

          return {
            options: listOptions,
            hasMore: listResponse.continuationToken != null,
            additional: {
              ...additional,
              continuationToken: listResponse.continuationToken
            }
          };
        })
        .catch((e) => {
          setIsLoading(false);
          throw e;
        });
    },
    [model, rawValue, contextSettings, allOptions]
  );

  const onChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e: any) => {
      if (model.isMultiOption) {
        updateItem(_.map(e, 'value'));
      } else {
        updateItem(e?.value ?? null);
      }
    },
    [model, updateItem]
  );

  const allProps = {
    keyText: model.label,
    disabled: disabled,
    value: value,
    onAction: model.onAction,
    onChange: onChange,
    placeholder: model.placeholder,
    hasError: hasError,
    isClearable: model.isClearable,
    helpText,
    useTooltipHelpTexts,
    loadOptions,
    showOptionWhenEmpty: model.showOptionWhenEmpty,
    additional: {
      rawValue
    },
    isLoading
  };

  if (model.isMultiOption) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return <KeyValueSelectableInput<any, true> {...allProps} isMulti />;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return <KeyValueSelectableInput<any, false> {...allProps} />;
};

export default React.memo(ModelEditorODataListOptions);
