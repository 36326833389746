import React, { useCallback } from 'react';

import styles from './ModalHeader.module.css';

export type ModalTitleIconProps = {
  className?: string;
  height?: number;
  width?: number;
};

interface ModalHeaderProps {
  /**
   * The content of the header.
   */
  children?: React.ReactNode;
  /**
   * Icon that will be displayed to the left of the header content
   */
  titleIcon?: React.FC<ModalTitleIconProps>;
  /**
   * Content that will end up on the right side of the modal header
   */
  rightContent?: React.ReactNode;

  /**
   * Set this to receive a callback whenever the user drags the header.
   * Can be used to implement modal dragging.
   */
  onHeaderDragged?: (dx: number, dy: number) => void;
}

/**
 *  This component is used to add a top header to a modal dialog.
 */
const ModalHeader = ({
  children,
  titleIcon: TitleIcon = null,
  rightContent = null,
  onHeaderDragged = null,
  ...otherProps
}: ModalHeaderProps) => {
  const dragHandler: React.MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      if (e.buttons === 1) {
        onHeaderDragged?.(e.movementX, e.movementY);
      }
    },
    [onHeaderDragged]
  );

  return (
    <div
      className={styles.modalHeader}
      {...otherProps}
      onMouseMove={dragHandler}
    >
      <span className={styles.modalHeaderInner}>
        {TitleIcon && (
          <TitleIcon className={styles.titleIcon} height={24} width={24} />
        )}

        <div className={styles.text}>{children}</div>
      </span>

      {rightContent && <>{rightContent}</>}
    </div>
  );
};

export default ModalHeader;
