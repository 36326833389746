import React, { useMemo } from 'react';
import classnames from 'classnames';
import View from '../../components/View/View';
import PredictionChart from './PredictionChart';
import Weather from './Weather';
import './EctocloudView.css';
import EctotableDashboardPanel from '../DashboardView/EctotableDashboardPanel';
import dashboardData from './EctocloudViewDashboardData.json';
import deliveryHeatingData from './EctocloudViewDeliveryHeatingData.json';
import deliveryCoolingData from './EctocloudViewDeliveryCoolingData.json';
import gaugeData from './EctocloudViewGaugeData.json';

type EctocloudViewProps = {
  data: any;
  className?: string;
  ectocloudMode: boolean;
  size: { width: number; height: number };
};

/** Client screen showing weather and prediction data. */
const EctocloudView = ({
  data,
  className,
  ectocloudMode,
  size
}: EctocloudViewProps) => {
  const location = data && data.location;

  if (ectocloudMode) {
    return (
      <div className="EctocloudView__dashboard_content__main">
        <div className="View__title">
          <h1>Key Performance Indicators - Previous Day</h1>
        </div>
        <div className="EctocloudView__dashboard_panel_container">
          <div className="EctocloudView__dashboard_main_panel_container">
            <EctotableDashboardPanel panelData={dashboardData} />
          </div>
          <div className="EctocloudView__dashboard_side_panel_container">
            <div className="EctocloudView__side_panel EctocloudView__gauge">
              <EctotableDashboardPanel panelData={gaugeData} />
            </div>
            <div className="EctocloudView__side_panel EctocloudView__gauge">
              <EctotableDashboardPanel panelData={deliveryHeatingData} />
            </div>
            <div className="EctocloudView__side_panel EctocloudView__gauge">
              <EctotableDashboardPanel panelData={deliveryCoolingData} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  const childSize = useMemo(() => {
    return {
      width: size.width * 0.65,
      height: size.height
    };
  }, [size.width, size.height]);

  return (
    <View
      className={classnames(className, 'EctocloudView')}
      title="ectocloud™ Prediction"
    >
      <div className="EctocloudView__content__side">
        <Weather location={location} />
      </div>
      <div className="EctocloudView__content__main">
        <PredictionChart data={data} size={childSize} />
      </div>
    </View>
  );
};

export default React.memo(EctocloudView);
