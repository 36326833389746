import React from 'react';
import ModelEditorNode from './ModelEditorNode';
import ModelEditorNodeList from './ModelEditorNodeList';
import ModelEditorBool from './ModelEditorBool';
import ModelEditorLabel from './ModelEditorLabel';
import ModelEditorOptions from './ModelEditorOptions';
import ModelEditorText from './ModelEditorText';
import ModelEditorTable from './ModelEditorTable';
import ModelEditorButton from './ModelEditorButton';
import ModelEditorNumber from './ModelEditorNumber';
import ModelEditorRadioButton from './ModelEditorRadioButton';
import ModelEditorColor from './ModelEditorColor';
import ModelEditorSignal from 'ecto-common/lib/ModelForm/Plugins/ModelEditorSignal';
import ModelEditorEquipment from 'ecto-common/lib/ModelForm/Plugins/ModelEditorEquipment';
import ModelEditorSpace from 'ecto-common/lib/ModelForm/Plugins/ModelEditorSpace';
import ModelEditorCheckboxOptions from 'ecto-common/lib/ModelForm/Plugins/ModelEditorCheckboxOptions';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import ModelEditorSignalType from 'ecto-common/lib/ModelForm/Plugins/ModelEditorSignalType';
import ModelEditorODataListOptions from './ModelEditorODataListOptions';
import { ModelDefinition } from '../ModelPropType';
import {
  ModelEditorProps,
  evaluateModelFormProperty
} from 'ecto-common/lib/ModelForm/ModelEditor';
import { modelFormattedValue } from 'ecto-common/lib/ModelForm/modelValueUtil';
import ModelEditorDate from 'ecto-common/lib/ModelForm/Plugins/ModelEditorDate';
import ModelEditorSignalList from 'ecto-common/lib/ModelForm/Plugins/ModelEditorSignalList';
import ModelEditorFile from './ModelEditorFile';

const EmptyArray: unknown[] = [];

export function renderModelFormEditor<
  ObjectType extends object,
  EnvironmentType extends object
>(
  model: ModelDefinition<ObjectType, EnvironmentType>,
  defaultProps: ModelEditorProps<EnvironmentType>,
  rawValue: unknown,
  input: ObjectType,
  environment: EnvironmentType
) {
  switch (model.modelType) {
    case ModelType.NODE:
      return <ModelEditorNode {...defaultProps} model={model} />;
    case ModelType.NODE_LIST:
      return <ModelEditorNodeList {...defaultProps} model={model} />;
    case ModelType.TEXT:
      return <ModelEditorText {...defaultProps} model={model} />;
    case ModelType.DISABLED_TEXT:
      return (
        <ModelEditorText
          {...defaultProps}
          rawValue={modelFormattedValue(model, rawValue, input)}
          disabled
          model={model}
        />
      );
    case ModelType.COLOR:
      return <ModelEditorColor {...defaultProps} model={model} />;
    case ModelType.NUMBER:
      return <ModelEditorNumber {...defaultProps} model={model} />;
    case ModelType.BOOL:
      return <ModelEditorBool {...defaultProps} model={model} />;
    case ModelType.LABEL:
      return (
        <ModelEditorLabel
          {...defaultProps}
          formattedValue={modelFormattedValue(model, rawValue, input)}
          model={model}
        />
      );
    case ModelType.BUTTON:
      return <ModelEditorButton {...defaultProps} model={model} />;
    case ModelType.OPTIONS:
      return (
        <ModelEditorOptions
          {...defaultProps}
          options={evaluateModelFormProperty(
            model.options,
            rawValue,
            input,
            environment,
            EmptyArray,
            model
          )}
          model={model}
        />
      );
    case ModelType.ODATA_LIST_OPTIONS:
      return <ModelEditorODataListOptions {...defaultProps} model={model} />;
    case ModelType.TABLE:
      return (
        <ModelEditorTable
          {...defaultProps}
          tableData={model.tableData(rawValue, input, environment, model)}
          model={model}
        />
      );
    case ModelType.RADIO:
      return (
        <ModelEditorRadioButton
          {...defaultProps}
          model={model}
          isChecked={
            model.isChecked
              ? model.isChecked(rawValue, input, environment, model)
              : rawValue === model.targetValue
          }
        />
      );
    case ModelType.SIGNAL:
      return (
        <ModelEditorSignal
          {...defaultProps}
          model={model}
          nodeId={evaluateModelFormProperty(
            model.nodeId,
            rawValue,
            input,
            environment,
            null,
            model
          )}
        />
      );
    case ModelType.SIGNAL_LIST:
      return (
        <ModelEditorSignalList
          {...defaultProps}
          model={model}
          nodeId={evaluateModelFormProperty(
            model.nodeId,
            rawValue,
            input,
            environment,
            null,
            model
          )}
        />
      );
    case ModelType.SIGNAL_TYPE:
      return <ModelEditorSignalType {...defaultProps} model={model} />;
    case ModelType.EQUIPMENT:
      return <ModelEditorEquipment {...defaultProps} model={model} />;
    case ModelType.SPACE:
      return <ModelEditorSpace />;
    case ModelType.CHECKBOX_OPTIONS:
      return (
        <ModelEditorCheckboxOptions
          {...defaultProps}
          options={evaluateModelFormProperty(
            model.options,
            rawValue,
            input,
            environment,
            EmptyArray,
            model
          )}
          model={model}
        />
      );
    case ModelType.CUSTOM:
      return model.render(defaultProps, model, input, environment);
    case ModelType.DATE:
      return <ModelEditorDate {...defaultProps} model={model} />;
    case ModelType.FILE:
      return <ModelEditorFile {...defaultProps} model={model} />;
    default:
      throw new Error('Failed to find editor for model type', model);
  }
}
