import imgCommercial from '../images/building_white_commercial.svg';
import imgDataCenter from '../images/building_white_data_center.svg';
import imgIndustry from '../images/building_white_industry.svg';
import imgResidential from '../images/building_white_residential.svg';
import imgSupermarket from '../images/building_white_supermarket.svg';
import imgAquifer from '../images/building_aquifer.svg';
import imgEctogrid from '../images/building_ectogrid.svg';
import imgBattery from '../assets/battery.svg';
import imgPvCell from '../assets/sun.svg';

export default {
  buildingIconConstants: {
    industry: 'industry',
    supermarket: 'supermarket',
    commercial: 'commercial',
    residential: 'residential',
    datacenter: 'datacenter',
    ectogrid: 'ectogrid',
    battery: 'battery',
    pvcell: 'pvcell',
    aquifer: 'aquifer'
  },
  buildingIcons: {
    industry: imgIndustry,
    supermarket: imgSupermarket,
    commercial: imgCommercial,
    residential: imgResidential,
    datacenter: imgDataCenter,
    ectogrid: imgEctogrid,
    battery: imgBattery,
    pvcell: imgPvCell,
    aquifer: imgAquifer
  }
};
