import Util from '../util';
import SquareButton from '../square_button';
import { TableRect } from 'js/Table/types';
import TableP5 from 'js/Table/TableP5';

/**
 * An image that is clickable
 * @class SquareButton
 */
class ComboSelectButton extends SquareButton {
  nr: number;
  defaultRect: TableRect;
  selected: boolean;

  constructor(nr, selected = false) {
    super();
    this.selected = selected;
    this.nr = nr;
  }

  setDefault(rect) {
    this.defaultRect = rect;
  }

  renderInit(_p5) {
    // Do nothing
  }

  draw(
    p5Instance: TableP5,
    overrideRect: TableRect,
    currentComboScreen: number
  ) {
    const defaultRect = this.defaultRect;

    try {
      p5Instance.push();
      let w = 32;
      let h = 32;

      // The priority order is
      // 1. if the value exist in the override use that
      // 2. else use the value set on the object
      // 3. use the calculated size (and 0 position)

      this.bounding.x =
        (overrideRect && overrideRect.x) || (defaultRect && defaultRect.x) || 0;
      this.bounding.y =
        (overrideRect && overrideRect.y) || (defaultRect && defaultRect.y) || 0;
      this.bounding.w =
        (overrideRect && overrideRect.width) ||
        (defaultRect && defaultRect.width) ||
        w;
      this.bounding.h =
        (overrideRect && overrideRect.height) ||
        (defaultRect && defaultRect.height) ||
        h;

      const txtX = this.bounding.x + this.bounding.w / 2;
      const txtY = this.bounding.y + this.bounding.h / 2;
      const thisSelectIsActvie = this.nr === currentComboScreen;

      if (thisSelectIsActvie) p5Instance.fill('#FFFFFF');
      else p5Instance.noFill();

      p5Instance.stroke('#FFFFFF');
      p5Instance.strokeWeight(2);
      p5Instance.square(this.bounding.x, this.bounding.y, this.bounding.w);

      if (thisSelectIsActvie) {
        p5Instance.fill('#444444');
        p5Instance.stroke('#444444');
      } else p5Instance.fill('#FFFFFF');

      p5Instance.strokeWeight(1);
      p5Instance.textSize(this.bounding.h * 0.6);
      p5Instance.textAlign(p5Instance.CENTER, p5Instance.CENTER);
      p5Instance.text(this.nr, txtX, txtY - 3);
      p5Instance.pop();

      return Util.SUCCESS;
    } catch (error) {
      console.error(error);
      return Util.NOOP;
    }
  }
}

export default ComboSelectButton;
