import TableP5 from 'js/Table/TableP5';
import SquareButton from './square_button';
import Util from './util';
import { circleNodeTextInset } from './StructureDrawUtils';

export default class SliderButton extends SquareButton {
  value: number;
  minValue: number;
  maxValue: number;
  onValueChanged: (newValue: number) => void;
  drawLeftStop = true;

  constructor(onValueChanged: (newValue: number) => void, value = 0.1) {
    super();
    this.value = value;
    this.bounding.x = 0;
    this.bounding.y = 0;
    this.bounding.w = 100;
    this.bounding.h = 34;
    this.maxValue = 1.0;
    this.minValue = 0.0;
    this.onValueChanged = onValueChanged;
  }

  mouseReleased(): boolean {
    const wasPressed = this.pressed;
    this.pressed = false;
    return wasPressed;
  }

  mouseDragged(x: number, _y: number): boolean {
    if (this.pressed) {
      this.value = Math.min(
        this.maxValue,
        Math.max(this.minValue, (x - this.bounding.x) / this.bounding.w)
      );
      this.onValueChanged(this.value);
      return true;
    }

    return false;
  }

  draw(p: TableP5) {
    p.push();

    if (this.drawLeftStop) {
      p.noStroke();
      p.fill('white');
      p.rect(
        this.bounding.x - circleNodeTextInset + 1,
        this.bounding.y,
        circleNodeTextInset,
        this.bounding.h
      );
    }

    // White line to the right hand side of the circle
    {
      const innerSliderWidth = this.value * this.bounding.w;

      p.noStroke();
      p.fill('#333');
      p.rect(
        this.bounding.x,
        this.bounding.y,
        this.bounding.w,
        this.bounding.h,
        0,
        0,
        0,
        0
      );
      p.fill('white');
      p.rect(
        this.bounding.x,
        this.bounding.y,
        innerSliderWidth,
        this.bounding.h,
        0,
        0,
        0,
        0
      );
    }

    p.pop();
    return Util.SUCCESS;
  }
}
