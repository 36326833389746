import React, { Component } from 'react';
import View from '../../components/View/View';
import Flex from '../../components/Flex/Flex';
import './AdminView.css';

type AdminViewProps = {
  data: any;
};

export default class AdminView extends Component<AdminViewProps> {
  renderSocketsInRoom(room) {
    const sockets = [];

    room.forEach((socket) => {
      sockets.push(
        <p key={socket.socketName}>
          <strong>{socket.role}</strong> - {socket.socketName}
        </p>
      );
    });
    return sockets;
  }

  renderRoom(roomName, room) {
    return (
      <div key={roomName}>
        <h2>Room: {roomName}</h2>
        {this.renderSocketsInRoom(room)}
      </div>
    );
  }

  renderRooms(data) {
    if (!data) return null;

    return Object.keys(data).map((roomName) =>
      this.renderRoom(roomName, data[roomName])
    );
  }

  render() {
    return (
      <View title="Administration">
        <Flex direction="column" className="AdminView__content__main">
          {this.renderRooms(this.props.data)}
        </Flex>
      </View>
    );
  }
}
