import { Highcharts } from 'ecto-common/lib/Highcharts/Highcharts';
import T from 'ecto-common/lib/lang/Language';
import _ from 'lodash';

type SeriesWithData = Highcharts.SeriesOptionsType & {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  data?: any[];
};

/**
 * Some of our series functions embed an additional property on the series
 * with the original data array. This is a workaround to retrieve the length
 * of that data array.
 */
export const extractDataLength = (options: Highcharts.SeriesOptionsType) => {
  const withData = options as SeriesWithData;
  if (withData?.data) {
    return withData?.data.length;
  }

  return 0;
};

export function styleLegendItems() {
  const elems = document.getElementsByClassName('highcharts-legend-item');

  for (let i = 0; i < elems.length; i++) {
    const elem = elems[i];
    const paths = elem.getElementsByTagName('path');
    for (let j = 0; j < paths.length; j++) {
      paths[j].setAttribute('stroke-width', 11 + '');
      paths[j].setAttribute('stroke-linecap', 'none');
    }
  }
}

type ShowChartStatusArgs = {
  hasError: boolean;
  chart: Highcharts.Chart;
  hasPointsOverflow: boolean;
  isLoading: boolean;
  config: Highcharts.Options;
  noSeriesText: string;
  noDataText?: string;
};

export function showChartStatus({
  hasError,
  chart,
  hasPointsOverflow,
  isLoading,
  config,
  noSeriesText,
  noDataText = T.powercontrol.dispatch.chart.nodata
}: ShowChartStatusArgs) {
  if (hasError) {
    chart.showLoading(T.powercontrol.dispatch.chart.loadingdata.failure);
  } else if (hasPointsOverflow) {
    chart.showLoading(T.graphs.pointsoverflow);
  } else if (isLoading) {
    chart.showLoading(T.common.loading);
  } else if (config.series.length === 0) {
    chart.showLoading(noSeriesText);
  } else if (_.find(config.series, (s) => extractDataLength(s) > 0) == null) {
    chart.showLoading(noDataText);
  } else {
    chart.hideLoading();
  }
}
