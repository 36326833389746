import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import T from 'ecto-common/lib/lang/Language';

export const isWhitespace = (input: string) => {
  return input.replace(/\s/g, '').length < 1;
};

export const isNullOrWhitespace = (input: string) => {
  if (typeof input === 'undefined' || input == null) {
    return true;
  }

  return isWhitespace(input);
};

const re = new RegExp(
  // eslint-disable-next-line no-control-regex
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
);

export const isEONKIDEMail = (email: string) => {
  return /^[A-Z]\d+@eon.com$/.test(email);
};

export const isEONKID = (kid: string) => {
  return /^[A-Z]\d+$/.test(kid);
};

export const isValidEmail = (email: string) =>
  re.test(String(_.trim(email)).toLowerCase());

export const isValidNumber = (number: string) => {
  return /^-?[0-9]+(\.)?[0-9]*$/.test(number);
};

export const isValidIP = (ip: string) => {
  return /^([1-9]|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])(\.(\d|[1-9]\d|1\d{2}|2[0-4]\d|25[0-5])){3}$/.test(
    ip
  );
};

export const parseStringToNumber = (number: string) => {
  return isValidNumber(number) ? parseFloat(number) : null;
};

export const Unit = Object.freeze({
  CELCIUS: '°C',
  BINARY: 'binary'
});

export const shouldShowUnit = (unit: string) => {
  return unit && unit !== 'binary' && unit !== 'None';
};

export const formatNumberUnit = (
  number: number,
  unit: string,
  numberOfDecimals = 2
) => {
  let numberString;

  if (number == null || isNaN(number)) {
    numberString = '-';
  } else {
    if (numberOfDecimals < 0) {
      numberString = _.round(number, numberOfDecimals).toLocaleString(
        moment.locale()
      );
    } else {
      numberString = number.toLocaleString(moment.locale(), {
        maximumFractionDigits: numberOfDecimals
      });
    }
  }

  if (shouldShowUnit(unit)) {
    return numberString + ' ' + unit;
  }

  return numberString;
};

export const formatNumberUnitNode = (
  number: number,
  unit: React.ReactNode,
  numberOfDecimals = 2
) => {
  return (
    <>
      {' '}
      {formatNumberUnit(number, null, numberOfDecimals)} {unit}{' '}
    </>
  );
};

export const formatNumber = (number: number, numberOfDecimals = 2) =>
  formatNumberUnit(number, null, numberOfDecimals);

export const capitalizeString = (s: string) => {
  if (typeof s !== 'string') {
    return '';
  }

  return s.charAt(0).toUpperCase() + s.slice(1);
};

/**
 * Match variables in a string where variable are defined as ${variable}.
 * Returns an array of variable names.
 * @param str
 * @returns {unknown[]|*[]}
 */
export const extractVariables = (str: string) => {
  if (str == null) {
    return [];
  }

  return Array.from(str.matchAll(/\${(.*?)}/gi)).map((v: string[]) => v[1]);
};

export const replaceVariables = (
  str: string,
  keyValues: Record<string, string>
) => {
  if (str == null) {
    return str;
  }
  let data = str.slice();
  for (const [key, value] of Object.entries(keyValues)) {
    data = data.replace(new RegExp(`\\$\{${key}}`, 'g'), value);
  }
  return data;
};

export const commaSeparateCollectionWithAnd = (
  translationsCollection: string[]
) => {
  const firstItems = _.initial(translationsCollection);
  const lastItem = _.last(translationsCollection);

  return _.join(
    _.compact([_.join(firstItems, ', '), lastItem]),
    ' ' + T.common.and + ' '
  );
};

/**
 * Search in collection for searchText and pick properties on each object in collection to search text in
 * @param collection Search in this collection
 * @param searchText Search for this text, case insensitive
 * @param iteratees array of iteratees to be used to get the object properties and compare them to searchText Default: _.identity
 */
export function searchByCaseInsensitive<CollectionType>(
  collection: CollectionType[],
  searchText: string,
  ...iteratees: Array<string | ((input: CollectionType) => string)>
) {
  // Use these transforms to pick object properties
  const transforms = _.isEmpty(iteratees)
    ? [_.identity]
    : _.map(iteratees, _.iteratee);

  const lowerCaseSearchText = _.toLower(searchText);
  return _.filter(collection, (item) =>
    // Find a property that has a substring of the search text
    _.some(
      transforms,
      (transform) =>
        _.toLower(transform(item)).indexOf(lowerCaseSearchText) !== -1
    )
  );
}

const countOccurences = (
  string: string,
  start: number,
  end: number,
  searchChar: string
) => {
  let numOccurences = 0;

  for (let i = start; i < end; i++) {
    if (string[i] === searchChar) {
      numOccurences++;
    }
  }

  return numOccurences;
};

/**
 * Parse a localized number to a float.
 * @param {string} stringNumber - the localized number
 * @param {string} locale - [optional] the locale that the number is represented in. Omit this parameter to use the current locale.
 */
export const parseLocaleNumber = (
  stringNumber: string | number,
  locale = moment.locale()
) => {
  if (_.isNumber(stringNumber)) {
    return stringNumber;
  }

  const thousandSeparator = Intl.NumberFormat(locale)
    .format(11111)
    .replace(/\p{Number}/gu, '');
  const decimalSeparator = Intl.NumberFormat(locale)
    .format(1.1)
    .replace(/\p{Number}/gu, '');

  return parseFloat(
    stringNumber
      .replace(new RegExp('\\' + thousandSeparator, 'g'), '')
      .replaceAll('−', '-')
      .replace(new RegExp('\\' + decimalSeparator), '.')
  );
};

/**
 * This method makes sure values entered into input fields are
 * formatted correctly. It tries to helpfully turn various different
 * decimal separators into the correct one for the locale (if it
 * can be done without ambiguity). It will also automatically add
 * group separators based on the locale while maintaining the
 * previous position of the text field cursor.
 *
 * @param value The string to format
 * @param inputField The input field responsible for the string
 * @param locale The locale to use. Will use moments locale by default.
 * @returns {string|*}
 * @constructor
 */
export const numberLocaleFormatter = (
  value: string,
  inputField: HTMLInputElement,
  locale = moment.locale()
) => {
  const formatter = new Intl.NumberFormat(locale);
  const parts = formatter.formatToParts(1000.1);
  const groupSeparator = _.find(parts, { type: 'group' }).value;
  const decimalSeparator = _.find(parts, { type: 'decimal' }).value;

  // Strip everything down to a float string
  let valueString = value;

  const selectionStart = inputField?.selectionStart ?? 0;
  const selectionEnd = inputField?.selectionEnd ?? 0;

  // If the group separator is whitespace, there is no chance of
  // uncertainty regarding if , is the decimal or group separator,
  // or . is. In this case, we can assume that any non-space separator
  // token can be translated into the correct one. This is to handle
  // the case where technical (programming) users are accustomed to
  // using . as comma separator even though their locale uses "," which
  // is common in Sweden. In this case we automatically translate into the
  // correct decimal separator.
  if (isNullOrWhitespace(groupSeparator)) {
    valueString = valueString.replaceAll(',', decimalSeparator);
    valueString = valueString.replaceAll('.', decimalSeparator);
    valueString = valueString.replaceAll('·', decimalSeparator);
  }

  valueString = valueString.replaceAll('−', '-');

  let decimalPart = '';
  const decimalPosition = valueString.indexOf(decimalSeparator);

  if (decimalPosition !== -1) {
    decimalPart = valueString.substring(decimalPosition);
    valueString = valueString.substring(0, decimalPosition);
  }

  valueString = valueString.replaceAll(/[^\-^−^\d]/g, '');
  const number = parseInt(valueString, 10);

  if (isNaN(number)) {
    return value;
  }

  // Maintain insertion position by calculating how many characters were inserted before
  // the start of the selection. Adjust selection range to account for this difference.
  let newValue = formatter.format(number) + decimalPart;

  const numGroupSeparatorsBefore = countOccurences(
    value,
    0,
    selectionStart,
    groupSeparator
  );
  const numGroupSeparatorsAfter = countOccurences(
    newValue,
    0,
    selectionStart,
    groupSeparator
  );
  const diff = numGroupSeparatorsAfter - numGroupSeparatorsBefore;

  _.defer(() => {
    inputField?.setSelectionRange(selectionStart + diff, selectionEnd + diff);
  });

  // Always use hyphen instead of minus sign regardless of locale
  newValue = newValue.replaceAll('−', '-');

  return newValue;
};
