/* eslint no-lone-blocks: 0 */ // --> OFF

import GlobalSettings from './Settings';

import SquareButton from './square_button';
import { AppContextType } from 'js/components/App/app_context';
import { TableRect } from 'js/Table/types';
import TableP5 from 'js/Table/TableP5';

const Settings = GlobalSettings.locationButton;

/* Possible states of a button, not all states are used */
const STATES = {
  ACTIVE: 0,
  HIDING: 1,
  HIDDEN: 2,
  DROPDOWNING: 3,
  DROPDOWN: 4,
  SELECTED: 6
};

/* These are the default values of a button, meant to be overwritten  */
const baseLayout = {
  width: 200,
  height: 30,
  opacity: 1,
  x: 0,
  y: 0,
  bgColor: null,
  textColor: 'white'
};

/**
 * A single button in the location selection menu
 * @class CountryButton
 */
class CountryButton extends SquareButton {
  context: AppContextType;
  place: any;
  listItem: any;
  state: number;
  touchEnded: (x: number, y: number) => void;
  highlighted = false;
  textWidth = 0;
  opacity = 255;

  constructor(context, place) {
    super();
    this.context = context;
    this.place = place;
    this.name = place.city + ', ' + place.country.toUpperCase();

    this.touchStarted = this.mousePressed;
    this.touchEnded = this.mouseReleased;

    this.state = STATES.ACTIVE;
  }

  draw(p5Instance: TableP5, rect?: TableRect) {
    this.bounding.x = rect.x;
    this.bounding.y = rect.y;
    this.bounding.w = rect.width;
    this.bounding.h = rect.height;

    const padding = 30;

    p5Instance.push();
    {
      p5Instance.textSize(Settings.fontSize);
      p5Instance.push();
      if (this.highlighted) {
        p5Instance.fill(255, 255, 255, this.opacity);
      } else {
        p5Instance.fill(0xbb, 0xbb, 0xbb, this.opacity);
      }

      {
        let textX = rect.x + rect.width - Settings.horisontalPadding;

        const text = this.place.city + ', ' + this.place.country;

        // Text indentation
        p5Instance.textAlign(p5Instance.RIGHT, p5Instance.CENTER);
        p5Instance.text(text, textX, rect.y + padding);
        this.textWidth = p5Instance.textWidth(text);
      }
      p5Instance.pop();
    }
    p5Instance.pop();
  }

  /**
   * Determines if x, y hit the general area of this button or not.
   */
  hit(x, y) {
    return super.hit(x, y);
  }

  select() {
    this.state = STATES.SELECTED;
  }

  unselect() {
    this.state = STATES.ACTIVE;
  }

  _tGetBaseLayout() {
    return baseLayout;
  }
}

export default CountryButton;
