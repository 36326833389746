/* eslint no-lone-blocks: 0 */ // --> OFF

import p5 from 'p5';
import Settings from './Settings';
import Structure from './Structure';

/**
 * This is used for buildings that doesn't have a fingerprint setting that
 * creates them.
 *
 * The buildings are primarily included in the Ectogrid.
 */
class Building extends Structure {
  relativePosition: { x: number; y: number };
  showAsDots: boolean;

  constructor(
    context,
    p5Instance,
    buildingTemplate,
    _parentPos,
    _city,
    scale,
    imageCache: Record<string, p5.Image>
  ) {
    super(context, p5Instance, buildingTemplate, 1, imageCache);

    let x = buildingTemplate.x * scale;
    let y = buildingTemplate.y * scale;

    this.relativePosition = { x, y };
    this.fixedSize = true;
    this.showAsDots = false;
  }

  hit() {
    return null;
  }

  parentMovedTo(parentCenter) {
    const newVector = this.p5Instance.createVector(
      this.relativePosition.x + parentCenter.x,
      this.relativePosition.y + parentCenter.y
    );

    this.centerPos = newVector;
    this.cache.center = newVector;
  }

  _drawText(p) {
    const center = this.center();

    p.push();
    {
      p.fill(Settings.structureFontColor);
      p.textFont(Settings.structureFont);
      p.textSize(Settings.structureHeaderFontSize);
      p.textAlign(p.CENTER, p.CENTER);

      p.text(this.name, center.x, center.y);
    }
    p.pop();
  }

  _drawChart(_) {}

  _drawMainDot(p) {
    const center = this.center();
    const mainRadius = this._mainRadius();

    // Main circle
    p.push();
    {
      p.noStroke();

      const energyRatio = this.node.getRatio();
      let endWarm; // = energyRatio ? energyRatio.heatRatio * Math.PI * 2: 0;

      //mainCircleColor.setAlpha(0.5 * Util.HEX_ONE);

      let primaryColor;
      let secondColor;

      if (energyRatio) {
        if (energyRatio.heatRatio === 1) {
          primaryColor = p.color(Settings.warmColor);
        } else if (energyRatio.heatRatio === 0) {
          primaryColor = p.color(Settings.coldColor);
        } else {
          primaryColor = p.color(Settings.warmColor);
          secondColor = p.color(Settings.coldColor);

          endWarm = energyRatio.heatRatio * Math.PI * 2;
        }
      } else {
        primaryColor = p.color('white');
      }

      if (!secondColor) {
        p.fill(primaryColor);
        p.arc(center.x, center.y, mainRadius, mainRadius, 0, Math.PI * 2);
      } else {
        p.fill(primaryColor);
        p.arc(center.x, center.y, mainRadius, mainRadius, 0, endWarm);

        p.fill(secondColor);
        p.arc(center.x, center.y, mainRadius, mainRadius, endWarm, Math.PI * 2);
      }
    }
    p.pop();
  }

  _drawMainShape(p) {
    if (!this.showAsDots) {
      return this._drawMainCircle(p);
    }

    return this._drawMainDot(p);
  }

  _drawCrust() {}

  _boundaryRadius() {
    return this.cache.boundaryRadius * 0.3;
  }

  _pipeInterSectRadius() {
    if (this.showAsDots) {
      // Because the dot is solid this just should be small to make the pipe intersect under the dot
      // It's a bit arbitrary and might need to be adjusted if the pipe size changes
      return this._mainRadius() / 3;
    }
    return this._mainRadius() / 2;
  }

  _mainRadius() {
    return this.cache.mainRadius * 0.3;
  }

  _mainThickness() {
    return this.cache.mainThickness * 0.5;
  }
}

export default Building;
