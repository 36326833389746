export const getNodePage = (tenantId: string, node: { nodeId: string }) => {
  return `/${tenantId}/home/${node.nodeId}/dashboard`;
};

export const getSignalProvidersPage = (
  tenantId: string,
  nodeId: string,
  equipmentTypeId: string
) => {
  const path = `/${tenantId}/home/${nodeId}/signalproviders`;

  if (equipmentTypeId) {
    return `${path}/${equipmentTypeId}`;
  }

  return path;
};

export const getAlarmsPage = (tenantId: string, nodeId: string) =>
  `/${tenantId}/home/${nodeId}/alarms/signalClusters`;
