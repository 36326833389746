import React from 'react';
import Button, { ButtonProps } from 'ecto-common/lib/Button/Button';
import Icons from 'ecto-common/lib/Icons/Icons';

const SaveButton = ({ children, ...otherProps }: ButtonProps) => {
  return (
    <Button {...otherProps}>
      <Icons.Save />
      {children}
    </Button>
  );
};

export default React.memo(SaveButton);
