import {
  InfiniteData,
  MutationOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query';
import queryString from 'query-string';
import {
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult
} from '@tanstack/react-query/src/types';
import { APIGenType } from 'ecto-common/lib/API/APIGenType';
import { getAPIFetch } from 'ecto-common/lib/utils/APIFetchInstance';
import { useContext } from 'react';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { APIFetchType } from 'ecto-common/lib/utils/APIFetchType';
import { getBackendSettings } from './BackendSettings';
import { featureFlagStore } from '../FeatureFlags/FeatureFlags';

export enum ContentType {
  Json = 'Json',
  Text = 'Text',
  Empty = 'Empty',
  Blob = 'Blob',
  FormData = 'FormData'
}

export type BackendSetting = {
  apiFetch?: APIFetchType;
  fetchOptions: object;
};

export type ApiContextSettings = {
  tenantId: string;
};

const getSettings = (apiType: APIGenType): BackendSetting => {
  const BackendSettings = getBackendSettings();

  return (
    BackendSettings[apiType] ?? {
      apiFetch: null,
      fetchOptions: {}
    }
  );
};

export const Method = {
  PATCH: 'PATCH',
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE'
};

const eIOTSignalPaths = [
  '/signals/values/timerange',
  '/signals/values/last',
  '/signals/values'
];

function jsonPromise<ReturnType, ArgsType, QueryArgsType>(
  contextSettings: ApiContextSettings,
  method: string,
  endpoint: string,
  apiType: APIGenType,
  requestContentType: ContentType,
  contentType: ContentType,
  _errorContentType: ContentType, // TODO: Add handling of this as well
  args: ArgsType,
  queryArgs: QueryArgsType,
  signal: AbortSignal
) {
  let _endpoint = endpoint.replace('/api/', '/');

  if (
    eIOTSignalPaths.includes(_endpoint) &&
    featureFlagStore.getSnapshot()['eiot-signals']
  ) {
    _endpoint = _endpoint.replace('/signals', '/eiot-signals');
  }

  let suffix = '';
  let body: string | FormData;

  if (queryArgs != null) {
    suffix = '?' + queryString.stringify(queryArgs);
  }

  if (args != null) {
    if (requestContentType === ContentType.FormData) {
      const formData = new FormData();

      // Type-unsafe, needed to index properties
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      const argsObject = args as Record<string, any>;

      for (const name of Object.keys(argsObject)) {
        formData.append(name, argsObject[name]);
      }

      body = formData;
    } else if (typeof args === 'string') {
      body = args;
    } else {
      body = JSON.stringify(args);
    }
  }

  const settings = getSettings(apiType);

  const headers: Record<string, string> = {};

  // Will be set automatically for form data if body is of type FormData
  if (requestContentType !== ContentType.FormData) {
    headers['Content-Type'] = 'application/json';
  }

  const options = {
    method,
    body,
    headers,
    signal
  };

  const apiFetch: APIFetchType = settings.apiFetch ?? getAPIFetch();

  const promise: Promise<ReturnType> = apiFetch(
    contextSettings,
    _endpoint + suffix,
    options,
    { ...settings.fetchOptions }
  ).then((response) => {
    switch (contentType) {
      case ContentType.Json:
        return response.json() as ReturnType;
      case ContentType.Text:
        return response.text() as ReturnType;
      case ContentType.Blob:
        return response.blob() as ReturnType;
      case ContentType.Empty:
        return Promise.resolve({} as ReturnType);
      default:
        return Promise.reject(null);
    }
  });

  return promise;
}

export function useInfiniteAPIQuery<ArgsType, ReturnType, ErrorType>(
  method: string,
  endpoint: string,
  promise: (
    contextSettings: ApiContextSettings,
    args: ArgsType,
    signal: AbortSignal
  ) => Promise<ReturnType>,
  options: Partial<UseInfiniteQueryOptions<ReturnType, ErrorType, ReturnType>>,
  query: ArgsType,
  pageParamKey: string
): UseInfiniteQueryResult<ReturnType, ErrorType> {
  const { contextSettings } = useContext(TenantContext);
  const queryKey = [contextSettings.tenantId, method, endpoint, query];

  const result = useInfiniteQuery<ReturnType, ErrorType, ReturnType>({
    queryKey,
    queryFn: (queryArgs) => {
      const { signal, pageParam } = queryArgs;
      return promise(
        contextSettings,
        { ...query, [pageParamKey]: pageParam },
        signal
      );
    },
    ...options,
    initialPageParam: 0,
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    getNextPageParam: (lastPage: any) => lastPage[pageParamKey]
  });

  return { ...result };
}

function useInfiniteAPIQueryWithQueryParams<
  ArgsType,
  QueryParamsType,
  ReturnType,
  ErrorType
>(
  method: string,
  endpoint: string,
  promise: (
    contextSettings: ApiContextSettings,
    args: ArgsType,
    queryParams: QueryParamsType,
    signal: AbortSignal
  ) => Promise<ReturnType>,

  options: Partial<
    UseInfiniteQueryOptions<
      ReturnType,
      ErrorType,
      InfiniteData<ReturnType>,
      ReturnType,
      (string | ArgsType | QueryParamsType)[],
      string
    >
  >,
  query: ArgsType,
  queryParams: QueryParamsType,
  pageParamKey: string
): UseInfiniteQueryResult<InfiniteData<ReturnType, unknown>, ErrorType> {
  const { contextSettings } = useContext(TenantContext);
  const queryKey = [
    contextSettings.tenantId,
    method,
    endpoint,
    query,
    queryParams
  ];

  return useInfiniteQuery({
    queryKey,
    queryFn: (queryArgs) => {
      const { signal, pageParam } = queryArgs;
      return promise(
        contextSettings,
        { ...query, [pageParamKey]: pageParam },
        { ...queryParams, [pageParamKey]: pageParam },
        signal
      );
    },
    ...options,
    initialPageParam: '',
    getNextPageParam: (lastPage: ReturnType) => {
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      return (lastPage as unknown as any)[pageParamKey] === ''
        ? null
        : /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
          ((lastPage as unknown as any)[pageParamKey] as string);
    }
  });
}

export function useAPIQuery<ArgsType, ReturnType, ErrorType>(
  method: string,
  endpoint: string,
  promise: (
    contextSettings: ApiContextSettings,
    args: ArgsType,
    signal: AbortSignal
  ) => Promise<ReturnType>,
  options: Partial<UseQueryOptions<ReturnType, ErrorType, ReturnType>>,
  query: ArgsType
): UseQueryResult<ReturnType, ErrorType> {
  const { contextSettings } = useContext(TenantContext);
  const queryKey = [contextSettings.tenantId, method, endpoint, query];

  return useQuery({
    queryKey: queryKey,

    queryFn: (queryArgs) => {
      const { signal } = queryArgs;
      return promise(contextSettings, query, signal);
    },

    ...options
  });
}

export function useAPIQueryWithQueryParams<
  ArgsType,
  QueryParamsType,
  ReturnType,
  ErrorType
>(
  method: string,
  endpoint: string,
  promise: (
    contextSettings: ApiContextSettings,
    args: ArgsType,
    queryParams: QueryParamsType,
    signal: AbortSignal
  ) => Promise<ReturnType>,
  options: Partial<UseQueryOptions<ReturnType, ErrorType, ReturnType>>,
  args: ArgsType,
  queryParams: QueryParamsType
): UseQueryResult<ReturnType, ErrorType> {
  const { contextSettings } = useContext(TenantContext);

  let queryKey: unknown[] = [contextSettings.tenantId, method, endpoint];

  if (args != null) {
    queryKey = [...queryKey, args];
  }
  if (queryParams != null) {
    queryKey = [...queryKey, queryParams];
  }

  return useQuery({
    queryKey: queryKey,

    queryFn: (queryArgs) => {
      const { signal } = queryArgs;
      return promise(contextSettings, args, queryParams, signal);
    },

    ...options
  });
}

export function apiEndpoint<ArgsType, ResponseType, ErrorType>(
  method: string,
  endpoint: string,
  apiType: APIGenType,
  requestContentType: ContentType,
  contentType: ContentType,
  errorContentType: ContentType
) {
  const promise: (
    contextSettings: ApiContextSettings,
    args: ArgsType,
    signal: AbortSignal
  ) => Promise<ResponseType> = (contextSettings, args, signal) =>
    jsonPromise(
      contextSettings,
      method,
      endpoint,
      apiType,
      requestContentType,
      contentType,
      errorContentType,
      args,
      null,
      signal
    );

  return {
    promise,
    path: (contextSettings: ApiContextSettings) => [
      contextSettings.tenantId,
      method,
      endpoint
    ],
    useInfiniteQuery: (
      query: ArgsType,
      options: Partial<
        UseInfiniteQueryOptions<ResponseType, ErrorType, ResponseType>
      > = {},
      pageParamKey = 'continuationToken'
    ) =>
      useInfiniteAPIQuery(
        method,
        endpoint,
        promise,
        options,
        query,
        pageParamKey
      ),
    useQuery: (
      query: ArgsType,
      options: Partial<
        UseQueryOptions<ResponseType, ErrorType, ResponseType>
      > = {}
    ) => useAPIQuery(method, endpoint, promise, options, query),
    useMutation: (
      mutationOptions: MutationOptions<ResponseType, ErrorType, ArgsType>
    ) => {
      const { contextSettings } = useContext(TenantContext);
      return useMutation({
        mutationFn: (args) => promise(contextSettings, args, null),
        ...mutationOptions
      });
    }
  };
}

export function apiEndpointUsingQueryParams<
  ArgsType,
  QueryParamsType,
  ResponseType,
  ErrorType
>(
  method: string,
  endpoint: string,
  apiType: APIGenType,
  requestContentType: ContentType,
  contentType: ContentType,
  errorContentType: ContentType
) {
  const promise: (
    contextSettings: ApiContextSettings,
    args: ArgsType,
    queryParams: QueryParamsType,
    signal: AbortSignal
  ) => Promise<ResponseType> = (contextSettings, args, queryParams, signal) =>
    jsonPromise(
      contextSettings,
      method,
      endpoint,
      apiType,
      requestContentType,
      contentType,
      errorContentType,
      args,
      queryParams,
      signal
    );

  return {
    promise,
    path: (contextSettings: ApiContextSettings) => [
      contextSettings.tenantId,
      method,
      endpoint
    ],
    useInfiniteQuery: (
      args: ArgsType,
      queryParams: QueryParamsType,
      options: Partial<
        UseInfiniteQueryOptions<
          ResponseType,
          ErrorType,
          InfiniteData<ResponseType>,
          ResponseType,
          (string | ArgsType | QueryParamsType)[],
          string
        >
      > = {},
      pageParamKey = 'continuationToken'
    ) =>
      useInfiniteAPIQueryWithQueryParams(
        method,
        endpoint,
        promise,
        options,
        args,
        queryParams,
        pageParamKey
      ),
    useQuery: (
      args: ArgsType,
      queryParams: QueryParamsType,
      options: Partial<
        UseQueryOptions<ResponseType, ErrorType, ResponseType>
      > = {}
    ) =>
      useAPIQueryWithQueryParams(
        method,
        endpoint,
        promise,
        options,
        args,
        queryParams
      ),
    useMutation: (
      queryParams: QueryParamsType,
      mutationOptions: MutationOptions<ResponseType, ErrorType, ArgsType>
    ) => {
      const { contextSettings } = useContext(TenantContext);
      return useMutation({
        mutationFn: (args) => promise(contextSettings, args, queryParams, null),
        ...mutationOptions
      });
    }
  };
}

export function apiEndpointEmpty<ResponseType, ErrorType>(
  method: string,
  endpoint: string,
  apiType: APIGenType,
  requestContentType: ContentType,
  contentType: ContentType,
  errorContentType: ContentType
) {
  const promise: (
    contextSettings: ApiContextSettings,
    signal: AbortSignal
  ) => Promise<ResponseType> = (contextSettings, signal) =>
    jsonPromise<ResponseType, unknown, unknown>(
      contextSettings,
      method,
      endpoint,
      apiType,
      requestContentType,
      contentType,
      errorContentType,
      null,
      null,
      signal
    );

  return {
    promise,
    path: (contextSettings: ApiContextSettings) => [
      contextSettings.tenantId,
      method,
      endpoint
    ],
    useQuery: (
      options: Partial<
        UseQueryOptions<ResponseType, ErrorType, ResponseType>
      > = {}
    ) => useAPIQuery(method, endpoint, promise, options, null),
    useMutation: (
      mutationOptions: MutationOptions<ResponseType, ErrorType>
    ) => {
      const { contextSettings } = useContext(TenantContext);
      return useMutation({
        mutationFn: () => promise(contextSettings, null),
        ...mutationOptions
      });
    }
  };
}

export function apiEndpointEmptyUsingQueryParams<
  QueryParamsType,
  ResponseType,
  ErrorType
>(
  method: string,
  endpoint: string,
  apiType: APIGenType,
  requestContentType: ContentType,
  contentType: ContentType,
  errorContentType: ContentType
) {
  const promise: (
    contextSettings: ApiContextSettings,
    queryParams: QueryParamsType,
    signal: AbortSignal
  ) => Promise<ResponseType> = (contextSettings, queryParams, signal) => {
    return jsonPromise(
      contextSettings,
      method,
      endpoint,
      apiType,
      requestContentType,
      contentType,
      errorContentType,
      null,
      queryParams,
      signal
    );
  };

  return {
    promise,
    path: (contextSettings: ApiContextSettings) => [
      contextSettings.tenantId,
      method,
      endpoint
    ],
    useQuery: (
      queryParams: QueryParamsType,
      options: Partial<
        UseQueryOptions<ResponseType, ErrorType, ResponseType>
      > = {}
    ) =>
      useAPIQueryWithQueryParams<
        unknown,
        QueryParamsType,
        ResponseType,
        ErrorType
      >(
        method,
        endpoint,
        (contextSettings, _unused, _queryParams, abortSignal) =>
          promise(contextSettings, queryParams, abortSignal),
        options,
        null,
        queryParams
      ),
    useMutation: (
      mutationOptions: MutationOptions<ResponseType, ErrorType, QueryParamsType>
    ) => {
      const { contextSettings } = useContext(TenantContext);
      return useMutation({
        mutationFn: (queryParams) =>
          promise(contextSettings, queryParams, null),
        ...mutationOptions
      });
    },
    useInfiniteQuery: (
      queryParams: QueryParamsType,
      options: Partial<
        UseInfiniteQueryOptions<
          ResponseType,
          ErrorType,
          InfiniteData<ResponseType>,
          ResponseType,
          (string | QueryParamsType)[],
          string
        >
      > = {},
      pageParamKey = 'continuationToken'
    ) =>
      useInfiniteAPIQueryWithQueryParams(
        method,
        endpoint,
        (
          contextSettings: ApiContextSettings,
          _unused: unknown,
          _queryParams: QueryParamsType,
          signal: AbortSignal
        ) => promise(contextSettings, _queryParams, signal),
        options,
        null,
        queryParams,
        pageParamKey
      )
  };
}

// ===================================================================================

export function apiEndpointWithPath<
  ArgsType,
  ResponseType,
  ErrorType,
  PathArgsType
>(
  method: string,
  endpoint: (pathArgs: PathArgsType) => string,
  apiType: APIGenType,
  requestContentType: ContentType,
  contentType: ContentType,
  errorContentType: ContentType
) {
  const promise: (
    contextSettings: ApiContextSettings,
    pathArgs: PathArgsType,
    args: ArgsType,
    signal: AbortSignal
  ) => Promise<ResponseType> = (contextSettings, pathArgs, args, signal) =>
    jsonPromise(
      contextSettings,
      method,
      endpoint(pathArgs),
      apiType,
      requestContentType,
      contentType,
      errorContentType,
      args,
      null,
      signal
    );

  return {
    promise,
    path: (contextSettings: ApiContextSettings, pathArgs: PathArgsType) => [
      contextSettings.tenantId,
      method,
      endpoint(pathArgs)
    ],
    useInfiniteQuery: (
      pathArgs: PathArgsType,
      args: ArgsType,
      options: Partial<
        UseInfiniteQueryOptions<ResponseType, ErrorType, ResponseType>
      > = {},
      pageParamKey = 'continuationToken'
    ) =>
      useInfiniteAPIQuery(
        method,
        endpoint(pathArgs),
        (contextSettings, _args: ArgsType, signal: AbortSignal) =>
          promise(contextSettings, pathArgs, args, signal),
        options,
        args,
        pageParamKey
      ),
    useQuery: (
      pathArgs: PathArgsType,
      query: ArgsType,
      options: Partial<
        UseQueryOptions<ResponseType, ErrorType, ResponseType>
      > = {}
    ) =>
      useAPIQuery(
        method,
        endpoint(pathArgs),
        (contextSettings, args: ArgsType, signal: AbortSignal) =>
          promise(contextSettings, pathArgs, args, signal),
        options,
        query
      ),
    useMutation: (
      pathArgs: PathArgsType,
      mutationOptions: MutationOptions<ResponseType, ErrorType, ArgsType>
    ) => {
      const { contextSettings } = useContext(TenantContext);
      return useMutation({
        mutationFn: (args) => promise(contextSettings, pathArgs, args, null),
        ...mutationOptions
      });
    }
  };
}

export function apiEndpointWithPathUsingQueryParams<
  ArgsType,
  QueryParamsType,
  ResponseType,
  ErrorType,
  PathArgsType
>(
  method: string,
  endpoint: (pathArgs: PathArgsType) => string,
  apiType: APIGenType,
  requestContentType: ContentType,
  contentType: ContentType,
  errorContentType: ContentType
) {
  const promise: (
    contextSettings: ApiContextSettings,
    pathArgs: PathArgsType,
    args: ArgsType,
    queryParams: QueryParamsType,
    signal: AbortSignal
  ) => Promise<ResponseType> = (
    contextSettings,
    pathArgs,
    args,
    queryParams,
    signal
  ) =>
    jsonPromise(
      contextSettings,
      method,
      endpoint(pathArgs),
      apiType,
      requestContentType,
      contentType,
      errorContentType,
      args,
      queryParams,
      signal
    );

  return {
    promise,
    path: (contextSettings: ApiContextSettings, pathArgs: PathArgsType) => [
      contextSettings.tenantId,
      method,
      endpoint(pathArgs)
    ],
    useInfiniteQuery: (
      pathArgs: PathArgsType,
      args: ArgsType,
      queryParams: QueryParamsType,
      options: Partial<
        UseInfiniteQueryOptions<
          ResponseType,
          ErrorType,
          InfiniteData<ResponseType>,
          ResponseType,
          (string | ArgsType | QueryParamsType)[],
          string
        >
      > = {},
      pageParamKey = 'continuationToken'
    ) =>
      useInfiniteAPIQueryWithQueryParams(
        method,
        endpoint(pathArgs),
        (
          contextSettings,
          _args: ArgsType,
          _queryParams: QueryParamsType,
          signal: AbortSignal
        ) => promise(contextSettings, pathArgs, args, queryParams, signal),
        options,
        args,
        queryParams,
        pageParamKey
      ),
    useQuery: (
      pathArgs: PathArgsType,
      args: ArgsType,
      queryParams: QueryParamsType,
      options: Partial<
        UseQueryOptions<ResponseType, ErrorType, ResponseType>
      > = {}
    ) =>
      useAPIQueryWithQueryParams(
        method,
        endpoint(pathArgs),
        (
          contextSettings,
          _args: ArgsType,
          _queryParams: QueryParamsType,
          signal: AbortSignal
        ) => promise(contextSettings, pathArgs, args, queryParams, signal),
        options,
        args,
        queryParams
      ),
    useMutation: (
      pathArgs: PathArgsType,
      queryParams: QueryParamsType,
      mutationOptions: MutationOptions<ResponseType, ErrorType, ArgsType>
    ) => {
      const { contextSettings } = useContext(TenantContext);
      return useMutation({
        mutationFn: (args) =>
          promise(contextSettings, pathArgs, args, queryParams, null),
        ...mutationOptions
      });
    }
  };
}

export function apiEndpointEmptyWithPath<ResponseType, ErrorType, PathArgsType>(
  method: string,
  endpoint: (pathArgs: PathArgsType) => string,
  apiType: APIGenType,
  requestContentType: ContentType,
  contentType: ContentType,
  errorContentType: ContentType
) {
  const promise: (
    contextSettings: ApiContextSettings,
    pathArgs: PathArgsType,
    signal: AbortSignal
  ) => Promise<ResponseType> = (contextSettings, pathArgs, signal) =>
    jsonPromise(
      contextSettings,
      method,
      endpoint(pathArgs),
      apiType,
      requestContentType,
      contentType,
      errorContentType,
      null,
      null,
      signal
    );

  return {
    promise,
    path: (contextSettings: ApiContextSettings, pathArgs: PathArgsType) => [
      contextSettings.tenantId,
      method,
      endpoint(pathArgs)
    ],
    useQuery: (
      pathArgs: PathArgsType,
      options: Partial<
        UseQueryOptions<ResponseType, ErrorType, ResponseType>
      > = {}
    ) =>
      useAPIQuery(
        method,
        endpoint(pathArgs),
        (contextSettings, _unused: unknown, signal: AbortSignal) =>
          promise(contextSettings, pathArgs, signal),
        options,
        null
      ),
    useMutation: (
      mutationOptions: MutationOptions<
        ResponseType,
        ErrorType,
        PathArgsType
      > = {}
    ) => {
      const { contextSettings } = useContext(TenantContext);
      return useMutation({
        mutationFn: (pathArgs: PathArgsType) =>
          promise(contextSettings, pathArgs, null),
        ...mutationOptions
      });
    }
  };
}

export function apiEndpointEmptyWithPathUsingQueryParams<
  QueryParamsType,
  ResponseType,
  ErrorType,
  PathArgsType
>(
  method: string,
  endpoint: (pathArgs: PathArgsType) => string,
  apiType: APIGenType,
  requestContentType: ContentType,
  contentType: ContentType,
  errorContentType: ContentType
) {
  const promise: (
    contextSettings: ApiContextSettings,
    pathArgs: PathArgsType,
    queryParams: QueryParamsType,
    signal: AbortSignal
  ) => Promise<ResponseType> = (
    contextSettings,
    pathArgs,
    queryParams,
    signal
  ) => {
    return jsonPromise(
      contextSettings,
      method,
      endpoint(pathArgs),
      apiType,
      requestContentType,
      contentType,
      errorContentType,
      null,
      queryParams,
      signal
    );
  };

  return {
    promise,
    path: (contextSettings: ApiContextSettings, pathArgs: PathArgsType) => [
      contextSettings.tenantId,
      method,
      endpoint(pathArgs)
    ],
    useQuery: (
      pathArgs: PathArgsType,
      queryParams: QueryParamsType,
      options: Partial<
        UseQueryOptions<ResponseType, ErrorType, ResponseType>
      > = {}
    ) =>
      useAPIQueryWithQueryParams(
        method,
        endpoint(pathArgs),
        (
          contextSettings,
          _unused: unknown,
          _queryParams: QueryParamsType,
          signal
        ) => promise(contextSettings, pathArgs, queryParams, signal),
        options,
        null,
        queryParams
      ),
    useInfiniteQuery: (
      pathArgs: PathArgsType,
      queryParams: QueryParamsType,
      options: Partial<
        UseInfiniteQueryOptions<
          ResponseType,
          ErrorType,
          InfiniteData<ResponseType>,
          ResponseType,
          (string | PathArgsType | QueryParamsType)[],
          string
        >
      > = {},
      pageParamKey = 'continuationToken'
    ) =>
      useInfiniteAPIQueryWithQueryParams(
        method,
        endpoint(pathArgs),
        (
          contextSettings,
          _unused: unknown,
          _queryParams: QueryParamsType,
          signal
        ) => promise(contextSettings, pathArgs, _queryParams, signal),
        options,
        null,
        queryParams,
        pageParamKey
      ),
    useMutation: (
      pathArgs: PathArgsType,
      queryParams: QueryParamsType,
      mutationOptions: MutationOptions<ResponseType, ErrorType> = {}
    ) => {
      const { contextSettings } = useContext(TenantContext);
      return useMutation({
        mutationFn: () => promise(contextSettings, pathArgs, queryParams, null),
        ...mutationOptions
      });
    }
  };
}
