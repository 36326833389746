import React from 'react';
import { KeyValueGeneric } from 'ecto-common/lib/KeyValueInput/KeyValueGeneric';
import { ModelEditorProps } from 'ecto-common/lib/ModelForm/ModelEditor';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import { ModelDefinitionInternal } from 'ecto-common/lib/ModelForm/ModelPropType';
import DateInput from 'ecto-common/lib/DateInput/DateInput';
import { Moment } from 'moment';

export type DateModelDefinition<
  ObjectType extends object,
  EnvironmentType extends object = object
> = {
  modelType: typeof ModelType.DATE;
  withDivider?: boolean;
  isValidDate?: (date: Moment) => boolean;
  withTime?: boolean;
} & ModelDefinitionInternal<ObjectType, EnvironmentType, string>;

type ModelEditorDateProps<
  ObjectType extends object,
  EnvironmentType extends object
> = ModelEditorProps<EnvironmentType, string> & {
  model: DateModelDefinition<ObjectType>;
};

const ModelEditorDate = <
  ObjectType extends object,
  EnvironmentType extends object
>({
  model,
  rawValue,
  updateItem,
  disabled,
  hasError,
  helpText = null,
  useTooltipHelpTexts = false,
  isHorizontal
}: ModelEditorDateProps<ObjectType, EnvironmentType>) => {
  const _onDateChanged = (date: Moment) => {
    updateItem(date?.toISOString());
  };

  return (
    <KeyValueGeneric
      keyText={model.label}
      isHorizontal={isHorizontal}
      horizontalWeights={model.horizontalWeights}
      withDivider={model.withDivider}
      helpText={helpText}
      useTooltipHelpTexts={useTooltipHelpTexts}
    >
      <DateInput
        enabled={!disabled}
        wrapContent
        compact={false}
        expandingWidth
        hasError={hasError}
        onChange={_onDateChanged}
        isValidDate={model.isValidDate}
        timeFormat={model.withTime}
        value={rawValue}
      />
    </KeyValueGeneric>
  );
};

export default React.memo(ModelEditorDate);
