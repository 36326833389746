import React from 'react';
import styles from './ToolbarLocationPicker.module.css';
import classNames from 'classnames';
import Icons from 'ecto-common/lib/Icons/Icons';

const ToolbarNavControl = ({
  icon,
  title,
  subtitle,
  isOpen,
  setIsOpen,
  largeIcon = false
}: {
  icon: React.ReactNode;
  title: React.ReactNode;
  subtitle: React.ReactNode;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  largeIcon?: boolean;
}) => {
  return (
    <div
      className={classNames(
        isOpen && styles.open,
        styles.buildingInfoPicker,
        styles.buildingInfo
      )}
      onClick={() => setIsOpen(!isOpen)}
    >
      <div className={styles.title}>
        <div className={classNames(styles.icon, largeIcon && styles.large)}>
          {icon}
        </div>
        {title}
        <Icons.MenuRightArrow
          size="lg"
          className={classNames(
            styles.locationArrowIcon,
            isOpen && styles.open
          )}
        />
      </div>
      <div className={styles.subtitle}>{subtitle}</div>
    </div>
  );
};

export default React.memo(ToolbarNavControl);
