import NodeDataSource, { nodeDataSourceSections } from './NodeDataSource';
import SignalValuesDataSource, {
  signalValuesDataSourceSections
} from './SignalValuesDataSource';
import SignalProvidersDataSource, {
  signalProvidersDataSourceSections
} from './SignalProvidersDataSource';
import SignalTimeSeriesDataSource, {
  signalTimeSeriesDataSourceSections
} from './SignalTimeSeriesDataSource';
import NodesDataSource, { nodesDataSourceSections } from './NodesDataSource';
import LastSignalValuesDataSource, {
  lastSignalValuesDataSourceSections
} from './LastSignalValuesDataSource';

import DataSourceTypes from './DataSourceTypes';
import { ModelFormSectionType } from 'ecto-common/lib/ModelForm/ModelPropType';

type DataSourceDefinition = {
  implementation: (args: unknown) => unknown;
  sections: (args: unknown) => ModelFormSectionType<object, object>[];
};

export const AllDataSources: Record<string, DataSourceDefinition> = {
  [DataSourceTypes.NODE]: {
    implementation: NodeDataSource,
    sections: nodeDataSourceSections
  },
  [DataSourceTypes.NODES]: {
    implementation: NodesDataSource,
    sections: nodesDataSourceSections
  },
  [DataSourceTypes.SIGNALS]: {
    implementation: SignalValuesDataSource,
    sections: signalValuesDataSourceSections
  },
  [DataSourceTypes.SIGNALS_LAST_VALUE]: {
    implementation: LastSignalValuesDataSource,
    sections: lastSignalValuesDataSourceSections
  },
  [DataSourceTypes.OBSOLETE_EQUIPMENTS]: {
    implementation: SignalProvidersDataSource,
    sections: signalProvidersDataSourceSections
  },
  [DataSourceTypes.SIGNAL_PROVIDERS]: {
    implementation: SignalProvidersDataSource,
    sections: signalProvidersDataSourceSections
  },
  [DataSourceTypes.SIGNAL_TIME_SERIES]: {
    implementation: SignalTimeSeriesDataSource,
    sections: signalTimeSeriesDataSourceSections
  }
};
