import React, { useCallback } from 'react';
import { KeyValueInput } from 'ecto-common/lib/KeyValueInput/KeyValueInput';
import { ModelEditorProps } from 'ecto-common/lib/ModelForm/ModelEditor';
import { ModelDefinitionInternal } from 'ecto-common/lib/ModelForm/ModelPropType';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';

export type TextModelDefinition<
  ObjectType extends object,
  EnvironmentType extends object = object
> = {
  modelType: typeof ModelType.TEXT;
  autoFocus?: boolean;
  rows?: number;
  multiline?: boolean;
} & ModelDefinitionInternal<ObjectType, EnvironmentType, string>;

export type DisabledTextModelDefinition<
  ObjectType extends object,
  EnvironmentType extends object = object
> = Omit<TextModelDefinition<ObjectType, EnvironmentType>, 'modelType'> & {
  modelType: typeof ModelType.DISABLED_TEXT;
  dataFormatter?: (
    rawValue: string | number,
    input: ObjectType
  ) => React.ReactNode;
};

type ModelEditorTextProps = ModelEditorProps & {
  model:
    | TextModelDefinition<object, object>
    | DisabledTextModelDefinition<object, object>;
};

const ModelEditorText = ({
  model,
  updateItem,
  disabled,
  hasError,
  helpText = null,
  useTooltipHelpTexts = false,
  isHorizontal = false,
  rawValue
}: ModelEditorTextProps) => {
  const onChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      updateItem(event.target.value);
    },
    [updateItem]
  );

  return (
    <KeyValueInput
      keyText={model.label}
      help={helpText}
      useTooltipHelpTexts={useTooltipHelpTexts}
      rows={model.rows}
      multiline={model.multiline}
      disabled={disabled}
      value={rawValue}
      onChange={onChange}
      placeholder={model.placeholder}
      hasError={hasError}
      autoFocus={model.autoFocus}
      isHorizontal={isHorizontal}
      horizontalWeights={model.horizontalWeights}
    />
  );
};

export default React.memo(ModelEditorText);
