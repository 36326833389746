import React, { memo } from 'react';
import T from 'ecto-common/lib/lang/Language';
import styles from './NoDataMessage.module.css';
import Icons from 'ecto-common/lib/Icons/Icons';
import colors from 'ecto-common/lib/styles/variables/colors';

import Heading from 'ecto-common/lib/Heading/Heading';

export const NoDataMessageTextOnlyHeight = 20;
export const NoDataMessageHeight = 237;

interface NoDataMessageProps {
  /**
   * A string with that will be represented as a title header in the component
   */
  title?: string;
  /**
   * a string with a translated message
   */
  message?: React.ReactNode;
  /**
   * If the message should be shown or not
   */
  visible?: boolean;
  /**
   * If we should render the text without icon and title
   */
  textOnly?: boolean;
}

const NoDataMessage = ({
  title = T.common.information,
  message = T.common.datatable.nodata,
  visible = true,
  textOnly = false
}: NoDataMessageProps) => {
  if (!visible) {
    return null;
  }

  if (textOnly) {
    return (
      <div style={{ height: NoDataMessageTextOnlyHeight + 'px' }}>
        {message}
      </div>
    );
  }

  return (
    <div
      className={styles.noDataMessage}
      style={{ minHeight: NoDataMessageHeight + 'px' }}
    >
      <div>
        <Icons.InfoCircle
          size={'6x'}
          color={colors.accent5Color}
          className={styles.noDataMessageIcon}
        />
      </div>

      <Heading level={1} className={styles.noDataMessageTitle}>
        {title}
      </Heading>

      <Heading level={5} className={styles.noDataMessageMessage}>
        {message}
      </Heading>
    </div>
  );
};

export default memo(NoDataMessage);
