import React, { useCallback, useMemo } from 'react';
import styles from './RadioButton.module.css';
import classNames from 'classnames';

interface RadioButtonProps {
  /**
   * Called whenever the state of the checkbox changes. First argument is checked state.
   */
  onChange?(checked: boolean): void;
  /**
   * Checkbox label.
   */
  children?: React.ReactNode;
  /**
   * Whether or not the Checkbox is checked.
   */
  checked?: boolean;
  /**
   *  Gives the checkbox a disabled appearance. onChange will not be triggered if this is set.
   */
  disabled?: boolean;
  /**
   * Used to override the appearance of the checkbox. Should be a valid CSS class name.
   */
  className?: string;
}

const RadioButton = ({
  disabled,
  children,
  onChange,
  checked,
  className
}: RadioButtonProps) => {
  const constrainSize =
    children == null || React.Children.count(children) === 0;
  const onClick = useCallback(() => {
    if (disabled) {
      return;
    }
    onChange?.(true);
  }, [disabled, onChange]);

  const containerClassName = useMemo(
    () =>
      classNames(
        styles.radiobutton,
        disabled && styles.disabled,
        constrainSize && styles.constrainSize,
        className
      ),
    [constrainSize, disabled, className]
  );
  const radioCircleClassName = useMemo(
    () => classNames(styles.radiobuttonCircle, children && styles.marginRight),
    [children]
  );
  const innerCircleClassName = useMemo(
    () => classNames(styles.innerCircle, checked && styles.visibleCircle),
    [checked]
  );

  return (
    <div className={containerClassName} onClick={onClick}>
      <div className={radioCircleClassName}>
        <div className={innerCircleClassName} />
      </div>
      {children}
    </div>
  );
};

export default React.memo(RadioButton);
