import React from 'react';
import styles from 'ecto-common/lib/SignalSelector/SignalSelectorGeneric.module.css';
import TableColumn from 'ecto-common/lib/TableColumn/TableColumn';
import { SamplingIntervalText } from 'ecto-common/lib/types/SamplingInterval';
import { AggregationText } from 'ecto-common/lib/types/Aggregation';
import T from 'ecto-common/lib/lang/Language';
import { AggregationType } from 'ecto-common/lib/API/APIGen';

interface SignalItemCellProps {
  title: string;
  name: string;
  color?: string;
  samplingInterval?: string;
  aggregation?: AggregationType;
  showAggregation?: boolean;
}

const SignalItemCell = ({
  color,
  title,
  name,
  samplingInterval = null,
  aggregation = null,
  showAggregation
}: SignalItemCellProps) => {
  let aggregationText = null;

  if (showAggregation) {
    if (samplingInterval != null && aggregation != null) {
      aggregationText = T.format(
        T.graphs.aggregationformat,
        SamplingIntervalText[samplingInterval] +
          ', ' +
          AggregationText[aggregation]
      );
    } else {
      aggregationText = T.format(
        T.graphs.aggregationformat,
        T.graphs.settingplaceholder
      );
    }
  }

  return (
    <TableColumn
      icon={
        color != null && (
          <div className={styles.colorBar} style={{ background: color }} />
        )
      }
      title={title}
      minHeight={0}
      truncateText={false}
      subtitle={
        <>
          {name} {showAggregation && <br />}
          {aggregationText}
        </>
      }
    />
  );
};

export default SignalItemCell;
