import React, {
  memo,
  MouseEventHandler,
  ChangeEventHandler,
  KeyboardEventHandler,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  FocusEventHandler
} from 'react';
import styles from './DateTextInput.module.css';
import classNames from 'classnames';

import TextInput from 'ecto-common/lib/TextInput/TextInput';
import Icons from 'ecto-common/lib/Icons/Icons';

interface DateTextInputProps {
  className?: string;
  textFieldClassName?: string;
  label?: string;
  openCalendar?(): void;
  closeCalendar?(): void;
  enabled?: boolean;
  value?: string;
  placeholder?: string;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  hasFocus?: boolean;
  expandingWidth?: boolean;
  setPendingInput?: React.Dispatch<SetStateAction<string>>;
  compact?: boolean;
  clearButton?: boolean;
  onClear?: MouseEventHandler<unknown>;
}

const DateTextInput = ({
  className,
  textFieldClassName = null,
  openCalendar,
  closeCalendar,
  enabled,
  value,
  label,
  placeholder,
  onFocus,
  hasFocus,
  setPendingInput,
  onClear,
  compact = true,
  expandingWidth = false,
  clearButton = false
}: DateTextInputProps) => {
  const dateRef = useRef(null);

  // Workaround for react-datetime tab detection not working 100%
  // Close calendar if focus changes.
  useEffect(() => {
    const listener = (e: FocusEvent) => {
      if (
        e.target !== dateRef.current &&
        !(e.target as HTMLElement).contains?.(dateRef.current)
      ) {
        dateRef.current?.blur();
        closeCalendar();
      }
    };

    document.addEventListener('focusin', listener);

    return () => {
      document.removeEventListener('focusin', listener);
    };
  }, [closeCalendar]);

  const onChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setPendingInput(e.target.value);
    },
    [setPendingInput]
  );

  const onClickRightSideIcon = useCallback(() => {
    if (hasFocus) {
      closeCalendar();
    } else {
      openCalendar();
    }
  }, [hasFocus, openCalendar, closeCalendar]);

  const onConfirmKeyPressed: KeyboardEventHandler<HTMLInputElement> =
    useCallback(
      (e) => {
        (e.target as HTMLElement).blur?.();
        closeCalendar();
      },
      [closeCalendar]
    );

  const _onClear = useCallback(
    (e: React.MouseEvent<HTMLInputElement | SVGSVGElement>) => {
      onClear(e);
      closeCalendar();
    },
    [closeCalendar, onClear]
  );

  const _onFocus: FocusEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      openCalendar();
      onFocus(e);
    },
    [onFocus, openCalendar]
  );
  return (
    <div
      className={classNames(
        styles.container,
        expandingWidth && styles.expandingWidth,
        className
      )}
    >
      {label && (
        <label className={styles.dateLabel} onClick={openCalendar}>
          {label}
        </label>
      )}

      <TextInput
        autoFocus={hasFocus}
        wrapperClassName={styles.dateInput}
        className={classNames(
          label != null && styles.noPadding,
          compact && styles.compact,
          textFieldClassName
        )}
        value={value}
        rightSideIcon={<Icons.MenuDownArrow />}
        onEnterKeyPressed={onConfirmKeyPressed}
        onTabKeyPressed={onConfirmKeyPressed}
        onChange={onChange}
        onFocus={_onFocus}
        onClickRightSideIcon={onClickRightSideIcon}
        onClick={openCalendar}
        placeholder={placeholder}
        disabled={!enabled}
        clearButton={clearButton}
        onClear={_onClear}
        ref={dateRef}
      />
    </div>
  );
};

export default memo(DateTextInput);
