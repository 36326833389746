import React from 'react';

import T from 'ecto-common/lib/lang/Language';
import Icons from 'ecto-common/lib/Icons/Icons';
import StandardButton, {
  StandardButtonProps
} from 'ecto-common/lib/Button/StandardButton';

type DeleteButtonProps = StandardButtonProps & {
  tooltipText?: string;
};

const DeleteButton = ({ tooltipText = null, ...props }: DeleteButtonProps) => (
  <StandardButton
    destructive
    {...props}
    icon={<Icons.Delete />}
    tooltipText={tooltipText || T.common.delete}
  />
);

export default DeleteButton;
