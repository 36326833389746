import _ from 'lodash';
import { evaluateModelFormProperty } from 'ecto-common/lib/ModelForm/ModelEditor';
import {
  getPathStringFromModelKeyFunc,
  modelFormSectionsToModels
} from 'ecto-common/lib/ModelForm/formUtils';
import {
  BaseModelDefinition,
  BaseModelSectionType
} from 'ecto-common/lib/ModelForm/ModelPropType';

/**
 * Validates a specific key in form
 * Returns dictionary with form keys set to true/false whether they are valid
 */
function validateFormModelKey({
  models,
  values,
  key,
  environment
}: {
  models: Record<string, BaseModelDefinition>;
  values: object;
  key: string;
  environment: object;
}) {
  const model = _.get(models, key);

  const errorText = evaluateModelFormProperty(
    model.errorText,
    _.get(values, key),
    values,
    environment,
    null,
    model
  );
  const hasError = evaluateModelFormProperty(
    model.hasError,
    _.get(values, key),
    values,
    environment,
    false,
    model
  );

  return {
    [key]: !hasError && errorText == null
  };
}

/**
 * Validates all keys in form
 * @param formValidation
 * @param values
 * @returns {Object} with isValid and the form keys 'validKeyValues' set to true/false whether they are valid
 * For example: { isValid: false, { name: false, otherInput: true } }
 */
export function validateFormModel({
  models,
  values,
  environment
}: {
  models: Record<string, BaseModelDefinition>;
  values: object;
  environment: object;
}) {
  // Validate all keys that needs validation
  const validKeyValues = _.reduce(
    models,
    (result, { key }) => {
      const keyStr = getPathStringFromModelKeyFunc(key);
      return {
        ...result,
        ...validateFormModelKey({ models, values, key: keyStr, environment })
      };
    },
    {}
  );

  const isValid =
    _.find(validKeyValues, (value) => value === false) === undefined;
  return { isValid, validKeyValues };
}

export const modelFormSectionsAreValid = (
  sections: BaseModelSectionType[],
  input: object,
  environment: object
) => modelFormIsValid(modelFormSectionsToModels(sections), input, environment);

export function modelFormIsValid(
  models: BaseModelDefinition[],
  input: object,
  environment: object = null
) {
  const keyedModels = _.keyBy(models, (model) => {
    const pathStr = getPathStringFromModelKeyFunc(model.key);

    return pathStr;
  });

  return validateFormModel({ models: keyedModels, values: input, environment })
    .isValid;
}
