import React from 'react';
import classNames from 'classnames';
import styles from './Notice.module.css';
import Heading from 'ecto-common/lib/Heading/Heading';
import T from 'ecto-common/lib/lang/Language';

export interface NoticeProps {
  /**
   * The content of the notice
   */
  children: React.ReactNode;
  /**
   * Used to override the appearance of the notice. Should be a valid CSS class name.
   */
  className?: string;
  /**
   * Whether to show a "Information" header before the message
   */
  showHeader?: boolean;
}

/**
 * A notice is used to signal that something requires the users attention.
 */
const Notice = ({
  className = null,
  children = null,
  showHeader = false,
  ...otherProps
}: NoticeProps) => {
  return (
    <div className={classNames(styles.notice, className)} {...otherProps}>
      {showHeader && <Heading level={3}>{T.common.information}</Heading>}
      {children}
    </div>
  );
};

export default React.memo(Notice);
