import React, {
  useRef,
  useContext,
  useEffect,
  useState,
  useReducer
} from 'react';
import { comboViewMessageTypes } from '../../assets/combo_view';
import View from '../../components/View/View';
import { ROLES } from '../../components/App/app_util';
import './InfoView.css';
import AppContext from 'js/components/App/app_context';
import EctotableClientAPIGen, {
  ScreenContentType
} from 'ecto-common/lib/API/EctotableClientAPIGen';
import Spinner from 'ecto-common/lib/Spinner/Spinner';
import _ from 'lodash';
import classNames from 'classnames';
import ReactMarkdown from 'react-markdown';
import ReactDOM from 'react-dom';
import SocketMessages from '../../../../server/socket-messages';
import STATES from '../../config/states';
import infoIcon from '../../assets/info.svg';
import { usePopper } from 'react-popper';
import dimensions from 'ecto-common/lib/styles/dimensions';

const FullScreenControls = ({ socket, roomData, screenContentId }) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [isOpen, toggleIsOpen] = useReducer((state) => {
    return !state;
  }, false);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'top-end',
    modifiers: [
      {
        name: 'preventOverflow',
        options: {
          mainAxis: false
        }
      },
      {
        name: 'offset',
        options: {
          offset: [0, dimensions.smallMargin]
        }
      }
    ]
  });

  const onClick = (content) => {
    if (content.id === screenContentId) {
      socket.emit(SocketMessages.TABLE_STATE, {
        mainState: STATES.ACTIVE.name,
        fromTableApp: false
      });
    } else {
      socket.emit(SocketMessages.TABLE_STATE, {
        mainState: STATES.INFO.name,
        subState: content.id,
        fromTableApp: false
      });
    }

    toggleIsOpen();
  };

  return (
    <div className="InfoViewFullscreenControls">
      <div
        className={classNames(
          'InfoViewFullscreenControls__menu',
          isOpen && 'open'
        )}
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
      >
        {_.map(roomData.screenContents, (screenContent, index) => (
          <div
            className={classNames(
              'InfoViewFullscreenControls__menu__item',
              screenContentId === screenContent.id && 'selected'
            )}
            key={index}
            onClick={() => onClick(screenContent)}
          >
            • {screenContent.name}
          </div>
        ))}
      </div>
      <button
        onClick={toggleIsOpen}
        className={isOpen && 'open'}
        ref={setReferenceElement}
      >
        <img src={infoIcon} />
      </button>
    </div>
  );
};

/** Manages the view from when the table is in "Advertisement mode", showing logos or other screens. */
const InfoView = ({ role, socket, screenContentId }) => {
  const [comboScreen, setComboScreen] = useState(0);
  const roleName = role.roleName;
  const isComboView = roleName === ROLES.combo.roleName;

  const { room } = useContext(AppContext);

  const roomQuery = EctotableClientAPIGen.Room.getRoomConfiguration.useQuery({
    id: room
  });
  const screenContentCollection =
    _.find(
      roomQuery.data?.screenContents,
      (screenContent) => screenContent.id === screenContentId
    ) ?? _.head(roomQuery.data?.screenContents);
  const allScreens = [
    screenContentCollection?.screen1,
    screenContentCollection?.screen2,
    screenContentCollection?.screen3
  ];

  let screen = null;
  let renderFullscreen = false;

  if (screenContentCollection?.fullWallScreen != null) {
    if (roleName === ROLES.production.roleName) {
      screen = screenContentCollection.fullWallScreen;
      renderFullscreen = true;
    }
  } else {
    if (roleName === ROLES.combo.roleName) {
      screen = allScreens[comboScreen];
    } else if (roleName === ROLES.production.roleName) {
      screen = allScreens[0];
    } else if (roleName === ROLES.consumption.roleName) {
      screen = allScreens[1];
    } else if (roleName === ROLES.ectocloud.roleName) {
      screen = allScreens[2];
    }
  }

  useEffect(() => {
    if (!isComboView) {
      return;
    }

    const handler = ({ comboScreen: newComboScreen }) => {
      setComboScreen(newComboScreen - 1);
    };

    socket.on(comboViewMessageTypes.CHANGE, handler);

    return () => {
      socket.off(comboViewMessageTypes.CHANGE, handler);
    };
  }, [socket, isComboView]);

  const ref = useRef(null);

  const content = (
    <View
      direction="column"
      className={classNames(
        'InfoView',
        renderFullscreen && 'InfoViewFullscreen',
        screen?.screenContentType === ScreenContentType.Image && 'InfoViewImage'
      )}
    >
      {roomQuery.isLoading && <Spinner />}
      {screen?.screenContentType === ScreenContentType.Text && (
        <ReactMarkdown>{screen.text}</ReactMarkdown>
      )}
      {screen?.screenContentType === ScreenContentType.Image && (
        <img key={screen.contentUri} src={screen.contentUri} />
      )}
      {screen?.screenContentType === ScreenContentType.Video && (
        <video width="100%" controls autoPlay muted loop playsInline={true}>
          <source src={screen.contentUri} />
          Your browser does not support the video tag.
        </video>
      )}

      {renderFullscreen && (
        <FullScreenControls
          socket={socket}
          roomData={roomQuery.data}
          screenContentId={screenContentId}
        />
      )}
    </View>
  );

  return renderFullscreen
    ? ReactDOM.createPortal(
        <div ref={ref} className="InfoViewFullScreenContainer">
          {content}{' '}
        </div>,
        document.getElementById('root')
      )
    : content;
};

export default React.memo(InfoView);
