import { ApiContextSettings } from '../API/APIUtils';
import APIGen, {
  SignalsGetSignalValuesParams
} from 'ecto-common/lib/API/APIGen';
import _ from 'lodash';

export function getChunkedAPISignalValues(
  contextSettings: ApiContextSettings,
  args: SignalsGetSignalValuesParams,
  signal: AbortSignal
) {
  const chunks: SignalsGetSignalValuesParams[] = [];
  const chunkSize = 20;

  for (let i = 0; i < args.SignalIds.length; i += chunkSize) {
    chunks.push({
      ...args,
      SignalIds: args.SignalIds.slice(i, i + chunkSize)
    });
  }

  const allPromises = chunks.map((chunk) =>
    APIGen.Signals.getSignalValues.promise(contextSettings, chunk, signal)
  );

  return Promise.all(allPromises).then((results) => {
    return _.flatMap(results);
  });
}
