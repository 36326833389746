import React, { useState } from 'react';
import SegmentControl from 'ecto-common/lib/SegmentControl/SegmentControl';
import { SegmentControlItem } from 'ecto-common/lib/SegmentControl/SegmentControlItem';
import ModelFormSection from 'ecto-common/lib/ModelForm/ModelFormSection';
import styles from './ModelFormSegmentControl.module.css';
import { ModelFormSectionType } from 'ecto-common/lib/ModelForm/ModelPropType';
import { ModelRenderCallbackFunction } from 'ecto-common/lib/ModelForm/ModelForm';

interface ModelFormSegmentControlProps<ObjectType extends object> {
  sections?: ModelFormSectionType<ObjectType>[];
  renderChildModel?: ModelRenderCallbackFunction<ObjectType>;
  sectionClassName?: string;
  input: ObjectType;
}

const ModelFormSegmentControl = <ObjectType extends object>({
  sections,
  renderChildModel,
  sectionClassName,
  input
}: ModelFormSegmentControlProps<ObjectType>) => {
  const [curIndex, setCurIndex] = useState(0);
  const activeSection =
    curIndex < sections.length ? sections[curIndex] : { lines: [] };

  return (
    <>
      <SegmentControl expanding className={styles.segmentControl}>
        {sections.map((section, index) => (
          <SegmentControlItem
            key={index}
            compact
            active={index === curIndex}
            onClick={() => setCurIndex(index)}
          >
            {section.label}
          </SegmentControlItem>
        ))}
      </SegmentControl>
      <ModelFormSection
        renderChildModel={renderChildModel}
        section={activeSection}
        className={sectionClassName}
        hideLabel
        input={input}
      />
    </>
  );
};

export default ModelFormSegmentControl;
