import queryString from 'query-string';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';

const PAGE_SPREAD = 2;

export const getLocationWithPageParameter = (
  location: ReturnType<typeof useLocation>,
  pageLinkQueryParameter: string,
  page: number
) => {
  return {
    ...location,
    search: queryString.stringify({
      ...queryString.parse(location.search),
      ...{ [pageLinkQueryParameter]: page }
    })
  };
};

/**
 * This function calculates a suitable list of page indices to show in a pagination component.
 * It tries to keep the amount of digits the same as far as possible to avoid "jumpy" relayouts.
 *
 * It works like this:
 *
 * - It starts by adding the current page, and then tries to add 1 more page for every side PAGE_SPREAD
 *   times.
 * - If it cannot add a page to one side (no room), it tries to add it to the other
 * - After this loop it tries to add the first and last page since they should always be shown. If
 *   the first page is already there, it tries to add one more to the right side. If the last page
 *   is already there, it tries to add one to the left side.
 * - If there is distance (more than 0 digits) between the "inner" digits and the first/last digits,
 *   a text ellipsis "..." is shown between those digits.
 *
 * @param totalPages The total number of pages
 * @param currentPage The currently active page.
 * @returns An array with digits for the pages to show and ellipsis strings in the positions without overlap.
 */
export const calculatePaginationRange = (
  totalPages: number,
  currentPage: number
) => {
  if (totalPages < 1) {
    return [];
  }
  const _currentPage = Math.min(Math.max(0, currentPage), totalPages - 1);

  let pageIndices = [];

  const addPage = (idx: number, ret: number[]) => {
    if (idx >= 0 && idx < totalPages) {
      ret.push(idx);
      return true;
    }

    return false;
  };

  pageIndices.push(_currentPage);

  let left = _currentPage;
  let right = _currentPage;

  for (let i = 0; i < PAGE_SPREAD; i++) {
    if (!addPage(--left, pageIndices)) {
      addPage(++right, pageIndices);
    }
    if (!addPage(++right, pageIndices)) {
      addPage(--left, pageIndices);
    }
  }

  if (!_.includes(pageIndices, 0)) {
    pageIndices.push(0);
  } else {
    addPage(++right, pageIndices);
  }

  const lastPage = totalPages - 1;
  if (!_.includes(pageIndices, lastPage)) {
    pageIndices.push(lastPage);
  } else if (!_.includes(pageIndices, left - 1)) {
    addPage(--left, pageIndices);
  }

  pageIndices = _.sortBy(pageIndices);

  const indicesWithTruncation = [];

  for (let i = 0; i < pageIndices.length; i++) {
    indicesWithTruncation.push(pageIndices[i]);
    if (i < lastPage && pageIndices[i + 1] - pageIndices[i] > 1) {
      indicesWithTruncation.push('...');
    }
  }

  return indicesWithTruncation;
};
