/**
 * @readonly
 * @enum {number}
 */
export enum LayoutDirection {
  VERTICAL = 1,
  HORIZONTAL = 2
}

export default LayoutDirection;
