import React, { Fragment } from 'react';
import _ from 'lodash';

import styles from './SignalsTable.module.css';

import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import T from 'ecto-common/lib/lang/Language';
import {
  SET_SIGNAL_ERROR_DEVICE,
  SET_SIGNAL_ERROR_DEVICE_NO_DEVICE,
  SetSignalDeviceError
} from 'ecto-common/lib/hooks/useSignalSetter';
import {
  LastSignalValuesDataSet,
  SignalValueType
} from 'ecto-common/lib/hooks/useLatestSignalValues';
import CopyToClipboardTooltip from '../CopyToClipboardTooltip/CopyToClipboardTooltip';

interface WordBreakerProps {
  text?: string;
}

/**
 * This component adds "word break opportunities" between capital letters. Useful for
 * columns like signalTypeWithoutSpaces that does not break gracefully in lower resolutions.
 */
const WordBreaker = ({ text }: WordBreakerProps) => {
  const words = text.split(/(?=[A-Z])/);

  return (
    <>
      {words.map((word, idx) => {
        return <Fragment key={idx}>{word}&#8203;</Fragment>;
      })}
    </>
  );
};

export const hoverDataFormatter = (value: string, breakWords = false) => {
  const valueStr = value || '-';

  return (
    <div className={styles.hoverFlex}>
      <CopyToClipboardTooltip valueToCopy={valueStr}>
        {!breakWords && valueStr}
        {breakWords && <WordBreaker text={valueStr} />}
      </CopyToClipboardTooltip>
    </div>
  );
};

export const showUpdateSignalError = (error: unknown) => {
  if ((error as SetSignalDeviceError)?.type === SET_SIGNAL_ERROR_DEVICE) {
    toastStore.addErrorToast(T.equipment.setvaluefailurenoconnection);
  } else if (
    (error as SetSignalDeviceError)?.type === SET_SIGNAL_ERROR_DEVICE_NO_DEVICE
  ) {
    toastStore.addErrorToast(T.equipment.setvaluefailurenodevice);
  } else {
    toastStore.addErrorToast(T.equipment.setvaluefailure);
  }
};

export const getLatestDataPoint = (
  signal: LastSignalValuesDataSet
): SignalValueType => {
  const allValues = signal?.values;
  const currentTime = new Date().toISOString();
  const dataPoint = _.findLast(allValues, (point) => currentTime >= point.time);

  if (dataPoint == null) {
    return _.head(allValues);
  }

  return dataPoint;
};
