import React from 'react';
import styles from './ToolbarMenu.module.css';
import classNames from 'classnames';

interface ToolbarMenuItemProps {
  /**
   * Used to override the appearance of the menu item. Should be a valid CSS class name.
   */
  className?: string;
  /**
   * The content that will be wrapped.
   */
  children?: React.ReactElement;
}

/**
 * This component can wrap custom components within a ToolbarMenu.
 */
const ToolbarMenuItem = ({
  children = null,
  className = null
}: ToolbarMenuItemProps) => {
  return (
    <div className={classNames(className, styles.toolbarMenuItem)}>
      {children}
    </div>
  );
};

export default React.memo(ToolbarMenuItem);
