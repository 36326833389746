import T from 'ecto-common/lib/lang/Language';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { AggregationType, SamplingInterval } from 'ecto-common/lib/API/APIGen';

export enum TimeRangeOptions {
  // TODO: add custom
  // CUSTOM: 'custom',
  HOUR = 'hour',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
  FIVE_YEARS_BACK = 'fiveyears'
}

export const TimeRangeOptionsText: Record<string, string> = {
  // TODO: Add custom range
  // [TimeRangeOptions.CUSTOM]: T.graphs.timerange.option.custom,
  [TimeRangeOptions.HOUR]: T.graphs.timerange.option.hour,
  [TimeRangeOptions.DAY]: T.graphs.timerange.option.day,
  [TimeRangeOptions.WEEK]: T.graphs.timerange.option.week,
  [TimeRangeOptions.MONTH]: T.graphs.timerange.option.month,
  [TimeRangeOptions.YEAR]: T.graphs.timerange.option.year,
  [TimeRangeOptions.FIVE_YEARS_BACK]: T.graphs.timerange.option.fiveyears
};

const timeRangeSettings: Record<
  string,
  { aggregation: AggregationType; samplingInterval: SamplingInterval }
> = {
  [TimeRangeOptions.HOUR]: {
    aggregation: AggregationType.Mean,
    samplingInterval: SamplingInterval.Minute
  },
  [TimeRangeOptions.DAY]: {
    aggregation: AggregationType.Mean,
    samplingInterval: SamplingInterval.Hour
  },
  [TimeRangeOptions.WEEK]: {
    aggregation: AggregationType.Mean,
    samplingInterval: SamplingInterval.Day
  },
  [TimeRangeOptions.MONTH]: {
    aggregation: AggregationType.Mean,
    samplingInterval: SamplingInterval.Day
  },
  [TimeRangeOptions.YEAR]: {
    aggregation: AggregationType.Mean,
    samplingInterval: SamplingInterval.Month
  },
  // This only works with preaggregated data. Disable otherwise. We should have SamplingInterval.YEAR for this to work
  [TimeRangeOptions.FIVE_YEARS_BACK]: {
    aggregation: AggregationType.Mean,
    samplingInterval: SamplingInterval.Month
  }
};

type DateFuncType = (now: Moment | string | number) => Moment;

// Create a time range function that round the start time to sampling interval of the option
const rangeFunc = (
  option: string,
  amount: number,
  unit: moment.unitOfTime.DurationConstructor
): Record<string, DateFuncType> => {
  const value = (now: Moment | string) =>
    moment(now)
      .add(amount, unit)
      .startOf(
        _.toLower(
          timeRangeSettings[option].samplingInterval
        ) as moment.unitOfTime.StartOf
      );
  return { [option]: value };
};

const timeRangeUnits: Record<string, moment.unitOfTime.DurationConstructor> = {
  [TimeRangeOptions.HOUR]: 'hour',
  [TimeRangeOptions.DAY]: 'day',
  [TimeRangeOptions.WEEK]: 'week',
  [TimeRangeOptions.MONTH]: 'month',
  [TimeRangeOptions.YEAR]: 'year',
  [TimeRangeOptions.FIVE_YEARS_BACK]: 'year'
};

const timeRangeFunctions: Record<string, DateFuncType> = {
  ...rangeFunc(TimeRangeOptions.HOUR, -1, 'hour'),
  ...rangeFunc(TimeRangeOptions.DAY, -1, 'day'),
  ...rangeFunc(TimeRangeOptions.WEEK, -7, 'day'),
  ...rangeFunc(TimeRangeOptions.MONTH, -1, 'month'),
  ...rangeFunc(TimeRangeOptions.YEAR, -1, 'year'),
  ...rangeFunc(TimeRangeOptions.FIVE_YEARS_BACK, -5, 'years')
};

const DEFAULT_TIME_RANGE_SETTING = {
  aggregation: AggregationType.Mean,
  samplingInterval: SamplingInterval.Hour
};

export type DateRange = {
  dateFrom: Moment;
  dateTo: Moment;
};

export const dateRangeFromTimeRange = (
  timeRange: string,
  referenceDate: string | Moment | number,
  useLastValueBeforeRange = false
): DateRange => {
  const unit = timeRangeUnits[timeRange];

  if (referenceDate != null) {
    const dateFrom = moment(referenceDate).startOf(unit);
    let dateTo;

    if (timeRange === TimeRangeOptions.FIVE_YEARS_BACK) {
      // Include this whole year, and 4 years before it = 5 years.
      dateFrom.add(-4, 'year');
      dateTo = moment(dateFrom).add(5, 'year');
    } else {
      dateTo = moment(dateFrom).add(1, unit);
    }

    // Makes sure we won't get values for the exact date dateTo.
    dateTo.add(-1, 'second');

    return {
      dateFrom,
      dateTo
    };
  } else if (useLastValueBeforeRange) {
    const dateTo = moment().startOf(unit);
    const dateFrom = timeRangeFunctions[timeRange](dateTo);
    dateTo.add(-1, 'second');

    return { dateFrom, dateTo };
  }

  return {
    dateFrom:
      timeRangeFunctions[timeRange]?.(Date.now()) ?? moment().add(-24, 'hours'),
    dateTo: moment()
  };
};

// Create a time range function that round the start time to sampling interval of the option
export const getStartDateForTimeRange = (
  timeRange: string,
  referenceDate: Moment
): Moment => {
  if (timeRange === TimeRangeOptions.FIVE_YEARS_BACK) {
    return moment(referenceDate).startOf('year');
  }

  return dateRangeFromTimeRange(timeRange, referenceDate).dateFrom;
};

export const settingsFromTimeRange = (timeRange: TimeRangeOptions) => {
  return timeRangeSettings[timeRange] ?? DEFAULT_TIME_RANGE_SETTING;
};
