import React from 'react';

const EctoGridLogo = () => (
  <svg
    width="25"
    height="32"
    viewBox="0 0 34 47"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink={'http://www.w3.org/1999/xlink'}
    xmlSpace="preserve"
    style={{
      fillRule: 'evenodd',
      clipRule: 'evenodd',
      strokeLinejoin: 'round',
      strokeMiterlimit: 2
    }}
  >
    <g transform="matrix(1,0,0,1,-11,-8.03711)">
      <path
        d="M26.765,12.953C24.4,12.953 22.483,14.999 22.483,17.522L22.483,23.508L24.738,23.508L20.905,30.59L17.072,23.508L19.327,23.508L19.327,17.522C19.327,13.366 22.321,9.96 26.133,9.617C24.613,8.617 22.816,8.037 20.889,8.037C15.427,8.037 11,12.681 11,18.411C11,24.14 20.889,41.465 20.889,41.465C20.889,41.465 30.778,24.14 30.778,18.411C30.778,17.341 30.623,16.309 30.336,15.338C29.684,13.917 28.389,12.953 26.765,12.953Z"
        style={{ fill: 'white' }}
      />
      <path
        d="M34.928,21.572C34.928,21.572 25.039,38.897 25.039,44.626C25.039,45.202 25.086,45.767 25.172,46.317C25.789,48.031 27.342,49.249 29.167,49.249C31.531,49.249 33.448,47.204 33.448,44.681L33.448,39.248L31.194,39.248L35.026,32.166L38.859,39.248L36.605,39.248L36.605,44.681C36.605,49.064 33.274,52.616 29.167,52.616C28.973,52.616 28.782,52.606 28.592,52.59C30.308,54.095 32.517,55.001 34.928,55.001C40.389,55.001 44.817,50.356 44.817,44.626C44.817,38.897 34.928,21.572 34.928,21.572Z"
        style={{ fill: 'white', fillOpacity: 0.85 }}
      />
    </g>
  </svg>
);

export default EctoGridLogo;
