import React from 'react';
import AppContext from './app_context';

type ClientNrProps = {
  fontSize?: number;
};

/**
 * The client number used for pairing. Used by different screens but styled
 * differently.
 */
class ClientNr extends React.Component<ClientNrProps> {
  declare context: React.ContextType<typeof AppContext>;

  render() {
    let props = this.props;
    let appState = this.context;

    if (appState.isMultiScreen) {
      return null;
    }

    return (
      appState &&
      appState.clientNumber &&
      appState.clientNumber !== -1 && (
        <div
          {...props}
          className="clientnr"
          style={{ fontSize: props.fontSize }}
        >
          {appState.clientNumber}
        </div>
      )
    );
  }
}

ClientNr.contextType = AppContext;

export default ClientNr;
