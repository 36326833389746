import { ModelDefinitionInternal } from 'ecto-common/lib/ModelForm/ModelPropType';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import React from 'react';

export type SpaceModelDefinition<
  ObjectType extends object,
  EnvironmentType extends object = object
> = {
  modelType: typeof ModelType.SPACE;
} & ModelDefinitionInternal<ObjectType, EnvironmentType, unknown>;

const ModelEditorSpace = () => {
  return <div />;
};

export default React.memo(ModelEditorSpace);
