import 'regenerator-runtime/runtime';
import 'ecto-common/lib/Layout/Layout';
import React from 'react';
import MultiApp from './js/components/App/MultiApp';
import './js/assets/fonts/font-eon_brix_sans.css';
import * as moment from 'moment';
import * as momentTimezone from 'moment-timezone';
import _ from 'lodash';
import { darkModeStore } from 'ecto-common/lib/DarkMode/DarkMode';
import { setApiEnvironment } from 'ecto-common/lib/utils/apiEnvironment';
import { emptyEnvironment } from 'ecto-common/lib/API/ApiConfigType';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { createRoot } from 'react-dom/client';

// Import and inject moment timezone into moment object.
_.noop(moment);
_.noop(momentTimezone);

darkModeStore.setEnabled(true);
// TODO: Move to release pipeline

setApiEnvironment(
  _.merge({}, emptyEnvironment, {
    name: 'Ectotable',
    urls: {
      ...emptyEnvironment.urls,
      ectotableClientUrl: 'https://ectotableexperiment.azurewebsites.net/api'
    }
  })
);

const router = createBrowserRouter([
  {
    path: '*',
    element: <MultiApp />
  }
]);

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(<RouterProvider router={router} />);
