import React, { useMemo } from 'react';
import _ from 'lodash';

import { useAlarmEventHubSubscription } from 'ecto-common/lib/EventHubConnection/EventHubConnectionHooks';
import AlarmTableView from 'ecto-common/lib/Alarms/AlarmTableView';
import useReloadTrigger from 'ecto-common/lib/hooks/useReloadTrigger';
import T from 'ecto-common/lib/lang/Language';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import DataSourceTypes from 'ecto-common/lib/Dashboard/datasources/DataSourceTypes';
import HelpPaths from 'ecto-common/help/tocKeys';

import { buildingStatusSection } from './panelUtil';

import styles from './AlarmListPanel.module.css';
import { CustomPanelProps } from 'ecto-common/lib/Dashboard/Panel';
import { SingleGridNode } from 'ecto-common/lib/types/EctoCommonTypes';
import { BuildingStatus } from 'ecto-common/lib/API/APIGen';
import { ModelFormSectionType } from 'ecto-common/lib/ModelForm/ModelPropType';

const DEFAULT_PAGE_SIZE = 15;
const MIN_PAGE_SIZE = 5;
const MAX_PAGE_SIZE = 50;

type AlarmListPanelConfig = {
  node?: SingleGridNode;
  pageSize: number;
  buildingStatus?: BuildingStatus[];
};

type AlarmListPanelProps = CustomPanelProps & {
  data: AlarmListPanelConfig;
};

const AlarmListPanel = ({ data }: AlarmListPanelProps) => {
  const node = data?.node;
  const nodeId = node?.nodeId;
  const grid = node?.grid;
  const pageSize = data?.pageSize;

  const [reloadTrigger, triggerReload] = useReloadTrigger();

  useAlarmEventHubSubscription(
    grid,
    nodeId,
    triggerReload,
    data?.buildingStatus
  );

  const filterParams = useMemo(
    () => ({ buildingStatuses: data?.buildingStatus }),
    [data?.buildingStatus]
  );

  if (!node) {
    return <div />;
  }
  return (
    <div className={styles.alarmContainer}>
      <AlarmTableView
        nodeId={nodeId}
        compact
        search={''}
        reloadTrigger={reloadTrigger}
        listHistory={false}
        pageSize={pageSize}
        filterParams={filterParams}
      />
    </div>
  );
};

const sections: ModelFormSectionType<AlarmListPanelConfig>[] = [
  {
    label: T.admin.dashboards.sections.alarm,
    lines: [
      {
        models: [
          {
            key: (input) => input.pageSize,
            modelType: ModelType.NUMBER,
            label: T.admin.dashboards.panels.types.alarmlist.pagesize,
            placeholder: _.toString(DEFAULT_PAGE_SIZE),
            hasError: (value: number) =>
              value !== undefined &&
              !_.inRange(value, MIN_PAGE_SIZE, MAX_PAGE_SIZE + 1)
          }
        ]
      }
    ]
  },
  buildingStatusSection
];

export const AlarmListPanelData = {
  minWidth: 550,
  helpPath: HelpPaths.docs.dashboard.dashboards.alarm_list,
  sections,
  emptyTargets: {
    node: {
      sourceType: DataSourceTypes.NODE
    }
  }
};

export default React.memo(AlarmListPanel);
