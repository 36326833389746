import T from 'ecto-common/lib/lang/Language';
import { SamplingInterval } from 'ecto-common/lib/API/APIGen';

export const SamplingIntervalText: Record<string, string> = {
  [SamplingInterval.Raw]: T.graphs.exportdialog.sampling.raw,
  [SamplingInterval.Minute]: T.graphs.exportdialog.sampling.minute,
  [SamplingInterval.MinutesFive]: T.graphs.exportdialog.sampling.fiveminutes,
  [SamplingInterval.MinutesFifteen]:
    T.graphs.exportdialog.sampling.fifteenminutes,
  [SamplingInterval.Hour]: T.graphs.exportdialog.sampling.hour,
  [SamplingInterval.Day]: T.graphs.exportdialog.sampling.day,
  [SamplingInterval.Week]: T.graphs.exportdialog.sampling.week,
  [SamplingInterval.Month]: T.graphs.exportdialog.sampling.month
};
