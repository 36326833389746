import React from 'react';
import HelpTooltip from 'ecto-common/lib/HelpTooltip/HelpTooltip';

interface ModalHeaderHelpRightContentProps {
  helpPath?: string;
}

export const ModalHeaderHelpRightContent = ({
  helpPath
}: ModalHeaderHelpRightContentProps) => {
  if (helpPath) {
    return (
      <div>
        <HelpTooltip helpPath={helpPath} light small />
      </div>
    );
  }

  return null;
};

export default ModalHeaderHelpRightContent;
