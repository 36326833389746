import React, { useMemo } from 'react';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import Icons from 'ecto-common/lib/Icons/Icons';
import { formatNumberUnit } from 'ecto-common/lib/utils/stringUtils';
import DataTableFooter from 'ecto-common/lib/DataTable/DataTableFooter';
import moment from 'moment';
import { DEFAULT_TIMEZONE } from 'ecto-common/lib/constants';
import {
  TimeFormats,
  getDefaultDateTimeFormat
} from 'ecto-common/lib/utils/dateUtils';
import T from 'ecto-common/lib/lang/Language';
import _ from 'lodash';

type AnalyticsRow = {
  label: string;
  min: number | null;
  max: number | null;
  mean: number | null;
  unit: string;
};

const columns: DataTableColumnProps<AnalyticsRow>[] = [
  {
    dataKey: 'label',
    label: T.common.name
  },
  {
    dataKey: 'min',
    label: T.graphs.exportdialog.aggregation.min,
    dataFormatter: (value: number | null, object) =>
      formatNumberUnit(value, object.unit)
  },
  {
    dataKey: 'max',
    label: T.graphs.exportdialog.aggregation.max,
    dataFormatter: (value: number | null, object) =>
      formatNumberUnit(value, object.unit)
  },
  {
    dataKey: 'mean',
    label: T.graphs.exportdialog.aggregation.mean,
    dataFormatter: (value: number | null, object) =>
      formatNumberUnit(value, object.unit)
  }
];

const ChartAnalyticsDialog = ({
  isOpen,
  onHide,
  series,
  range,
  isLoading
}: {
  isOpen: boolean;
  onHide: () => void;
  series?: Highcharts.SeriesOptionsType[];
  range: [number, number];
  isLoading: boolean;
}) => {
  const tableData = useMemo(() => {
    if (!isOpen || series == null) {
      return [];
    }

    const rows: AnalyticsRow[] = [];

    for (const seriesObject of series) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data = (seriesObject as any).data as number[][];
      if (data == null || data.length === 0) {
        continue;
      }

      // Do proper data validation since we technically cannot trust the data from the Highcharts config object, there are
      // multiple data formats and we only support the [number, number] format. Ideally we should improve the typing coming
      // in to the StockChart class, since it uses boost, and boost relies on the [number, number] form anyways.
      if (
        !_.isArray(data) ||
        data.length === 0 ||
        !_.isNumber(data[0][0]) ||
        data[0].length !== 2
      ) {
        throw new Error('Invalid series configuration');
      }

      let numValues = 0;
      let minValue = Number.MAX_SAFE_INTEGER;
      let maxValue = Number.MIN_SAFE_INTEGER;
      let sumMean = 0;

      for (const dataPoint of data) {
        if (dataPoint[0] >= range[0] && dataPoint[0] <= range[1]) {
          sumMean += dataPoint[1];
          if (dataPoint[1] < minValue) {
            minValue = dataPoint[1];
          }
          if (dataPoint[1] > maxValue) {
            maxValue = dataPoint[1];
          }

          numValues++;
        }
      }

      rows.push({
        label: seriesObject.name,
        min: numValues === 0 ? null : minValue,
        max: numValues === 0 ? null : maxValue,
        mean: numValues === 0 ? null : sumMean / numValues,
        unit: _.isString(seriesObject.yAxis) ? seriesObject.yAxis : 'n/a'
      });
    }

    return rows;
  }, [isOpen, range, series]);

  const header = useMemo(() => {
    const startDate = moment
      .tz(range[0], DEFAULT_TIMEZONE)
      .format(getDefaultDateTimeFormat(TimeFormats.SECONDS));
    const endDate = moment
      .tz(range[1], DEFAULT_TIMEZONE)
      .format(getDefaultDateTimeFormat(TimeFormats.SECONDS));

    const startParts = startDate.split(' ');
    const endParts = endDate.split(' ');
    if (startParts[0] === endParts[0]) {
      return startDate + ' - ' + endParts[1];
    }

    return startDate + ' - ' + endDate;
  }, [range]);

  return (
    <ActionModal
      title={T.graphs.analytics.title + ' ' + header}
      isOpen={isOpen}
      onModalClose={onHide}
      onConfirmClick={onHide}
      disableCancel
      actionText={T.common.close}
      headerIcon={Icons.Table}
      wide
    >
      <DataTable<AnalyticsRow>
        columns={columns}
        data={tableData}
        isLoading={isLoading}
      />
      <DataTableFooter>{T.graphs.analytics.help}</DataTableFooter>
    </ActionModal>
  );
};

export default React.memo(ChartAnalyticsDialog);
