import React from 'react';
import SvgIcon, { SvgIconProps } from './SvgIcon';
import styles from './Remove.module.css';

const Remove = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 42 42" {...props}>
    <line
      className={styles.remove}
      x1="30.13"
      y1="11.87"
      x2="11.87"
      y2="30.13"
    />
    <line
      className={styles.remove}
      x1="11.87"
      y1="11.87"
      x2="30.13"
      y2="30.13"
    />
  </SvgIcon>
);

export default Remove;
