import UUID from 'uuidjs';
import _ from 'lodash';
import {
  GetEnumsAndFixedConfigurationsResponseModel,
  SignalProviderType,
  SignalTypeResponseModel
} from 'ecto-common/lib/API/APIGen';

export const migrateSignalSettingSystemNamesToSignalTypes = (
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  list: any,
  enums: GetEnumsAndFixedConfigurationsResponseModel,
  signalTypesNameMap: Record<string, SignalTypeResponseModel>
) => {
  _.forEach(list.signals, (signal) => {
    let signalProviderType = null;

    if (signal.signalProviderName.endsWith('Weather')) {
      signalProviderType = SignalProviderType.Meteorology;
    } else {
      // Strip whitespace from provider name
      const typeName = signal.signalProviderName.replace(/\s/g, '');
      if (enums.signalProviderTypes.includes(typeName)) {
        signalProviderType = typeName;
      }
    }

    const signalType = signalTypesNameMap[signal.systemName];

    delete signal.signalProviderName;
    delete signal.systemName;

    signal.signalProviderType = signalProviderType;
    signal.signalTypeId = signalType?.id;

    if (signal.id == null) {
      signal.id = UUID.generate();
    }
  });

  return list;
};
