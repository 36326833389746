import { TableContextType } from 'js/Table/context';
import { arrayLast } from './util';
import p5 from 'p5';

const numberOfPositions = 10;

class Position2DHistory {
  array: p5.Vector[];
  times: number[];
  context: TableContextType;

  constructor(context) {
    this.array = [];
    this.times = [];
    this.context = context;
  }

  reset() {
    this.array = [];
    this.times = [];
  }

  add(position2D) {
    const now = this.context.appNow();
    const lastTime = this.times[this.times.length - 1];

    if (now > lastTime + 2000) {
      this.reset();
    }

    if (this.array.length > numberOfPositions + 1) {
      this.array.shift();
      this.times.shift();
    }

    this.array.push(position2D);
    this.times.push(now);
  }

  getSpeed() {
    const recordedNumbers = this.array.length;

    if (recordedNumbers < 2) {
      return 0;
    }

    // @ts-ignore-next-line: Incorrect definition @types/p5
    const totalMovement = this.context.Vector.sub(
      arrayLast(this.array),
      this.array[0]
    );
    const totalMovementMagnitude = totalMovement.mag();

    const totalTime = arrayLast(this.times) - this.times[0];

    if (totalTime === 0) {
      return undefined;
    }

    return totalMovementMagnitude / totalTime;
  }
}

export default Position2DHistory;
