import React, { MouseEventHandler, useCallback } from 'react';
import styles from './PagingFooter.module.css';
import GreyButton from '../Button/GreyButton';
import Icons from '../Icons/Icons';
import T from '../lang/Language';
import classNames from 'classnames';
import _ from 'lodash';
import PagingFooterLink from 'ecto-common/lib/PagingFooter/PagingFooterLink';
import {
  calculatePaginationRange,
  getLocationWithPageParameter
} from 'ecto-common/lib/PagingFooter/pagingUtil';
import { useNavigate, useLocation } from 'react-router-dom';

const handlePageEvent = (
  event: React.MouseEvent<HTMLButtonElement>,
  location: ReturnType<typeof useLocation>,
  navigate: ReturnType<typeof useNavigate>,
  pageLinkQueryParameter: string,
  onPageChange: (newPage: number) => void,
  page: number
) => {
  if (event.target instanceof HTMLElement) {
    event.target.blur();
  }

  if (pageLinkQueryParameter != null) {
    const targetLocation = getLocationWithPageParameter(
      location,
      pageLinkQueryParameter,
      page
    );
    navigate(targetLocation);
  } else {
    onPageChange(page);
  }
};

interface PagingFooterProps {
  /**
   * If set to true then the user cannot navigate forward.
   */
  disableForwardNavigation?: boolean;
  /**
   * If set to true then the user cannot navigate backwards.
   */
  disableBackNavigation?: boolean;
  /**
   * Handler for when the user navigates a link or presses the buttons. Will only be called if pageLinkQueryParameter is not set.
   */
  onPageChange?(newPage: number): void;
  /**
   * The current page
   */
  page?: number;
  /**
   * The total number of pages (optional)
   */
  totalPages?: number;
  /**
   * Used to override the appearance of the footer. Should be a valid CSS class name.
   */
  className?: string;
  /**
   * If this is set, then the paging footer will create actual links for its items. The prop
   * represents which query parameter represents the current page. When you press a link or any
   * of the buttons the URL will change accordingly.
   */
  pageLinkQueryParameter?: string;
}

/**
 * This component draws
 */
const PagingFooter = ({
  onPageChange,
  page,
  totalPages,
  disableBackNavigation,
  disableForwardNavigation,
  className,
  pageLinkQueryParameter
}: PagingFooterProps) => {
  const useLinks = totalPages != null && totalPages > 1;
  const navigate = useNavigate();
  const currentPage = Math.min((totalPages ?? 0) - 1, page);
  const location = useLocation();

  const backButtonPressed: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      handlePageEvent(
        event,
        location,
        navigate,
        pageLinkQueryParameter,
        onPageChange,
        page - 1
      );
    },
    [location, navigate, pageLinkQueryParameter, onPageChange, page]
  );

  const forwardButtonPressed: MouseEventHandler<HTMLButtonElement> =
    useCallback(
      (event) => {
        handlePageEvent(
          event,
          location,
          navigate,
          pageLinkQueryParameter,
          onPageChange,
          page + 1
        );
      },
      [location, navigate, pageLinkQueryParameter, onPageChange, page]
    );

  if (useLinks && totalPages < 2) {
    return null;
  }

  const paginationRange = calculatePaginationRange(totalPages, currentPage);

  return (
    <div className={classNames(styles.footer, className)}>
      <GreyButton
        isIconButton
        hasIconButtonBorder
        onClick={backButtonPressed}
        disabled={disableBackNavigation || page <= 0}
      >
        <Icons.NavigationArrowLeft />
      </GreyButton>

      {useLinks && (
        <div className={styles.linkArea}>
          {paginationRange.map((pageProp, idx) =>
            _.isString(pageProp) ? (
              <div key={idx}>{pageProp}</div>
            ) : (
              <PagingFooterLink
                page={pageProp}
                active={currentPage === pageProp}
                key={`page-${idx}`}
                onClick={onPageChange}
                pageLinkQueryParameter={pageLinkQueryParameter}
              >
                {pageProp + 1}
              </PagingFooterLink>
            )
          )}
        </div>
      )}

      {!useLinks && (
        <label>{T.format(T.pagingfooter.pageformat, page + 1)}</label>
      )}
      <GreyButton
        disabled={
          totalPages === 0 ||
          disableForwardNavigation ||
          (useLinks && page >= totalPages - 1) ||
          totalPages === 1
        }
        hasIconButtonBorder
        isIconButton
        onClick={forwardButtonPressed}
      >
        <Icons.NavigationArrowRight />
      </GreyButton>
    </div>
  );
};

export default PagingFooter;
