import React, { useState } from 'react';

import { ROOT_NODE_ID } from 'ecto-common/lib/constants';
import SearchableTreeView from 'ecto-common/lib/SearchableTreeView/SearchableTreeView';

import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';
import { SingleGridNode } from 'ecto-common/lib/types/EctoCommonTypes';
import { EquipmentResponseModel } from 'ecto-common/lib/API/APIGen';

interface SelectNodeProps {
  onNodesSelected(nodeIs: string[]): void;
  nodeIds?: string[];
  multiSelect?: boolean;
  customNodeTreeSet?: CustomNodeTreeSet;
  selectEquipment?: boolean;
}
export type CustomNodeTreeSet = {
  nodeTree: SingleGridNode[];
  nodeMap: Record<string, SingleGridNode>;
  equipmentMap: Record<string, EquipmentResponseModel>;
  nodeTags: string[];
};

const SelectNode = ({
  onNodesSelected,
  nodeIds: initialNodeIds,
  multiSelect = true,
  selectEquipment = false,
  customNodeTreeSet = null
}: SelectNodeProps) => {
  const [nodeIds, setNodeIds] = useState<string[]>([...(initialNodeIds ?? [])]);
  let nodeTree = useCommonSelector((state) => state.general.nodeTree);
  let nodeMap = useCommonSelector((state) => state.general.nodeMap);
  let equipmentMap = useCommonSelector((state) => state.general.equipmentMap);
  let nodeTags = useCommonSelector((state) => state.general.nodeTags);

  if (customNodeTreeSet != null) {
    nodeTree = customNodeTreeSet.nodeTree;
    nodeMap = customNodeTreeSet.nodeMap;
    equipmentMap = customNodeTreeSet.equipmentMap;
    nodeTags = customNodeTreeSet.nodeTags;
  }

  const _onSelectedChanged = (nodeId: string, isSelected: boolean) => {
    if (nodeId.startsWith(ROOT_NODE_ID)) {
      return;
    }

    let newNodeIds = [...nodeIds];

    if (multiSelect) {
      if (!isSelected) {
        newNodeIds = newNodeIds.filter((x) => x !== nodeId);
      } else if (!nodeIds.includes(nodeId)) {
        newNodeIds = [...newNodeIds, nodeId];
      }
    } else {
      newNodeIds = isSelected ? [nodeId] : [];
    }

    onNodesSelected(newNodeIds);
    setNodeIds(newNodeIds);
  };

  return (
    <SearchableTreeView
      nodeTree={nodeTree}
      nodeIds={nodeIds}
      nodeTags={nodeTags}
      nodeMap={nodeMap}
      equipmentMap={equipmentMap}
      onSelectionChanged={_onSelectedChanged}
      multiSelect={multiSelect}
      selectEquipment={selectEquipment}
    />
  );
};

export default React.memo(SelectNode);
