import { createContext } from 'react';
import { TimeRangeOptions } from '../../types/TimeRangeOptions';
import { Moment } from 'moment/moment';

export type TimeRangeContextType = {
  timeRangeOption: TimeRangeOptions;
  referenceDate: Moment;
  addTimeRangeConsumer: (
    consumerId: string,
    specifiedTimeRanges: TimeRangeOptions[]
  ) => void;
  removeTimeRangeConsumer: (consumerId: string) => void;
};

const TimeRangeContext = createContext<TimeRangeContextType>({
  timeRangeOption: null,
  referenceDate: null,

  // Each consumer of time range adds or removes them self from the time range user

  addTimeRangeConsumer: (_consumerId) => {},
  removeTimeRangeConsumer: (_consumerID) => {}
});

export default TimeRangeContext;
