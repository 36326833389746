import React from 'react';
import styles from './LocationPageHeader.module.css';
import classNames from 'classnames';
import HelpTooltip from 'ecto-common/lib/HelpTooltip/HelpTooltip';
import DebugInformation from 'ecto-common/lib/DebugInformation/DebugInformation';

interface LocationPageHeaderProps {
  title?: React.ReactNode;
  children?: React.ReactNode;
  helpPath?: string;
}

const LocationPageHeader = ({
  title,
  children,
  helpPath
}: LocationPageHeaderProps) => {
  return (
    <div className={styles.pageHeader}>
      <div
        className={classNames(
          styles.pageHeaderInner,
          !children && styles.bottomPadding
        )}
      >
        <div className={styles.title}>{title}</div>

        {children}
      </div>
      {process.env.DEV_BUILD && <DebugInformation />}

      {helpPath && (
        <div
          className={classNames(
            styles.pageHeaderHelpTooltip,
            children && styles.multipleItems
          )}
        >
          <HelpTooltip helpPath={helpPath} />
        </div>
      )}
    </div>
  );
};

export default LocationPageHeader;
