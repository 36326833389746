import React from 'react';
import { KeyValueGeneric } from 'ecto-common/lib/KeyValueInput/KeyValueGeneric';
import Checkbox from 'ecto-common/lib/Checkbox/Checkbox';
import { ModelEditorProps } from 'ecto-common/lib/ModelForm/ModelEditor';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import { ModelDefinitionInternal } from 'ecto-common/lib/ModelForm/ModelPropType';

export type BoolModelDefinition<
  ObjectType extends object,
  EnvironmentType extends object = object
> = {
  modelType: typeof ModelType.BOOL;
  withDivider?: boolean;
  placeholderChecked?: boolean;
} & ModelDefinitionInternal<ObjectType, EnvironmentType, boolean>;

type ModelEditorBoolProps = ModelEditorProps & {
  model: BoolModelDefinition<object>;
};

const ModelEditorBool = ({
  model,
  rawValue,
  updateItem,
  disabled,
  helpText = null,
  useTooltipHelpTexts = false,
  isHorizontal = true
}: ModelEditorBoolProps) => {
  return (
    <KeyValueGeneric
      keyText={model.label}
      isHorizontal={isHorizontal}
      horizontalWeights={model.horizontalWeights}
      withDivider={model.withDivider}
      helpText={helpText}
      useTooltipHelpTexts={useTooltipHelpTexts}
    >
      <Checkbox
        disabled={disabled}
        checked={rawValue}
        partiallyChecked={rawValue == null && model.placeholderChecked}
        onChange={updateItem}
      />
    </KeyValueGeneric>
  );
};

export default React.memo(ModelEditorBool);
