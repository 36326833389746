import React, { Component } from 'react';
import io from 'socket.io-client';
import {
  ROLES,
  Role,
  getRoleRoomCompanyAndToken,
  getTableStates
} from './app_util';
import './App.css';
import colors from '../../styles/variables/colors';
import AppContext, {
  initialAppContext,
  NOT_CONNECTED,
  CONNECTED,
  CONNECTED_BUT_NO_TABLE,
  CONNECTED_BUT_NO_BUILDINGS
} from './app_context';
import SocketMessages from '../../../../server/socket-messages';
import View from '../View/View';
import classNames from 'classnames';

import TableView from '../../views/TableView/TableView';
import AdminView from '../../views/AdminView/AdminView';
import ClientView from '../../views/ClientView/ClientView';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { globalClientSocket } from '../../utils/clientSocket';

let start = performance.now();
const ts = () => Math.round(performance.now() - start);

const queryClient = new QueryClient();

type AppProps = {
  room: string;
  isMultiScreen: boolean;
  localStoragePrefix?: string;
  role?: Role;
  size: { width: number; height: number };
  onToggleMultiScreen: () => void;
  multiScreenActive: () => boolean;
};

type AppState = {
  room: string;
  role: Role;
  connectionState: number;
  CurrentView: any;
  currentViewAttributes: any;
  data: any;
  fakeNoConnection: boolean;
  isMultiScreen: boolean;
  tableState: any;
  tableSubState: any;
  company: string;
  mounted: boolean;
  reloadApp: () => void;
  connecting: boolean;
  clientNumber: number;
};

class App extends Component<AppProps, AppState> {
  socket: any;
  authTimer: any;

  get isClient() {
    return (
      !this.state.room ||
      (this.state.role !== ROLES.table && this.state.role !== ROLES.admin)
    );
  }

  get isTable() {
    return !!this.state.room && this.state.role === ROLES.table;
  }

  get isAdmin() {
    return (
      !!this.state.room &&
      this.state.room === 'admin' &&
      this.state.role === ROLES.admin
    );
  }

  constructor(props) {
    super(props);

    // Gets the stored or dynamic role and room to show. No other evaluation is
    // done after this.
    const { role: storedRole, company } = getRoleRoomCompanyAndToken(
      props.localStoragePrefix
    );
    const { tableState, tableSubState } = getTableStates();
    let role = props.role ?? storedRole;

    console.info(
      `App - Starting...\n\t${[
        `mode: ${process.env.NODE_ENV}`,
        `room: ${this.props.room}`,
        `role: ${role ? role.roleName : ''}`,
        `company: ${company}`,
        `tableState: ${tableState} - ${tableSubState}`
      ].join('\n\t')}`
    );

    this.setView = this.setView.bind(this);
    this.onSocketData = this.onSocketData.bind(this);
    this.toggleFakeConnection = this.toggleFakeConnection.bind(this);

    this.state = {
      ...initialAppContext,
      data: null,
      isMultiScreen: this.props.isMultiScreen,
      fakeNoConnection: false,
      tableState,
      tableSubState,
      room: this.props.room,
      role,
      company,
      mounted: false,
      currentViewAttributes: null,
      CurrentView: null,
      reloadApp: () => {
        if (
          !this.state.connectionState ||
          !this.state.data ||
          !this.state.data.structures ||
          this.state.data.structures.length === 0
        ) {
          console.info('Reloading app');
          window.location.reload();
        }
      }
    };

    this.state = {
      ...this.state,
      currentViewAttributes: this.getViewAttributes(),
      CurrentView: this.getViewComponent()
    };
  }

  handleClientRoleChange = (newRole) => {
    const roleObject = ROLES[newRole];
    if (roleObject != null) {
      this.setState({
        role: roleObject
      });
      this.setView();
    }
  };

  componentDidMount() {
    this.setupSocket();

    if (process.env.DEVELOPMENT) {
      window.addEventListener('keyup', this.toggleFakeConnection);
    }
  }

  componentWillUnmount() {
    if (process.env.DEVELOPMENT) {
      window.removeEventListener('keyup', this.toggleFakeConnection);
    }
  }

  /**
   * Event-listener for testing if the connection drops.
   * @param {KeyboardEvent} ev
   */
  toggleFakeConnection(ev) {
    if (ev.key === 'c') {
      this.setState({
        fakeNoConnection: !this.state.fakeNoConnection
      });
    }
  }

  onSocketData(json) {
    let connectionState = CONNECTED;

    try {
      console.assert(!!json, 'App@onSocketData - No data');
      const data = json ? JSON.parse(json) : undefined;

      if (!data) {
        connectionState = CONNECTED_BUT_NO_TABLE;
      } else if (this.state.role !== ROLES.admin) {
        if (!data.structures || data.structures.length === 0) {
          // console.warn('App@onSocketData Data has no buildings', ts());
          connectionState = CONNECTED_BUT_NO_BUILDINGS;
        }
      } else {
        // Update the view attributes and data for the admin view.
        this.setState({
          data,
          currentViewAttributes: {
            data
          }
        });
      }

      // If this client is the table and the server says there are no tables connected
      // It make sense to update the server - this is an edge case that hopefully doesn't happen anymore
      if (
        this.state.role === ROLES.table &&
        connectionState === CONNECTED_BUT_NO_TABLE
      ) {
        this.socket.emit(SocketMessages.ROLE_CHANGE, {
          role: this.state.role.roleName
        });
      }
    } catch (error) {
      console.error(error);
    }
    return { connectionState };
  }

  /**
   * Gets the current view component to render, based on the state.
   *
   * @return {React.Component} View to use in rendering.
   */
  getViewComponent() {
    let view = null;

    if (this.state.connectionState === NOT_CONNECTED) view = View;
    else if (this.isTable) {
      view = TableView;
    } else if (this.isAdmin) {
      view = AdminView;
    } else {
      view = ClientView;
    }
    return view;
  }

  setView() {
    this.setState({
      CurrentView: this.getViewComponent(),
      currentViewAttributes: this.getViewAttributes()
    });
  }

  /**
   * Gets the current view attributes to render, based on the state.
   *
   * @return {React.Component} View to use in rendering.
   */
  getViewAttributes() {
    const state = this.state;

    if (this.isClient) {
      return {
        data: state.data,
        role: state.role,
        socket: this.socket,
        tableState: state.tableState,
        tableSubState: state.tableSubState,
        localStoragePrefix: this.props.localStoragePrefix,
        handleClientRoleChange: this.handleClientRoleChange
      };
    } else if (this.isAdmin) {
      return { data: state.data };
    } else if (this.isTable) {
      return {
        room: state.room,
        socket: this.socket,
        company: state.company,
        onToggleMultiScreen: this.props.onToggleMultiScreen,
        multiScreenActive: this.props.multiScreenActive
      };
    }
  }

  setupSocket() {
    console.info(
      'Connecting to',
      window.location.origin + '/hotrock',
      SocketMessages.CONNECT
    );

    if (this.props.isMultiScreen) {
      globalClientSocket.room = this.state.room;
      globalClientSocket.roleName = this.state.role.roleName;
    }

    this.socket = this.props.isMultiScreen
      ? globalClientSocket
      : io(window.location.origin + '/hotrock', {
          query: {
            room: this.state.room,
            role: this.state.role.roleName
          },
          credentials: 'include'
        });

    this.socket.on(SocketMessages.CONNECT, () => {
      console.info(`App@${SocketMessages.CONNECT} - ${this.socket.id} ${ts()}`);
      this.setState(
        {
          connectionState: CONNECTED_BUT_NO_TABLE,
          connecting: false
        },
        this.setView
      );
    });

    this.socket.on(SocketMessages.CLIENT_NUMBER, (number) => {
      console.info(`App@${SocketMessages.CLIENT_NUMBER} - ${number} ${ts()}`);
      this.setState(
        {
          clientNumber: number,
          connectionState: CONNECTED
        },
        this.setView
      );
    });

    this.socket.on(SocketMessages.DATA, this.onSocketData);
    this.socket.on(SocketMessages.REFRESH_FORCE, () => {
      console.info(`App@${SocketMessages.REFRESH_FORCE}`);
      window.location.reload();
    });

    this.socket.on(SocketMessages.DISCONNECT, (reason) => {
      console.info(`App@${SocketMessages.DISCONNECT} - ${reason} ${ts()}`);
      this.setState({ connectionState: NOT_CONNECTED }, this.setView);
    });

    this.socket.on(SocketMessages.CONNECT_ERROR, (error) => {
      console.error(`App@${SocketMessages.CONNECT_ERROR} -`, error, ts());
      this.socket.disconnect();
      this.setState(
        { connectionState: NOT_CONNECTED, data: null },
        this.setView
      );

      clearTimeout(this.authTimer);
      const retrySocketIfNeeded = () => {
        if (!this.socket.connectionState) {
          this.setupSocket();
        }
      };

      this.authTimer = setTimeout(retrySocketIfNeeded.bind(this), 10 * 1000);
    });

    this.socket.on(SocketMessages.CONNECT_TIMEOUT, () => {
      console.error(
        ts(),
        `App@${SocketMessages.CONNECT_TIMEOUT} - Socket timed out`
      );
      this.setState(
        { connectionState: NOT_CONNECTED, data: null },
        this.setView
      );
    });
  }

  render() {
    const {
      connectionState,
      clientNumber,
      fakeNoConnection,
      connecting,
      CurrentView,
      currentViewAttributes,
      reloadApp,
      room,
      isMultiScreen
    } = this.state;

    const contextState = {
      clientNumber,
      connectionState: fakeNoConnection ? NOT_CONNECTED : connectionState,
      connecting,
      colors,
      reloadApp,
      room,
      isMultiScreen,
      debug: false,
      error: null
    };

    if (connectionState === NOT_CONNECTED) {
      console.warn(
        'Rendering without being connected to the server. This is not a problem if you just started the app.'
      );
    } else if (connectionState === CONNECTED_BUT_NO_TABLE) {
      // console.warn(
      //   'We are connected to the server but the table is gone. This is not a problem if you just started the app.'
      // );
    }

    const { size } = this.props;

    return (
      <QueryClientProvider client={queryClient}>
        <AppContext.Provider value={contextState}>
          <div
            className={classNames(
              'App',
              this.props.size.width > this.props.size.height
                ? 'landscape'
                : 'portrait'
            )}
          >
            <div id="dpi"></div>
            {size.width != null && size.height != null && (
              <CurrentView
                {...currentViewAttributes}
                size={this.props.size}
                role={this.state.role}
              />
            )}
          </div>
        </AppContext.Provider>
      </QueryClientProvider>
    );
  }
}

export default App;
