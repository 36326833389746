import Util from './util';
import SquareButton from './square_button';
import { TableRect } from 'js/Table/types';
import { Image } from 'p5';
import TableP5 from 'js/Table/TableP5';

/**
 * An image that is clickable
 * @class SquareButton
 */
class ImageButton extends SquareButton {
  path: string;
  disabledPath: string;
  disabled: boolean;
  defaultRect: TableRect;
  image: Image;
  disabledImage: Image;
  imageCache: Record<string, Image>;
  rotateInactiveDegrees: number;

  constructor(path: string, disabledPath: string) {
    super();
    this.path = path;
    this.disabledPath = disabledPath;
    this.disabled = false;
    this.visible = true;
    this.menu = null;
    this.rotateInactiveDegrees = 0;
  }

  setDefault(rect) {
    this.defaultRect = rect;
  }

  renderInit(p5Instance: TableP5, imageCache: Record<string, Image>) {
    this.image = imageCache[this.path] ?? p5Instance.loadImage(this.path);

    if (this.disabledPath) {
      this.disabledImage =
        imageCache[this.disabledPath] ??
        p5Instance.loadImage(this.disabledPath);
    }
  }

  setVisible(visible: boolean) {
    this.visible = visible;
  }

  disable() {
    this.disabled = true;
  }

  enable() {
    this.disabled = false;
  }

  hit(x: number, y: number) {
    if (this.disabled || !this.visible) {
      return false;
    }
    return super.hit(x, y);
  }

  draw(p5: TableP5, overrideRect) {
    if (!this.visible) {
      return;
    }

    const defaultRect = this.defaultRect;
    const image =
      this.disabled && this.disabledPath ? this.disabledImage : this.image;

    if (image !== undefined) {
      let w = image.width;
      let h = image.height;
      p5.push();

      // The priority order is
      // 1. if the value exist in the override use that
      // 2. else use the value set on the object
      // 3. use the calculated size (and 0 position)

      this.bounding.x =
        (overrideRect && overrideRect.x) || (defaultRect && defaultRect.x) || 0;
      this.bounding.y =
        (overrideRect && overrideRect.y) || (defaultRect && defaultRect.y) || 0;
      this.bounding.w =
        (overrideRect && overrideRect.width) ||
        (defaultRect && defaultRect.width) ||
        w;
      this.bounding.h =
        (overrideRect && overrideRect.height) ||
        (defaultRect && defaultRect.height) ||
        h;

      let rotate = 0;
      if (!this.isActive()) {
        p5.tint(255, 255, 255, 140);
        rotate = this.rotateInactiveDegrees;
      }

      if (rotate != 0) {
        p5.translate(
          this.bounding.x + this.bounding.w / 2,
          this.bounding.y + this.bounding.h / 2
        );
        p5.rotate(p5.radians(rotate));
        p5.image(
          image,
          -this.bounding.w / 2,
          -this.bounding.h / 2,
          this.bounding.w,
          this.bounding.h
        );
      } else {
        p5.image(
          image,
          this.bounding.x,
          this.bounding.y,
          this.bounding.w,
          this.bounding.h
        );
      }

      // if (this.isActive()) {
      //   p5.fill(Settings.selectedColor);
      //   p5.rect(
      //     this.bounding.x,
      //     this.bounding.y + this.bounding.h + 6,
      //     this.bounding.w,
      //     4);
      // }
      p5.pop();

      return Util.SUCCESS;
    }

    return Util.NOOP;
  }
}

export default ImageButton;
