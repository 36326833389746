import React, { CSSProperties, PureComponent } from 'react';
import classNames from 'classnames';
import Tooltip from '../Tooltip/Tooltip';
import styles from './RowCell.module.css';

interface RowCellProps {
  children?: React.ReactNode;
  className?: string;
  tooltipEnabled: boolean;
  selected?: boolean;
  truncateText?: boolean;
  style?: CSSProperties;
}

class RowCell extends PureComponent<RowCellProps> {
  //
  // Local variables
  _ref: HTMLDivElement = null;

  //
  // Control

  _setRef = (ref: HTMLDivElement) => {
    this._ref = ref;
  };

  _tooltipVisibility = () => {
    const findOverflowingElement = (domNode: HTMLElement): Element => {
      const overflowing = domNode.offsetWidth < domNode.scrollWidth;

      if (overflowing) {
        return domNode;
      }

      for (const childNode of domNode.childNodes) {
        if (childNode instanceof HTMLElement) {
          const childOverflowing = findOverflowingElement(childNode);
          if (childOverflowing != null) {
            return childOverflowing;
          }
        }
      }

      return null;
    };

    const elem = findOverflowingElement(this._ref);

    return this.props.tooltipEnabled && this._ref && elem != null;
  };

  //
  // Render function

  getText = () => {
    return this._ref?.innerText ?? '';
  };

  render() {
    const { className, children, selected, truncateText, style } = this.props;

    const cssStyle = classNames(
      styles.rowCell,
      selected && styles.selected,
      className
    );

    if (!truncateText) {
      return (
        <div className={cssStyle} ref={this._setRef} style={style}>
          {children}
        </div>
      );
    }

    return (
      <Tooltip
        innerRef={this._setRef}
        className={classNames(cssStyle, styles.truncated)}
        text={this.getText}
        visibilityFn={this._tooltipVisibility}
        multiline
      >
        {children}
      </Tooltip>
    );
  }
}

export default RowCell;
