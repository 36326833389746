import { ProcessMapRect } from 'ecto-common/lib/ProcessMap/ProcessMapViewConstants';

/**
 * Calculate the Euclidean distance between two points.
 *
 * @param a - First point.
 * @param b - Second point.
 * @returns The distance between point a and point b.
 */
function distanceBetweenPoints(a: ProcessMapRect, b: ProcessMapRect): number {
  return Math.sqrt(
    Math.pow(a.centerX - b.centerX, 2) + Math.pow(a.centerY - b.centerY, 2)
  );
}

/**
 * Determine the line equation coefficients (A, B, C) for Ax + By + C = 0,
 * given two points defining the line.
 *
 * @param p0 - starting point of line
 * @param p1 - end point of line
 * @returns Array [A, B, C] representing the line equation coefficients.
 */
function lineEquation(
  p0: ProcessMapRect,
  p1: ProcessMapRect
): [number, number, number] {
  const a = p1.centerY - p0.centerY;
  const b = p0.centerX - p1.centerX;
  const c = p1.centerX * p0.centerY - p1.centerY * p0.centerX;
  return [a, b, c];
}

/**
 * Calculate the perpendicular distance from a point to a line.
 *
 * @param x - x coordinate of point to calculate the distance from.
 * @param y - y coordinate of point to calculate the distance from.
 * @param p0 - starting point of line
 * @param p1 - end point of line
 * @returns The perpendicular distance from pt to the line.
 */
export function perpendicularDistance(
  x: number,
  y: number,
  p0: ProcessMapRect,
  p1: ProcessMapRect
): number {
  const [a, b, c] = lineEquation(p0, p1);
  return Math.abs(a * x + b * y + c) / Math.sqrt(a * a + b * b);
}

/**
 * Determine if a point hits a line within a specified tolerance.
 *
 * @param x - The x coordinate of the clicked point.
 * @param y - The y coordinate of the clicked point.
 * @param p0 - starting point of line
 * @param p1 - end point of line
 * @param tolerance - Allowable distance from the line to still be considered a hit.
 * @returns True if the point hits the line within the tolerance, otherwise false.
 */
export function isPointOnLine(
  x: number,
  y: number,
  p0: ProcessMapRect,
  p1: ProcessMapRect,
  tolerance: number
): boolean {
  // Check Bounding Box
  const minX = Math.min(p0.centerX, p1.centerX) - tolerance;
  const maxX = Math.max(p0.centerX, p1.centerX) + tolerance;
  const minY = Math.min(p0.centerY, p1.centerY) - tolerance;
  const maxY = Math.max(p0.centerY, p1.centerY) + tolerance;

  if (x < minX || x > maxX || y < minY || y > maxY) {
    return false; // Point is outside bounding box
  }

  // Check Perpendicular Distance
  if (perpendicularDistance(x, y, p0, p1) > tolerance) {
    return false; // Point is too far from the line
  }

  // Check Segment Limits
  const d1 = distanceBetweenPoints(
    { centerX: x, centerY: y, width: 1, height: 1, id: null },
    p0
  );
  const d2 = distanceBetweenPoints(
    { centerX: x, centerY: y, width: 1, height: 1, id: null },
    p1
  );
  const lineLength = distanceBetweenPoints(p0, p1);

  return d1 + d2 <= lineLength + tolerance;
}
