// @ts-ignore-next-line
import { jspack } from 'jspack';

const decodeInteger = (data: Uint8Array) => {
  return {
    value: jspack.Unpack('>i', data.slice(0, 4))[0],
    data: data.slice(4)
  };
};

const decoder = new TextDecoder();

interface ParseResult<T> {
  value: T;
  data: Uint8Array;
}

const decodeString = (data: Uint8Array): ParseResult<string> => {
  let endIndex = data.indexOf(0);
  if (endIndex === -1) {
    endIndex = data.length;
  }

  return {
    value: decoder.decode(data.slice(0, endIndex)),
    data: data.slice(Math.ceil((endIndex + 1) / 4) * 4)
  };
};

const decodeFloat = (data: Uint8Array): ParseResult<number> => {
  return {
    value: jspack.Unpack('>f', data.slice(0, 4))[0],
    data: data.slice(4)
  };
};

const decodeTime = (data: Uint8Array): ParseResult<number> => {
  const time = jspack.Unpack('>LL', data.slice(0, 8));
  const seconds = time[0];
  const fraction = time[1];

  return {
    data: data.slice(8),
    value: seconds + fraction / 4294967296
  };
};

const decodeBundle = (data: Uint8Array) => {
  const message: any[] = [];
  const time = decodeTime(data);
  let bundleSize = null;
  let content = null;

  data = time.data;

  message.push('#bundle');
  message.push(time.value);

  while (data.length > 0) {
    bundleSize = decodeInteger(data);
    data = bundleSize.data;

    content = data.slice(0, bundleSize.value);
    message.push(decodeTUIOPacket(content));

    data = data.slice(bundleSize.value, data.length);
  }

  return message;
};

const decodeByTypeTag = (typeTag: string, data: any) => {
  switch (typeTag) {
    case 'i':
      return decodeInteger(data);
    case 'f':
      return decodeFloat(data);
    case 's':
      return decodeString(data);
  }
};

const decodeMessage = (address: ParseResult<string>, inputData: Uint8Array) => {
  const message: any[] = [];
  message.push(address.value);

  const typeTagsResult = decodeString(inputData);
  let data = typeTagsResult.data;
  const typeTags: string = typeTagsResult.value;

  if (typeTags[0] === ',') {
    for (let i = 1; i < typeTags.length; i++) {
      const arg = decodeByTypeTag(typeTags[i], data);
      data = arg.data;
      message.push(arg.value);
    }
  }

  return message;
};

export const decodeTUIOPacket = (data: Uint8Array): any[] => {
  const address = decodeString(data);

  if (address.value === '#bundle') {
    return decodeBundle(address.data);
  } else if (data.length > 0) {
    return decodeMessage(address, address.data);
  }

  return [];
};
