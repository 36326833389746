import React from 'react';

const CoolingIcon = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 5.625H10.65L11.5125 4.7625L10.9875 4.2375L9.6 5.625H6.9L8.8125 3.7125H10.7625V2.9625H9.5625L10.5 2.025L9.975 1.5L9.0375 2.4375V1.2375H8.2875V3.1875L6.375 5.1V2.4L7.7625 1.0125L7.2375 0.4875L6.375 1.35V0H5.625V1.35L4.7625 0.4875L4.2375 1.0125L5.625 2.4V5.1L3.7125 3.1875V1.2375H2.9625V2.4375L2.025 1.5L1.5 2.025L2.4375 2.9625H1.2375V3.7125H3.1875L5.1 5.625H2.4L1.0125 4.2375L0.4875 4.7625L1.35 5.625H0V6.375H1.35L0.4875 7.2375L1.0125 7.7625L2.4 6.375H5.1L3.1875 8.2875H1.2375V9.0375H2.4375L1.5 9.975L2.025 10.5L2.9625 9.5625V10.7625H3.7125V8.8125L5.625 6.9V9.6L4.2375 10.9875L4.7625 11.5125L5.625 10.65V12H6.375V10.65L7.2375 11.5125L7.7625 10.9875L6.375 9.6V6.9L8.2875 8.8125V10.7625H9.0375V9.5625L9.975 10.5L10.5 9.975L9.5625 9.0375H10.7625V8.2875H8.8125L6.9 6.375H9.6L10.9875 7.7625L11.5125 7.2375L10.65 6.375H12V5.625Z"
        fill="white"
      />
    </svg>
  );
};

export default React.memo(CoolingIcon);
