import Highcharts from 'highcharts/highstock';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsDraggablePoints from 'highcharts/modules/draggable-points';
import HighchartsAnnotations from 'highcharts/modules/annotations';
import HighchartsSolidGauge from 'highcharts/modules/solid-gauge';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsOfflineExporting from 'highcharts/modules/offline-exporting';
import HighchartsAccessibility from 'highcharts/modules/accessibility';
import HighchartsBoost from 'highcharts/modules/boost';

HighchartsMore(Highcharts);
HighchartsDraggablePoints(Highcharts);
HighchartsAnnotations(Highcharts);
HighchartsSolidGauge(Highcharts);
HighchartsExporting(Highcharts);
HighchartsOfflineExporting(Highcharts);
HighchartsAccessibility(Highcharts);
HighchartsBoost(Highcharts);

// Add center line at 'crossing' value
(function (H) {
  H.wrap(H.Axis.prototype, 'render', function (proceed) {
    if (this.options.crossing != null) {
      this.offset = this.chart[this.isXAxis ? 'yAxis' : 'xAxis'][0]?.toPixels(
        this.options.crossing,
        true
      );
    }
    proceed.call(this);
  });
})(Highcharts);

export { Highcharts };
