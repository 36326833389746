import React from 'react';
import T from 'ecto-common/lib/lang/Language';
import Icons from 'ecto-common/lib/Icons/Icons';
import StandardButton, {
  StandardButtonProps
} from 'ecto-common/lib/Button/StandardButton';

type DuplicateButtonProps = StandardButtonProps & {
  tooltipText?: string;
};

const DuplicateButton = ({
  tooltipText = null,
  ...props
}: DuplicateButtonProps) => (
  <StandardButton
    {...props}
    icon={<Icons.Copy />}
    tooltipText={tooltipText || T.common.duplicate}
  />
);

export default DuplicateButton;
