export default class ElectricityNode {
  capacity = 0;
  attenuation = 0;
  scale = 0;

  getCurrentEffectMW() {
    return this.capacity * this.scale * this.attenuation;
  }

  getMaxEffectMW() {
    return this.capacity * this.scale;
  }
}
