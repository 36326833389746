import React from 'react';
import SvgIcon, { SvgIconProps } from './SvgIcon';
import styles from './Device.module.css';

const Device = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 42 42" {...props}>
    <g>
      <rect
        className={styles.cls1}
        x="10"
        y="5"
        width="22"
        height="32"
        rx="4"
        ry="4"
      />
      <circle className={styles.cls1} cx="21" cy="17" r="5" />
      <circle className={styles.cls1} cx="21" cy="30" r="2" />
    </g>
  </SvgIcon>
);

export default Device;
