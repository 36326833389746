import Renderable from './renderable';
import CountryButton from './CountryButton';

import GlobalSettings from './Settings';

import * as d3 from 'd3-ease';
import TableP5 from 'js/Table/TableP5';
import p5 from 'p5';
import pinImageUrl from '../assets/location_pin.svg';

let ANIMATION_DURATION = 30;

const Settings = GlobalSettings.locationButton;

export const COUNTRY_BUTTON_STATE = {
  CONTRACTED: 0,
  EXPANDED: 1
};

/**
 * The Location selection menu
 * @class CountryButtons
 */
class CountryButtons extends Renderable {
  initiallySelected: boolean;
  listeners: any[];
  indexOfHighlight: number;
  buttons: CountryButton[];
  bounding: any;
  STATES: any;
  touchStarted: (x: number, y: number) => void;
  chosen?: CountryButton;
  chosenIndex: number;
  prevChosenIndex: number;
  state: number;
  lastHighlightIndex: number;
  current: any;
  _debugTouch: {
    x: number;
    y: number;
  };
  highlightTimer: number;
  fonts: any;
  pressed: boolean;
  animationStart: number = null;
  p5Instance: TableP5;
  pinImage: p5.Image = null;

  static STATES: any;

  constructor(context) {
    super(context);

    this.initiallySelected = false;
    this.name = 'CountryButtons';
    this.listeners = [];

    this.indexOfHighlight = undefined;

    this.buttons = [];
    GlobalSettings.places.forEach((place) => {
      const button = new CountryButton(context, place);

      this.buttons.push(button);
      button.addListener(() => {
        this.listeners.forEach((listener) => {
          if (listener.countryClicked !== undefined) {
            listener.countryClicked(button);
          }
        });
      });
    });

    this.touchStarted = this.mousePressed;

    this.bounding = {
      x: 0,
      y: 0,
      w: 0,
      h: 0,
      inside: function (_x, _y) {
        return (
          _x > this.x &&
          _x < this.x + this.w &&
          _y > this.y &&
          _y < this.y + this.h
        );
      }
    };

    this.STATES = COUNTRY_BUTTON_STATE;
    this.setState(COUNTRY_BUTTON_STATE.EXPANDED);
  }

  /**
   * @param {object} state one of the states in the STATES selection
   * @throws {String} Message about bad in parameters
   * @returns {RESULTS} Success or Failure code
   */
  setState(state) {
    if (Object.values(COUNTRY_BUTTON_STATE).indexOf(state) === -1) {
      throw 'CountryButtons.setState got called with bad value';
    }

    if (this.animationStart != null) {
      const elapsedFrames = this.p5Instance.frameCount - this.animationStart;
      if (elapsedFrames < ANIMATION_DURATION) {
        this.prevChosenIndex = this.chosenIndex;
      }
    }

    if (this.state !== state && this.p5Instance) {
      this.animationStart = this.p5Instance.frameCount;
    }

    this.state = state;
  }

  setLocation(chosenPlace) {
    this.chosen = undefined;
    this.lastHighlightIndex = this.indexOfHighlight || this.chosenIndex;

    const index = this.buttons.findIndex(
      (button) => button.place === chosenPlace
    );

    if (index !== -1) {
      this.chosen = this.buttons[index];
      this.buttons.forEach((button) => button.unselect());
      this.chosen.select();
      this.prevChosenIndex = this.chosenIndex;
      this.chosenIndex = index;
    }
  }

  addListener(listener) {
    this.listeners.push(listener);
  }

  renderInit(p5Instance: TableP5, fonts) {
    this.fonts = fonts;
    this.p5Instance = p5Instance;
    this.pinImage = p5Instance.loadImage(pinImageUrl);
  }

  draw(p5Instance: TableP5) {
    p5Instance.noStroke();
    p5Instance.textFont(this.fonts.medium);

    const renderW = p5Instance.renderWidth;

    const rect = this.bounding;

    rect.w = Settings.width;
    const buttonWidth = rect.w;
    let buttonHeight = Settings.height;

    if (this.state === COUNTRY_BUTTON_STATE.CONTRACTED) {
      rect.h = Settings.contractedHeight;
    } else {
      rect.h = Settings.height * (this.buttons.length + 1);
    }

    let elapsedFrames = ANIMATION_DURATION;
    let t = this.state === COUNTRY_BUTTON_STATE.CONTRACTED ? 0 : 1;

    if (this.animationStart != null) {
      elapsedFrames = p5Instance.frameCount - this.animationStart;
      t =
        elapsedFrames < ANIMATION_DURATION
          ? d3.easeCubicOut(elapsedFrames / ANIMATION_DURATION)
          : 1;

      if (this.state === COUNTRY_BUTTON_STATE.CONTRACTED) {
        t = 1 - t;
      }
    }

    rect.x = renderW - rect.w;
    rect.y = 0;

    const marginBetween = 0;
    const verticalOffsetForEachButton = buttonHeight + marginBetween;

    let buttonX = rect.x;
    let buttonY = rect.y + Settings.verticalGutter;

    const topButtonRect = {
      x: buttonX,
      y: buttonY,
      width: buttonWidth,
      height: Settings.height
    };

    if (
      this.state === COUNTRY_BUTTON_STATE.EXPANDED ||
      elapsedFrames < ANIMATION_DURATION
    ) {
      p5Instance.fill(0, 0, 0, t * 170);
      p5Instance.rect(0, 0, p5Instance.renderWidth, p5Instance.renderHeight);
    }

    if (elapsedFrames > ANIMATION_DURATION) {
      this.prevChosenIndex = this.chosenIndex;
    }

    const showPrev =
      this.prevChosenIndex != null &&
      this.prevChosenIndex !== this.chosenIndex &&
      elapsedFrames < ANIMATION_DURATION;
    const chosenButton = this.buttons[this.chosenIndex];
    chosenButton.highlighted = true;
    chosenButton.opacity = showPrev ? (1 - t) * 255 : 255;
    chosenButton.draw(p5Instance, topButtonRect);
    chosenButton.highlighted = false;

    if (showPrev) {
      this.buttons[this.prevChosenIndex].opacity = t * 255;
      this.buttons[this.prevChosenIndex].highlighted = true;
      this.buttons[this.prevChosenIndex].draw(p5Instance, topButtonRect);
      this.buttons[this.prevChosenIndex].highlighted = false;
      this.buttons[this.prevChosenIndex].opacity = 255;
    }

    if (this.pinImage) {
      const chosenRect = this.buttons[this.chosenIndex].bounding;
      let prevTextWidth = chosenButton.textWidth;

      if (showPrev) {
        prevTextWidth = this.buttons[this.prevChosenIndex].textWidth;
      }

      let textWidth =
        chosenButton.textWidth + (prevTextWidth - chosenButton.textWidth) * t;
      let textX = chosenRect.x + chosenRect.w - Settings.horisontalPadding;
      p5Instance.image(
        this.pinImage,
        textX - textWidth - this.pinImage.width - 17,
        chosenRect.y + 13
      );
    }

    buttonY += verticalOffsetForEachButton;

    if (
      this.state === COUNTRY_BUTTON_STATE.EXPANDED ||
      elapsedFrames < ANIMATION_DURATION
    ) {
      const l = this.buttons.length;

      for (let i = 0; i < l; i++) {
        if (i !== this.chosenIndex) {
          const buttonRect = {
            x: buttonX + (1 - t) * buttonWidth,
            y: buttonY,
            width: buttonWidth,
            height: buttonHeight
          };

          this.buttons[i].draw(p5Instance, buttonRect);
          buttonY += verticalOffsetForEachButton;
        }
      }
    }

    const helpText = 'Click to select location';

    p5Instance.textSize(Settings.helpFontSize);
    p5Instance.textAlign(p5Instance.RIGHT, p5Instance.BOTTOM);
    p5Instance.fill(0x90, 0x90, 0x90, 255 * (1 - t));
    p5Instance.text(
      helpText,
      rect.x + rect.w - Settings.horisontalPadding,
      110
    );
  }

  /**
   * Determines if x, y hit the general area of the list of buttons.
   */
  hit(x, y) {
    return this.bounding.inside(x, y);
  }

  mousePressed(x, y) {
    this.current = undefined;
    const l = this.buttons.length;

    if (this.state === COUNTRY_BUTTON_STATE.CONTRACTED) {
      return null;
    }

    for (let i = l - 1; i >= 0; i--) {
      const button = this.buttons[i];

      this.current = button.mousePressed(x, y);
      if (this.current) {
        console.info(
          button.place.city + ' claimed the press' + x + ', y: ' + y
        );
        break;
      }
    }

    if (!this.current) {
      if (this.hit(x, y)) {
        console.info('CountryButtons claimed the press', x, y);
        this.current = this;
        this.pressed = true;
        this._debugTouch = { x, y };
      }
    }
    return this.current;
  }

  mouseDragged(_x: number, _y: number): boolean {
    return false;
  }

  mouseReleased(x, y) {
    let consumed = false;
    let releasedOn;
    const l = this.buttons.length;

    if (
      this.state === COUNTRY_BUTTON_STATE.CONTRACTED &&
      this.bounding.inside(x, y)
    ) {
      this.setState(COUNTRY_BUTTON_STATE.EXPANDED);
      this.highlightTimer = this.context.appNow();
      return null;
    }

    for (let i = l - 1; i >= 0; i--) {
      const button = this.buttons[i];

      if (
        i != this.chosenIndex &&
        this.state === COUNTRY_BUTTON_STATE.CONTRACTED
      ) {
        continue;
      }

      // Note, that we need to do release on All the buttons,
      // to make sure that they are all in non pressed state
      if (button.mouseReleased(x, y)) {
        releasedOn = button;
      }
    }

    if (!releasedOn) {
      if (this.hit(x, y)) {
        console.info('CountryButtons claimed the release', x, y);
        this.setState(COUNTRY_BUTTON_STATE.CONTRACTED);
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        releasedOn = this;
      }
    }

    if (this.current === releasedOn) {
      if (releasedOn === this) {
        this.listeners.forEach((listener) => {
          if (listener.countryClicked !== undefined) {
            listener.countryClicked(undefined);
          }
        });
      }
      consumed = true;
    }

    if (releasedOn && releasedOn !== this) {
      this.initiallySelected = true;
      this.setState(COUNTRY_BUTTON_STATE.CONTRACTED);
      this.highlightTimer = this.context.appNow();
    }

    this.pressed = false;

    this._debugTouch = undefined;

    this.current = undefined;
    return consumed;
  }

  touchEnded(touch) {
    return this.mouseReleased(touch.clientX, touch.clientY);
  }
}

CountryButtons.STATES = COUNTRY_BUTTON_STATE;

export default CountryButtons;
