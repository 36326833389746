import React, {
  useMemo,
  useCallback,
  useEffect,
  useState,
  createContext,
  useContext
} from 'react';
import {
  useIsAuthenticated,
  useMsal,
  useAccount,
  AuthenticatedTemplate,
  UnauthenticatedTemplate
} from '@azure/msal-react';
import {
  InteractionStatus,
  EventType,
  InteractionRequiredAuthError,
  IPublicClientApplication,
  AccountInfo,
  AuthenticationResult,
  AuthError,
  SilentRequest
} from '@azure/msal-browser';

import AuthenticationWrapper from 'ecto-common/lib/AuthenticationWrapper/AuthenticationWrapper';
import { getSelectedLanguage } from 'ecto-common/lib/utils/localStorageUtil';
import UserContext from 'ecto-common/lib/hooks/UserContext';

export const handleMSALNeedsInteraction = (
  error: InteractionRequiredAuthError,
  instance: IPublicClientApplication,
  request: SilentRequest
) => {
  if (error instanceof InteractionRequiredAuthError && !isAuthRedirecting()) {
    // fallback to interaction when silent call fails
    // We think this is a bug in the MSAL library, where it doesn't handle the AADB2C90077 error correctly.
    // This is a workaround for that bug. If we do not logout, the user will be stuck in an endless loop.
    const invalidSessionError =
      (error?.message ?? '').indexOf('AADB2C90077') > -1;
    if (invalidSessionError) {
      console.error(
        'Got AADB2C90077 invalid session without prompt error, logging out'
      );
      instance.logoutRedirect({ postLogoutRedirectUri: '/' });
    } else if (error?.errorCode === 'no_tokens_found') {
      // Same with this one, will be stuck in an endless loop if we don't logout.
      console.error('Got no tokens found, logging out');
      instance.logoutRedirect({ postLogoutRedirectUri: '/' });
    } else {
      console.error(
        'acquireTokenSilent error, fallback to interaction',
        error,
        error?.errorMessage
      );
      instance.acquireTokenRedirect(request);
    }

    setIsAuthRedirecting(true);
    return true;
  }

  return false;
};

const extraQueryParameters = { ui_locales: getSelectedLanguage() };

interface AuthenticatedAreaProps {
  children?: React.ReactNode;
}

export const AuthenticatedArea = ({ children }: AuthenticatedAreaProps) => {
  const authenticationRequest = { extraQueryParameters };

  // eslint-disable-next-line react/prop-types
  return (
    <>
      <UnauthenticatedTemplate>
        <AuthenticationWrapper authenticationRequest={authenticationRequest} />
      </UnauthenticatedTemplate>

      <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
    </>
  );
};

let IsMSALRedirecting = false;

export const setIsAuthRedirecting = (isAuthRedirecting: boolean) => {
  IsMSALRedirecting = isAuthRedirecting;
};

export const isAuthRedirecting = () => IsMSALRedirecting;

export const useLogout = () => {
  const { instance, accounts } = useMsal();
  const { signIn } = useContext(UserContext);
  const currentAccount = useAccount(accounts[0] || {});

  return useCallback(() => {
    if (currentAccount) {
      signIn?.(null);
      setIsAuthRedirecting(true);
      instance.logoutRedirect({
        postLogoutRedirectUri: '/'
      });
    }
  }, [signIn, currentAccount, instance]);
};

const useAuthentication = (scope: string) => {
  const isAuthenticated = useIsAuthenticated();
  const { inProgress, instance, accounts } = useMsal();
  const currentAccount = useAccount(accounts[0] || {});
  const isLoading = inProgress === InteractionStatus.Login;
  const [errorMessage, setErrorMessage] = useState<AuthError>(null);
  const { signIn } = useContext(UserContext);

  const onAuthenticateCallback = useCallback(
    (response: AuthenticationResult) => {
      signIn?.(response);
    },
    [signIn]
  );

  useEffect(() => {
    const callbackId = instance.addEventCallback(
      (message: {
        eventType: EventType;
        error?: { errorMessage: AuthError; errorCode: string };
      }) => {
        switch (message.eventType) {
          case EventType.LOGIN_FAILURE:
          case EventType.SSO_SILENT_FAILURE:
          case EventType.LOGOUT_FAILURE:
            return setErrorMessage(message.error.errorMessage);
          case EventType.ACQUIRE_TOKEN_FAILURE:
            console.error(
              'Authentication got ACQUIRE_TOKEN_FAILURE, error',
              message?.error?.errorCode
            );

            if (message.error.errorCode === 'no_tokens_found') {
              return setErrorMessage(message.error.errorMessage);
            }

            // In this case we always redirect to the login page, so no point in showing error
            break;
          default:
            return;
        }
      }
    );

    return () => {
      instance.removeEventCallback(callbackId);
    };
  }, [instance]);

  /**
   * First initial authenticate that sets the prerequisites for all the api calls in MainContainer.js.
   * Otherwise `acquireTokenSilent` is executed from:
   * - ecto-common/lib/utils/APIFetch.js
   * - ecto-common/lib/EventHubConnection/EventHubService.js
   */
  const onAuthenticate = useCallback(
    (
      authScope: string,
      account: AccountInfo,
      onSuccess: (res: AuthenticationResult) => void
    ) => {
      const accessTokenRequest = { scopes: [authScope], account };

      instance
        .acquireTokenSilent(accessTokenRequest)
        .then((response) => {
          if (response) {
            onSuccess?.(response);
          }
        })
        .catch(async (error) => {
          handleMSALNeedsInteraction(error, instance, accessTokenRequest);
        });
    },
    [instance]
  );

  useEffect(() => {
    if (
      currentAccount &&
      scope &&
      inProgress === InteractionStatus.None &&
      !isAuthRedirecting() &&
      isAuthenticated
    ) {
      onAuthenticate(scope, currentAccount, onAuthenticateCallback);
    }
  }, [
    onAuthenticateCallback,
    currentAccount,
    onAuthenticate,
    scope,
    inProgress,
    isAuthenticated
  ]);

  return useMemo(
    () => ({
      isAuthenticated,
      isLoading,
      onAuthenticate,
      errorMessage,
      instance,
      currentAccount,
      isLoggingOut: inProgress === InteractionStatus.Logout
    }),
    [
      currentAccount,
      errorMessage,
      instance,
      isAuthenticated,
      isLoading,
      onAuthenticate,
      inProgress
    ]
  );
};

export default useAuthentication;

type AuthenticationContextProps = {
  scopes?: Record<string, string>;
  msalConfiguration: IPublicClientApplication;
  currentAccount: AccountInfo;
};

export const AuthenticationContext =
  createContext<AuthenticationContextProps>(null);
