import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useMemo,
  MouseEventHandler,
  KeyboardEventHandler
} from 'react';
import classNames from 'classnames';
import _ from 'lodash';

import { RemoveIcon } from 'ecto-common/lib/Icon';
import styles from 'ecto-common/lib/TextInput/TextInput.module.css';
import {
  KEY_CODE_ARROW_DOWN,
  KEY_CODE_ARROW_UP,
  KEY_CODE_ENTER,
  KEY_CODE_TAB
} from 'ecto-common/lib/constants';
import Spinner, { SpinnerSize } from 'ecto-common/lib/Spinner/Spinner';

export const getInputPaddingStyle = (
  icon: React.ReactNode,
  rightSideIcon: React.ReactNode
) => {
  if (rightSideIcon && icon) {
    return styles.hasTwoIcons;
  } else if (icon) {
    return styles.hasLeftIcon;
  } else if (rightSideIcon) {
    return styles.hasRightIcon;
  }

  return null;
};

export const textInputOverrideHeight = {
  height: '100%'
};

export type BaseTextInputProps = {
  disabled?: boolean;
  icon?: React.ReactNode;
  rightSideIcon?: React.ReactNode;
  onClickRightSideIcon?: MouseEventHandler<HTMLDivElement>;
  error?: boolean;
  multiline?: boolean;
  wrapperClassName?: string;
  clearButton?: React.ReactNode;
  autoFocus?: boolean;
  showFocusHighlight?: boolean;
  onEnterKeyPressed?: KeyboardEventHandler<HTMLInputElement>;
  onTabKeyPressed?: KeyboardEventHandler<HTMLInputElement>;
  onClear?: MouseEventHandler<SVGSVGElement>;
  onArrowUpKeyPressed?: KeyboardEventHandler<HTMLInputElement>;
  onArrowDownKeyPressed?: KeyboardEventHandler<HTMLInputElement>;
  isLoading?: boolean;
  textFormatter?: (text?: string, source?: HTMLInputElement) => string;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    newValue: string
  ) => void;
};

export type TextInputProps = Omit<
  React.HTMLProps<HTMLInputElement>,
  'onChange'
> &
  BaseTextInputProps;

type PossibleTextInputTypes = HTMLInputElement | HTMLTextAreaElement;

export function useTextInput<TextInputType extends PossibleTextInputTypes>({
  externalValue,
  autoFocus,
  inputRef,
  setValue,
  textFormatter,
  onChange,
  onFocus,
  onBlur,
  setFocus,
  onEnterKeyPressed,
  onTabKeyPressed,
  onArrowUpKeyPressed,
  onArrowDownKeyPressed,
  onClear = null
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  externalValue: any; // TODO: This should be string really, the issue is that the DOM component can pass string, readonly string[] or number. We should find a good workaround.
  autoFocus: boolean;
  inputRef: React.MutableRefObject<TextInputType>;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  textFormatter: (text?: string, source?: TextInputType) => string;
  onChange: (event: React.ChangeEvent<TextInputType>, newValue: string) => void;
  onFocus: (event: React.FocusEvent<TextInputType>) => void;
  onBlur: (event: React.FocusEvent<TextInputType>) => void;
  setFocus: React.Dispatch<React.SetStateAction<boolean>>;
  onEnterKeyPressed: KeyboardEventHandler<TextInputType>;
  onTabKeyPressed: KeyboardEventHandler<TextInputType>;
  onArrowUpKeyPressed: KeyboardEventHandler<TextInputType>;
  onArrowDownKeyPressed: KeyboardEventHandler<TextInputType>;
  onClear: MouseEventHandler<SVGSVGElement>;
}) {
  const safeExternalValue = externalValue ?? '';

  const onClearClick: React.MouseEventHandler<SVGSVGElement> = useCallback(
    (e) => {
      if (onClear) {
        onClear(e);
      } else {
        // TODO: This is a really really ugly design decision. The two event types are really different.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onChange?.(e as any, '');
      }
    },
    [onChange, onClear]
  );

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      _.defer(() => inputRef.current?.focus?.());
    }
  }, [autoFocus, inputRef]);

  useEffect(() => {
    let newValue = safeExternalValue;

    if (textFormatter != null) {
      newValue = textFormatter(newValue, inputRef.current);
    }

    setValue(newValue);
  }, [safeExternalValue, inputRef, textFormatter, setValue]);

  const _onChange = useCallback(
    (event: React.ChangeEvent<TextInputType>) => {
      let newValue = event.target.value;

      if (textFormatter != null) {
        newValue = textFormatter(newValue, inputRef.current);
      }

      event.persist();

      setValue(newValue);

      onChange?.(event, newValue);
    },
    [onChange, textFormatter, inputRef, setValue]
  );

  const _onFocus = useCallback(
    (e: React.FocusEvent<TextInputType>) => {
      onFocus?.(e);
      setFocus(true);
    },
    [onFocus, setFocus]
  );

  const _onBlur = useCallback(
    (e: React.FocusEvent<TextInputType>) => {
      onBlur?.(e);
      setFocus(false);
    },
    [onBlur, setFocus]
  );

  const handleKeyDown: React.KeyboardEventHandler<TextInputType> = useCallback(
    (e) => {
      if (e.keyCode === KEY_CODE_ENTER && onEnterKeyPressed) {
        onEnterKeyPressed(e);
      } else if (e.keyCode === KEY_CODE_TAB && onTabKeyPressed) {
        onTabKeyPressed(e);
      } else if (e.keyCode === KEY_CODE_ARROW_UP && onArrowUpKeyPressed) {
        onArrowUpKeyPressed(e);
      } else if (e.keyCode === KEY_CODE_ARROW_DOWN && onArrowDownKeyPressed) {
        onArrowDownKeyPressed(e);
      }
    },
    [
      onEnterKeyPressed,
      onTabKeyPressed,
      onArrowUpKeyPressed,
      onArrowDownKeyPressed
    ]
  );

  return [_onChange, _onFocus, _onBlur, handleKeyDown, onClearClick] as const;
}

export const textInputWrapperClassnames = ({
  error,
  disabled,
  focus,
  showFocusHighlight,
  wrapperClassName
}: {
  error?: boolean;
  disabled?: boolean;
  focus?: boolean;
  showFocusHighlight?: boolean;
  wrapperClassName?: string;
}) =>
  classNames(
    styles.wrapper,
    error && styles.error,
    disabled && styles.disabledField,
    focus && showFocusHighlight && styles.focus,
    wrapperClassName
  );

export const TextInputIcon = ({
  icon,
  onFocusClick
}: {
  icon: React.ReactNode;
  onFocusClick: MouseEventHandler<HTMLDivElement>;
}) => {
  return (
    <div onClick={onFocusClick} className={styles.iconWrapper}>
      {icon}
    </div>
  );
};

type TextInputRightSideIconsProps = {
  isLoading?: boolean;
  disabled?: boolean;
  rightSideIcon?: React.ReactNode;
  onClickRightSideIcon?: MouseEventHandler<HTMLDivElement>;
  clearButton?: React.ReactNode;
  onClearClick?: MouseEventHandler<SVGSVGElement>;
  value: string | readonly string[] | number;
};

export const TextInputRightSideIcons = ({
  isLoading,
  rightSideIcon,
  onClickRightSideIcon,
  disabled,
  clearButton,
  onClearClick,
  value
}: TextInputRightSideIconsProps) => {
  return (
    <>
      {isLoading && (
        <div className={styles.spinnerContainer}>
          <Spinner size={SpinnerSize.TINY} />
        </div>
      )}

      {rightSideIcon && !disabled && (
        <div onClick={onClickRightSideIcon} className={styles.iconWrapperRight}>
          {rightSideIcon}
        </div>
      )}

      {clearButton && (
        <RemoveIcon
          onClick={onClearClick}
          className={classNames(
            styles.clearButton,
            value === '' && styles.disabled
          )}
        />
      )}
    </>
  );
};

/**
 * Base component for all text input. Often you should not use this directly. KeyValueInput is a better alternative - it wraps this component with a label and other features.
 */
const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      disabled = false,
      onChange = null,
      icon,
      rightSideIcon,
      onClickRightSideIcon,
      error = false,
      className,
      value: externalValue,
      wrapperClassName = null,
      clearButton = null,
      autoFocus = false,
      type = 'text',
      showFocusHighlight = true,
      onBlur = null,
      onFocus = null,
      onEnterKeyPressed = null,
      onTabKeyPressed = null,
      placeholder,
      onClear = null,
      onArrowUpKeyPressed = null,
      onArrowDownKeyPressed = null,
      textFormatter = null,
      isLoading = false,
      ...otherProps
    },
    ref
  ) => {
    const safeExternalValue = externalValue ?? '';
    const [value, setValue] = useState(
      textFormatter?.(safeExternalValue as string) ?? safeExternalValue
    );
    const [focus, setFocus] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    const [_onChange, _onFocus, _onBlur, handleKeyDown, onClearClick] =
      useTextInput({
        externalValue,
        autoFocus,
        inputRef,
        textFormatter,
        onChange,
        onFocus,
        onBlur,
        setFocus,
        onEnterKeyPressed,
        onTabKeyPressed,
        onArrowUpKeyPressed,
        onArrowDownKeyPressed,
        setValue,
        onClear
      });

    // Remove default value
    const cleanProps = useMemo(
      () =>
        _.omit({ ...otherProps }, [
          'defaultValue',
          'triggerFocus',
          'autoFocus',
          'onEnterKeyPressed'
        ]),
      [otherProps]
    );
    const padStyle = getInputPaddingStyle(icon, rightSideIcon);

    const onFocusClick = useCallback(() => {
      _.defer(() => inputRef?.current?.focus?.());
    }, []);

    return (
      <div
        className={textInputWrapperClassnames({
          error,
          disabled,
          focus,
          showFocusHighlight,
          wrapperClassName
        })}
      >
        {icon && <TextInputIcon icon={icon} onFocusClick={onFocusClick} />}

        <input
          {...cleanProps}
          className={classNames(styles.input, padStyle, className)}
          style={cleanProps?.rows && textInputOverrideHeight}
          onChange={_onChange}
          onFocus={_onFocus}
          onBlur={_onBlur}
          value={value}
          disabled={disabled}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          type={type}
          ref={(node) => {
            inputRef.current = node;
            if (typeof ref === 'function') {
              ref(node);
            } else if (ref) {
              ref.current = node;
            }
          }}
          autoComplete="off"
        />

        <TextInputRightSideIcons
          rightSideIcon={rightSideIcon}
          onClickRightSideIcon={onClickRightSideIcon}
          onClearClick={onClearClick}
          isLoading={isLoading}
          disabled={disabled}
          clearButton={clearButton}
          value={value}
        />
      </div>
    );
  }
);

export default React.memo(TextInput);
