import React, { Component } from 'react';
import styles from './AlarmSeverityCircle.module.css';
import AlarmSeverity from './AlarmSeverity';

interface AlarmSeverityCircleProps {
  severity: number;
}

class AlarmSeverityCircle extends Component<AlarmSeverityCircleProps> {
  render() {
    const { severity } = this.props;

    return (
      <div className={styles.alarmSeverityCircle}>
        <AlarmSeverity severity={severity} />
      </div>
    );
  }
}

export default AlarmSeverityCircle;
