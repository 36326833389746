import React from 'react';
import SvgIcon, { SvgIconProps } from './SvgIcon';
import styles from './Fuse.module.css';

const Fuse = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 42 42" {...props}>
    <g>
      <path
        className={styles.fuse}
        d="M30.62,17H29.3c-2.06,0-7.83-4-8.33-4H16.38A1.38,1.38,0,0,0,15,14.38V27.62A1.38,1.38,0,0,0,16.38,29H21c.49,0,6.19-4,8.33-4h1.32A1.38,1.38,0,0,0,32,23.62V18.38A1.38,1.38,0,0,0,30.62,17Z"
      />
      <path
        className={styles.fuse}
        d="M32,21h2.58A2.42,2.42,0,0,1,37,23.42V30"
      />
      <line className={styles.fuse} x1="6" y1="17" x2="12" y2="17" />
      <line className={styles.fuse} x1="6" y1="26" x2="12" y2="26" />
    </g>
  </SvgIcon>
);

export default Fuse;
