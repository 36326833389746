import React, { useCallback } from 'react';
import { useCommonSelector } from '../../reducers/storeCommon';
import { ProcessMapObjectsProps } from '../ProcessMapObjectProps';
import {
  ProcessMapSignalTextObject,
  defaultSignalSubtitleSettings,
  defaultSignalTitleSettings
} from '../ProcessMapViewConstants';
import {
  ProcessMapSignalView,
  ProcessMapSignalViewValue
} from '../View/ProcessMapSignalView';
import { ModelFormSectionType } from 'ecto-common/lib/ModelForm/ModelPropType';
import { CustomNodeTreeSet } from 'ecto-common/lib/SelectNodeDialog/SelectNode';
import T from 'ecto-common/lib/lang/Language';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import { textAlignmentOption, textStyleSections } from './commonModels';

export const SignalView = ({
  allSignalsBySignalTypeOrSignalId,
  node: nodeIn,
  signalData,
  updateTextSize,
  objectIndex,
  selectedRectHandles,
  isLoading,
  showSignalLabelsWhenNotFound,
  isHovering,
  onClick,
  onMouseOver,
  onMouseOut
}: ProcessMapObjectsProps) => {
  const signalTypesMap = useCommonSelector(
    (state) => state.general.signalTypesMap
  );
  const signalUnitTypesMap = useCommonSelector(
    (state) => state.general.signalUnitTypesMap
  );

  const node = nodeIn as ProcessMapSignalTextObject;
  const signal =
    allSignalsBySignalTypeOrSignalId[node.signalTypeId] ??
    allSignalsBySignalTypeOrSignalId[node.signalId];
  const value: ProcessMapSignalViewValue = signalData[signal?.signalId];
  const signalType = signalTypesMap[signal?.signalTypeId ?? node.signalTypeId];
  const unit = signalUnitTypesMap[signalType?.unitId];
  const amongSelected = selectedRectHandles.some(
    (handle) => handle.objectIndex === objectIndex && handle.rectIndex === 0
  );

  const _onClick = useCallback(
    (event: MouseEvent) => {
      onClick?.(event, node, signal, value?.isWritable);
    },
    [onClick, node, signal, value]
  );

  const _onMouseOver = useCallback(
    (event: MouseEvent) => {
      onMouseOver?.(event, node, signal, value?.isWritable);
    },
    [onMouseOver, node, signal, value]
  );

  const _onMouseOut = useCallback(
    (event: MouseEvent) => {
      onMouseOut?.(event, node, signal, value?.isWritable);
    },
    [onMouseOut, node, signal, value]
  );

  return (
    <ProcessMapSignalView
      objectIndex={objectIndex}
      key={node.id}
      node={node}
      isDragging={amongSelected}
      updateTextSize={updateTextSize}
      signal={signal}
      signalType={signalType}
      value={value}
      unit={unit}
      isLoading={isLoading}
      isHovering={isHovering}
      showSignalLabelsWhenNotFound={showSignalLabelsWhenNotFound}
      onMouseOut={_onMouseOut}
      onMouseOver={_onMouseOver}
      onClick={_onClick}
    />
  );
};

export const signalSections = (
  previewNodeId: string,
  nodeTreeSet: CustomNodeTreeSet
): ModelFormSectionType<ProcessMapSignalTextObject>[] => [
  {
    label: T.admin.processmaps.objecteditor.signalobject,
    models: [
      {
        label: T.signals.signaltype,
        key: (input) => input.signalTypeId,
        modelType: ModelType.SIGNAL_TYPE,
        customNodeTreeSet: nodeTreeSet,
        nodeId: previewNodeId,
        isClearable: true
      },
      {
        label: T.signals.signalid.label,
        helpText: T.signals.signalid.description,
        key: (input) => input.signalId,
        modelType: ModelType.SIGNAL,
        customNodeTreeSet: nodeTreeSet,
        nodeId: previewNodeId,
        placeholder: T.signals.signalid.label,
        isClearable: true
      },
      {
        label: T.admin.processmaps.objecteditor.hidelabel,
        key: (input) => input.hideLabel,
        modelType: ModelType.BOOL
      },
      {
        label: T.admin.processmaps.objecteditor.hidevalue,
        key: (input) => input.hideValue,
        modelType: ModelType.BOOL
      },
      textAlignmentOption
    ]
  },
  ...textStyleSections<ProcessMapSignalTextObject>(
    T.admin.processmaps.objecteditor.labeltextstyle,
    (input) => input.labelTextSettings,
    defaultSignalTitleSettings
  ),
  ...textStyleSections<ProcessMapSignalTextObject>(
    T.admin.processmaps.objecteditor.valuetextstyle,
    (input) => input.valueTextSettings,
    defaultSignalSubtitleSettings
  )
];
