import { AggregationType } from 'ecto-common/lib/API/APIGen';
import T from 'ecto-common/lib/lang/Language';

export const AggregationText: Record<AggregationType, string> = {
  [AggregationType.None]: T.graphs.exportdialog.aggregation.none,
  [AggregationType.Mean]: T.graphs.exportdialog.aggregation.mean,
  [AggregationType.Max]: T.graphs.exportdialog.aggregation.max,
  [AggregationType.Min]: T.graphs.exportdialog.aggregation.min,
  [AggregationType.First]: T.graphs.exportdialog.aggregation.first,
  [AggregationType.Last]: T.graphs.exportdialog.aggregation.last,
  [AggregationType.Count]: T.graphs.exportdialog.aggregation.count,
  [AggregationType.Sum]: T.graphs.exportdialog.aggregation.sum,
  [AggregationType.Median]: T.graphs.exportdialog.aggregation.median,
  [AggregationType.Mode]: T.graphs.exportdialog.aggregation.mode,
  [AggregationType.Unknown]: 'Unknown',
  [AggregationType.NoneWithTags]: 'NoneWithTags'
};

// The other two (NoneWithTags and Unknown) are internal for backend and should not be seen in the frontend
export const ValidAggregations = (
  Object.keys(AggregationText) as AggregationType[]
).filter(
  (aggregation) =>
    aggregation !== AggregationType.Unknown &&
    aggregation !== AggregationType.NoneWithTags
);
