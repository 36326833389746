import React from 'react';
import SvgIcon, { SvgIconProps } from './SvgIcon';
import styles from './Arrow.module.css';

const Arrow = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 42 42" {...props}>
    <g>
      <path
        className={styles.cls1}
        d="M25.44,22.41a45.32,45.32,0,0,1-8.77,7,1.12,1.12,0,0,1-1.52-.33,1,1,0,0,1,.34-1.47,43.17,43.17,0,0,0,8.21-6.52,42.8,42.8,0,0,0-8.21-6.56,1,1,0,0,1-.34-1.47,1.12,1.12,0,0,1,1.52-.33,45,45,0,0,1,8.78,7,1.89,1.89,0,0,1,0,2.64"
      />
      <polygon
        className={styles.cls1}
        points="24.98 19.77 24.98 19.77 24.98 19.77 24.98 19.77"
      />
      <polygon
        className={styles.cls1}
        points="24.98 22.41 24.98 22.41 24.98 22.41 24.98 22.41"
      />
    </g>
  </SvgIcon>
);

export default Arrow;
