import DropdownButton, {
  DropdownButtonProps
} from 'ecto-common/lib/DropdownButton/DropdownButton';
import React from 'react';
import styles from './ToolbarMenu.module.css';
import classNames from 'classnames';
import ToolbarMenuItem from 'ecto-common/lib/Toolbar/ToolbarMenuItem';

const ToolbarMenuDropdownButton = ({
  className,
  tooltipText,
  ...otherProps
}: DropdownButtonProps & { tooltipText: string }) => {
  return (
    <ToolbarMenuItem>
      <DropdownButton
        tooltipText={tooltipText}
        isIconButton
        className={classNames(className, styles.toolbarMenuButton)}
        {...otherProps}
      />
    </ToolbarMenuItem>
  );
};

export default React.memo(ToolbarMenuDropdownButton);
