import React, { useCallback, useContext, useMemo } from 'react';
import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import styles from './AlarmView.module.css';
import T from 'ecto-common/lib/lang/Language';
import AlarmSeverityCircle from './AlarmSeverityCircle';
import classNames from 'classnames';
import moment from 'moment';
import _ from 'lodash';

import { DEFAULT_TIMEZONE } from 'ecto-common/lib/constants';
import Icons from 'ecto-common/lib/Icons/Icons';
import AcknowledgeButton from './AcknowledgeButton';
import TableColumn from 'ecto-common/lib/TableColumn/TableColumn';

import { useNavigate } from 'react-router-dom';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import UrlContext, {
  GetAlarmUrlFunction,
  GetSignalsUrlFunction
} from 'ecto-common/lib/hooks/UrlContext';
import { SortDirectionType } from 'ecto-common/lib/DataTable/SortDirection';
import { AlarmTableViewNode } from 'ecto-common/lib/Alarms/AlarmTableView';
import {
  TimeFormats,
  getDefaultDateTimeFormat,
  getDefaultTimeFormat
} from '../utils/dateUtils';

const SeverityDataKey = 'severity';

const getColumns = (
  tenantId: string,
  getAlarmUrl: GetAlarmUrlFunction,
  getSignalsUrl: GetSignalsUrlFunction
): DataTableColumnProps<AlarmTableViewNode>[] => [
  {
    label: (
      <div className={styles.severityWrapper}>
        {' '}
        {T.alarms.columns.severity}{' '}
      </div>
    ),
    dataKey: SeverityDataKey,
    width: 78,
    minWidth: 78,
    flexGrow: 0,
    flexShrink: 0,
    canSort: true,
    dataFormatter: (severity: number) => (
      <div className={styles.severityWrapper}>
        <AlarmSeverityCircle severity={severity} />
      </div>
    )
  },
  {
    label: T.alarms.columns.signal,
    dataKey: 'name',
    minWidth: 115,
    flexGrow: 2,
    canSort: false,
    dataFormatter: (name: string, { description }) => (
      <TableColumn title={name} subtitle={description} />
    )
  },
  {
    label: T.alarms.columns.equipment,
    dataKey: 'fullPath',
    minWidth: 115,
    flexGrow: 2,
    canSort: false,
    dataFormatter: (fullPath: string, { equipmentName, node, nodeId }) => (
      <TableColumn
        title={equipmentName}
        titleLink={getSignalsUrl(tenantId, node.nodeId, nodeId)}
        subtitle={fullPath}
        subtitleLink={getAlarmUrl(tenantId, node.nodeId, null)}
      />
    )
  },
  {
    label: T.alarms.columns.status,
    dataKey: 'isActive',
    minWidth: 80,
    flexGrow: 1,
    canSort: true,
    dataFormatter: (isActive: boolean, alarm) => (
      <TableColumn
        title={
          <span className={classNames(isActive && styles.active)}>
            {isActive ? T.alarms.active : T.alarms.inactive}
          </span>
        }
        subtitle={
          !alarm.isHistoryItem &&
          alarm.numberOfActiveAlarms > 0 && (
            <a href="#" onClick={(e) => alarm.onClickAlarmDate(e, alarm)}>
              {T.format(
                T.alarms.numberofactiveformat,
                alarm.numberOfActiveAlarms
              )}
            </a>
          )
        }
      />
    )
  },
  {
    label: T.alarms.columns.firstactivedate,
    dataKey: 'isActiveDate',
    minWidth: 105,
    flexGrow: 1,
    canSort: true,
    dataFormatter: (alarmDate: string, alarm) => {
      const date = moment.tz(alarmDate, DEFAULT_TIMEZONE);
      return (
        <TableColumn
          title={
            <a href="#" onClick={(e) => alarm.onClickAlarmDate(e, alarm)}>
              {date.format(getDefaultDateTimeFormat(TimeFormats.NONE))}{' '}
            </a>
          }
          subtitle={T.format(
            T.alarms.datesubtitleformat,
            date.format(getDefaultTimeFormat(TimeFormats.LONG_TIME))
          )}
        />
      );
    }
  },
  {
    label: T.alarms.columns.acknowledge,
    dataKey: 'isAcknowledged',
    flexGrow: 0,
    minWidth: 200,
    canSort: false,
    dataFormatter: (isAcknowledged: boolean, alarm) => {
      const date =
        alarm.isAcknowledgeDate &&
        moment.tz(alarm.isAcknowledgeDate, DEFAULT_TIMEZONE);

      if (isAcknowledged || alarm.isHistoryItem) {
        const text = isAcknowledged
          ? alarm.acknowledgedBy
          : T.alarms.unacknowledgedtext;

        return (
          <TableColumn
            icon={
              isAcknowledged && (
                <div className={styles.acknowledgedIcon}>
                  <Icons.CheckmarkCircle />
                </div>
              )
            }
            title={
              <div
                className={classNames(
                  styles.acknowledgedText,
                  !isAcknowledged && styles.unacknowledgedText
                )}
              >
                {text}
              </div>
            }
            subtitle={
              date &&
              date.format(getDefaultDateTimeFormat(TimeFormats.LONG_TIME))
            }
          />
        );
      }

      return <AcknowledgeButton alarm={alarm} node={alarm.node} />;
    }
  }
];

const getCompactColumns = (
  tenantId: string,
  getAlarmUrl: GetAlarmUrlFunction,
  getSignalsUrl: GetSignalsUrlFunction
): DataTableColumnProps<AlarmTableViewNode>[] =>
  getColumns(tenantId, getAlarmUrl, getSignalsUrl).filter(
    (x) => x.dataKey !== 'isAcknowledged'
  );

interface AlarmViewSectionProps {
  nodeId: string;
  alarmList?: AlarmTableViewNode[];
  loading?: boolean;
  compact?: boolean;
  sortDirection?: SortDirectionType;
  sortBy?: string;
  onSortChange?(newSortBy: string, newSortDirection: string): void;
}

const AlarmViewSection = ({
  nodeId,
  alarmList,
  loading,
  compact,
  sortDirection,
  sortBy,
  onSortChange
}: AlarmViewSectionProps) => {
  const { tenantId } = useContext(TenantContext);
  const { getAlarmUrl, getSignalsUrl } = useContext(UrlContext);

  const columnsToUse: DataTableColumnProps<AlarmTableViewNode>[] =
    useMemo(() => {
      const source = compact
        ? getCompactColumns(tenantId, getAlarmUrl, getSignalsUrl)
        : getColumns(tenantId, getAlarmUrl, getSignalsUrl);

      if (sortBy === SeverityDataKey) {
        (source.find((x) => x.dataKey === SeverityDataKey).width as number) +=
          18;
      }

      return source;
    }, [compact, sortBy, tenantId, getAlarmUrl, getSignalsUrl]);

  const navigate = useNavigate();

  const goToAlarms = useCallback(() => {
    navigate(getAlarmUrl(tenantId, nodeId, null));
  }, [navigate, getAlarmUrl, tenantId, nodeId]);

  return (
    <>
      <DataTable<AlarmTableViewNode>
        isLoading={loading}
        columns={columnsToUse}
        data={alarmList}
        sortBy={sortBy}
        sortDirection={sortDirection}
        onSortChange={onSortChange}
        className={styles.table}
        onClickRow={compact && goToAlarms}
        noDataText={T.alarms.nodata}
        useAllAvailableHeight
      />
    </>
  );
};

export default React.memo(AlarmViewSection);
