import { ProcessMapObjectTypes } from 'ecto-common/lib/ProcessMap/ProcessMapViewConstants';
import { capitalizeString } from 'ecto-common/lib/utils/stringUtils';
import ProcessMapLibraryList, {
  SymbolModelOrSystemItem,
  ProcessMapLibrarySymbolItemWithSize
} from 'ecto-common/lib/ProcessMaps/ProcessMapLibraryList';
import React, { useCallback, useState } from 'react';
import styles from './ProcessMapEditor.module.css';
import { SymbolModel } from 'ecto-common/lib/API/PresentationAPIGen';

export type ProcessMapLibraryMenuProps = {
  library: SymbolModel[];
  onClickLibraryMenuItem: (
    item: ProcessMapLibrarySymbolItemWithSize,
    mouseX: number,
    mouseY: number
  ) => void;
  onDragItemEnd: (
    libraryItem: ProcessMapLibrarySymbolItemWithSize,
    event: React.DragEvent<HTMLImageElement>,
    offsetX: number,
    offsetY: number
  ) => void;
};

const ProcessMapLibraryMenu = ({
  library,
  onClickLibraryMenuItem,
  onDragItemEnd
}: ProcessMapLibraryMenuProps) => {
  const [previewItem, setPreviewItem] = useState<{
    item: SymbolModelOrSystemItem;
    href: string;
    yPosition: number;
  }>(null);

  const clearPreviewItem = useCallback(() => {
    setPreviewItem(null);
  }, []);

  const onHover = (
    item: SymbolModelOrSystemItem,
    href: string,
    yPosition: number
  ) => {
    setPreviewItem({ item, href, yPosition });
  };

  return (
    <>
      {previewItem && (
        <div
          className={styles.libraryHoverPreview}
          style={{
            position: 'absolute',
            top: previewItem.yPosition,
            right: -200,
            width: 190
          }}
        >
          <img src={previewItem.href} />
          <hr />
          {previewItem.item.type === ProcessMapObjectTypes.Symbol
            ? previewItem.item.item.name
            : capitalizeString(previewItem.item.type)}
        </div>
      )}
      <div className={styles.libraryContent}>
        <div className={styles.libraryMenu}>
          <ProcessMapLibraryList
            onClickLibraryItem={onClickLibraryMenuItem}
            selectedIndex={-1}
            library={library}
            onDragItemStart={clearPreviewItem}
            onDragItemEnd={onDragItemEnd}
            onHoverItem={onHover}
          />
        </div>
      </div>
    </>
  );
};

export default React.memo(ProcessMapLibraryMenu);
