// Use the following format:
// name    = the name of the key, this will be emitted as `key:<name>`
// keyCode = the actual keycode to listen to
// key     = the alternative name of this key, it is used as fallback is `keyCode` is not available (some browsers)
// altKey  = if alt-key needs to be pressed (optional)
// metaKey = if ctrl-key needs to be pressed (or meta for mac) (optional)

export const ESC = {
  name: 'escape',
  keyCode: 27,
  key: 'Escape'
};
