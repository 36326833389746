import React, {
  KeyboardEventHandler,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import TextInput, { TextInputProps } from 'ecto-common/lib/TextInput/TextInput';
import classNames from 'classnames';
import dropdownStyles from './GeocodingInputField.module.css';
import { usePopper } from 'react-popper';
import dimensions from 'ecto-common/lib/styles/dimensions';
import _ from 'lodash';
import T from 'ecto-common/lib/lang/Language';
import { isNullOrWhitespace } from 'ecto-common/lib/utils/stringUtils';
import { KEY_CODE_ENTER } from 'ecto-common/lib/constants';
import useOnclickOutside from 'react-cool-onclickoutside';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import { DEFAULT_SEARCH_DEBOUNCE_TIME_MS } from 'ecto-common/lib/utils/constants';
import { MapLocation } from 'ecto-common/lib/Map/Map';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';
import { useMutation, useQuery } from '@tanstack/react-query';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { MapsLibrary } from 'google-maps-react-markers';

type PlacesResultType = {
  description?: string;
};

const emptyResults: google.maps.places.AutocompletePrediction[] = [];

interface PlacesResultProps {
  result?: PlacesResultType;
  onClicked?(result: PlacesResultType): void;
  selected?: boolean;
}

const PlacesResult = ({ result, onClicked, selected }: PlacesResultProps) => {
  const _onClicked = useCallback(() => {
    onClicked(result);
  }, [result, onClicked]);

  const handleKeyPress: KeyboardEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      if (e.keyCode === KEY_CODE_ENTER) {
        onClicked(result);
      }
    },
    [onClicked, result]
  );

  return (
    <div
      className={classNames(
        dropdownStyles.result,
        selected && dropdownStyles.selected
      )}
      onClick={_onClicked}
      onKeyUp={handleKeyPress}
    >
      {result.description}
    </div>
  );
};

interface GeocodingInputFieldProps extends Omit<TextInputProps, 'ref'> {
  map?: object;
  maps?: MapsLibrary;
  value?: string;
  onChange?(event: React.ChangeEvent<HTMLInputElement>): void;
  onSelectedLocation?(location: MapLocation): void;
  latitude?: number;
  longitude?: number;
  placeholder?: string;
}

const GeocodingInputField = ({
  value,
  onChange,
  onSelectedLocation,
  latitude,
  longitude,
  maps,
  placeholder = T.geocodinginput.placeholder,
  ...otherProps
}: GeocodingInputFieldProps) => {
  const [referenceElement, setReferenceElement] =
    React.useState<HTMLElement>(null);
  const [popperElement, setPopperElement] = React.useState<HTMLElement>(null);
  const [sessionToken, setSessionToken] = useState<string>(null);
  const [attributionRef, setAttributionRef] = useState<HTMLDivElement>(null);
  const [placesAPI, setPlacesAPI] =
    useState<google.maps.places.AutocompleteService>(null);
  const [placesServiceAPI, setPlacesServiceAPI] =
    useState<google.maps.places.PlacesService>(null);

  useEffect(() => {
    if (maps != null) {
      if (attributionRef != null) {
        // @ts-ignore-next-line
        setPlacesServiceAPI(new maps.places.PlacesService(attributionRef));
      }

      // @ts-ignore-next-line
      setPlacesAPI(new maps.places.AutocompleteService());

      // @ts-ignore-next-line
      setSessionToken(new maps.places.AutocompleteSessionToken());
    }
  }, [maps, attributionRef]);

  const onFocusLost = useCallback(() => {
    setPlacesRequest(null);
    setPlaceResultIndex(-1);
  }, []);

  const clickOutsideRef = useOnclickOutside(onFocusLost);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'preventOverflow',
        options: {
          mainAxis: false
        }
      },
      {
        name: 'offset',
        options: {
          offset: [0, dimensions.smallMargin]
        }
      }
    ]
  });

  const geocodeDetailsPromise = useMemo(() => {
    return (_contextSettings: ApiContextSettings, placeId: string) => {
      if (placesServiceAPI != null) {
        return new Promise((resolve, reject) => {
          placesServiceAPI.getDetails(
            {
              placeId,
              sessionToken,
              fields: [
                'address_components',
                'formatted_address',
                'geometry.location'
              ]
            },
            (results, status) => {
              if (status === 'OK') {
                resolve(results);
              } else {
                reject();
              }
            }
          );
        });
      }

      return Promise.resolve(null);
    };
  }, [placesServiceAPI, sessionToken]);

  const placesPromise = useMemo(() => {
    return (
      _contextSettings: ApiContextSettings,
      input: string
    ): Promise<google.maps.places.AutocompletePrediction[]> => {
      if (placesAPI != null) {
        return new Promise((resolve, reject) => {
          placesAPI.getPlacePredictions(
            {
              types: ['address'],
              // @ts-ignore-next-line
              locationRestriction: new maps.LatLngBounds(
                { lat: latitude - 3, lng: longitude - 3 },
                { lat: latitude + 3, lng: longitude + 3 }
              ),
              sessionToken,
              input
            },
            (results, status) => {
              if (status === 'OK') {
                resolve(results);
              } else {
                reject();
              }
            }
          );
        });
      }

      return Promise.resolve([]);
    };
  }, [placesAPI, sessionToken, maps, latitude, longitude]);

  const [placesRequest, setPlacesRequest] = useState<string>(null);
  const [placeResultIndex, setPlaceResultIndex] = useState(-1);

  const { contextSettings } = useContext(TenantContext);
  const searchForPlacesEnabled = !isNullOrWhitespace(placesRequest);
  const searchForPlacesQuery = useQuery({
    queryKey: ['searchForPlaces', placesRequest],

    queryFn: () => {
      return placesPromise(contextSettings, placesRequest);
    },

    enabled: searchForPlacesEnabled,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });

  const placesResults = searchForPlacesQuery.data ?? emptyResults;

  const geocodeMutation = useMutation({
    mutationFn: (placeId: string) => {
      return geocodeDetailsPromise(contextSettings, placeId);
    },

    onSuccess: (result) => {
      if (result == null) {
        return;
      }

      // @ts-ignore-next-line
      setSessionToken(new maps.places.AutocompleteSessionToken());
      const route = _.find(result.address_components, { types: ['route'] });
      const streetNumber = _.find(result.address_components, {
        types: ['street_number']
      });
      let street = result.formatted_address;

      if (route) {
        if (streetNumber) {
          street = route.long_name + ' ' + streetNumber.long_name;
        } else {
          street = route.long_name;
        }
      }

      onSelectedLocation({
        street,
        lat: result.geometry.location.lat(),
        lng: result.geometry.location.lng()
      });
    },

    onError: () => {
      toastStore.addErrorToast(T.geocodinginput.error.resolve);
    }
  });

  const _onChange = useMemo(() => {
    return _.debounce((e) => {
      setPlacesRequest(e.target.value);
      onChange(e);
    }, DEFAULT_SEARCH_DEBOUNCE_TIME_MS);
  }, [onChange]);

  const _onClicked = useCallback(
    (result: google.maps.places.AutocompletePrediction) => {
      geocodeMutation.mutate(result.place_id);
      setPlacesRequest(null);
      setPlaceResultIndex(-1);
    },
    [geocodeMutation]
  );

  const onFocus = useCallback(() => {
    // @ts-ignore-next-line
    setSessionToken(new maps.places.AutocompleteSessionToken());

    if (!isNullOrWhitespace(value)) {
      setPlacesRequest(value);
    }
  }, [maps, value]);

  const onEnterKeyPressed: React.KeyboardEventHandler<HTMLInputElement> =
    useCallback(
      (e) => {
        if (placeResultIndex !== -1) {
          _onClicked(placesResults[placeResultIndex]);
          if (e.target instanceof HTMLElement) {
            e.target.blur();
          }
        }
      },
      [placeResultIndex, placesResults, _onClicked]
    );

  const onArrowKeyUpPressed = useCallback(() => {
    setPlaceResultIndex((oldIndex) => {
      const newIndex = oldIndex - 1;

      if (newIndex < 0) {
        return placesResults.length - 1;
      }

      return newIndex;
    });
  }, [placesResults]);

  const onArrowKeyDownPressed = useCallback(() => {
    setPlaceResultIndex((oldIndex) => (oldIndex + 1) % placesResults.length);
  }, [placesResults]);

  return (
    <>
      <TextInput
        ref={setReferenceElement}
        {...otherProps}
        onChange={_onChange}
        placeholder={placeholder}
        value={value}
        onFocus={onFocus}
        onTabKeyPressed={onFocusLost}
        onArrowUpKeyPressed={onArrowKeyUpPressed}
        onArrowDownKeyPressed={onArrowKeyDownPressed}
        onEnterKeyPressed={onEnterKeyPressed}
        className="ignore-onclickoutside"
        disabled={geocodeMutation.isPending}
        isLoading={
          geocodeMutation.isPending ||
          (searchForPlacesQuery.isLoading && searchForPlacesEnabled)
        }
      />
      <div
        ref={setPopperElement}
        className={classNames(
          'ignore-onclickoutside',
          dropdownStyles.dropdown,
          placesResults?.length > 0 && dropdownStyles.dropdownVisible
        )}
        style={styles.popper}
        {...attributes.popper}
      >
        <div className={dropdownStyles.innerDropdown} ref={clickOutsideRef}>
          {placesResults.map((result, idx) => (
            <PlacesResult
              onClicked={_onClicked}
              result={result}
              key={result.description}
              selected={idx === placeResultIndex}
            />
          ))}
        </div>
      </div>
      <div ref={setAttributionRef} />
    </>
  );
};

export default React.memo(GeocodingInputField);
