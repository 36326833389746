import React from 'react';
import Spinner from '../Spinner/Spinner';
import styles from './LoadingScreen.module.css';
import T from 'ecto-common/lib/lang/Language';

interface LoadingScreenProps {
  isLoading: boolean;
  text?: string;
}

const LoadingScreen = ({
  isLoading,
  text = T.common.loadingtextlong
}: LoadingScreenProps) => {
  return (
    isLoading && (
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.text}>{text}</div>
          <Spinner />
        </div>
      </div>
    )
  );
};

export default React.memo(LoadingScreen);
