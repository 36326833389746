// Imports
import ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ from "../../../../../../../node_modules/html-loader/dist/runtime/getUrl.js";
var ___HTML_LOADER_IMPORT_0___ = new URL("../img/notifications_overview.png", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("../img/notifications_add_notification.png", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("../img/locations_notifications_add_notification_select_signals.png", import.meta.url);
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var code = "<h1 id=\"notifications\">Notifications</h1> <p>The Manage Notifications view collects all notifications that have the logged-in user as recipient for the notification. </p> <p><img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\" alt=\"notifications overview\"></p> <p>it is possible to add, edit and delete locations from this view. Edit and delete is done through the icons on each notification. Use the &quot;Add Notification&quot; button to add a Notification. When pressed the following dialog is opened:</p> <p><img src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\" alt=\"add notification\"></p> <h2 id=\"add-notification\">Add notification</h2> <p>The dialog has the following fields</p> <h3 id=\"name\">Name</h3> <p>A descriptive name of the notification.</p> <h3 id=\"severity\">Severity</h3> <p>Select the severities that the notification rule should trigger upon. All alarms with the selected severity will trigger a new notification.</p> <h3 id=\"location\">Location</h3> <p>Select which location that triggers the notification.</p> <h3 id=\"users\">Users</h3> <p>Select users that shall receive the notification when triggered.</p> <h3 id=\"signals\">Signals</h3> <p>It is possible to select the specific signals that shall trigger a notification through the signal selector:</p> <p><img src=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\" alt=\"Select Signals\"></p> <p>All signals marked in the list will trigger the notification.</p> ";
// Exports
export default code;