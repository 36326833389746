import React, { useState, useCallback, useEffect } from 'react';
import _ from 'lodash';

import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import Icons from 'ecto-common/lib/Icons/Icons';
import T from 'ecto-common/lib/lang/Language';
import { ROOT_NODE_ID } from 'ecto-common/lib/constants';
import styles from 'ecto-common/lib/SelectEquipmentDialog/SelectEquipmentDialog.module.css';
import SelectEquipment from 'ecto-common/lib/SelectEquipment/SelectEquipment';
import { EquipmentResponseModel } from 'ecto-common/lib/API/APIGen';

interface SelectEquipmentDialogProps {
  isOpen: boolean;
  onModalClose(): void;
  onEquipmentSelected(selectedIds: string[]): void;
  selectedEquipment?: EquipmentResponseModel;
  nodeId?: string;
  title?: string;
  selectFromCurrentNodeOnly?: boolean;
}

const SelectEquipmentDialog = ({
  isOpen,
  onModalClose,
  onEquipmentSelected,
  selectedEquipment,
  nodeId,
  selectFromCurrentNodeOnly = false,
  title = T.admin.selectequipment.title
}: SelectEquipmentDialogProps) => {
  const isRootNode = _.startsWith(
    _.defaultTo(nodeId, ROOT_NODE_ID),
    ROOT_NODE_ID
  );
  const [selectedIds, setSelectedIds] = useState(
    isRootNode ? [] : _.compact([nodeId])
  );

  useEffect(
    () => setSelectedIds([selectedEquipment?.equipmentId]),
    [selectedEquipment]
  );

  const onConfirm = useCallback(
    () => onEquipmentSelected(selectedIds),
    [selectedIds, onEquipmentSelected]
  );

  const _onEquipmentSelected = useCallback((itemId: string) => {
    setSelectedIds([itemId]);
  }, []);

  return (
    <ActionModal
      headerIcon={Icons.Edit}
      onModalClose={onModalClose}
      onConfirmClick={onConfirm}
      isOpen={isOpen}
      title={title}
      className={styles.modalResizer}
      messageBodyClassName={styles.modalBody}
    >
      <SelectEquipment
        nodeId={nodeId}
        onEquipmentSelected={_onEquipmentSelected}
        selectFromCurrentNodeOnly={selectFromCurrentNodeOnly}
        equipmentId={selectedEquipment?.equipmentId}
      />
    </ActionModal>
  );
};

export default React.memo(SelectEquipmentDialog);
