import Performance from '../performance';
import p5 from 'p5';
import { formatNumberUnit } from 'ecto-common/lib/utils/stringUtils';

let performanceMeasure = Performance.performanceMeasure;

const startedAt = performance.now();
const appNow = () => performance.now() - startedAt;

const TableContext = {
  appNow,
  debug: false,
  performanceMeasure,
  testing: false,
  startMeasureFor: Performance.startMeasureFor,
  stopMeasureFor: Performance.stopMeasureFor,
  Vector: p5.Vector,
  model: null as string,
  company: null as string,
  formatNumberUnit
};

export default TableContext;

export type TableContextType = typeof TableContext;
