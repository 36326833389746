import React, { useCallback } from 'react';
import moment from 'moment';

import queryString from 'query-string';
import Flex from '../../components/Flex/Flex';

import defaultLocation from '../../assets/default_location';

import './Weather.css';

import { useQuery } from '@tanstack/react-query';
import imgLocation from '../../images/location.svg';

import img_clearsky_day from '../../images/weather/clearsky_day.svg';
import img_clearsky_night from '../../images/weather/clearsky_night.svg';
import img_clearsky_polartwilight from '../../images/weather/clearsky_polartwilight.svg';
import img_cloudy from '../../images/weather/cloudy.svg';
import img_fair_day from '../../images/weather/fair_day.svg';
import img_fair_night from '../../images/weather/fair_night.svg';
import img_fair_polartwilight from '../../images/weather/fair_polartwilight.svg';
import img_fog from '../../images/weather/fog.svg';
import img_heavyrain from '../../images/weather/heavyrain.svg';
import img_heavyrainandthunder from '../../images/weather/heavyrainandthunder.svg';
import img_heavyrainshowers_day from '../../images/weather/heavyrainshowers_day.svg';
import img_heavyrainshowers_night from '../../images/weather/heavyrainshowers_night.svg';
import img_heavyrainshowers_polartwilight from '../../images/weather/heavyrainshowers_polartwilight.svg';
import img_heavyrainshowersandthunder_day from '../../images/weather/heavyrainshowersandthunder_day.svg';
import img_heavyrainshowersandthunder_night from '../../images/weather/heavyrainshowersandthunder_night.svg';
import img_heavyrainshowersandthunder_polartwilight from '../../images/weather/heavyrainshowersandthunder_polartwilight.svg';
import img_heavysleet from '../../images/weather/heavysleet.svg';
import img_heavysleetandthunder from '../../images/weather/heavysleetandthunder.svg';
import img_heavysleetshowers_day from '../../images/weather/heavysleetshowers_day.svg';
import img_heavysleetshowers_night from '../../images/weather/heavysleetshowers_night.svg';
import img_heavysleetshowers_polartwilight from '../../images/weather/heavysleetshowers_polartwilight.svg';
import img_heavysleetshowersandthunder_day from '../../images/weather/heavysleetshowersandthunder_day.svg';
import img_heavysleetshowersandthunder_night from '../../images/weather/heavysleetshowersandthunder_night.svg';
import img_heavysleetshowersandthunder_polartwilight from '../../images/weather/heavysleetshowersandthunder_polartwilight.svg';
import img_heavysnow from '../../images/weather/heavysnow.svg';
import img_heavysnowandthunder from '../../images/weather/heavysnowandthunder.svg';
import img_heavysnowshowers_day from '../../images/weather/heavysnowshowers_day.svg';
import img_heavysnowshowers_night from '../../images/weather/heavysnowshowers_night.svg';
import img_heavysnowshowers_polartwilight from '../../images/weather/heavysnowshowers_polartwilight.svg';
import img_heavysnowshowersandthunder_day from '../../images/weather/heavysnowshowersandthunder_day.svg';
import img_heavysnowshowersandthunder_night from '../../images/weather/heavysnowshowersandthunder_night.svg';
import img_heavysnowshowersandthunder_polartwilight from '../../images/weather/heavysnowshowersandthunder_polartwilight.svg';
import img_lightrain from '../../images/weather/lightrain.svg';
import img_lightrainandthunder from '../../images/weather/lightrainandthunder.svg';
import img_lightrainshowers_day from '../../images/weather/lightrainshowers_day.svg';
import img_lightrainshowers_night from '../../images/weather/lightrainshowers_night.svg';
import img_lightrainshowers_polartwilight from '../../images/weather/lightrainshowers_polartwilight.svg';
import img_lightrainshowersandthunder_day from '../../images/weather/lightrainshowersandthunder_day.svg';
import img_lightrainshowersandthunder_night from '../../images/weather/lightrainshowersandthunder_night.svg';
import img_lightrainshowersandthunder_polartwilight from '../../images/weather/lightrainshowersandthunder_polartwilight.svg';
import img_lightsleet from '../../images/weather/lightsleet.svg';
import img_lightsleetandthunder from '../../images/weather/lightsleetandthunder.svg';
import img_lightsleetshowers_day from '../../images/weather/lightsleetshowers_day.svg';
import img_lightsleetshowers_night from '../../images/weather/lightsleetshowers_night.svg';
import img_lightsleetshowers_polartwilight from '../../images/weather/lightsleetshowers_polartwilight.svg';
import img_lightsnow from '../../images/weather/lightsnow.svg';
import img_lightsnowandthunder from '../../images/weather/lightsnowandthunder.svg';
import img_lightsnowshowers_day from '../../images/weather/lightsnowshowers_day.svg';
import img_lightsnowshowers_night from '../../images/weather/lightsnowshowers_night.svg';
import img_lightsnowshowers_polartwilight from '../../images/weather/lightsnowshowers_polartwilight.svg';
import img_lightssleetshowersandthunder_day from '../../images/weather/lightssleetshowersandthunder_day.svg';
import img_lightssleetshowersandthunder_night from '../../images/weather/lightssleetshowersandthunder_night.svg';
import img_lightssleetshowersandthunder_polartwilight from '../../images/weather/lightssleetshowersandthunder_polartwilight.svg';
import img_lightssnowshowersandthunder_day from '../../images/weather/lightssnowshowersandthunder_day.svg';
import img_lightssnowshowersandthunder_night from '../../images/weather/lightssnowshowersandthunder_night.svg';
import img_lightssnowshowersandthunder_polartwilight from '../../images/weather/lightssnowshowersandthunder_polartwilight.svg';
import img_partlycloudy_day from '../../images/weather/partlycloudy_day.svg';
import img_partlycloudy_night from '../../images/weather/partlycloudy_night.svg';
import img_partlycloudy_polartwilight from '../../images/weather/partlycloudy_polartwilight.svg';
import img_rain from '../../images/weather/rain.svg';
import img_rainandthunder from '../../images/weather/rainandthunder.svg';
import img_rainshowers_day from '../../images/weather/rainshowers_day.svg';
import img_rainshowers_night from '../../images/weather/rainshowers_night.svg';
import img_rainshowers_polartwilight from '../../images/weather/rainshowers_polartwilight.svg';
import img_rainshowersandthunder_day from '../../images/weather/rainshowersandthunder_day.svg';
import img_rainshowersandthunder_night from '../../images/weather/rainshowersandthunder_night.svg';
import img_rainshowersandthunder_polartwilight from '../../images/weather/rainshowersandthunder_polartwilight.svg';
import img_sleet from '../../images/weather/sleet.svg';
import img_sleetandthunder from '../../images/weather/sleetandthunder.svg';
import img_sleetshowers_day from '../../images/weather/sleetshowers_day.svg';
import img_sleetshowers_night from '../../images/weather/sleetshowers_night.svg';
import img_sleetshowers_polartwilight from '../../images/weather/sleetshowers_polartwilight.svg';
import img_sleetshowersandthunder_day from '../../images/weather/sleetshowersandthunder_day.svg';
import img_sleetshowersandthunder_night from '../../images/weather/sleetshowersandthunder_night.svg';
import img_sleetshowersandthunder_polartwilight from '../../images/weather/sleetshowersandthunder_polartwilight.svg';
import img_snow from '../../images/weather/snow.svg';
import img_snowandthunder from '../../images/weather/snowandthunder.svg';
import img_snowshowers_day from '../../images/weather/snowshowers_day.svg';
import img_snowshowers_night from '../../images/weather/snowshowers_night.svg';
import img_snowshowers_polartwilight from '../../images/weather/snowshowers_polartwilight.svg';
import img_snowshowersandthunder_day from '../../images/weather/snowshowersandthunder_day.svg';
import img_snowshowersandthunder_night from '../../images/weather/snowshowersandthunder_night.svg';
import img_snowshowersandthunder_polartwilight from '../../images/weather/snowshowersandthunder_polartwilight.svg';
import {
  MetWeatherResponse,
  TimeSeries
} from 'js/views/EctocloudView/WeatherTypes';

const weatherIcons: Record<string, string> = {
  clearsky_day: img_clearsky_day,
  clearsky_night: img_clearsky_night,
  clearsky_polartwilight: img_clearsky_polartwilight,
  cloudy: img_cloudy,
  fair_day: img_fair_day,
  fair_night: img_fair_night,
  fair_polartwilight: img_fair_polartwilight,
  fog: img_fog,
  heavyrain: img_heavyrain,
  heavyrainandthunder: img_heavyrainandthunder,
  heavyrainshowers_day: img_heavyrainshowers_day,
  heavyrainshowers_night: img_heavyrainshowers_night,
  heavyrainshowers_polartwilight: img_heavyrainshowers_polartwilight,
  heavyrainshowersandthunder_day: img_heavyrainshowersandthunder_day,
  heavyrainshowersandthunder_night: img_heavyrainshowersandthunder_night,
  heavyrainshowersandthunder_polartwilight:
    img_heavyrainshowersandthunder_polartwilight,
  heavysleet: img_heavysleet,
  heavysleetandthunder: img_heavysleetandthunder,
  heavysleetshowers_day: img_heavysleetshowers_day,
  heavysleetshowers_night: img_heavysleetshowers_night,
  heavysleetshowers_polartwilight: img_heavysleetshowers_polartwilight,
  heavysleetshowersandthunder_day: img_heavysleetshowersandthunder_day,
  heavysleetshowersandthunder_night: img_heavysleetshowersandthunder_night,
  heavysleetshowersandthunder_polartwilight:
    img_heavysleetshowersandthunder_polartwilight,
  heavysnow: img_heavysnow,
  heavysnowandthunder: img_heavysnowandthunder,
  heavysnowshowers_day: img_heavysnowshowers_day,
  heavysnowshowers_night: img_heavysnowshowers_night,
  heavysnowshowers_polartwilight: img_heavysnowshowers_polartwilight,
  heavysnowshowersandthunder_day: img_heavysnowshowersandthunder_day,
  heavysnowshowersandthunder_night: img_heavysnowshowersandthunder_night,
  heavysnowshowersandthunder_polartwilight:
    img_heavysnowshowersandthunder_polartwilight,
  lightrain: img_lightrain,
  lightrainandthunder: img_lightrainandthunder,
  lightrainshowers_day: img_lightrainshowers_day,
  lightrainshowers_night: img_lightrainshowers_night,
  lightrainshowers_polartwilight: img_lightrainshowers_polartwilight,
  lightrainshowersandthunder_day: img_lightrainshowersandthunder_day,
  lightrainshowersandthunder_night: img_lightrainshowersandthunder_night,
  lightrainshowersandthunder_polartwilight:
    img_lightrainshowersandthunder_polartwilight,
  lightsleet: img_lightsleet,
  lightsleetandthunder: img_lightsleetandthunder,
  lightsleetshowers_day: img_lightsleetshowers_day,
  lightsleetshowers_night: img_lightsleetshowers_night,
  lightsleetshowers_polartwilight: img_lightsleetshowers_polartwilight,
  lightsnow: img_lightsnow,
  lightsnowandthunder: img_lightsnowandthunder,
  lightsnowshowers_day: img_lightsnowshowers_day,
  lightsnowshowers_night: img_lightsnowshowers_night,
  lightsnowshowers_polartwilight: img_lightsnowshowers_polartwilight,
  lightssleetshowersandthunder_day: img_lightssleetshowersandthunder_day,
  lightssleetshowersandthunder_night: img_lightssleetshowersandthunder_night,
  lightssleetshowersandthunder_polartwilight:
    img_lightssleetshowersandthunder_polartwilight,
  lightssnowshowersandthunder_day: img_lightssnowshowersandthunder_day,
  lightssnowshowersandthunder_night: img_lightssnowshowersandthunder_night,
  lightssnowshowersandthunder_polartwilight:
    img_lightssnowshowersandthunder_polartwilight,
  partlycloudy_day: img_partlycloudy_day,
  partlycloudy_night: img_partlycloudy_night,
  partlycloudy_polartwilight: img_partlycloudy_polartwilight,
  rain: img_rain,
  rainandthunder: img_rainandthunder,
  rainshowers_day: img_rainshowers_day,
  rainshowers_night: img_rainshowers_night,
  rainshowers_polartwilight: img_rainshowers_polartwilight,
  rainshowersandthunder_day: img_rainshowersandthunder_day,
  rainshowersandthunder_night: img_rainshowersandthunder_night,
  rainshowersandthunder_polartwilight: img_rainshowersandthunder_polartwilight,
  sleet: img_sleet,
  sleetandthunder: img_sleetandthunder,
  sleetshowers_day: img_sleetshowers_day,
  sleetshowers_night: img_sleetshowers_night,
  sleetshowers_polartwilight: img_sleetshowers_polartwilight,
  sleetshowersandthunder_day: img_sleetshowersandthunder_day,
  sleetshowersandthunder_night: img_sleetshowersandthunder_night,
  sleetshowersandthunder_polartwilight:
    img_sleetshowersandthunder_polartwilight,
  snow: img_snow,
  snowandthunder: img_snowandthunder,
  snowshowers_day: img_snowshowers_day,
  snowshowers_night: img_snowshowers_night,
  snowshowers_polartwilight: img_snowshowers_polartwilight,
  snowshowersandthunder_day: img_snowshowersandthunder_day,
  snowshowersandthunder_night: img_snowshowersandthunder_night,
  snowshowersandthunder_polartwilight: img_snowshowersandthunder_polartwilight
};

type WeatherSectionData = {
  date: string;
  windSpeed: number;
  probabilityOfPrecipitation: number;
  precipitationAmount: number;
  imageSymbolCode: string;
  temperature: number;
};

function timeSeriesToWeatherData(timeSeries: TimeSeries) {
  return {
    date: moment.utc(timeSeries?.time).format('YYYY-MM-DD'),
    windSpeed: timeSeries.data?.instant?.details?.wind_speed,
    probabilityOfPrecipitation:
      timeSeries.data?.next_12_hours?.details?.probability_of_precipitation,
    precipitationAmount:
      timeSeries.data?.next_6_hours?.details?.precipitation_amount,
    imageSymbolCode: timeSeries.data?.next_12_hours?.summary?.symbol_code,
    temperature: timeSeries.data?.instant?.details?.air_temperature
  };
}
type WeatherSectionProps = {
  day: string;
  weather: WeatherSectionData;
};

function WeatherSection({ weather, day }: WeatherSectionProps) {
  return (
    <div className="Weather__section">
      <h3>
        {day}, {weather.date}
      </h3>
      <Flex direction="column">
        <Flex className="Weather__section__temp">
          <div>
            <img src={weatherIcons[weather.imageSymbolCode]} alt="" />
          </div>
          <div>
            <Flex className="Weather__section__summary">
              <Flex>
                <div className="Weather__section_text">Windspeed:</div>
                <div className="Weather__section_value">
                  {Math.round(weather.windSpeed)} m/s
                </div>
              </Flex>
              <p className="Weather__section_text">
                <>Precipitation: {weather.precipitationAmount} mm</>
              </p>
            </Flex>
            <div className="h1">{Math.round(weather.temperature)}°C</div>
          </div>
        </Flex>
      </Flex>
    </div>
  );
}

function Weather({ location = defaultLocation }) {
  const weatherQuery = useQuery({
    queryKey: ['weather', location?.latitude, location?.longitude],
    queryFn: () => {
      return fetch(
        'https://api.met.no/weatherapi/locationforecast/2.0/complete?' +
          queryString.stringify({
            lat: location?.latitude,
            lon: location?.longitude
          })
      ).then((res) => res.json());
    },
    enabled: location != null,
    select: useCallback((data: MetWeatherResponse) => {
      if (data.properties.timeseries.length >= 24) {
        return {
          now: timeSeriesToWeatherData(data.properties.timeseries[0]),
          tomorrow: timeSeriesToWeatherData(data.properties.timeseries[24])
        };
      }

      return null;
    }, [])
  });

  if (weatherQuery.data != null) {
    return (
      <Flex direction="column" className="Weather">
        <div className="Weather__title">
          <h2>{location.city}</h2>
          <img src={imgLocation} alt="Location" />
        </div>
        <Flex direction="column" className="WeatherItems">
          <WeatherSection weather={weatherQuery.data?.now} day="Today" />
          <WeatherSection
            weather={weatherQuery.data?.tomorrow}
            day="Tomorrow"
          />
        </Flex>
      </Flex>
    );
  }
  return <p>No weather data</p>;
}

export default React.memo(Weather);
