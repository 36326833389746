import React, { useMemo } from 'react';
import LineChart, {
  HighchartsOptionsWithCrossing
} from 'ecto-common/lib/Charts/LineChart';
import _ from 'lodash';
import { formatNumberUnit } from 'ecto-common/lib/utils/stringUtils';
import {
  exportChartConfig,
  yAxisFormatter
} from 'ecto-common/lib/SignalSelector/ChartUtils';
import T from 'ecto-common/lib/lang/Language';
import { Highcharts } from 'ecto-common/lib/Highcharts/Highcharts';
import { SeriesOptionsType } from 'highcharts';
import { SignalInputType } from 'ecto-common/lib/Dashboard/datasources/LastSignalValuesDataSource';
import { FullSignalProviderResponseModel } from 'ecto-common/lib/API/APIGen';

// Extend SignalInputType with properties for rendering
export type AdjustableChartSignal = SignalInputType & {
  signalId?: string;
  signalProvider?: FullSignalProviderResponseModel;
  name?: string;
  time?: string;
  unit?: string;
  isWritable?: boolean;
};

/**
 * The data source extends the input signalX/signalY with additional properties
 * for rendering values.
 */
export type AdjustableChartSeries = {
  signalX: AdjustableChartSignal;
  signalY: AdjustableChartSignal;
};

export type AdjustableChartCurve = {
  name: string;
  color: string;
  isWritableX: boolean;
  isWritableY: boolean;
  series: AdjustableChartSeries[];
};

const chartOptions: HighchartsOptionsWithCrossing = {
  xAxis: {
    crossing: 0,
    opposite: true
  },
  yAxis: {
    crossing: 0,
    labels: {
      formatter: yAxisFormatter
    }
  },
  chart: {
    marginLeft: 10,
    marginTop: 10
  },
  exporting: exportChartConfig
};

const flattenSignals = (signals: AdjustableChartSeries[]) =>
  _.flatMap(signals, (signal) => [signal.signalX, signal.signalY]);

const createSeries = (
  data: AdjustableChartCurve,
  onChartDrop: (data: AdjustableChartSignal[]) => void
): Highcharts.SeriesOptionsType => {
  const onChartDropHandler: Highcharts.PointDropCallbackFunction = (event) => {
    _.defer(() => {
      const sortedPoints = event.target.series.data
        .sort((a, b) => a.x - b.x)
        .map((point) => [point.x, point.y]);

      event.target.series.setData(sortedPoints, true, undefined, false);

      // Should be equal length to sortedPoints
      const formattedPoints = flattenSignals(
        _.map(data.series, (signal, idx) => {
          const [valueX, valueY] = sortedPoints[idx];

          return {
            ...signal,
            signalX: {
              ...signal.signalX,
              value: valueX
            },
            signalY: {
              ...signal.signalY,
              value: valueY
            }
          };
        })
      );

      onChartDrop(formattedPoints);
    });
  };

  const series = data.series.map(({ signalX, signalY }) => [
    signalX.value,
    signalY.value
  ]);

  return {
    data: series,
    color: data.color,
    dragDrop: {
      draggableX: data.isWritableX,
      draggableY: data.isWritableY,
      dragPrecisionX: 0.1,
      dragPrecisionY: 0.1
    },
    point: {
      events: {
        drop: onChartDropHandler
      }
    },
    tooltip: {
      headerFormat: '',
      pointFormatter: function () {
        const unitX = data.series[this.index].signalX?.unit;
        const unitY = data.series[this.index].signalY?.unit;
        return (
          formatNumberUnit(this.x, unitX) +
          ', ' +
          formatNumberUnit(this.y, unitY)
        );
      }
    },
    marker: {
      enabled: true
    },
    name: data.name,
    stickyTracking: false,
    cursor: 'move',
    animation: false,
    type: 'line'
  };
};

export type HighchartsOptionsWithAdjustableSettings =
  HighchartsOptionsWithCrossing & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    additionalSeries?: any[];
    dragDrop: {
      // TODO: Unsure why this is not on options object itself - verify if valid
      dragMinY: number;
      dragMaxY: number;
      dragMinX: number;
      dragMaxX: number;
    };
  };

interface AdjustableChartProps {
  series: AdjustableChartCurve[];
  onChartDrop(data: AdjustableChartSignal[]): void;
  settings?: HighchartsOptionsWithAdjustableSettings;
  size?: {
    width?: number;
    height?: number;
  };
  domProps?: object;
  isLoading?: boolean;
  hasPointsOverflow?: boolean;
  noSeriesText?: string;
  hasError?: boolean;
}

const AdjustableChart = ({
  series,
  onChartDrop,
  settings,
  size,
  domProps,
  hasPointsOverflow = false,
  isLoading = false,
  hasError = false,
  noSeriesText = T.graphs.nosignalsfound
}: AdjustableChartProps) => {
  const _series: SeriesOptionsType[] = useMemo(() => {
    return [
      ..._.map(series, (data) => createSeries(data, onChartDrop)),
      ...(settings.additionalSeries ?? [])
    ];
  }, [series, onChartDrop, settings?.additionalSeries]);

  const options = useMemo(
    () => _.merge(_.cloneDeep(chartOptions), settings ?? {}),
    [settings]
  );

  return (
    <LineChart
      series={_series}
      options={options}
      containerHeight={size?.height}
      containerWidth={size?.width}
      domProps={domProps}
      hasPointsOverflow={hasPointsOverflow}
      isLoading={isLoading}
      hasError={hasError}
      noSeriesText={noSeriesText}
    />
  );
};

export default React.memo(AdjustableChart);
