/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export enum AggregationType {
  Unknown = 'Unknown',
  None = 'None',
  Mean = 'Mean',
  Max = 'Max',
  Min = 'Min',
  First = 'First',
  Last = 'Last',
  Count = 'Count',
  Sum = 'Sum',
  Median = 'Median',
  Mode = 'Mode',
  NoneWithTags = 'NoneWithTags'
}

export enum AlarmType {
  Unknown = 'Unknown',
  Stateful = 'Stateful',
  Stateless = 'Stateless'
}

export interface BuildingInfoResponseModel {
  /** @format uuid */
  id: string;
  /** @format uuid */
  buildingId: string;
  districtHeatingFacilityId?: string | null;
  buildingStatus: BuildingStatus;
  /** @format date-time */
  buildingStatusTimestamp: string;
}

export enum BuildingStatus {
  Unknown = 'Unknown',
  Created = 'Created',
  Testing = 'Testing',
  Production = 'Production'
}

export enum ByteOrder {
  Unknown = 'Unknown',
  Abcd = 'Abcd',
  Badc = 'Badc',
  Cdab = 'Cdab',
  Dcba = 'Dcba'
}

export enum ComfortAlarmType {
  Unknown = 'Unknown',
  FlowTempHigh = 'FlowTempHigh',
  FlowTempLow = 'FlowTempLow',
  RoomTempHigh = 'RoomTempHigh',
  RoomTempLow = 'RoomTempLow',
  RoomTempSensor = 'RoomTempSensor',
  HeatingHumidityHigh = 'HeatingHumidityHigh',
  HeatingTempHigh = 'HeatingTempHigh',
  WaterTempHigh = 'WaterTempHigh',
  WaterTempLow = 'WaterTempLow'
}

export interface ConnectionDefaultModbusConfigResponseModel {
  /** @format uuid */
  id: string;
  /** @format int32 */
  port?: number;
  ipAddress?: string | null;
  modbusMode: ModbusMode;
  modbusType: ModbusType;
  serialName?: string | null;
  /** @format int32 */
  baudrate?: number;
  /** @format int32 */
  databits?: number;
  parity: Parity;
  /** @format int32 */
  stopbits?: number;
  /** @format int32 */
  connectionTimeout?: number;
  /** @format int32 */
  byteTimeoutSeconds?: number;
  /** @format int32 */
  byteTimeoutMicroseconds?: number;
  /** @format int32 */
  responseTimeoutSeconds?: number;
  /** @format int32 */
  responseTimeoutMicroseconds?: number;
  /** @format int32 */
  slaveId?: number;
  /** @format int32 */
  watchdogModbusTimeout?: number;
  /** @format int32 */
  delayReadModbus?: number;
  /** @format int32 */
  delayReadDb?: number;
  /** @format int32 */
  delayWriteModbus?: number;
  /** @format int32 */
  delayWriteDb?: number;
  /** @format int32 */
  delayFlag?: number;
  /** @format int32 */
  timeoutFlag?: number;
  /** @format int32 */
  delayWriteReadback?: number;
  /** @format int32 */
  queueSizeFactor?: number;
}

export enum DataFormat {
  Unknown = 'Unknown',
  Continuous = 'Continuous',
  Discrete = 'Discrete'
}

export enum DateComparisonType {
  Unknown = 'Unknown',
  Before = 'Before',
  After = 'After',
  Exact = 'Exact'
}

export enum EfCacheTag {
  Unknown = 'Unknown',
  Node = 'Node',
  AlarmSignal = 'AlarmSignal',
  AlarmSignalTemplate = 'AlarmSignalTemplate',
  EquipmentTemplate = 'EquipmentTemplate',
  Device = 'Device',
  SignalProvider = 'SignalProvider',
  SignalProviderTemplate = 'SignalProviderTemplate',
  User = 'User',
  PowerControl = 'PowerControl',
  RemoteOptimisation = 'RemoteOptimisation',
  TemperatureImpact = 'TemperatureImpact',
  Meteorology = 'Meteorology',
  Comfort = 'Comfort',
  DeviceTemplate = 'DeviceTemplate',
  ProcessMap = 'ProcessMap',
  Schedule = 'Schedule',
  PowerDeltaControl = 'PowerDeltaControl',
  LoBaBatteryChargingThreshold = 'LoBaBatteryChargingThreshold'
}

export interface EquipmentTypeResponseModel {
  /** @format uuid */
  equipmentTypeId: string;
  name?: string | null;
}

export enum ExportFormat {
  Unknown = 'Unknown',
  Csv = 'Csv',
  Excel = 'Excel'
}

export enum ExportStatus {
  Unknown = 'Unknown',
  Pending = 'Pending',
  Running = 'Running',
  Finished = 'Finished',
  Aborted = 'Aborted'
}

export enum FillOption {
  None = 'None',
  Previous = 'Previous',
  Linear = 'Linear',
  Null = 'Null',
  Numerical = 'Numerical'
}

export interface GetEnumsAndFixedConfigurationsResponseModel {
  alarmTypes?: AlarmType[] | null;
  gridTypes?: GridType[] | null;
  nodeTypes?: NodeType[] | null;
  scheduleStatus?: ScheduleStatus[] | null;
  aggregationTypes?: AggregationType[] | null;
  samplingIntervals?: SamplingInterval[] | null;
  signalProviderTypes?: SignalProviderType[] | null;
  modbusModes?: ModbusMode[] | null;
  modbusTypes?: ModbusType[] | null;
  parities?: Parity[] | null;
  units?: UnitResponseModel[] | null;
  exportFormats?: ExportFormat[] | null;
  exportStatus?: ExportStatus[] | null;
  signalFunctions?: SignalFunction[] | null;
  readWrites?: ReadWrite[] | null;
  signalModbusTypes?: SignalModbusType[] | null;
  byteOrders?: ByteOrder[] | null;
  modbusDataTypes?: ModbusDataType[] | null;
  toolTypes?: ToolType[] | null;
  connectionModbusConfigDefaults?: ConnectionDefaultModbusConfigResponseModel | null;
  buildingStatus?: BuildingStatus[] | null;
  fillOption?: FillOption[] | null;
  mappingAction?: MappingAction[] | null;
  signalDirection?: SignalDirection[] | null;
  graphicalRepresentation?: GraphicalRepresentation[] | null;
  dataFormat?: DataFormat[] | null;
  efCacheTag?: EfCacheTag[] | null;
  dateComparisonType?: DateComparisonType[] | null;
  comfortAlarmType?: ComfortAlarmType[] | null;
  networkInterfaceType?: NetworkInterfaceType[] | null;
  powerDeltaControlType?: PowerDeltaControlType[] | null;
}

export interface GetLatestSignalValuesRequestModel {
  signalIds: string[];
  /** @format date-time */
  endDate?: string | null;
}

export interface GetNodeSignalViewsRequestModel {
  nodeIds: string[];
}

export interface GetSignalsByNodeRequestModel {
  nodesIds?: string[] | null;
  signalProviderTypes?: SignalProviderType[] | null;
}

export enum GraphicalRepresentation {
  Unknown = 'Unknown',
  Event = 'Event',
  Step = 'Step',
  Linear = 'Linear',
  Spline = 'Spline'
}

export enum GridType {
  Unknown = 'Unknown',
  Heating = 'Heating',
  Cooling = 'Cooling',
  Electricity = 'Electricity',
  Ectogrid = 'Ectogrid',
  Generic = 'Generic',
  Inherited = 'Inherited'
}

export enum MappingAction {
  Unknown = 'Unknown',
  NoAction = 'NoAction',
  SendOnPush = 'SendOnPush',
  SendBasedOnCycleTime = 'SendBasedOnCycleTime'
}

export enum ModbusDataType {
  Unknown = 'Unknown',
  Int = 'Int',
  Real = 'Real'
}

export enum ModbusMode {
  Unknown = 'Unknown',
  Slave = 'Slave',
  Master = 'Master'
}

export enum ModbusType {
  Unknown = 'Unknown',
  Rtu = 'Rtu',
  Tcp = 'Tcp'
}

export enum NetworkInterfaceType {
  Unknown = 'Unknown',
  PPP0 = 'PPP0',
  Eth0 = 'Eth0'
}

export interface NodeEquipmentResponseModel {
  /** @format uuid */
  equipmentId: string;
  name?: string | null;
  description?: string | null;
  /** @format uuid */
  equipmentTypeId: string;
  /** @format uuid */
  nodeId: string;
  linearOptimisations?: SignalGroupLiteResponseModel[] | null;
  powerControls?: SignalGroupLiteResponseModel[] | null;
}

export interface NodeResponseModel {
  /** @format uuid */
  nodeId: string;
  name?: string | null;
  /** @format double */
  longitude?: number | null;
  /** @format double */
  latitude?: number | null;
  street?: string | null;
  nodeType: NodeType;
  grids?: GridType[] | null;
  buildingInfo?: BuildingInfoResponseModel | null;
  /** @format int32 */
  alarmStatus?: number | null;
  parentIds?: string[] | null;
  equipments?: NodeEquipmentResponseModel[] | null;
  powerControls?: SignalGroupLiteResponseModel[] | null;
  /** @format int32 */
  numberOfActiveAlarms?: number;
  tags?: string[] | null;
}

export interface NodeSignalViewResponseModel {
  /** @format uuid */
  nodeId: string;
  map?: string | null;
  equipmentSignals?: SignalInfoResponseModel[] | null;
}

export enum NodeType {
  Unknown = 'Unknown',
  Site = 'Site',
  Building = 'Building',
  Equipment = 'Equipment'
}

export enum Parity {
  Unknown = 'Unknown',
  None = 'None',
  Even = 'Even',
  Odd = 'Odd'
}

export enum PowerDeltaControlType {
  Unknown = 'Unknown',
  Heating = 'Heating',
  Cooling = 'Cooling'
}

export interface ProblemDetails {
  type?: string | null;
  title?: string | null;
  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
}

export enum ReadWrite {
  Unknown = 'Unknown',
  Read = 'Read',
  Write = 'Write'
}

export interface RoomResponseExanded {
  id?: string;
  name?: string;
  company?: string;
  location?: string;
  contactPerson?: string;
  tenantId?: string;
  screenContentsIds?: string[];
  screenContents?: ScreenContentsResponse[] | null;
}

export enum SamplingInterval {
  Unknown = 'Unknown',
  Raw = 'Raw',
  Minute = 'Minute',
  Hour = 'Hour',
  Day = 'Day',
  Week = 'Week',
  Month = 'Month',
  MinutesFive = 'MinutesFive',
  MinutesFifteen = 'MinutesFifteen'
}

export enum ScheduleStatus {
  Unknown = 'Unknown',
  Pending = 'Pending',
  Running = 'Running',
  Finished = 'Finished',
  Aborted = 'Aborted'
}

export enum ScreenContentType {
  Image = 'Image',
  Video = 'Video',
  Text = 'Text',
  EctocloudDashboard = 'EctocloudDashboard',
  WebPage = 'WebPage'
}

export interface ScreenContentsResponse {
  id?: string;
  name?: string;
  tenantId?: string;
  screen1?: ScreenContentsResponseScreenContent | null;
  screen2?: ScreenContentsResponseScreenContent | null;
  screen3?: ScreenContentsResponseScreenContent | null;
  fullWallScreen?: ScreenContentsResponseScreenContent | null;
}

export interface ScreenContentsResponseScreenContent {
  screenContentType?: ScreenContentType;
  text?: string;
  filename?: string;
  /** @format uri */
  contentUri?: string | null;
  /** @format date-time */
  upladed?: string;
  uploadedBy?: string;
  ectocloudDashboard?: ScreenContentsResponseScreenContentEctocloudDashboard | null;
}

export interface ScreenContentsResponseScreenContentEctocloudDashboard {
  name?: string;
  tenantId?: string;
  nodeTenantId?: string;
  gridId?: string;
  nodeId?: string;
}

export enum SignalDirection {
  Unknown = 'Unknown',
  Input = 'Input',
  Output = 'Output'
}

export enum SignalFunction {
  Unknown = 'Unknown',
  None = 'None',
  MobileSignalStrength = 'MobileSignalStrength'
}

export interface SignalGroupLiteResponseModel {
  /** @format uuid */
  signalGroupId: string;
  name?: string | null;
  canSchedule?: boolean;
}

export interface SignalInfoResponseModel {
  /** @format uuid */
  signalId: string;
  /** @format uuid */
  energyManagerDeviceId?: string | null;
  /** @format float */
  step?: number | null;
  isWritable?: boolean;
}

export enum SignalModbusType {
  Unknown = 'Unknown',
  DiscreteInput = 'DiscreteInput',
  Coil = 'Coil',
  InputRegister = 'InputRegister',
  HoldingRegister = 'HoldingRegister'
}

export interface SignalProviderByNodeResponseModel {
  /** @format uuid */
  signalProviderId: string;
  signalProviderName?: string | null;
  signalProviderType: SignalProviderType;
  signals?: SignalResponseModel[] | null;
  nodeIds?: string[] | null;
}

export interface SignalProviderTelemetryResponseModel {
  /** @format uuid */
  signalId: string;
  signals?: SignalProviderTelemetryValueResponseModel[] | null;
}

export interface SignalProviderTelemetryValueResponseModel {
  /** @format date-time */
  time: string;
  /** @format double */
  value?: number;
}

export enum SignalProviderType {
  Unknown = 'Unknown',
  SteerablePower = 'SteerablePower',
  PowerControl = 'PowerControl',
  LinearOptimisation = 'LinearOptimisation',
  Alarm = 'Alarm',
  Meteorology = 'Meteorology',
  Equipment = 'Equipment',
  HeatingForecast = 'HeatingForecast',
  CoolingForecast = 'CoolingForecast',
  COPCoolingForecast = 'COPCoolingForecast',
  COPHeatingForecast = 'COPHeatingForecast',
  ReversibleHeatPumpCOP = 'ReversibleHeatPumpCOP',
  ReversibleHeatPumpScheduler = 'ReversibleHeatPumpScheduler',
  AccumulatorTankEnergyModel = 'AccumulatorTankEnergyModel',
  EctogridSetpointHeating = 'EctogridSetpointHeating',
  EctogridSetpointCooling = 'EctogridSetpointCooling',
  EctogridSetpointModel = 'EctogridSetpointModel',
  EctogridControlBoundary = 'EctogridControlBoundary',
  DisaIntegration = 'DisaIntegration',
  TemperatureImpact = 'TemperatureImpact',
  ComfortHeating = 'ComfortHeating',
  EcoguardIntegration = 'EcoguardIntegration',
  ReversibleHeatPumpSchedulerConfig = 'ReversibleHeatPumpSchedulerConfig',
  ReversibleHeatPumpControl = 'ReversibleHeatPumpControl',
  OptimaControlSignals = 'OptimaControlSignals',
  ElvacoIntegration = 'ElvacoIntegration',
  PowerDeltaControl = 'PowerDeltaControl',
  ElectricityPeakGuardControlLoop = 'ElectricityPeakGuardControlLoop',
  ElectricityPeakGuardCooling = 'ElectricityPeakGuardCooling',
  ElectricityPeakGuardHeating = 'ElectricityPeakGuardHeating',
  EctogridEnergyUsage = 'EctogridEnergyUsage',
  EctogridEnergyElectricityUsage = 'EctogridEnergyElectricityUsage',
  EctogridEnergyHeatingUsage = 'EctogridEnergyHeatingUsage',
  EctogridEnergyUsageHeating = 'EctogridEnergyUsageHeating',
  EctogridEnergyUsageCooling = 'EctogridEnergyUsageCooling',
  EctogridEnergyDelivery = 'EctogridEnergyDelivery',
  EctogridEnergyHeatingDelivery = 'EctogridEnergyHeatingDelivery',
  EctogridEnergyCoolingDelivery = 'EctogridEnergyCoolingDelivery',
  EctogridEctoCOP = 'EctogridEctoCOP',
  EctogridEctoCOPHeating = 'EctogridEctoCOPHeating',
  EctogridEctoCOPCooling = 'EctogridEctoCOPCooling',
  EctogridEnergyActiveBalancing = 'EctogridEnergyActiveBalancing',
  EctogridEnergyHeatingActiveBalancing = 'EctogridEnergyHeatingActiveBalancing',
  EctogridEnergyCoolingActiveBalancing = 'EctogridEnergyCoolingActiveBalancing',
  EctogridEnergyUsageSubstation = 'EctogridEnergyUsageSubstation',
  EctogridEnergyElectricityUsageSubstation = 'EctogridEnergyElectricityUsageSubstation',
  EctogridEnergyUsageActiveBalancing = 'EctogridEnergyUsageActiveBalancing',
  EctogridEnergyElectricityUsageActiveBalancing = 'EctogridEnergyElectricityUsageActiveBalancing',
  EctogridEnergyHeatingUsageActiveBalancing = 'EctogridEnergyHeatingUsageActiveBalancing',
  EctogridExcessBalancingRatio = 'EctogridExcessBalancingRatio',
  EctogridEnergyHeatingFromGrid = 'EctogridEnergyHeatingFromGrid',
  EctogridEnergyHeatingToGrid = 'EctogridEnergyHeatingToGrid',
  EctogridEnergyCoolingFromGrid = 'EctogridEnergyCoolingFromGrid',
  EctogridEnergyCoolingToGrid = 'EctogridEnergyCoolingToGrid',
  EctogridEnergyToGrid = 'EctogridEnergyToGrid',
  EctogridEnergyFromGrid = 'EctogridEnergyFromGrid',
  AutomaticPowerControlSchedules = 'AutomaticPowerControlSchedules',
  LoBaEnergyElectricityFromGrid = 'LoBaEnergyElectricityFromGrid',
  LoBaEnergyElectricityToGrid = 'LoBaEnergyElectricityToGrid',
  LoBaEnergyElectricityFromBattery = 'LoBaEnergyElectricityFromBattery',
  LoBaEnergyElectricityToBattery = 'LoBaEnergyElectricityToBattery',
  LoBaEnergyElectricityFromPV = 'LoBaEnergyElectricityFromPV',
  LoBaEnergyElectricityConsumption = 'LoBaEnergyElectricityConsumption',
  LoBaSelfSufficiencyRatio = 'LoBaSelfSufficiencyRatio',
  LoBaElectricityPeakShaving = 'LoBaElectricityPeakShaving',
  EctoGridKPIConfig = 'EctoGridKPIConfig',
  LocalBalancingKPIConfig = 'LocalBalancingKPIConfig',
  LoBaBatteryChargingThreshold = 'LoBaBatteryChargingThreshold',
  PowerControlStopSignal = 'PowerControlStopSignal',
  LoBaSelfConsumptionRatio = 'LoBaSelfConsumptionRatio',
  EctogridPreloadingHeating = 'EctogridPreloadingHeating',
  EctogridPreloadingCooling = 'EctogridPreloadingCooling',
  EctogridPreloadingHeatingMapping = 'EctogridPreloadingHeatingMapping',
  EctogridPreloadingCoolingMapping = 'EctogridPreloadingCoolingMapping',
  NordPoolIntegration = 'NordPoolIntegration',
  NetmoreIntegration = 'NetmoreIntegration',
  HeatingDemandPriceLoadShifting = 'HeatingDemandPriceLoadShifting'
}

export interface SignalResponseModel {
  /** @format uuid */
  signalId: string;
  name?: string | null;
  description?: string | null;
  dataFormat: DataFormat;
  signalDirection: SignalDirection;
  graphicalRepresentation: GraphicalRepresentation;
  isWritable?: boolean;
  signalCategoryIds?: string[] | null;
  /** @format uuid */
  signalTypeId: string;
}

export interface SignalTypeResponseModel {
  /** @format uuid */
  id: string;
  name?: string | null;
  description?: string | null;
  /** @format uuid */
  unitId: string;
  /** @format uuid */
  signalTypeFolderId?: string | null;
  isSystem?: boolean;
}

export enum ToolType {
  Unknown = 'Unknown',
  PowerControlHeatingTracking = 'PowerControlHeatingTracking',
  PowerControlHeatingBasic = 'PowerControlHeatingBasic',
  PowerControlCoolingTracking = 'PowerControlCoolingTracking',
  PowerControlCoolingBasic = 'PowerControlCoolingBasic',
  LinearOptimisation = 'LinearOptimisation',
  PowerControlHeatingTransmit = 'PowerControlHeatingTransmit',
  PowerControlCoolingTransmit = 'PowerControlCoolingTransmit'
}

export interface UnitResponseModel {
  name?: string | null;
  /** @format uuid */
  id: string;
  dataType?: string | null;
  unit?: string | null;
}

export interface NodesNodesListParams {
  /** @format uuid */
  parentId?: string;
  nodes?: string[];
  nodeType?: NodeType;
  gridType?: GridType;
}

export interface SignalsValuesTimerangeListParams {
  signalIds?: string[];
  /** @format date-time */
  startDate?: string;
  /** @format date-time */
  endDate?: string;
  samplingInterval?: SamplingInterval;
  aggregationType?: AggregationType;
  isInclusive?: boolean;
}

export interface SignalsValuesCreateParams {
  /** @format date-time */
  startDate?: string;
  /** @format date-time */
  endDate?: string;
  /** @format int32 */
  points?: number;
}

/**
 * @title Ectotable proxy API
 * @version proxy
 */

import { APIGenType } from './APIGenType';
import {
  ContentType,
  Method,
  apiEndpoint,
  apiEndpointEmpty,
  apiEndpointEmptyUsingQueryParams,
  apiEndpointEmptyWithPath,
  apiEndpointUsingQueryParams
} from './APIUtils';

const apiTitle = APIGenType.EctotableClientAPIGen;

export interface GetRoomConfigurationPath {
  id: string;
}

const EctotableClientAPIGen = {
  EctocloudProxy: {
    equipmentsEquipmentTypesList: apiEndpointEmpty<
      EquipmentTypeResponseModel[],
      any
    >(
      Method.GET,
      `/api/equipments/equipmentTypes`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    nodesNodesList: apiEndpointEmptyUsingQueryParams<
      NodesNodesListParams,
      NodeResponseModel[],
      any
    >(
      Method.GET,
      `/api/nodes/nodes`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    nodesGridsList: apiEndpointEmpty<string[], any>(
      Method.GET,
      `/api/nodes/grids`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    enumsList: apiEndpointEmpty<
      GetEnumsAndFixedConfigurationsResponseModel,
      any
    >(
      Method.GET,
      `/api/enums`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    signalTypesAllCreate: apiEndpointEmpty<SignalTypeResponseModel[], any>(
      Method.POST,
      `/api/signalTypes/all`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    signalsValuesLastCreate: apiEndpoint<
      GetLatestSignalValuesRequestModel,
      SignalProviderTelemetryResponseModel[],
      any
    >(
      Method.POST,
      `/api/signals/values/last`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    signalsNodesCreate: apiEndpoint<
      GetSignalsByNodeRequestModel,
      SignalProviderByNodeResponseModel[],
      any
    >(
      Method.POST,
      `/api/signals/nodes`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    signalsValuesTimerangeList: apiEndpointEmptyUsingQueryParams<
      SignalsValuesTimerangeListParams,
      SignalProviderTelemetryResponseModel[],
      any
    >(
      Method.GET,
      `/api/signals/values/timerange`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    signalsValuesCreate: apiEndpointUsingQueryParams<
      string[],
      SignalsValuesCreateParams,
      SignalProviderTelemetryResponseModel[],
      any
    >(
      Method.POST,
      `/api/signals/values`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    nodeSignalViewsCreate: apiEndpoint<
      GetNodeSignalViewsRequestModel,
      NodeSignalViewResponseModel[],
      any
    >(
      Method.POST,
      `/api/nodeSignalViews`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    )
  },
  Room: {
    getRoomConfiguration: apiEndpointEmptyWithPath<
      RoomResponseExanded,
      ProblemDetails,
      GetRoomConfigurationPath
    >(
      Method.GET,
      ({ id }: GetRoomConfigurationPath) => `/api/room/${id}`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    )
  },
  Version: {
    version: apiEndpointEmpty<object, any>(
      Method.GET,
      `/Version`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    )
  }
};

export default EctotableClientAPIGen;
