import React from 'react';
import Spinner, { SpinnerSize } from 'ecto-common/lib/Spinner/Spinner';
import Flex, { AlignItems } from 'ecto-common/lib/Layout/Flex';
import DataTable from 'ecto-common/lib/DataTable/DataTable';

interface LoadingTableProps {
  name?: string;
  loadingText?: string;
}

export const LoadingTable = ({ name, loadingText }: LoadingTableProps) => {
  const loadingColumns = [
    {
      dataKey: 'text',
      label: name,
      dataFormatter: (_loadingText: string) => (
        <Flex alignItems={AlignItems.CENTER} gap={0}>
          <Spinner size={SpinnerSize.TINY} />
          &nbsp; {_loadingText}
        </Flex>
      )
    }
  ];

  const data = [
    {
      text: loadingText
    }
  ];

  return <DataTable inline columns={loadingColumns} data={data} />;
};
