import React from 'react';
import styles from './SegmentControl.module.css';
import classNames from 'classnames';

export interface SegmentControlProps {
  /**
   * The items of the SegmentControl. Should be SegmentControlItem.
   */
  children?: React.ReactNode;
  /**
   * Used to override the appearance of the button. Should be a valid CSS class name.
   */
  className?: string;
  /**
   * If set to true then the segment will be a vertical list.
   */
  vertical?: boolean;
  /**
   * If set to true then the segment control will attempt to claim as much space as possible in its layout direction
   */
  expanding?: boolean;
}

/**
 * A Segment Control is a linear set of two or more segments where each functions as a mutually exclusive button. Like buttons, Segments can contain text or images. Instead of using this component directly you should probably use ArraySegmentControl.
 */
const SegmentControl = ({
  children,
  className,
  vertical = false,
  expanding = false
}: SegmentControlProps) => {
  return (
    <div
      className={classNames(
        styles.segmentControl,
        vertical && styles.vertical,
        expanding && styles.expanding,
        className
      )}
    >
      {children}
    </div>
  );
};

export default React.memo(SegmentControl);
