import { configureStore } from '@reduxjs/toolkit';
import general from './general';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

const reducers = {
  general
};

const store = configureStore({
  reducer: reducers
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type CommonRootState = ReturnType<typeof store.getState>;
export type CommonGetState = () => CommonRootState;
export type CommonDispatch = typeof store.dispatch;
export const useCommonDispatch: () => CommonDispatch = useDispatch; // Export a hook that can be reused to resolve types
export const useCommonSelector: TypedUseSelectorHook<CommonRootState> =
  useSelector;

export default store;
