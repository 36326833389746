import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import PresentationAPIGen, {
  FileMetadataModel
} from '../API/PresentationAPIGen';
import DataTable, { DataTableColumnProps } from '../DataTable/DataTable';
import T from '../lang/Language';
import { standardColumns } from '../utils/dataTableUtils';
import Icons from '../Icons/Icons';
import moment from 'moment';
import { useSimpleDialogState } from '../hooks/useDialogState';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import TenantContext from '../hooks/TenantContext';
import { toastStore } from '../Toast/ToastContainer';
import ConfirmDeleteDialog from '../ConfirmDeleteDialog/ConfirmDeleteDialog';
import _ from 'lodash';
import useSort from '../hooks/useSort';
import { DESC } from '../DataTable/SortDirection';
import TableColumn from '../TableColumn/TableColumn';
import Flex from '../Layout/Flex';
import TableSearchInput from '../SearchableTable/TableSearchInput';
import useSearchResult from '../hooks/useSearchResult';
import LayoutDirection from '../types/LayoutDirection';
import dimensions from '../styles/dimensions';

const FilesList = ({
  nodeId,
  items,
  isLoading: _isLoading,
  canEditFiles
}: {
  nodeId: string;
  items: FileMetadataModel[];
  isLoading: boolean;
  canEditFiles?: boolean;
}) => {
  const { contextSettings } = useContext(TenantContext);
  const [downloadItem, setDownloadItem] = useState<FileMetadataModel | null>(
    null
  );

  const getFileQuery = PresentationAPIGen.Nodes.getFile.useQuery(
    { nodeId, id: downloadItem?.id },
    {
      retry: false,
      enabled: downloadItem != null,
      meta: {
        errorString: T.format(T.toast.getaction.failformat, downloadItem?.name)
      }
    }
  );

  useEffect(() => {
    if (getFileQuery.data?.downloadUrl) {
      // Reset download item, so it can be requested again.
      setDownloadItem(null);
      window.open(getFileQuery.data.downloadUrl, '_blank');
    }
  }, [getFileQuery.data?.downloadUrl]);

  useEffect(() => {
    if (getFileQuery.isError) {
      // Reset download item, so it can be requested again.
      setDownloadItem(null);
    }
  }, [getFileQuery.isError]);

  const isLoading = _isLoading || getFileQuery.isLoading;
  const queryClient = useQueryClient();

  const deleteItemMutation = useMutation({
    mutationFn: (item: { id?: string; name?: string }) => {
      return PresentationAPIGen.Nodes.deleteFile.promise(
        contextSettings,
        { id: item.id, nodeId },
        null
      );
    },
    onSuccess: (_unused, item) => {
      toastStore.addSuccessToastForDeletedItem(
        _.isEmpty(item?.name) ? T.common.unknown : item.name
      );
      setConfirmDeleteItem(null);

      queryClient.invalidateQueries({
        queryKey: PresentationAPIGen.Nodes.getFiles.path(contextSettings, {
          nodeId
        })
      });
    },
    onError: (_unusedError, item) => {
      toastStore.addErrorToastForDeletedItem(
        _.isEmpty(item?.name) ? T.common.unknown : item.name
      );
    }
  });

  const [isDeleteDialogOpen, openDeleteDialog, closeDeleteDialog] =
    useSimpleDialogState();

  const [confirmDeleteItem, _setConfirmDeleteItem] =
    useState<FileMetadataModel>(null);
  const setConfirmDeleteItem = useCallback(
    (item: FileMetadataModel) => {
      _setConfirmDeleteItem(item);
      if (item) {
        openDeleteDialog();
      } else {
        closeDeleteDialog();
      }
    },
    [closeDeleteDialog, openDeleteDialog]
  );

  const columns: DataTableColumnProps<FileMetadataModel>[] = useMemo(
    () => [
      {
        dataKey: 'name',
        label: T.common.name,
        canSort: true,
        isLink: true,
        linkColumn: true,
        dataFormatter: (name: string) => (
          <TableColumn icon={<Icons.File />} title={name} />
        )
      },
      {
        dataKey: 'lastModified',
        label: T.common.date,
        dataFormatter: (date: string) =>
          moment(date).format('YYYY-MM-DD HH:mm:ss'),
        canSort: true
      },
      ...standardColumns<FileMetadataModel>({
        extraButtons: [
          {
            icon: <Icons.Download />,
            action: (item: FileMetadataModel) => {
              setDownloadItem(item);
            },
            enabled: () => !getFileQuery.isFetching
          }
        ],
        onDelete: canEditFiles
          ? (item: FileMetadataModel) => setConfirmDeleteItem(item)
          : null
      })
    ],
    [canEditFiles, getFileQuery.isFetching, setConfirmDeleteItem]
  );

  const [sortBy, sortDirection, setSortParams, sortedData] =
    useSort<FileMetadataModel>('lastModified', DESC, columns, items);
  const [searchString, setSearchString] = useState('');
  const searchItems = useSearchResult(sortedData, 'name', searchString);

  return (
    <Flex direction={LayoutDirection.VERTICAL} gap={dimensions.smallMargin}>
      <TableSearchInput
        searchString={searchString}
        onSearch={setSearchString}
        disabled={isLoading}
      />

      <DataTable<FileMetadataModel>
        isLoading={isLoading}
        data={searchItems}
        columns={columns}
        onSortChange={setSortParams}
        sortBy={sortBy}
        sortDirection={sortDirection}
        onClickRow={(item) => {
          setDownloadItem(item);
        }}
      />
      <ConfirmDeleteDialog
        isOpen={isDeleteDialogOpen}
        onModalClose={() => setConfirmDeleteItem(null)}
        isLoading={deleteItemMutation.isPending}
        itemName={
          _.isEmpty(confirmDeleteItem?.name)
            ? T.common.unknown
            : confirmDeleteItem.name
        }
        onDelete={() => deleteItemMutation.mutate(confirmDeleteItem)}
      />
    </Flex>
  );
};
export default FilesList;
