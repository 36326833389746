import Icons from 'ecto-common/lib/Icons/Icons';
import React from 'react';
import dimensions from 'ecto-common/lib/styles/dimensions';

const TooltipQuestionMark = () => {
  return (
    <Icons.QuestionCircle
      style={{ color: '#999', marginLeft: dimensions.smallMargin }}
    />
  );
};

export default React.memo(TooltipQuestionMark);
