import { useState, useCallback, useEffect, useMemo, useContext } from 'react';

import useReloadTrigger from 'ecto-common/lib/hooks/useReloadTrigger';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { useSignalUpdateEventHubSubscription } from 'ecto-common/lib/EventHubConnection/EventHubConnectionHooks';
import APIGen from '../API/APIGen';
import { SignalProviderTelemetryResponseModel } from '../API/APIGen';
import { useQuery } from '@tanstack/react-query';
import { featureFlagStore } from 'ecto-common/lib/FeatureFlags/FeatureFlags';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';

export type SignalValueType = {
  signalId: string;
  time: string;
  value: number;
};

export type LastSignalValuesDataSet = {
  values: SignalValueType[];
};

export type LastSignalValuesResult = Record<string, LastSignalValuesDataSet>;

const transformStaticResults = (
  data: SignalProviderTelemetryResponseModel[]
): LastSignalValuesResult => {
  const ret: LastSignalValuesResult = {};

  for (const item of data ?? []) {
    ret[item.signalId] = {
      values: item.signals?.map((signal) => ({
        signalId: item.signalId,
        ...signal
      }))
    };
  }

  return ret;
};

const useLatestSignalValues = (
  providerIds: string[],
  signalIds: string[],
  allSignalIds: string[] = [],
  fromDate: Moment = null
): LastSignalValuesResult => {
  const [nextFutureTimestamp, setNextFutureTimestamp] = useState<string>(null);
  const [signalData, setSignalData] = useState<LastSignalValuesResult>({});
  const [reloadTrigger, triggerReload] = useReloadTrigger();
  const { contextSettings } = useContext(TenantContext);

  const updateNextFutureTimestamp = useCallback(
    (newSignalData: LastSignalValuesResult) => {
      const currentTime = new Date().toISOString();

      const nextTime = _(newSignalData)
        .flatMap('values')
        .map('time')
        .sortBy()
        .find((date) => date > currentTime);

      setNextFutureTimestamp(nextTime);
    },
    []
  );

  useEffect(() => {
    if (nextFutureTimestamp != null) {
      const timeUntilNext = Math.max(
        moment(nextFutureTimestamp).diff(moment()),
        1
      );
      const timeout = setTimeout(() => {
        updateNextFutureTimestamp(signalData);
        triggerReload();
      }, timeUntilNext);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [
    nextFutureTimestamp,
    signalData,
    triggerReload,
    updateNextFutureTimestamp
  ]);

  const onValuesChanged = useCallback(
    (newValues: SignalValueType[]) => {
      setSignalData((s) => {
        // We want to merge data but overwrite values subarray, so we can't use _.merge
        const newSignalData = { ...s };
        _(newValues)
          .groupBy('signalId')
          .mapValues((val) => ({ values: _.orderBy(val, 'time') }))
          .forEach((entry, signalId) => {
            const oldestExistingValue = _.minBy(
              newSignalData[signalId]?.values,
              'time'
            )?.time;

            const newerValues =
              oldestExistingValue != null
                ? _.filter(
                    entry.values,
                    (value) => value.time >= oldestExistingValue
                  )
                : entry.values;

            if (newerValues.length > 0) {
              newSignalData[signalId] = {
                ...newSignalData[signalId],
                values: newerValues
              };
            }
          });

        updateNextFutureTimestamp(newSignalData);

        return newSignalData;
      });
    },
    [updateNextFutureTimestamp]
  );

  useEffect(() => {
    setSignalData((s) => ({ ...s }));
  }, [reloadTrigger]);

  useSignalUpdateEventHubSubscription(providerIds, signalIds, onValuesChanged);

  const signalArgs = {
    signalIds: allSignalIds,
    endDate: fromDate?.toISOString()
  };

  const { data: specificData } = useQuery({
    queryKey: ['signals', 'lastValues', signalArgs],

    queryFn: ({ signal }) => {
      const promise = featureFlagStore.getSnapshot()['eiot-signals']
        ? APIGen.EIoTSignals.getLastSignalValues.promise
        : APIGen.Signals.getLastSignalValues.promise;

      if (signalArgs.signalIds.length === 0) {
        return Promise.resolve<SignalProviderTelemetryResponseModel[]>([]);
      }

      return promise(contextSettings, signalArgs, signal);
    },

    enabled: fromDate != null
  });

  const specificSignalData = useMemo(() => {
    return transformStaticResults(specificData);
  }, [specificData]);

  if (fromDate != null) {
    return specificSignalData;
  }

  return signalData;
};

export default useLatestSignalValues;
