import { Matrix, translate, inverse } from 'transformation-matrix';
import {
  DOCUMENT_HEIGHT,
  DOCUMENT_WIDTH,
  DefineAreaState,
  ProcessMapDocument,
  ProcessMapLineObject,
  ProcessMapPendingConnectionLine,
  ProcessMapPendingConnectionSymbol,
  ProcessMapRectHandle,
  ProcessMapRectResizeArea,
  emptyProcessMapDocument,
  smallConnectionCircleRadius
} from 'ecto-common/lib/ProcessMap/ProcessMapViewConstants';
import _ from 'lodash';
import { Base64 } from 'js-base64';

export type MatrixPair = {
  current: Matrix;
  inverse: Matrix;
};

export const MouseDragStates = {
  Idle: 'idle',
  Panning: 'panning',
  MovingObject: 'movingObject',
  DefiningArea: 'definingArea',
  ResizingRect: 'resizingRect'
} as const;

export type MouseDragState =
  (typeof MouseDragStates)[keyof typeof MouseDragStates];

export type MouseState = {
  mouseDownX: number;
  mouseDownY: number;
  didMove: boolean;
  dragState: MouseDragState;
  buttonDown: number;
  dragOffsetX: number;
  dragOffsetY: number;
  selectedRectRootIndex: number;
  mouseDownTimeStamp: number;
  didMoveObject: boolean;
  cycleRectCounter: number;
  rectSizeInfo: ProcessMapRectHandle & { resizeArea: ProcessMapRectResizeArea };
};

export type ProcessMapState = {
  processMap: ProcessMapDocument;
  connectionCircleRadius: number;
  transform: MatrixPair;
  defineAreaState: DefineAreaState;
  selectedRectHandles: ProcessMapRectHandle[];
  pendingConnectionLine?: ProcessMapPendingConnectionLine;
  pendingConnectionSymbol?: ProcessMapPendingConnectionSymbol;
  hoverRectHandles: ProcessMapRectHandle[];
  isMouseDown: boolean;
  showDeleteConnections: boolean;
  mouseState: MouseState;
  undoStack: ProcessMapDocument[];
  undoStackIndex: number;
  hasChanges: boolean;
};

export type ProcessMapViewState = {
  processMap: ProcessMapDocument;
  transform: MatrixPair;
};

type SignalTextLocation = {
  x: number;
  y: number;
};

type SignalTextLocationWithText = SignalTextLocation & {
  text: string;
  fontStyle: string;
  fontSize: number;
};

export type MigrationData = {
  labelLocations: Record<string, SignalTextLocation>;
  valueLocations: Record<string, SignalTextLocation>;
  lineObjects: ProcessMapLineObject[];
  textLocations: SignalTextLocationWithText[];
};

export const initProcessMapState = (
  initialData: string,
  initialWidth = DOCUMENT_WIDTH,
  initialHeight = DOCUMENT_HEIGHT
): ProcessMapState => {
  const decodedData = Base64.decode(initialData ?? '');

  let document: ProcessMapDocument = _.cloneDeep({
    ...emptyProcessMapDocument,
    width: initialWidth,
    height: initialHeight
  });

  try {
    const jsonData: ProcessMapDocument = JSON.parse(decodedData);
    document = jsonData;
  } catch (_e) {
    /* empty */
  }

  const returnState: ProcessMapState = {
    processMap: document,
    connectionCircleRadius: smallConnectionCircleRadius,
    transform: {
      current: translate(1, 1),
      inverse: inverse(translate(1, 1))
    },
    showDeleteConnections: false,
    defineAreaState: null,
    selectedRectHandles: [],
    hoverRectHandles: [],
    isMouseDown: false,
    mouseState: {
      didMoveObject: false,
      didMove: false,
      cycleRectCounter: 0,
      mouseDownX: 0,
      mouseDownY: 0,
      dragState: MouseDragStates.Idle,
      dragOffsetX: 0,
      dragOffsetY: 0,
      buttonDown: 0,
      selectedRectRootIndex: 0,
      mouseDownTimeStamp: 0,
      rectSizeInfo: null
    },
    hasChanges: false,
    undoStack: [_.cloneDeep(document)],
    undoStackIndex: 0
  };

  return returnState;
};
