import { useEffect, useRef } from 'react';

const useInterval = (
  callback: () => void,
  delay: number,
  fireImmediately = false,
  reloadDependency: unknown = null
) => {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
    if (fireImmediately && callback != null) {
      callback();
    }
  }, [callback, fireImmediately]);

  useEffect(() => {
    const id = setInterval(() => {
      if (callbackRef.current != null) {
        callbackRef.current();
      }
    }, delay);
    return () => clearInterval(id);
  }, [delay, reloadDependency]);
};

export default useInterval;
