const defaultLocation = {
  city: 'Lund',
  country: 'Sweden',
  countryCode: 'SE',
  latitude: 55.70584,
  longitude: 13.19321,
  fallbackConsumption: 35
};

export default defaultLocation;

export type TableLocation = Partial<typeof defaultLocation>;
