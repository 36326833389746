// Imports
import ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ from "../../../../../../../node_modules/html-loader/dist/runtime/getUrl.js";
var ___HTML_LOADER_IMPORT_0___ = new URL("../img/weatherpoints.png", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("../img/weatherpoint_create.png", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("../img/weatherpoint_edit.png", import.meta.url);
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var code = "<h1 id=\"weather\">Weather</h1> <p>Administrators can set up weather locations in ectocloud. ectocloud will fetch historical data for the location and also continuously fetch future weather prognosis. the weather service is currently SMHI and the area Sweden. ectocloud uses outdoor temperature and wind speed but there are currently 19 parameters that are fetched from SMHI. The parameters can be found in the operator view, in the signal and graph view.</p> <p><img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\" alt=\"WeatherPoints\"></p> <h2 id=\"create-weather-point\">Create Weather point</h2> <p>It is possible to create a new Weather Point by clicking &quot;Add new Point&quot; which opens up a dialog like the one below. <img src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\" alt=\"CreateWeatherPoint\"></p> <p>Here can the Administrator move the pin to the correct location of the weather point. It is possible to zoom in and out either via the +/- buttons in the map or by scrolling on the mouse wheel. Panning of the map is done by dragging the map around with the mouse with the left mouse button pressed.</p> <p>Enter a name when the correct location of the pin is found in the edit box and click the &quot;Add new point&quot; button to save it. ectocloud will now start to fetch weather forecasts and historical data for the weather point.</p> <h2 id=\"edit-weather-point\">Edit Weather point</h2> <p>It is possible to edit a weather point by clicking on the weather point in the main list. A click opens up a window like the one below. <img src=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\" alt=\"WeatherPointEdit\"> Name and position is possible to edit here. It is also possible to select which nodes and buildings that shall be connected with the weather point.</p> ";
// Exports
export default code;