import React from 'react';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import ModalFooter from 'ecto-common/lib/Modal/ModalFooter';
import GreyButton from 'ecto-common/lib/Button/GreyButton';
import SaveButton from 'ecto-common/lib/Button/SaveButton';
import T from 'ecto-common/lib/lang/Language';
import _ from 'lodash';

interface UnsavedChangesFooterProps {
  onSave(): void;
  onDiscardChanges(): void;
  onCancel(): void;
  disabled?: boolean;
}

const UnsavedChangesFooter = ({
  onSave,
  onDiscardChanges,
  onCancel,
  disabled = false
}: UnsavedChangesFooterProps) => {
  return (
    <ModalFooter>
      <SaveButton onClick={onSave} disabled={disabled}>
        {T.common.save}
      </SaveButton>
      <GreyButton onClick={onDiscardChanges} disabled={disabled}>
        {T.common.discardchanges}
      </GreyButton>
      <GreyButton onClick={onCancel} disabled={disabled}>
        {T.common.cancel}
      </GreyButton>
    </ModalFooter>
  );
};

interface UnsavedChangesDialogProps {
  title?: string;
  message?: string;
  isOpen: boolean;
  onSave(): void;
  onDiscardChanges(): void;
  onCancel(): void;
  isLoading?: boolean;
}

const UnsavedChangesDialog = ({
  title = T.common.unsavedchanges,
  message = T.common.unsavedchangesmessage,
  isOpen,
  onSave,
  onDiscardChanges,
  onCancel,
  isLoading = false
}: UnsavedChangesDialogProps) => {
  return (
    <ActionModal
      title={title}
      onModalClose={onCancel}
      isOpen={isOpen}
      isLoading={isLoading}
      footer={
        <UnsavedChangesFooter
          onSave={onSave}
          onDiscardChanges={onDiscardChanges}
          onCancel={onCancel}
          disabled={isLoading}
        />
      }
      onConfirmClick={_.noop}
    >
      {message}
    </ActionModal>
  );
};

export default React.memo(UnsavedChangesDialog);
