import React from 'react';
import classNames from 'classnames';
import styles from './Heading.module.css';
import Tooltip from 'ecto-common/lib/Tooltip/Tooltip';

interface HeadingProps {
  /**
   * Used to override the appearance of the heading. Should be a valid CSS class name.
   */
  className?: string;
  /**
   * The size of the Heading. Valid values 1-6.
   */
  level?: number;
  /**
   * Header content.
   */
  children?: React.ReactNode;

  /**
   * Whether to add a top margin to the heading.
   */
  withMarginTop?: boolean;

  /**
   * Optional tooltip to display when hovering over the heading.
   */
  tooltip?: React.ReactNode;
}

const Heading = ({
  className = null,
  level = 1,
  children,
  withMarginTop = false,
  tooltip,
  ...otherProps
}: HeadingProps) => {
  let Element = null;

  switch (level) {
    case 1:
      Element = 'h1';
      break;
    case 2:
      Element = 'h2';
      break;
    case 3:
      Element = 'h3';
      break;
    case 4:
      Element = 'h4';
      break;
    case 5:
      Element = 'h5';
      break;
    case 6:
      Element = 'h6';
      break;
    default:
      Element = 'h1';
      break;
  }

  let content = children;

  if (tooltip != null) {
    content = (
      <Tooltip text={tooltip} multiline withIcon>
        {children}
      </Tooltip>
    );
  }

  return (
    // @ts-ignore-next-line
    <Element
      className={classNames(
        styles.heading,
        withMarginTop && styles.marginTop,
        styles[Element],
        className
      )}
      {...otherProps}
    >
      {content}
    </Element>
  );
};

export default React.memo(Heading);
