import React from 'react';
import { KeyValueLine } from 'ecto-common/lib/KeyValueInput/KeyValueLine';
import { ModelFormLineType } from 'ecto-common/lib/ModelForm/ModelPropType';
import styles from './ModelFormSection.module.css';
import _ from 'lodash';
import { ModelRenderCallbackFunction } from 'ecto-common/lib/ModelForm/ModelForm';

interface ModelFormSectionLinesProps<ObjectType extends object> {
  lines?: ModelFormLineType<ObjectType>[];
  renderChildModel?: ModelRenderCallbackFunction<ObjectType>;
}

const ModelFormSectionLines = <ObjectType extends object>({
  lines,
  renderChildModel
}: ModelFormSectionLinesProps<ObjectType>) => {
  const lineViews = lines.map((lineObject, idx) => {
    if (lineObject.lines != null && lineObject.lines.length > 0) {
      if (lineObject.models && lineObject.models.length > 0) {
        console.error(
          'You should specify either models or lines. Will only render these lines',
          lines
        );
      }

      return (
        <div className={styles.nestedSection} key={idx}>
          <ModelFormSectionLines
            key={idx}
            renderChildModel={renderChildModel}
            lines={lineObject.lines}
          />
        </div>
      );
    }

    const modelViews = lineObject.models.map((modelInfo) =>
      renderChildModel(modelInfo.key)
    );
    const hasElements = _.some(modelViews, (modelView) => modelView != null);

    if (!hasElements) {
      return null;
    }

    return (
      <KeyValueLine key={idx} centerVertically={lineObject.horizontalLabels}>
        {modelViews}
      </KeyValueLine>
    );
  });

  const hasElements = _.some(lineViews, (view) => view != null);

  if (!hasElements) {
    return null;
  }

  return <>{lineViews}</>;
};

export default ModelFormSectionLines;
