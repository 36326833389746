import React, { CSSProperties } from 'react';
import classNames from 'classnames';
import Heading from '../Heading/Heading';
import styles from './PlainBox.module.css';

type PlainBoxProps = React.HTMLProps<HTMLDivElement> & {
  /**
   * Used to override the appearance of the box. Should be a valid CSS class name.
   */
  className?: string;
  /**
   * Set this property to add a Heading to the Plainbox
   */
  header?: string;
  /**
   * The level of the header
   */
  headerLevel?: number;
  /**
   * Set this to use the PlainBox as a content container for the entire page. The box will expand to take the full width of the page.
   */
  isPageBox?: boolean;

  style?: CSSProperties;
};

/**
 * A PlainBox encapsulates content in a card like container.
 */
const PlainBox = React.forwardRef<HTMLDivElement, PlainBoxProps>(
  (
    {
      header = null,
      className = null,
      headerLevel = 4,
      children,
      isPageBox = false,
      style,
      ...otherProps
    }: PlainBoxProps,
    ref
  ) => {
    if (header) {
      return (
        <div
          ref={ref}
          className={classNames(
            styles.headerBox,
            isPageBox && styles.pageBox,
            className
          )}
          style={style}
          {...otherProps}
        >
          <Heading className={styles.heading} level={headerLevel}>
            {header}
          </Heading>

          <div className={classNames(styles.child)}>{children}</div>
        </div>
      );
    }

    return (
      <div
        className={classNames(
          styles.box,
          isPageBox && styles.pageBox,
          className
        )}
        style={style}
        ref={ref}
        {...otherProps}
      >
        {children}
      </div>
    );
  }
);

export default React.memo(PlainBox);
