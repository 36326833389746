export const comboViewOrder = [
  'PowerProductionView',
  'PowerConsumptionView',
  'EctocloudView'
];

export const comboViewMessageTypes = {
  DISCONNECT: 'role:combo:disconnect',
  CHANGE: 'role:combo:change',
  REQUEST: 'role:combo:request'
};

export function comboRoleScreenDisconnectMessage() {
  return [comboViewMessageTypes.DISCONNECT];
}

export function comboRoleScreenChangeMessage(comboScreen?: number | undefined) {
  const payload: any = {};

  if (comboScreen) payload.comboScreen = comboScreen;

  return [comboViewMessageTypes.CHANGE, payload];
}

export function comboRoleScreenRequestMessage() {
  return [comboViewMessageTypes.REQUEST];
}
