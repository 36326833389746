import { SET_SIGNAL_TYPES, PATCH_SIGNAL_TYPES } from './actionTypes';
import {
  AddOrUpdateSignalTypeRequestModel,
  SignalTypeResponseModel
} from 'ecto-common/lib/API/APIGen';

export function setSignalTypes(signalTypes: SignalTypeResponseModel[]) {
  return {
    type: SET_SIGNAL_TYPES,
    payload: signalTypes
  };
}

export function patchSignalTypes(signalTypes: {
  editedItems: AddOrUpdateSignalTypeRequestModel[];
  deletedItems: string[];
}) {
  return {
    type: PATCH_SIGNAL_TYPES,
    payload: signalTypes
  };
}
