/**
 * Helper class to handle scaling, in particular for recharts but also other
 * related components.
 *
 * This class is meant to be used statically only.
 */
class ScaleHelper {
  /**
   * @static
   * @param {Number} px Original (wished for) pixel size.
   * @return {Number} The calculated pixel size.
   */
  static scaleFromPx(px, size) {
    const scale = Math.max(size.width / 1920, size.height / 1080);

    return px * scale;
  }
}

export default ScaleHelper;
