import React, { useEffect, useMemo, useRef } from 'react';

import HighchartsReact from 'highcharts-react-official';
import { Highcharts } from 'ecto-common/lib/Highcharts/Highcharts';
import { yAxisFormatter } from 'ecto-common/lib/SignalSelector/ChartUtils';
import T from 'ecto-common/lib/lang/Language';
import { showChartStatus } from 'ecto-common/lib/Charts/ChartUtil';
import { SeriesOptionsType } from 'highcharts';

// Add draggable points module
/* eslint-disable @typescript-eslint/no-var-requires */

/**
 * Since we patch the Highcharts library to add support for 'crossing' we must
 * also provide types that have this property
 */
type HighchartsXAxisOptionsWithCrossing = Highcharts.XAxisOptions & {
  crossing?: number;
};

type HighchartsYAxisOptionsWithCrossing = Highcharts.XAxisOptions & {
  crossing?: number;
};

export type HighchartsOptionsWithCrossing = Omit<
  Highcharts.Options,
  'xAxis' | 'yAxis'
> & {
  xAxis?:
    | HighchartsXAxisOptionsWithCrossing
    | Array<HighchartsXAxisOptionsWithCrossing>;
  yAxis?:
    | HighchartsYAxisOptionsWithCrossing
    | Array<HighchartsYAxisOptionsWithCrossing>;
};

// Add center line at 'crossing' value
(function (H) {
  H.wrap(H.Axis.prototype, 'render', function (proceed) {
    if (this.options.crossing != null) {
      this.offset = this.chart[this.isXAxis ? 'yAxis' : 'xAxis'][0]?.toPixels(
        this.options.crossing,
        true
      );
    }
    proceed.call(this);
  });
})(Highcharts);

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const getOptions = (series: SeriesOptionsType[], options: any) => {
  const merge = Highcharts.merge;

  return {
    ...merge(
      {
        title: null,
        xAxis: {
          gridLineWidth: 1,
          tickInterval: 1,
          lineColor: 'black'
        },
        yAxis: {
          tickInterval: 1,
          lineWidth: 1,
          lineColor: 'black',
          title: {
            text: null
          },
          labels: {
            formatter: yAxisFormatter
          }
        },
        chart: {
          animation: false
        },
        credits: {
          enabled: false
        },
        legend: {
          enabled: false
        },
        dataGrouping: {
          enabled: false
        }
      },
      options
    ),
    series
  };
};

const LINECHART_CONTAINER_PROPS = {
  style: {
    height: '100%',
    width: '100%'
  }
};

interface LineChartProps {
  series: SeriesOptionsType[];
  domProps?: object;
  options?: HighchartsOptionsWithCrossing;
  containerWidth?: number;
  containerHeight?: number;
  isLoading?: boolean;
  hasPointsOverflow?: boolean;
  noSeriesText?: string;
  hasError?: boolean;
}

const LineChart = ({
  options,
  series,
  containerWidth,
  containerHeight,
  domProps = LINECHART_CONTAINER_PROPS,
  hasPointsOverflow = false,
  isLoading = false,
  hasError = false,
  noSeriesText = T.graphs.nosignalsfound
}: LineChartProps) => {
  const chartComponentRef = useRef(null);

  useEffect(() => {
    if (chartComponentRef.current != null) {
      chartComponentRef.current.chart.reflow();
    }
  }, [containerWidth, containerHeight]);

  const _options = useMemo(
    () => getOptions(series, options),
    [series, options]
  );

  // We use the loading text as a generic label mechanism for displaying information.
  useEffect(() => {
    if (chartComponentRef.current) {
      const chart = chartComponentRef.current.chart;

      showChartStatus({
        hasError,
        chart,
        hasPointsOverflow,
        isLoading,
        config: _options,
        noSeriesText
      });
    }
  }, [
    chartComponentRef,
    isLoading,
    hasError,
    _options,
    hasPointsOverflow,
    noSeriesText
  ]);

  return (
    <HighchartsReact
      options={_options}
      highcharts={Highcharts}
      containerProps={domProps}
      ref={chartComponentRef}
    />
  );
};

export default React.memo(LineChart);
