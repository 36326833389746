import { useEffect, useCallback, useRef } from 'react';

/**
 * @param callback
 * Function to call. No args passed.
 *
 * @param timeout
 * delay in ms
 */
const useTimeout = (callback: () => void, timeout = 0) => {
  const callbackRef = useRef(callback);
  const timerId = useRef(null);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    return () => {
      if (timerId.current) {
        clearTimeout(timerId.current);
        timerId.current = null;
      }
    };
  }, [timerId]);

  const start = useCallback(() => {
    if (timerId.current) {
      clearTimeout(timerId.current);
    }

    timerId.current = setTimeout(() => {
      if (callbackRef.current != null) {
        callbackRef.current();
      }
    }, timeout);
  }, [timeout]);

  const cancel = useCallback(() => {
    if (timerId.current) {
      clearTimeout(timerId.current);
      timerId.current = null;
    }
  }, []);

  return [start, cancel];
};

export default useTimeout;
