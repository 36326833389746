import React, {
  useState,
  useCallback,
  useRef,
  useMemo,
  KeyboardEventHandler
} from 'react';
import classNames from 'classnames';
import _ from 'lodash';

import styles from 'ecto-common/lib/TextInput/TextInput.module.css';
import {
  BaseTextInputProps,
  getInputPaddingStyle,
  TextInputIcon,
  textInputOverrideHeight,
  TextInputRightSideIcons,
  textInputWrapperClassnames,
  useTextInput
} from 'ecto-common/lib/TextInput/TextInput';

type MultilineTextInputProps = Omit<
  React.HTMLProps<HTMLTextAreaElement>,
  'onChange' | 'value'
> &
  Omit<
    BaseTextInputProps,
    | 'onChange'
    | 'type'
    | 'onEnterKeyPressed'
    | 'textFormatter'
    | 'onArrowUpKeyPressed'
    | 'onArrowDownKeyPressed'
  > & {
    onEnterKeyPressed?: KeyboardEventHandler<HTMLTextAreaElement>;
    onTabKeyPressed?: KeyboardEventHandler<HTMLTextAreaElement>;
    onArrowUpKeyPressed?: KeyboardEventHandler<HTMLTextAreaElement>;
    onArrowDownKeyPressed?: KeyboardEventHandler<HTMLTextAreaElement>;
    textFormatter?: (value: string, source?: HTMLTextAreaElement) => string;
    onChange?: (
      event: React.ChangeEvent<HTMLTextAreaElement>,
      newValue: string
    ) => void;
    value: string;
  };

/**

 */
const MultilineTextInput = React.forwardRef<
  HTMLTextAreaElement,
  MultilineTextInputProps
>(
  (
    {
      disabled = false,
      onChange = null,
      icon,
      rightSideIcon,
      onClickRightSideIcon,
      error = false,
      className,
      value: externalValue,
      wrapperClassName = null,
      clearButton = null,
      autoFocus = false,
      showFocusHighlight = true,
      onBlur = null,
      onFocus = null,
      onEnterKeyPressed = null,
      onTabKeyPressed = null,
      placeholder,
      onClear = null,
      onArrowUpKeyPressed = null,
      onArrowDownKeyPressed = null,
      textFormatter = null,
      isLoading = false,
      ...otherProps
    },
    ref
  ) => {
    const safeExternalValue = externalValue ?? '';
    const [value, setValue] = useState(
      textFormatter?.(safeExternalValue as string) ?? safeExternalValue
    );
    const [focus, setFocus] = useState(false);
    const inputRef = useRef<HTMLTextAreaElement>(null);

    const [_onChange, _onFocus, _onBlur, handleKeyDown, onClearClick] =
      useTextInput({
        externalValue,
        autoFocus,
        inputRef,
        textFormatter,
        onChange,
        onFocus,
        onBlur,
        setFocus,
        onEnterKeyPressed,
        onTabKeyPressed,
        onArrowUpKeyPressed,
        onArrowDownKeyPressed,
        setValue,
        onClear
      });

    // Remove default value
    const cleanProps = useMemo(
      () =>
        _.omit({ ...otherProps }, [
          'defaultValue',
          'triggerFocus',
          'autoFocus',
          'onEnterKeyPressed'
        ]),
      [otherProps]
    );
    const padStyle = getInputPaddingStyle(icon, rightSideIcon);

    const onFocusClick = useCallback(() => {
      _.defer(() => inputRef?.current?.focus?.());
    }, []);

    return (
      <div
        className={textInputWrapperClassnames({
          error,
          disabled,
          focus,
          showFocusHighlight,
          wrapperClassName
        })}
      >
        {icon && <TextInputIcon icon={icon} onFocusClick={onFocusClick} />}

        <textarea
          {...cleanProps}
          className={classNames(styles.input, padStyle, className)}
          style={cleanProps?.rows && textInputOverrideHeight}
          onChange={_onChange}
          onFocus={_onFocus}
          onBlur={_onBlur}
          value={value}
          disabled={disabled}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          ref={(node) => {
            inputRef.current = node;
            if (typeof ref === 'function') {
              ref(node);
            } else if (ref) {
              ref.current = node;
            }
          }}
          autoComplete="off"
        />

        <TextInputRightSideIcons
          rightSideIcon={rightSideIcon}
          onClickRightSideIcon={onClickRightSideIcon}
          onClearClick={onClearClick}
          isLoading={isLoading}
          disabled={disabled}
          clearButton={clearButton}
          value={value}
        />
      </div>
    );
  }
);

export default React.memo(MultilineTextInput);
