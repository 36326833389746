export function numDecimalsForUnit(unit: string) {
  if (unit == null) {
    return 2;
  }

  switch (unit) {
    case '°C':
      return 1;
    case '°F':
      return 1;
    case 'kWh':
      return 1;
    case 'kW':
      return 1;
    case 'binary':
      return 0;
    default:
      return 2;
  }
}
