import React from 'react';
import LastSignalValuesDataSource, {
  LastSignalValuesDataSourceProps,
  SignalInputType
} from 'ecto-common/lib/Dashboard/datasources/LastSignalValuesDataSource';
import SignalTimeSeriesModelEditor from 'ecto-common/lib/Dashboard/modelforms/SignalTimeSeriesModelEditor';
import SectionListPriority from 'ecto-common/lib/Dashboard/SectionListPriority';
import _ from 'lodash';
import { useContext, useMemo } from 'react';
import T from 'ecto-common/lib/lang/Language';
import DashboardDataContext from 'ecto-common/lib/hooks/DashboardDataContext';
import { isConstantSignal } from 'ecto-common/lib/utils/constants';
import { MatchSignalsForNodesResult } from 'ecto-common/lib/Dashboard/datasources/SignalValuesDataSource';
import { SignalValueType } from 'ecto-common/lib/hooks/useLatestSignalValues';
import {
  SignalTypeResponseModel,
  UnitResponseModel
} from 'ecto-common/lib/API/APIGen';
import {
  AdjustableChartCurve,
  AdjustableChartSeries
} from 'ecto-common/lib/AdjustableChart/AdjustableChart';
import {
  ModelDefinition,
  ModelFormSectionType
} from 'ecto-common/lib/ModelForm/ModelPropType';
import { EditDashboardPanelEnvironment } from 'ecto-common/lib/Dashboard/panels';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';

const nullIfEmpty = (obj: SignalInputType) => (_.isEmpty(obj) ? null : obj);
const seriesSignalsToArray = (item: SignalTimeSeriesDataInputSeries) => [
  nullIfEmpty(item.signalX),
  nullIfEmpty(item.signalY)
];
/**
 * @typedef {Object} CurveSeriesValues
 * @property {Signal} signalX
 * @property {Signal} signalY
 */

/**
 * @typedef {Object} Curve
 * @property {CurveSeriesValues[]} series
 */

/**
 * Remap signal values from server to signals in the curves series
 * @param {Curve[]} curves
 * @param {SignalInfo} signalInfo
 * @param {array} signalValues
 * @param {object} signalTypesMap
 * @param {object} signalUnitTypesMap
 * @returns {Curve[]}
 */
const mapDataToCurves = (
  curves: SignalTimeSeriesDataInputCurve[],
  signalInfo: MatchSignalsForNodesResult,
  signalValues: SignalValueType[],
  signalTypesMap: Record<string, SignalTypeResponseModel>,
  signalUnitTypesMap: Record<string, UnitResponseModel>
): AdjustableChartCurve[] => {
  // Keep track of signal order, same as the one we create in 'signals' sent to LastSignalValuesDataSource
  let matchIndex = 0;
  // Append value and time to each signal
  return _.map<SignalTimeSeriesDataInputCurve, AdjustableChartCurve>(
    curves,
    (curve) => {
      const newCurve: AdjustableChartCurve = {
        ..._.cloneDeep(curve),
        isWritableX: false,
        isWritableY: false
      };

      // Assign property 'name' a signal value and time stamp
      const updateValue = (
        series: AdjustableChartSeries,
        name: 'signalX' | 'signalY'
      ) => {
        if (series[name] != null && isConstantSignal(series[name])) {
          series[name].isWritable = false;
          matchIndex++;
          return true;
        } else if (series[name] != null && signalInfo.matchingSignals != null) {
          const match = _.find(signalInfo.matchingSignals, {
            signalInfo: { matchIndex }
          });
          const signalId = match?.signal.signalId;
          const signalProviderId = signalInfo.signalIdToProviderId[signalId];
          const signalProvider = signalInfo.signalProviders[signalProviderId];
          const signalValue = _.find(signalValues, { signalId });
          const unit =
            signalUnitTypesMap[
              signalTypesMap[match?.signal.signalTypeId]?.unitId
            ]?.unit;
          series[name] = {
            ...series[name],
            signalId,
            signalProvider,
            name: match?.signal.name,
            value: signalValue?.value,
            time: signalValue?.time,
            unit,
            isWritable: match?.signal.isWritable
          };
          matchIndex++;
          return true;
        }

        return false;
      };

      // Add value and time stamp to curve series
      newCurve.series = _.reduce(
        curve.series,
        (acc, oldSeries) => {
          const series: AdjustableChartSeries = { ...oldSeries };
          // push new series if both values are added
          if (
            updateValue(series, 'signalX') &&
            updateValue(series, 'signalY')
          ) {
            acc.push(series);
          }
          return acc;
        },
        []
      );

      // All signals must be writable in order to make the curve writable.
      newCurve.isWritableX = !_.some(newCurve.series, [
        'signalX.isWritable',
        false
      ]);
      newCurve.isWritableY = !_.some(newCurve.series, [
        'signalY.isWritable',
        false
      ]);
      return newCurve;
    }
  );
};

export type SignalTimeSeriesDataSourceResult = {
  curves: AdjustableChartCurve[];
  signalInfo: MatchSignalsForNodesResult;
};

type SignalTimeSeriesDataInputSeries = {
  signalX: SignalInputType;
  signalY: SignalInputType;
};

export type SignalTimeSeriesDataInputCurve = {
  color: string;
  name: string;
  series: SignalTimeSeriesDataInputSeries[];
};

type SignalTimeSeriesDataSourceProps = LastSignalValuesDataSourceProps & {
  curves: SignalTimeSeriesDataInputCurve[];
};

const SignalTimeSeriesDataSource = (
  props: SignalTimeSeriesDataSourceProps
): SignalTimeSeriesDataSourceResult => {
  const { signalUnitTypesMap, signalTypesMap } =
    useContext(DashboardDataContext);

  const signals = useMemo(() => {
    return _.compact(
      _.flatMap(props.curves, (curve) => {
        return _.flatMap(curve.series, seriesSignalsToArray);
      })
    );
  }, [props.curves]);

  const curveValueData = LastSignalValuesDataSource({ ...props, signals });

  return useMemo(() => {
    return {
      curves: mapDataToCurves(
        props.curves,
        curveValueData.signalInfo,
        curveValueData.signalValues,
        signalTypesMap,
        signalUnitTypesMap
      ),
      signalInfo: curveValueData.signalInfo
    };
  }, [
    props.curves,
    curveValueData.signalInfo,
    curveValueData.signalValues,
    signalTypesMap,
    signalUnitTypesMap
  ]);
};

type SignalTimeSeriesDataSourceArgs = {
  useConstantValues: boolean;
  maxItems: number;
  optionalSignalModels: ModelDefinition<SignalInputType, object>[];
};

export const signalTimeSeriesDataSourceSections: (
  args: SignalTimeSeriesDataSourceArgs
) => ModelFormSectionType<
  SignalTimeSeriesDataSourceProps,
  EditDashboardPanelEnvironment
>[] = ({
  useConstantValues,
  maxItems,
  optionalSignalModels
}: SignalTimeSeriesDataSourceArgs) => [
  {
    label: T.admin.dashboards.datasources.proptexts.signalcurves,
    lines: [
      {
        models: [
          {
            key: (input) => input.curves,
            modelType: ModelType.CUSTOM,
            render: (props) => (
              <SignalTimeSeriesModelEditor
                {...props}
                useConstantValues={useConstantValues}
                maxItems={maxItems}
                optionalSignalModels={optionalSignalModels}
              />
            ),
            label: T.admin.dashboards.datasources.proptexts.signalcurves,
            hasError: () => {
              return false;
            }
          }
        ]
      }
    ],
    listPriority: SectionListPriority.Signals // Should always be last since it looks odd otherwise
  }
];

export default SignalTimeSeriesDataSource;
