import {
  ProcessMapLineObject,
  ProcessMapObjectTypes,
  ProcessMapRectObject,
  ProcessMapSignalTextObject,
  ProcessMapSymbolObject,
  ProcessMapTextObject,
  lineRectHeight,
  processMapNewLineSize
} from 'ecto-common/lib/ProcessMap/ProcessMapViewConstants';
import _ from 'lodash';
import md5 from 'md5';
import UUID from 'uuidjs';
import { SymbolModelOrSystemItem } from 'ecto-common/lib/ProcessMaps/ProcessMapLibraryList';
import { ConnectionModel } from 'ecto-common/lib/API/PresentationAPIGen';

export const ProcessMapObjectFactory = {
  createSymbolObject(
    centerX: number,
    centerY: number,
    width: number,
    height: number,
    typeId: string,
    itemMd5: string,
    connections: ConnectionModel[],
    states: string[]
  ): ProcessMapSymbolObject {
    return {
      rects: [
        {
          centerX,
          centerY,
          width,
          height,
          id: UUID.generate()
        }
      ],
      typeId,
      id: UUID.generate(),
      type: ProcessMapObjectTypes.Symbol,
      svgMd5: itemMd5,
      states: _.cloneDeep(states),
      connections: _.cloneDeep(connections),
      symbolRules: [],
      originalWidth: width,
      originalHeight: height
    };
  },
  createRectObject(centerX: number, centerY: number): ProcessMapRectObject {
    return {
      type: ProcessMapObjectTypes.Rect,
      id: UUID.generate(),
      rects: [
        { centerX, centerY, width: 100, height: 100, id: UUID.generate() }
      ],
      strokeWidth: 1,
      strokeColor: '#222222',
      fillColor: '#dadada',
      cornerRadius: 0
    };
  },
  createSignalObject(
    centerX: number,
    centerY: number
  ): ProcessMapSignalTextObject {
    return {
      type: ProcessMapObjectTypes.Signal,
      id: UUID.generate(),
      rects: [
        { centerX, centerY, width: 180, height: 40, id: UUID.generate() }
      ],
      labelTextSettings: {},
      valueTextSettings: {}
    };
  },
  createTextObject(centerX: number, centerY: number): ProcessMapTextObject {
    return {
      type: ProcessMapObjectTypes.Text,
      id: UUID.generate(),
      rects: [
        { centerX, centerY, width: 180, height: 40, id: UUID.generate() }
      ],
      text: 'Sample text',
      textSettings: {}
    };
  },
  createLineObject(
    x0: number,
    y0: number,
    x1: number,
    y1: number
  ): ProcessMapLineObject {
    return {
      type: ProcessMapObjectTypes.Line,
      rects: [
        {
          centerX: x0,
          centerY: y0,
          width: lineRectHeight,
          height: lineRectHeight,
          id: UUID.generate()
        },
        {
          centerX: x1,
          centerY: y1,
          width: lineRectHeight,
          height: lineRectHeight,
          id: UUID.generate()
        }
      ],
      id: UUID.generate()
    };
  },
  createObjectFromLibraryItem: (
    libraryItem: SymbolModelOrSystemItem,
    centerX: number,
    centerY: number
  ) => {
    switch (libraryItem.type) {
      case ProcessMapObjectTypes.Symbol: {
        const item = libraryItem.item;
        const itemMd5 = md5(item.data);
        return ProcessMapObjectFactory.createSymbolObject(
          centerX,
          centerY,
          libraryItem.width,
          libraryItem.height,
          libraryItem.item.id,
          itemMd5,
          libraryItem.item.connections,
          libraryItem.item.states ?? []
        );
      }
      case ProcessMapObjectTypes.Line: {
        return ProcessMapObjectFactory.createLineObject(
          centerX,
          centerY,
          centerX + processMapNewLineSize,
          centerY
        );
      }
      case ProcessMapObjectTypes.Text: {
        return ProcessMapObjectFactory.createTextObject(centerX, centerY);
      }
      case ProcessMapObjectTypes.Signal: {
        return ProcessMapObjectFactory.createSignalObject(centerX, centerY);
      }
      case ProcessMapObjectTypes.Rect: {
        return ProcessMapObjectFactory.createRectObject(centerX, centerY);
      }
      default:
        throw new Error('Invalid object type');
    }
  }
};
