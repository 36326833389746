import PhysicalCluster from './physical_cluster';
import Renderable from './renderable';

import Util from './util';

import * as DataManager from './data/data_manager';

import Building from './Building';
import Settings from './Settings';

import EctogridBuildingDefinitions from './EctogridBuildingDefinitions';

import pointInPolygon from 'point-in-polygon';
import ImageButton from './image_button';
import removeIcon from '../assets/remove.svg';
import { TableContextType } from 'js/Table/context';
import p5 from 'p5';
import TableP5 from 'js/Table/TableP5';
import PhysicalNode from './physical_node';
import { drawNodeText } from './StructureDrawUtils';
import { EctogridBuilding } from 'js/Table/EctogridBuilding';
import ExternalEnergySource from './ExternalEnergySource';
import { EnergyGridSummary } from 'js/Table/energy_grid';
import { ExternalEnergySourceType } from 'js/Table/ExternalEnergySourceType';

export type EctogridBuildingInstance = EctogridBuilding & {
  // Added later in Medicon class, sort of a hack but do this to get on with TS migration
  name?: string;
  icon?: string;
  object: Building;
};

/**
 * The object created when the MV building is placed on the screen
 * In turn creating a number of Buildings
 * @class Medicon
 */
class EctogridCluster extends PhysicalCluster {
  context: TableContextType;
  name: string;
  rect: any;
  touchPolygon: [x: number, y: number][];
  translatedPolygon: [x: number, y: number][];
  closeButton: ImageButton;
  buildings: Record<string, EctogridBuildingInstance>;
  _debugTouch: {
    x: number;
    y: number;
  };
  p5Instance: TableP5;
  dataStructures: any[];
  icon: string;
  buildingsAsDots: boolean;
  dead: boolean;
  imagePath: string;
  medImg: p5.Image;
  relativeScale: number;
  infoOffset: [x: number, y: number];
  closeButtonCenterOffset: [x: number, y: number];
  externalEnergySource: ExternalEnergySource;
  superDraw: (p5Instance: TableP5) => void;

  constructor(
    context: TableContextType,
    p5Instance: TableP5,
    buildingTemplate: any,
    _parentPos,
    city: string,
    imageCache: Record<string, p5.Image>
  ) {
    super(null);

    this.validTUIOObject = true;
    this.tuioId = buildingTemplate.tuioId;
    this.externalEnergySource = null;
    this.context = context;

    const buildingDefinitions =
      EctogridBuildingDefinitions[city] ?? EctogridBuildingDefinitions.Lund;
    this.name = buildingDefinitions.name;
    this.relativeScale = buildingDefinitions.scale;
    this.infoOffset = buildingDefinitions.infoOffset;
    this.closeButtonCenterOffset = buildingDefinitions.closeButtonCenterOffset;

    Renderable.implement(this, context);

    this.imagePath = buildingDefinitions.image;
    this.medImg = imageCache[this.imagePath];
    this.rect = {
      x: 0,
      y: 0,
      w: 0,
      h: 0
    };

    this.touchPolygon = buildingDefinitions.touchPolygon;

    this.closeButton = new ImageButton(removeIcon, null);
    this.closeButton.setDefault({
      x: 500,
      y: 500,
      width: 50,
      height: 50
    });

    this.closeButton.addListener(() => {
      if (this.active) {
        p5Instance.App?._removeTouchStructure(this);
      }
    });

    this.buttons.buttons = [this.closeButton];
    this.buttons.renderInit(p5Instance, imageCache);
    this.active = false;
    this.translatedPolygon = new Array(this.touchPolygon.length);
    for (let i = 0; i < this.touchPolygon.length; i++) {
      this.translatedPolygon[i] = [...this.touchPolygon[i]];
    }

    this.buildings = Util.objectCopy(buildingDefinitions.buildings);
    this.p5Instance = p5Instance;
    this.dataStructures = [];
    this.icon = buildingTemplate.icon;
    this.buildingId = buildingTemplate.buildingId;

    this.buildingsAsDots = false;
    if (this.context.model === 'surface' || this.context.model === 'ipad') {
      this.buildingsAsDots = true;
    }
    this.buildingsAsDots = false;

    Object.keys(this.buildings).forEach((buildingName) => {
      const building = this.buildings[buildingName];

      building.name = buildingName;
      building.icon = this.icon;
      const newBuilding = new Building(
        context,
        p5Instance,
        building,
        this.centerPos,
        city,
        this.relativeScale,
        imageCache
      );

      this.addNode(newBuilding);
      building.object = newBuilding;
    });

    if (buildingDefinitions.externalEnergySource) {
      this.addExternalEnergySource(
        buildingDefinitions.externalEnergySource.type,
        buildingDefinitions.externalEnergySource.x,
        buildingDefinitions.externalEnergySource.y
      );
    }
    this.recombineData();

    this.superDraw = this.draw;
    this.draw = this.overRideDraw;
  }

  addExternalEnergySource(
    type: ExternalEnergySourceType,
    x: number,
    y: number,
    imageCache: Record<string, p5.Image> = {}
  ) {
    if (this.externalEnergySource != null) {
      this.externalEnergySource.resetConnections();
      this.externalEnergySource.destructor();
      this.removeNode(this.externalEnergySource);
      this.externalEnergySource = null;
      return;
    }

    this.externalEnergySource = new ExternalEnergySource(
      this.context,
      this.p5Instance,
      type,
      x,
      y,
      imageCache
    );

    this.addNode(this.externalEnergySource);
  }

  recombineData() {
    const { heating, cooling } = DataManager.combineNeedsForSeveral(
      this.getAllChildren()
    );

    DataManager.pushDataForStructure(this.buildingId, heating, cooling);

    this.touch();

    this.setupInternalConnections();
  }

  destructor() {
    this.dead = true;

    const numberOfBuildings = Object.keys(this.buildings).length;

    for (let i = 0; i < numberOfBuildings; i++) {
      let building = Object.values(this.buildings)[i];

      const object = building.object;

      object.resetConnections();
    }
  }

  /**
   * @throws {String} Message about dead object
   */
  getHeatingNeedMW() {
    if (this.dead) throw 'Trying to fetch heat value from dead Ectogrid';

    return Util.arraySummariseOverItems(
      this.getAllChildren(),
      'getHeatingNeedMW'
    );
  }

  /**
   * @throws {String} Message about dead object
   */
  getCoolingNeedMW() {
    if (this.dead) throw 'Trying to fetch cool value from dead Ectogrid';

    return Util.arraySummariseOverItems(
      this.getAllChildren(),
      'getCoolingNeedMW'
    );
  }

  /**
   * @throws {String} Message about dead object
   */
  getExternalGridHeatingNeedMW() {
    if (this.dead) throw 'Trying to fetch heat value from dead Ectogrid';

    return Util.arraySummariseOverItems(
      this.getAllChildren(),
      'getExternalGridHeatingNeedMW'
    );
  }

  /**
   * @throws {String} Message about dead object
   */
  getExternalGridCoolingNeedMW() {
    if (this.dead) throw 'Trying to fetch cool value from dead Ectogrid';

    return Util.arraySummariseOverItems(
      this.getAllChildren(),
      'getExternalGridCoolingNeedMW'
    );
  }

  getSize() {
    return 1;
  }

  touch() {}

  dataIsDirtySince() {
    return false;
  }

  center() {
    return this.centerPos;
  }

  offsetCenter(posCenter: { x: number; y: number }) {
    const newCenter: { x: number; y: number } = {
      x: posCenter.x,
      y: posCenter.y
    };

    if (this.buildingsAsDots) {
      newCenter.x += 190;
      newCenter.y += 30;
    }

    return new p5.Vector(newCenter.x, newCenter.y);
  }

  touchTriangleCenterUpdated(posCenter) {
    this.touch();

    this.centerPos = this.offsetCenter(posCenter);
    Object.values(this.buildings).forEach((building) => {
      building.object.parentMovedTo(this.centerPos);
    });

    this.externalEnergySource?.parentMovedTo(this.centerPos);
  }

  layout(p5Instance: TableP5): void {
    super.layout(p5Instance);
    this.externalEnergySource?.parentMovedTo(this.centerPos);
  }

  paint() {}

  _drawText(p, placement) {
    const center = this.center();

    p.push();
    {
      p.fill(Settings.structureFontColor);
      p.textFont(Settings.structureFont);
      p.textSize(Settings.structureHeaderFontSize);
      p.textAlign(p.LEFT, p.TOP);

      const textPos = {
        x: center.x + placement.x,
        y: center.y + placement.y
      };

      let totalElectricityNeedMW = 0;
      let totalHeatingNeed = 0;
      let totalCoolingNeed = 0;

      Object.values(this.buildings).forEach((building) => {
        totalHeatingNeed += building.object.node.getHeatingNeedMW();
        totalCoolingNeed += building.object.node.getCoolingNeedMW();
        totalElectricityNeedMW +=
          building.object.node.heatingElectricityCostMW +
          building.object.node.coolingElectricityCostMW;
      });

      drawNodeText(
        p,
        textPos,
        this.name,
        totalHeatingNeed,
        totalCoolingNeed,
        totalElectricityNeedMW,
        1.0,
        0.0,
        0.0,
        null
      );
    }
    p.pop();
  }

  hit(
    x: number,
    y: number,
    minDistance: number
  ): [node: PhysicalNode, x: number, y: number] {
    const all = this.getAllChildren();

    for (let structureIndex in all) {
      const structure = all[structureIndex];
      const hit = structure.hit(x, y, minDistance);

      if (hit) {
        return hit;
      }
    }

    if (pointInPolygon([x, y], this.translatedPolygon)) {
      const centerPos = this.center();
      return [this, centerPos.x - x, centerPos.y - y];
    }

    return undefined;
  }

  shapeRadius() {
    if (this.medImg !== undefined) {
      let w = this.medImg.width;
      let h = this.medImg.height;
      w *= this.relativeScale;
      h *= this.relativeScale;
      w /= 2;
      h /= 2;

      return Math.sqrt(w * w + h * h);
    }

    return 0;
  }

  setSummary(summary: EnergyGridSummary) {
    this.externalEnergySource?.setSummary(summary);
  }

  overRideDraw(p5Instance: TableP5) {
    this.closeButton.visible = this.active;

    if (this.buildingsAsDots) {
      this._drawText(p5Instance, { x: -120, y: -200 });
    } else {
      if (this.medImg !== undefined) {
        let w = this.medImg.width;
        let h = this.medImg.height;

        // The centerpoint on the image that we have calculated the buildings in MV from - on a 1000x500 image
        let offsetX = 435;
        let offsetY = 335;

        // The base size for the MV image is too small for scale 1
        w *= this.relativeScale;
        h *= this.relativeScale;
        offsetX *= this.relativeScale;
        offsetY *= this.relativeScale;

        const center = this.center();
        this.rect.x = center.x - offsetX;
        this.rect.y = center.y - offsetY;
        this.rect.w = w;
        this.rect.h = h;
        const rect = this.closeButton.defaultRect;
        this.closeButton.setDefault({
          ...rect,
          x: center.x + this.closeButtonCenterOffset[0],
          y: center.y + this.closeButtonCenterOffset[1]
        });

        for (let i = 0; i < this.touchPolygon.length; i++) {
          const p = this.touchPolygon[i];
          this.translatedPolygon[i] = [this.rect.x + p[0], this.rect.y + p[1]];
        }

        if (this.active) {
          p5Instance.fill(255, 255, 255, 30);
          p5Instance.beginShape();

          for (let i = 0; i < this.translatedPolygon.length; i++) {
            let p = this.translatedPolygon[i];
            p5Instance.vertex(p[0], p[1]);
          }

          p5Instance.endShape(p5Instance.CLOSE);
          this.buttons.draw(p5Instance);
        }

        p5Instance.image(
          this.medImg,
          this.rect.x,
          this.rect.y,
          this.rect.w,
          this.rect.h
        );
      }

      this._drawText(p5Instance, {
        x: this.infoOffset[0],
        y: this.infoOffset[1]
      });
    }

    this.superDraw(p5Instance);
  }

  setupInternalConnections() {
    const numberOfBuildings = Object.keys(this.buildings).length;

    for (let i = 0; i < numberOfBuildings; i++) {
      let tmpParent = Object.values(this.buildings)[i];
      let tempChildName = tmpParent.connectTo;

      if (tempChildName !== undefined) {
        //this is dangerous, need a faulty check to avoid infinite loops
        let tmpChild = this.buildings[tempChildName];

        if (tmpChild === undefined) {
          console.warn('Child Building ', tempChildName, "doesn't exist!");
        } else {
          let tmpChildNew = this.buildings[tempChildName].object;

          tmpParent.object.preparePersistentConnection(tmpChildNew);
        }
      }
    }
  }
}

export default EctogridCluster;
