/* eslint no-lone-blocks: 0 */ // --> OFF

import Settings from './Settings';
import p5 from 'p5';
import TableP5 from './TableP5';

const distanceBetweenComets = 10;
const cometSpeed = 40;

/**
 * An concrete pipe to draw
 * @class Pipe
 */
class Pipe {
  p5Instance: TableP5;
  start: p5.Vector;
  end: p5.Vector;
  baseColor: string;
  progress = 0;
  /*
   * @params
   * p5Instance - p5Instance
   * start - a p5 Vector describing the Start position
   * end - a p5 Vector describing the End position
   * baseColor - an optional color for the pipe
   */
  constructor(
    p5Instance: TableP5,
    start: p5.Vector,
    end: p5.Vector,
    baseColor: string
  ) {
    this.init(p5Instance, start, end, baseColor);
  }

  init(
    p5Instance: TableP5,
    start: p5.Vector,
    end: p5.Vector,
    baseColor: string
  ) {
    this.p5Instance = p5Instance;

    this.start = start;
    this.end = end;
    this.baseColor = baseColor ? baseColor : 'rgb(255,0,0)';
  }

  draw(reverse = false, magnitude: number) {
    const p = this.p5Instance;

    // Keep the magnitude from going too far, will just result in a solid color
    magnitude = Math.min(0.2, Math.max(-0.2, magnitude));

    this.drawPipe();

    // Draw the "comets" with the particles moving in the pipes
    let end = this.end;
    let start = this.start;

    if (reverse) {
      start = this.end;
      end = this.start;
    }

    const totalTravelDistance = start.dist(end);
    const nbrOfComets = Math.ceil(
      (totalTravelDistance / distanceBetweenComets) * magnitude * 2
    );

    const localSpeed = cometSpeed * magnitude * 2;

    const traveldistancePerComet = totalTravelDistance / nbrOfComets;

    this.progress = (this.progress + localSpeed * 0.01) % 1;
    p.push();
    p.noStroke();
    p.fill(this.baseColor);

    for (let i = 0; i < nbrOfComets + 1; i++) {
      this.comet(
        start,
        end,
        i,
        nbrOfComets,
        traveldistancePerComet,
        this.progress
      );
    }

    p.pop();
  }

  /*
   * @params
   * start - a p5 Vector describing the Start position
   * end - a p5 Vector describing the End position
   * progress - a ratio 0..1 descrigin where in the animation we are
   */
  comet(
    start: p5.Vector,
    end: p5.Vector,
    index: number,
    nbrOfComets: number,
    travelDistance: number,
    progress: number
  ) {
    const p = this.p5Instance;

    // The range of the overall pipe that this comet spans over 0..1
    const startRatio = index / nbrOfComets;

    // Where along the the pipe the center part of the comet is this frame 0..1
    const progressRatio = progress / nbrOfComets + startRatio;

    // The 2D position of the center point for this comet
    let particlePos = start.copy().lerp(end, progressRatio);

    // angle is the noise value for the distortion of the ellipse placement, based on the previous position
    const particleOffset =
      (Settings.pipeRelativeScale * 3 * 0.5 * 1) / travelDistance;
    const localRatio = progress - particleOffset; // How far along this comet's length is this particle 0..1
    const globalRatio = localRatio / nbrOfComets + startRatio; // How far along the whole pipe length is this particle 0..1

    // If the particle falls outside the 0..1 range cap it to avoid the overshoots
    if (globalRatio > 0 && globalRatio < 1) {
      particlePos = start.copy().lerp(end, globalRatio);

      const maxSize = 1.75 * Settings.pipeRelativeScale;
      const size = maxSize;

      p.ellipse(particlePos.x, particlePos.y, size);
    }
  }

  drawPipe() {
    const pipeWidth = Settings.pipeRelativeScale * 0.2;

    const p = this.p5Instance;

    p.push();
    p.strokeCap(p.SQUARE);
    p.stroke(this.baseColor);
    p.strokeWeight(pipeWidth);
    p.line(this.start.x, this.start.y, this.end.x, this.end.y);
    p.pop();
  }
}

export default Pipe;

function getEndpoints(
  Vector: any,
  intersectRadiusStart: number,
  intersectRadiusEnd: number,
  startingPoint: p5.Vector,
  endPoint: p5.Vector,
  offsetVectorStartUnitAngle: number,
  offsetVectorEndUnitAngle: number
) {
  const offsetVectorStartUnit = Vector.fromAngle(offsetVectorStartUnitAngle);
  const offsetVectorEndUnit = Vector.fromAngle(offsetVectorEndUnitAngle);

  const offsetVectorStart = Vector.mult(
    offsetVectorStartUnit,
    intersectRadiusStart
  );
  const offsetVectorEnd = Vector.mult(offsetVectorEndUnit, intersectRadiusEnd);

  const pipeStartPoint = Vector.add(startingPoint, offsetVectorStart);
  const pipeEndPoint = Vector.sub(endPoint, offsetVectorEnd);

  return [pipeStartPoint, pipeEndPoint];
}

export function calculateWarmAndColdPipePoints(
  startingPoint: p5.Vector,
  endPoint: p5.Vector,
  intersectRadiusStart: number,
  intersectRadiusEnd: number,
  Vector
) {
  const nominalVector = Vector.sub(endPoint, startingPoint);
  const nominalAngle = Math.atan2(nominalVector.y, nominalVector.x);

  const offsetDistance = Settings.pipeDistance;

  const offsetAngleStartSin = offsetDistance / intersectRadiusStart;
  const offsetAngleEndSin = offsetDistance / intersectRadiusEnd;

  const [warmStartPoint, warmEndPoint] = getEndpoints(
    Vector,
    intersectRadiusStart,
    intersectRadiusEnd,
    startingPoint,
    endPoint,
    nominalAngle - Math.asin(offsetAngleStartSin),
    nominalAngle + Math.asin(offsetAngleEndSin)
  );
  const [coldStartPoint, coldEndPoint] = getEndpoints(
    Vector,
    intersectRadiusStart,
    intersectRadiusEnd,
    startingPoint,
    endPoint,
    nominalAngle + Math.asin(offsetAngleStartSin),
    nominalAngle - Math.asin(offsetAngleEndSin)
  );

  return [warmStartPoint, warmEndPoint, coldStartPoint, coldEndPoint];
}

export function calculateElectricityPipePoints(
  startingPoint: p5.Vector,
  endPoint: p5.Vector,
  intersectRadiusStart: number,
  intersectRadiusEnd: number,
  Vector
) {
  const nominalVector = Vector.sub(endPoint, startingPoint);
  const nominalAngle = Math.atan2(nominalVector.y, nominalVector.x);

  const [electricityStartPoint, electricityEndPoint] = getEndpoints(
    Vector,
    intersectRadiusStart,
    intersectRadiusEnd,
    startingPoint,
    endPoint,
    nominalAngle,
    nominalAngle
  );

  return [electricityStartPoint, electricityEndPoint];
}
