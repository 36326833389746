import React from 'react';
import { ASC, SortDirectionType } from './SortDirection';
import styles from './SortIndicator.module.css';

interface SortIndicatorProps {
  sortDirection?: SortDirectionType;
}

// Displayed beside a column header to indicate that a
// Table is currently sorted by this column.

export default function SortIndicator({ sortDirection }: SortIndicatorProps) {
  return (
    <svg
      width={18}
      height={18}
      viewBox="0 0 24 24"
      className={styles.sortIndicator}
    >
      {sortDirection === ASC ? (
        <path d="M7 14l5-5 5 5z" />
      ) : (
        <path d="M7 10l5 5 5-5z" />
      )}

      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  );
}
