import React from 'react';
import styles from './ModelHelpText.module.css';
import classNames from 'classnames';

interface ModelHelpTextProps {
  children: React.ReactNode;
  className?: string;
}

const ModelHelpText = ({ children, className }: ModelHelpTextProps) => {
  return (
    <div className={classNames(styles.helpText, className)}>{children}</div>
  );
};

export default React.memo(ModelHelpText);
