import { useMemo } from 'react';
import _ from 'lodash';

const useSearchResult = <ItemType>(
  array: ItemType[],
  property: keyof ItemType,
  searchString: string
) => {
  return useMemo(() => {
    const adjustString = (str: string) =>
      _.toLower(_.trim(str)).normalize('NFC');
    const trimmedSearchString = adjustString(searchString);
    if (_.isEmpty(trimmedSearchString)) {
      return array;
    }

    return _.filter(
      array,
      (item) =>
        adjustString(_.get(item, property, '')).indexOf(trimmedSearchString) !==
        -1
    );
  }, [searchString, property, array]);
};
export default useSearchResult;
