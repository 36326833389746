/**
 * Helper component for drawing SVG.
 */

import React from 'react';
import _ from 'lodash';

function styleSvg(style, sourceProps) {
  if (style === undefined) style = {};
  if (sourceProps === undefined) return style;

  if (sourceProps.fill) {
    style.fill = sourceProps.fill.color;
    style.fillOpacity = sourceProps.fill.alpha
      ? sourceProps.fill.alpha / 100
      : 1;
  }
  if (sourceProps.stroke) {
    style.stroke = sourceProps.stroke.color;
    style.strokeOpacity = sourceProps.stroke.alpha
      ? sourceProps.stroke.alpha / 100
      : 1;
  }
  if (sourceProps.strokeWidth) style.strokeWidth = sourceProps.strokeWidth;
  return style;
}

type SVGComponentProps = {
  children: React.ReactNode;
  height: number;
  width: number;
};

export class SVGComponent extends React.Component<SVGComponentProps> {
  render() {
    return <svg {...this.props}>{this.props.children}</svg>;
  }
}

type CircleProps = {
  r: number;
  strokeWidth?: number;
  children?: React.ReactNode;
  fill?: any;
};

export class Circle extends React.Component<CircleProps> {
  render() {
    let strokeWidth = this.props.strokeWidth || 0;
    let r = this.props.r || 0;

    let height = r * 2 + 2 * strokeWidth;
    let width = r * 2 + 2 * strokeWidth;

    let cx = r + strokeWidth / 2;
    let cy = r + strokeWidth / 2;
    let props = styleSvg(_.omit(this.props, 'style'), this.props);

    return (
      <SVGComponent height={height} width={width}>
        <circle {...props} cx={cx} cy={cy}>
          {this.props.children}
        </circle>
      </SVGComponent>
    );
  }
}

let sharedFields = {
  fill: { type: 'colorPicker' },
  stroke: { type: 'colorPicker' },
  strokeWidth: { type: 'number' }
};
let sharedShapeMetaData = {
  defaultColors: {
    fill: { color: '#2409ba' },
    stroke: { color: '#E65243' },
    strokeWidth: 20
  }
};

export default {
  SVGComponent: SVGComponent,
  Circle: _.assignIn(Circle, {
    metaData: {
      props: _.assignIn(
        {
          r: 200
        },
        sharedShapeMetaData.defaultColors
      ),
      settings: {
        fields: _.assignIn(
          {
            r: { type: 'number' }
          },
          sharedFields
        )
      }
    }
  })
};
