import React, { useState, useMemo } from 'react';
import _ from 'lodash';

import styles from 'ecto-common/lib/SignalSelector/SignalSelectorGeneric.module.css';
import { ROOT_NODE_ID } from 'ecto-common/lib/constants';
import T from 'ecto-common/lib/lang/Language';
import Icons from 'ecto-common/lib/Icons/Icons';
import { getNodeOrEquipment } from 'ecto-common/lib/SignalSelector/SignalUtils';
import { LoadingTable } from 'ecto-common/lib/SignalSelector/LoadingTable';
import { SignalSelectorTable } from 'ecto-common/lib/SignalSelector/SignalSelectorTable';
import TextInput from 'ecto-common/lib/TextInput/TextInput';
import { DEFAULT_SEARCH_DEBOUNCE_TIME_MS } from 'ecto-common/lib/utils/constants';
import { ChartSignal } from 'ecto-common/lib/SignalSelector/ChartUtils';
import {
  EquipmentResponseModel,
  FullSignalProviderResponseModel,
  SignalProviderSignalResponseModel
} from 'ecto-common/lib/API/APIGen';
import { SingleGridNode } from 'ecto-common/lib/types/EctoCommonTypes';
import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';

const _hasSignal = (
  array: ChartSignal[],
  signal: SignalProviderSignalResponseModel,
  provider: FullSignalProviderResponseModel
) => {
  return _.some(
    array,
    (value) =>
      value.group.signalProviderId === provider.signalProviderId &&
      value.item.signalId === signal.signalId
  );
};

interface SignalProvidersProps {
  selectedId?: string;
  selectedSignals: ChartSignal[];
  nodeMap?: Record<string, SingleGridNode>;
  equipmentMap?: Record<string, EquipmentResponseModel>;
  signalProviders?: FullSignalProviderResponseModel[];
  isLoading?: boolean;
  hasError?: boolean;
  addNewSignal?(
    signal: SignalProviderSignalResponseModel,
    provider: FullSignalProviderResponseModel
  ): void;
  showSignalType?: boolean;
  hasSignal?(
    signals: ChartSignal[],
    signal: SignalProviderSignalResponseModel,
    provider: FullSignalProviderResponseModel
  ): boolean;
  validSignalTypeIds?: string[];
}

const SignalProviders = ({
  selectedId,
  selectedSignals,
  nodeMap,
  equipmentMap,
  signalProviders,
  isLoading,
  hasError,
  addNewSignal,
  showSignalType,
  hasSignal,
  validSignalTypeIds = null
}: SignalProvidersProps) => {
  const node =
    selectedId == null
      ? null
      : getNodeOrEquipment(selectedId, nodeMap, equipmentMap);

  const signalTypesMap = useCommonSelector(
    (state) => state.general.signalTypesMap
  );

  const loadingTableProgress = useMemo(
    () => <LoadingTable name={node?.name} loadingText={T.common.loading} />,
    [node?.name]
  );

  const [filterText, setFilterText] = useState('');
  const updateSearch = useMemo(
    () =>
      _.debounce(
        (e) => setFilterText(e?.target?.value ?? ''),
        DEFAULT_SEARCH_DEBOUNCE_TIME_MS
      ),
    []
  );

  const filteredSignalProviders = useMemo(() => {
    if (filterText.length || validSignalTypeIds != null) {
      const filteredProviders: FullSignalProviderResponseModel[] = [];
      const filterTextLower = filterText.toLowerCase();

      _.map(signalProviders, (provider) => {
        const newProvider = { ...provider };

        if (provider?.signals) {
          const filteredSignals = _.filter(provider.signals, (signal) => {
            let includeSignal = true;

            if (validSignalTypeIds != null) {
              includeSignal = validSignalTypeIds.includes(signal.signalTypeId);
            }

            if (filterText.length && includeSignal) {
              const signalType = signalTypesMap?.[signal.signalTypeId];

              includeSignal =
                _.includes(signal.name.toLowerCase(), filterTextLower) ||
                _.includes(
                  (signalType?.name ?? '').toLowerCase(),
                  filterTextLower
                ) ||
                _.includes(
                  (signalType?.description ?? '').toLowerCase(),
                  filterTextLower
                );
            }

            return includeSignal;
          });

          if (filteredSignals.length) {
            newProvider.signals = filteredSignals;
            filteredProviders.push(newProvider);
          }
        }
      });

      return filteredProviders;
    }

    return signalProviders;
  }, [filterText, validSignalTypeIds, signalProviders, signalTypesMap]);

  if (!selectedId || selectedId.startsWith(ROOT_NODE_ID)) {
    return <div />;
  }

  const isEquipment = (node as EquipmentResponseModel)?.equipmentId != null;

  if (!isLoading && hasError) {
    return (
      <div className={styles.failedToLoadDiv}>
        {T.graphs.failedtoloadsignals}
      </div>
    );
  }

  return (
    <>
      <div className={styles.filterBox}>
        <TextInput
          icon={<Icons.Search />}
          placeholder={T.common.search.placeholder}
          size={50}
          value={filterText}
          onChange={updateSearch}
          clearButton
        />
      </div>

      {isLoading ? (
        loadingTableProgress
      ) : (
        <SignalSelectorTable
          node={node}
          isEquipment={isEquipment}
          selectedSignals={selectedSignals}
          signalProviders={filteredSignalProviders}
          addNewSignal={addNewSignal}
          hasSignal={hasSignal ?? _hasSignal}
          showSignalType={showSignalType}
          validSignalTypeIds={validSignalTypeIds}
        />
      )}
    </>
  );
};

export default SignalProviders;
