import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ModelFormSection from 'ecto-common/lib/ModelForm/ModelFormSection';
import { KeyValueSelectableInput } from 'ecto-common/lib/KeyValueInput/KeyValueSelectableInput';
import { ModelFormSectionType } from 'ecto-common/lib/ModelForm/ModelPropType';
import styles from './ModelFormSelectControl.module.css';
import _ from 'lodash';
import classNames from 'classnames';
import { GenericSelectOption } from 'ecto-common/lib/Select/Select';
import { ModelRenderCallbackFunction } from './ModelForm';

interface ModelFormSelectControlProps<ObjectType extends object> {
  sections?: ModelFormSectionType<ObjectType>[];
  renderChildModel?: ModelRenderCallbackFunction<ObjectType>;
  sectionsTitle?: React.ReactNode;
  sectionClassName?: string;
  input: ObjectType;
}

const ModelFormSelectControl = <ObjectType extends object>({
  sections,
  renderChildModel,
  sectionsTitle,
  sectionClassName,
  input
}: ModelFormSelectControlProps<ObjectType>) => {
  const options: GenericSelectOption<number>[] = useMemo(() => {
    return sections.map((section, idx) => ({
      label: (
        <div className={classNames(section.hasError && styles.error)}>
          {section.label}
        </div>
      ),
      value: idx
    }));
  }, [sections]);

  const [curOptionIndex, setCurOptionIndex] = useState(0);

  const setCurOption = useCallback(
    (newOption: GenericSelectOption<number>) => {
      setCurOptionIndex(_.indexOf(options, newOption));
    },
    [options]
  );

  useEffect(() => {
    setCurOptionIndex((oldIndex) => {
      return oldIndex >= options.length ? 0 : oldIndex;
    });
  }, [options]);

  return (
    <>
      <KeyValueSelectableInput
        keyText={sectionsTitle}
        options={options}
        onChange={setCurOption}
        value={options[curOptionIndex]}
      />
      <div className={styles.divider} />
      <ModelFormSection
        renderChildModel={renderChildModel}
        section={sections[options[curOptionIndex].value]}
        className={sectionClassName}
        hideLabel
        input={input}
      />
    </>
  );
};

export default ModelFormSelectControl;
