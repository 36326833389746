import React from 'react';
import ReactMarkdown from 'react-markdown';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import styles from './markdown.module.css';

interface MarkdownModalProps {
  title?: string;
  isOpen: boolean;
  onConfirmClick(): void;
  content?: string;
}

const MarkdownModal = ({
  title,
  isOpen,
  onConfirmClick,
  content
}: MarkdownModalProps) => {
  return (
    <ActionModal
      isOpen={isOpen}
      onConfirmClick={onConfirmClick}
      onModalClose={onConfirmClick}
      title={title}
      disableCancel
    >
      <ReactMarkdown className={styles.markdown}>{content}</ReactMarkdown>
    </ActionModal>
  );
};

export default React.memo(MarkdownModal);
