import React, { useCallback, useState, useMemo, useEffect } from 'react';
import _ from 'lodash';
import Icons from 'ecto-common/lib/Icons/Icons';
import { KeyValueColumn } from 'ecto-common/lib/KeyValueInput/KeyValueColumn';
import { KeyValueInput } from 'ecto-common/lib/KeyValueInput/KeyValueInput';
import KeyValueNumberInput from 'ecto-common/lib/KeyValueInput/KeyValueNumberInput';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import { getSignalTypeUnit } from 'ecto-common/lib/SignalSelector/SignalUtils';
import T from 'ecto-common/lib/lang/Language';
import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';
import { parseLocaleNumber, Unit } from 'ecto-common/lib/utils/stringUtils';
import {
  KeyValueGeneric,
  KeyValueGenericProps
} from 'ecto-common/lib/KeyValueInput/KeyValueGeneric';
import Switch from 'ecto-common/lib/Switch/Switch';

type KeyValueSwitchInputProps = Omit<KeyValueGenericProps, 'children'> & {
  value: number;
  onChange?: React.MouseEventHandler<HTMLDivElement>;
};

export const KeyValueSwitchInput = ({
  onChange = null,
  value,
  ...props
}: KeyValueSwitchInputProps) => {
  return (
    <KeyValueGeneric {...props}>
      <Switch isOn={value === 1.0} onClick={onChange} />
    </KeyValueGeneric>
  );
};

interface SignalEditType {
  signalId: string;
  name?: string;
  signalTypeId: string;
}

export interface SignalValueEditModalProps {
  signal?: SignalEditType;
  currentValue: string;
  currentRawValue: number;
  isLoading: boolean;
  isOpen: boolean;
  onCancel(): void;
  onConfirm(
    signal: SignalEditType,
    value: number,
    oldValue: number,
    message: string
  ): void;
}

const SignalValueEditModal = ({
  signal,
  currentValue,
  currentRawValue,
  isLoading,
  isOpen,
  onCancel,
  onConfirm
}: SignalValueEditModalProps) => {
  const title = useMemo(
    () => T.format(T.equipment.setvalueformat, signal?.name ?? ''),
    [signal?.name]
  );
  const signalTypesMap = useCommonSelector(
    (state) => state.general.signalTypesMap
  );
  const signalUnitTypesMap = useCommonSelector(
    (state) => state.general.signalUnitTypesMap
  );

  const [newValue, setNewValue] = useState('');
  const [message, setMessage] = useState('');
  const [newBinaryValue, setNewBinaryValue] = useState(0);

  useEffect(() => {
    if (!isOpen) {
      setNewValue('');
      setMessage('');
    }
  }, [isOpen]);

  useEffect(() => {
    setNewBinaryValue(currentRawValue);
  }, [currentRawValue]);

  const unit = signal
    ? getSignalTypeUnit(signal.signalTypeId, signalTypesMap, signalUnitTypesMap)
    : null;

  const onValueChanged = useCallback(
    (
      event:
        | React.ChangeEvent<HTMLInputElement>
        | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
      setNewValue(event.target.value);
    },
    []
  );

  const onConfirmClick = useCallback(() => {
    const newValueNum =
      unit === Unit.BINARY ? newBinaryValue : parseLocaleNumber(newValue);
    onConfirm(signal, newValueNum, currentRawValue, message);
  }, [
    currentRawValue,
    message,
    newBinaryValue,
    newValue,
    onConfirm,
    signal,
    unit
  ]);

  const toggleSignal = useCallback(() => {
    setNewBinaryValue((oldValue) => (oldValue === 1 ? 0 : 1));
  }, []);

  const valueIsValid =
    unit === Unit.BINARY
      ? newBinaryValue !== currentRawValue
      : newValue != null &&
        newValue !== '' &&
        !isNaN(parseLocaleNumber(newValue));
  const validMessage = !_.isEmpty(message);
  const canConfirm = valueIsValid && !isLoading && validMessage;

  return (
    <ActionModal
      title={title}
      isOpen={isOpen}
      headerIcon={Icons.Edit}
      onModalClose={onCancel}
      onConfirmClick={onConfirmClick}
      disableActionButton={!canConfirm}
      isLoading={isLoading}
      actionText={T.equipment.setvalue}
    >
      <KeyValueColumn>
        {unit === Unit.BINARY ? (
          <>
            <KeyValueSwitchInput
              keyText={T.equipment.currentvalue}
              value={currentRawValue}
            />
            <KeyValueSwitchInput
              keyText={T.equipment.newvalue}
              value={newBinaryValue}
              onChange={toggleSignal}
            />
          </>
        ) : (
          <>
            <KeyValueNumberInput
              keyText={T.equipment.currentvalue}
              disabled
              value={currentValue}
            />
            <KeyValueNumberInput
              keyText={T.equipment.newvalue}
              onEnterKeyPressed={onConfirmClick}
              value={newValue}
              onChange={onValueChanged}
              hasError={!valueIsValid}
              autoFocus
            />
          </>
        )}
        <KeyValueInput
          keyText={T.common.reason}
          value={message}
          hasError={!validMessage}
          onChange={(event) => setMessage(event.target.value)}
        />
      </KeyValueColumn>
    </ActionModal>
  );
};

export default React.memo(SignalValueEditModal);
