import React from 'react';
import {
  ProcessMapObjectsProps,
  ProcessMapOverlayObjectProps
} from '../ProcessMapObjectProps';
import { ProcessMapObjectTypes } from '../ProcessMapViewConstants';
import { RectView, RectOverlay } from './RectView';
import { SignalView } from './SignalView';
import { TextView } from './TextView';
import { SymbolOverlay, SymbolView } from './SymbolView';
import { LineView, LineOverlay } from './LineView';

type ProcessMapObjectView = {
  view: React.ComponentType<ProcessMapObjectsProps>;
  overlay?: React.ComponentType<ProcessMapOverlayObjectProps>;
};

export const objects: Record<string, ProcessMapObjectView> = {
  [ProcessMapObjectTypes.Line]: {
    view: LineView,
    overlay: LineOverlay
  },
  [ProcessMapObjectTypes.Symbol]: {
    view: SymbolView,
    overlay: SymbolOverlay
  },
  [ProcessMapObjectTypes.Text]: {
    view: TextView
  },
  [ProcessMapObjectTypes.Signal]: {
    view: SignalView
  },
  [ProcessMapObjectTypes.Rect]: {
    view: RectView,
    overlay: RectOverlay
  }
};
