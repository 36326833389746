import localStore from 'store';
import _ from 'lodash';
import {
  ApiEnvironmentConfig,
  emptyEnvironment
} from 'ecto-common/lib/API/ApiConfigType';

export const ENVIRONMENT_HAS_BEEN_SELECTED_KEY =
  'EctocloudEnvironmentConfirmed';
export const ENVIRONMENT_INDEX_KEY = 'EctocloudEnvironmentIndex';

export const setApiEnvironmentIndex = (newIndex: number) => {
  localStore.set(ENVIRONMENT_INDEX_KEY, newIndex);
};

let apiEnvironment: ApiEnvironmentConfig = _.cloneDeep(emptyEnvironment);

export type APIEnvironmentDefinition = {
  name: string;
  filename: string;
};

export const setApiEnvironment = (newApiEnvironment: ApiEnvironmentConfig) => {
  apiEnvironment = newApiEnvironment;
};

export const getApiEnvironment = () => apiEnvironment;

export const setHasSelectedAPIEnvironment = (hasSelected: boolean) => {
  if (hasSelected) {
    localStore.set(ENVIRONMENT_HAS_BEEN_SELECTED_KEY, true);
  } else {
    localStore.remove(ENVIRONMENT_HAS_BEEN_SELECTED_KEY);
  }
};

export const hasSelectedAPIEnvironment = () => {
  return localStore.get(ENVIRONMENT_HAS_BEEN_SELECTED_KEY) != null;
};

export const getApiEnvironmentIndex = () => {
  const index = localStore.get(ENVIRONMENT_INDEX_KEY) ?? 0;

  if (index >= process.env.API_ENVIRONMENTS.length || index < 0) {
    return 0;
  }

  return index;
};

export const getApiEnvironmentDefinitions = (): APIEnvironmentDefinition[] => {
  return process.env.API_ENVIRONMENTS as unknown as APIEnvironmentDefinition[];
};

export const getCurrentApiEnvironmentDefinition =
  (): APIEnvironmentDefinition => {
    return getApiEnvironmentDefinitions()[getApiEnvironmentIndex()];
  };
