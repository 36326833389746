import React from 'react';
import styles from './Toolbar.module.css';
import classNames from 'classnames';

interface ToolbarItemProps {
  /**
   * The content of the component.
   */
  children?: React.ReactNode;
  /**
   * Used to override the appearance of the Toolbar item. Should be a valid CSS class name.
   */
  className?: string;
  /**
   * Set to true if you want the item to expand and take remaining space.
   */
  expanding?: boolean;
}

/**
 * This component is used to wrap custom components in the Toolbar. Makes sure that the spacing between items is correct.
 */
const ToolbarItem = ({
  expanding = false,
  children,
  className = null
}: ToolbarItemProps) => {
  return (
    <div
      className={classNames(
        styles.toolbarItem,
        expanding && styles.expanding,
        className
      )}
    >
      {children}
    </div>
  );
};

export default React.memo(ToolbarItem);
