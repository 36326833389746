import Icons from '../Icons/Icons';
import React from 'react';
import Button, { ButtonProps } from './Button';

const AddButton = ({ children = null, ...props }: ButtonProps) => {
  return (
    <Button {...props}>
      <Icons.Add />
      {children}
    </Button>
  );
};

export default AddButton;
