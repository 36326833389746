import React from 'react';
import SvgIcon, { SvgIconProps } from './SvgIcon';
import styles from './Alert.module.css';

const Alert = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 42 42" {...props}>
    <path
      className={styles.alert}
      d="M22.36,30A1.21,1.21,0,0,1,21,31.16a1.18,1.18,0,1,1,0-2.33A1.22,1.22,0,0,1,22.36,30ZM19.79,16.75h2.42l-.6,9.89H20.3Z"
    />
    <path
      className={styles.alert}
      d="M21.08,7.94a.17.17,0,0,1,.17.1L35.89,33.39a.19.19,0,0,1-.17.29H6.45a.19.19,0,0,1-.17-.29L20.91,8a.17.17,0,0,1,.17-.1m0-2A2.17,2.17,0,0,0,19.18,7L4.55,32.39a2.19,2.19,0,0,0,1.9,3.29H35.72a2.19,2.19,0,0,0,1.9-3.29L23,7a2.17,2.17,0,0,0-1.9-1.1Z"
    />
  </SvgIcon>
);

export default Alert;
