import React, { Component } from 'react';
import classnames from 'classnames';
import Flex from '../../components/Flex/Flex';
import View from '../../components/View/View';
import './SetupView.css';
import backspaceIcon from '../../assets/icon_backspace.svg';
import checkIcon from '../../assets/icon_check.svg';
import ectocloudImage from '../../assets/screen_ectocloud.png';
import powerProductionImage from '../../assets/screen_production.png';
import powerConsumptionImage from '../../assets/screen_consumption.png';
import comboImage from '../../assets/screen_combo.png';
import SocketMessages from '../../../../server/socket-messages';
import { getRoomToken } from '../../components/App/app_util';

const availableRoles = [
  ['production', 'Power Production'],
  ['consumption', 'Power Consumption'],
  ['ectocloud', 'ectocloud™ Prediction'],
  ['combo', 'Consolidated']
];

const imageMap = {
  consumption: powerConsumptionImage,
  production: powerProductionImage,
  ectocloud: ectocloudImage,
  combo: comboImage
};

function StatusText(props) {
  if (!props.status) {
    return null;
  }
  return (
    <p className="screen-pane__status">
      {props.status === 'success' ? (
        <React.Fragment>
          <img src={checkIcon} alt="" />
          Screen found
        </React.Fragment>
      ) : (
        <React.Fragment>Screen not found</React.Fragment>
      )}
    </p>
  );
}

type SetupViewProps = {
  socket: any;
  room: string;
  onSetupToggle: (val: boolean) => void;
};

type SetupViewState = {
  selectedRole?: string;
  selectedRoleNumber?: number;
  role?: any;
  statuses?: Record<string, string>;
  clientNumber?: string;
  numbers?: Record<string, number[]>;
};

/** The pairing screen on the table, allowing you to pair the table to clients. */
export default class SetupView extends Component<
  SetupViewProps,
  SetupViewState
> {
  roleScreens: {
    id: string;
    title: string;
    image: string;
  }[];
  roleScreenNumbers: number;

  constructor(props) {
    super(props);

    // roleScreens is used for rendering the available screens
    this.roleScreens = availableRoles.map((roleTuple) => ({
      id: roleTuple[0],
      title: roleTuple[1],
      image: imageMap[roleTuple[0]]
    }));
    // Decides how many numbers each screen supports
    this.roleScreenNumbers = 3;

    this.handleScreenChange = this.handleScreenChange.bind(this);
    this.handleNumberClick = this.handleNumberClick.bind(this);
    this.handleNumberReset = this.handleNumberReset.bind(this);
    this.handleNumberDelete = this.handleNumberDelete.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.selectScreen = this.selectScreen.bind(this);
    this.deselectScreens = this.deselectScreens.bind(this);
    this.isMatchingSelectedRole = this.isMatchingSelectedRole.bind(this);
    this.findEmptyNumber = this.findEmptyNumber.bind(this);
    this.connectToScreen = this.connectToScreen.bind(this);
    this.screenPaired = this.screenPaired.bind(this);
    this.screenFailedToPair = this.screenFailedToPair.bind(this);
    this.closeSetupView = this.closeSetupView.bind(this);

    this.state = {
      role: availableRoles[0][0],
      clientNumber: '',
      selectedRole: null,
      selectedRoleNumber: 0,

      // We create an object to house the numbers for each role
      numbers: availableRoles.reduce((acc, roleTuple) => {
        acc[roleTuple[0]] = Array(this.roleScreenNumbers).fill(-1);
        return acc;
      }, {}),
      // We create an object to house the status for each role
      statuses: availableRoles.reduce((acc, roleTuple) => {
        acc[roleTuple[0]] = null;
        return acc;
      }, {})
    };
  }

  componentDidMount() {
    this.props.socket.on(SocketMessages.PAIR_SUCCESS, this.screenPaired);
    this.props.socket.on(SocketMessages.PAIR_ERROR, this.screenFailedToPair);
  }

  componentWillUnmount() {
    this.props.socket.off(SocketMessages.PAIR_SUCCESS, this.screenPaired);
    this.props.socket.off(SocketMessages.PAIR_ERROR, this.screenFailedToPair);
  }

  screenPaired(role) {
    this.setState({
      statuses: {
        ...this.state.statuses,
        [role]: 'success'
      }
    });
  }

  screenFailedToPair(role) {
    this.setState({
      statuses: {
        ...this.state.statuses,
        [role]: 'error'
      }
    });
  }

  get currentNumber() {
    return this.state.clientNumber.length === 0
      ? '<number>'
      : this.state.clientNumber;
  }

  findEmptyNumber(screenRoleId) {
    return this.state.numbers[screenRoleId].findIndex(
      (number) => number === -1
    );
  }

  handleScreenChange(ev) {
    this.setState({
      role: ev.target.value
    });
  }

  handleNumberClick(ev, number) {
    ev.stopPropagation();
    const newState: Partial<SetupViewState> = {};
    let activeRoleScreen = this.state.selectedRole;

    // If we have no active screen, select the first one.
    if (!activeRoleScreen) {
      activeRoleScreen = availableRoles[0][0];
      newState.selectedRole = activeRoleScreen;
    }

    // We need to figure out wether we are changing the active screen or number.
    let emptyNumberPlace = this.findEmptyNumber(activeRoleScreen);
    const currentRoleNumber =
      this.state.numbers[activeRoleScreen][emptyNumberPlace];
    const currentNumberSet = currentRoleNumber !== -1;

    if (!currentNumberSet) {
      const newRoleNumbers = [...this.state.numbers[activeRoleScreen]];

      newRoleNumbers[emptyNumberPlace] = number;

      newState.numbers = {
        ...this.state.numbers,
        [activeRoleScreen]: newRoleNumbers
      };
    }

    this.setState(newState, () => {
      let changeScreen = false;
      let newScreen = '';

      // If we are on the last number in the current active screen and it is set.
      emptyNumberPlace = this.findEmptyNumber(activeRoleScreen);

      if (emptyNumberPlace === -1) {
        // No more empty numbers, send pairing request

        // Find place (index) of the current active screen
        const currentScreenPlace = availableRoles.findIndex(
          (roleTuple) => roleTuple[0] === activeRoleScreen
        );

        // If we are not on the last screen, change to next
        if (currentScreenPlace < availableRoles.length - 1) {
          changeScreen = true;
          newScreen = availableRoles[currentScreenPlace + 1][0];
        } else {
          // We should be absolute done so deselect everything
          this.deselectScreens(ev);
        }

        this.connectToScreen(
          activeRoleScreen,
          this.state.numbers[activeRoleScreen].join('')
        );
      }

      if (changeScreen) {
        const newStatuses = {
          ...this.state.statuses,
          [newScreen]: null
        };

        this.setState({
          statuses: newStatuses,
          selectedRole: newScreen,
          selectedRoleNumber: this.findEmptyNumber(newScreen)
        });
      } else {
        this.setState({
          selectedRoleNumber: emptyNumberPlace
        });
      }
    });
  }

  handleNumberDelete(ev) {
    ev.stopPropagation();

    const { numbers, selectedRoleNumber, selectedRole } = this.state;
    const currentRoleNumbers = numbers[selectedRole];

    // If the last one is not empty we want to clear it
    if (
      selectedRoleNumber === 2 &&
      currentRoleNumbers[selectedRoleNumber] !== -1
    ) {
      currentRoleNumbers[selectedRoleNumber] = -1;
    } else if (selectedRoleNumber > 0) {
      currentRoleNumbers[selectedRoleNumber - 1] = -1;
    }

    this.setState(
      {
        numbers: {
          ...numbers,
          [selectedRole]: currentRoleNumbers
        }
      },
      () => {
        this.setState({
          selectedRoleNumber: this.findEmptyNumber(selectedRole)
        });
      }
    );
  }

  handleNumberReset() {
    this.setState({ clientNumber: '' });
  }

  handleAdd() {
    this.props.socket.emit(SocketMessages.PAIR_TABLE_TO_CLIENT, {
      role: this.state.role,
      number: this.state.clientNumber,
      room: this.props.room,
      roomToken: getRoomToken()
    });
  }

  connectToScreen(roleId, number) {
    this.setState({
      statuses: {
        ...this.state.statuses,
        [roleId]: null
      }
    });

    this.props.socket.emit(SocketMessages.PAIR_TABLE_TO_CLIENT, {
      role: roleId,
      number: number,
      room: this.props.room,
      roomToken: getRoomToken()
    });
  }

  closeSetupView() {
    this.props.onSetupToggle && this.props.onSetupToggle(false);
  }

  deselectScreens(ev) {
    ev.stopPropagation();
    this.setState({
      selectedRole: null
    });
  }

  selectScreen(ev, screenRole) {
    ev.stopPropagation();
    const emptyNumber = this.findEmptyNumber(screenRole.id);

    this.setState({
      statuses: {
        ...this.state.statuses,
        [screenRole.id]:
          this.state.statuses[screenRole.id] === 'error'
            ? null
            : this.state.statuses[screenRole.id]
      },
      selectedRole: screenRole.id,
      selectedRoleNumber:
        emptyNumber === -1 ? this.roleScreenNumbers - 1 : emptyNumber
    });
  }

  isMatchingSelectedRole(role) {
    return role.id === this.state.selectedRole;
  }

  render() {
    return (
      <View
        connecting={false}
        className="SetupView"
        direction="column"
        onClick={this.deselectScreens}
      >
        <h1>Setup</h1>
        <p className="SetupView__desc">
          Enter the digits for each associated display.
        </p>
        <Flex direction="row" className="row screen-select">
          {this.roleScreens.map((role) => (
            <Flex
              key={role.id}
              direction="column"
              className={classnames(
                'col',
                'screen-pane',
                this.isMatchingSelectedRole(role) && 'screen-pane--active',
                this.state.statuses[role.id] === 'success' &&
                  'screen-pane--activated',
                this.state.statuses[role.id] === 'error' && 'screen-pane--error'
              )}
              onClick={(ev) => this.selectScreen(ev, role)}
            >
              <h2>{role.title}</h2>
              <div className="screenshot">
                <img src={role.image} alt="" />
              </div>
              <Flex className="screen-pane__numbers" direction="row">
                {this.state.numbers[role.id].map((value, i) => (
                  <div
                    key={`${role.id}-${i}`}
                    className={classnames(
                      'screen-pane__number',
                      this.isMatchingSelectedRole(role) &&
                        this.state.selectedRoleNumber === i &&
                        'screen-pane__number--active'
                    )}
                  >
                    {value === -1 ? '' : value}
                  </div>
                ))}
              </Flex>
              {this.state.statuses[role.id] && (
                <StatusText status={this.state.statuses[role.id]} />
              )}
            </Flex>
          ))}
        </Flex>
        <div className="row">
          <div className="col col--keypad">&nbsp;</div>
          <Flex className="col keypad" direction="column">
            <Flex direction="row">
              <button onClick={(ev) => this.handleNumberClick(ev, 1)}>1</button>
              <button onClick={(ev) => this.handleNumberClick(ev, 2)}>2</button>
              <button onClick={(ev) => this.handleNumberClick(ev, 3)}>3</button>
            </Flex>
            <Flex direction="row">
              <button onClick={(ev) => this.handleNumberClick(ev, 4)}>4</button>
              <button onClick={(ev) => this.handleNumberClick(ev, 5)}>5</button>
              <button onClick={(ev) => this.handleNumberClick(ev, 6)}>6</button>
            </Flex>
            <Flex direction="row">
              <button onClick={(ev) => this.handleNumberClick(ev, 7)}>7</button>
              <button onClick={(ev) => this.handleNumberClick(ev, 8)}>8</button>
              <button onClick={(ev) => this.handleNumberClick(ev, 9)}>9</button>
            </Flex>
            <Flex direction="row">
              <button disabled>&nbsp;</button>
              <button onClick={(ev) => this.handleNumberClick(ev, 0)}>0</button>
              <button onClick={this.handleNumberDelete}>
                <img src={backspaceIcon} alt="" />
              </button>
            </Flex>
          </Flex>
          <div className="col">&nbsp;</div>
        </div>
        <div className="row">
          <div className="col">
            <button className="button" onClick={this.closeSetupView}>
              Back to table
            </button>
          </div>
        </div>
      </View>
    );
  }
}
