import { useCallback, useState, useEffect, useRef } from 'react';

// "otherReload" is another external trigger that can trigger this one.
// So you can chain different triggers.
const useReloadTrigger = (
  otherReload = 0,
  initialValue = 0
): [number, () => void] => {
  const [reloadTrigger, setReloadTrigger] = useState(initialValue);
  const reload = useCallback(() => setReloadTrigger((prev) => prev + 1), []);
  const isInitialCall = useRef(true);

  useEffect(() => {
    if (isInitialCall.current) {
      isInitialCall.current = false;
    } else {
      reload();
    }
  }, [otherReload, reload]);

  return [reloadTrigger, reload];
};

export default useReloadTrigger;
