import React, { useCallback } from 'react';

import styles from 'ecto-common/lib/ModelForm/Plugins/ModelEditorRadioButton.module.css';

import RadioButton from 'ecto-common/lib/RadioButton/RadioButton';
import { KeyValueLine } from 'ecto-common/lib/KeyValueInput/KeyValueLine';
import { ModelEditorProps } from 'ecto-common/lib/ModelForm/ModelEditor';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import { ModelDefinitionInternal } from 'ecto-common/lib/ModelForm/ModelPropType';

export type RadioButtonModelDefinition<
  ObjectType extends object,
  EnvironmentType extends object,
  ValueType = object
> = {
  modelType: typeof ModelType.RADIO;
  targetValue?: ValueType;
  isChecked: (
    value: ValueType,
    input: ObjectType,
    environment: EnvironmentType,
    model: RadioButtonModelDefinition<ObjectType, EnvironmentType, ValueType>
  ) => boolean;
} & ModelDefinitionInternal<ObjectType, EnvironmentType, ValueType>;

type ModelEditorRadioButtonProps = ModelEditorProps & {
  model: RadioButtonModelDefinition<object, object, unknown>;
  isChecked: boolean;
};

const ModelEditorRadioButton = ({
  model,
  updateItem,
  disabled,
  isChecked
}: ModelEditorRadioButtonProps) => {
  const _updateItem = useCallback(
    (newIsChecked: boolean) => {
      if (newIsChecked) {
        updateItem(model.targetValue);
      }
    },
    [updateItem, model.targetValue]
  );

  return (
    <KeyValueLine>
      <div className={styles.radioButtonContainer}>
        <span
          className={styles.radioButtonLabel}
          onClick={() => _updateItem(!isChecked)}
        >
          {model.label}
        </span>
        <RadioButton
          disabled={disabled}
          className={styles.radioButton}
          checked={isChecked}
          onChange={_updateItem}
        />
      </div>
    </KeyValueLine>
  );
};

export default React.memo(ModelEditorRadioButton);
