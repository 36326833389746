export default class NumberBuffer {
  buffer: Array<number>;
  minValue: number;
  maxValue: number;
  avgValue: number;

  constructor(numElements: number) {
    console.assert(numElements > 2);
    this.buffer = new Array(numElements);
    this.minValue = 0;
    this.maxValue = 0;
    this.avgValue = 0;
  }

  push(value: number) {
    this.maxValue = this.buffer[1];
    this.minValue = this.buffer[1];
    this.avgValue = 0;

    for (let i = 1; i < this.buffer.length; i++) {
      this.buffer[i - 1] = this.buffer[i];
      this.avgValue += this.buffer[i];
      this.maxValue = Math.max(this.maxValue, this.buffer[i]);
      this.minValue = Math.min(this.minValue, this.buffer[i]);
    }

    this.maxValue = Math.max(this.maxValue, value);
    this.minValue = Math.min(this.minValue, value);
    this.avgValue = (this.avgValue + value) / this.buffer.length;
    this.buffer[this.buffer.length - 1] = value;
  }
}
