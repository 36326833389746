import React from 'react';
import ErrorNotice from 'ecto-common/lib/Notice/ErrorNotice';
import { NoticeProps } from 'ecto-common/lib/Notice/Notice';
import styles from './KeyValueInput.module.css';

const KeyValueErrorNotice = (props: NoticeProps) => {
  return <ErrorNotice className={styles.errorNotice} {...props} />;
};

export default React.memo(KeyValueErrorNotice);
